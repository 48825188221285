import type { ReactNode } from "react"
import React from "react"

import { Paper, Typography } from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import { solidGray } from "../../../../utils/colors"

interface ReportSummaryProps {
  children: ReactNode
}

const DataSummary = ({ children }: ReportSummaryProps) => (
  <>
    <Typography
      color={solidGray.toString()}
      mb={1}
      variant={TYPOGRAPHY_VARIANT.h2}
    >
      Data Summary
    </Typography>
    <Paper sx={{ p: 2, width: "376px" }} variant="outlined">
      {children}
    </Paper>
  </>
)

export default DataSummary
