import { z } from "zod"

import { s } from "../utils/schema"

// This schema currently supports only "create" and "edit" form validation
// It is not ready to be used to generate TypeScript types
export const billingGroupSchema = s.define("billingGroup", {
  groupName: z.string().min(1),
  alias: z.string().nullable().optional(),
})
