// @ts-strict-ignore
import type { PersistedSpraypaintRecord } from "spraypaint"

import type LoadAggregationPoint from "../../../../models/loadAggregationPoint"
import type Price from "../../../../models/price"
import { useLAP } from "./useLAP"
import { useLAPPrices } from "./useLAPPrices"

/**
 * Provides a load aggregation point and day ahead market prices for that load aggregation point.
 * Provides a single loading state determined by either loading states.
 * Provides a single fetched state determined by both fetched states.
 *
 * @example
 * const {
 *  isSiteLoadAggregationPointAndPricesLoading,
 *  isSiteLoadAggregationPointAndPricesFetched,
 *  siteLoadAggregationPoint,
 *  siteLoadAggregationPointPrices,
 * } = useSiteLoadAggregationPointAndPrices(site)
 *
 */
const useSiteLoadAggregationPointAndPrices = (
  site
): {
  isSiteLoadAggregationPointAndPricesFetched: boolean
  isSiteLoadAggregationPointAndPricesLoading: boolean
  siteLoadAggregationPoint:
    | PersistedSpraypaintRecord<LoadAggregationPoint>
    | undefined
  siteLoadAggregationPointPrices: Price[] | undefined
} => {
  const {
    siteLoadAggregationPoint,
    isSiteLoadAggregationPointFetched,
    isSiteLoadAggregationPointLoading,
  } = useLAP(site)
  const {
    siteLoadAggregationPointPrices,
    isSiteLoadAggregationPointPricesFetched,
    isSiteLoadAggregationPointPricesLoading,
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  } = useLAPPrices(site)

  return {
    isSiteLoadAggregationPointAndPricesLoading:
      isSiteLoadAggregationPointPricesLoading ||
      isSiteLoadAggregationPointLoading,
    isSiteLoadAggregationPointAndPricesFetched:
      isSiteLoadAggregationPointPricesFetched &&
      isSiteLoadAggregationPointFetched,
    siteLoadAggregationPoint,
    siteLoadAggregationPointPrices,
  }
}

export { useSiteLoadAggregationPointAndPrices }
