import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class SiteEmissionDay extends ApplicationRecord {
  public static jsonapiType = "site_emission_days"

  @Attr() public amount: number

  @Attr() public category: string

  @Attr() public kgCo2e: string

  @Attr() public name: string

  @Attr() public scope: string

  @Attr() public subCategory: string

  /**
   * @deprecated Use the kgCo2e property and retype it to a number
   */
  get parsedKgCo2e() {
    return parseFloat(this.kgCo2e)
  }
}
