// @ts-strict-ignore
import type { components } from "@/services/generated/schema"
import type { Schemas } from "@/services/types"

import { useFormatPercentage } from "../../../formatters/useFormatPercentage"

export const buildPlanName = (plan: Schemas["BuildSimResponse"]) => {
  if (!plan.interventions) {
    return undefined
  }
  const interventionValues = Object.values(
    plan.interventions as Record<
      string,
      Record<string, components["schemas"]["InterventionContribution"]>
    >
  )
  const interventionsList = []
  for (const interventionSet of interventionValues) {
    for (const [interventionName, intervention] of Object.entries(
      interventionSet
    )) {
      if (intervention.included) {
        interventionsList.push({ name: interventionName, ...intervention })
      }
    }
  }

  interventionsList.sort(
    (a, b) =>
      b.contribution.percentage_emissions_savings -
      a.contribution.percentage_emissions_savings
  )

  const planNameList: { name: string; percentage?: number }[] = []

  for (const i of interventionsList) {
    const nameListValue = {
      name: i.name,
      percentage: i.contribution.emission_savings_ratio,
    }
    planNameList.push(nameListValue)
  }

  return planNameList
}

export const useBuildPlanNameString = () => {
  const { format } = useFormatPercentage()

  const buildPlanNameString = (plan?: Schemas["BuildSimResponse"]): string => {
    if (!plan) {
      return ""
    }

    const planNameList = buildPlanName(plan)
    const planNameString = (planNameList ?? [])
      .map(
        (value) =>
          `${value.name}: ${value.percentage ? format(value.percentage) : ""}`
      )
      .join(", ")

    return planNameString
  }

  return { buildPlanNameString }
}
