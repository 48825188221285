/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import type { FieldValues, Path } from "react-hook-form"
import { useController } from "react-hook-form"

import type { SelectProps } from "@mui/material"

import { TamYesNoField } from "../baseInputs/tamYesNoField"

type BooleanString = "true" | "false" | "null"

const parseValueIn = (valueIn: boolean | null): BooleanString => {
  if (valueIn === true) {
    return "true"
  }
  if (valueIn === false) {
    return "false"
  }
  return "null"
}

const parseValueOut = (valueOut: BooleanString): boolean | null => {
  if (valueOut === "true") {
    return true
  }
  if (valueOut === "false") {
    return false
  }
  return null
}

interface TamFormYesNoFieldProps<T> extends SelectProps {
  dataE2E?: string
  isNoDisabled?: boolean
  label: string
  name: Path<T>
  readOnly?: boolean
}

export const TamFormYesNoField = <T extends FieldValues>(
  props: TamFormYesNoFieldProps<T>
) => {
  const { name, label, isNoDisabled, dataE2E, readOnly, required, disabled } =
    props

  const {
    field: { ref, onBlur, onChange, value },
    fieldState: { error },
  } = useController({ name })

  return (
    <TamYesNoField
      ref={ref}
      dataE2E={dataE2E}
      disabled={disabled}
      error={!!error}
      errorMessage={error?.message}
      inputRef={ref}
      isNoDisabled={isNoDisabled}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={({ target: { value: valueOut } }) => {
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        onChange(parseValueOut(valueOut))
      }}
      readOnly={readOnly}
      required={required}
      value={parseValueIn(value) ?? ""}
    />
  )
}
