import { appCompanyName } from "@/utils/constants"

const nzeroMailServerAndDomain = `${appCompanyName.toLowerCase()}.com`

const isNzeroEmail = (email: string | null | undefined): boolean => {
  return email?.endsWith(`@${nzeroMailServerAndDomain}`) ?? false
}

const createSafeSigmaEmbedUserEmail = (email: string): string => {
  const emailParts = email.split("@")
  const username = emailParts[0]
  const mailServerAndDomain = emailParts[1]

  return `${username}+sigma@${mailServerAndDomain}`
}

export const getSafeSigmaEmbedUserEmail = (
  email: string | null | undefined
): string => {
  if (isNzeroEmail(email)) {
    return createSafeSigmaEmbedUserEmail(email)
  }

  return email ?? ""
}
