import type { ReactNode } from "react"
import React, { useEffect } from "react"

import { useAvailableReportDates } from "@/services"
import type { Moment } from "moment"

import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import useFilters from "../../../../hooks/useFilters/useFilters"
import BooleanFilter from "../../../../models/filter/booleanFilter"
import DateFilter from "../../../../models/filter/dateFilter"
import MultiSelectNumberFilter from "../../../../models/filter/multiSelectNumberFilter"
import SingleSelectNumberFilter from "../../../../models/filter/singleSelectNumberFilter"
import type { IRange } from "../../../../models/range"
import {
  getMostRecentPreviousYearDateRange,
  isValidDateRange,
} from "../../../../utils/date"
import type { IScopeThreeFilters } from "../../models/scopeThree"

interface ScopeThreeResolverProps {
  children: ReactNode
}

/**
 * A component that ensures the scope three report url and form data are set up
 * before rendering the report
 *
 * @param props - The component properties
 * @param props.children - The children to render
 * @example
 * return (
 *   <ScopeThreeResolver>
 *     <SummaryReport />
 *   </ScopeThreeResolver>
 * )
 */
export const ScopeThreeResolver = ({ children }: ScopeThreeResolverProps) => {
  const { organization } = useOrganizationContext()

  const { availableReportDatesData, availableReportDatesIsFetched } =
    useAvailableReportDates(organization?.id)

  const { filters, setFilters } = useFilters<IScopeThreeFilters>({
    start: DateFilter,
    end: DateFilter,
    summary: BooleanFilter,
    category: SingleSelectNumberFilter,
    subcategory: MultiSelectNumberFilter,
  })

  // Initialize filters
  useEffect(() => {
    const newFilters: Partial<IScopeThreeFilters> = {
      summary: new BooleanFilter(
        filters.summary.value === null ? true : filters.summary.value
      ),
    }

    // Set "start", "end", and "summary" query params
    if (
      availableReportDatesData?.start.isValid() &&
      availableReportDatesData.end.isValid() &&
      // Don't rewrite valid date ranges
      !isValidDateRange(
        {
          start: filters.start.value,
          end: filters.end.value,
        },
        availableReportDatesData
      )
    ) {
      const mostRecentPreviousYearDateRange: IRange<Moment> =
        getMostRecentPreviousYearDateRange(availableReportDatesData)

      newFilters.start = new DateFilter(mostRecentPreviousYearDateRange.start)
      newFilters.end = new DateFilter(mostRecentPreviousYearDateRange.end)
    }

    setFilters(newFilters, { replace: true })
  }, [
    availableReportDatesData,
    filters.end.value,
    filters.start.value,
    filters.summary.value,
    setFilters,
  ])

  return availableReportDatesIsFetched ? <>{children}</> : null
}

export default ScopeThreeResolver
