import React from "react"
import type { FC } from "react"
import { NavLink, useParams } from "react-router-dom"

import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"
import { ReportPath } from "@/models/route"
import { useSigmaWorkbook } from "@/services/sigma"
import { useUrl } from "@/utils/url"

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded"
import { Button } from "@mui/material"

import { SigmaReport } from "../../sigma-report"

export const CustomReport: FC = () => {
  const { buildReportingUrl } = useUrl()
  const { workbookId } = useParams<{ workbookId: string }>()
  const { sigmaWorkbookData } = useSigmaWorkbook(workbookId)

  return (
    <>
      <PageHeader title={sigmaWorkbookData?.name ?? ""} />
      <PageHeaderActionBar hasTabs={false}>
        <Button
          component={NavLink}
          size="small"
          startIcon={<ChevronLeftRoundedIcon />}
          to={buildReportingUrl(ReportPath.CustomReports)}
        >
          Back to Custom Reports
        </Button>
      </PageHeaderActionBar>
      <Page fullHeight>
        <SigmaReport workbookId={workbookId} />
      </Page>
    </>
  )
}
