import type { Filters, IFilter } from "../models/filter/filter"

/**
 * Gets a query string from a filters object
 *
 * @param filters - The filters object from which to generate the query string
 * @returns - The query string
 * @example
 * getQueryStringFromFilters({
 *   start: new DateFilter(moment("2022-01-01")),
 *   end: new DateFilter(moment("2022-01-31")),
 *   department: new MultiSelectStringFilter(["1","2"])
 * })
 */
// TODO: Once useFilters is used everywhere, replace `TFilters = Filters` with just `TFilters`
// to require typing for each use
export const getQueryStringFromFilters = <TFilters = Filters>(
  filters: TFilters
): string => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filterEntries: [string, IFilter<unknown, unknown>][] =
    Object.entries(filters)

  const urlSearchParams: URLSearchParams = filterEntries.reduce(
    (searchParams: URLSearchParams, [filterName, filter]) => {
      const isFilterValueAnArray: boolean = Array.isArray(filter.value)

      // Don't add filters with null values or filters with empty array values
      if (
        (!isFilterValueAnArray && filter.value !== null) ||
        (isFilterValueAnArray && (filter.value as unknown[]).length)
      ) {
        searchParams.set(filterName, filter.toQueryParamValue())
      }

      return searchParams
    },
    new URLSearchParams()
  )

  return urlSearchParams.toString().length
    ? `?${urlSearchParams.toString()}`
    : ""
}
