import type { ReactNode } from "react"
import React from "react"

import { pxToRem } from "../utils/unit"

interface IPanelProps {
  buttons?: ReactNode
  children: ReactNode
  className?: string
  midSubtitle?: ReactNode
  midTitle?: ReactNode
  title?: ReactNode
  width?: string | number
}

export const Panel = ({
  buttons,
  children,
  title,
  midTitle,
  midSubtitle,
  className = null,
  width = null,
}: IPanelProps) => (
  <div
    className={className ? `${className} panel` : "panel"}
    style={{
      width,
      padding: 0,
      marginTop: pxToRem(16),
      border: "none",
      justifyContent: "flex-start",
    }}
  >
    {title && (
      <div className="panel__header">
        <h2 className="panel__title">{title}</h2>
        <div className="panel__mid">
          {midTitle && <h2 className="panel__mid-title">{midTitle}</h2>}
          {midSubtitle && <p className="panel__mid-subtitle">{midSubtitle}</p>}
        </div>
        {buttons && <div className="panel__buttons">{buttons}</div>}
      </div>
    )}
    {children}
  </div>
)
