import { EMPTY_DATA_PLACEHOLDER } from "../../utils"

export const buildingTypes = [
  "Education",
  "Enclosed mall",
  "Food sales",
  "Food service",
  "Inpatient health care",
  "Laboratory",
  "Lodging",
  "Nonrefrigerated warehouse",
  "Nursing",
  "Office",
  "Other",
  "Outpatient health care",
  "Public assembly",
  "Public order and safety",
  "Refrigerated warehouse",
  "Religious worship",
  "Retail other than mall",
  "Service",
  "Strip shopping center",
  "Vacant",
]

export const selectedInterventionsThreshold = 1

export type Step =
  | "Select Site"
  | "Set Goals"
  | "Select Plan"
  | "Review and Save"

export interface StepInfoSelectSite {
  getUrl: () => string
  label: "Select Site"
}
export interface StepInfoSetGoals {
  getUrl: (siteId: string) => string
  label: "Set Goals"
}
export interface StepInfoSelectPlan {
  getUrl: (siteId: string) => string
  label: "Select Plan"
}
export interface StepInfoSavePlan {
  label: "Review and Save"
}

export type StepInfo =
  | StepInfoSelectSite
  | StepInfoSetGoals
  | StepInfoSelectPlan
  | StepInfoSavePlan

export const planningSteps: StepInfo[] = [
  { label: "Select Site", getUrl: () => "/recommendations/plan" },
  {
    label: "Set Goals",
    getUrl: (siteId) => `/recommendations/sites/${siteId}/goals`,
  },
  {
    label: "Select Plan",
    getUrl: (siteId) => `/recommendations/sites/${siteId}`,
  },
  { label: "Review and Save" },
]

export const emptyDataPlaceholder = EMPTY_DATA_PLACEHOLDER
