import { useMutation } from "react-query"

import { ApiQueryName } from "../../models/api"
import Organization from "../../models/organization"
import type { QueryMutation } from "../types"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useUpdateOrganization = () => {
  const mutationKey = useQueryKey(ApiQueryName.Organization, "updateOne")
  const { update } = useSpraypaintModel(Organization)
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      serviceEntityKey,
      newAttributes,
    }: Parameters<
      QueryMutation<"updateOne", ModelAttributes<Organization>>
    >[0]) => {
      await update(serviceEntityKey, newAttributes)
    },
    onSuccess: (_, { serviceEntityKey }) => {
      invalidateQueries([
        createQueryKey(ApiQueryName.Organization, "getOne", serviceEntityKey),
        createQueryKey(ApiQueryName.Organizations, "getMany"),
      ])
    },
  })

  return useMutationReturnHandle(mutationKey, mutationResult)
}
