import { useMutation } from "react-query"

import Address from "../../models/address"
import { ApiQueryName } from "../../models/api"
import Currency from "../../models/currency"
import Site from "../../models/site"
import type { QueryMutation, UseCreateEntityOptions } from "../types"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useCreateSite = ({
  onSuccess,
  onError,
}: UseCreateEntityOptions) => {
  const mutationKey = useQueryKey(ApiQueryName.Site, "createOne")
  const { create } = useSpraypaintModel(Site)
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      newAttributes,
    }: Parameters<QueryMutation<"createOne", ModelAttributes<Site>>>[0]) => {
      const site = await create(newAttributes)
      return site
    },
    onSuccess: (site) => {
      onSuccess?.(site?.id)
      invalidateQueries([
        createQueryKey(ApiQueryName.OrganizationCounts, "getOne"),
        createQueryKey(ApiQueryName.DepartmentCounts, "getOne"),
        createQueryKey(ApiQueryName.Sites, "getMany"),
      ])
    },
    onError: (error: Error) => {
      onError?.(error.message)
    },
  })

  return useMutationReturnHandle(mutationKey, mutationResult)
}

/**
 * @deprecated Remove this hook once we're able to sidepost multiple resources with our
 * ActiveRecord wrapper's create() function.
 */
export const useCreateSiteWithAddress = ({
  onSuccess,
  onError,
}: UseCreateEntityOptions) => {
  const { invalidateQueries } = useInvalidateQueries()
  const mutationKey = useQueryKey(ApiQueryName.Site, "createOne")

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      newAttributes,
    }: Parameters<QueryMutation<"createOne", ModelAttributes<Site>>>[0]) => {
      const { address, currency, ...rest } = newAttributes
      const addressModel = address ? new Address(address) : undefined

      // Even though we receive a Currency object in our param, Spraypaint won't recognize it
      // as an existing record without fetching the actual instance first.
      const currencyModel = currency?.id
        ? (await Currency.where({ id: currency.id }).first()).data
        : undefined

      const site = new Site({ ...rest })
      site.address = addressModel
      site.currency = currencyModel

      const success = await site.save({
        with: ["address", "location.id", "sink.id", "currency.id", "nodes"],
      })
      if (!success) {
        throw new Error("Failed to create site")
      }
      return site.id
    },
    onSuccess: (siteId) => {
      onSuccess?.(siteId)
      invalidateQueries([
        createQueryKey(ApiQueryName.OrganizationCounts, "getOne"),
        createQueryKey(ApiQueryName.DepartmentCounts, "getOne"),
        createQueryKey(ApiQueryName.Sites, "getMany"),
      ])
    },
    onError: (error: Error) => {
      onError?.(error.message)
    },
  })
  return useMutationReturnHandle(mutationKey, mutationResult)
}
