import { titleCase } from "title-case"

/**
 * transforms snake_case_thing into Snake Case Thing
 */
export const snakeToTitleCase = (text?: string): string => {
  if (!text) {
    return ""
  }
  const textWithoutUnderscores = text.replaceAll("_", " ")
  return titleCase(textWithoutUnderscores)
}
