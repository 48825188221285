import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { DecimalWithPlaceholder } from "@/components/decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { PlanningCell } from "@/components/planning-cell/planning-cell"
import { MeasurementSystem } from "@/models/measurementSystem"
import { UnitName } from "@/models/unit"
import { Blur } from "@/nzds"
import {
  DataGridColumnHeader,
  baseColumnDef,
  baseNumberColumnDef,
} from "@/nzds/data-grid"
import {
  getMeasurementSystem,
  translateUnit,
  unitsPerSquareFootToUnitsPerSquareMeter,
} from "@/utils"
import { renderRowNumberPaginated } from "@/utils/render-row-number-paginated"
import { useCommonSiteDataGridColumns } from "@/utils/site/use-common-site-data-grid-columns"

import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"

import type { RowData } from "../pages.types"

export const calculateIsBlurred = (rowId: RowData["id"]): boolean => {
  return rowId < 0
}

export const useCommonResultsColumns = (): Record<
  string,
  GridColDef<RowData>
> => {
  const { i18n } = useTranslation()

  const isMetric: boolean = useMemo(
    () => getMeasurementSystem(i18n.language) !== MeasurementSystem.USCU,
    [i18n.language]
  )

  const commonSiteDataGridColumns = useCommonSiteDataGridColumns<RowData>({
    isBlurEnabled: true,
  })

  const columns: Record<string, GridColDef<RowData>> = useMemo(() => {
    return {
      rowNumber: {
        ...baseColumnDef,
        align: "center",
        disableColumnMenu: true,
        field: "rowNumber",
        headerAlign: "center",
        headerName: "#",
        hideable: false,
        minWidth: 44,
        pinnable: false,
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            {renderRowNumberPaginated({
              pageIndex: params.row.pageIndex,
              page: params.row.page,
              pageSize: params.row.pageSize,
            })}
          </Blur>
        ),
        resizable: false,
        sortable: false,
        width: 44,
      },
      siteName: {
        ...baseColumnDef,
        field: "name",
        headerName: "Site Name",
        hideable: false,
        pinnable: false,
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            {params.row.name}
          </Blur>
        ),
        width: 240,
      },
      buildingArea: {
        ...baseNumberColumnDef,
        field: "sq_ft",
        headerName: "Building Area",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <DecimalWithPlaceholder
              format={{
                precision: 1,
              }}
              value={
                isMetric
                  ? unitsPerSquareFootToUnitsPerSquareMeter(
                      params.value as number
                    )
                  : (params.value as number)
              }
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Building Area"
            headerName="Building Area"
            unit={`${translateUnit(i18n.language, UnitName.SquareFoot)}`}
          />
        ),
        width: 128,
      },
      combinedVolume: {
        ...commonSiteDataGridColumns.combinedEnergyUsage,
      },
      planning: {
        ...baseColumnDef,
        headerName: "Planning",
        field: "planning",
        hideable: false,
        pinnable: false,
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <PlanningCell />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Planning"
            headerName="Planning"
            tooltipContent="Use the NZero Planning tool to view existing plans or create new ones for site decarbonization."
          />
        ),
        resizable: false,
        sortable: false,
        width: 184,
      },
    }
  }, [commonSiteDataGridColumns.combinedEnergyUsage, i18n.language, isMetric])

  return columns
}
