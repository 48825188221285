export enum Language {
  Danish = "da",
  Dutch = "nl",
  English = "en",
  French = "fr",
  German = "de",
  Italian = "it",
  Norwegian = "no",
  Spanish = "es",
  Swedish = "sv",
}

export enum LanguageRegion {
  DanishDenmark = "da-DK",
  DutchBelgium = "nl-BE",
  DutchNetherlands = "nl-NL",
  EnglishCanada = "en-CA",
  EnglishGreatBritain = "en-GB",
  EnglishIreland = "en-IE",
  EnglishSingapore = "en-SG",
  EnglishUnitedStates = "en-US",
  FrenchBelgium = "fr-BE",
  FrenchCanada = "fr-CA",
  FrenchFrance = "fr-FR",
  FrenchLuxembourg = "fr-LU",
  FrenchSwitzerland = "fr-CH",
  GermanBelgium = "de-BE",
  GermanGermany = "de-DE",
  GermanLuxembourg = "de-LU",
  GermanSwitzerland = "de-CH",
  ItalianItaly = "it-IT",
  ItalianSwitzerland = "it-CH",
  NorwegianNorway = "no-NO",
  SpanishSpain = "es-ES",
  SwedishSweden = "sv-SE",
}

export const languageRegions: LanguageRegion[] = [
  LanguageRegion.DutchBelgium,
  LanguageRegion.DanishDenmark,
  LanguageRegion.DutchNetherlands,
  LanguageRegion.EnglishCanada,
  LanguageRegion.EnglishGreatBritain,
  LanguageRegion.EnglishIreland,
  LanguageRegion.EnglishSingapore,
  LanguageRegion.EnglishUnitedStates,
  LanguageRegion.FrenchBelgium,
  LanguageRegion.FrenchCanada,
  LanguageRegion.FrenchFrance,
  LanguageRegion.FrenchSwitzerland,
  LanguageRegion.FrenchLuxembourg,
  LanguageRegion.GermanBelgium,
  LanguageRegion.GermanGermany,
  LanguageRegion.GermanLuxembourg,
  LanguageRegion.GermanSwitzerland,
  LanguageRegion.ItalianItaly,
  LanguageRegion.ItalianSwitzerland,
  LanguageRegion.NorwegianNorway,
  LanguageRegion.SpanishSpain,
  LanguageRegion.SwedishSweden,
]

export enum TranslationNamespace {
  Common = "common",
  Dashboard = "dashboard",
  Organization = "organization",
  Settings = "settings",
  Site = "site",
}

export const Translation = {
  Common: {
    CarbonFreeEnergy: "common:carbonFreeEnergy",
    Currency: {
      CAD: "common:currency.CAD",
      CHF: "common:currency.CHF",
      DKK: "common:currency.DKK",
      EUR: "common:currency.EUR",
      GBP: "common:currency.GBP",
      NOK: "common:currency.NOK",
      SEK: "common:currency.SEK",
      SGD: "common:currency.SGD",
      USD: "common:currency.USD",
    },
    Date: "common:date",
    Decimal: "common:decimal",
    Language: {
      [Language.Danish]: `common:language.${Language.Danish}`,
      [Language.Dutch]: `common:language.${Language.Dutch}`,
      [Language.English]: `common:language.${Language.English}`,
      [Language.French]: `common:language.${Language.French}`,
      [Language.German]: `common:language.${Language.German}`,
      [Language.Italian]: `common:language.${Language.Italian}`,
      [Language.Norwegian]: `common:language.${Language.Norwegian}`,
      [Language.Spanish]: `common:language.${Language.Spanish}`,
      [Language.Swedish]: `common:language.${Language.Swedish}`,
    },
    OnSiteGenerated: "common:onSiteGenerated",
  },
  Dashboard: {
    DashboardTitle: "dashboard:dashboardTitle",
  },
  Organization: {
    WaterUseValue: "organization:waterUseValue",
    WaterUseTitle: "organization:waterUseTitle",
  },
  Settings: {
    CurrencyLabel: "settings:currencyLabel",
    LanguageLabel: "settings:languageLabel",
    SettingsTitle: "settings:settingsTitle",
  },
  Site: {
    CarbonFreeEnergyConsumedYTD: "site:carbonFreeEnergyConsumedYTD",
  },
}
