import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link as RRLink } from "react-router-dom"

import { PlanningCell } from "@/components/planning-cell/planning-cell"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { useFormatDecimal } from "@/formatters"
import { UnitName } from "@/models/unit"
import { DataGridColumnHeader, DataGridNumberEditCell } from "@/nzds/data-grid"
import { FeatureFlags, useFeature } from "@/services"
import { EMPTY_DATA_PLACEHOLDER, translateUnit } from "@/utils"
import { renderRowNumberPaginated } from "@/utils/render-row-number-paginated"
import { useUrl } from "@/utils/url"

import CancelIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import { Link, Typography } from "@mui/material"
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid-pro"
import type {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro"

import { baseSiteColumnDef, baseSiteNumberColumnDef } from "../../../models"
import type { SiteRowData } from "../../../services/use-get-site-explorer"

type CommonGridColumnName =
  | "actions"
  | "buildingArea"
  | "buildingType"
  | "combinedEmissions"
  | "combinedEnergyUsage"
  | "combinedEui"
  | "electricityEmissions"
  | "electricityEui"
  | "electricityUsage"
  | "group"
  | "naturalGasEmissions"
  | "naturalGasEui"
  | "naturalGasUsage"
  | "planning"
  | "rowNumber"
  | "siteName"

interface UseCommonGridColumnsProps {
  buildingTypes: string[] | undefined
  handleRowEditCancelClick: (id: GridRowId) => void
  handleRowEditClick: (id: GridRowId) => void
  handleRowEditSaveClick: (id: GridRowId) => void
  rowModesModel: GridRowModesModel
}

export const useCommonGridColumns = ({
  buildingTypes,
  handleRowEditCancelClick,
  handleRowEditClick,
  handleRowEditSaveClick,
  rowModesModel,
}: UseCommonGridColumnsProps): Record<
  CommonGridColumnName,
  GridColDef<SiteRowData>
> => {
  const { i18n } = useTranslation()
  const { buildSiteUrl } = useUrl()
  const { format: formatDecimal } = useFormatDecimal()
  const { format: formatInteger } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  const columns = useMemo(() => {
    const columnDefMap: Record<
      CommonGridColumnName,
      GridColDef<SiteRowData>
    > = {
      actions: {
        ...baseSiteNumberColumnDef,
        align: "left",
        cellClassName: "actions",
        field: "actions",
        getActions: ({ id }) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                data-testid="save"
                icon={<SaveIcon />}
                key="save"
                label="Save"
                onClick={() => {
                  handleRowEditSaveClick(id)
                }}
                sx={{
                  color: "primary.main",
                }}
              />,
              <GridActionsCellItem
                className="textPrimary"
                icon={<CancelIcon />}
                key="cancel"
                label="Cancel"
                onClick={() => {
                  handleRowEditCancelClick(id)
                }}
              />,
            ]
          }

          return [
            <GridActionsCellItem
              className="textPrimary"
              icon={<EditIcon />}
              key="edit"
              label="Edit"
              onClick={() => {
                handleRowEditClick(id)
              }}
              sx={{
                color: "primary.main",
              }}
            />,
          ]
        },
        headerAlign: "left",
        headerName: "Actions",
        type: "actions",
        width: 92,
      },
      buildingArea: {
        ...baseSiteNumberColumnDef,
        editable: true,
        field: "sq_ft",
        headerName: "Building Area",
        renderEditCell: (
          params: GridRenderEditCellParams<SiteRowData, SiteRowData["sq_ft"]>
        ) => (
          <DataGridNumberEditCell<SiteRowData>
            {...params}
            allowNegative={false}
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Building Area"
            unit={`${translateUnit(i18n.language, UnitName.SquareFoot)}`}
          />
        ),
        valueFormatter: (
          params: GridValueFormatterParams<SiteRowData["sq_ft"]>
        ): string => formatInteger(params.value),
        width: 140,
      },
      buildingType: {
        ...baseSiteColumnDef,
        editable: true,
        field: "building_type",
        headerName: "Building Type",
        type: "singleSelect",
        valueFormatter: (
          params: GridValueFormatterParams<SiteRowData["building_type"]>
        ) => params.value ?? EMPTY_DATA_PLACEHOLDER,
        // Convert null to empty string to avoid error in the singleSelect editor
        valueGetter: (
          params: GridValueGetterParams<
            SiteRowData,
            SiteRowData["building_type"]
          >
        ) => params.value ?? "",
        valueOptions: buildingTypes,
        width: 160,
      },
      combinedEmissions: {
        ...baseSiteNumberColumnDef,
        field: "total_emissions_mt_co2e",
        headerName: "Combined Emissions",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Combined Emissions"
            tooltipContent="Sum of electricity
          and natural gas emissions."
            unit={translateUnit(
              i18n.language,
              UnitName.MetricTonsOfCarbonDioxideEquivalent
            )}
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatDecimal(params.value),
        width: 216,
      },
      combinedEnergyUsage: {
        ...baseSiteNumberColumnDef,
        field: "total_energy_consumption_kbtu",
        headerName: "Combined Energy Usage",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Combined Energy Usage"
            tooltipContent="Sum of electricity
          and natural gas usage."
            unit={translateUnit(i18n.language, UnitName.KiloBritishThermalUnit)}
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatInteger(params.value),
        width: 236,
      },
      combinedEui: {
        ...baseSiteNumberColumnDef,
        field: "total_kbtu_per_sq_ft",
        headerName: "Combined EUI",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Combined EUI"
            tooltipContent="Sum of electricity and natural gas EUI (Energy Use Intensity)."
            unit="kBtu / sq ft" // Purposely not translated per locale
          />
        ),
        valueFormatter: (
          params: GridValueFormatterParams<SiteRowData["total_mmbtu_per_sq_ft"]>
        ) => formatDecimal(params.value),
        width: 160,
      },
      electricityEmissions: {
        ...baseSiteNumberColumnDef,
        field: "electricity_mt_co2e",
        headerName: "Electricity Emissions",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Electricity Emissions"
            unit={translateUnit(
              i18n.language,
              UnitName.MetricTonsOfCarbonDioxideEquivalent
            )}
          />
        ),
        valueFormatter: (
          params: GridValueFormatterParams<SiteRowData["electricity_mt_co2e"]>
        ) => formatDecimal(params.value),
        width: 180,
      },
      electricityEui: {
        ...baseSiteNumberColumnDef,
        field: "electricity_kbtu_per_sq_ft",
        headerName: "Electricity EUI",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Electricity EUI"
            unit="kBtu / sq ft" // Purposely not translated per locale
          />
        ),
        valueFormatter: (
          params: GridValueFormatterParams<
            SiteRowData["electricity_kbtu_per_sq_ft"]
          >
        ) => formatDecimal(params.value),
        width: 140,
      },
      electricityUsage: {
        ...baseSiteNumberColumnDef,
        field: "electricity_kbtu",
        headerName: "Electricity Usage",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Electricity Usage"
            unit={translateUnit(i18n.language, UnitName.KiloBritishThermalUnit)}
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatInteger(params.value),
        width: 152,
      },
      group: {
        ...baseSiteColumnDef,
        field: isUseFlexibleHierarchyEnabled
          ? "organizational_unit_name"
          : "department_name",
        headerName: "Groups",
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <Typography variant="body2" noWrap>
            {isUseFlexibleHierarchyEnabled
              ? params.row.organizational_unit_name
              : params.row.department_name}
          </Typography>
        ),
      },
      naturalGasEmissions: {
        ...baseSiteNumberColumnDef,
        field: "nat_gas_mt_co2e",
        headerName: "Natural Gas Emissions",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Natural Gas Emissions"
            unit={translateUnit(
              i18n.language,
              UnitName.MetricTonsOfCarbonDioxideEquivalent
            )}
          />
        ),
        valueFormatter: (
          params: GridValueFormatterParams<SiteRowData["nat_gas_mt_co2e"]>
        ) => formatDecimal(params.value),
        width: 180,
      },
      naturalGasEui: {
        ...baseSiteNumberColumnDef,
        field: "nat_gas_kbtu_per_sq_ft",
        headerName: "Natural Gas EUI",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Natural Gas EUI"
            unit="kBtu / sq ft" // Purposely not translated per locale
          />
        ),
        valueFormatter: (
          params: GridValueFormatterParams<
            SiteRowData["nat_gas_kbtu_per_sq_ft"]
          >
        ) => formatDecimal(params.value),
        width: 140,
      },
      naturalGasUsage: {
        ...baseSiteNumberColumnDef,
        field: "nat_gas_kbtu",
        headerName: "Natural Gas Usage",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Natural Gas Usage"
            unit={translateUnit(i18n.language, UnitName.KiloBritishThermalUnit)}
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatInteger(params.value),
        width: 180,
      },
      planning: {
        ...baseSiteColumnDef,
        field: "isPlanned",
        filterable: false,
        headerName: "Planning",
        hideable: false,
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <PlanningCell
            planId={params.row.planId}
            siteId={params.row.site_id}
          />
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Planning"
            tooltipContent="Use the NZero Planning tool to view existing plans or create new ones for site decarbonization."
          />
        ),
        sortable: false,
        width: 184,
      },
      rowNumber: {
        ...baseSiteColumnDef,
        align: "center",
        disableColumnMenu: true,
        field: "rowNumber",
        headerAlign: "center",
        headerName: "#",
        hideable: false,
        minWidth: 44,
        renderCell: (params: GridRenderCellParams<SiteRowData>) =>
          renderRowNumberPaginated({
            pageIndex: params.row.pageIndex,
            page: params.row.page,
            pageSize: params.row.pageSize,
          }),
        resizable: false,
        sortable: false,
        width: 44,
      },
      siteName: {
        ...baseSiteColumnDef,
        field: "site_name",
        headerName: "Site Name",
        hideable: false,
        renderCell: (params: GridRenderCellParams<SiteRowData>) => (
          <Link
            component={RRLink}
            noWrap
            to={`${buildSiteUrl(params.row.site_id.toString())}`}
          >
            {params.value}
          </Link>
        ),
        width: 240,
      },
    }

    return columnDefMap
  }, [
    buildSiteUrl,
    buildingTypes,
    formatDecimal,
    formatInteger,
    handleRowEditCancelClick,
    handleRowEditClick,
    handleRowEditSaveClick,
    i18n.language,
    isUseFlexibleHierarchyEnabled,
    rowModesModel,
  ])

  return columns
}
