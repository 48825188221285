import React from "react"
import { useFormContext } from "react-hook-form"

import { Button } from "@mui/material"

interface TamResetButtonProps {
  dataE2E?: string
  onClick?: (event: React.MouseEvent) => void
}

export const TamResetButton = (props: TamResetButtonProps) => {
  const { dataE2E, onClick } = props

  const {
    reset,
    formState: { isDirty, isSubmitting },
  } = useFormContext()

  const handleClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event)
    } else {
      reset()
    }
  }

  return (
    <Button
      color="neutral"
      data-e2e={dataE2E}
      disabled={!isDirty || isSubmitting}
      onClick={handleClick}
      variant="outlined"
    >
      Cancel
    </Button>
  )
}
