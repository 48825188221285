import React, { useEffect } from "react"
import type { FC } from "react"
import { Outlet, useParams } from "react-router-dom"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import type { Organization } from "@/models/organization"
import { useAuthorizedOrganizations } from "@/services/organization"

import Page404 from "../nav/page404/page404"

export const OrganizationGuard: FC = () => {
  const params = useParams()
  const { organization, setOrganization } = useOrganizationContext()
  const { data: authorizedOrganizations } = useAuthorizedOrganizations()

  const urlOrganization: Organization | undefined =
    authorizedOrganizations?.find((org) => org.id === params.orgId)

  useEffect(() => {
    if (urlOrganization && urlOrganization.id !== organization?.id) {
      setOrganization(urlOrganization)
    }
  }, [organization?.id, setOrganization, urlOrganization])

  if (!urlOrganization) {
    return <Page404 />
  }

  if (urlOrganization) {
    return <Outlet />
  }
}
