import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class LoadAggregationPoint extends ApplicationRecord {
  public static jsonapiType = "load_aggregation_points"

  @Attr() public currentCongestion: number

  @Attr() public currentEnergy: number

  @Attr() public currentLmp: number

  @Attr() public currentLmpMajor: string

  @Attr() public currentLosses: number

  @Attr() public name: string

  @Attr() public updatedAt: Date
}
