import React from "react"
import { useNavigate } from "react-router-dom"

import { capitalize } from "lodash-es"

import AddRoundedIcon from "@mui/icons-material/AddRounded"
import { Box, Button, Typography } from "@mui/material"

import type { ParentObjectIds } from "../../../../../models/onboarding/onboarding"
import { OrgViewDialogCreateContent } from "../../../../../models/onboarding/onboarding"

interface OrgViewDetailCreateButtonProps {
  createObjectName: string
  onDialogClose: () => void
  onDialogOpen: (
    title: string,
    content: JSX.Element,
    actions?: JSX.Element
  ) => void
  parentObjectIds: ParentObjectIds
  // TO-DO: Make this permanent when all create buttons are converted to use this.
  redirectPath?: string
}

const OrgViewDetailCreateButton = ({
  createObjectName,
  parentObjectIds,
  onDialogOpen,
  onDialogClose,
  redirectPath,
}: OrgViewDetailCreateButtonProps): JSX.Element => {
  const navigate = useNavigate()

  const onSuccess = (newEntityId: string) => {
    navigate(`${redirectPath}/${newEntityId}`)
  }

  const OrgViewDialogCreateContentComponent =
    OrgViewDialogCreateContent[createObjectName]

  // TO-DO: Create proper error message system in the future.
  const onError = (errorMessage: string) => {
    onDialogOpen(
      `Could not create`,
      <Box>
        <Typography sx={{ mb: 3 }}>{capitalize(errorMessage)}</Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onDialogClose}>Close</Button>
        </Box>
      </Box>
    )
  }

  return (
    <Button
      color="neutral"
      data-testid="orgViewDetailCreateButton"
      onClick={() => {
        onDialogOpen(
          `Create ${createObjectName}`,
          <OrgViewDialogCreateContentComponent
            objectName={createObjectName}
            onClose={onDialogClose}
            onError={onError}
            onSuccess={onSuccess}
            parentObjectIds={parentObjectIds}
          />
        )
      }}
      size="small"
      startIcon={<AddRoundedIcon />}
      variant="outlined"
    >
      Create {createObjectName}
    </Button>
  )
}

export default OrgViewDetailCreateButton
