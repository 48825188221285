import React, { useCallback, useMemo } from "react"

import type { Moment } from "moment"
import moment from "moment"

import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"

import { DatePeriod } from "../../../models/date/date"
import type { IRange } from "../../../models/range"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { pxToRem } from "../../../utils/unit"
import { MonthRangeSelector } from "../monthRangeSelector/monthRangeSelector"

interface IMonthRangeWithPeriodsProps {
  availableMaxMinMonths: IRange<Moment> | undefined
  onChange: (value: IRange<Moment>) => void
  value?: IRange<Moment>
}

export const MonthRangeWithPeriodsSelector = ({
  availableMaxMinMonths,
  value,
  onChange,
}: IMonthRangeWithPeriodsProps) => {
  if (!availableMaxMinMonths) {
    return
  }
  const isPeriodButtonDisabled: boolean = useMemo(
    () =>
      !availableMaxMinMonths.start.isValid() ||
      !availableMaxMinMonths.end.isValid() ||
      !availableMaxMinMonths.end.isSame(moment(), "month"),
    [availableMaxMinMonths]
  )

  const isYTD = (dateRange: IRange<Moment>): boolean => {
    if (!dateRange.start || !dateRange.end) {
      return false
    }

    return (
      !dateRange.start.diff(moment().startOf("year"), "month") &&
      !dateRange.end.diff(moment(), "month")
    )
  }

  const isMTD = (dateRange: IRange<Moment>): boolean => {
    if (!dateRange.start || !dateRange.end) {
      return false
    }

    return (
      !dateRange.start.diff(moment(), "month") &&
      !dateRange.end.diff(moment(), "month")
    )
  }

  const datePeriod = useMemo((): DatePeriod | null => {
    if (isYTD(value)) {
      return DatePeriod.YTD
    }
    if (isMTD(value)) {
      return DatePeriod.MTD
    }
    return null
  }, [value])

  const onMonthRangeSelectorChange = useCallback(
    (monthRange: IRange<Moment>): void => {
      onChange(monthRange)
    },
    [onChange]
  )

  const handlePeriodChange = (
    _event: React.MouseEvent<HTMLElement>,
    period: DatePeriod
  ) => {
    const startDate = {
      [DatePeriod.MTD]: moment().startOf("month"),
      [DatePeriod.YTD]: moment().startOf("year"),
    }
    const monthRange: IRange<Moment> = {
      start: startDate[period],
      end: moment().endOf("hour"),
    }
    onChange(monthRange)
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box mr={2}>
        <MonthRangeSelector
          availableMaxMinMonths={availableMaxMinMonths}
          onChange={onMonthRangeSelectorChange}
          value={value}
        />
      </Box>
      <ToggleButtonGroup
        aria-label="Date Time Period"
        color="neutral"
        disabled={isPeriodButtonDisabled}
        exclusive
        onChange={handlePeriodChange}
        size="small"
        value={datePeriod}
      >
        <ToggleButton value={DatePeriod.MTD}>{DatePeriod.MTD}</ToggleButton>
        <ToggleButton value={DatePeriod.YTD}>{DatePeriod.YTD}</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}
