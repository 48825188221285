import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import { NzDataGrid } from "@/components/nzDataGrid"
import { DataGridColumnHeader } from "@/nzds/data-grid"
import { useAvailableReportDates } from "@/services"
import { useUrl } from "@/utils/url"
import { upperFirst } from "lodash-es"
import type { Moment } from "moment"

import { Box, Divider, Link, Stack, Tooltip, Typography } from "@mui/material"
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"
import type { DatePicker } from "@mui/x-date-pickers-pro"

import { DecimalWithPlaceholder } from "../../../../components/decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import useFilters from "../../../../hooks/useFilters/useFilters"
import DateFilter from "../../../../models/filter/dateFilter"
import SingleSelectStringFilter from "../../../../models/filter/singleSelectStringFilter"
import type { IRange } from "../../../../models/range"
import { Autocomplete } from "../../../../nzds/inputs/autocomplete"
import { MonthRangePicker } from "../../../../nzds/inputs/month-range-picker"
import { TextField } from "../../../../nzds/inputs/text-field"
import {
  FeatureFlags,
  useActiveOrganizationId,
  useFeature,
} from "../../../../services"
import { translateUnit } from "../../../../utils/unit"
import {
  MonthlySitesReportColumnName,
  MonthlySitesReportColumnUnit,
  MonthlySitesReportKey,
  emissionOptionLabel,
  emissionOptions,
} from "../../models/monthlySite"
import type { MonthlySiteDataFilters } from "../../models/monthlySiteData"
import { reportCard } from "../../models/report"
import { useMonthlySiteReport } from "../../services/useMonthlySiteReport/useMonthlySiteReport"
import DownloadCsv from "../downloadCsv/downloadCsv"
import AdvancedReportingTools from "../reportSummary/advancedReportingTools/advancedReportingTools"
import ReportTwoColumnLayout from "../reportTwoColumnLayout/reportTwoColumnLayout"

const MonthlySiteData = () => {
  const { i18n } = useTranslation()
  const { isFeatureEnabled } = useFeature()
  const { buildSiteUrl } = useUrl()
  const { organization } = useOrganizationContext()
  const { filters, setFilters } = useFilters<MonthlySiteDataFilters>({
    data: SingleSelectStringFilter,
    start: DateFilter,
    end: DateFilter,
    electricityEmissionsFactor: SingleSelectStringFilter,
  })
  const { activeOrganizationId } = useActiveOrganizationId()
  const { availableReportDatesData } =
    useAvailableReportDates(activeOrganizationId)

  const {
    monthlySiteReport,
    isMonthlySiteReportFetching,
    isMonthlySiteReportLoading,
  } = useMonthlySiteReport(
    activeOrganizationId,
    {
      start: filters.start.value,
      end: filters.end.value,
    },
    filters?.data.value,
    filters.electricityEmissionsFactor.value
  )

  const defaultColumns: GridColDef[] = [
    {
      headerName: MonthlySitesReportColumnName.SiteName,
      field: MonthlySitesReportKey.SiteName,
      width: 262,
      renderCell: (params: GridRenderCellParams) => (
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Tooltip title={params.value}>
          <Link
            component={NavLink}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            to={buildSiteUrl(params.row.siteId as string)}
          >
            {params.value}
          </Link>
        </Tooltip>
      ),
    },
    {
      headerName: MonthlySitesReportColumnName.SiteId,
      field: MonthlySitesReportKey.SiteId,
      width: 110,
    },
  ]

  const createColumns = (columns): GridColDef[] => {
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const dataColumns = columns.slice(2)
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const styledDataColumns = dataColumns.map((column) => ({
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      headerName: column.title,
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      field: column.key,
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <DecimalWithPlaceholder
          format={{ precision: 2 }}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value={params.value}
        />
      ),
      renderHeader: (params: GridRenderCellParams) => {
        const header = params.colDef.headerName

        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, selectedData, unit, date] = header.match(
          /^(.*?)(\([^)]+\)) (.*)$/
        )

        return (
          <DataGridColumnHeader
            description={header}
            field={params.field}
            headerName={`${date}`}
            unit={unit.replaceAll("(", "").replaceAll(")", "")}
          />
        )
      },
      align: "right",
      type: "number",
    }))
    // migration to strict mode batch disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
    return [...defaultColumns, ...styledDataColumns]
  }

  const handleDateChange = useCallback(
    (
      value: IRange<Moment>,
      context: Parameters<Parameters<typeof DatePicker>[0]["onChange"]>[1]
    ): void => {
      if (context?.validationError) {
        return
      }
      setFilters({
        start: new DateFilter(value.start),
        end: new DateFilter(value.end),
      })
    },
    [setFilters]
  )

  const handleDataChange = useCallback(
    (emission: string): void => {
      setFilters({
        data: new SingleSelectStringFilter(emission),
      })
    },
    [setFilters]
  )

  const handleSelectedElectricityEmissionsFactorChange = useCallback(
    (electricityEmissionsFactor: string): void => {
      setFilters({
        electricityEmissionsFactor: new SingleSelectStringFilter(
          electricityEmissionsFactor
        ),
      })
    },
    [setFilters]
  )

  return (
    <ReportTwoColumnLayout
      actions={
        <DownloadCsv
          isDisabled={!monthlySiteReport || isMonthlySiteReportFetching}
          isLoading={isMonthlySiteReportLoading}
          report={monthlySiteReport}
        />
      }
      details={
        <NzDataGrid
          columns={
            monthlySiteReport?.data?.columns
              ? createColumns(monthlySiteReport.data.columns)
              : defaultColumns
          }
          initialState={{
            pinnedColumns: {
              left: [MonthlySitesReportKey.SiteName],
            },
            sorting: {
              sortModel: [
                { field: MonthlySitesReportKey.SiteName, sort: "asc" },
              ],
            },
          }}
          isLoading={isMonthlySiteReportFetching}
          rows={monthlySiteReport?.data?.rows || []}
        />
      }
      detailsTitle="Data Preview"
      filters={
        <>
          <Stack spacing={2} sx={{ mb: 2 }}>
            <div>
              <Typography>Date Range</Typography>
              <MonthRangePicker
                availableMaxMinMonths={availableReportDatesData}
                fullWidth
                onChange={handleDateChange}
                value={{
                  start: filters.start.value,
                  end: filters.end.value,
                }}
              />
            </div>
            <Autocomplete
              disableClearable
              getOptionLabel={(option) =>
                `${emissionOptionLabel[option]} (${translateUnit(
                  i18n.language,
                  // Mass lint disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  MonthlySitesReportColumnUnit[upperFirst(option)]
                )})`
              }
              onChange={(_, newValue: string) => {
                handleDataChange(newValue)
              }}
              options={emissionOptions}
              renderInput={(params) => (
                <TextField {...params} label="Select Data" />
              )}
              value={filters.data.value}
            />
          </Stack>
          {isFeatureEnabled(
            FeatureFlags.REPORT_MONTHLY_SITE_DATA_ADVANCED_REPORTING_TOOLS,
            organization
          ) && (
            <Box mt={1}>
              <Divider sx={{ marginBottom: 2 }} />
              <AdvancedReportingTools
                onChange={handleSelectedElectricityEmissionsFactorChange}
                value={filters.electricityEmissionsFactor.value}
              />
            </Box>
          )}
        </>
      }
      report={reportCard.MonthlySiteData}
    />
  )
}

export default MonthlySiteData
