import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import useMonthlySiteData from "@/services/useMonthlySiteData/useMonthlySiteData"
import type { Moment } from "moment"

import type { IRange } from "../../../../models/range"
import type { ITableColumn, ITableRow } from "../../../../models/table"
import type {
  MonthlySiteReport,
  MonthlySiteReportResult,
  MonthlySiteReportTableRow,
} from "../../models/monthlySite"
import { MonthlySiteReportReportName } from "../../models/monthlySite"
import { getReportFileName, getReportRows } from "../../util/util"
import {
  getMonthlySiteReportColumns,
  getResourceSummariesWithConvertedMtResults,
} from "./useMonthlySiteReportUtils"

interface UseMonthlySiteReportValue {
  isMonthlySiteReportFetched: boolean
  isMonthlySiteReportFetching: boolean
  isMonthlySiteReportLoading: boolean
  monthlySiteReport: {
    data: {
      columns: ITableColumn<MonthlySiteReportTableRow>[]
      rows: (MonthlySiteReportResult & ITableRow)[]
    }
    name: string
  }
}
/*
 * Returns a monthly site report for the given organization, date range, and column definition.
 *
 * @example
 * const {
 *  monthlySiteReport,
 *  isMonthlySiteReportFetched,
 *  isMonthlySiteReportLoading,
 *  isMonthlySiteReportFetching
 * } =
 *   useMonthlySiteReport(
 *     "1",
 *     { start: moment("2021-01-01"), end: moment("2021-01-31") },
 *     "totalMtCo2e",
 *      "1"
 *   )
 */
export const useMonthlySiteReport = (
  orgId: string,
  dateRange: IRange<Moment>,
  emissionSelected: string,
  electricityEmissionsFactor: string
): UseMonthlySiteReportValue => {
  const {
    monthlySiteData,
    isMonthlySiteDataFetched,
    isMonthlySiteDataLoading,
    isMonthlySiteDataFetching,
  } = useMonthlySiteData(orgId, dateRange, { departments: [] })
  const { i18n } = useTranslation()

  const monthlySiteReport: MonthlySiteReport = useMemo(
    () => ({
      name: getReportFileName(
        MonthlySiteReportReportName.MonthlySiteReport,
        dateRange,
        true // preview not relevant
      ),
      data: {
        columns: getMonthlySiteReportColumns(
          dateRange,
          emissionSelected,
          i18n.language,
          electricityEmissionsFactor
        ),
        rows: getReportRows(
          getResourceSummariesWithConvertedMtResults(
            monthlySiteData,
            emissionSelected,
            electricityEmissionsFactor
          )
        ),
      },
    }),
    [
      dateRange,
      emissionSelected,
      i18n.language,
      monthlySiteData,
      electricityEmissionsFactor,
    ]
  )

  return useMemo(
    () => ({
      monthlySiteReport,
      isMonthlySiteReportFetched: isMonthlySiteDataFetched,
      isMonthlySiteReportLoading: isMonthlySiteDataLoading,
      isMonthlySiteReportFetching: isMonthlySiteDataFetching,
    }),
    [
      monthlySiteReport,
      isMonthlySiteDataFetched,
      isMonthlySiteDataLoading,
      isMonthlySiteDataFetching,
    ]
  )
}
