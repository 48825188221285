import { useQuery } from "react-query"

import type { Moment } from "moment"

import type { Organization } from ".."
import { ApiQueryName } from "../../models/api"
import type { IRange } from "../../models/range"
import type { ResourceSummaryMonthRecord } from "../../modules/report/models/resourceSummary"
import { fetchResourceSummaryMonthRecords } from "../../modules/report/services/useResourceSummaryReport/useResourceSummaryReportUtils"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

/**
 * Returns monthly site data for the given organization, date range, and departments.
 *
 * @example
 * const { monthlySiteData, isMonthlySiteDataFetched, isMonthlySiteDataLoading } =
 *   useMonthlySiteData(
 *    "1",
 *    { start: moment("2021-01-01"), end: moment("2021-01-31") },
 *    { departments: ["1", "2"], groupIds: ["3", "4"] }
 *   )
 */
const useMonthlySiteData = (
  orgId: Organization["id"],
  dateRange: IRange<Moment>,
  options?: {
    departments?: string[]
    groupIds?: string[]
  }
) => {
  const { departments = [], groupIds = [] } = options ?? {}
  // Sort department ids so array order is the same for query caching
  departments.sort((a, b) => Number(a) - Number(b))
  groupIds.sort((a, b) => Number(a) - Number(b))

  const queryFn = () =>
    fetchResourceSummaryMonthRecords(orgId, {
      dateRange,
      departments,
      groupIds,
    })

  const queryKey = useQueryKey(ApiQueryName.MonthlySiteData, "getMany", orgId, {
    start: dateRange?.start?.format("YYYY-MM-DD"),
    end: dateRange?.end?.format("YYYY-MM-DD"),
    departments: departments.join(","),
    groupIds: groupIds.join(","),
  })

  const queryResult = useQuery<ResourceSummaryMonthRecord[]>({
    queryKey,
    queryFn,
    enabled:
      !!orgId && !!dateRange?.start?.isValid() && !!dateRange?.end?.isValid(),
    keepPreviousData: true, // Prevent flash of previous data on date range change
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export default useMonthlySiteData
