import React, { useMemo } from "react"
import type { FC } from "react"

import { InfoOutlined } from "@mui/icons-material"
import { Box, Drawer, IconButton, Tooltip, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"

import { useUnit } from "../../../../components/unit/use-unit"
import { useFormatDecimal } from "../../../../formatters/useFormatDecimal"
import { useFormatMoney } from "../../../../formatters/useFormatMoney"
import { useFormatPercentage } from "../../../../formatters/useFormatPercentage"
import { UnitName } from "../../../../models/unit"
import { SiteInfoContent } from "../../../site/components/siteInfo/siteInfoContent"
import { emptyDataPlaceholder } from "../../constants"
import { useGetPotentialSites } from "../../services/use-get-potential-sites/use-get-potential-sites"
import { drawerWidth } from "./site-info-drawer.constants"
import type { SiteInfoDrawerProps } from "./site-info-drawer.types"

export const SiteInfoDrawer: FC<SiteInfoDrawerProps> = ({
  open,
  onClose,
  site,
}) => {
  const { data } = useGetPotentialSites()

  const { format: formatDecimal } = useFormatDecimal({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const { format: formatPercentage } = useFormatPercentage({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const { format: formatMoney } = useFormatMoney({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const co2eUnit = useUnit(UnitName.MetricTonsOfCarbonDioxideEquivalent)
  const gjSqFtUnit = useUnit(UnitName.GigaJoulePerSquareFoot)

  // temporary for now, as other endpionts are not ready
  const potentialSite = useMemo(() => {
    let foundSite
    if (data && data.length > 0) {
      foundSite = data.find((item) => item.site_id === Number(site.id))
    }

    return foundSite
  }, [data, site.id])

  let supplementalInfo = null
  if (potentialSite) {
    supplementalInfo = (
      <Box>
        <Grid2 container spacing={1}>
          <Grid2 xs={6}>
            <Typography fontWeight="fontWeightBold" variant="body2">
              Annual Emissions
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body2">
              {formatDecimal(potentialSite.base_total_emissions)} {co2eUnit}
            </Typography>
          </Grid2>
          <Grid2 alignItems="center" display="flex" xs={6}>
            <Typography fontWeight="fontWeightBold" variant="body2">
              Portfolio Impact
            </Typography>
            <Tooltip
              arrow
              placement="bottom"
              title="This represents the percentage contribution of this site to your entire portfolio's emissions."
            >
              <IconButton
                aria-label="information"
                color="neutral"
                role="presentation"
                size="small"
              >
                <InfoOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid2>
          <Grid2 alignItems="center" display="flex" xs={6}>
            <Typography variant="body2">
              {formatPercentage(potentialSite.portfolio_impact)}
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography fontWeight="fontWeightBold" variant="body2">
              Annual Volume
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body2">
              {formatDecimal(potentialSite.base_total_usage)} {gjSqFtUnit}
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography fontWeight="fontWeightBold" variant="body2">
              EUI
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body2">
              {potentialSite.eui ? (
                <>
                  {formatDecimal(potentialSite.eui)} {gjSqFtUnit}
                </>
              ) : (
                emptyDataPlaceholder
              )}
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography fontWeight="fontWeightBold" variant="body2">
              Operational Cost
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body2">
              {formatMoney(potentialSite.total_cost)}
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    )
  }

  return (
    <Drawer
      anchor="right"
      elevation={1}
      open={open}
      PaperProps={{ elevation: 1 }}
      sx={{
        width: drawerWidth,
        flexShrink: 1,
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          position: "relative",
          zIndex: 0,
          display: open ? "block" : "none",
        },
      }}
      variant="persistent"
    >
      <SiteInfoContent
        onClose={onClose}
        site={site}
        supplementalInfo={supplementalInfo}
      />
    </Drawer>
  )
}
