import { upperFirst } from "lodash-es"

import {
  queryAttributePropNames,
  queryFunctionPropNames,
  queryStatusPropNames,
} from "../../types"
import type {
  RenamedQueryAttributeProps,
  RenamedQueryFunctionProps,
  RenamedQueryProps,
  RenamedQueryStatusProps,
  ServiceName,
} from "../../types"

/**
 * Creates an object of React Query based status names customized to the entity and action.
 * @param serviceName The name of the service.
 * @param serviceAction The action of the service.
 * @param inputObject The input object to be remapped.
 * @returns The remapped object.
 * @example
 * createQueryOutputStatus("test", "updateOne", {
 *  isIdle: false,
 * isLoading: false,
 * isSuccess: true,
 * isError: false,
 * })
 */
export const createRenamedQueryProps = <NameT extends ServiceName>(
  serviceName: NameT,
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputObject: any
): RenamedQueryProps<NameT> => {
  const attributeProps = Object.fromEntries(
    queryAttributePropNames.map((statusKey) => [
      `${serviceName}${upperFirst(statusKey)}`,
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      inputObject[statusKey],
    ])
  ) as RenamedQueryAttributeProps<NameT>

  const functionProps = Object.fromEntries(
    queryFunctionPropNames.map((statusKey) => [
      `${statusKey}${upperFirst(serviceName)}`,
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      inputObject[statusKey],
    ])
  ) as RenamedQueryFunctionProps<NameT>

  const statusProps = Object.fromEntries(
    queryStatusPropNames.map((statusKey) => [
      `is${upperFirst(serviceName)}${upperFirst(statusKey)}`,
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      inputObject[`is${upperFirst(statusKey)}`],
    ])
  ) as RenamedQueryStatusProps<NameT>

  return {
    ...attributeProps,
    ...functionProps,
    ...statusProps,
  }
}
