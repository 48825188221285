import React from "react"
import { useTranslation } from "react-i18next"

import { Box, Divider, Typography } from "@mui/material"

import { FONT_WEIGHT } from "../../../models/typography"
import type { UnitName } from "../../../models/unit"
import { gray50, gray100 } from "../../../utils/colors"
import { pxToRem, translateUnit } from "../../../utils/unit"
import type { RowItem } from "../types"
import LegendRow from "./legendRow/legendRow"
import LegendRows from "./legendRows/legendRows"

interface LegendProps {
  items: RowItem[]
  itemsTitle: string
  unit: UnitName
}

const Legend = ({ items, itemsTitle, unit }: LegendProps): JSX.Element => {
  const { i18n } = useTranslation()
  const total: number | null = items?.every(
    (item) => !Number.isFinite(item.value)
  )
    ? null
    : items.reduce((acc, item) => acc + item.value, 0)

  return (
    <Box
      sx={{
        border: `1px solid ${gray100.toString()}`,
        height: "fit-content",
        padding: `${pxToRem(16)} 0 ${pxToRem(4)}`,
        minWidth: pxToRem(188),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: pxToRem(12),
          padding: `0 ${pxToRem(16)}`,
          whiteSpace: "nowrap",
        }}
      >
        <Typography fontWeight={FONT_WEIGHT.bold}>{itemsTitle}</Typography>
        <Typography fontWeight={FONT_WEIGHT.bold}>
          {translateUnit(i18n.language, unit)}
        </Typography>
      </Box>
      <Divider
        sx={{
          borderColor: gray50.toString(),
          marginBottom: pxToRem(1),
        }}
      />
      <LegendRows rows={items} />
      <Divider
        sx={{
          borderColor: gray50.toString(),
          marginBottom: pxToRem(1),
        }}
      />
      <LegendRow rowItem={{ label: "Total", value: total }} />
    </Box>
  )
}

export default Legend
