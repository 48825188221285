import type { Moment } from "moment"

import type { IRange } from "../../../models/range"

export const getGraphExportFileName = (
  graphTitle: string,
  dateRange: IRange<Moment> | null | undefined
): string =>
  `${graphTitle} ${dateRange?.start?.format(
    "YYYY-MM"
  )} through ${dateRange?.end?.format("YYYY-MM")}`
