import React from "react"
import type { FC } from "react"

import { useFormatDecimal } from "@/formatters/useFormatDecimal"

import type { ComplianceEmissionFactorValueProps } from "./compliance-emission-factor-value.types"

export const ComplianceEmissionFactorValue: FC<
  ComplianceEmissionFactorValueProps
> = ({ emissionFactorType, regulations }) => {
  const { format: formatDecimalToTenThousandths } = useFormatDecimal({
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  })

  return !regulations?.length ? (
    <>--</>
  ) : (
    <>
      {regulations.map((regulation, index) => (
        <span
          key={`${regulation.regulation_name}-${regulation.effective_date}`}
        >
          {index > 0 && ", "}
          {formatDecimalToTenThousandths(
            regulation.emission_factors?.[`${emissionFactorType}_ef`]
          )}
        </span>
      ))}
    </>
  )
}
