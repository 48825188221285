import moment from "moment"

import { fetchInPages } from "../../../../models/monthSummary"
import type { ScopeEmissionBase } from "../../../../models/scope"
import { poundsToMetricTon } from "../../../../utils"

interface FetchScopeTwoEmissionsProps {
  endDate: string
  organizationId: string
  startDate: string
}

export interface FetchScopeTwoEmissionsValue extends ScopeEmissionBase {
  costMajor: number
  meteredKwh: number
}

export const fetchScopeTwoEmissions = async ({
  organizationId,
  startDate,
  endDate,
}: FetchScopeTwoEmissionsProps): Promise<FetchScopeTwoEmissionsValue[]> =>
  fetchInPages({
    clause: {
      startDate,
      endDate,
      organizationId,
    },
  }).then((monthSummaries) =>
    monthSummaries.data.map((monthSummary) => {
      const {
        co2eLbs,
        meteredKwh,
        startDate: monthSummaryStartDate,
        costDollars,
      } = monthSummary.typedAttributes
      return {
        costMajor: Number(costDollars),
        month: moment(monthSummaryStartDate).month() + 1,
        mtCo2E: poundsToMetricTon(Number(co2eLbs)),
        meteredKwh: Number(meteredKwh),
        year: moment(monthSummaryStartDate).year(),
      }
    })
  )
