import { Attr, Model } from "spraypaint"

import { snakeCaseToCamelCase } from "../utils/formatters"
import ApplicationRecord from "./applicationRecord/applicationRecord"
import type { IRange } from "./range"

@Model()
export default class ReportingData extends ApplicationRecord {
  public static jsonapiType = "reporting_datas"

  @Attr() public startDate: moment.Moment

  @Attr() public endDate: moment.Moment
}

export const fetchMinMaxDates = (
  orgId: string | string[]
): Promise<IRange<string>> =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  ReportingData.where({
    organization_id: orgId,
  })
    .stats({
      min_max_months: "calculate",
    })
    .all()
    .then((result) =>
      Promise.resolve(
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        snakeCaseToCamelCase(result.meta.stats.min_max_months.calculate)
      )
    )
