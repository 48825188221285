import type { IFilter } from "./filter"

/**
 * A filter for one string
 *
 * @param value - The string
 * @returns The filter instance
 * @example
 * new SingleSelectStringFilter()
 * new SingleSelectStringFilter("1")
 * SingleSelectStringFilter.fromQueryParamValue()
 * SingleSelectStringFilter.fromQueryParamValue("")
 * SingleSelectStringFilter.fromQueryParamValue("1")
 */
export default class SingleSelectStringFilter
  implements IFilter<SingleSelectStringFilter, string>
{
  value: string | null

  constructor(value?: string) {
    // Take only the first comma separated value
    this.value = typeof value === "string" ? value.split(",")[0] : null
  }

  isEqualTo(filter: SingleSelectStringFilter) {
    return this.value === filter.value
  }

  toQueryParamValue() {
    return this.value ?? ""
  }

  static fromQueryParamValue(
    queryParamValue: string | undefined
  ): SingleSelectStringFilter {
    return new SingleSelectStringFilter(queryParamValue)
  }
}
