// @ts-strict-ignore
import type { MouseEvent } from "react"
import React, { useCallback, useEffect, useRef, useState } from "react"

import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded"
import { Box, Button } from "@mui/material"

import { SettingsPopup } from "../../../../../../components/settingsPopup"
import type { UnitName } from "../../../../../../models/unit"

interface ElectricitySettingsProps {
  onEnergyUsageUnitChange: (
    wu: UnitName.KilowattHour | UnitName.MegawattHour
  ) => void
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
}

interface SettingsPopupLocation {
  left: number
  top: number
}

const ElectricitySettings = ({
  wattUnit,
  onEnergyUsageUnitChange,
}: ElectricitySettingsProps): JSX.Element => {
  const [settingsPopupLocation, setSettingsPopupLocation] =
    useState<SettingsPopupLocation>({
      left: 0,
      top: 0,
    })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const wrapperRef = useRef(null)

  const handleClickOutside = (event) => {
    // Mass lint disable
    // Mass eslint disable
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-condition
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false)
    return () => {
      document.removeEventListener("click", handleClickOutside, false)
    }
  }, [])

  const togglePopup = (e: MouseEvent) => {
    const currentTarget = e.currentTarget as HTMLElement

    if (!isOpen) {
      setSettingsPopupLocation({
        left: currentTarget.offsetLeft,
        top: currentTarget.offsetTop,
      })
    }
    setIsOpen(!isOpen)
  }

  const recomputeLocation = useCallback(() => {
    const settingsToggle = document.getElementById("settingsToggle")
    if (!settingsToggle) {
      return
    }
    const boundingRect = settingsToggle.getBoundingClientRect()
    setSettingsPopupLocation({
      left: boundingRect.left,
      top: boundingRect.top,
    })
  }, [])

  return (
    <Box ref={wrapperRef}>
      <Button
        color="neutral"
        id="settingsToggle"
        onClick={(e) => {
          togglePopup(e)
        }}
        size="small"
        startIcon={<SettingsRoundedIcon />}
        variant="outlined"
      >
        Settings
      </Button>
      {isOpen && (
        <SettingsPopup
          {...{
            closePopup: () => {
              setIsOpen(false)
            },
            location: settingsPopupLocation,
            recomputeLocation,
            setWattUnit: onEnergyUsageUnitChange,
            wattUnit,
          }}
        />
      )}
    </Box>
  )
}

export default ElectricitySettings
