import { useMutation } from "react-query"

import { createQueryKey } from "@/services/utils/createQueryKey"
import { useInvalidateQueries } from "@/services/utils/useInvalidateQueries"
import { useQueryKey } from "@/services/utils/useQueryKey"

import { ApiQueryName } from "../../../../models/api"
import type Site from "../../../../models/site"

/**
 * Hook that exposes a mutation function for updating multiple sites at once.
 * This ensures that queries for sites and organizational structure are not
 * invalidated until all sites have been updated.
 */
export const useBatchUpdateSites = () => {
  const mutationKey = useQueryKey(ApiQueryName.Site, "updateOne")
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({ sites }: { sites: Site[] }) => {
      const promises: Promise<boolean>[] = []

      sites.forEach((site) => {
        promises.push(
          site.save({
            with: ["organizationalUnit"],
          })
        )
      })

      await Promise.all(promises).catch((e) => {
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        console.error(e.response)
      })
    },
    onSuccess: () => {
      invalidateQueries([createQueryKey(ApiQueryName.Sites, "getMany")])
    },
  })

  return mutationResult
}
