import React from "react"

import type { Moment } from "moment"

import type Organization from "../../models/organization"
import type { ISitePreview } from "../../models/site"
import type { UnitName } from "../../models/unit"
import type {
  IDepartmentSummary,
  OrganizationChildrenSummary,
} from "../../modules/dashboard/services/useOrgSummaryStats/useOrgSummaryStatsUtils"
import { FeatureFlags, useFeature } from "../../services"
import { CollapsibleGroup } from "./collapsibleGroup"

/**
 * @deprecated This UI is slated to be replaced and this component will be deleted
 */
export const GroupViews = ({
  dateRange,
  groupsSummaryData,
  hasBundled,
  organization,
  sitePreviews,
  whDisplayUnit,
}: {
  dateRange: Moment[]
  groupsSummaryData: IDepartmentSummary[] | OrganizationChildrenSummary[]
  hasBundled: boolean
  organization: Organization
  sitePreviews: ISitePreview[]
  whDisplayUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const { isFeatureEnabled } = useFeature()
  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )
  // This is a hack in order to reduce based on the data we receive
  let groupsData

  if (isUseFlexibleHierarchyEnabled) {
    groupsData = groupsSummaryData as OrganizationChildrenSummary[]
  } else {
    groupsData = groupsSummaryData as IDepartmentSummary[]
  }

  // migration to strict mode batch disable
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const groupAggCost: Map<string, number> | undefined = groupsData.reduce(
    (agg, group) => {
      if (isUseFlexibleHierarchyEnabled) {
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { organizationalUnitId } = group

        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (!agg.has(organizationalUnitId)) {
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          agg.set(organizationalUnitId, group.costMajor)
        } else {
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          agg.set(
            organizationalUnitId,
            // Mass lint disable
            // Mass eslint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            agg.get(organizationalUnitId) + group.costMajor
          )
        }
      } else {
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { departmentId } = group
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (!agg.has(departmentId)) {
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          agg.set(departmentId, group.costMajor)
        } else {
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          agg.set(departmentId, agg.get(departmentId) + group.costMajor)
        }
      }

      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return agg
    },
    new Map()
  )

  const sortedGroupCost = groupAggCost
    ? [...groupAggCost.entries()].sort((a, b) => b[1] - a[1])
    : []

  return (
    <>
      {sortedGroupCost.map(([groupId]) => (
        <CollapsibleGroup
          key={groupId}
          dateRange={dateRange}
          groupId={groupId}
          groupName={
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            isUseFlexibleHierarchyEnabled
              ? // Mass lint disable
                // Mass eslint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                groupsData.find(
                  (group) =>
                    // Mass lint disable
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    group.organizationalUnitId === Number.parseInt(groupId, 10)
                )?.organizationalUnitName
              : // Mass lint disable
                // Mass eslint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                groupsData.find(
                  // Mass lint disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  (group) => group.departmentId === Number.parseInt(groupId, 10)
                )?.departmentName
          }
          groupsSummaryData={groupsSummaryData}
          hasBundled={hasBundled}
          organization={organization}
          sitePreviews={sitePreviews}
          whDisplayUnit={whDisplayUnit}
        />
      ))}
    </>
  )
}
