// @ts-strict-ignore
import type { Option } from "../../../../nzds"
import { useGetInterventions } from "./use-get-interventions"

export const useInterventionOptions = () => {
  const { data = [] } = useGetInterventions()
  const options: Option[] = []
  for (const interventionGroupName of Object.keys(data)) {
    for (const interventionName of data[interventionGroupName]) {
      options.push({
        label: interventionName as string,
        value: interventionName as string,
      })
    }
  }
  options.sort((a, b) => {
    const labelA = a.label.toUpperCase()
    const labelB = b.label.toUpperCase()
    if (labelA < labelB) {
      return -1
    }
    if (labelA > labelB) {
      return 1
    }

    return 0
  })

  return options
}
