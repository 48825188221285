import { useMemo } from "react"
import type { InfiniteData } from "react-query"

import type { Schemas } from "@/services/types"

import type { RowData } from "../../pages.types"

export function useComputedRows(
  data?: InfiniteData<Schemas["EmissionProfilePage"]>
) {
  const computedRows = useMemo(() => {
    const pagesMap: Record<string, RowData[]> = {}
    if (data && data.pages.length > 0) {
      for (const page of data.pages) {
        const rows: RowData[] = []
        page.items.forEach((item, index) => {
          rows.push({
            ...item,
            page: page.page,
            pageSize: page.size,
            pageIndex: index,
            total: page.total,
          })
        })
        if (page.page) {
          pagesMap[page.page] = rows
        }
      }
      // return the rows in order
      const orderedRows: RowData[] = []
      for (const key of Object.keys(pagesMap).sort((a, b) =>
        a.localeCompare(b)
      )) {
        orderedRows.push(...pagesMap[key])
      }

      return orderedRows
    }
    return []
  }, [data])

  return computedRows
}
