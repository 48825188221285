import { getPseudoEndValue } from "@/services/utils/utils"
import moment from "moment"

import type {
  MonthlyAreaGraphDatum,
  MonthlyEmissionsGraphValues,
} from "../../../../components/graph/types"
import { LBS_PER_METRIC_TON } from "../../../../utils/constants"
import type { ResourceSummaryMonthRecord } from "../../../report/models/resourceSummary"

type MonthlyEmissionsGraphDatum = MonthlyEmissionsGraphValues &
  MonthlyAreaGraphDatum

interface MonthlySiteEmissionsGraphData {
  data: MonthlyEmissionsGraphDatum[]
  totals: MonthlyEmissionsGraphValues
}

const getMonthlySiteEmissionsTotals = (
  monthlyEmissionsGraphData: MonthlyEmissionsGraphDatum[]
): MonthlyEmissionsGraphValues =>
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  monthlyEmissionsGraphData?.reduce(
    (acc, graphDatum) => {
      acc.electricity += graphDatum.electricity
      acc.naturalGas += graphDatum.naturalGas
      return acc
    },
    { electricity: 0, naturalGas: 0 }
  )

/**
 * @param monthlyDataBySite - A collection data with electricity and natural gas results
 * @param siteId - The site id to filter the data by
 * @example
 *  const monthlyEmissionsGraphData = getMonthlySiteEmissionsGraphData(
      [
        {
          siteId: "1",
          year: 2021,
          month: 1,
          advancedGridStudyCo2ELbs: 3,
          naturalGasCo2ELbs: 4,
        },
        {
          siteId: "2",
          year: 2021,
          month: 1,
          advancedGridStudyCo2ELbs: 1,
          naturalGasCo2ELbs: 2,
        }
      ],
    )
 */
const getMonthlySiteEmissionsGraphData = (
  monthlyDataBySite: ResourceSummaryMonthRecord[],
  siteId: string
): MonthlySiteEmissionsGraphData => {
  const monthlyDataForSite = monthlyDataBySite
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ?.filter((record) => record.siteId === siteId)
    .map((record, i) => ({
      date: moment([record.year, record.month - 1]).format("YYYY-MM-DD"),
      index: i,
      electricity: record.advancedGridStudyCo2ELbs / LBS_PER_METRIC_TON,
      naturalGas: record.naturalGasCo2ELbs / LBS_PER_METRIC_TON,
    }))

  // totaled before the duplicate data point is potentially added
  const monthlySiteEmissionsTotals =
    getMonthlySiteEmissionsTotals(monthlyDataForSite)

  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (monthlyDataForSite?.length === 1) {
    // This is needed for the graph to display correctly.
    // If there is only one month, a duplicate is created
    // with an end date set to the end of the month
    const duplicatedRecord: MonthlyEmissionsGraphDatum = getPseudoEndValue(
      monthlyDataForSite[0]
    ) as MonthlyEmissionsGraphDatum
    monthlyDataForSite.push(duplicatedRecord)
  }

  return {
    data: monthlyDataForSite,
    totals: monthlySiteEmissionsTotals,
  }
}

export { getMonthlySiteEmissionsGraphData }
