import { LanguageRegion } from "./i18n"

export enum CurrencyCode {
  CAD = "CAD",
  CHF = "CHF",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  NOK = "NOK",
  SEK = "SEK",
  SGD = "SGD",
  USD = "USD",
}

export const defaultCurrencyCode: CurrencyCode = CurrencyCode.USD

// Orgs do not yet have a default currency (e.g. organization.currency)
// This maps a locale (e.g. organization.locale) to a currency
// so we can get a org's default currency
export const LanguageCurrencyMap = new Map<LanguageRegion, CurrencyCode>([
  [LanguageRegion.DanishDenmark, CurrencyCode.DKK],
  [LanguageRegion.DutchNetherlands, CurrencyCode.EUR],
  [LanguageRegion.EnglishCanada, CurrencyCode.CAD],
  [LanguageRegion.EnglishGreatBritain, CurrencyCode.GBP],
  [LanguageRegion.EnglishIreland, CurrencyCode.EUR],
  [LanguageRegion.EnglishSingapore, CurrencyCode.SGD],
  [LanguageRegion.EnglishUnitedStates, CurrencyCode.USD],
  [LanguageRegion.FrenchBelgium, CurrencyCode.EUR],
  [LanguageRegion.FrenchFrance, CurrencyCode.EUR],
  [LanguageRegion.FrenchLuxembourg, CurrencyCode.EUR],
  [LanguageRegion.FrenchSwitzerland, CurrencyCode.CHF],
  [LanguageRegion.GermanGermany, CurrencyCode.EUR],
  [LanguageRegion.GermanLuxembourg, CurrencyCode.EUR],
  [LanguageRegion.GermanSwitzerland, CurrencyCode.CHF],
  [LanguageRegion.ItalianItaly, CurrencyCode.EUR],
  [LanguageRegion.ItalianSwitzerland, CurrencyCode.CHF],
  [LanguageRegion.NorwegianNorway, CurrencyCode.NOK],
  [LanguageRegion.SpanishSpain, CurrencyCode.EUR],
  [LanguageRegion.SwedishSweden, CurrencyCode.SEK],
])
