import { useMemo } from "react"

import type {
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"

import type { UseGridColumnVisibilityModelProps } from "./use-grid-column-visibility-model.types"

/**
 * Returns the new column visibility model by taking into account removed columns
 *
 * @returns The new column visibility model
 * @example
 * const columnVisibilityModel = useGridColumnVisibilityModel({
 *   savedColumnVisibilityModel,
 *   validColumns,
 * })
 */
export const useGridColumnVisibilityModel = <RowT extends GridValidRowModel>({
  savedColumnVisibilityModel,
  validColumns,
}: UseGridColumnVisibilityModelProps<RowT>): GridColumnVisibilityModel => {
  // compute new column visibility model by taking into account removed columns
  const newColumnVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    const updatedColumnVisibilityModel = { ...savedColumnVisibilityModel }

    // if columns have been removed after a deployment
    // remove them from the visibility model
    Object.keys(savedColumnVisibilityModel).forEach((columnKey) => {
      const isRemovedColumn = !validColumns.find(
        (validColumn) => validColumn.field === columnKey
      )

      if (isRemovedColumn) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete updatedColumnVisibilityModel[columnKey]
      }
    })

    return updatedColumnVisibilityModel
  }, [savedColumnVisibilityModel, validColumns])

  return newColumnVisibilityModel
}
