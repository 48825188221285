import React from "react"

import type { IDecimalFormat } from "../decimal/decimal"
import type { IDecimalWithPlaceholderProps } from "../decimalWithPlaceholder/decimalWithPlaceholder"
import { DecimalWithPlaceholder } from "../decimalWithPlaceholder/decimalWithPlaceholder"

type IUsageRateDecimalProps = Omit<
  IDecimalWithPlaceholderProps,
  "format" | "replaceZeroWithPlaceholder" | "useAccountingFormatWhenNegative"
>

/**
 * A component that properly formats and internationalizes the provided decimal for displaying a usage rate
 *
 * @param props - The component properties
 * @param children - The children
 * @param props.value - The decimal
 * @param props.placeholder - The placeholder to render instead of the decimal
 * @param props.unit - The unit to display
 * @example
 * return (
 *   <UsageRateDecimal value={waterUse} placeholder="0.000" />
 * )
 */
export const UsageRateDecimal = ({
  value,
  placeholder,
  unit,
}: IUsageRateDecimalProps) => {
  let format: IDecimalFormat

  if (Number(value) < 1) {
    format = { maximumFractionDigits: 6, maximumSignificantDigits: 4 }
  } else {
    format = { precision: 2 }
  }

  return (
    <DecimalWithPlaceholder
      format={format}
      placeholder={placeholder}
      replaceZeroWithPlaceholder
      unit={unit}
      value={value}
    />
  )
}
