import type { ReactNode } from "react"
import React from "react"

import { LoadingSpinner } from "../../loadingSpinner"
import { If } from "../if"

interface IProvideChildrenUnlessLoadingProps {
  children: ReactNode
  loading: boolean
  loadingComponent?: ReactNode
  loadingText?: string
}

export const ProvideChildrenUnlessLoading = (
  props: IProvideChildrenUnlessLoadingProps
) => (
  <>
    <If condition={props.loading}>
      <If condition={props.loadingComponent}>{props.loadingComponent}</If>
      <If condition={!props.loadingComponent}>
        <LoadingSpinner>{props.loadingText}</LoadingSpinner>
      </If>
    </If>
    <If condition={!props.loading}>{props.children}</If>
  </>
)
