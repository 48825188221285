import type { Moment } from "moment"

import type { IRange } from "../../../../models/range"
import ReportingData from "../../../../models/reportingData"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"
import type {
  Gri302,
  Gri302Dto,
  Gri303,
  Gri303Dto,
  Gri305,
  Gri305Dto,
  Gri306,
  Gri306Dto,
  GriData,
  GriDataDto,
} from "../../models/gri"
import { GriEntitySourceName } from "../../models/gri"

export const getGri302FromDtos = (energyDtos: Gri302Dto[]): Gri302[] =>
  energyDtos.map((dto) => ({
    disclosure: dto.disclosure,
    electricityConsumptionGj: Number.parseFloat(dto.electricityConsumptionGj),
    heatingConsumptionGj: Number.parseFloat(dto.heatingConsumptionGj),
    nonRenewableFuelConsumptionGj: Number.parseFloat(
      dto.nonRenewableFuelConsumptionGj
    ),
    renewableFuelConsumptionGj: Number.parseFloat(
      dto.renewableFuelConsumptionGj
    ),
    source: GriEntitySourceName.Gri302,
    totalEnergyConsumptionGj: Number.parseFloat(dto.totalEnergyConsumptionGj),
  }))

export const getGri303FromDtos = (dtos: Gri303Dto[]): Gri303[] =>
  dtos.map((dto) => ({
    disclosure: dto.disclosure,
    source: GriEntitySourceName.Gri303,
    waterConsumptionMl: Number.parseFloat(dto.waterConsumptionMl),
  }))

export const getGri305FromDtos = (emissionDtos: Gri305Dto[]): Gri305[] =>
  emissionDtos.map((dto) => ({
    disclosure: dto.disclosure,
    gases: "CO2, CH4, N20",
    grossScope1MtCo2E: Number.parseFloat(dto.grossScope1MtCo2E),
    grossScope2AdvancedGridStudyMtCo2E: Number.parseFloat(
      dto.grossScope2AdvancedGridStudyMtCo2E
    ),
    grossScope2LocationBasedMtCo2E: Number.parseFloat(
      dto.grossScope2LocationBasedMtCo2E
    ),
    grossScope2MarketBasedMtCo2E: Number.parseFloat(
      dto.grossScope2MarketBasedMtCo2E
    ),
    grossScope3MtCo2E: Number.parseFloat(dto.grossScope3MtCo2E),
    scope3Category1MtCo2E: Number.parseFloat(dto.scope3Category1MtCo2E),
    scope3Category2MtCo2E: Number.parseFloat(dto.scope3Category2MtCo2E),
    scope3Category3MtCo2E: Number.parseFloat(dto.scope3Category3MtCo2E),
    scope3Category4MtCo2E: Number.parseFloat(dto.scope3Category4MtCo2E),
    scope3Category5MtCo2E: Number.parseFloat(dto.scope3Category5MtCo2E),
    scope3Category6MtCo2E: Number.parseFloat(dto.scope3Category6MtCo2E),
    scope3Category7MtCo2E: Number.parseFloat(dto.scope3Category7MtCo2E),
    scope3Category8MtCo2E: Number.parseFloat(dto.scope3Category8MtCo2E),
    scope3Category9MtCo2E: Number.parseFloat(dto.scope3Category9MtCo2E),
    scope3Category10MtCo2E: Number.parseFloat(dto.scope3Category10MtCo2E),
    scope3Category11MtCo2E: Number.parseFloat(dto.scope3Category11MtCo2E),
    scope3Category12MtCo2E: Number.parseFloat(dto.scope3Category12MtCo2E),
    scope3Category13MtCo2E: Number.parseFloat(dto.scope3Category13MtCo2E),
    scope3Category14MtCo2E: Number.parseFloat(dto.scope3Category14MtCo2E),
    scope3Category15MtCo2E: Number.parseFloat(dto.scope3Category15MtCo2E),
    source: GriEntitySourceName.Gri305,
  }))

export const getGri306FromDtos = (dtos: Gri306Dto[]): Gri306[] =>
  dtos.map((dto) => ({
    disclosure: dto.disclosure,
    source: GriEntitySourceName.Gri306,
    wasteWeightMt: Number.parseFloat(dto.wasteWeightMt),
  }))

/**
 * Fetches GRI report data for the given organization and timeframe
 *
 * @param orgId - The organization id
 * @param dateRange - The date range for the report data
 * @returns The report data
 * @example
 * fetchGriReport("1", {
 *   start: moment("2022-01-01"),
 *   end: moment("2022-02-28"),
 * })
 */
export const fetchGriReport = async (
  orgId: string,
  dateRange: IRange<Moment>
): Promise<GriData> => {
  const griData = await ReportingData.where({
    organization_id: Number.parseInt(orgId, 10),
    year: dateRange.start.year(),
    start_month: dateRange.start.month() + 1,
    end_month: dateRange.end.month() + 1,
  })
    .stats({
      gri_service: "calculate",
    })
    .all()
    .then((response) => {
      const reportDataDto = snakeCaseToCamelCase(
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        response.meta.stats.gri_service.calculate
      ) as GriDataDto

      const reportData: GriData = {
        gri302: getGri302FromDtos(reportDataDto.gri302Energy),
        gri303: getGri303FromDtos(reportDataDto.gri303Water),
        gri305: getGri305FromDtos(reportDataDto.gri305Emissions),
        gri306: getGri306FromDtos(reportDataDto.gri306Waste),
      }

      return reportData
    })

  return griData
}

export default {
  getGri302FromDto: getGri302FromDtos,
  getGri303FromDto: getGri303FromDtos,
  getGri305FromDto: getGri305FromDtos,
  getGri306FromDto: getGri306FromDtos,
  fetchGriReport,
}
