import type OrganizationalUnit from "../../../../models/organizationalUnit"
import type Site from "../../../../models/site"
import type { HierarchyDataGridRow, OUTreeNode } from "./types"

/**
 * Recursively flattens an organizational hierarchy into a single array of OUTreeNodes.
 * Doing this as a first step makes it easier to assign sites to their respescitve parent OUs and build out the tree structure.
 * The root OU is excluded from the returned array as we do not need to display it in the tree.
 * @param rootOU
 * @returns A flattened array of OUTreeNodes, without the root OU
 */
export const flattenOrganizationalUnits = (
  rootOU: OUTreeNode
): OUTreeNode[] => {
  const orgUnits: OUTreeNode[] = []

  const flattenChildren = (ou: OUTreeNode) => {
    if (ou?.children) {
      ou.children.forEach((child) => {
        if (child.type !== "site") {
          child.type = "ou"
        }
        orgUnits.push(child)
        flattenChildren(child)
      })
    }
  }

  flattenChildren(rootOU)

  return orgUnits.sort((a, b) => a.id - b.id)
}

/**
 * Converts an array of Site models into an array of OUTreeNodes.
 * This allows us to build a uniform tree structure with both OUs and Sites prior to
 * generating the `path` property needed for the MUI Tree data grid.
 * @param sites
 * @returns An array of OUTreeNodes
 */
export const convertSitesToTreeNodes = (sites: Site[]) => {
  const siteNodes: OUTreeNode[] = []
  sites?.forEach((site) => {
    const siteNode: OUTreeNode = {
      id: Number(site.id),
      name: site.name,
      type: "site",
      parent_id: site.organizationalUnitId,
      children: [],
    }
    siteNodes.push(siteNode)
  })
  return siteNodes
}

/**
 * Takes in the flattened organizational hierarchy with OUs and Sites and builds out the rows needed
 * for the MUI Tree data grid. The `path` property is generated by traversing each node of the tree.
 * The approriate spraypaint models are assigned to each row so that we can easily modify data within the tree.
 * @param treeNodes
 * @param orgId
 * @param orgUnits
 * @param sites
 * @returns An array of rows used to populate our `<OrganizeDataGrid />` component.
 */
export const generateDataGridRows = (
  treeNodes: OUTreeNode[],
  orgId: string,
  orgUnits: OrganizationalUnit[],
  sites: Site[]
) => {
  if (!orgUnits || !sites) {
    return []
  }

  const rows: HierarchyDataGridRow[] = []

  const buildRow = (node: OUTreeNode, path: string[] = []) => {
    const { id, name, type, children, parent_id } = node
    const newPath = [...path, `${node.type}-${node.id}`]
    const model =
      type === "ou"
        ? orgUnits.find((ou) => ou.id === id.toString())
        : sites.find((site) => site.id === id.toString())

    const row = {
      id,
      orgId,
      type,
      name,
      path: newPath,
      model,
      parentId: parent_id,
      children,
    }

    if (children) {
      children.forEach((child) => {
        buildRow(child, newPath)
      })
    }

    if (!rows.find((r) => r.id === row.id)) {
      rows.push(row)
    }
  }

  treeNodes.forEach((node) => {
    buildRow(node)
  })

  return rows
}
