import React from "react"

import SquareFootRoundedIcon from "@mui/icons-material/SquareFootRounded"
import TodayRoundedIcon from "@mui/icons-material/TodayRounded"
import { Grid, Stack, Typography } from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../../models/typography"
import { lightGray } from "../../../../../utils/colors"
import type {
  IFetchEmissionsAndEnergyVolume,
  IFetchEnergyAllocationStats,
} from "../../../../../utils/spraypaintApi"
import { pxToRem } from "../../../../../utils/unit"
import BuildingMetrics from "./buildingMetrics/buildingMetrics"
import CarbonMetrics from "./carbonMetrics/carbonMetrics"

interface SitePerformanceProps {
  currentYear: number
  emissionsAndEnergyVolume: IFetchEmissionsAndEnergyVolume
  gridRate: number
  isBundled: boolean
  isMwhSelected: boolean
  siteEnergyAllocationStats: IFetchEnergyAllocationStats
  siteIsAmi: boolean
  squareFoot: number | null
  ytdMeteredKwh: number
}

const SitePerformance = ({
  siteEnergyAllocationStats,
  squareFoot,
  isMwhSelected,
  currentYear,
  isBundled,
  siteIsAmi,
  gridRate,
  emissionsAndEnergyVolume,
  ytdMeteredKwh,
}: SitePerformanceProps): JSX.Element => {
  const isBundledAmi = isBundled && siteIsAmi
  const isDirect = !isBundled && siteIsAmi
  return (
    <Stack
      sx={[
        (isBundledAmi || isDirect) && {
          borderBottom: `1px solid ${lightGray.toString()}`,
          pb: 2,
        },
        {
          pt: 2,
          gap: pxToRem(16),
        },
      ]}
    >
      <Typography variant={TYPOGRAPHY_VARIANT.h2}>Performance</Typography>
      <Grid container>
        {isBundledAmi && (
          <Grid
            item
            sx={{ borderRight: `1px solid ${lightGray.toString()}`, pr: 2 }}
            xs={6}
          >
            <Stack alignItems="center" direction="row" mb={1} spacing={1}>
              <SquareFootRoundedIcon />
              <Typography variant={TYPOGRAPHY_VARIANT.h3}>
                Building Size
              </Typography>
            </Stack>
            <BuildingMetrics
              isMwhSelected={isMwhSelected}
              siteEnergyAllocationStats={siteEnergyAllocationStats}
              squareFoot={squareFoot}
            />
          </Grid>
        )}
        <Grid item sx={[isBundledAmi && { pl: 2 }]} xs={isBundledAmi ? 6 : 12}>
          <Stack alignItems="center" direction="row" mb={1} spacing={1}>
            <TodayRoundedIcon />
            <Typography variant={TYPOGRAPHY_VARIANT.h3}>
              {currentYear} (YTD)
            </Typography>
          </Stack>
          <CarbonMetrics
            emissionsAndEnergyVolume={emissionsAndEnergyVolume}
            gridRate={gridRate}
            isBundledAmi={isBundledAmi}
            ytdMeteredKwh={ytdMeteredKwh}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}

export default SitePerformance
