import type { FC } from "react"
import React from "react"
import { Link } from "react-router-dom"

import { Step, StepButton, Stepper } from "@mui/material"

import type { StepInfo } from "../../constants"
import { planningSteps } from "../../constants"
import type { PlanningStepsProps } from "./planning-steps.types"

function isClickableStep(activeStepInfo: StepInfo, stepInfo: StepInfo) {
  return activeStepInfo.label !== stepInfo.label
}

function getStepButtonHref(
  activeStepInfo: StepInfo,
  step: StepInfo,
  data?: { planId?: string; siteId?: string }
) {
  let to = ""
  if (!isClickableStep(activeStepInfo, step)) {
    return to
  }
  if (step.label === "Set Goals" && data?.siteId) {
    to = step.getUrl(data.siteId)
  }
  if (step.label === "Select Plan" && data?.siteId) {
    to = step.getUrl(data.siteId)
  }
  if (step.label === "Select Site") {
    to = step.getUrl()
  }

  return to
}
export const PlanningSteps: FC<PlanningStepsProps> = ({
  activeStep = 0,
  sx,
  siteId,
  planId,
}) => {
  const activeStepInfo = planningSteps[activeStep]
  return (
    <Stepper activeStep={activeStep} alternativeLabel sx={sx}>
      {planningSteps.map((step) => (
        <Step key={step.label}>
          <StepButton
            component={Link}
            to={getStepButtonHref(activeStepInfo, step, { siteId, planId })}
          >
            {step.label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}
