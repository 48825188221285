import React from "react"

import { last } from "lodash-es"
import type { Moment } from "moment"

import type { IDateFormat } from "../../models/date/date"
import { darkGray } from "../../utils/colors"
import { TIME_RANGES } from "../../utils/constants"
import { getDateFormatFromTimeRange } from "../../utils/date"
import { DateDisplay } from "../date/dateDisplay/dateDisplay"
import { DateRangeDisplay } from "../date/dateRangeDisplay/dateRangeDisplay"
import { If } from "../if/if"
import type { GraphData } from "./graphData"

export const GroupLabel = ({
  graphData,
  minWidth,
  timeRange,
}: {
  graphData: GraphData[]
  minWidth: number
  timeRange: TIME_RANGES
}) => {
  const startDate: Moment = graphData[0].hour
  const endDate: Moment = last(graphData).hour
  const dateFormat: IDateFormat = getDateFormatFromTimeRange(timeRange)

  // Draw label text
  return (
    <text
      className="dashboard-data__graph-date-label"
      fill={darkGray.toString()}
      style={{ fontSize: 14, fontFamily: "Lato", fontWeight: 400 }}
      textAnchor="left"
      x={minWidth}
      y="30"
    >
      <If
        condition={
          timeRange === TIME_RANGES.DAY || timeRange === TIME_RANGES.YEAR
        }
      >
        <DateDisplay format={dateFormat} value={startDate} />
      </If>
      <If
        condition={
          timeRange === TIME_RANGES.MONTH || timeRange === TIME_RANGES.RANGE
        }
      >
        <DateRangeDisplay end={endDate} format={dateFormat} start={startDate} />
      </If>
    </text>
  )
}
