import type { FC } from "react"
import React from "react"

import { Stack, Typography } from "@mui/material"

import { formatTextToId } from "../../utils/format-text-to-id"
import type { StatCardContentProps } from "./stat-card-content.types"

export const StatCardContent: FC<StatCardContentProps> = ({ title, value }) => (
  <Stack flex="1" gap={1} minWidth="0">
    <Typography component="h3" id={title} noWrap variant="body2">
      {title}
    </Typography>
    <Typography
      component="p"
      data-testid={`${formatTextToId(title)}-value`}
      noWrap
      variant="h1"
    >
      {value}
    </Typography>
  </Stack>
)
