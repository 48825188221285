import type { UseDeleteEntityOptions } from "@/services/types"

import type { IBillingGroupCounts } from "../../../../models/billingGroup"
import type { IDepartmentCounts } from "../../../../models/department"
import type { IMeterCounts } from "../../../../models/meter"
import type { IOrganizationCounts } from "../../../../models/organization"
import { DeveloperAdminPath, RootPath } from "../../../../models/route"
import type { ISiteCounts } from "../../../../models/site"
import type { ITableColumn, ITableRow } from "../../../../models/table"
import {
  useDeleteBillingGroup,
  useDeleteDepartment,
  useDeleteMeter,
  useDeleteSite,
} from "../../../../services"
import OrgViewDialogBillingGroupCreateContent from "../../components/tamAdminOnboarding/orgView/orgViewDialog/orgViewDialogBillingGroupCreateContent/orgViewDialogBillingGroupCreateContent"
import OrgViewDialogDepartmentCreateContent from "../../components/tamAdminOnboarding/orgView/orgViewDialog/orgViewDialogDepartmentCreateContent/orgViewDialogDepartmentCreateContent"
import OrgViewDialogMeterCreateContent from "../../components/tamAdminOnboarding/orgView/orgViewDialog/orgViewDialogMeterCreateContent/orgViewDialogMeterCreateContent"
import OrgViewDialogSiteCreateContent from "../../components/tamAdminOnboarding/orgView/orgViewDialog/orgViewDialogSiteCreateContent/orgViewDialogSiteCreateContent"

export const NUMBER_OF_SKELETON_ROWS = 13

export const routeMatchPattern = "/tam-admin/onboarding/:orgId"
export const departmentRouteMatchPattern = `${routeMatchPattern}/department/:departmentId`
export const siteRouteMatchPattern = `${departmentRouteMatchPattern}/site/:siteId`
export const billingGroupRouteMatchPattern = `${siteRouteMatchPattern}/billing-group/:billingGroupId`
export const meterRouteMatchPattern = `${billingGroupRouteMatchPattern}/meter/:meterId`

// Routes for Flexible Hierarchy
export const overviewMatchPattern = "/tam-admin/onboarding/:orgId"
export const organizeMatchPattern = `${overviewMatchPattern}/organize`
export const siteListMatchPattern = `${overviewMatchPattern}/sites`
export const siteDetailMatchPattern = `${siteListMatchPattern}/:siteId`
export const billingGroupDetailMatchPattern = `${siteDetailMatchPattern}/billing-group/:billingGroupId`
export const meterDetailMatchPattern = `${billingGroupDetailMatchPattern}/meter/:meterId`

export enum LIST_ITEM_NAME_PROPERTY {
  GroupName = "groupName",
  MeterCode = "meterCode",
  Name = "name",
}

export interface IOrgViewDisplayCount<TCount> {
  href?: string
  key: keyof TCount
  name: string
  order: number
  value: number
}

export interface IOrgViewListContent {
  groupName?: string
  // id must be optional at this time because we're using Spraypaint models that have optional ids
  id?: string
  meterCode?: string
  name?: string
}

export type ParentObjectIds = Partial<
  Record<
    "billingGroupId" | "departmentId" | "organizationId" | "siteId",
    string
  >
>

export interface OrgViewDialogCreateContentProps {
  objectName: string
  onClose: () => void
  onError: (errorMessage: string) => void
  onSuccess: (navigatePath: string) => void
  parentObjectIds: ParentObjectIds
}

export enum OrgViewDialogEntity {
  BillingGroup = "Billing Group",
  Department = "Department",
  Meter = "Meter",
  Site = "Site",
}

export const METER_TYPE_OPTIONS = ["AMI", "AMR"]

export const OrgViewDialogCreateContent: Record<
  string,
  (OrgViewDialogCreateContent: OrgViewDialogCreateContentProps) => JSX.Element
> = {
  [OrgViewDialogEntity.BillingGroup]: OrgViewDialogBillingGroupCreateContent,
  [OrgViewDialogEntity.Department]: OrgViewDialogDepartmentCreateContent,
  [OrgViewDialogEntity.Site]: OrgViewDialogSiteCreateContent,
  [OrgViewDialogEntity.Meter]: OrgViewDialogMeterCreateContent,
}

// TO-DO: Update return type when we properly use useMutationReturnHandle
export type OrgViewDialogDeleteAction = ({
  onSuccess,
}: // Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
UseDeleteEntityOptions) => any

export const OrgViewDialogDeleteActions: Record<
  string,
  OrgViewDialogDeleteAction
> = {
  [OrgViewDialogEntity.BillingGroup]: useDeleteBillingGroup,
  [OrgViewDialogEntity.Department]: useDeleteDepartment,
  [OrgViewDialogEntity.Meter]: useDeleteMeter,
  [OrgViewDialogEntity.Site]: useDeleteSite,
}

/**
 * @deprecated - This will eventually be
 * deleted when orgViewDetailEntityDeleteButton is fully implemented
 */
export interface OrgViewDetailDeleteEntityProps {
  canEntityBeDeleted: boolean
  entityId: string
  isButtonDisabled: boolean
  navLink: string
  onDeleteDialogClose: () => void
  onDeleteDialogOpen: (
    title: string,
    content: JSX.Element,
    actions: JSX.Element
  ) => void
}

// TO-DO: Add direct access when it can be created
export const ELECTRICITY_TYPE_OPTIONS = [{ label: "Bundled", value: "bundled" }]

export const GRANULARITY_OPTIONS = [
  { label: "Monthly", value: "monthly" },
  { label: "Hourly", value: "hourly" },
  { label: "Mixed", value: "mixed" },
]

export const organizationDisplayCounts: IOrgViewDisplayCount<IOrganizationCounts>[] =
  [
    {
      key: "organizationalUnits",
      name: "Organizational Units",
      order: 0,
      value: NaN,
    },
    {
      href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.Department}?organization_id=`,
      key: "departments",
      name: "Departments",
      order: 0,
      value: NaN,
    },
    {
      href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.Site}?organization_id=`,
      key: "sites",
      name: "Sites",
      order: 1,
      value: NaN,
    },
    {
      key: "billingGroups",
      name: "Billing Groups",
      order: 2,
      value: NaN,
    },
    {
      key: "meters",
      name: "Meters",
      order: 3,
      value: NaN,
    },
    {
      href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.OrganizationEmission}?organization_id=`,
      key: "organizationEmissions",
      name: "Org Emissions",
      order: 4,
      value: NaN,
    },
    {
      href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.User}?organization_id=`,
      key: "energyManagers",
      name: "Energy Managers",
      order: 6,
      value: NaN,
    },
  ]

export const departmentDisplayCounts: IOrgViewDisplayCount<IDepartmentCounts>[] =
  [
    {
      href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.Site}?department_id=`,
      key: "sites",
      name: "Sites",
      order: 0,
      value: NaN,
    },
  ]

export const siteDisplayCounts: IOrgViewDisplayCount<ISiteCounts>[] = [
  {
    href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.Bill}?site_id=`,
    key: "bills",
    name: "Bills",
    order: 0,
    value: NaN,
  },
  {
    href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.SiteEmission}?site_id=`,
    key: "siteEmissions",
    name: "Site Emissions",
    order: 1,
    value: NaN,
  },
  {
    href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.BillingGroup}?site_id=`,
    key: "billingGroups",
    name: "Billing Groups",
    order: 2,
    value: NaN,
  },
]

export const billingGroupDisplayCounts: IOrgViewDisplayCount<IBillingGroupCounts>[] =
  [
    {
      href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.Meter}?billing_group_id=`,
      key: "meters",
      name: "Meters",
      order: 0,
      value: NaN,
    },
  ]

export const meterDisplayCounts: IOrgViewDisplayCount<IMeterCounts>[] = [
  {
    href: `${RootPath.DeveloperAdmin}${DeveloperAdminPath.MeterHour}?meter_id=`,
    key: "meterHours",
    name: "Meter Hours",
    order: 0,
    value: NaN,
  },
]

export enum TamAdminOrganizationsColumnKey {
  DateCreated = "dateCreated",
  DateUpdated = "dateUpdated",
  DemoOrganization = "demoOrganization",
  Id = "id",
  Locale = "locale",
  Name = "name",
}

export interface ITamAdminOrganizationsTableRow extends ITableRow {
  dateCreated: string
  dateUpdated: string
  demoOrganization: boolean
  id: string
  locale: string
  name: string
}

export const tamAdminOrganizationsColumns: ITableColumn<ITamAdminOrganizationsTableRow>[] =
  [
    {
      key: "name",
      title: "Organization Name",
      order: 1,
      id: 0,
    },
    {
      key: "id",
      title: "Org ID",
      order: 2,
      id: 1,
    },
    {
      key: "locale",
      title: "Locale",
      order: 3,
      id: 2,
    },
    {
      key: "demoOrganization",
      title: "Demo Organization",
      order: 4,
      id: 3,
    },
    {
      key: "dateCreated",
      title: "Created (UTC)",
      order: 5,
      id: 4,
    },
    {
      key: "dateUpdated",
      title: "Updated (UTC)",
      order: 6,
      id: 5,
    },
  ]
