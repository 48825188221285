import { useOrganizationContext } from "../../contexts/organizationProvider"

export const useActiveOrganizationId = (): {
  activeOrganizationId: string | undefined
} => {
  // TODO: Pull logic from context into this hook
  const { organization } = useOrganizationContext()

  return { activeOrganizationId: organization?.id }
}
