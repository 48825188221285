import type { FC } from "react"
import React, { useMemo, useState } from "react"
import { Outlet, useParams } from "react-router-dom"

import { isNil } from "lodash"

import { Paper, Stack } from "@mui/material"

import { RootPath, TamAdminPath } from "../../../../models/route"
import { HStack } from "../../../../nzds"
import { useBillingGroups, useMeters, useSites } from "../../../../services"
import { CreateSiteButton } from "../../components/create-site-button/create-site-button"
import OrgViewContainer from "../../components/tamAdminOnboarding/orgView/orgViewContainer/orgViewContainer"
import OrgViewList from "../../components/tamAdminOnboarding/orgView/orgViewList/orgViewList"
import { LIST_ITEM_NAME_PROPERTY } from "../../models/onboarding/onboarding"

export const SiteListRoute: FC = () => {
  const [filterSiteText, setFilterSiteText] = useState("")
  const [filterBillingGroupText, setFilterBillingGroupText] = useState("")
  const [filterMeterText, setFilterMeterText] = useState("")
  const params = useParams()

  const { orgId, billingGroupId, siteId, meterId } = params

  const { sites, isSitesLoading } = useSites(orgId, {
    enabled: !!orgId,
  })

  const { billingGroups, isBillingGroupsLoading } = useBillingGroups({
    sort: { key: "groupName", order: "asc" },
    // TODO: Verify / fix typing for where clause.
    // migration to strict mode batch disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    where: { site_id: siteId } as any,
    disabled: isNil(siteId),
  })

  const { meters, isMetersLoading } = useMeters({
    sort: { key: "meterCode", order: "asc" },
    // TODO: Verify / fix typing for where clause.
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    where: {
      billing_group_id: billingGroupId,
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    disabled: isNil(billingGroupId),
  })

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filteredSiteResults = useMemo(() => {
    if (filterSiteText?.length < 1) {
      return sites
    }
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return sites.filter((site) =>
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      site?.name.toLowerCase().includes(filterSiteText.toLowerCase())
    )
  }, [sites, filterSiteText])

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filteredBillingGroupResults = useMemo(() => {
    if (filterBillingGroupText?.length < 1) {
      return billingGroups
    }
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return billingGroups.filter((billingGroup) =>
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      billingGroup?.groupName
        .toLowerCase()
        .includes(filterBillingGroupText.toLowerCase())
    )
  }, [billingGroups, filterBillingGroupText])

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filteredMeterResults = useMemo(() => {
    if (filterMeterText?.length < 1) {
      return meters
    }
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return meters.filter((meter) =>
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      meter?.meterCode.toLowerCase().includes(filterMeterText.toLowerCase())
    )
  }, [meters, filterMeterText])

  return (
    <>
      <Stack gap={2} sx={{ flex: 1, maxWidth: { lg: "calc(50% - 8px)" } }}>
        <HStack>
          <CreateSiteButton
            parentObjectIds={{ organizationId: orgId }}
            redirectPath={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites`}
          />
        </HStack>
        <Paper
          data-e2e="org-view-container"
          sx={{
            flex: 1,
            overflowX: "auto",
          }}
          variant="outlined"
        >
          <HStack sx={{ height: { lg: "100%", xs: "592px" } }}>
            <OrgViewContainer
              dataE2e="sites"
              filterText={filterSiteText}
              isContentPresent={sites?.length > 0}
              setFilterText={setFilterSiteText}
              title="Sites"
            >
              <OrgViewList
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                content={filteredSiteResults}
                dataE2e="sites"
                isLoading={isSitesLoading}
                nameProperty={LIST_ITEM_NAME_PROPERTY.Name}
                selectedItemId={siteId}
                url={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites`}
              />
            </OrgViewContainer>
            <OrgViewContainer
              dataE2e="billing-groups"
              filterText={filterBillingGroupText}
              isContentPresent={billingGroups?.length > 0}
              setFilterText={setFilterBillingGroupText}
              title="Billing Groups"
            >
              {siteId && (
                <OrgViewList
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  content={filteredBillingGroupResults}
                  dataE2e="billing-groups"
                  isLoading={isBillingGroupsLoading}
                  nameProperty={LIST_ITEM_NAME_PROPERTY.GroupName}
                  selectedItemId={params.billingGroupId}
                  url={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites/${siteId}/billing-group`}
                />
              )}
            </OrgViewContainer>
            <OrgViewContainer
              dataE2e="meters"
              filterText={filterMeterText}
              isContentPresent={meters?.length > 0}
              setFilterText={setFilterMeterText}
              title="Meters"
            >
              {billingGroupId && (
                <OrgViewList
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  content={filteredMeterResults}
                  dataE2e="meters"
                  isLoading={isMetersLoading}
                  nameProperty={LIST_ITEM_NAME_PROPERTY.MeterCode}
                  selectedItemId={meterId}
                  url={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites/${siteId}/billing-group/${params.billingGroupId}/meter`}
                />
              )}
            </OrgViewContainer>
          </HStack>
        </Paper>
      </Stack>
      <Outlet />
    </>
  )
}
