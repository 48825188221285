import React from "react"

import { Button } from "@mui/material"

interface CancelButtonProps {
  onCancel: () => void
}

const CancelButton = ({ onCancel }: CancelButtonProps): JSX.Element => (
  <Button onClick={onCancel}>Cancel</Button>
)

export default CancelButton
