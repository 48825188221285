import React, { useState } from "react"
import type { FC } from "react"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import { useAuthentication } from "@/services/authentication"
import {
  useSigmaWorkbook,
  useSigmaWorkbookEmbeds,
  useSigmaWorkbookSecureEmbedUrl,
} from "@/services/sigma"

import { Box, Skeleton } from "@mui/material"

import type { SigmaReportProps } from "./sigma-report.types"

export const SigmaReport: FC<SigmaReportProps> = ({ siteId, workbookId }) => {
  const { authenticatedUser, isConsultantUser } = useAuthentication()
  const { organization } = useOrganizationContext()
  const { sigmaWorkbookData } = useSigmaWorkbook(workbookId)
  const { sigmaWorkbookEmbedsData } = useSigmaWorkbookEmbeds(workbookId)
  const {
    sigmaWorkbookSecureEmbedUrlData,
    sigmaWorkbookSecureEmbedUrlIsFetched,
  } = useSigmaWorkbookSecureEmbedUrl({
    email: authenticatedUser?.email,
    embedPath: sigmaWorkbookEmbedsData?.[0]?.embedUrl,
    externalUserTeam: organization?.name,
    isConsultantUser,
    organizationId: organization?.id,
    siteId,
  })

  const [isReportLoading, setIsReportLoading] = useState<boolean>(true)

  return (
    <Box
      sx={{
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
      }}
    >
      {isReportLoading && (
        <Skeleton
          data-testid="sigma-report-skeleton"
          height="auto"
          sx={{
            bottom: 0,
            left: 0,
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 1,
          }}
          variant="rounded"
          width="auto"
        />
      )}
      {sigmaWorkbookSecureEmbedUrlIsFetched && (
        <iframe
          height="100%"
          onLoad={() => {
            setIsReportLoading(false)
          }}
          src={sigmaWorkbookSecureEmbedUrlData?.secureEmbedUrl}
          style={{
            border: "none",
            visibility: isReportLoading ? "hidden" : "visible",
          }}
          title={sigmaWorkbookData?.name ?? ""}
          width="100%"
        />
      )}
    </Box>
  )
}
