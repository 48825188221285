import { range } from "lodash-es"

import type { ITableColumn, ITableData, ITableRow } from "../models/table"

const getSkeletonTableRows = <TTableRow extends ITableRow>(
  columns: ITableColumn<TTableRow>[],
  rowCount: number
): TTableRow[] => {
  const idKey = "id"

  // Rows must have an id property for tracking during rendering
  const columnKeys: (keyof TTableRow)[] = [
    ...columns.map((column) => column.key),
    idKey,
  ]

  const skeletonRows: TTableRow[] = range(rowCount).map((index) =>
    columnKeys.reduce((row, key) => {
      if (key === idKey) {
        row.id = index
      } else {
        row[key] = null
      }

      return row
      // We will want to address this in the future
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    }, {} as TTableRow)
  )

  return skeletonRows
}

export const getSkeletonTableData = <TTableRow extends ITableRow>(
  columns: ITableColumn<TTableRow>[],
  rowCount: number
): ITableData<TTableRow> => {
  const rows = getSkeletonTableRows<TTableRow>(columns, rowCount)

  return {
    columns,
    rows,
  }
}
