import { Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class MeterHour extends ApplicationRecord {
  public static jsonapiType = "meter_hours"
}

export const fetchMeterMeterHourCount = async (
  meterId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await MeterHour.where({
    meter_id: meterId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}
