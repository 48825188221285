import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class Bill extends ApplicationRecord {
  public static jsonapiType = "bills"

  @Attr() public readonly siteId: number
}

/**
 * Fetches the bill count for an site
 *
 * @param siteId - The site id
 * @returns - The bill count
 * @example
 * fetchSiteBillCount("15")
 */
export const fetchSiteBillCount = async (siteId: string): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await Bill.where({
    site_id: siteId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}
