import { useMemo } from "react"

import type { Schemas } from "@/services/types"

import { calcuateAnnualSavings } from "../../utils/calculateAnnualSavings"
import { calculateEmissionSavingsPercentage } from "../../utils/calculateEmissionSavingsPercentage"

export function useSiteStats(
  plannedSites?: Schemas["PlanResponse"][],
  potentialSites?: Schemas["PotentialSiteResponse"][]
) {
  return useMemo(() => {
    const plannedSitesCount = plannedSites?.length ?? 0
    const totalSitesCount = plannedSitesCount + (potentialSites?.length ?? 0)

    let totalCapitalCost = 0
    let totalC02MTsRemoved = 0
    let totalBaseEmissions = 0
    let totalAnnualSavings = 0
    for (const plan of plannedSites ?? []) {
      totalCapitalCost += plan.capital_cost
      totalC02MTsRemoved += plan.emissions_diff
      totalBaseEmissions += plan.base_total_emissions
      totalAnnualSavings += calcuateAnnualSavings(
        plan.capital_cost,
        plan.payback_period
      )
    }

    // add in base emissions from rest of portfolio
    for (const potentialSite of potentialSites ?? []) {
      totalBaseEmissions += potentialSite.base_total_emissions
    }

    return {
      plannedSitesCount,
      totalSitesCount,
      totalCapitalCost,
      totalC02MTsRemoved,
      totalAnnualSavings,
      emissionSavingsPercent: calculateEmissionSavingsPercentage(
        totalC02MTsRemoved,
        totalBaseEmissions
      ),
      totalRoi: Number.isFinite(totalCapitalCost / totalAnnualSavings)
        ? totalCapitalCost / totalAnnualSavings
        : 0,
    }
  }, [potentialSites, plannedSites])
}
