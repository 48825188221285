import React, { useCallback, useEffect } from "react"
import type { FC, ReactNode } from "react"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import { CIN_BELL_ID } from "@/models/organization"
import type Organization from "@/models/organization"
import { useAuthentication } from "@/services/authentication"
import { useAuthorizedOrganizations } from "@/services/organization"
import { useUserState } from "@/utils/use-user-state"
import { isCinBellUser } from "@/utils/user"

interface OrganizationResolverProps {
  children?: ReactNode
}

/**
 * A component that ensures an active organization is set when it otherwise wouldn't be
 *
 * @example
 * return (
 *   <OrganizationResolver>
 *     <ChildComponent>
 *   </OrganizationResolver>
 * )
 */
export const OrganizationResolver: FC<OrganizationResolverProps> = ({
  children,
}) => {
  const { authenticatedUser } = useAuthentication()

  const {
    data: authorizedOrganizations,
    isFetched: isAuthorizedOrganizationsFetched,
  } = useAuthorizedOrganizations()

  const { organization, setOrganization } = useOrganizationContext()

  const { userState } = useUserState()

  const savedOrganizationId = userState.organizationId

  const setAndSaveOrganization = useCallback(
    (org: Organization): void => {
      setOrganization(org)
    },
    [setOrganization]
  )

  // Resolve the active organization
  useEffect(() => {
    const setActiveOrganization = (): void => {
      let defaultOrgId: string =
        authenticatedUser?.organizationIds?.[0]?.toString() ?? ""
      const isCinBell: boolean = isCinBellUser(authenticatedUser)

      // Default Cincinnati Bell users to Cincinnati Bell org view
      if (isCinBell) {
        defaultOrgId = CIN_BELL_ID
      }

      const defaultOrganization: Organization | undefined =
        authorizedOrganizations?.find((org) => org.id === defaultOrgId)

      const savedOrganization: Organization | undefined =
        authorizedOrganizations?.find((org) => org.id === savedOrganizationId)

      setAndSaveOrganization(
        savedOrganizationId ? savedOrganization : defaultOrganization
      )
    }

    if (
      isAuthorizedOrganizationsFetched &&
      authorizedOrganizations?.length &&
      !organization
    ) {
      setActiveOrganization()
    }
  }, [
    authenticatedUser,
    authorizedOrganizations,
    isAuthorizedOrganizationsFetched,
    organization,
    savedOrganizationId,
    setAndSaveOrganization,
    setOrganization,
  ])

  return organization ? <>{children}</> : null
}
