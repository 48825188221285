import React from "react"
import type { FC } from "react"

import { useFormatDecimal } from "@/formatters"
import { HStack } from "@/nzds/layout"

import { Divider, Typography } from "@mui/material"

import { DataGridLegendItem } from "../data-grid-legend-item"
import type { DataGridLegendProps } from "./data-grid-legend.types"

export const DataGridLegend: FC<DataGridLegendProps> = ({
  children,
  itemCount,
  itemLabel,
}) => {
  const { format: formatInteger } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  return (
    <Typography component="div" variant="body2">
      <HStack
        alignItems="center"
        divider={
          <Divider
            sx={{ borderColor: "text.secondary" }}
            flexItem
            orientation="vertical"
          />
        }
        gap={3}
      >
        <DataGridLegendItem
          data-testid="site-count"
          label={itemLabel}
          value={formatInteger(itemCount)}
        />

        {children ? (
          <HStack
            alignItems="center"
            divider={<Divider flexItem orientation="vertical" />}
            gap={1}
          >
            {children}
          </HStack>
        ) : null}
      </HStack>
    </Typography>
  )
}
