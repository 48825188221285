import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import { EMPTY_DATA_PLACEHOLDER } from "../utils"
import { shouldShowPlaceholder } from "./utils"

export const defaultOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const useFormatDecimal = (
  options: Intl.NumberFormatOptions = defaultOptions
) => {
  const { t } = useTranslation()

  const format = useCallback(
    (
      value?: string | number | null,
      formatOptions?: Intl.NumberFormatOptions
    ) => {
      if (shouldShowPlaceholder(value)) {
        return EMPTY_DATA_PLACEHOLDER
      }
      return t("common:decimal", {
        value,
        formatParams: {
          value: formatOptions ? { ...formatOptions } : { ...options },
        },
      })
    },
    [options, t]
  )

  return { format }
}
