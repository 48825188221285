import React from "react"

interface OrganizationDashboardMetricTitleProps {
  descriptor?: JSX.Element | string
  title: string
}

export const OrganizationDashboardMetricTitle = ({
  title,
  descriptor,
}: OrganizationDashboardMetricTitleProps) => (
  <>
    {title}
    {descriptor && <> ({descriptor})</>}
  </>
)

OrganizationDashboardMetricTitle.defaultProps = {
  descriptor: "",
}
