/** Helpers for D3 charts */
import type { Area, Axis, BaseType, NumberValue, Selection, Series } from "d3"
import { area, curveMonotoneX, easeCubic, line, pointer, select } from "d3"

import { grayScale, solidGray } from "./colors"
import { ORGANIZATION_DASHBOARD_TICK_TEXT_LIMIT } from "./constants"

export type INode = Selection<
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SVGGElement | BaseType | SVGSVGElement | any,
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Series<Record<string, number>, string>[] | any,
  BaseType | SVGPathElement | SVGSVGElement,
  unknown
>

export interface IDrawProps {
  /**
   * @param className String of classNames
   */
  className?: string
  /**
   * @param INode Selection alias
   */
  node: INode
  /**
   * @param x Number for x position
   */
  x?: number
  /**
   * @param y Number for y position
   */
  y?: number
}

/**
 * Returns concatenated, space-separated, string from array items
 *
 * @param val array of strings or other string-based arrays
 * @returns {string} concatenated, space-separated, string
 */
const strJoinSpaces = (val: string[]): string =>
  val.reduce(
    (a: string, b) =>
      b ? (Array.isArray(b) ? `${a}${strJoinSpaces(b)}` : `${a} ${b}`) : a,
    ""
  )

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SEPARATION_TOOLTIP = 16
const TOOLTIP_WIDTH = 148

/**
 * Creates a path with a curved line
 */
export interface ICreateCurvedLineProps {
  /**
   * @param className a string of CSS class names to be applied (space-separated)
   */
  className?: string
  /**
   * @param data an iterable collection of data to be applied
   */
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  /**
   * @param fillColor a string for the fill color of the generated area
   */
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fillColor?: string | ((d: any) => string)
  /**
   * @param strokeColor a string for the fill color of the generated area
   */
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  strokeColor?: string | ((d: any) => string)
  /**
   * Creates an curved line path
   */
  /**
   * @param svg a D3 Selection
   */
  svg: // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
    | INode
    | Selection<
        BaseType | SVGPathElement,
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        SVGGElement,
        Series<Record<string, number>, string>[]
      >
  /**
   * @param xFunc a number or function that resolves to a number for the x position
   */
  xFunc: // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
    | number
    | ((d: [number, number], index: number, data: [number, number][]) => number)
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((d: any) => number)
  /**
   * @param y1Func a number or function that resolves to a number for the y1 position
   */
  yFunc: // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
    | number
    | ((
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        d: [number, number] | any,
        index: number,
        data: [number, number][]
      ) => number)
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((d: any) => number)
}

export const createCurvedLine = ({
  svg,
  data,
  xFunc,
  yFunc,
  className,
  strokeColor,
  fillColor = "none",
}: ICreateCurvedLineProps) => {
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const myLine = line().x(xFunc).y(yFunc).curve(curveMonotoneX)

  // Apply path with appended line
  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  return svg
    .selectAll(".line")
    .data(data)
    .join((enter) =>
      // Mass lint disable
      // Mass eslint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      enter
        .append("path")
        .classed(strJoinSpaces(["line", className]), true)
        .attr("d", myLine)
        .attr("fill", fillColor)
        .attr("stroke", strokeColor)
    )
}

export interface ICreateAreaProps {
  /**
   * @param animate a boolean on whether to animate the area
   * @default true
   */
  animate?: boolean
  /**
   * @param className a string of CSS class names to be applied (space-separated)
   */
  className?: string
  /**
   * @param data an iterable collection of data to be applied
   */
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  /**
   * @param fillColor a string for the fill color of the generated area
   */
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fillColor?: string | ((d: any, i?: number) => string | unknown)
  /**
   * Creates an area path
   */
  /**
   * @param svg a D3 Selection
   */
  svg: // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
    | INode
    | Selection<
        BaseType | SVGPathElement,
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        SVGGElement,
        Series<Record<string, number>, string>[]
      >
  /**
   * @param xFunc a number or function that resolves to a number for the x position
   */
  xFunc: // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
    | number
    | ((d: [number, number], index: number, data: [number, number][]) => number)
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((d: any) => number)
  /**
   * @param y0 a number or function that resolves to a number for the y0 position
   */
  y0: // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
    | number
    | ((d: [number, number], index: number, data: [number, number][]) => number)
  /**
   * @param y1Func a number or function that resolves to a number for the y1 position
   */
  y1Func: // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
    | number
    | ((
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        d: [number, number] | any,
        index: number,
        data: [number, number][]
      ) => number)
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((d: any) => number)
}
export const createArea = ({
  svg,
  data,
  className = "",
  y0,
  xFunc,
  y1Func,
  fillColor = "none",
  animate = true,
}: ICreateAreaProps): Selection<
  SVGGElement,
  Series<Record<string, number>, string>[],
  BaseType | SVGPathElement | SVGSVGElement,
  string[] | unknown
> => {
  const computedArea = (apply = true): Area<[number, number]> =>
    area()
      .curve(curveMonotoneX)
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .x(xFunc)
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .y0(y0)
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .y1(apply ? y1Func : y0)

  // Apply path with appended computedArea
  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  return svg
    .selectAll(".area")
    .data(data)
    .join(
      (enter) => {
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        enter
          .append("path")
          .classed(strJoinSpaces(["area", className]), true)
          .attr("fill", fillColor)
          .attr("d", computedArea(!animate))
      },
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (_update) => {
        if (animate) {
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          svg
            .selectAll("path")
            .transition()
            .duration(650)
            .delay(250)
            .ease(easeCubic)
            .attr("d", computedArea(true))
        }
      }
    )
}

export interface IDrawGridProps extends IDrawProps {
  /**
   * @param color A color string
   */
  color?: string
  /**
   * Create a grid underlay
   */
  /**
   * @param grid An Axis Generator
   */
  grid: Axis<NumberValue>
}
export const drawGrid = ({
  node,
  className = "",
  grid,
  x = 0,
  y = 0,
  color = grayScale.toString(),
}: IDrawGridProps): Selection<
  SVGGElement,
  Series<Record<string, number>, string>[],
  BaseType,
  unknown
> =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  node
    .append("g")
    .classed(strJoinSpaces(["axis-grid", className]), true)
    .attr("color", color)
    .attr("transform", `translate(${x}, ${y})`)
    .call(grid)

export interface IDrawPlotAreaProps {
  /**
   * @param className CSS class name(s) to apply
   */
  className: string
  /**
   * @param enter function to apply content to newly created plot area group
   */
  enter: (owner: INode) => void
  /**
   * Creates a group for the visualized data
   */
  /**
   * @param node Selection alias of parent
   */
  node: INode
  /**
   * @param x x position
   */
  x: number | (() => number)
  /**
   * @param y y position
   */
  y: number | (() => number)
}
export const drawPlotArea = ({
  node,
  className,
  x = 0,
  y = 0,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  enter = (owner = undefined) => {
    /* noop */
  },
}: IDrawPlotAreaProps): Selection<
  SVGGElement,
  Series<Record<string, number>, string>[],
  BaseType,
  unknown
> =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  node
    .append("g")
    .classed(strJoinSpaces(["plot-area", className]), true)
    .attr("transform", `translate(${x}, ${y})`)
    .call((plotArea) => {
      enter && typeof enter === "function" ? enter(plotArea) : plotArea
    })

export const createTooltip = ({
  svg,
  width,
  height,
  tooltipClass,
  mouseLineClass,
  xRectPosition,
  leftTooltipLeftOffset,
  rightTooltipLeftOffset,
  setHoverValue,
  ticks,
}) => {
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  svg
    .append("path") // this is the black vertical line to follow mouse
    .classed(mouseLineClass, true)
    .style("stroke", solidGray.toString())
    .style("stroke-width", "1px")
    .style("opacity", "1")
    .style("transition", "opacity 0.45s")

  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  return svg
    .append("svg:rect") // append a rect to catch mouse movements on canvas
    .attr("width", width) // can't catch mouse events on a g element
    .attr("height", height)
    .attr("fill", "none")
    .attr("x", xRectPosition) // TODO: make this configurable (Resolve YAxis width)
    .attr("pointer-events", "all")
    .on("mouseout", () => {
      // on mouse out hide tooltip and bar
      select(`.${mouseLineClass}`).style("opacity", "0")
      select(`.${tooltipClass}`).style("opacity", "0")
    })
    .on("mouseover", () => {
      // on mouse in show tooltip and bar
      select(`.${mouseLineClass}`).style("opacity", "1")
      select(`.${tooltipClass}`)
        .style("display", "initial")
        .style("opacity", "1")
        .style("width", `${TOOLTIP_WIDTH}px`)
    })
    .on("mousemove", (event: MouseEvent) => {
      // mouse moving over canvas
      const [mouseX] = pointer(event) // get mouse x position
      // migration to strict mode batch disable
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const tickXPositions: number[] = ticks
        // Mass lint disable
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        .filter((_tick: any, i: number) => i < ticks.length)
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((tick: any) =>
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          Number(tick.getAttribute("transform").slice(10, -1).split(",", 1))
        )
      const xPos = mouseX - xRectPosition + tickXPositions[0]
      let xValue = 0
      tickXPositions.forEach((tick, index) => {
        if (xPos > tick) {
          xValue = index
        }
      })

      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setHoverValue(xValue)
      // TODO: Add refactor/test for this
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const xPositionTooltip =
        xValue < tickXPositions.length / 2
          ? tickXPositions[xValue] + leftTooltipLeftOffset
          : tickXPositions[xValue] - TOOLTIP_WIDTH - rightTooltipLeftOffset

      select(`.${mouseLineClass}`).attr("d", () => {
        let d = `M${tickXPositions[xValue]},${height}`
        d += ` ${tickXPositions[xValue]},${0}`
        return d
      })
      select(`.${tooltipClass}`).style("left", `${xPositionTooltip}px`)
    })
}

export const formatMockEndValue = (ticks, xOffset): void => {
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!ticks.length) {
    return
  }
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  ticks[0].setAttribute("transform", `translate(${xOffset}, 0)`)
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  ticks[ticks.length - 1].remove()
}

/**
 * Limits the amount of tick text present if it passes a certain limit
 *
 * @param {any} ticks xAxisTicks
 * @param xTicks
 * @returns {void}
 */
// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const limitXAxisTickText = (xTicks: any): void => {
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (xTicks.length > ORGANIZATION_DASHBOARD_TICK_TEXT_LIMIT) {
    // Mass lint disable
    // Mass eslint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
    xTicks.forEach((tick: any, index: number) => {
      if (index % 2 === 1) {
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        tick.textContent = ""
      }
    })
  }
}

/**
 * Returns the number of ticks in a given axis
 *
 * @param {INode} parent D3 Selection alias
 * @param {string} axisClassName CSS class name(s) to apply
 * Must be prefaced with dot (.) for CSS class or hash (#) for id
 * @returns {number} count of ticks
 */
export const getAxisTickCount = (
  parent: INode,
  axisClassName: string
): number => parent?.select(axisClassName)?.selectAll(".tick")?.nodes()?.length

/**
 * Sets the text-anchor attribute on the start and end ticks
 *
 * @param xTicks - The x-axis ticks
 */
// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setXAxisStartAndEndTickAnchors = (xTicks: any): void => {
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  xTicks[0].setAttribute("text-anchor", "start")
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  xTicks[xTicks.length - 1].setAttribute("text-anchor", "end")
}
