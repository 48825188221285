import { select } from "d3"

import { darkCarbon as strokeColor, transparent } from "../../utils/colors"
import type { DialGraphDataInterFace } from "../dialGraph"
import { generateSemiAnnularPath } from "./generateSemiAnnularPath"

const annularRange: [number, number] = [0.0, Math.PI * 2]

export interface SemiAnnuliInterface {
  data: Map<string, DialGraphDataInterFace>
  innerRadius: number
  outerRadius: number
  range?: [number, number]
  svg: React.MutableRefObject<undefined>
  viewHeight: number
  viewWidth: number
}

export const drawSemiAnnularGroup = ({
  svg: svgRef,
  range = annularRange,
  viewWidth,
  viewHeight,
  data: dataGroup,
  innerRadius,
  outerRadius,
}: SemiAnnuliInterface) => {
  const centerX = viewWidth / 2
  const centerY = viewHeight / 2
  let endAngle: number = range[1] + Math.PI / 2 || 0.0
  const maxArcLength: number = endAngle - (range[0] + Math.PI / 2 || 0.0)

  const svg = select(svgRef.current)
    .attr("width", viewWidth)
    .attr("height", viewHeight)
  const graphDataGroup = svg
    .append("g")
    .attr("class", "dial-graph__graph-group")

  dataGroup.forEach((data: DialGraphDataInterFace) => {
    const shouldStroke = "stroke" in data && data.stroke
    const startAngle = endAngle
    const chevronPathLength = data.ratio * maxArcLength
    const nextEndAngle = endAngle + chevronPathLength
    endAngle = nextEndAngle

    const baseClassName = data.name
      .replace(/\s/g, "-")
      .replace(/\(|\)/g, "")
      .toLowerCase()
    const className = shouldStroke
      ? `${baseClassName}--stroke_shape`
      : baseClassName
    const stroke = shouldStroke
      ? strokeColor.toString()
      : transparent.toString()

    const path = graphDataGroup
      .append("path")
      .attr("class", className)
      .attr(
        "d",
        generateSemiAnnularPath(startAngle, endAngle, outerRadius, innerRadius)
      )
      .attr("fill", data.color.toString())
      .attr("stroke", stroke)
      .attr("transform", `translate(${centerX} ${centerY})`)
    if (shouldStroke) {
      path.style("stroke", stroke)
    }
    if (data.fill !== "") {
      path.attr("fill", `url(${data.fill})`)
    }
  })
}
