import React from "react"
import type { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

import { Box, Button, Typography } from "@mui/material"

import { RootPath } from "../../../models/route"

export const ResetPasswordSuccess: FC = () => {
  return (
    <Box textAlign="center">
      <Typography mb={4}>Your password has been successfully reset.</Typography>
      <Button
        color="primary"
        component={RouterLink}
        to={RootPath.Root}
        variant="contained"
      >
        Log In to NZero
      </Button>
    </Box>
  )
}
