// @ts-strict-ignore
import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"

import { ApiQueryName } from "../../../../models/api"
import { fetchLAP } from "./useSiteLoadAggregationPointAndPricesUtils"

/**
 * Takes a site and provides the load aggregation point for that site
 *  to an API query. Blocking the query if the site does not have a load aggregation point
 *
 * @example
 * const {
 *  siteLoadAggregationPoint,
 *  isSiteLoadAggregationPointFetched,
 *  isSiteLoadAggregationPointLoading,
 * } = useLAP(site)
 *
 */
export const useLAP = (site) => {
  const queryKey = useQueryKey(
    ApiQueryName.SiteLoadAggregationPoint,
    "getOne",
    null,
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    site
  )
  const queryResult = useQuery({
    keepPreviousData: true,
    queryKey,
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    queryFn: async () => fetchLAP(site?.loadAggregationPointId),
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    enabled: !!site?.loadAggregationPointId,
  })
  return useRenamedQueryResult(queryKey, queryResult)
}
