// @ts-strict-ignore
import type { MeterProps, MetersData, SubMeterPresence } from "./modalMetrics"

const IS_SUBMETER: SubMeterPresence = true
const TOTAL_COST_INDEX = 4

const addIncompletenessLabel = ({
  meterDisplayName,
  subMeterVolume,
  resolvedData,
}) => {
  if (
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    !Number.isNaN(subMeterVolume.totalUsage) &&
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    subMeterVolume.totalUsage !== Number(resolvedData.totalUsage) &&
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    subMeterVolume.totalUsage > Number(resolvedData.totalUsage)
  ) {
    // migrate directories to strict mode
    // eslint-disable-next-line no-param-reassign
    meterDisplayName += "(incomplete)"
  }
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return meterDisplayName
}

interface IResolvedData {
  resolvedData: MeterProps
}

const proRatedSubMeterVolume = ({
  resolvedData,
}: IResolvedData): { totalUsage: number } => {
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if ((resolvedData?.subMeters?.length ?? 0) <= 0) {
    return { totalUsage: NaN }
  }

  return resolvedData.subMeters.reduce(
    (acc, subMeter) => ({
      totalUsage: Number(acc.totalUsage) + Number(subMeter.totalUsage),
    }),
    { totalUsage: 0 }
  )
}

const formatterMeterName = ({ meterCode, name }: IFormatterMeterName) => {
  let meterDisplayName = `[${meterCode}]`
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (name !== undefined && name !== null && name !== "") {
    meterDisplayName += ` - ${name}`
  }
  return meterDisplayName
}

const subMeterDataArray = ({ resolvedData }: IResolvedData) => {
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if ((resolvedData?.subMeters?.length ?? 0) <= 0) {
    return [] as MetersData[]
  }

  return resolvedData.subMeters.map(
    ({ id, meterCode, name, totalCost, totalUsage, meterType }) => {
      const subMeterDisplayName = formatterMeterName({ name, meterCode })
      return [
        subMeterDisplayName,
        meterType,
        id,
        totalUsage,
        totalCost,
      ] as MetersData
    }
  )
}

interface IMeterDataArray extends IResolvedData {
  meterDisplayName: string
}

const meterDataArray = ({ resolvedData, meterDisplayName }: IMeterDataArray) =>
  [
    meterDisplayName,
    resolvedData.meterType,
    resolvedData.id,
    resolvedData.totalUsage || "",
    resolvedData.totalCost || "",
  ] as MetersData

interface IFormattedSubMeterDataArray extends IResolvedData {
  subMeter: MetersData
  subMeterVolume: { totalUsage: number }
}

const formattedSubMeterDataArray = ({
  subMeter,
  subMeterVolume,
  resolvedData,
}: IFormattedSubMeterDataArray) => {
  if (
    !Number.isNaN(subMeterVolume.totalUsage) &&
    subMeterVolume.totalUsage !== Number(resolvedData.totalUsage) &&
    subMeterVolume.totalUsage > Number(resolvedData.totalUsage)
  ) {
    subMeter[TOTAL_COST_INDEX] = "" // Sets the totalCost value to blank as it would result in an unrealistic value
  }
  const structuredData: MetersData = subMeter
  structuredData.push(IS_SUBMETER)
  return structuredData
}

interface IFormatterMeterName {
  meterCode: MeterProps["meterCode"]
  name: MeterProps["name"]
}

const meterAndSubmeterDataArray = ({ resolvedData }: IResolvedData) => {
  const metersData = [] as MetersData[]
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (resolvedData === undefined) {
    return
  }
  let meterDisplayName = formatterMeterName({
    meterCode: resolvedData.meterCode,
    name: resolvedData.name,
  })

  const subMeterVolume = proRatedSubMeterVolume({ resolvedData })

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  meterDisplayName = addIncompletenessLabel({
    meterDisplayName,
    subMeterVolume,
    resolvedData,
  })
  const resolvedMetersData: MetersData = meterDataArray({
    resolvedData,
    meterDisplayName,
  })
  metersData.push(resolvedMetersData)

  const subMeterData: MetersData[] = subMeterDataArray({ resolvedData })
  const structuredDataSubmeterData =
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    subMeterData?.map((subMeter) =>
      formattedSubMeterDataArray({ subMeter, subMeterVolume, resolvedData })
    ) ?? []

  return metersData.concat(structuredDataSubmeterData)
}

/**
 * takes an array of promises and returns a 2D array of data used for rendering a table
 *
 * @param data - the array of promises
 * @returns  {Promise<Array<MetersData>>} a promise of 2D array data used for rendering a table
 */
export const buildMetersData = (data: MeterProps[]): MetersData[] =>
  data.map((resolvedData) => meterAndSubmeterDataArray({ resolvedData })).flat()
