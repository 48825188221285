import { useMutation } from "react-query"

import { ApiQueryName } from "../../models/api"
import BillingGroup from "../../models/billingGroup"
import type { QueryMutation, UseDeleteEntityOptions } from "../types"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useDeleteBillingGroup = ({
  onSuccess,
}: UseDeleteEntityOptions) => {
  const mutationKey = useQueryKey(ApiQueryName.BillingGroup, "deleteOne")
  const { delete: deleteBillingGroup } = useSpraypaintModel(BillingGroup)
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      serviceEntityKey,
    }: Parameters<
      QueryMutation<"deleteOne", ModelAttributes<BillingGroup>>
    >[0]) => {
      await deleteBillingGroup(serviceEntityKey)
    },
    onSuccess: (_, { serviceEntityKey }) => {
      onSuccess()
      invalidateQueries([
        createQueryKey(ApiQueryName.BillingGroup, "getOne", serviceEntityKey),
        createQueryKey(ApiQueryName.OrganizationCounts, "getOne"),
        createQueryKey(ApiQueryName.BillingGroups, "getMany"),
        createQueryKey(ApiQueryName.SiteCounts, "getOne"),
      ])
    },
  })

  return useMutationReturnHandle(mutationKey, mutationResult)
}
