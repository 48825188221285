import { useMemo } from "react"

// TODO: Lift up useUserSettings so we're not reaching down into a module
import { useUserSettings } from "../../modules/settings"
import { createQueryKey } from "./createQueryKey"

/**
 * Create a memoized query key.
 * @see {@link createQueryKey} for query key creation.
 */
export const useQueryKey: typeof createQueryKey = (...args) => {
  const [serviceName, serviceAction, serviceEntityKey, serviceOptions = {}] =
    args
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const userSettings = useUserSettings(serviceOptions.ignoreCurrency)
  const queryKey = createQueryKey(
    serviceName,
    serviceAction,
    serviceEntityKey,
    {
      ...serviceOptions,
      currency: userSettings?.currency,
    }
  )
  // Query key is always an array of primitives so let's use those values as the deps.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => queryKey, [...queryKey])
}
