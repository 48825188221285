import moment from "moment"

import type { StackedAreaGraphDataPoint } from "../../../nzds"
import type {
  AreaGraphMap,
  MonthlyAreaGraphDatum,
  MonthlyEmissionsByScopeGraphValues,
  MonthlyEmissionsGraphValues,
  RowItem,
} from "../types"
import type { ToolTipLabel } from "./monthly-stacked-area-graph.types"

/* This is a utility function that converts graph data into shape that Recharts expects. 
 It also sets up axes tick values, and tooltip labels */
export const formatStackedAreaGraphData = (
  graphDataGroups: MonthlyAreaGraphDatum[],
  keys: string[],
  unitLabel: string,
  tooltipLabelsMap: Record<string, ToolTipLabel>
) => {
  let year
  return graphDataGroups.map((graphDataGroup: MonthlyAreaGraphDatum) => {
    // x-axis tick values
    const parsedDate = moment(graphDataGroup.date).format("MMM")
    const parsedYear = moment(graphDataGroup.date).format("YYYY")
    let yearTickValue
    if (year !== parsedYear) {
      year = parsedYear
      yearTickValue = parsedYear
    }
    // tooltip labels
    const tooltipColumn1Header = moment(graphDataGroup.date).format("MMM YYYY")
    const tooltipColumn2Header = unitLabel
    const data: StackedAreaGraphDataPoint = {
      month: parsedDate,
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      year: yearTickValue,
      tooltipColumn1Header,
      tooltipColumn2Header,
      tooltipLabelsMap,
    }

    // actual graph data
    keys.forEach((key) => {
      data[key] = graphDataGroup[key] as number
    })
    return data
  })
}

// This is a utility function that sets up the legend items
export const buildLegendItems = (
  emissionsTotals:
    | MonthlyEmissionsByScopeGraphValues
    | MonthlyEmissionsGraphValues,
  emissionsMap: AreaGraphMap
): RowItem[] =>
  Object.keys(emissionsTotals).map((key) => ({
    ...emissionsMap[key],
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    value: emissionsTotals[key],
  }))
