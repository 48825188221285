/**
 * Gets an object's key by the key's value using strict equality
 *
 * @param obj - The object in which to find the key
 * @param value - The value of the key
 * @returns - The key in the object who's value is strictly equal to the provided value, or undefined it none is found
 * @example
 * getKeyByValue(myObject, 100)
 */
export const getKeyByValue = <TValue>(
  obj: Record<string, TValue>,
  value: TValue
): string | undefined => Object.keys(obj).find((key) => obj[key] === value)
