import React, { useCallback, useEffect } from "react"

import { Box, Grid, Typography, useTheme } from "@mui/material"

import {
  CancelButton,
  CreateEntityButton,
  useOptions,
} from "../../../../../../../components/form"
import { TYPOGRAPHY_VARIANT } from "../../../../../../../models/typography"
import {
  useCreateMeter,
  useEnergyAllocators,
  useMeterSourceTypes,
} from "../../../../../../../services"
import type { OrgViewDialogCreateContentProps } from "../../../../../models/onboarding/onboarding"
import { METER_TYPE_OPTIONS } from "../../../../../models/onboarding/onboarding"
import { TamForm, useTamForm } from "../../../../tamForm"
import {
  TamFormDateTimePicker,
  TamFormSelectField,
  TamFormTextField,
  TamFormYesNoField,
} from "../../../../tamForm/formFields"
import type { Meter } from "../../../../tamForm/schemas/meter"
import { schema } from "../../../../tamForm/schemas/meter"

const OrgViewDialogMeterCreateContent = ({
  objectName,
  parentObjectIds,
  onClose,
  onSuccess,
  onError,
}: OrgViewDialogCreateContentProps): JSX.Element => {
  const theme = useTheme()
  const { createOneMeter } = useCreateMeter({ onSuccess, onError })

  const { meterSourceTypes, isMeterSourceTypesLoading } = useMeterSourceTypes()
  const meterSourceTypeOptions = useOptions(
    meterSourceTypes,
    ({ name, id }) => ({
      label: name,
      value: id,
    })
  )

  const { energyAllocators, isEnergyAllocatorsLoading } = useEnergyAllocators()
  const energyAllocatorOptions = useOptions(
    energyAllocators,
    ({ name, id }) => ({
      label: name,
      value: id,
    })
  )

  const handleFormSubmit = useCallback(
    (newAttributes) => {
      // TODO: Refactor logic in order to allow type Partial<Meter>
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const attributes = {
        ...newAttributes,
        billingGroupId: parentObjectIds.billingGroupId,
        siteId: parentObjectIds.siteId,
      }
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      createOneMeter(null, attributes)
      onClose()
    },
    [onClose]
  )

  const form = useTamForm({
    schema,
  })

  const meterSourceTypeSelection = form.watch("meterSourceTypeId")

  useEffect(() => {
    if (
      meterSourceTypeSelection === "1" &&
      !form.getValues("energyAllocatorId")
    ) {
      form.setError("energyAllocatorId", {
        message: "Required for this meter source type",
      })
    } else {
      form.clearErrors("energyAllocatorId")
    }
  }, [form, meterSourceTypeSelection])

  if (isMeterSourceTypesLoading || isEnergyAllocatorsLoading) {
    return null
  }

  return (
    <TamForm
      data-testid="meterCreateForm"
      form={form}
      name="meter"
      onSubmit={handleFormSubmit}
    >
      {/* This styling is needed since the actions will be part of the content */}
      <Grid container direction="row" sx={{ mb: 3 }}>
        {/* This styling is needed to provide space between the form and required text */}
        <Grid container direction="row" item spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <TamFormTextField<Meter>
              dataE2E="meter-name-input"
              label="Meter Name"
              name="name"
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TamFormTextField<Meter>
              dataE2E="meter-code-input"
              label="Meter Code"
              name="meterCode"
              required
            />
          </Grid>
          <Grid item xs={3}>
            <TamFormTextField<Meter>
              dataE2E="meter-provider-input"
              label="Meter Provider"
              name="meterProvider"
              required
            />
          </Grid>
          <Grid item xs={3}>
            <TamFormSelectField<Meter>
              dataE2E="meter-type-input"
              label="Meter Type"
              name="meterType"
              options={METER_TYPE_OPTIONS}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TamFormSelectField<Meter>
              dataE2E="energy-allocator-input"
              label="Energy Allocator"
              name="energyAllocatorId"
              options={energyAllocatorOptions}
              required={meterSourceTypeSelection === "1"}
            />
          </Grid>
          <Grid item xs={2}>
            <TamFormYesNoField<Meter>
              dataE2E="full-meter-input"
              isNoDisabled
              label="Full Meter"
              name="fullMeter"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TamFormSelectField<Meter>
              dataE2E="meter-source-type-input"
              label="Meter Source Type"
              name="meterSourceTypeId"
              options={meterSourceTypeOptions}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <TamFormYesNoField<Meter>
              dataE2E="net-meter-input"
              label="Net Meter?"
              name="isNetMeter"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TamFormYesNoField<Meter>
              dataE2E="on-site-generation-input"
              label="On site generation?"
              name="isOnSiteGeneration"
              required
            />
          </Grid>
          <Grid item xs={3}>
            <TamFormYesNoField<Meter>
              dataE2E="probe-meter-input"
              label="Probe Meter?"
              name="probeMeter"
              required
            />
          </Grid>
          <Grid item xs={8}>
            <Box
              display="flex"
              height="100%"
              justifyContent="flex-start"
              width="100%"
            >
              <TamFormDateTimePicker<Meter>
                dataE2E="meter-start-date-input"
                label="Start Date"
                name="startDate"
                required
              />
              <div
                style={{
                  width: theme.typography.pxToRem(30),
                  height: theme.typography.pxToRem(1),
                  backgroundColor: theme.palette.neutral.light,
                  marginLeft: theme.typography.pxToRem(8),
                  marginRight: theme.typography.pxToRem(8),
                  marginTop: theme.typography.pxToRem(50),
                }}
              />
              <TamFormDateTimePicker<Meter>
                dataE2E="meter-end-date-input"
                label="End Date"
                name="endDate"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={TYPOGRAPHY_VARIANT.body2}>*required</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        item
        justifyContent="flex-end"
        spacing={1}
      >
        <Grid item>
          <CancelButton onCancel={onClose} />
        </Grid>
        <Grid item>
          <CreateEntityButton entityName={objectName} />
        </Grid>
      </Grid>
    </TamForm>
  )
}

export default OrgViewDialogMeterCreateContent
