// @ts-strict-ignore
import React from "react"

import type { Moment } from "moment"

import { Button, Stack, Typography } from "@mui/material"

import type { IRange } from "../../../../../models/range"
import type { UnitName } from "../../../../../models/unit"
import type { MeterWithEnergyAllocator } from "../../../models/site"
import { useSiteProRatedUsageMeters } from "../../../services/useSiteProRatedUsageMeters/useSiteProRatedUsageMeters"
import ElectricityHeaderMeterData from "./electricityHeaderMeterData/electricityHeaderMeterData"
import ElectricitySettings from "./electricitySettings/electricitySettings"
import { ModalMetricsConsumption } from "./modalMetricsConsumption/modalMetricsConsumption"

interface ElectricityHeaderProps {
  dateRange: IRange<Moment>
  electricityMetersWithEnergyAllocator: MeterWithEnergyAllocator[]
  isBundled: boolean
  onEnergyUsageUnitChange: (
    unit: UnitName.KilowattHour | UnitName.MegawattHour
  ) => void
  openModalSiteMetersDetails: boolean
  setOpenModalSiteMetersDetails: (value: boolean) => void
  siteHasSubMeter: boolean
  siteIsAmi: boolean
  siteIsAmr: boolean
  siteIsProbe: boolean
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
}

const ElectricityHeader = ({
  dateRange,
  isBundled,
  siteHasSubMeter,
  siteIsAmi,
  siteIsAmr,
  siteIsProbe,
  wattUnit,
  onEnergyUsageUnitChange,
  openModalSiteMetersDetails,
  setOpenModalSiteMetersDetails,
  electricityMetersWithEnergyAllocator,
}: ElectricityHeaderProps) => {
  const { siteProRatedUsageMeters, isSiteProRatedUsageMetersFetched } =
    useSiteProRatedUsageMeters(
      electricityMetersWithEnergyAllocator,
      dateRange,
      !!siteIsAmi, // double negation can be removed with strict null checking
      !!isBundled
    )

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
      <Typography data-e2e="electricity-overview-title" variant="h2">
        Electricity Overview
      </Typography>
      <Stack alignItems="center" direction="row" spacing={1}>
        <ElectricityHeaderMeterData
          siteHasSubMeter={siteHasSubMeter}
          siteIsAmi={siteIsAmi}
          siteIsAmr={siteIsAmr}
          siteIsProbe={siteIsProbe}
        />
        {siteIsAmi && isBundled && (
          <>
            <Button
              color="primary"
              disabled={!isSiteProRatedUsageMetersFetched}
              onClick={() => {
                setOpenModalSiteMetersDetails(true)
              }}
              size="small"
              variant="outlined"
            >
              Meters Info
            </Button>
            {isSiteProRatedUsageMetersFetched && (
              <ModalMetricsConsumption
                meters={siteProRatedUsageMeters}
                onClose={() => {
                  setOpenModalSiteMetersDetails(false)
                }}
                open={openModalSiteMetersDetails}
              />
            )}
          </>
        )}
        <ElectricitySettings
          onEnergyUsageUnitChange={onEnergyUsageUnitChange}
          wattUnit={wattUnit}
        />
      </Stack>
    </Stack>
  )
}

export default ElectricityHeader
