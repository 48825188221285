import type { IFilter } from "./filter"

/**
 * A filter for multiple numbers
 *
 * @param value - An array of numbers
 * @returns The filter instance
 * @example
 * new MultiSelectNumberFilter()
 * new MultiSelectNumberFilter([])
 * new MultiSelectNumberFilter([1, 2, 3])
 * MultiSelectNumberFilter.fromQueryParamValue()
 * MultiSelectNumberFilter.fromQueryParamValue("")
 * MultiSelectNumberFilter.fromQueryParamValue("1,2,3")
 */
export default class MultiSelectNumberFilter
  implements IFilter<MultiSelectNumberFilter, number[]>
{
  value: number[] | null

  constructor(value?: number[]) {
    this.value = value ?? null
  }

  isEqualTo(filter: MultiSelectNumberFilter) {
    return (
      (this.value === null && filter.value === null) ||
      (this.value?.length === filter.value?.length &&
        this.value.every((value) => filter.value.includes(value)))
    )
  }

  toQueryParamValue() {
    return this.value?.join(",") ?? ""
  }

  static fromQueryParamValue(queryParamValue: string): MultiSelectNumberFilter {
    const newValue: number[] | undefined =
      queryParamValue
        ?.split(",")
        .map((value) => Number.parseInt(value, 10))
        .filter((value) => !Number.isNaN(value)) || undefined

    return new MultiSelectNumberFilter(newValue)
  }
}
