import React, { useImperativeHandle, useRef } from "react"
import type { FieldPath, FieldValues } from "react-hook-form"
import { useController } from "react-hook-form"

import { NumericTextField } from "../../inputs/numeric-text-field/numeric-text-field"
import type { FormNumericTextFieldProps } from "./form-numeric-text-field.types"

export const FormNumericTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  id,
  inputRef,
  helperText,
  InputProps,
  ...rest
}: FormNumericTextFieldProps<TFieldValues, TName>) => {
  const { field, fieldState } = useController({
    name,
  })

  const { ref: fieldRef, ...fieldRest } = field

  const innerRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    inputRef,
    () => innerRef.current,
    []
  )

  return (
    <NumericTextField
      {...rest}
      {...fieldRest}
      error={Boolean(fieldState.error)}
      helperText={fieldState.error ? fieldState.error.message : helperText}
      id={id ?? name}
      InputProps={{ ...(InputProps ?? {}), required: false }}
      inputRef={(node) => {
        fieldRef(node)
        innerRef.current = node as HTMLInputElement
      }}
    />
  )
}
