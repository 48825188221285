import { upperFirst } from "lodash-es"
import type { Moment } from "moment"

import type { IRange } from "../../../../models/range"
import {
  ElectricityEmissionsFactor,
  ElectricityEmissionsFactorById,
} from "../../../../models/scopeTwo"
import { poundsToMetricTon } from "../../../../utils"
import { translateUnit } from "../../../../utils/unit"
import type { MonthlySiteReportResult } from "../../models/monthlySite"
import {
  MonthlySitesReportColumnName,
  MonthlySitesReportColumnUnit,
  MonthlySitesReportKey,
  defaultMonthlySiteReportColumns,
} from "../../models/monthlySite"
import type { ResourceSummaryMonthRecord } from "../../models/resourceSummary"

const delimiter = "_"

const getDateStringWithOffset = (
  date: Moment,
  offset: number,
  format: string
): string => date.clone().add(offset, "months").format(format)

const getKeyNameWithDate = (
  key: keyof MonthlySiteReportResult,
  date: Moment,
  offset: number,
  electricityEmissionsFactor: string
): string => {
  const isElectricityEmissionsFactor: boolean =
    key === MonthlySitesReportKey.ElectricityEmissionsFactorMtCo2e
  if (isElectricityEmissionsFactor) {
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    key =
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ElectricityEmissionsFactorById[electricityEmissionsFactor].mtCo2eKeyName
  }
  return `${key}${delimiter}${getDateStringWithOffset(date, offset, "YYYY_M")}`
}

const getMonthlySitesReportTitle = (
  key: string,
  dateRange: IRange<Moment>,
  monthNumber: number,
  language: string
) =>
  `${MonthlySitesReportColumnName[upperFirst(key)]} (${translateUnit(
    language,
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    MonthlySitesReportColumnUnit[upperFirst(key)]
  )}) ${getDateStringWithOffset(dateRange.start, monthNumber, "MMMM YYYY")}`

const getAdditionalColumnCount = (dateRange: IRange<Moment>): number => {
  if (!dateRange) {
    return 0
  }
  if (dateRange.end?.isValid() && dateRange.start?.isValid()) {
    return dateRange.end.diff(dateRange.start, "months") + 1
  }
  return 0
}

const getMonthlySiteReportColumn = (
  offset: number,
  i: number,
  key: string,
  dateRange: IRange<Moment>,
  language: string,
  electricityEmissionsFactor: string
) => ({
  id: i + offset,
  order: i + offset,
  title: getMonthlySitesReportTitle(
    key,
    dateRange,
    i,
    language
  ) as MonthlySitesReportColumnName,
  key: getKeyNameWithDate(key, dateRange.start, i, electricityEmissionsFactor),
})

const getMonthlySiteReportColumns = (
  dateRange: IRange<Moment>,
  key: string,
  language: string,
  electricityEmissionsFactor: string
): typeof defaultMonthlySiteReportColumns =>
  defaultMonthlySiteReportColumns.concat(
    Array.from({ length: getAdditionalColumnCount(dateRange) }, (_, i) =>
      getMonthlySiteReportColumn(
        defaultMonthlySiteReportColumns.length,
        i,
        key,
        dateRange,
        language,
        electricityEmissionsFactor
      )
    )
  )

// refactor to return a very clear interface (result and mtCo2e is unnecessarily complicated)
const convertLbsToMt = (
  key,
  value
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { mtCo2e: number; result: [keyof MonthlySiteReportResult, any] } => {
  const { convertFrom, convertTo } = {
    convertFrom: "Co2ELbs",
    convertTo: "MtCo2e",
  }
  // This expects the keys to be in the format of <resource>Co2ELbs
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  if (key.endsWith(convertFrom)) {
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const convertedValue = poundsToMetricTon(value)

    return {
      result: [
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        key.replace(convertFrom, convertTo) as keyof MonthlySiteReportResult,
        convertedValue,
      ],
      mtCo2e: convertedValue,
    }
  }
  return { result: [key as keyof MonthlySiteReportResult, value], mtCo2e: NaN }
}

const addUnlessNaN = (a: number, b: number) => {
  if (Number.isNaN(a)) {
    return b
  }

  if (!Number.isNaN(a) && !Number.isNaN(b)) {
    return a + b
  }
  return a
}

const getConvertedValues = (
  monthRecord: ResourceSummaryMonthRecord,
  electricityEmissionsFactor: string
) => {
  let totalMtCo2e = NaN
  const convertedValues = Object.fromEntries(
    Object.entries(monthRecord).map(
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ([key, value]: [keyof ResourceSummaryMonthRecord, any]): [
        keyof MonthlySiteReportResult,
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
      ] => {
        const { result, mtCo2e } = convertLbsToMt(key, value)
        const resultIsNotAdvancedGridStudyElectricityEmissionsFactor: boolean =
          result[0] !==
          ElectricityEmissionsFactor.AdvancedGridStudy.mtCo2eKeyName
        const resultIsNotLocationBasedElectricityEmissionsFactor: boolean =
          result[0] !== ElectricityEmissionsFactor.LocationBased.mtCo2eKeyName
        const isAdvancedGridStudySelected: boolean =
          electricityEmissionsFactor ===
          ElectricityEmissionsFactor.AdvancedGridStudy.id
        const isLocationElectricitySelected: boolean =
          electricityEmissionsFactor ===
          ElectricityEmissionsFactor.LocationBased.id

        if (
          (isAdvancedGridStudySelected &&
            resultIsNotLocationBasedElectricityEmissionsFactor) ||
          (isLocationElectricitySelected &&
            resultIsNotAdvancedGridStudyElectricityEmissionsFactor)
        ) {
          totalMtCo2e = addUnlessNaN(mtCo2e, totalMtCo2e)
        }

        return result
      }
    )
  ) as MonthlySiteReportResult

  convertedValues.totalMtCo2e = totalMtCo2e
  return convertedValues
}
type EmissionsAccumulator = Record<string, MonthlySiteReportResult>

const accumulateEmissionsDataBySite = (
  monthlySiteData: ResourceSummaryMonthRecord[] | undefined,
  emissionSelected: keyof MonthlySiteReportResult,
  electricityEmissionsFactor: string
): EmissionsAccumulator =>
  monthlySiteData?.reduce((acc, currValue) => {
    const isElectricityEmissionsFactor: boolean =
      emissionSelected ===
      MonthlySitesReportKey.ElectricityEmissionsFactorMtCo2e
    if (!currValue.siteId) {
      return acc
    }
    const convertedValues = getConvertedValues(
      currValue,
      electricityEmissionsFactor
    )

    // if emissionSelected is ElectricityEmissionsFactorMtCo2e, then we need to change emissionSelected to either advancedGridStudyMtCo2e
    // or locationElectricityMtCo2e when accessing currValue object, leaving the emissionsKeyWithDate above as is
    if (isElectricityEmissionsFactor) {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      emissionSelected =
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ElectricityEmissionsFactorById[electricityEmissionsFactor].mtCo2eKeyName
    }
    const emissionsKeyWithDate = `${emissionSelected}${delimiter}${currValue.year}${delimiter}${currValue.month}`

    if (!acc[currValue.siteId]) {
      acc[currValue.siteId] = {
        ...convertedValues,
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        [emissionsKeyWithDate]: currValue[emissionSelected],
      }
    }

    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    acc[currValue.siteId] = {
      ...acc[currValue.siteId],
      [emissionsKeyWithDate]: convertedValues[emissionSelected],
    }

    return acc
  }, {})

const getResourceSummariesWithConvertedMtResults = (
  monthlySiteData: ResourceSummaryMonthRecord[] | undefined,
  emissionSelected: keyof MonthlySiteReportResult,
  electricityEmissionsFactor: string
): MonthlySiteReportResult[] => {
  const emissionsDataBySite = accumulateEmissionsDataBySite(
    monthlySiteData,
    emissionSelected,
    electricityEmissionsFactor
  )

  return Object.values(emissionsDataBySite || {})
}

export {
  getMonthlySiteReportColumns,
  getResourceSummariesWithConvertedMtResults,
}
