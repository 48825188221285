import { useEffect } from "react"

import type { EnvironmentName } from "../../models/environment.types"
import { useAuthentication } from "../authentication"
import { usePendo } from "./usePendo"

export const usePendoInit = () => {
  const { authenticatedUser } = useAuthentication()
  const { isFetched, data: pendo } = usePendo()

  // Initialize Pendo
  useEffect(() => {
    if (authenticatedUser && isFetched) {
      pendo.initialize({
        visitor: {
          // Creating a unique identifier per environment ensures a consistent prod experience
          // https://support.pendo.io/hc/en-us/articles/360031862352-Pendo-in-Development-and-Testing
          id:
            process.env.REACT_APP_ENV !==
            ("production" satisfies EnvironmentName)
              ? `${authenticatedUser.email}-${process.env.REACT_APP_ENV}`
              : authenticatedUser.email,
          email: authenticatedUser.email,
          full_name: authenticatedUser.name,
        },
      })
    }
  }, [authenticatedUser, isFetched, pendo])
}
