import { CurrencyWithPlaceholder as CurrencyWithPlaceholderComponent } from "../../components/currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { EmissionDecimal } from "../../components/decimal/emissionDecimal/emissionDecimal"
import { EnergyUnitDecimal } from "../../components/decimal/energyUnitDecimal/energyUnitDecimal"

export enum MetricType {
  Emission = 0,
  EnergyUnit = 1,
  CurrencyWithPlaceholder = 2,
}

export const metricFormattingComponentMap: Record<
  number,
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any) => JSX.Element
> = {
  [MetricType.Emission]: EmissionDecimal,
  [MetricType.EnergyUnit]: EnergyUnitDecimal,
  [MetricType.CurrencyWithPlaceholder]: CurrencyWithPlaceholderComponent,
}

export const getMetricFormattingComponent = (metricType: number) =>
  metricFormattingComponentMap[metricType]
