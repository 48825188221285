import React, { useRef } from "react"
import type { FC } from "react"

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { IconButton, Stack, Tooltip, Typography } from "@mui/material"
import type { GridEventListener } from "@mui/x-data-grid-pro"
import { useGridApiContext, useGridApiEventHandler } from "@mui/x-data-grid-pro"

import { HStack } from "../../layout/h-stack/h-stack"
import { DataGridCellPopover } from "../data-grid-cell-popover/data-grid-cell-popover"
import type { DataGridColumnHeaderProps } from "./data-grid-column-header.types"

function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  )
}

export const DataGridColumnHeader: FC<DataGridColumnHeaderProps> = ({
  headerName,
  tooltipContent,
  unit,
  field,
  description,
  subHeaderName,
}) => {
  const titleContainerRef = useRef<HTMLDivElement>(null)

  const [isTitleOverflown, setIsTitleOverflown] = React.useState(false)

  const apiRef = useGridApiContext()

  const handleColumnWidthChange: GridEventListener<"columnWidthChange"> = (
    params
  ) => {
    if (params.colDef.field !== field) {
      return
    }
    if (titleContainerRef.current) {
      setIsTitleOverflown(isOverflown(titleContainerRef.current))
    }
  }

  // Results in a memory leak warning, but there is no leak
  // https://github.com/mui/mui-x/issues/9261
  useGridApiEventHandler(apiRef, "columnWidthChange", handleColumnWidthChange)

  return (
    <HStack
      alignItems="flex-start"
      gap={1}
      onMouseOver={() => {
        if (titleContainerRef.current) {
          setIsTitleOverflown(isOverflown(titleContainerRef.current))
        }
      }}
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      <Stack
        overflow="hidden"
        textAlign="right"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <HStack alignItems="center" gap={1}>
          {isTitleOverflown ? (
            <Tooltip
              disableInteractive
              placement="bottom"
              title={description ?? headerName}
            >
              <Typography
                ref={titleContainerRef}
                fontWeight="fontWeightBold"
                noWrap
              >
                {headerName}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              ref={titleContainerRef}
              fontWeight="fontWeightBold"
              noWrap
            >
              {headerName}
            </Typography>
          )}
          {tooltipContent ? (
            <DataGridCellPopover content={tooltipContent}>
              <IconButton
                aria-label="information"
                color="neutral"
                role="presentation"
                size="small"
                sx={{
                  height: (theme) => theme.typography.pxToRem(24),
                  width: (theme) => theme.typography.pxToRem(24),
                  minHeight: (theme) => theme.typography.pxToRem(24),
                  minWidth: (theme) => theme.typography.pxToRem(24),
                }}
              >
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </DataGridCellPopover>
          ) : null}
        </HStack>

        {unit ? (
          <Typography noWrap variant="body2">
            {unit}
          </Typography>
        ) : null}
        {subHeaderName ? (
          <Typography noWrap variant="body2">
            {subHeaderName}
          </Typography>
        ) : null}
      </Stack>
    </HStack>
  )
}
