import type { Moment } from "moment"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import MonthSummary from "../../../../models/monthSummary"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"
import { formatToYYYYMMDD } from "../../../../utils/timeHelpers"

interface IGetCo2eLbsAndCarbonFreeKwh {
  data: Record<
    | "carbonBasedKwh"
    | "carbonFreeKwh"
    | "unknownResourceBasedKwh"
    | "batteryBasedKwh"
    | "carbonEquivalentEmissionLbs",
    number
  >
  stats: {
    batteryBasedKwh: Record<"sum", number>
    carbonBasedKwh: Record<"sum", number>
    carbonFreeKwh: Record<"sum", number>
    co2ELbs: Record<"sum", number>
    unknownResourceBasedKwh: Record<"sum", number>
  }
}

/**
 * Queries the API for a MonthSummary in range of the startTime, endTime and organizationId
 * and stats in range of the startTime, endTime and organizationId.
 *
 * Note the only consumer of this function that uses data is only using carbonEquivalentEmissionLbs.
 * It is not clear why we are returning all of the other data
 * or why carbonEquivalentEmissionLbs is used instead of the total sum co2ELbs.
 *
 * @returns The stat for sums in range and the attributes for the result as data.
 * @example
 * const {
 *   meta,
 *   stats,
 * } = getCo2eLbsAndCarbonFreeKwh({
 *      organizationId: "1",
 *      startTime: moment("2022-01-01"),
 *      endTime: moment("2022-02-28"),
 *     })
 */
const getCo2eLbsAndCarbonFreeKwh = ({
  startTime,
  endTime,
  organizationId,
}: {
  endTime: Moment
  organizationId: string | string[]
  startTime: Moment
}): Promise<IGetCo2eLbsAndCarbonFreeKwh> =>
  MonthSummary.where({
    organization_id: organizationId,
    start_date: formatToYYYYMMDD(startTime.clone()),
    // api/v1/monthSummary queries require end_date to be the last day of the month
    end_date: formatToYYYYMMDD(endTime.clone().endOf("month")),
  })
    .stats({
      carbon_free_kwh: "sum",
      co2e_lbs: "sum",
      unknown_resource_based_kwh: "sum",
      carbon_based_kwh: "sum",
      battery_based_kwh: "sum",
    })
    .per(1)
    .all()
    .then(({ meta, data }: CollectionProxy<MonthSummary>) => {
      // Turns the stats from snake_case to camelCase and converts the values to floats
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const statsWithFloatValues = Object.entries(meta.stats).map(
        ([k, v]: [
          keyof IGetCo2eLbsAndCarbonFreeKwh["stats"],
          Record<"sum", string>,
        ]) => [k, { sum: parseFloat(v.sum) }]
      )
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const unFormattedStats = Object.fromEntries(statsWithFloatValues)
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const stats: IGetCo2eLbsAndCarbonFreeKwh["stats"] =
        snakeCaseToCamelCase(unFormattedStats)
      // Exit early statement
      if (data.length === 0 || data[0]?.energyTotals === null) {
        return {
          stats,
          data: {
            // Not sure the reason we don't just return an empty object or null here
            carbonBasedKwh: 0,
            carbonFreeKwh: 0,
            unknownResourceBasedKwh: 0,
            batteryBasedKwh: 0,
            carbonEquivalentEmissionLbs: 0,
          } as IGetCo2eLbsAndCarbonFreeKwh["data"],
        }
      }
      // Turns the energyTotals from snake_case to camelCase and converts the values to floats
      const energyTotalEntriesWithFloatValues = Object.entries(
        data[0].energyTotals
      ).map(([k, v]) => [k, parseFloat(v)])
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const unFormattedEnergyTotals = Object.fromEntries(
        energyTotalEntriesWithFloatValues
      )
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const formattedEnergyTotals: IGetCo2eLbsAndCarbonFreeKwh["data"] =
        snakeCaseToCamelCase(unFormattedEnergyTotals)

      return {
        stats,
        data: formattedEnergyTotals,
      }
    })

export { getCo2eLbsAndCarbonFreeKwh }
