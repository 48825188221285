import type { Moment } from "moment"
import moment from "moment"

import type { IRange } from "../../models/range"
import ReportingData from "../../models/reportingData"
import type { AvailableReportDatesDto } from "../../modules/report/models/api"
import { removeTimeFromISODateString } from "../../utils/date"

/**
 * Fetches a range of the available report dates for an organization
 * where 'start' is the beginning of the start month
 * and 'end' is the end of the end month
 *
 * @param orgId - The organization id
 * @returns The available report dates
 * @example
 * fetchAvailableReportDates("1")
 */
export const fetchAvailableReportDates = async (
  orgId: string
): Promise<IRange<Moment>> => {
  const availableReportDates = ReportingData.where({
    organization_id: orgId,
  })
    .stats({
      min_max_months: "calculate",
    })
    .all()
    .then((result) => {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const dateRange: AvailableReportDatesDto =
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        result.meta.stats.min_max_months.calculate

      return {
        // Remove timezone from server dates so they can be compared to YYYY-MM-DD dates
        start: moment(removeTimeFromISODateString(dateRange.start)).startOf(
          "month"
        ),
        end: moment(removeTimeFromISODateString(dateRange.end)).endOf("month"),
      }
    })

  return availableReportDates
}

export default {
  fetchAvailableReportDates,
}
