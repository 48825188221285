import React from "react"
import type { FC } from "react"

import { Stack, Typography } from "@mui/material"

import type { DataGuardProps } from "./data-guard.types"

export const DataGuard: FC<DataGuardProps> = ({
  children,
  hasData,
  isLoading,
}) => {
  return (
    <>
      {isLoading && <></>}
      {!isLoading && !hasData && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            bottom: 0,
            left: 0,
            pointerEvents: "none",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <Typography variant="h2">You need data!</Typography>
          <Typography variant="body2">
            Contact NZero to get your data into the system.
          </Typography>
        </Stack>
      )}
      {!isLoading && hasData && <>{children}</>}
    </>
  )
}
