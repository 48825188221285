import type { FC } from "react"
import React from "react"
import { useTranslation } from "react-i18next"

import { useUnit } from "../../../../components/unit/use-unit"
import { UnitName } from "../../../../models/unit"
import { HStack } from "../../../../nzds"
import { useUserSettings } from "../../../settings"
import { calcuateAnnualSavings } from "../../utils/calculateAnnualSavings"
import { calculateEmissionSavingsPercentage } from "../../utils/calculateEmissionSavingsPercentage"
import { StatCardContent } from "../stat-card-content/stat-card-content"
import { StatCard } from "../stat-card/stat-card"
import type { PlanStatsBarProps } from "./plan-stats-bar.types"

export const PlanStatsBar: FC<PlanStatsBarProps> = ({ plan }) => {
  const { currency } = useUserSettings()
  const co2eUnit = useUnit(UnitName.MetricTonsOfCarbonDioxideEquivalent)
  const { t } = useTranslation()
  return (
    <HStack gap={3}>
      <StatCard>
        <StatCardContent
          title="Emission Savings"
          value={t("common:percentage", {
            value: calculateEmissionSavingsPercentage(
              plan.emissions_diff,
              plan.base_total_emissions
            ),
          })}
        />
        <StatCardContent
          title={`Total ${co2eUnit}`}
          value={`${t("common:decimal", {
            value: plan.emissions_diff,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`}
        />
        <StatCardContent
          title="Portfolio Impact"
          value={t("common:percentage", {
            value: plan.portfolio_impact,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        />
      </StatCard>
      <StatCard>
        <StatCardContent
          title="Capital Cost"
          value={t("common:money", {
            value: plan.capital_cost,
            formatParams: {
              value: {
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            },
          })}
        />
        <StatCardContent
          title="Annual Cost Savings"
          value={t("common:money", {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value: calcuateAnnualSavings(
              plan.capital_cost,
              plan.payback_period
            ),
            formatParams: {
              value: {
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            },
          })}
        />
        <StatCardContent
          title="ROI (Years)"
          value={`${t("common:decimal", {
            value: plan.payback_period,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`}
        />
      </StatCard>
    </HStack>
  )
}
