import { groupBy, map } from "lodash-es"

import OrganizationEmissionDay from "../../../../models/organizationEmissionDay"
import { ResourceName } from "../../../../models/resource"
import { Scope } from "../../../../models/scope"
import SiteEmissionDay from "../../../../models/siteEmissionDay"
import { KG_PER_METRIC_TON } from "../../../../utils/constants"
import type { EmissionDayStats } from "../../models/dashboard"

interface EmissionDaysProps {
  organizationId: string
  rangeEnd: string
  rangeStart: string
  scopes: number[]
  siteIds: string[]
}

export interface EmissionsDayStats {
  directEmissionStats: EmissionDayStats[]
  directEmissions: number
  indirectEmissionStats: EmissionDayStats[]
  indirectEmissions: number
  waterEmissionStats: EmissionDayStats[]
  waterEmissions: number
}

const reduceEmissionStatsByName = (siteAndOrgEmissionStats) =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  map(groupBy(siteAndOrgEmissionStats, "name"), (group, name) =>
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    group.reduce(
      (acc, obj) => {
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        acc.amount += parseFloat(obj.amount)
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        acc.kgCo2e += parseFloat(obj.kg_co2e)
        // Mass eslint disable @typescript-eslint/no-unsafe-return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return acc
      },
      {
        name,
        amount: 0.0,
        kgCo2e: 0.0,
      }
    )
  )

export const fetchEmissionDays = async ({
  organizationId,
  siteIds,
  rangeStart,
  rangeEnd,
  scopes,
}: EmissionDaysProps): Promise<EmissionDayStats[]> => {
  const siteEmissionDaysPromise = SiteEmissionDay.stats({
    amount_and_kg_co2e_by_resource_name: "sum",
  })
    .where({
      day: {
        gte: rangeStart,
        lte: rangeEnd,
      },
      site_id: siteIds,
      scopes,
    })
    .per(0)
    .all()
    .then((data) => data)

  const orgEmissionDaysPromise = OrganizationEmissionDay.stats({
    amount_and_kg_co2e_by_resource_name: "sum",
  })
    .where({
      day: {
        gte: rangeStart,
        lte: rangeEnd,
      },
      organization_id: organizationId,
      scopes,
    })
    .per(0)
    .all()
    .then((data) => data)

  return Promise.all([siteEmissionDaysPromise, orgEmissionDaysPromise]).then(
    (resolvedPromises) =>
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      resolvedPromises
        .reduce((acc, resolvedPromise) => {
          acc.push(
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            resolvedPromise.meta.stats.amount_and_kg_co2e_by_resource_name.sum
          )
          // Mass eslint disable @typescript-eslint/no-unsafe-return
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return acc
        }, [])
        .flat()
  )
}

export const filterEmissionStats = (
  emissionStats: EmissionDayStats[]
): EmissionsDayStats => {
  if (!emissionStats) {
    return {
      directEmissions: 0,
      indirectEmissions: 0,
      waterEmissions: 0,
      directEmissionStats: [],
      indirectEmissionStats: [],
      waterEmissionStats: [],
    }
  }
  const indirectEmissionStatsByName = reduceEmissionStatsByName(
    emissionStats.filter((emission) => emission.scope === Scope.Three)
  )

  const indirectEmissionsTonsCo2e =
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    indirectEmissionStatsByName.reduce((sum, stats) => sum + stats.kgCo2e, 0) /
    KG_PER_METRIC_TON

  const waterEmissionStatsByName = reduceEmissionStatsByName(
    emissionStats.filter((emission) => emission.name === ResourceName.Water)
  )

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const waterEmissionsAmount = waterEmissionStatsByName.reduce(
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    (sum, stats) => sum + stats.amount,
    0
  )

  const directEmissionStatsByName = reduceEmissionStatsByName(
    emissionStats.filter((emission) => emission.scope === Scope.One)
  )

  const directEmissionsTonsCo2e =
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    directEmissionStatsByName.reduce((sum, stats) => sum + stats.kgCo2e, 0) /
    KG_PER_METRIC_TON

  return {
    directEmissions: directEmissionsTonsCo2e,
    indirectEmissions: indirectEmissionsTonsCo2e,
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    waterEmissions: waterEmissionsAmount,
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    directEmissionStats: directEmissionStatsByName,
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    indirectEmissionStats: indirectEmissionStatsByName,
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    waterEmissionStats: waterEmissionStatsByName,
  }
}

export default { fetchEmissionDays, filterEmissionStats }
