import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { axisLeft, max, min, scaleLinear, scaleLog, select } from "d3"

import { useTheme } from "@mui/material"

import { useUserSettings } from "../../modules/settings"
import { dustyGray, solidGray } from "../../utils/colors"
import { translateCurrency } from "../../utils/currency"

const spaceBetween = 40
const baselineOffset = 40
const offsetLeft = 96
const labelOffset = 4

export const BAR_ANIMATION_TIME_MS = 500
export const BASELINE_ANIMATION_TIME_MS = 250

export const OrganizationBarGraph = ({
  height,
  data,
  graphIsLog,
}: {
  data: Map<string, number>
  graphIsLog: boolean
  height: number
}) => {
  const { i18n } = useTranslation()
  const { currency } = useUserSettings()
  const ref = useRef()
  const theme = useTheme()

  const colorMap = new Map<string, string>([
    ["Energy", theme.palette.spectrum.cerulean[300]],
    ["Imbalance", theme.palette.spectrum.peach[300]],
    ["Scheduling Fees", theme.palette.spectrum.purple[300]],
    ["Transmission & Distribution", theme.palette.spectrum.cerulean[100]],
    ["Resource Adequacy", theme.palette.grey[200]],
    ["RECs", theme.palette.grey[200]],
    ["Other", theme.palette.grey[200]],
  ])

  const xyAxisLabelColor: string = solidGray.toString()

  const dataValues: number[] =
    [...data.values()].length > 0 ? [...data.values()] : [0]
  const maxValue = 10

  const draw = (isLog) => {
    let scale
    let domainSize
    let accessType

    if (dataValues[0] === 0) {
      return
    }

    if (min(dataValues) * maxValue < max(dataValues)) {
      scale = scaleLog().base(2)
      domainSize = max(dataValues) + max(dataValues)
      accessType = "log-graph"
    } else if (min(dataValues) * maxValue >= max(dataValues)) {
      scale = scaleLinear()
      domainSize = max(dataValues)
      accessType = "linear-graph"
    }

    if (isLog) {
      scale = scaleLog().base(2)
      domainSize = max(dataValues) + max(dataValues)
      accessType = "log-graph"
    } else if (!isLog) {
      scale = scaleLinear()
      domainSize = max(dataValues)
      accessType = "linear-graph"
    }

    const svg = select(ref.current)
    const svgDom = svg.node() as SVGElement
    const areaWidth = svgDom.clientWidth
    const workingAreaWidth = areaWidth - offsetLeft - spaceBetween * data.size
    const workingAreaHeight = height - baselineOffset + labelOffset
    const barWidth = workingAreaWidth / data.size

    const domainStart = min(dataValues) / 2

    const range = [baselineOffset, workingAreaHeight]
    const domain = [domainSize, domainStart]

    const selection = svg.selectAll("rect").data([...data.entries()])
    // Mass lint disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const yScale = scale.domain(domain).range(range)

    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const yAxis = axisLeft(yScale)
      .tickSize(8)
      .tickFormat((d) =>
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        translateCurrency(i18n, currency, d as number, {
          precision: 0,
        })
      )

    const getBarColor = (key) => {
      const colorMapper = colorMap
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (colorMapper.has(key)) {
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return colorMapper.get(key)
      }
      // default color
      return theme.palette.spectrum.cerulean[300]
    }

    const yAxisSvg = svg
      .append("g")
      .attr("class", "y-axis")
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .attr("data-name", accessType)
      .attr("stroke", "rgb(221, 221, 221)")
      .attr("stroke-width", 0.5)
      .attr("stroke-opacity", 0.5)
      .call((self) => self.attr("transform", "translate(-20 0)"))
    if (dataValues.length > 0) {
      yAxisSvg.call(yAxis)
    }
    yAxisSvg
      .call((self) =>
        self
          .selectAll(".tick text")
          .style("color", xyAxisLabelColor)
          .style("text-anchor", "start")
          .attr("x", 20)
          .attr("dy", -2)
          .attr(
            "dx",
            (_d, i, elements: SVGTextElement[]) =>
              max([...elements].map((e) => e.getBoundingClientRect().width)) -
              elements[i].getBoundingClientRect().width
          )
      )
      .call((self) => self.selectAll(".tick line").remove())
      .call((self) => self.select(".domain").remove())
      .call((self) => self.selectAll("g.tick:nth-child(2n)").remove())

    selection
      .transition()
      .duration(BAR_ANIMATION_TIME_MS)
      // Mass eslint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      .attr("height", (d) => yScale(d[1]))
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      .attr("y", (d) => height - yScale(d[1]))

    const barsGroup = selection.enter().append("g").attr("class", "bar-group")
    const priceTexts = barsGroup
      .append("text")
      .attr("class", "price")
      .text((d) => translateCurrency(i18n, currency, d[1], { precision: 0 }))

    barsGroup
      .append("rect")
      .attr("class", "bar")
      .attr("x", (_d, i) => offsetLeft + i * barWidth + i * spaceBetween)
      .attr("width", barWidth)
      .attr("fill", (d) => getBarColor(d[0]))
      .attr("y", height / 2)
      .attr("height", 0)
      .transition()
      .duration(BAR_ANIMATION_TIME_MS)
      // Mass eslint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      .attr("y", (d) => yScale(d[1]))
      .attr("height", (d) =>
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        Math.max(height - yScale(d[1]) - baselineOffset, 0)
      )
      .attr("data-e2e", "bar-graph")

    const labelTexts = barsGroup
      .append("foreignObject")
      .attr("color", xyAxisLabelColor)
      .attr("class", "label")
      .attr("width", barWidth + spaceBetween)
      .attr("height", 200)
      .text((d) => d[0])

    priceTexts
      .attr(
        "x",
        (_d, i, elements) =>
          offsetLeft +
          i * barWidth +
          i * spaceBetween +
          (barWidth - elements[i].getBoundingClientRect().width) / 2
      )
      .attr("y", workingAreaHeight / 2)
      .transition()
      .duration(500)
      .attr(
        "y",
        (d, i, elements) =>
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          yScale(d[1]) +
          elements[i].getBoundingClientRect().height -
          baselineOffset / 2 -
          labelOffset
      )
    labelTexts
      .attr(
        "x",
        (_d, i, elements) =>
          offsetLeft +
          i * barWidth +
          i * spaceBetween +
          (barWidth - elements[i].getBoundingClientRect().width) / 2
      )
      .attr("y", height)
      .transition()
      .duration(BAR_ANIMATION_TIME_MS)
      .attr("y", workingAreaHeight + 5)

    svg.call((self) => {
      self
        .append("line")
        .attr("class", "baseline")
        .attr("shape-rendering", "crispEdges")
        .attr("stroke-width", "1px")
        .attr("stroke", dustyGray.toString())
        .attr("y1", height - baselineOffset)
        .attr("y2", height - baselineOffset)
        .attr(
          "x1",
          (_d, i, elements) =>
            elements[i].parentElement.getBoundingClientRect().width / 2
        )
        .attr(
          "x2",
          (_d, i, elements) =>
            elements[i].parentElement.getBoundingClientRect().width / 2
        )
        .transition()
        .duration(BASELINE_ANIMATION_TIME_MS)
        .attr("x1", 0)
        .attr(
          "x2",
          (_d, i, elements) =>
            elements[i].parentElement.getBoundingClientRect().width
        )
    })

    selection
      .exit()
      .transition()
      .duration(500)
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .attr("y", (d) => height)
      .attr("height", 0)
      .remove()
  }

  useEffect(() => {
    select(ref.current).attr("width", "100%").attr("height", height)
  }, [])

  useEffect(() => {
    draw(graphIsLog)
  }, [data])

  return <svg ref={ref} className="financial-summary__graph" />
}
