import moment from "moment"
import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class ForecastHour extends ApplicationRecord {
  public static jsonapiType = "forecast_hours"

  @Attr() public hour: moment.Moment

  @Attr() public icon: string

  @Attr() public description: string

  @Attr() public temp: number

  @Attr() public tempC: number

  @Attr() public humidity: number
}
