import { useMutation } from "react-query"

import { ApiQueryName } from "../../models/api"
import Department from "../../models/department"
import type { QueryMutation, UseDeleteEntityOptions } from "../types"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useDeleteDepartment = ({ onSuccess }: UseDeleteEntityOptions) => {
  const mutationKey = useQueryKey(ApiQueryName.Department, "deleteOne")
  const { delete: deleteDepartment } = useSpraypaintModel(Department)
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      serviceEntityKey,
    }: Parameters<
      QueryMutation<"deleteOne", ModelAttributes<Department>>
    >[0]) => {
      await deleteDepartment(serviceEntityKey)
    },
    onSuccess: (_, { serviceEntityKey }) => {
      onSuccess()
      invalidateQueries([
        createQueryKey(ApiQueryName.Department, "getOne", serviceEntityKey),
        createQueryKey(ApiQueryName.Departments, "getMany"),
        createQueryKey(ApiQueryName.OrganizationCounts, "getOne"),
      ])
    },
  })

  return useMutationReturnHandle(mutationKey, mutationResult)
}
