import EnergyAllocation from "../../../../models/energyAllocation"
import type Site from "../../../../models/site"
import { parseFloatMetaStatSum } from "../../../../utils"
import { LBS_PER_METRIC_TON } from "../../../../utils/constants"
import type { IFetchEmissionsAndEnergyVolume } from "../../../../utils/spraypaintApi"

interface IFetchBundledEmissionsAndEnergyVolume {
  dayStart: string
  site: Site
  yearStart: string
}

export const fetchBundledEmissionsAndEnergyVolume = ({
  site,
  yearStart,
  dayStart,
}: IFetchBundledEmissionsAndEnergyVolume): Promise<IFetchEmissionsAndEnergyVolume> => {
  const fetchedEnergyAllocations = EnergyAllocation.carbonBasedAndFreeStats({
    site,
    yearStart,
    dayStart,
  })
  return fetchedEnergyAllocations.then((result) => ({
    ytdCarbonEmissionsInMtCo2e:
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      parseFloatMetaStatSum(result?.carbon_stats.calculate[0].co2e_lbs) /
      LBS_PER_METRIC_TON,
    ytdCarbonBasedKwh: parseFloatMetaStatSum(
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      result?.carbon_stats.calculate[0].carbon_based_kwh
    ),
    ytdCarbonFreeKwh: parseFloatMetaStatSum(
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      result?.carbon_stats.calculate[0].carbon_free_kwh
    ),
  }))
}
