import { initCaseResponseInterceptor } from "@/utils"
import axios from "axios"
import type { AxiosInstance } from "axios"

import type { SigmaAccessToken } from "../sigma.types"

const sigmaAuthHttpClient: AxiosInstance = axios.create({
  baseURL: process.env.SIGMA_API_URL,
})

initCaseResponseInterceptor(sigmaAuthHttpClient)

const SIGMA_API_CLIENT_ID = `${process.env.SIGMA_API_CLIENT_ID}`
const SIGMA_API_SECRET = `${process.env.SIGMA_API_SECRET}`

/**
 *
 * @returns new sigma access token, refresh token and expiration time
 */
export const getSigmaAccessToken = async (): Promise<SigmaAccessToken> => {
  const SIGMA_ACCESS_TOKEN_API_URL = `/auth/token`
  const data = new URLSearchParams()

  data.append("grant_type", "client_credentials")
  data.append("client_id", SIGMA_API_CLIENT_ID)
  data.append("client_secret", SIGMA_API_SECRET)

  try {
    const response = await sigmaAuthHttpClient.post<SigmaAccessToken>(
      SIGMA_ACCESS_TOKEN_API_URL,
      data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )

    return response.data
  } catch (error) {
    return null
  }
}

/**
 *
 * @returns refreshed sigma access token, refresh token and expiration time
 */
export const refreshSigmaAccessToken = async (
  refreshToken: string
): Promise<SigmaAccessToken> => {
  const SIGMA_ACCESS_TOKEN_API_URL = `/auth/token`
  const data = new URLSearchParams()

  data.append("grant_type", "refresh_token")
  data.append("client_id", SIGMA_API_CLIENT_ID)
  data.append("client_secret", SIGMA_API_SECRET)
  data.append("refresh_token", refreshToken)

  try {
    const response = await sigmaAuthHttpClient.post<SigmaAccessToken>(
      SIGMA_ACCESS_TOKEN_API_URL,
      data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )

    return response.data
  } catch (error) {
    return null
  }
}
