import React, { useEffect } from "react"
import type { FC } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Link as RouterLink, useParams } from "react-router-dom"

import { useResendEmail, useValidateAccount } from "@/services"
import type { ResendEmailError } from "@/services"
import type { AxiosError } from "axios"

import { Box, Button, Fade, Typography } from "@mui/material"

import { RootPath } from "../../models/route"
import { Form, FormTextField, SubmitButton, useForm } from "../../nzds"
import { If } from "../if/if"
import type { SignUpSchema } from "../sign-up/sign-up.schema"
import { VerifyEmail } from "../sign-up/verify-email/verify-email"
import { WhiteBox } from "../whiteBox"
import { reSendSchema } from "./re-send.schema"
import type { ReSendSchema } from "./re-send.schema"

const hasServerError = (
  error: AxiosError<ResendEmailError>,
  regex: RegExp
): boolean => {
  return !!error.response.data.errors.find((errorMessage) =>
    regex.test(errorMessage)
  )
}

export const ValidateAccount: FC = () => {
  const { token } = useParams<{ token: string }>()
  const {
    validateAccount,
    validateAccountData,
    validateAccountIsError,
    validateAccountIsLoading,
    validateAccountIsSuccess,
  } = useValidateAccount()

  const form = useForm({
    schema: reSendSchema,
    defaultValues: {
      email: "",
    },
  })

  const { resendEmail, resendEmailIsLoading, resendEmailIsSuccess } =
    useResendEmail({
      onError: (error): void => {
        const emailErrorMessage: string = hasServerError(error, /email/i)
          ? "Invalid email address"
          : ""

        if (emailErrorMessage) {
          form.setError("root.emailServerError", {
            type: error.response.statusText,
            message: emailErrorMessage,
          })
        }
      },
    })

  useEffect(() => {
    if (
      !validateAccountData &&
      !validateAccountIsError &&
      !validateAccountIsLoading &&
      !validateAccountIsSuccess
    ) {
      void validateAccount(token)
    }
  }, [
    token,
    validateAccount,
    validateAccountData,
    validateAccountIsError,
    validateAccountIsLoading,
    validateAccountIsSuccess,
  ])

  const handleSubmit: SubmitHandler<ReSendSchema> = (value): void => {
    void resendEmail(value.email)
  }

  return (
    <Fade in={validateAccountIsError || validateAccountIsSuccess}>
      <div>
        <WhiteBox>
          <If condition={validateAccountData?.data.success}>
            <Typography mb={4} textAlign="center" variant="h1">
              You&rsquo;re Verified
            </Typography>
            <Box textAlign="center">
              <Typography mb={4}>
                Your account has been successfully verified.
              </Typography>
              <Button
                color="primary"
                component={RouterLink}
                to={RootPath.Root}
                variant="contained"
              >
                Log In to NZero
              </Button>
            </Box>
          </If>

          <If condition={validateAccountIsError}>
            <If condition={!resendEmailIsSuccess}>
              <Typography mb={4} textAlign="center" variant="h1">
                Account Validation Failure
              </Typography>
              <Typography mb={4}>
                If you need a new verification email, please enter your email
                address below.
              </Typography>
              <Form<ReSendSchema> form={form} onSubmit={handleSubmit}>
                <Box mb={4}>
                  <FormTextField<SignUpSchema>
                    fullWidth
                    error={Boolean(
                      form.formState.errors.root?.emailServerError
                    )}
                    helperText={
                      form.formState.errors.root?.emailServerError?.message
                    }
                    id="email"
                    label="Email"
                    name="email"
                  />
                </Box>

                <SubmitButton
                  disabled={resendEmailIsLoading}
                  fullWidth
                  loading={resendEmailIsLoading}
                >
                  Resend Email
                </SubmitButton>
              </Form>
            </If>
            <If condition={resendEmailIsSuccess}>
              <Fade in={true}>
                <div>
                  <VerifyEmail email={form.getValues("email")} />
                </div>
              </Fade>
            </If>
          </If>
        </WhiteBox>
      </div>
    </Fade>
  )
}
