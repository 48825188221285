// @ts-strict-ignore
import type { FC } from "react"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, useFeature } from "@/services/feature"

import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"

import { DecimalWithPlaceholder } from "../../../../components/decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { Unit } from "../../../../components/unit/unit"
import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import { MeasurementSystem } from "../../../../models/measurementSystem"
import type Site from "../../../../models/site"
import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import { UnitName } from "../../../../models/unit"
import {
  getMeasurementSystem,
  squareFootToSquareMeter,
} from "../../../../utils"
import { SiteMap } from "../map/siteMap"
import { getSiteAddress } from "./siteInfoUtils"

interface SiteInfoContentProps {
  onClose: () => void
  site: Site | null
  /**
   * Optional content to display below the primary site info
   */
  // eslint-disable-next-line react/require-default-props
  supplementalInfo?: React.ReactNode
}

export const SiteInfoContent: FC<SiteInfoContentProps> = ({
  onClose,
  site,
  supplementalInfo,
}) => {
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  const { i18n } = useTranslation()
  const isMetric = useMemo(
    () => getMeasurementSystem(i18n.language) !== MeasurementSystem.USCU,
    [i18n.language]
  )
  const siteArea = isMetric
    ? squareFootToSquareMeter(Number(site?.sqFt))
    : site?.sqFt

  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const siteAddress = getSiteAddress(site?.address)
  return (
    <Stack gap={2} height="100%" p={2} pt={0} sx={{ overflow: "auto" }}>
      <Box
        pt={2}
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
          position: "sticky",
          top: 0,
          zIndex: 4, // Above the Google map
        }}
      >
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant={TYPOGRAPHY_VARIANT.h2}>{site?.name}</Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <Divider />
      </Box>
      <Box>
        <Box py={2}>
          <Grid2 container spacing={1}>
            <Grid2 xs={6}>
              <Typography fontWeight="fontWeightBold" variant="body2">
                Location
              </Typography>
            </Grid2>
            <Grid2 xs={6}>
              {site?.address ? (
                <Stack direction="column">
                  <Typography variant="body2">
                    {/* migrate directories to strict mode */}
                    {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                    {site.address.address_line_1 ?? ""}
                    {site.address.address_line_2
                      ? ` ${site.address.address_line_2}`
                      : ""}
                    ,
                  </Typography>
                  <Typography variant="body2">
                    {/* migrate directories to strict mode */}
                    {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                    {site.address.city ?? ""}, {site.address.state ?? ""}{" "}
                    {/* migrate directories to strict mode */}
                    {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                    {site.address.postal_code ?? ""}
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="body2">No Address Found</Typography>
              )}
            </Grid2>
            <Grid2 xs={6}>
              <Typography fontWeight="bold" variant="body2">
                {isUseFlexibleHierarchyEnabled ? "Group" : "Department"}
              </Typography>
            </Grid2>
            <Grid2 xs={6}>
              <Typography variant="body2">
                {/* migrate directories to strict mode */}
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                {site?.departmentName ??
                  `No ${
                    isUseFlexibleHierarchyEnabled ? "Group" : "Department"
                  } Found`}{" "}
              </Typography>
            </Grid2>
          </Grid2>
        </Box>
        <Divider />
      </Box>

      <SiteMap address={siteAddress} />

      <Divider />
      <Box>
        <Grid2 container spacing={1}>
          <Grid2 xs={6}>
            <Typography fontWeight="fontWeightBold" variant="body2">
              Building Type
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body2">
              {site?.buildingType ?? "No Building Type Found"}
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography fontWeight="fontWeightBold" variant="body2">
              Building Area
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body2">
              <DecimalWithPlaceholder
                format={{ precision: 1 }}
                value={siteArea}
              />{" "}
              <Unit unit={UnitName.SquareFoot} />
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography fontWeight="fontWeightBold" variant="body2">
              Year Built
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body2">
              {site?.yearBuilt ?? "No Year Built Found"}
            </Typography>
          </Grid2>
        </Grid2>
      </Box>

      {supplementalInfo ? (
        <>
          <Divider />
          {supplementalInfo}
        </>
      ) : null}
    </Stack>
  )
}
