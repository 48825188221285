import { faker } from "@faker-js/faker"
import { z } from "zod"

import { generateMocks } from "./generateMocks"
import { s } from "./schema"

export const locationsSchema = s.define("locations", {
  name: z.string().min(3),
})

export const peopleSchema = s.define("people", {
  firstName: z.string().min(3),
  lastName: z.string().min(3),
  position: z
    .enum(["ceo", "manager", "engineer"])
    .describe("no relations here, just an array"),
  markets: s
    .hasMany("locations")
    .nullable()
    .describe("a person can be responsible for more than one location"),
  location: s
    .hasOne("locations")
    .describe("a person cannot live in more than one location at once"),
  company: s.belongsTo("companies"),
})

export const companiesSchema = s.define("companies", {
  name: z.string().min(3).max(10),
  mission: z.string().min(10),
  headquarters: s.hasOne("locations"),
  people: s.hasMany("people").nullable(),
  locations: s.hasMany("locations"),
})

export const mockRawSchemas = {
  locations: locationsSchema,
  people: peopleSchema,
  companies: companiesSchema,
} as const

export const mockRawSchemasArr = Object.freeze(Object.values(mockRawSchemas))

export const mockSchemaNames = Object.keys(
  mockRawSchemas
) as readonly (keyof typeof mockRawSchemas)[]

export const expectedRelationshipsBySchema = {
  locations: [],
  people: ["markets", "location", "company"],
  companies: ["headquarters", "people", "locations"],
}

export const mockHydratedSchemas = s.hydrate(mockRawSchemasArr)

// migration to strict mode batch disable
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const mockValues = generateMocks(mockRawSchemasArr, {
  locations: {
    count: 10,
    overrides: {
      name: () => faker.address.cityName(),
    },
    relationships: {},
  },
  people: {
    overrides: {
      firstName: () => faker.name.firstName(),
      lastName: () => faker.name.lastName(),
    },
    relationships: { markets: true, location: true, company: true },
  },
  companies: {
    count: { min: 3 },
    overrides: {
      mission: () => `To ${faker.company.bs()}.`,
      name: () => faker.company.name(),
    },
    relationships: {
      headquarters: true,
      people: { max: 10 },
      locations: true,
    },
  },
})
