/* eslint-disable no-restricted-syntax */
import type { FC } from "react"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useOrganization } from "@/services/organization"
import {
  useCreateOrganizationalUnit,
  useOrganizationalUnits,
} from "@/services/organizationalUnit"

import AddRoundedIcon from "@mui/icons-material/AddRounded"
import { Button, Stack } from "@mui/material"
import type { GridRowSelectionModel } from "@mui/x-data-grid-pro"

import { HStack } from "../../../../nzds"
import { useDisclosure } from "../../../../nzds/utils/use-disclosure/use-disclosure"
import { CreateOUDialog } from "../../components/dialog/create-ou-dialog/create-ou-dialog"
import { EditOUDialog } from "../../components/dialog/edit-ou-dialog/edit-ou-dialog"
import { MoveItemsDialog } from "../../components/dialog/move-items-dialog/move-items-dialog"
import { DeleteGroupsButton } from "../../components/organize-data-grid/delete-groups-button/delete-groups-button"
import { MoveItemsButton } from "../../components/organize-data-grid/move-items-button/move-items-button"
import { OrganizeDataGrid } from "../../components/organize-data-grid/organize-data-grid"
import { useDataGridContext } from "../../contexts/data-grid-provider"
import type { HierarchyDataGridRow } from "../../hooks/use-hierarchy-tree-data/types"
import { useHierarchyTreeData } from "../../hooks/use-hierarchy-tree-data/use-hierarchy-tree-data"

export const OrganizeRoute: FC = () => {
  const params = useParams()
  const { orgId } = params
  const { organization } = useOrganization(orgId)
  const { mutateAsync } = useCreateOrganizationalUnit()
  const { organizationalUnits, isOrganizationalUnitsSuccess } =
    useOrganizationalUnits(orgId)
  // migration to strict mode batch disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const rootOU = organizationalUnits?.find((ou) => ou.parentId === null)

  // This useEffect automatically creates an organization's root OU
  // if it doesn't exist and the org is enabled for Flexibile Hierarchy's UI.
  // The root OU is necessary for the organize route to function.
  useEffect(() => {
    const createRootOU = async () => {
      await mutateAsync({
        name: `${organization.name} - Root`,
        organizationId: Number(orgId),
        parentId: null,
      })
    }
    if (orgId && organization.name && isOrganizationalUnitsSuccess && !rootOU) {
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      createRootOU()
    }
  }, [
    rootOU,
    isOrganizationalUnitsSuccess,
    mutateAsync,
    orgId,
    organization.name,
  ])

  const { rows, isHierarchyLoading } = useHierarchyTreeData(orgId)
  const { editedOU, setEditedOU } = useDataGridContext()
  const {
    isOpen: isCreateDialogOpen,
    onOpen: onCreateDialogOpen,
    onClose: onCreateDialogClose,
  } = useDisclosure()

  const {
    isOpen: isMoveItemsDialogOpen,
    onOpen: onMoveItemsDialogOpen,
    onClose: onMoveItemsDialogClose,
  } = useDisclosure()

  const [selectedRows, setSelectedRows] = useState<HierarchyDataGridRow[]>([])

  const handleEditDialogClose = () => {
    setEditedOU(null)
  }

  const handleRowSelect = useCallback(
    (ids: GridRowSelectionModel) => {
      const newSelectedRows = rows.filter((row) =>
        ids.includes(`${row.type}-${row.id}`)
      )
      setSelectedRows(newSelectedRows)
    },
    [rows]
  )

  return (
    <Stack width="100%">
      <HStack
        alignItems="flex-start"
        justifyContent="space-between"
        marginBottom={2}
      >
        <Button
          onClick={onCreateDialogOpen}
          size="small"
          startIcon={<AddRoundedIcon />}
          sx={{
            backgroundColor: "primary.light",
            color: (theme) => theme.palette.spectrum.blue[800],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.spectrum.blue[200], // 20% darker color
            },
          }}
          variant="contained"
        >
          Create New Group (OU)
        </Button>

        <HStack justifyContent="space-around">
          <MoveItemsButton
            onClick={onMoveItemsDialogOpen}
            selectedRows={selectedRows}
          />
          <DeleteGroupsButton selectedRows={selectedRows} />
        </HStack>
      </HStack>

      <OrganizeDataGrid
        isLoading={isHierarchyLoading}
        onRowSelect={handleRowSelect}
        rows={rows}
      />

      {editedOU ? (
        <EditOUDialog
          onClose={handleEditDialogClose}
          open={!!editedOU}
          organizationalUnit={editedOU}
        />
      ) : null}

      {organizationalUnits ? (
        <MoveItemsDialog
          onClose={onMoveItemsDialogClose}
          open={isMoveItemsDialogOpen}
          organizationalUnits={organizationalUnits}
          selectedRows={selectedRows}
        />
      ) : null}

      <CreateOUDialog
        onClose={onCreateDialogClose}
        open={isCreateDialogOpen}
        organizationId={Number(orgId)}
        parentId={Number(rootOU?.id)}
      />
    </Stack>
  )
}
