import React from "react"

import type { Moment } from "moment"

import EventRoundedIcon from "@mui/icons-material/EventRounded"
import { useTheme } from "@mui/material"
import type { DateRange } from "@mui/x-date-pickers-pro"
import {
  LocalizationProvider,
  DateRangePicker as MuiDateRangePicker,
} from "@mui/x-date-pickers-pro"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment"

import type { DateRangePickerProps } from "./date-range-picker.types"

/**
 * A component that allows a user to select a date range by day across years
 *
 * @param isLoading - Whether the component is loading
 * @param maxDate - The maximum date the date range can be
 * @param minDate - The minimum date the date range can be
 * @param onChange - The action taken when the date range changes
 * @param value - The dates displayed in the start and end date pickers
 * @example
 *
 * ```tsx
 * import { DateRangePicker } from 'nzds'
 *
 * <DateRangePicker
 *  isLoading={isLoading}
 *  maxDate={maxDate}
 *  minDate={minDate}
 *  onChange={handleDateChange}
 *  value={{
 *  start: filters.start.value,
 *  end: filters.end.value,
 *  }}
 * />
 * ```
 */
export const DateRangePicker = ({
  isLoading,
  maxDate,
  minDate,
  onChange,
  value,
}: DateRangePickerProps) => {
  const theme = useTheme()

  const handleChange = (newValue: DateRange<Moment>) => {
    const newStartValue = newValue[0]
    const newEndValue = newValue[1]

    if (newStartValue?.isValid() && newEndValue?.isValid()) {
      onChange({
        start: newStartValue,
        end: newEndValue,
      })
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDateRangePicker
        calendars={1}
        disabled={isLoading}
        localeText={{
          start: "",
          end: "",
        }}
        maxDate={maxDate}
        minDate={minDate}
        onAccept={handleChange}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <EventRoundedIcon
                  sx={{
                    color: theme.palette.action.active,
                    left: "-1px",
                    position: "relative",
                    top: "-1px",
                  }}
                />
              ),
            },
            size: "small",
            sx: { width: theme.typography.pxToRem(184) },
          },
        }}
        value={value}
      />
    </LocalizationProvider>
  )
}
