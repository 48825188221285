import type { ReactNode } from "react"
import React from "react"

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Stack } from "@mui/material"

import { OrgViewDetailHeader } from "./orgViewDetailHeader/orgViewDetailHeader"

interface IOrgViewDetailProps {
  children: ReactNode
  createButton?: React.JSX.Element
  datUrl: string
  dateCreated: string
  dateUpdated: string
  deleteButton?: React.JSX.Element
  entityName: string
  webAppUrl?: string
}

const OrgViewDetail = ({
  children,
  dateCreated,
  dateUpdated,
  datUrl,
  entityName,
  createButton,
  deleteButton,
  webAppUrl,
}: IOrgViewDetailProps) => (
  <Stack
    direction="column"
    sx={{
      height: "100%",
    }}
  >
    <OrgViewDetailHeader
      createButton={createButton}
      dateCreated={dateCreated}
      dateUpdated={dateUpdated}
      datUrl={datUrl}
      deleteButton={deleteButton}
      entityName={entityName}
      webAppUrl={webAppUrl}
    />
    {children}
  </Stack>
)

export default OrgViewDetail
