import { useCallback, useMemo, useState } from "react"
import type { NavigateFunction } from "react-router-dom"
import { useLocation, useNavigate } from "react-router-dom"

import type { Location } from "history"

import type { FilterClass, IFilter } from "../../models/filter/filter"

export interface IUseFiltersSetOptions {
  replace?: boolean
}

interface IUseFiltersValue<TFilters> {
  filters: TFilters
  setFilters: (
    filters: Partial<TFilters>,
    options?: IUseFiltersSetOptions
  ) => void
}

/**
 * Determines whether or not the new filter values are different from the previous filter values
 *
 * @param prevFilters - The previous filters
 * @param newFilters - The new filters
 * @returns Whether or not the new filter values are different from the previous filter values
 * @example
 * areFiltersChanged<IReportFilters>(filters, newFilters)
 */
const areFiltersChanged = <TFilters>(
  prevFilters: TFilters,
  newFilters: Partial<TFilters>
): boolean =>
  Object.entries(newFilters).some(
    ([newFilterName, newFilter]: [string, IFilter<unknown, unknown>]) => {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const prevFilter: IFilter<unknown, unknown> = prevFilters[newFilterName]

      return !prevFilter.isEqualTo(newFilter)
    }
  )

/**
 * The `useFilters` hook returns a set of `filters` based on the url search params
 * and a `setFilters` function to update the url based on a set of filters.
 *
 * @param initialFilterTypes - An object whose keys are filter names and values are filter classes
 * @returns The filters and a filters setter
 * @example
 * const { filters, setFilters } = useFilters<IReportFilters>({
 *   start: DateFilter,
 *   end: DateFilter,
 *   summary: BooleanFilter,
 *   category: SingleSelectNumberFilter,
 *   subcategory: MultiSelectNumberFilter,
 * })
 */
const useFilters = <TFilters>(
  initialFilterTypes: Record<keyof TFilters, FilterClass>
): IUseFiltersValue<TFilters> => {
  let navigate: NavigateFunction
  let location: Location

  try {
    navigate = useNavigate()
    location = useLocation()
  } catch (error) {
    throw new Error("'useFilters' must be used within a 'Router'")
  }

  // Store initialFilterTypes in state so it's the same object used in dependency arrays
  const [filterTypes] =
    useState<Record<keyof TFilters, FilterClass>>(initialFilterTypes)

  // The set of filters derived from the url
  // The url is the source of truth
  const filters: TFilters = useMemo((): TFilters => {
    const searchParams: URLSearchParams = new URLSearchParams(
      decodeURIComponent(location.search)
    )

    const filtersFromQueryString: TFilters = Object.entries(filterTypes).reduce(
      (prevFilters, [filterName, Class]: [string, FilterClass]) => {
        const newFilters = { ...prevFilters }
        newFilters[filterName] = Class.fromQueryParamValue(
          searchParams.get(filterName)
        )

        return newFilters
      },
      // We will want to address this in the future
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
      {} as TFilters
    )

    return filtersFromQueryString
  }, [filterTypes, location.search])

  // Update the url if filter values have changed
  // The url is the source of truth
  const setFilters = useCallback(
    (
      newFilters: Partial<TFilters>,
      options: IUseFiltersSetOptions = { replace: false }
    ): void => {
      const urlSearchParams = new URLSearchParams(
        decodeURIComponent(location.search)
      )

      // Only set filters if at least one has changed to avoid unnecessary render cycles
      if (areFiltersChanged<TFilters>(filters, newFilters)) {
        Object.entries(newFilters).forEach(
          ([newFilterName, newFilter]: [string, IFilter<unknown, unknown>]) => {
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const prevFilter: IFilter<unknown, unknown> = filters[newFilterName]
            const prevFilterHasValue: boolean = prevFilter.value !== null
            const newFilterHasValue: boolean = newFilter.value !== null
            const shouldDeleteFilter: boolean =
              prevFilterHasValue && !newFilterHasValue
            const shouldUpdateFilter: boolean =
              !shouldDeleteFilter && !prevFilter.isEqualTo(newFilter)

            if (shouldDeleteFilter) {
              urlSearchParams.delete(newFilterName)
            } else if (shouldUpdateFilter) {
              urlSearchParams.set(newFilterName, newFilter.toQueryParamValue())
            }
          }
        )

        if (options.replace) {
          navigate({ search: urlSearchParams.toString() }, { replace: true })
        } else {
          navigate({ search: urlSearchParams.toString() })
        }
      }
    },
    [filters, navigate, location.search]
  )

  return useMemo(
    () => ({
      filters,
      setFilters,
    }),
    [filters, setFilters]
  )
}

export default useFilters
