import { useQuery } from "react-query"

import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import DepartmentModel from "../../models/department"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useDepartment = (
  departmentId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const { getOne } = useSpraypaintModel(DepartmentModel)

  const queryKey = useQueryKey(ApiQueryName.Department, "getOne", departmentId)

  const queryResult = useQuery({
    queryKey,
    queryFn: () => getOne(departmentId),
    enabled: !isNil(departmentId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type Department = InferredQueryServiceValue<
  typeof useDepartment,
  ApiQueryName.Department
>
