import React from "react"
import type { FC } from "react"

import { useFormatMoney } from "@/formatters/useFormatMoney"

import type { ComplianceFinesProps } from "./compliance-fines.types"

export const ComplianceFines: FC<ComplianceFinesProps> = ({ regulations }) => {
  const { format: formatMoney } = useFormatMoney({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const fines: number | null = regulations?.reduce(
    (total: number | null, regulation) => {
      if (
        !Number.isFinite(total) &&
        (regulation.regulation_name === "No Regulation" ||
          regulation.is_supported_without_fines)
      ) {
        return null
      }

      if (!Number.isFinite(total)) {
        return regulation.penalties
      }

      return total + regulation.penalties
    },
    null
  )

  return <>{!regulations?.length ? "--" : formatMoney(fines)}</>
}
