import React from "react"
import type { FC } from "react"

import { Box } from "@mui/material"

import type { PerformanceTierId } from "../performance-tier.types"
import type { PerformanceTierIconProps } from "./performance-tier-icon.types"

interface ColorScheme {
  bgcolor: string
  borderColor: string
}

const tierToColorScheme: Record<PerformanceTierId, ColorScheme> = {
  atBenchmark: {
    bgcolor: "spectrum.mustard.100",
    borderColor: "spectrum.mustard.400",
  },
  efficient: {
    bgcolor: "spectrum.green.50",
    borderColor: "spectrum.green.400",
  },
  extremelyInefficient: {
    bgcolor: "spectrum.red.100",
    borderColor: "spectrum.red.400",
  },
  highlyEfficient: {
    bgcolor: "spectrum.green.100",
    borderColor: "spectrum.green.400",
  },
  inefficient: {
    bgcolor: "spectrum.red.50",
    borderColor: "spectrum.red.400",
  },
}

export const PerformanceTierIcon: FC<PerformanceTierIconProps> = ({ tier }) => {
  return (
    <Box
      border={2}
      {...tierToColorScheme[tier]}
      borderRadius="50%"
      minHeight={16}
      width={16}
    />
  )
}
