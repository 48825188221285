import type { FC } from "react"
import React, { useMemo } from "react"
import { useParams } from "react-router-dom"

import { useDialog } from "@/services/dialog"
import { sortBy } from "lodash"

import { Paper } from "@mui/material"

import {
  DeveloperAdminPath,
  RootPath,
  TamAdminPath,
} from "../../../../models/route"
import type { MeterCounts } from "../../../../services"
import { useMeter, useMeterCounts } from "../../../../services"
import type { IOrgViewDisplayCount } from "../../models/onboarding/onboarding"
import { meterDisplayCounts } from "../../models/onboarding/onboarding"
import { convertToDisplayCounts } from "../../util/onboarding/onboarding"
import OrgViewDeleteDialog from "../tamAdminOnboarding/orgView/orgViewDeleteDialog/orgViewDeleteDialog"
import OrgViewDetail from "../tamAdminOnboarding/orgView/orgViewDetail/orgViewDetail"
import OrgViewDetailEntityDeleteButton from "../tamAdminOnboarding/orgView/orgViewDetail/orgViewDetailEntityDeleteButton/orgViewDetailEntityDeleteButton"
import OrgViewDetailLinkSection from "../tamAdminOnboarding/orgView/orgViewDetailLinkSection/orgViewDetailLinkSection"
import OrgViewMeterDetail from "../tamAdminOnboarding/orgView/orgViewMeterDetail/orgViewMeterDetail"

export const MeterDetail: FC = () => {
  const params = useParams()
  const { siteId, orgId, billingGroupId, meterId } = params
  const { meter, isMeterLoading } = useMeter(meterId)

  const { meterCounts } = useMeterCounts(meterId)

  const currentMeterDisplayCounts: IOrgViewDisplayCount<MeterCounts>[] =
    useMemo(
      () =>
        sortBy(
          convertToDisplayCounts<MeterCounts>(meterCounts, meterDisplayCounts),
          "order"
        ),
      [meterCounts]
    )

  const {
    onDialogClose,
    onDialogOpen,
    isDialogOpen,
    dialogActions,
    dialogContent,
    dialogTitle,
  } = useDialog()

  const canEntityBeDeleted = (numberOfChildObjects: number) =>
    !numberOfChildObjects

  return (
    <>
      <Paper
        sx={{
          flex: 1,
          maxWidth: { lg: "calc(50% - 8px)" },
          padding: (theme) => theme.typography.pxToRem(24),
        }}
        variant="outlined"
      >
        <OrgViewDetail
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          dateCreated={meter?.dateCreated}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          dateUpdated={meter?.dateUpdated}
          datUrl={`${RootPath.DeveloperAdmin}${DeveloperAdminPath.Meter}/${meter?.id}`}
          deleteButton={
            <OrgViewDetailEntityDeleteButton
              canEntityBeDeleted={canEntityBeDeleted(
                meter?.childMeterNamesAndIds?.length
              )}
              entityId={meterId}
              entityName="Meter"
              isButtonDisabled={isMeterLoading}
              navLink={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites/${siteId}/billing-group/${billingGroupId}`}
              onDeleteDialogClose={onDialogClose}
              onDeleteDialogOpen={onDialogOpen}
            />
          }
          entityName="Meter"
        >
          <OrgViewMeterDetail
            linkSection={
              <OrgViewDetailLinkSection
                counts={currentMeterDisplayCounts}
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                id={meter?.id}
              />
            }
            meterId={meterId}
          />
        </OrgViewDetail>
      </Paper>
      <OrgViewDeleteDialog
        actions={dialogActions}
        content={dialogContent}
        onClose={onDialogClose}
        open={isDialogOpen}
        title={dialogTitle}
      />
    </>
  )
}
