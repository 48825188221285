import type { FC, MouseEvent } from "react"
import React, { useState } from "react"
import type { SubmitHandler } from "react-hook-form"

import { passwordRequirementsMessage } from "@/schemas"

import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, IconButton, InputAdornment, Typography } from "@mui/material"

import { Form, FormTextField, SubmitButton, useForm } from "../../../nzds"
import type { ResetPasswordSchema } from "./reset-password-form.schema"
import { resetPasswordSchema } from "./reset-password-form.schema"
import type { ResetPasswordFormProps } from "./reset-password-form.types"

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  onSubmit,
  user,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const defaultFormValues: ResetPasswordSchema = {
    confirmPassword: "",
    password: "",
  }

  const form = useForm({
    schema: resetPasswordSchema,
    defaultValues: defaultFormValues,
  })

  const handlePasswordVisibleClick = (): void => {
    setIsPasswordVisible((isVisible) => !isVisible)
  }

  const handlePasswordVisibleMouseDown = (event: MouseEvent): void => {
    event.preventDefault()
  }

  const handleSubmit: SubmitHandler<ResetPasswordSchema> = (value): void => {
    onSubmit(value)
  }

  return (
    <>
      <Typography mb={4}>
        {`Enter a new password for ${user?.email}`}
      </Typography>

      <Form<ResetPasswordSchema> form={form} onSubmit={handleSubmit}>
        <Box mb={1.5}>
          <FormTextField<ResetPasswordSchema>
            fullWidth
            helperText={passwordRequirementsMessage}
            id="password"
            inputProps={{
              autoComplete: "new-password",
              type: isPasswordVisible ? "text" : "password",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handlePasswordVisibleClick}
                    onMouseDown={handlePasswordVisibleMouseDown}
                    edge="end"
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="New Password"
            name="password"
          />
          <FormTextField<ResetPasswordSchema>
            fullWidth
            id="confirmPassword"
            inputProps={{
              autoComplete: "new-password",
              type: isPasswordVisible ? "text" : "password",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handlePasswordVisibleClick}
                    onMouseDown={handlePasswordVisibleMouseDown}
                    edge="end"
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Confirm New Password"
            name="confirmPassword"
          />
        </Box>

        <SubmitButton fullWidth>Reset Password</SubmitButton>
      </Form>
    </>
  )
}
