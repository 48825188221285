import { Language, LanguageRegion } from "../models/i18n"
import type { IMeasurementSystem } from "../models/measurementSystem"
import { MeasurementSystem } from "../models/measurementSystem"

export const getMeasurementSystem = (
  locale: LanguageRegion | Language | string
): IMeasurementSystem => {
  if (
    locale !== LanguageRegion.EnglishUnitedStates &&
    locale !== Language.English
  ) {
    return MeasurementSystem.SI
  }
  return MeasurementSystem.USCU
}
