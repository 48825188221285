import { useMutation } from "react-query"

import { ApiQueryName } from "../../models/api"
import Department from "../../models/department"
import type { QueryMutation, UseCreateEntityOptions } from "../types"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useCreateDepartment = ({ onSuccess }: UseCreateEntityOptions) => {
  const mutationKey = useQueryKey(ApiQueryName.Department, "createOne")
  const { create } = useSpraypaintModel(Department)
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      newAttributes,
    }: Parameters<
      QueryMutation<"createOne", ModelAttributes<Department>>
    >[0]) => {
      const department = await create(newAttributes)
      return department
    },
    onSuccess: (department) => {
      onSuccess?.(department?.id)
      invalidateQueries([
        createQueryKey(ApiQueryName.Departments, "getMany"),
        createQueryKey(ApiQueryName.OrganizationCounts, "getOne"),
      ])
    },
  })

  return useMutationReturnHandle(mutationKey, mutationResult)
}
