// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Organization } from "@/services/organization/useOrganization"

import MonthSummary from "../../../../models/monthSummary"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"

interface GroupSummary {
  confirmCostMinor: number | null
  costMajor: number | null
  currencyId: number | null
  energyCostMinor: number | null
  id: number | string | null
  imbalanceCostMinor: number | null
  meteredKwh: number | null
  schedulingCostMinor: number | null
  transmissionCostMajor: number | null
}

interface IDepartmentSummary extends GroupSummary {
  departmentId: number | null
  departmentName: string
}

interface OrganizationChildrenSummary extends GroupSummary {
  organizationalUnitId: number | null
  organizationalUnitName: string
}

interface IDepartmentSummaryDto {
  confirm_cost_minor: string
  cost_major: string
  currency_id: number
  department_id: number
  department_name: string
  energy_cost_minor: string
  id: number | string | null
  imbalance_cost_minor: string
  metered_kwh: string
  scheduling_cost_minor: string
  sq_ft: string | null
  transmission_cost_major: string
}

interface OrganizationChildrenSummaryDto {
  confirm_cost_minor: string
  cost_major: string
  currency_id: number
  energy_cost_minor: string
  id: number | string | null
  imbalance_cost_minor: string
  metered_kwh: string
  organizational_unit_id: number
  organizational_unit_name: string
  scheduling_cost_minor: string
  sq_ft: string | null
  transmission_cost_major: string
}

const transformTotalValuesToNumeric = (totalValues: IDepartmentSummaryDto) => ({
  ...totalValues,
  confirm_cost_minor: Number.parseFloat(totalValues.confirm_cost_minor),
  cost_major: Number.parseFloat(totalValues.cost_major),
  energy_cost_minor: Number.parseFloat(totalValues.energy_cost_minor),
  imbalance_cost_minor: Number.parseFloat(totalValues.imbalance_cost_minor),
  metered_kwh: Number.parseFloat(totalValues.metered_kwh),
  scheduling_cost_minor: Number.parseFloat(totalValues.scheduling_cost_minor),
  sq_ft: Number.parseFloat(totalValues.sq_ft),
  transmission_cost_major: Number.parseFloat(
    totalValues.transmission_cost_major
  ),
})

const getDepartmentTotals = async (
  orgId,
  startDate,
  endDate
): Promise<IDepartmentSummary[]> => {
  const response = MonthSummary.where({
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    organization_id: orgId,
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    start_date: startDate.format("YYYY-MM-DD"),
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    end_date: endDate.clone().endOf("month").format("YYYY-MM-DD"),
  })
    .stats({
      department_totals: "calculate",
    })
    .per(0)
    .all()
  return response.then((result) => {
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data: IDepartmentSummary[] = snakeCaseToCamelCase(
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      result.meta.stats.department_totals.calculate.map(
        transformTotalValuesToNumeric
      )
    )

    return data
  })
}

/**
 * The getOrganizationChildrenTotals function is a hook that returns the totals of the organization's children
 *
 * @param orgId - The id of the organization we are fetching the children totals for
 * @param startDate - The start date of the totals we are fetching
 * @param endDate - The end date of the totals we are fetching
 * @returns The organization children totals
 *
 * @example
 * ```tsx
 * import { getOrganizationChildrenTotals } from '@/services/useOrgSummaryStats/useOrgSummaryStatsUtils'
 *
 * const {
 *   organizationChildrenTotals,
 *   isOrganizationChildrenTotalsFetched,
 *   isOrganizationChildrenTotalsLoading
 * } = useOrganizationChildrenTotals(organizationId, startDate, endDate)
 * ```
 */
const getOrganizationChildrenTotals = async (
  orgId,
  startDate,
  endDate
): Promise<OrganizationChildrenSummary[]> => {
  const response = MonthSummary.where({
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    organization_id: orgId,
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    start_date: startDate.format("YYYY-MM-DD"),
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    end_date: endDate.clone().endOf("month").format("YYYY-MM-DD"),
  })
    .stats({
      organization_children_totals: "calculate",
    })
    .per(0)
    .all()
  return response.then((result) => {
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data: OrganizationChildrenSummary[] = snakeCaseToCamelCase(
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      result.meta.stats.organization_children_totals.calculate.map(
        transformTotalValuesToNumeric
      )
    )

    return data
  })
}

export {
  getDepartmentTotals,
  getOrganizationChildrenTotals,
  type GroupSummary,
  type IDepartmentSummary,
  type IDepartmentSummaryDto,
  type OrganizationChildrenSummary,
  type OrganizationChildrenSummaryDto,
}
