import React from "react"

import { ToggleButton, ToggleButtonGroup } from "@mui/material"

import { UnitName } from "../models/unit"
import { Unit } from "./unit/unit"

const X_OFFSET = -210
const Y_OFFSET = 40

export interface Config {
  closePopup: () => void
  location: Location
  recomputeLocation: () => void
  setWattUnit: (u: UnitName.KilowattHour | UnitName.MegawattHour) => void
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
}

export interface Location {
  left: number
  top: number
}

export const SettingsPopup = ({
  closePopup,
  location: { left, top },
  recomputeLocation,
  setWattUnit,
  wattUnit,
}: Config) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      closePopup()
    }
  }

  React.useEffect(() => {
    window.addEventListener("resize", recomputeLocation)
    return () => {
      window.removeEventListener("resize", recomputeLocation)
    }
  }, [])
  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    unit: UnitName.KilowattHour | UnitName.MegawattHour
  ) => {
    if (unit !== null) {
      setWattUnit(unit)
    }
  }

  return (
    <div
      className="settings-popup"
      style={{ left: left + X_OFFSET, top: top + Y_OFFSET }}
    >
      <div className="settings-popup__row energy-axis">
        <span className="energy-axis__label">Energy axis</span>
        <ToggleButtonGroup
          aria-label="Energy Unit"
          color="neutral"
          exclusive
          onChange={handleChange}
          size="small"
          value={wattUnit}
        >
          <ToggleButton value={UnitName.KilowattHour}>
            <Unit unit={UnitName.KilowattHour} />
          </ToggleButton>
          <ToggleButton value={UnitName.MegawattHour}>
            <Unit unit={UnitName.MegawattHour} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  )
}
