import React from "react"
import type { FC } from "react"

import { Dialog, DialogContent, Link, Typography } from "@mui/material"

import { DialogTitle } from "../../../../../nzds/feedback/dialog-title/dialog-title"
import { completedTemplateExportLink } from "../../constants"
import type { TemplateGuidelinesDialogProps } from "./template-guidelines-dialog.types"

export const TemplateGuidelinesDialog: FC<TemplateGuidelinesDialogProps> = ({
  open,
  onClose,
}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      aria-describedby="template-guidelines-dialog-description"
      aria-labelledby="template-guidelines-dialog-title"
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="template-guidelines-dialog-title" onClose={handleClose}>
        Template Guidelines
      </DialogTitle>
      <DialogContent id="template-guidelines-dialog-description">
        <Typography component="h3" variant="h4" sx={{ fontWeight: "bold" }}>
          Instructions
        </Typography>
        <ol style={{ marginTop: 0 }}>
          <li>Open the Excel File that was just downloaded.</li>
          <li>Populate the template according to the guidelines below.</li>
          <li>Save your Excel file as a CSV.</li>
          <li>Upload your CSV file for REP processing.</li>
        </ol>

        <Typography component="h3" variant="h4" sx={{ fontWeight: "bold" }}>
          Column Guidelines
        </Typography>
        <ul style={{ marginTop: 0 }}>
          <li>All columns are required except the department column.</li>
          <li>
            Ensure to include at least one electricity or natural gas usage for
            each site. You can include both if applicable.
          </li>
          <li>Site Name: Provide a unique identifier for each site.</li>
          <li>
            Zip Code: Enter the 5-digit numeric zip code. This helps in finding
            benchmarks and running simulations.
          </li>
          <li>
            Square Feet: Input the building area as a whole number without
            commas or decimals to determine compliance impacts and calculate
            building efficiency.
          </li>
          <li>
            Building Type: Choose from a list of all EnergyStar building types.
          </li>
          <li>Department: Optionally, add departments to group your sites.</li>
          <li>
            Annual Electricity Usage: Enter usage in kWh as a whole number
            without commas or decimals. If usage is calculated in different
            units, ensure it&rsquo;s converted to kWh.
          </li>
          <li>
            Annual Natural Gas Usage: Enter usage in therms as a whole number
            without commas or decimals. If calculated in different units,
            convert it to therms.
          </li>
        </ul>

        <Typography component="h3" variant="h4" sx={{ fontWeight: "bold" }}>
          Sample CSV File
        </Typography>
        <Typography>
          Download a{" "}
          <Link download href={completedTemplateExportLink} underline="none">
            completed CSV file
          </Link>{" "}
          with sample data to reference while filling out your building data.
        </Typography>

        <Typography
          component="h3"
          variant="h4"
          sx={{ fontWeight: "bold", mt: 2 }}
        >
          Disclaimer
        </Typography>
        <Typography>
          For the free version, compliance checks are available only for
          Energize Denver, LL97 (New York City), and BERDO (Boston).
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
