import React, { useEffect, useState } from "react"
import type { FC } from "react"

import { Button, HStack, useDisclosure } from "@/nzds"
import { useGtag } from "@/services/gtag"
import { useCopyToClipboard } from "@uidotdev/usehooks"

import CheckRoundedIcon from "@mui/icons-material/CheckRounded"
import CloseIcon from "@mui/icons-material/Close"
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded"
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined"
import {
  IconButton,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"

import type { ShareButtonProps } from "./share-button.types"

const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

export const ShareButton: FC<ShareButtonProps> = ({ title, iconButton }) => {
  const { gtagData } = useGtag()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [copiedText, copyToClipboard] = useCopyToClipboard()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const hasText = Boolean(copiedText)

  useEffect(() => {
    if (hasText) {
      void delay(2000).then(() => {
        void copyToClipboard("")
      })
    }
  }, [hasText, copyToClipboard])

  const currentHref = window.location.href

  const handleCopyButtonClick = () => {
    void copyToClipboard(currentHref)

    gtagData.gtag("event", "share", {
      content_type: "url",
      item_id: currentHref,
    })
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    onOpen()
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      {iconButton ? (
        <IconButton aria-label="Share" color="neutral" onClick={handleOpen}>
          <ShareOutlinedIcon />
        </IconButton>
      ) : (
        <Button
          color="neutral"
          onClick={onOpen}
          startIcon={<ShareOutlinedIcon />}
          variant="text"
        >
          Share
        </Button>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={onClose}
        open={isOpen}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack gap={1} p={3} width={(theme) => theme.typography.pxToRem(484)}>
          <HStack justifyContent="space-between">
            <Typography variant="h2">{title}</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </HStack>
          <HStack alignItems="center" gap={2}>
            <TextField
              aria-label="Share URL"
              fullWidth
              inputProps={{ readOnly: true }}
              value={currentHref}
            />
            <Tooltip
              open={hasText}
              placement="top"
              title="Link copied to clipboard!!"
            >
              <IconButton
                aria-label="Copy URL to clipboard"
                color="neutral"
                onClick={handleCopyButtonClick}
              >
                {hasText ? <CheckRoundedIcon /> : <ContentCopyRoundedIcon />}
              </IconButton>
            </Tooltip>
          </HStack>
        </Stack>
      </Popover>
    </>
  )
}
