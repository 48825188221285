import type { Feature } from "@/models/feature"
import type { User, UserCreate } from "@/models/user"
import type { UtilityCredentialsData } from "@/modules/utilityCredentials/model/utilityCredentials"
import axios from "axios"
import type { AxiosError, AxiosInstance, AxiosResponse } from "axios"

import { initCaseResponseInterceptor } from "./interceptors"

export interface SuccessData {
  success: boolean
}

export interface ErrorData extends SuccessData {
  error: string
  success: boolean
}

export interface TokenUser {
  email: string
  id: string
}

export interface GetUserByPasswordResetTokenData extends SuccessData {
  user: TokenUser
}

export interface LoginResponse {
  authenticationToken: string
  success: boolean
}

export const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL ?? "",
})

// Set up interceptors on the Axios instance
initCaseResponseInterceptor(httpClient)

interface FetchFeaturesResponse {
  features: Feature[]
}

export const fetchFeatures = async (): Promise<Feature[]> => {
  try {
    const response = await httpClient.get<FetchFeaturesResponse>(
      "/flipper/api/features"
    )

    return response.data.features
  } catch {
    console.error("Error getting features")
    return []
  }
}

export const createUser = async (
  newUser: UserCreate
): Promise<AxiosResponse<SuccessData>> => {
  try {
    const response = await httpClient.post<SuccessData>("/api/v1/users", {
      user: newUser,
    })

    return response
  } catch {
    console.error("Error creating user")
  }
}

interface GetUserResponse {
  data: {
    attributes: User
    id: string
    type: "users"
  }
}

// We are not returning a User class instance but instead a pojo that represents a user
export const getUser = async (params = {}): Promise<User | null> => {
  try {
    const response = await httpClient.get<GetUserResponse>("/api/v1/user", {
      params,
    })

    return {
      ...response.data.data.attributes,
      id: response.data.data.id,
    } as User
  } catch (error) {
    const { response } = error as AxiosError<string>
    console.warn(response.data)

    return null
  }
}

export const doLogin = async ({
  data = {},
  params = {},
}): Promise<LoginResponse | null> => {
  try {
    const response = await httpClient.post<LoginResponse>(
      "/api/v1/sessions",
      data,
      params
    )

    return response.data
  } catch (error) {
    const { response } = error as AxiosError<ErrorData>
    console.warn(response.data.error)

    return null
  }
}

export const doLogout = async (params = {}): Promise<void> => {
  try {
    await httpClient.delete("/api/v1/sessions", params)

    return undefined
  } catch {
    console.error("Error logging out")
  }
}

export const sendForgotPasswordEmail = async (params): Promise<SuccessData> => {
  try {
    const response = await httpClient.post<SuccessData>(
      "/api/v1/forgot_password/",
      params
    )

    return response.data
  } catch {
    console.error("Error sending forgot password email")
  }
}

export const getUserByPasswordResetToken = async (
  token
): Promise<TokenUser | null> => {
  try {
    const response = await httpClient.get<GetUserByPasswordResetTokenData>(
      `/api/v1/password_reset/${token}`
    )

    return response.data.success ? response.data.user : null
  } catch {
    console.error("Error getting user by password reset token")
    return null
  }
}

/**
 * @deprecated - Do not use. Slated for deletion.
 */
export const fetchUtilityCredentials = async (
  token
): Promise<UtilityCredentialsData> =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  httpClient
    .get(`/credentials/validate_token?token=${token}`)
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .then((response) => response.data)

/**
 * @deprecated - Do not use. Slated for deletion.
 */
export const postUtilityCredentials = async (data) =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  httpClient.post("/api/v1/credentials", data).then((response) => response.data)

export const updateUserPassword = async (
  token: string,
  params: { password: string }
): Promise<SuccessData | null> => {
  try {
    const response = await httpClient.patch<SuccessData>(
      `/api/v1/password_reset/${token}`,
      params
    )

    return response.data
  } catch {
    console.error("Error updating user password")
    return null
  }
}

export const parseFloatMetaStatSum = (metaStatSum: string | undefined) =>
  metaStatSum ? parseFloat(metaStatSum) : 0
