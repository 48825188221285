import React from "react"

import { Grid, Typography } from "@mui/material"

import { UnitName } from "../../models/unit"
import {
  formatEnergyVolumeInUnit,
  transformPricePerWhIntoUnit,
} from "../../utils/formatters"
import { CurrencyWithPlaceholder } from "../currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { UsageRateDecimal } from "../decimal/usageRateDecimal/usageRateDecimal"
import Metric from "../metric/metric"
import { Unit } from "../unit/unit"
import type { IOrgSummary } from "./organizationSummary"

export const FinancialSummaryMetrics = ({
  orgSummaryData,
  hasBundled,
  whDisplayUnit,
}: {
  hasBundled: boolean
  orgSummaryData: IOrgSummary
  whDisplayUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const { energyCost: orgEnergyCost, totalCost } = orgSummaryData
  const energyCost = hasBundled ? totalCost : orgEnergyCost
  const meteredEnergyWh = formatEnergyVolumeInUnit(
    orgSummaryData.meteredMwh,
    UnitName.MegawattHour,
    whDisplayUnit
  )
  const avgEnergyCost = transformPricePerWhIntoUnit(
    !meteredEnergyWh ? 0 : energyCost / meteredEnergyWh,
    whDisplayUnit,
    UnitName.KilowattHour
  )

  const lgBreakPoint = hasBundled ? 4 : 3
  const mdBreakPoint = hasBundled ? 4 : 6
  const xsBreakPoint = 12

  return (
    <Grid container mb={2} spacing={2}>
      <Grid item lg={lgBreakPoint} md={mdBreakPoint} xs={xsBreakPoint}>
        <Metric
          data-e2e="cost-value"
          title={hasBundled ? "Total Estimated Cost" : "Total Cost"}
        >
          {/* TODO: Address font size setting in _organizationSummary.scss */}
          <Typography>
            <CurrencyWithPlaceholder value={totalCost} />
          </Typography>
        </Metric>
      </Grid>
      <Grid item lg={lgBreakPoint} md={mdBreakPoint} xs={xsBreakPoint}>
        <Metric data-e2e="volume-value" title="Total Volume">
          {/* TODO: Address font size setting in _organizationSummary.scss */}
          <Typography>
            <UsageRateDecimal value={meteredEnergyWh} />
          </Typography>
          <Typography variant="caption">
            <Unit unit={whDisplayUnit} />
          </Typography>
        </Metric>
      </Grid>
      <Grid item lg={lgBreakPoint} md={mdBreakPoint} xs={xsBreakPoint}>
        <Metric data-e2e="avg-cost-value" title="Avg. Energy Cost">
          {/* TODO: Address font size setting in _organizationSummary.scss */}
          <Typography>
            <CurrencyWithPlaceholder
              format={{ maximumFractionDigits: 6, maximumSignificantDigits: 4 }}
              value={avgEnergyCost}
            />
          </Typography>
          <Typography variant="caption">
            <Unit unit={UnitName.PerKilowattHour} />
          </Typography>
        </Metric>
      </Grid>
      {!hasBundled && (
        <Grid item lg={lgBreakPoint} md={mdBreakPoint} xs={xsBreakPoint}>
          <Metric data-e2e="imbalance-value" title="Total Imbalance">
            {/* TODO: Address font size setting in _organizationSummary.scss */}
            <Typography>
              <CurrencyWithPlaceholder value={orgSummaryData?.imbalanceCost} />
            </Typography>
          </Metric>
        </Grid>
      )}
    </Grid>
  )
}
