import { scaleLinear, select } from "d3"

const setTextAnchor = (i, nodes) =>
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  Math.floor(nodes.length / 2) > i
    ? "end"
    : // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      Math.floor(nodes.length / 2) < i
      ? "start"
      : "middle"
const setTextXOffset = (i, nodes) => {
  const edgeOffset = 2
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return Math.floor(nodes.length / 2) > i
    ? // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      String(nodes[i].getBoundingClientRect().width / 2 + edgeOffset)
    : // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      Math.floor(nodes.length / 2) < i
      ? // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        String(-nodes[i].getBoundingClientRect().width / 2 - edgeOffset)
      : "0"
}

export interface CircularAxisLabelsInterface {
  domain: [number, number]
  innerRadius: number
  intervals: number
  range: [number, number]
  svg: React.MutableRefObject<undefined>
}

export const drawCircularAxisLabels = ({
  svg: svgRef,
  domain,
  range,
  intervals,
  innerRadius,
}: CircularAxisLabelsInterface) => {
  const svg = select(svgRef.current)
  const labelCircleInnerWidth = innerRadius * 0.825

  const scale = scaleLinear().domain(domain).range(range)
  const ticks = scale.ticks(intervals)
  const axis = svg
    .append("g")
    .attr("class", "labels axis")
    .style("transform", "translate(49%, 50%)")
    .selectAll("g")
    .data(ticks)
    .enter()
    .append("g")
    .attr("data-label", "rotation-group")
    .attr("transform", (d) => `rotate(${(scale(d) * 180) / Math.PI - 90})`)
  axis
    .append("text")
    .attr("class", "axis__text")
    .attr("x", labelCircleInnerWidth)
    .attr(
      "transform",
      (d) =>
        `rotate(${90 - (scale(d) * 180) / Math.PI},${labelCircleInnerWidth},0)`
    )
    .text((d) => d)
    .attr("dx", (_d, i, nodes) => setTextXOffset(i, nodes))
    .style("text-anchor", (_d, i, nodes) => setTextAnchor(i, nodes))
}
