import type { WhereClause } from "spraypaint"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import type { IFetchApplicationRecordOptions } from "../../models/applicationRecord/applicationRecord"
import Site from "../../models/site"

/**
 * Fetches sites. Results are filtered by provided orgId and any additional params
 * included in the clause.
 * @param organizationId - The organizationId to retrieve sites for
 * @param options.clause - Used to construct graphiti filter query params https://www.graphiti.dev/js/reads/filtering
 * @param options.fetchOptions - Used to configure sorting and pagination for the response.
 * @example
 * const options = {clause: {department_id: 1}, fetchOptions:{order: "asc"}}
 * const { data } = await fetchSites(oganizationId: 5, options)
 */
export const fetchSites = async (
  organizationId: string,
  options?: {
    clause?: WhereClause
    fetchOptions?: Partial<IFetchApplicationRecordOptions>
  }
): Promise<Site[]> => {
  const mergedOptions: IFetchApplicationRecordOptions = {
    ...{
      order: "asc",
      orderBy: "name",
      pageSize: 1000,
      pageNumber: 1,
    },
    ...options?.fetchOptions,
  }
  const response: CollectionProxy<Site> = await Site.order({
    [mergedOptions.orderBy]: mergedOptions.order,
  })
    .where({ organizationId, ...options?.clause })
    .includes(["organizationalUnit", "address"])
    .per(mergedOptions.pageSize)
    .page(mergedOptions.pageNumber)
    .all()

  return response.data
}
