import React, { useMemo } from "react"
import { Link } from "react-router-dom"

import { useUrl } from "@/utils/url"

import { ListAlt } from "@mui/icons-material"
import { Box, Button, Stack, useTheme } from "@mui/material"

import useFilters from "../../../hooks/useFilters/useFilters"
import { MetricType } from "../../../models/decimal/decimal"
import DateFilter from "../../../models/filter/dateFilter"
import { ReportPath } from "../../../models/route"
import { UnitName } from "../../../models/unit"
import type { AllScopeEmissionsData } from "../../../modules/dashboard/models/dashboard"
import EmptyChart from "../../../modules/site/components/site/emptyChart"
import type { Organization } from "../../../services"
import { FeatureFlags, useFeature } from "../../../services"
import { getQueryStringFromFilters } from "../../../utils/navigation"
import Legend from "../../graph/legend/legend"
import { MonthlyStackedAreaGraph } from "../../graph/monthly-stacked-area-graph/monthly-stacked-area-graph"
import { buildLegendItems } from "../../graph/monthly-stacked-area-graph/monthly-stacked-area-graph-util"
import type {
  AreaGraphMap,
  MonthlyAreaGraphDatum,
  MonthlyEmissionsByScopeGraphValues,
} from "../../graph/types"

interface EmissionsByScopeProps {
  isLimitedAccessUser: boolean
  organization: Organization
  scopeData: AllScopeEmissionsData | undefined
}

const emissionsByScopeTooltipLabelsMap = {
  "Scope 1": {
    label: "Scope 1",
    metricFormattingComponentId: MetricType.Emission,
  },
  "Scope 2": {
    label: "Scope 2",
    metricFormattingComponentId: MetricType.Emission,
  },
  "Scope 3": {
    label: "Scope 3",
    metricFormattingComponentId: MetricType.Emission,
  },
}

const EmissionsByScopeTooltipLabels: Record<
  number,
  keyof MonthlyEmissionsByScopeGraphValues
> = {
  1: "scopeOne",
  2: "scopeTwo",
  3: "scopeThree",
}

const EmissionsByScope: React.FC<EmissionsByScopeProps> = ({
  scopeData,
  isLimitedAccessUser,
  organization,
}) => {
  const theme = useTheme()
  const { buildReportingUrl } = useUrl()
  const { filters } = useFilters({
    start: DateFilter,
    end: DateFilter,
  })

  const { isFeatureEnabled } = useFeature()

  const isButtonVisible =
    isFeatureEnabled(FeatureFlags.REPORT_ACTIVITY_EMISSIONS, organization) &&
    !isLimitedAccessUser

  const colors: string[] = [
    theme.palette.spectrum.blue[500],
    theme.palette.spectrum.cerulean[200],
    theme.palette.spectrum.peach[100],
  ]

  const { graphData, graphKeys } = scopeData

  // Ensure each graph item returns a number value
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = useMemo(
    () =>
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      graphData.map((datum) => {
        const newDatum = { ...datum } as MonthlyAreaGraphDatum
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        graphKeys.forEach((key) => {
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          newDatum[key] = datum[key] ?? 0
        })
        return newDatum
      }),
    [graphData, graphKeys]
  )

  const scopeTotals: MonthlyEmissionsByScopeGraphValues = useMemo(() => {
    const totals: MonthlyEmissionsByScopeGraphValues = {
      scopeThree: 0,
      scopeTwo: 0,
      scopeOne: 0,
    }
    scopeData?.scopeTotals.forEach((scopeTotal) => {
      totals[EmissionsByScopeTooltipLabels[scopeTotal.scope]] = scopeTotal.value
    })
    return totals
  }, [scopeData?.scopeTotals])

  const emissionsByScopeMap: AreaGraphMap = {
    scopeThree: {
      label: "Scope 3",
      color: colors[2],
    },
    scopeTwo: {
      label: "Scope 2",
      color: colors[1],
    },
    scopeOne: {
      label: "Scope 1",
      color: colors[0],
    },
  }

  if (data?.length < 1) {
    return (
      <EmptyChart header="There is no data available for this selected time range." />
    )
  }

  return (
    <Box height={theme.typography.pxToRem(344)}>
      <MonthlyStackedAreaGraph
        colors={colors}
        exportHiddenElements={[".MuiButton-root"]}
        keys={["Scope 1", "Scope 2", "Scope 3"]}
        legendAlign="right"
        legendLayout="vertical"
        legendVerticalAlign="top"
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        rawData={data}
        title="Emissions by Scope"
        tooltipLabelsMap={emissionsByScopeTooltipLabelsMap}
        unit={UnitName.MetricTonsOfCarbonDioxideEquivalent}
      >
        <Stack ml={3} spacing={2}>
          <Legend
            items={buildLegendItems(scopeTotals, emissionsByScopeMap)}
            itemsTitle="Scope"
            unit={UnitName.MetricTonsOfCarbonDioxideEquivalent}
          />
          {isButtonVisible && (
            <Button
              color="neutral"
              component={Link}
              endIcon={<ListAlt />}
              size="small"
              to={`${buildReportingUrl(ReportPath.EmissionsSources)}${getQueryStringFromFilters(filters)}`}
              variant="outlined"
            >
              Emissions Sources
            </Button>
          )}
        </Stack>
      </MonthlyStackedAreaGraph>
    </Box>
  )
}

export default EmissionsByScope
