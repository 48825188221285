import React from "react"
import type { FC } from "react"

import { SigmaReport } from "@/modules/report/components/sigma-report"
import { allQAWorkspaceName, useSigmaWorkbooks } from "@/services/sigma"
import type { SigmaWorkbook } from "@/services/sigma"

export const DataQARoute: FC = () => {
  const { sigmaWorkbooksData } = useSigmaWorkbooks({
    workspaceNames: [allQAWorkspaceName],
  })

  const siteDetailWorkbook: SigmaWorkbook | undefined =
    sigmaWorkbooksData?.entries.find(
      (workbook) => workbook.name === "Data Completeness"
    )

  return <SigmaReport workbookId={siteDetailWorkbook?.workbookId} />
}
