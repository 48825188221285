import {
  baseStringSchema,
  passwordSchema,
  validatePasswordMatch,
  validatePasswordMatchParams,
} from "@/schemas"
import z from "zod"

export const resetPasswordSchema = z
  .object({
    confirmPassword: baseStringSchema,
    password: passwordSchema,
  })
  // Why partial? To allow the confirmPassword validation to display without having to complete the entire form.
  // See https://github.com/colinhacks/zod/issues/479#issuecomment-854822386
  .partial()
  .refine(validatePasswordMatch, validatePasswordMatchParams)

export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>
