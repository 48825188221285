import React from "react"

import { Stack as MuiStack } from "@mui/material"

import type { HStackProps } from "./h-stack.types"

/**
 * Wrapper for `Stack` that sets the direction to 'row'
 *
 * @example
 * ```tsx
 * import { HStack } from 'nzds'
 *
 * <HStack gap={2}>
 *   <Button>button 1</Button>
 *   <Typography>i'm next to the button with a gap of 16px</Typography>
 * </HStack>
 * ```
 */
export const HStack = <C extends React.ElementType>(props: HStackProps<C>) => (
  <MuiStack {...props} direction="row" />
)
