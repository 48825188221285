import { httpClient } from "@/utils/api"

export const getSessionTimeout = async (): Promise<number> => {
  try {
    const response = await httpClient.get<number>("/check_session_timeout")

    return response.data
  } catch {
    console.error("Error fetching session timeout")

    return 0
  }
}
