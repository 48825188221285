import React from "react"

import Color from "color"

import { Grid } from "@mui/material"

import ScopeOneNoDataIllustration from "../../../../assets/images/organizationDashboard/scope-1-no-data.png"
import ScopeTwoCarbonIntensityNoDataIllustration from "../../../../assets/images/organizationDashboard/scope-2-carbon-intensity-no-data.png"
import ScopeTwoNoDataIllustration from "../../../../assets/images/organizationDashboard/scope-2-no-data.png"
import ScopeThreeNoDataIllustration from "../../../../assets/images/organizationDashboard/scope-3-no-data.png"
import { DialGraph } from "../../../../components/dialGraph"
import OrganizationDashboardItem from "../../../../components/organizationDashboard/organizationDashboardItem/organizationDashboardItem"
import { OrganizationDashboardNoData } from "../../../../components/organizationDashboard/organizationDashboardNoData/organizationDashboardNoData"
import { OrganizationDashboardScopeOne } from "../../../../components/organizationDashboard/organizationDashboardScopeOne/organizationDashboardScopeOne"
import { OrganizationDashboardScopeThree } from "../../../../components/organizationDashboard/organizationDashboardScopeThree/organizationDashboardScopeThree"
import { OrganizationDashboardTooltip } from "../../../../components/organizationDashboard/organizationDashboardTooltip/organizationDashboardTooltip"
import { Scope2CardContent } from "../../../../components/organizationDashboard/scope2Card/scope2CardContent"
import { ElectricityVolumesTable } from "../../../../components/organizationSummary/electricityVolumesTable"
import { Scope } from "../../../../models/scope"
import {
  carbonIntensity1,
  carbonIntensity2,
  carbonIntensity3,
  carbonIntensity4,
  carbonIntensity5,
  carbonIntensity6,
} from "../../../../utils/colors"
import { CarbonIntensityGraphDefaults } from "../../../../utils/constants"
import { pxToRem } from "../../../../utils/unit"
import type {
  OrgDashboardDataE2eAttributes,
  ScopeOneEmissionsGraphItem,
  ScopeThreeEmissionsGraphItem,
  ScopeTwoEmissionsGraphItem,
} from "../../models/dashboard"
import {
  SCOPE_CARD_HEIGHT,
  scopeEmissionToolTipDescriptions,
} from "../../models/dashboard"

export interface ScopeOneEmissionsData {
  graphData: ScopeOneEmissionsGraphItem[]
  graphKeys: string[]
  isFetched: boolean
  totalEmissions: number
}
export interface ScopeTwoEmissionsData {
  graphData: ScopeTwoEmissionsGraphItem[]
  isFetched: boolean
  totalCost: number
  totalEmissions: number
  totalKwh: number
}

export interface ScopeThreeEmissionsData {
  graphData: ScopeThreeEmissionsGraphItem[]
  isFetched: boolean
  totalEmissions: number
}

interface OrganizationDashboardScopeSectionProps {
  OrgDashboardE2eAttr: OrgDashboardDataE2eAttributes
  isLimitedAccessUser: boolean
  scopeOneProps: ScopeOneEmissionsData
  scopeThreeProps: ScopeThreeEmissionsData
  scopeTwoCarbonIntensityProps: {
    carbonFreeKwh: number
    isScope2CarbonIntensityLoading: boolean
    mtCo2E: number
    totalMwh: number
  }
  scopeTwoProps: ScopeTwoEmissionsData
}

const scopeTwoGraphColorData = new Map([
  [
    "lowest",
    {
      name: "lowest",
      ratio: 0.25,
      color: carbonIntensity1,
      stroke: false,
      fill: "",
    },
  ],
  [
    "low",
    {
      name: "low",
      ratio: 0.125,
      color: carbonIntensity2,
      stroke: false,
      fill: "",
    },
  ],
  [
    "medium low",
    {
      name: "medium low",
      ratio: 0.125,
      color: carbonIntensity3,
      stroke: false,
      fill: "",
    },
  ],
  [
    "medium high",
    {
      name: "medium high",
      ratio: 0.125,
      color: carbonIntensity4,
      stroke: false,
      fill: "",
    },
  ],
  [
    "high",
    {
      name: "high",
      ratio: 0.125,
      color: carbonIntensity5,
      stroke: false,
      fill: "",
    },
  ],
  [
    "highest",
    {
      name: "highest",
      ratio: 0.25,
      color: carbonIntensity6,
      stroke: false,
      fill: "",
    },
  ],
])

const OrganizationDashboardScopeSection = ({
  isLimitedAccessUser,
  OrgDashboardE2eAttr,
  scopeOneProps,
  scopeThreeProps,
  scopeTwoProps,
  scopeTwoCarbonIntensityProps,
}: OrganizationDashboardScopeSectionProps): JSX.Element => {
  const scopeTwoCarbonIntensity =
    (scopeTwoCarbonIntensityProps?.mtCo2E ?? NaN) /
    (scopeTwoCarbonIntensityProps?.totalMwh ?? NaN)

  const carbonIntensityGraphData: Parameters<typeof DialGraph>[0]["graphData"] =
    {
      ...CarbonIntensityGraphDefaults,
      ...{
        classNamePrepend: "organization",
        // Provides the graph as a square, and in a multiple of 4 px
        viewWidth: 236,
        viewHeight: 236,
        data: scopeTwoGraphColorData,
      },
      ...{
        overlayData: {
          ...CarbonIntensityGraphDefaults.overlayData,
          name: "",
          displayValue: scopeTwoCarbonIntensity,
          ratio:
            scopeTwoCarbonIntensity / CarbonIntensityGraphDefaults.domain[1],
          color: new Color(),
        },
      },
    }

  const isAbleToDisplayCarbonIntensity = Number.isFinite(
    scopeTwoCarbonIntensity
  )

  return (
    <>
      {/* Scope 3 Module */}
      <Grid item lg={4} md={6} sm={12}>
        <OrganizationDashboardItem
          content={
            scopeThreeProps?.graphData.length > 0 ? (
              <OrganizationDashboardScopeThree
                data={scopeThreeProps}
                dataE2e={OrgDashboardE2eAttr.scopeThreeEmissionsValue}
                isLimitedAccessUser={isLimitedAccessUser}
              />
            ) : (
              <OrganizationDashboardNoData
                altText="A gray illustration of an airplane, waste bins, a truck, a construction crane and a factory to represent scope 3 emissions."
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                illustration={ScopeThreeNoDataIllustration}
                scope={Scope.Three}
              />
            )
          }
          isLoading={!scopeThreeProps?.isFetched}
          minHeight={SCOPE_CARD_HEIGHT}
          title="Scope 3"
          tooltip={
            <OrganizationDashboardTooltip
              content={scopeEmissionToolTipDescriptions.scope3}
              title="Scope 3"
            />
          }
        />
      </Grid>

      {/* Scope 1 Module */}
      <Grid item lg={8} md={6} sm={12}>
        <OrganizationDashboardItem
          content={
            scopeOneProps?.graphData?.length > 0 ? (
              <OrganizationDashboardScopeOne
                dataE2e={OrgDashboardE2eAttr.scopeOneEmissionsValue}
                isLimitedAccessUser={isLimitedAccessUser}
                scopeOneData={scopeOneProps}
              />
            ) : (
              <OrganizationDashboardNoData
                altText="A gray illustration of an office building with a car parked in front to represent scope 1 emissions."
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                illustration={ScopeOneNoDataIllustration}
                scope={Scope.One}
              />
            )
          }
          isLoading={!scopeOneProps.isFetched}
          minHeight={SCOPE_CARD_HEIGHT}
          title="Scope 1"
          tooltip={
            <OrganizationDashboardTooltip
              content={scopeEmissionToolTipDescriptions.scope1}
              title="Scope 1"
            />
          }
        />
      </Grid>

      {/* Scope 2 Module */}
      <Grid item lg={8} md={6} sm={12}>
        <OrganizationDashboardItem
          content={
            scopeTwoProps?.graphData.length > 0 ? (
              <Scope2CardContent
                isLimitedAccessUser={isLimitedAccessUser}
                scopeTwoData={scopeTwoProps}
              />
            ) : (
              <OrganizationDashboardNoData
                altText="A gray illustration of power grid lines to represent scope 2 emissions from electricity."
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                illustration={ScopeTwoNoDataIllustration}
                scope={Scope.Two}
              />
            )
          }
          isLoading={!scopeTwoProps.isFetched}
          minHeight={SCOPE_CARD_HEIGHT}
          title="Scope 2"
          tooltip={
            <OrganizationDashboardTooltip
              content={scopeEmissionToolTipDescriptions.scope2}
              title="Scope 2"
            />
          }
        />
      </Grid>

      {/* Scope 2 Carbon Intensity */}
      <Grid item lg={4} md={6} sm={12} sx={{ flex: 1 }}>
        <OrganizationDashboardItem
          content={
            isAbleToDisplayCarbonIntensity ? (
              <>
                <DialGraph graphData={carbonIntensityGraphData} />
                <ElectricityVolumesTable
                  carbonFreeKwh={scopeTwoCarbonIntensityProps?.carbonFreeKwh}
                  meteredMwh={scopeTwoCarbonIntensityProps?.totalMwh}
                />
              </>
            ) : (
              <OrganizationDashboardNoData
                altText="A grayed out gauge chart with no current value representing the measurement of carbon intensity in MT CO2e per mega watt hour."
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                illustration={ScopeTwoCarbonIntensityNoDataIllustration}
                scope={Scope.Two}
              />
            )
          }
          contentSx={
            isAbleToDisplayCarbonIntensity
              ? {
                  padding: `${pxToRem(40)} ${pxToRem(24)} ${pxToRem(
                    24
                  )} ${pxToRem(24)}`,
                  gap: pxToRem(24),
                }
              : {}
          }
          isLoading={
            scopeTwoCarbonIntensityProps?.isScope2CarbonIntensityLoading
          }
          minHeight={SCOPE_CARD_HEIGHT}
          title="Scope 2 Carbon Intensity"
          tooltip={
            <OrganizationDashboardTooltip
              content={scopeEmissionToolTipDescriptions.scope2CarbonIntensity}
              title="Scope 2 Carbon Intensity"
            />
          }
        />
      </Grid>
    </>
  )
}

export default OrganizationDashboardScopeSection
