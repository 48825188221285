import type { SortOrder } from "../../../models/sort"

const templateId = "1in_Iscjb-cnX4jKOC-Mh5ZbIIQU-ugB27ZyeF5UC2b0"
export const templateExportLink = `https://docs.google.com/spreadsheets/d/${templateId}/export?format=xlsx`

const completedTemplateId = "1rH9ZKEy734vNdjlWuYwLlWObSEeIvHzpUTZpjRsL7Ao"
export const completedTemplateExportLink = `https://docs.google.com/spreadsheets/d/${completedTemplateId}/export?format=csv`

export const ASC: SortOrder = "asc"
export const DESC: SortOrder = "desc"
