import { upperFirst } from "lodash-es"

import type {
  OutputMutation,
  QueryMutation,
  ServiceAction,
  ServiceMutation,
  ServiceName,
  ServiceValue,
} from "../../types"

/**
 * Customizes a React Query mutation result with property names based on the service entity.
 * @param serviceName The name of the service.
 * @param serviceAction The action of the service.
 * @param mutate The mutate function.
 * @returns The mutation result.
 * @example
 * createOutputMutation("test", "updateOne", {
 * mutate: () => {},
 * })
 */
export const createOutputMutation = <
  NameT extends ServiceName,
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
>(
  serviceName: NameT,
  serviceAction: ActionT,
  { mutate }: { mutate: QueryMutation<ActionT, ValueT> }
) => {
  const wrappedMutation = (serviceEntityKey, newAttributes) => {
    if (!mutate) {
      throw new Error("No mutation defined.")
    }
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    mutate({ serviceEntityKey, newAttributes })
  }

  return {
    [`${serviceAction}${upperFirst(serviceName)}`]: wrappedMutation,
  } as OutputMutation<NameT, ActionT, ValueT, ServiceMutation<ActionT, ValueT>>
}
