import React from "react"
import type {
  NumberFormatValues,
  NumericFormatProps,
} from "react-number-format"
import { NumericFormat } from "react-number-format"

import type { NumericFormatCustomProps } from "./numeric-text-field.types"

export const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  NumericFormatCustomProps
>((props, ref) => {
  const { onChange, NumericFormatProps = {}, ...rest } = props

  const {
    thousandSeparator = true,
    decimalScale = 2,
    valueIsNumericString = true,
    ...restNumericFormatProps
  } = NumericFormatProps

  return (
    <NumericFormat
      {...rest}
      {...restNumericFormatProps}
      decimalScale={decimalScale}
      getInputRef={ref}
      onValueChange={(values: NumberFormatValues) => {
        onChange({
          target: {
            name: props.name,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            value: values.value,
          },
        })
      }}
      thousandSeparator={thousandSeparator}
      valueIsNumericString={valueIsNumericString}
    />
  )
})
