import type DateFilter from "../../../models/filter/dateFilter"
import type { ITableColumn, ITableRow } from "../../../models/table"
import type { Report } from "./report"

export interface IGresbFilters {
  end: DateFilter
  start: DateFilter
}

export interface GresbEntityDto {
  assetName: string
  endDate: string
  startDate: string
}

export interface GresbEnergyDto extends GresbEntityDto {
  electricityConsumptionKwh: string | null
  fuelConsumptionKwh: string | null
}

export interface GresbGhgDto extends GresbEntityDto {
  scope1MtCo2E: string | null
  scope2AdvancedGridStudyMtCo2E: string | null
  scope2LocationBasedMtCo2E: string | null
  scope2MarketBasedMtCo2E: string | null
  scope3MtCo2E: string | null
}

export interface GresbWaterDto extends GresbEntityDto {
  waterConsumptionM3: string | null
}

export interface GresbDataDto {
  energy: GresbEnergyDto[]
  ghg: GresbGhgDto[]
  water: GresbWaterDto[]
}

export interface GresbEntity extends GresbEntityDto {
  year: number
}

export interface GresbEnergy extends GresbEntity {
  electricityConsumptionKwh: number
  fuelConsumptionKwh: number
}

export interface GresbGhg extends GresbEntity {
  scope1MtCo2E: number
  scope2AdvancedGridStudyMtCo2E: number
  scope2LocationBasedMtCo2E: number
  scope2MarketBasedMtCo2E: number
  scope3MtCo2E: number
}

export interface GresbWater extends GresbEntity {
  waterConsumptionM3: number
}

export interface GresbData {
  energy: GresbEnergy[]
  ghg: GresbGhg[]
  water: GresbWater[]
}

// Report
export enum GresbReportName {
  Energy = "GRESB Energy",
  Ghg = "GRESB GHG",
  Water = "GRESB Water",
}

export type GresbEnergyReportTableRow = GresbEnergy & ITableRow

export type GresbGhgReportTableRow = GresbGhg & ITableRow

export type GresbWaterReportTableRow = GresbWater & ITableRow

export interface GresbReport {
  energy: Report<GresbEnergyReportTableRow>
  ghg: Report<GresbGhgReportTableRow>
  water: Report<GresbWaterReportTableRow>
}

// Columns
export enum GresbEnergyReportColumnName {
  AssetName = "Asset Name",
  Electricity = "Whole Building Electricity Consumption (kWh)",
  From = "Data Available From (in reporting year)",
  Fuel = "Whole Building Fuels Consumption (kWh)",
  To = "Data Available To (in reporting year)",
  Year = "Reporting Year",
}

export enum GresbGhgReportColumnName {
  AssetName = "Asset Name",
  From = "Data Available From (in reporting year)",
  Scope1MtCo2E = "Scope 1 Emissions (tonne)",
  Scope2AdvancedGridStudyMtCo2E = "Scope 2: Advanced Grid Study Emissions (tonne)",
  Scope2LocationBasedMtCo2E = "Scope 2: Location Based Emissions (tonne)",
  Scope2MarketBasedMtCo2E = "Scope 2: Market Based Emissions (tonne)",
  Scope3MtCo2E = "Scope 3: Tenant Emissions (tonne)",
  To = "Data Available To (in reporting year)",
  Year = "Reporting Year",
}

export enum GresbWaterReportColumnName {
  AssetName = "Asset Name",
  From = "Data Available From (in reporting year)",
  To = "Data Available To (in reporting year)",
  Water = "Whole Building Consumption (m3)",
  Year = "Reporting Year",
}

export const gresbEnergyReportColumns: ITableColumn<GresbEnergyReportTableRow>[] =
  [
    {
      id: 0,
      order: 0,
      title: GresbEnergyReportColumnName.AssetName,
      key: "assetName",
    },
    {
      id: 1,
      order: 1,
      title: GresbEnergyReportColumnName.Year,
      key: "year",
    },
    {
      id: 2,
      order: 2,
      title: GresbEnergyReportColumnName.From,
      key: "startDate",
    },
    {
      id: 3,
      order: 3,
      title: GresbEnergyReportColumnName.To,
      key: "endDate",
    },
    {
      id: 4,
      order: 4,
      title: GresbEnergyReportColumnName.Fuel,
      key: "fuelConsumptionKwh",
    },
    {
      id: 5,
      order: 5,
      title: GresbEnergyReportColumnName.Electricity,
      key: "electricityConsumptionKwh",
    },
  ]

export const gresbGhgReportColumns: ITableColumn<GresbGhgReportTableRow>[] = [
  {
    id: 0,
    order: 0,
    title: GresbGhgReportColumnName.AssetName,
    key: "assetName",
  },
  {
    id: 1,
    order: 1,
    title: GresbGhgReportColumnName.Year,
    key: "year",
  },
  {
    id: 2,
    order: 2,
    title: GresbGhgReportColumnName.From,
    key: "startDate",
  },
  {
    id: 3,
    order: 3,
    title: GresbGhgReportColumnName.To,
    key: "endDate",
  },
  {
    id: 4,
    order: 4,
    title: GresbGhgReportColumnName.Scope1MtCo2E,
    key: "scope1MtCo2E",
  },
  {
    id: 5,
    order: 5,
    title: GresbGhgReportColumnName.Scope2LocationBasedMtCo2E,
    key: "scope2LocationBasedMtCo2E",
  },
  {
    id: 6,
    order: 6,
    title: GresbGhgReportColumnName.Scope2MarketBasedMtCo2E,
    key: "scope2MarketBasedMtCo2E",
  },
  {
    id: 7,
    order: 7,
    title: GresbGhgReportColumnName.Scope2AdvancedGridStudyMtCo2E,
    key: "scope2AdvancedGridStudyMtCo2E",
  },
  {
    id: 8,
    order: 8,
    title: GresbGhgReportColumnName.Scope3MtCo2E,
    key: "scope3MtCo2E",
  },
]

export const gresbWaterReportColumns: ITableColumn<GresbWaterReportTableRow>[] =
  [
    {
      id: 0,
      order: 0,
      title: GresbEnergyReportColumnName.AssetName,
      key: "assetName",
    },
    {
      id: 1,
      order: 1,
      title: GresbEnergyReportColumnName.Year,
      key: "year",
    },
    {
      id: 2,
      order: 2,
      title: GresbEnergyReportColumnName.From,
      key: "startDate",
    },
    {
      id: 3,
      order: 3,
      title: GresbEnergyReportColumnName.To,
      key: "endDate",
    },
    {
      id: 4,
      order: 4,
      title: GresbWaterReportColumnName.Water,
      key: "waterConsumptionM3",
    },
  ]
