import React from "react"

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Stack, Typography } from "@mui/material"

import { Scope } from "../../../models/scope"
import { TYPOGRAPHY_VARIANT } from "../../../models/typography"
import { UnitName } from "../../../models/unit"
import { EmissionDecimal } from "../../decimal/emissionDecimal/emissionDecimal"
import { Unit } from "../../unit/unit"
import { OrganizationDashboardMetricTitle } from "../organizationDashboardMetricTitle/organizationDashboardMetricTitle"
import { ScopeReportButton } from "../scopeReportButton/scopeReportButton"

interface IScopeThreeMetricsProps {
  dataE2e: string
  isLimitedAccessUser: boolean
  totalEmissions: number
}

export const ScopeThreeMetrics = ({
  totalEmissions,
  isLimitedAccessUser,
  dataE2e,
}: IScopeThreeMetricsProps) => (
  <Stack direction="row" justifyContent="space-between">
    <Stack>
      <Typography variant={TYPOGRAPHY_VARIANT.body2}>
        <OrganizationDashboardMetricTitle
          descriptor={
            <Unit unit={UnitName.MetricTonsOfCarbonDioxideEquivalent} />
          }
          title="Total Emissions"
        />
      </Typography>
      <Typography
        component="span"
        data-e2e={dataE2e}
        sx={{ mb: 3, display: "block" }}
        variant={TYPOGRAPHY_VARIANT.h2}
      >
        <EmissionDecimal value={totalEmissions} />
      </Typography>
      {!!totalEmissions && (
        <Typography
          data-testid="title-top-categories"
          sx={{ mb: 1 }}
          variant={TYPOGRAPHY_VARIANT.h6}
        >
          Top Categories by{" "}
          <Unit unit={UnitName.MetricTonsOfCarbonDioxideEquivalent} />
        </Typography>
      )}
    </Stack>
    <ScopeReportButton
      isLimitedAccessUser={isLimitedAccessUser}
      scope={Scope.Three}
    />
  </Stack>
)
