import { useMemo } from "react"
import type { UseQueryResult } from "react-query"
import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import type { ActivityEmissionsReport } from "../../models/activityEmissions"
import {
  ActivityEmissionsReportName,
  activityEmissionsReportColumns,
} from "../../models/activityEmissions"
import { getReportFileName, getReportRows } from "../../util/util"
import {
  fetchActivityEmissionsReport,
  hasResultMatchingFilters,
} from "./useActivityEmissionsReportUtils"

/**
 * The useActivityEmissionsReport service/hook returns report of emissions, including water
 * for a given organization and date range, filtered by given parameter.
 * Currently the only filter is scope but can be expanded in the future
 *
 * @returns The Activity Emissions Report
 * @example
 * const {
 *   activityEmissionsReport,
 *   isActivityEmissionsReportFetched,
 *   isActivityEmissionsReportLoading
 * } = useActivityEmissionsReport()
 */
export const useActivityEmissionsReport = (
  orgId: string,
  dateRange: IRange<Moment>,
  filters: { filter: string[] }
) => {
  const queryKey = useQueryKey(
    ApiQueryName.ActivityEmissionsReport,
    "getOne",
    orgId,
    {
      start: dateRange?.start?.format("YYYY-MM-DD"),
      end: dateRange?.end?.format("YYYY-MM-DD"),
    }
  )

  const queryResult = useQuery({
    keepPreviousData: true,
    queryKey,
    queryFn: () => fetchActivityEmissionsReport(orgId, dateRange),
    enabled:
      !!orgId &&
      !!dateRange?.start?.isValid() &&
      !!dateRange?.end?.isValid() &&
      !!dateRange?.start?.isBefore(dateRange?.end),
  })

  const activityEmissionsReport: ActivityEmissionsReport = useMemo(() => {
    if (queryResult.data?.length) {
      const filteredActivityEmissionsReportData = queryResult.data?.filter(
        (value) => hasResultMatchingFilters(value, filters)
      )
      return {
        name: getReportFileName(
          ActivityEmissionsReportName.EmissionsSources,
          dateRange,
          true // preview not relevant
        ),
        data: {
          columns: activityEmissionsReportColumns,
          rows: getReportRows(filteredActivityEmissionsReportData),
        },
      }
    }

    return null
  }, [queryResult.data, dateRange, filters])

  const modifiedQueryResult = {
    ...queryResult,
    data: activityEmissionsReport,
  } as UseQueryResult<ActivityEmissionsReport>

  return useRenamedQueryResult(queryKey, modifiedQueryResult)
}
