import React from "react"
import type { FC } from "react"

import DriveFileMoveRoundedIcon from "@mui/icons-material/DriveFileMoveRounded"

import { Button } from "../../../../../nzds/inputs/button"
import type { MoveItemsButtonProps } from "./move-items-button.types"

export const MoveItemsButton: FC<MoveItemsButtonProps> = ({
  selectedRows,
  ...rest
}) => {
  const disabled = !selectedRows.length

  return (
    <Button
      {...rest}
      aria-label="Move Selected Items"
      disabled={disabled}
      size="small"
      startIcon={<DriveFileMoveRoundedIcon />}
      sx={{ color: "primary.main" }}
      variant="text"
    >
      Move Selected Items
    </Button>
  )
}
