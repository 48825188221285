import { Attr, BelongsTo, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"
import Site from "./site"

/* Spraypaint's camelCase to snake_case conversion incorrectly transforms attributes
that end with numeric characters. The conversion when writing to the address resource
transforms addressLine1 to address_line1. This is a workaround to avoid the bug and allow writes. */
@Model({ keyCase: { server: "snake", client: "snake" } })
export default class Address extends ApplicationRecord {
  public static jsonapiType = "addresses"

  @BelongsTo("sites") public site: Site

  @Attr() public address_line_1: string

  @Attr() public address_line_2: string

  @Attr() public city: string

  @Attr() public state: string

  @Attr() public country: string

  @Attr() public postal_code: string

  @Attr() public readonly site_id: number
}
