import type { UseQueryOptions } from "react-query"
import { useQuery } from "react-query"

import type { Paths } from "@/services/types"
import { useQueryKey } from "@/services/utils/useQueryKey"

import { ApiQueryName } from "../../../../models/api"
import { getProfiles } from "./get-profiles"

interface UseGetProfilesOptions {
  parameters: Paths["/api/v1/recommendations/emission_profiles"]["get"]["parameters"]
  queryOptions?: Omit<UseQueryOptions, "queryKey" | "queryFn">
}

/**
 * Returns emission profiles for a given org
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetProfiles({
 *   parameters: { query: { organization_id: 123, orderBy: "name", order: "asc" } },
 *   queryOptions // react query options
 * });
 */
export const useGetProfiles = ({
  parameters,
  queryOptions,
}: UseGetProfilesOptions) => {
  const queryKey = useQueryKey(
    ApiQueryName.EmissionProfiles,
    "getMany",
    null,
    parameters.query
  )

  let queryParams = {
    queryKey,
    queryFn: () => getProfiles(parameters),
  }

  if (queryOptions) {
    queryParams = {
      ...queryOptions,
      ...queryParams,
    }
  }

  const query = useQuery(queryParams)

  return { ...query }
}
