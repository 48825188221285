import { z } from "zod"

import type { RelationshipMeta } from "./types"

/**
 * Create a special Zod Object schema with relationship information attached as meta.
 */
const createRelationship = <R extends RelationshipMeta>(
  relationshipMeta: Readonly<R> // Readonly so string literals are preserved.
) => {
  const relationshipSchema = z.custom<null | { __relationshipMeta: R }>(
    (value) => value === null || value === undefined,
    "Overwrite this schema relationship to set it's value"
  )
  Object.assign(relationshipSchema._def, {
    __relationshipMeta: relationshipMeta,
  })
  return relationshipSchema
}

const buildOptions = <
  T extends RelationshipMeta["relationshipType"],
  N extends RelationshipMeta["targetName"],
>(
  relationshipType: T,
  targetName: N
) =>
  ({
    relationshipType,
    targetName,
    isArray: relationshipType === "hasMany",
  }) as Readonly<{
    isArray: T extends "hasMany" ? true : false
    relationshipType: T
    targetName: N
  }>

export const hasOne = <N extends RelationshipMeta["targetName"]>(
  targetName: N
) => createRelationship(buildOptions("hasOne", targetName))

export const belongsTo = <N extends RelationshipMeta["targetName"]>(
  targetName: N
) => createRelationship(buildOptions("belongsTo", targetName))

export const hasMany = <N extends RelationshipMeta["targetName"]>(
  targetName: N
) => createRelationship(buildOptions("hasMany", targetName))

export type GetRelationshipMeta<Z extends z.ZodTypeAny> = Exclude<
  z.infer<Z>,
  null
>["__relationshipMeta"]

export const getRelationshipMeta = <Z extends z.ZodTypeAny>(schema: Z) =>
  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  (schema._def.__relationshipMeta ??
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    schema._def.innerType?._def.__relationshipMeta) as GetRelationshipMeta<Z>

export type IsRelationship<Z extends z.ZodTypeAny> =
  GetRelationshipMeta<Z> extends RelationshipMeta ? true : false

export const isRelationship = <Z extends z.ZodTypeAny>(schema: Z) =>
  !!getRelationshipMeta(schema)
