import React from "react"
import type { FC } from "react"

import { useDashboardContext } from "@/components/dashboard/useDashboardContext"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"
import { SigmaReport } from "@/modules/report/components/sigma-report"
import { HStack } from "@/nzds/layout"
import { allSitesWorkspaceName, useSigmaWorkbooks } from "@/services/sigma"
import type { SigmaWorkbook } from "@/services/sigma"

import { Box, Collapse } from "@mui/material"

export const SiteOverviewRoute: FC = () => {
  const { site, isSiteInfoOpen, siteInfoButton } = useDashboardContext()
  const { sigmaWorkbooksData } = useSigmaWorkbooks({
    workspaceNames: [allSitesWorkspaceName],
  })

  const siteOverviewWorkbook: SigmaWorkbook | undefined =
    sigmaWorkbooksData?.entries.find((workbook) => workbook.name === "Overview")

  return (
    <>
      <PageHeaderActionBar>
        <HStack alignItems="center" justifyContent="flex-end">
          <HStack>
            {siteInfoButton}
            <Collapse in={isSiteInfoOpen} orientation="horizontal">
              <Box width={(theme) => theme.typography.pxToRem(320)} />
            </Collapse>
          </HStack>
        </HStack>
      </PageHeaderActionBar>
      <Page fullHeight>
        <SigmaReport
          siteId={site?.id}
          workbookId={siteOverviewWorkbook?.workbookId}
        />
      </Page>
    </>
  )
}
