import React from "react"

import { UnitName } from "../../models/unit"
import type { IDepartmentSummary } from "../../modules/dashboard/services/useOrgSummaryStats/useOrgSummaryStatsUtils"
import { CENTS_PER_DOLLAR, KWH_PER_MWH } from "../../utils/constants"
import {
  formatEnergyVolumeInUnit,
  transformPricePerWhIntoUnit,
} from "../../utils/formatters"
import { CurrencyWithPlaceholder } from "../currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { DecimalWithPlaceholder } from "../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { Unit } from "../unit/unit"

/**
 * @deprecated This UI is slated to be replaced and this component will be deleted
 */
export const GroupFinancialMetrics = ({
  groupSummaryData,
  hasBundled,
  whDisplayUnit,
}: {
  groupSummaryData: IDepartmentSummary
  hasBundled: boolean
  whDisplayUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const groupSummary = {
    totalCost: 0.0,
    energyCost: 0.0,
    totalVolume: 0.0,
    carbonIntensity: 0.0,
  }

  groupSummary.totalCost = groupSummaryData.costMajor

  groupSummary.energyCost = hasBundled
    ? groupSummaryData.costMajor
    : groupSummaryData.energyCostMinor / CENTS_PER_DOLLAR

  groupSummary.totalVolume = formatEnergyVolumeInUnit(
    groupSummaryData.meteredKwh / KWH_PER_MWH,
    UnitName.MegawattHour,
    whDisplayUnit
  )

  const avgDepartmentEnergyCost = transformPricePerWhIntoUnit(
    !groupSummary.totalVolume
      ? 0
      : groupSummary.energyCost / groupSummary.totalVolume,
    whDisplayUnit,
    UnitName.KilowattHour
  )

  return (
    <table
      className="financial-summary__organization-table organization-summary__table"
      style={{ marginBottom: "16px" }}
    >
      <thead>
        <tr>
          <th data-org="cost" style={{ width: "125px" }}>
            {hasBundled ? "Total Estimated Cost" : "Energy Usage Cost"}
          </th>
          <th data-org="volume" style={{ width: "125px" }}>
            Total Volume
          </th>
          <th data-org="avg-cost" style={{ width: "125px" }}>
            Avg. Energy Cost
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className="organization-summary__table--dpt-value"
            data-e2e="cost-value"
            data-org="cost"
          >
            <CurrencyWithPlaceholder
              format={{ precision: 0 }}
              value={groupSummary.totalCost}
            />
          </td>
          <td
            className="organization-summary__table--dpt-value"
            data-e2e="volume-value"
            data-org="volume"
          >
            <DecimalWithPlaceholder
              format={{ precision: 2 }}
              value={groupSummary.totalVolume}
            />
            <span className="units-text">
              {" "}
              <Unit unit={whDisplayUnit} />
            </span>
          </td>
          <td
            className="organization-summary__table--dpt-value"
            data-e2e="avg-cost-value"
            data-org="avg-cost"
          >
            <CurrencyWithPlaceholder value={avgDepartmentEnergyCost} />
            <span className="units-text">
              <Unit unit={UnitName.PerKilowattHour} />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
