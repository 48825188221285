import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"

import { ApiQueryName } from "../../../../models/api"
import { fetchMinMaxMeteredCostRange } from "./useMinMaxMeteredCostRangeUtils"

/**
 * The useSiteTotals service/hook returns the min and max dates of
 * metered hour with cost data for an organization.
 *
 * @param organizationId - The organization id
 * @returns {
 * minMaxMeteredCostRange
 * isMinMaxMeteredCostRangeLoading
 * isMinMaxMeteredCostRangeIdle
 * isMinMaxMeteredCostRangeError
 * isMinMaxMeteredCostRangeSuccess
 * isMinMaxMeteredCostRangeFetching
 * isMinMaxMeteredCostRangeFetched
 * }
 * The minMaxMeteredCostRange service/hook return object
 * @example
 * const {
 *   minMaxMeteredCostRange,
 *   isMinMaxMeteredCostRangeLoading
 * } = useMinMaxMeteredCostRange(organizationId)
 */
export const useMinMaxMeteredCostRange = (organizationId: string) => {
  const queryKey = useQueryKey(
    ApiQueryName.MinMaxMeteredCostRange,
    "getOne",
    organizationId
  )

  const queryResult = useQuery<
    Awaited<ReturnType<typeof fetchMinMaxMeteredCostRange>> | undefined
  >({
    queryKey,
    queryFn: () => fetchMinMaxMeteredCostRange(organizationId),
    enabled: !!organizationId,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
