import React from "react"
import { Link } from "react-router-dom"

import { useTheme } from "@mui/material"

import { NzeroLogo } from "../logo/nzeroLogo"

import styles from "./unauthenticatedAppHeader.module.scss"

export const UnauthenticatedAppHeader = () => {
  const theme = useTheme()

  return (
    <header
      className={styles.root}
      style={{
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      }}
    >
      <Link
        style={{
          color: theme.palette.grey[800],
        }}
        to="/"
      >
        <NzeroLogo width={112} />
      </Link>
    </header>
  )
}
