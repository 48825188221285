import React from "react"
import type { FC } from "react"
import { Outlet, Route, Routes } from "react-router-dom"

import Page404 from "@/components/nav/page404/page404"
import UserGuard from "@/components/route/userGuard/userGuard"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { ReportPath } from "@/models/route"
import { useAuthentication } from "@/services/authentication"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useUserAccess } from "@/services/user"

import ActivityEmissions from "./components/activityEmissions/activityEmissions"
import ActivityEmissionsResolver from "./components/activityEmissionsResolver/activityEmissionsResolver"
import { CustomReport } from "./components/customReports/customReport/customReport"
import CustomReports from "./components/customReports/customReports"
import Gresb from "./components/gresb/gresb"
import GresbResolver from "./components/gresbResolver/gresbResolver"
import Gri from "./components/gri/gri"
import GriResolver from "./components/griResolver/griResolver"
import MonthlySiteData from "./components/monthlySiteData/monthlySiteData"
import MonthlySiteDataResolver from "./components/monthlySiteDataResolver/monthlySiteDataResolver"
import ReportSummary from "./components/reportSummary/reportSummary"
import { Reporting } from "./components/reporting/reporting"
import ScopeThree from "./components/scopeThree/scopeThree"
import ScopeThreeResolver from "./components/scopeThreeResolver/scopeThreeResolver"
import { SigmaReportRoute } from "./pages/sigma-report-route"

export const ReportingRoute: FC = () => {
  const { isConsultantUser } = useAuthentication()
  const { isLimitedAccessUser } = useUserAccess()
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  return (
    <Routes>
      <Route
        element={
          <UserGuard>
            <Outlet />
          </UserGuard>
        }
      >
        <Route index element={<Reporting />} />
        {!isConsultantUser &&
          !isLimitedAccessUser &&
          isFeatureEnabled(
            FeatureFlags.REPORT_CUSTOM_REPORTS,
            organization
          ) && (
            <Route
              path={`${ReportPath.CustomReports}`}
              element={<CustomReports />}
            />
          )}
        {!isConsultantUser &&
          !isLimitedAccessUser &&
          isFeatureEnabled(
            FeatureFlags.REPORT_CUSTOM_REPORTS,
            organization
          ) && (
            <Route
              path={`${ReportPath.CustomReports}/${ReportPath.Workbook}`}
              element={<CustomReport />}
            />
          )}
        {!isConsultantUser &&
          !isLimitedAccessUser &&
          isFeatureEnabled(
            FeatureFlags.REPORT_ACTIVITY_EMISSIONS,
            organization
          ) && (
            <Route
              path={`${ReportPath.EmissionsSources}`}
              element={
                <ActivityEmissionsResolver>
                  <ActivityEmissions />
                </ActivityEmissionsResolver>
              }
            />
          )}
        {!isLimitedAccessUser &&
          isFeatureEnabled(FeatureFlags.REPORT_GRESB, organization) && (
            <Route
              path={`${ReportPath.Gresb}`}
              element={
                <GresbResolver>
                  <Gresb />
                </GresbResolver>
              }
            />
          )}
        {!isLimitedAccessUser &&
          isFeatureEnabled(FeatureFlags.REPORT_GRI, organization) && (
            <Route
              path={`${ReportPath.Gri}`}
              element={
                <GriResolver>
                  <Gri />
                </GriResolver>
              }
            />
          )}
        {!isConsultantUser &&
          isFeatureEnabled(
            FeatureFlags.REPORT_MONTHLY_SITE_DATA,
            organization
          ) && (
            <Route
              path={`${ReportPath.MonthlySiteData}`}
              element={
                <MonthlySiteDataResolver>
                  <MonthlySiteData />
                </MonthlySiteDataResolver>
              }
            />
          )}
        {!isConsultantUser && (
          <Route
            path={`${ReportPath.ResourceSummary}`}
            element={<ReportSummary />}
          />
        )}
        {!isConsultantUser && !isLimitedAccessUser && (
          <Route
            path={`${ReportPath.ScopeThree}`}
            element={
              <ScopeThreeResolver>
                <ScopeThree />
              </ScopeThreeResolver>
            }
          />
        )}
        {isConsultantUser && (
          <Route path={ReportPath.Workbook} element={<SigmaReportRoute />} />
        )}
      </Route>
      <Route element={<Page404 />} path="*" />
    </Routes>
  )
}
