// @ts-strict-ignore
import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"

import { ApiQueryName } from "../../../../models/api"
import { fetchNaturalGasAvailableMonths } from "./useNaturalGasAvailableMonthsUtil"

export const useNaturalGasAvailableMonths = (siteId: string | undefined) => {
  const queryKey = useQueryKey(
    ApiQueryName.NaturalGasAvailableMonths,
    "getMany",
    siteId
  )
  const queryResult = useQuery({
    enabled: !!siteId,
    keepPreviousData: true,
    queryKey,
    queryFn: () => fetchNaturalGasAvailableMonths(siteId),
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
