import React from "react"
import type { FC } from "react"

import { useFormatDecimal } from "@/formatters/useFormatDecimal"

import type { ComplianceTargetUsageIntensityProps } from "./compliance-target-usage-intensity.types"

export const ComplianceTargetUsageIntensity: FC<
  ComplianceTargetUsageIntensityProps
> = ({ regulations, usageIntensityType }) => {
  const { format: formatDecimalToTenths } = useFormatDecimal({
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  })

  return !regulations?.length ? (
    <>--</>
  ) : (
    <>
      {regulations.map((regulation, index) => (
        <span
          key={`${regulation.regulation_name}-${regulation.effective_date}`}
        >
          {index > 0 && ", "}
          {
            // This logic is unnecessarily complicated
            // The api should not be returning effective dates when there is no regulation
            regulation.regulation_name === "No Regulation" ? (
              <>--</>
            ) : (
              formatDecimalToTenths(regulation[`target_${usageIntensityType}`])
            )
          }
        </span>
      ))}
    </>
  )
}
