import React from "react"
import type { FC } from "react"

import { Stack, Typography } from "@mui/material"

import repLandingHeaderSrc from "../../../../../../assets/images/rep/rep-landing-header.png"
import { HStack } from "../../../../../../nzds"

export const RepIndexHero: FC = () => (
  <HStack gap={2}>
    <img
      alt="rapid emissions icon"
      height="150"
      src={repLandingHeaderSrc as string}
      width="150"
    />
    <Stack gap={0.5} justifyContent="center">
      <Typography variant="h1">
        Identify regulatory fines—maximize reduction opportunities
      </Typography>
      <Typography variant="body1">
        Navigate through a seamless workflow to identify local regulatory
        compliance risks.
      </Typography>
    </Stack>
  </HStack>
)
