import { useCallback, useMemo, useState } from "react"

interface IUseToggle {
  isOn: boolean
  toggle: () => void
}

export const useToggle: () => IUseToggle = () => {
  const [isOn, setIsOn] = useState<boolean>(false)

  const toggle = useCallback((): void => {
    setIsOn((prev) => !prev)
  }, [])

  return useMemo(
    () => ({
      isOn,
      toggle,
    }),
    [isOn, toggle]
  )
}
