import type { IFilter } from "./filter"

/**
 * A filter for boolean values.
 *
 * @param value - A boolean value
 * @returns - The filter instance
 * @example
 * new BooleanFilter(true)
 */
export default class BooleanFilter implements IFilter<BooleanFilter, boolean> {
  value: boolean | null

  constructor(value?: boolean) {
    this.value = value ?? null
  }

  isEqualTo(filter: BooleanFilter) {
    return this.value === filter.value
  }

  toQueryParamValue() {
    return this.value?.toString() ?? "false"
  }

  static fromQueryParamValue(queryParamValue: string): BooleanFilter {
    if (queryParamValue?.toLowerCase() === "true") {
      return new BooleanFilter(true)
    }

    if (queryParamValue?.toLowerCase() === "false") {
      return new BooleanFilter(false)
    }

    return new BooleanFilter()
  }
}
