import React from "react"
import type { FC } from "react"

import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox as MuiCheckbox,
} from "@mui/material"

import type { CheckboxProps } from "./checkbox.types"

export const Checkbox: FC<CheckboxProps> = ({
  label,
  error,
  required,
  helperText,
  sx,
  ...rest
}) => {
  // this is a workaround until material ui is at 5.14.12 or more, then can just pass required and error to formcontrol label and formcontrol I believe
  const checkboxLabel = required ? (
    <span>
      {label}
      <Box color={error ? "error.main" : "inherit"} component="span">
        &thinsp;*
      </Box>
    </span>
  ) : (
    label
  )

  return (
    <FormControl error={error} required={required} sx={sx}>
      <FormGroup>
        <FormControlLabel
          control={<MuiCheckbox {...rest} />}
          label={checkboxLabel}
        />
      </FormGroup>
      {helperText ? (
        <FormHelperText sx={{ mt: -1 }}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  )
}
