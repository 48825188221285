import React from "react"

import { area, curveLinear, line, select } from "d3"
import { groupBy, map, nth, sortBy, sum, toPairs } from "lodash-es"
import moment from "moment"
import * as R from "ramda"

import type { Palette } from "@mui/material"
import { useTheme } from "@mui/material"

import type { Allocation } from "../models/energyAllocation"
import EnergyAllocation from "../models/energyAllocation"
import { UnitName } from "../models/unit"
import { KWH_PER_MWH } from "../utils/constants"
import { PriceDisplay } from "./priceDisplay"
import { Unit } from "./unit/unit"

const DrawAverageCostGraphic = (averageDailyPrices, palette: Palette) => {
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (averageDailyPrices.length === 0) {
    return
  }

  const HEIGHT = 40
  const WIDTH = 60
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const widthModifier = WIDTH / averageDailyPrices.length

  // migration to strict mode batch disable
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const maxPrice = R.reduce(R.max, 0, averageDailyPrices)
  // migration to strict mode batch disable
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const minPrice = R.reduce(R.min, Infinity, averageDailyPrices)
  const heightModifier = maxPrice > 0 ? HEIGHT / maxPrice : 0
  // migration to strict mode batch disable
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const pricingTrendData = averageDailyPrices.map((price, idx) => [
    idx * widthModifier,
    price * heightModifier,
  ])

  const svg = select(".price-overview .price-overview__avg-cost .graphic")
    .attr("height", HEIGHT)
    .attr("width", WIDTH)

  svg.selectAll("*").remove()

  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const pricingTrendLine = line().curve(curveLinear)(pricingTrendData)

  // Draw the line
  svg.append("path").attr("class", "line").attr("d", pricingTrendLine)

  // Draw the area
  svg
    .append("path")
    .attr("class", "area")
    .attr(
      "d",
      area()
        .x((d) => d[0])
        .y1(minPrice * heightModifier)
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .y0((d) => d[1])(pricingTrendData)
    )

  // Draw the circle indicating most recent price
  // migration to strict mode batch disable
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const lastPricePoint = pricingTrendData[pricingTrendData.length - 1]

  svg
    .append("circle")
    .attr("class", "circle")
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    .attr("cx", lastPricePoint[0])
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    .attr("cy", lastPricePoint[1])
    .attr("r", "2")
    .attr("stroke", "#018281")
    .attr("fill", palette.common.white)
}

const averageCostForHours = (aggregatedAllocations, tariffDollars) => {
  const contractCost = EnergyAllocation.getLossAdjustedConfirmCosts(
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    aggregatedAllocations
  )
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const lineLossCost = EnergyAllocation.getLineLossCosts(aggregatedAllocations)
  const imbalanceCost = EnergyAllocation.getLossAdjustedImbalanceCosts(
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    aggregatedAllocations
  )
  const schedulingCost = EnergyAllocation.getSchedulingCosts(
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    aggregatedAllocations
  )

  const totalMwh =
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    sum(map(aggregatedAllocations, (alloc) => alloc.meteredKwh || 0)) /
    KWH_PER_MWH
  const transmissionAndDistributionCost =
    totalMwh > 0 && tariffDollars ? totalMwh * tariffDollars : 0
  const totalCost =
    contractCost +
    imbalanceCost +
    lineLossCost +
    transmissionAndDistributionCost +
    schedulingCost
  return totalMwh > 0 ? totalCost / totalMwh : 0
}

export const PricingOverview = ({
  aggregatedAllocations,
  tariffDollars,
}: {
  aggregatedAllocations: Allocation[]
  tariffDollars: number
}) => {
  const theme = useTheme()
  const grouped = groupBy(aggregatedAllocations, (allocation) =>
    moment(allocation.hour).startOf("day")
  )
  const inPairs = sortBy(toPairs(grouped), (pair) => nth(pair, 0))
  const allocationGroups = map(inPairs, (pair) => nth(pair, 1))
  const averageDailyCosts = map(allocationGroups, (allocations) =>
    averageCostForHours(allocations, tariffDollars)
  )

  React.useEffect(() => {
    DrawAverageCostGraphic(averageDailyCosts, theme.palette)
  }, [averageDailyCosts, theme.palette])

  return (
    <div className="price-overview">
      <div className="price-overview__avg-cost price-overview__cell">
        <PriceDisplay
          amount={averageCostForHours(aggregatedAllocations, tariffDollars)}
        >
          Avg Cost (<Unit unit={UnitName.CurrencyPerMegawattHour} />)
        </PriceDisplay>
      </div>
    </div>
  )
}
