import MonthSummary from "../../../../models/monthSummary"

interface GetTotalElectricityCostProps {
  /**
   * The end date of the date range to get the total electricity cost for
   */
  endDate: string
  /**
   * The id of the organization to get the total electricity cost for
   */
  organizationId: string
  /**
   * The start date of the date range to get the total electricity cost for
   */
  startDate: string
}

export interface GetTotalElectricityCostValue {
  /**
   * The total energy consumption value
   */
  total: number
}

export const getElectricityCost = async ({
  organizationId,
  startDate,
  endDate,
}: GetTotalElectricityCostProps): Promise<GetTotalElectricityCostValue> => {
  const monthSummaries = await MonthSummary.stats({
    cost_major: "sum",
  })
    .where({
      organization_id: organizationId,
      startDate,
      endDate,
    })
    .per(0)
    .all()

  const stats = monthSummaries.meta.stats as Record<
    string,
    Record<string, string>
  >

  return { total: Number(stats.cost_major.sum) }
}
