import React from "react"
import type { FieldValues } from "react-hook-form"

import { Form } from "./form"
import type { FormProps } from "./form.types"

export const DialogForm = <TFieldValues extends FieldValues = FieldValues>(
  props: FormProps<TFieldValues>
) => (
  <Form
    {...props}
    sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }}
  />
)
