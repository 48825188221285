import { useMemo } from "react"

import { OrgViewDialogDeleteActions } from "../../../../../../models/onboarding/onboarding"
import type { UseEntityDeleteOptions, UseEntityDeleteValue } from "../types"

/**
 * Returns an object with a deleteOne function for a particular entity.
 *
 * @param entityName
 * @param onSuccess
 * @param onError
 * @returns An object with the deleteOne function
 * @example
 * const { deleteOne } = useEntityDelete({
 *  entityName: "Organization",
 *  onSuccess: () => {
 *   ...
 *  },
 *  onError: () => {
 *   ...
 *  })
 */
const useEntityDelete = ({
  entityName,
  onSuccess,
  onError,
}: UseEntityDeleteOptions): UseEntityDeleteValue => {
  const useEntityDeleteAction = OrgViewDialogDeleteActions[entityName]
  const entityDeleteName = `deleteOne${entityName.replaceAll(" ", "")}`
  // TO-DO: Properly type this
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const entityDelete = useEntityDeleteAction({ onSuccess, onError })
  return useMemo(
    () => ({
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      deleteOne: entityDelete[entityDeleteName],
    }),
    [entityDelete, entityDeleteName]
  )
}

export default useEntityDelete
