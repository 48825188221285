import React, { useMemo } from "react"

import { Box, useTheme } from "@mui/material"

import { MetricType } from "../../../models/decimal/decimal"
import { UnitName } from "../../../models/unit"
import type { ScopeTwoEmissionsData } from "../../../modules/dashboard/components/organizationDashboardScopeSection/organizationDashboardScopeSection"
import { MonthlyStackedAreaGraph } from "../../graph/monthly-stacked-area-graph/monthly-stacked-area-graph"
import type { MonthlyAreaGraphDatum } from "../../graph/types"
import { Scope2Metrics } from "./scope2Metrics"
import Scope2Tooltip from "./scope2Tooltip"

interface IScope2CardContentProps {
  isLimitedAccessUser: boolean
  scopeTwoData: ScopeTwoEmissionsData
}

export interface Scope2DataE2eAttributes {
  averageCost: string
  totalCost: string
  totalEmissionsValue: string
}

const Scope2E2eAttr: Scope2DataE2eAttributes = {
  totalEmissionsValue: "scope-two-emissions-value",
  totalCost: "scope-two-total-cost",
  averageCost: "scope-two-average-cost",
}

const styles = {
  cardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  metricsContainer: {
    pb: 2,
  },
  graphContainer: {
    position: "relative",
    display: "flex",
  },
}

const scopeTwoTooltipLabelsMap = {
  costMajor: {
    label: "Cost",
    metricFormattingComponentId: MetricType.CurrencyWithPlaceholder,
  },
  value: {
    label: "MT CO2e",
    metricFormattingComponentId: MetricType.Emission,
  },
}

export const Scope2CardContent = ({
  scopeTwoData,
  isLimitedAccessUser,
}: IScope2CardContentProps) => {
  const theme = useTheme()

  const { graphData, totalCost, totalEmissions, totalKwh } = scopeTwoData

  const averageCost = totalCost / totalKwh

  const colors: string[] = [theme.palette.spectrum.cerulean[200]]

  // Ensure each graph item returns a number value
  const data = useMemo(
    () =>
      graphData.map((datum) => {
        const newDatum = { ...datum } as MonthlyAreaGraphDatum
        ;["value"].forEach((key) => {
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          newDatum[key] = datum[key] ?? 0
        })
        return newDatum
      }),
    [graphData]
  )

  return (
    <Box sx={styles.cardContent}>
      <Box sx={styles.metricsContainer}>
        <Scope2Metrics
          averageCost={averageCost}
          dataE2e={Scope2E2eAttr}
          isLimitedAccessUser={isLimitedAccessUser}
          totalCost={totalCost}
          totalEmissions={totalEmissions}
        />
      </Box>
      <Box height={theme.typography.pxToRem(242)}>
        <MonthlyStackedAreaGraph
          colors={colors}
          customTooltip={<Scope2Tooltip />}
          keys={["costMajor", "value"]}
          keysToNotGraph={["costMajor"]}
          rawData={data}
          title="Scope 2"
          tooltipLabelsMap={scopeTwoTooltipLabelsMap}
          unit={UnitName.MetricTonsOfCarbonDioxideEquivalent}
        />
      </Box>
    </Box>
  )
}
