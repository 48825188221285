import React from "react"
import type { FC } from "react"

import { PerformanceTier } from "@/components/performance"

import { Stack } from "@mui/material"

export const PerformanceTooltipLegend: FC = () => (
  <Stack gap={1}>
    <PerformanceTier tier="extremelyInefficient" />
    <PerformanceTier tier="inefficient" />
    <PerformanceTier tier="atBenchmark" />
    <PerformanceTier tier="efficient" />
    <PerformanceTier tier="highlyEfficient" />
  </Stack>
)
