import createClient from "openapi-fetch"

import createFetchClient from "./fetch-client/client"
import type { paths } from "./generated/schema"

export const client = createClient<paths>({
  baseUrl: process.env.REACT_APP_API_BASE_URL ?? "",
  bodySerializer: (body) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    JSON.stringify(body, (k, v) => (v === undefined ? null : v)),
})

export const fetchClient = createFetchClient({
  baseUrl: process.env.REACT_APP_API_BASE_URL ?? "",
})
