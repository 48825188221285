import EnergyAllocation from "../../../../models/energyAllocation"
import type { SitePremiseFinancialSummary } from "../../../../models/sitePremiseFinancialSummary"
import { fetchSequentially } from "../../../../utils"

interface FetchSiteFinancialSummaryProps {
  searchEndDate: moment.Moment
  searchStartDate: moment.Moment
  siteId: string | number
}

export const fetchSiteFinancialSummaries = ({
  siteId,
  searchStartDate,
  searchEndDate,
}: FetchSiteFinancialSummaryProps): Promise<SitePremiseFinancialSummary[]> =>
  EnergyAllocation.fetchBillingGroups({
    siteId,
    searchStartDate,
    searchEndDate,
  }).then((billingGroupIds) => {
    const promiseArray = billingGroupIds
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      .filter((billingGroupId) => billingGroupId !== null)
      .map((billingGroupId) =>
        EnergyAllocation.fetchFinancialSummary({
          siteId,
          searchStartDate,
          searchEndDate,
          billingGroupId,
        })
      )
    return fetchSequentially(promiseArray).then(
      (premiseFinancialSummaryResults: SitePremiseFinancialSummary[]) => {
        const summaries = premiseFinancialSummaryResults
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          .filter((x) => x != null)
          .sort(
            (ea1, ea2) =>
              Number(ea1.billingGroupName) - Number(ea2.billingGroupName)
          )
        return summaries
      }
    )
  })
