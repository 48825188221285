// @ts-strict-ignore
import type { Moment } from "moment"

import type { MonthlyNaturalGasGraphDatum } from "../../../../components/graph/types"
import type { IRange } from "../../../../models/range"
import { useMonthlyConsumptionGraphData } from "../useMonthlyConsumptionGraphData/useMonthlyConsumptionGraphData"
import { useSiteDailyNaturalGasEmissions } from "../useSiteDailyNaturalGasEmissions/useSiteDailyNaturalGasEmissions"
import {
  getDailyAverageNaturalGasThm,
  getTotalEmissionsNaturalGasCo2eLbs,
  getTotalVolumeNaturalGasThm,
} from "./useMonthlySiteNaturalGasEmissionsDataUtil"

/**
 * Provides monthly consumption data (water and gas) for a given organization and date range
 * also provides natural gas emissions data for a given site and date range
 * with loading state and other expected boolean values
 * @example
 * const {
 *  dailyAvgNaturalGasThm,
 * graphData,
 * totalEmissionsNaturalGasCo2eLbs,
 * totalVolumeNaturalGasGasThm,
 * isMonthlySiteNaturalGasEmissionsDataLoading,
 * isMonthlySiteNaturalGasEmissionsDataFetching,
 * isMonthlySiteNaturalGasEmissionsDataFetched,
 * } = useMonthlySiteNaturalGasEmissionsData("1", {
 *  dateRange: {
 *    start: moment(),
 *    end: moment(),
 *  },
 *  siteId: "1",
 *})
 */
export const useMonthlySiteNaturalGasEmissionsData = (
  orgId: string,
  dateRange: IRange<Moment>,
  { siteId }
): {
  dailyAvgNaturalGasThm: number
  graphData: MonthlyNaturalGasGraphDatum[]
  isMonthlySiteNaturalGasEmissionsDataFetched: boolean
  isMonthlySiteNaturalGasEmissionsDataFetching: boolean
  isMonthlySiteNaturalGasEmissionsDataLoading: boolean
  totalEmissionsNaturalGasCo2eLbs: number
  totalVolumeNaturalGasGasThm: number
} => {
  const {
    monthlyConsumptionGraphData,
    isMonthlyConsumptionGraphDataFetched,
    isMonthlyConsumptionGraphDataFetching,
    isMonthlyConsumptionGraphDataLoading,
  } = useMonthlyConsumptionGraphData(
    orgId,
    {
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      start: dateRange?.start?.clone()?.startOf("month"),
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      end: dateRange?.end?.clone()?.endOf("month"),
    },
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    { siteId }
  )
  const {
    siteDailyNaturalGasEmissions,
    isSiteDailyNaturalGasEmissionsFetched,
    isSiteDailyNaturalGasEmissionsFetching,
    isSiteDailyNaturalGasEmissionsLoading,
  } = useSiteDailyNaturalGasEmissions(siteId, { dateRange })

  return {
    isMonthlySiteNaturalGasEmissionsDataLoading:
      isSiteDailyNaturalGasEmissionsLoading ||
      isMonthlyConsumptionGraphDataLoading,
    isMonthlySiteNaturalGasEmissionsDataFetching:
      isMonthlyConsumptionGraphDataFetching ||
      isSiteDailyNaturalGasEmissionsFetching,
    isMonthlySiteNaturalGasEmissionsDataFetched:
      isMonthlyConsumptionGraphDataFetched &&
      isSiteDailyNaturalGasEmissionsFetched,
    graphData: monthlyConsumptionGraphData,
    dailyAvgNaturalGasThm: getDailyAverageNaturalGasThm(
      siteDailyNaturalGasEmissions,
      dateRange
    ),
    totalEmissionsNaturalGasCo2eLbs: getTotalEmissionsNaturalGasCo2eLbs(
      siteDailyNaturalGasEmissions
    ),
    totalVolumeNaturalGasGasThm: getTotalVolumeNaturalGasThm(
      siteDailyNaturalGasEmissions
    ),
  }
}
