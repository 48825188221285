import React from "react"

import InfoIcon from "@mui/icons-material/Info"
import { Box, Typography } from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../../../../models/typography"
import { dustyGray, hoverOpacity } from "../../../../../../../utils/colors"

const footerText =
  "Implementing sub meters can provide more frequent data reporting."

interface ElectricityHeaderMeterDataCardProps {
  meterTypeContent: string
  showFooter?: boolean
}

const ElectricityHeaderMeterDataCard = ({
  meterTypeContent,
  showFooter = true,
}: ElectricityHeaderMeterDataCardProps) => (
  <Box>
    <Typography
      fontSize="1rem"
      fontStyle="italic"
      sx={{ mb: "8px", px: "8px" }}
    >
      {meterTypeContent}
    </Typography>

    {showFooter && (
      <Box
        sx={{
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          backgroundColor: `${hoverOpacity.rgb()}`,
          display: "flex",
          p: "8px",
          borderRadius: "4px",
        }}
      >
        <InfoIcon
          fontSize="small"
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          sx={{ color: `${dustyGray.rgb()}`, mr: "8px" }}
        />
        <Typography variant={TYPOGRAPHY_VARIANT.body2}>{footerText}</Typography>
      </Box>
    )}
  </Box>
)

export default ElectricityHeaderMeterDataCard
