import type { FC } from "react"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import type {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro"
import { DataGridPro } from "@mui/x-data-grid-pro"

import { useUnit } from "../../../../components/unit/use-unit"
import { useCurrencySymbol } from "../../../../hooks/useCurrencySymbol/useCurrencySymbol"
import { UnitName } from "../../../../models/unit"
import { renderColumnHeader } from "../../../../utils/render-column-header"
import { renderRowNumber } from "../../../../utils/render-row-number"
import { useUserSettings } from "../../../settings"
import { emptyDataPlaceholder } from "../../constants"
import { InterventionTypeCell } from "../intervention-type-cell/intervention-type-cell"
import type { RowData } from "./interventions-table.types"

interface InterventionsTableProps {
  rows: RowData[]
}

const baseColumnDef: Partial<GridColDef> = {
  disableColumnMenu: true,
  hideable: false,
  disableReorder: true,
  renderHeader: renderColumnHeader,
  flex: 1,
}

export const InterventionsTable: FC<InterventionsTableProps> = ({ rows }) => {
  const { t } = useTranslation()
  const co2eUnit = useUnit(UnitName.MetricTonsOfCarbonDioxideEquivalent)
  const currencySymbol = useCurrencySymbol()
  const { currency } = useUserSettings()
  const columnDefinitions: GridColDef[] = useMemo(() => {
    const moneyFormatParams = {
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
    return [
      {
        ...baseColumnDef,
        field: "number",
        align: "center",
        headerAlign: "center",
        sortable: false,
        resizable: false,
        headerName: "#",
        width: 44,
        maxWidth: 44,
        renderCell: renderRowNumber,
      },
      {
        ...baseColumnDef,
        field: "name",
        align: "left",
        headerName: "Intervention name",
        minWidth: 230,
      },
      {
        ...baseColumnDef,
        field: "interventionType",
        align: "left",
        headerName: "Intervention type",
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<RowData, RowData["interventionType"]>
        ) => {
          if (params.value) {
            return <InterventionTypeCell interventionType={params.value} />
          }
          return emptyDataPlaceholder
        },
      },
      {
        ...baseColumnDef,
        field: "percentEmissionSavings",
        align: "right",
        headerAlign: "right",
        headerName: "Emission Reduction (%)",
        minWidth: 200,
        valueFormatter: (
          params: GridValueFormatterParams<number | undefined>
        ) => {
          if (!params.value) {
            return emptyDataPlaceholder
          }
          const formatted = t("common:percentage", {
            value: params.value,
            maximumFractionDigits: 2,
            minimumFactionDigits: 2,
          })
          return formatted
        },
      },
      {
        ...baseColumnDef,
        field: "annualEmissionsSavings",
        align: "right",
        headerAlign: "right",
        headerName: `${co2eUnit} Removed`,
        valueFormatter: (params: GridValueFormatterParams<string | number>) => {
          if (typeof params.value === "string") {
            return emptyDataPlaceholder
          }
          const formattedDecimal = t("common:decimal", {
            value: params.value,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
          return formattedDecimal
        },
      },
      {
        ...baseColumnDef,
        field: "capitalCost",
        align: "right",
        headerAlign: "right",
        headerName: `Capital cost (${currencySymbol})`,
        valueFormatter: (
          params: GridValueFormatterParams<number | undefined>
        ) => {
          if (!params.value) {
            return emptyDataPlaceholder
          }
          const formatted = t("common:money", {
            value: params.value,
            formatParams: {
              value: moneyFormatParams,
            },
          })
          return formatted
        },
      },
      {
        ...baseColumnDef,
        field: "annualCostSavings",
        align: "right",
        headerAlign: "right",
        headerName: `Annual cost savings (${currencySymbol})`,
        valueFormatter: (
          params: GridValueFormatterParams<number | undefined>
        ) => {
          if (!params.value) {
            return emptyDataPlaceholder
          }
          const formatted = t("common:money", {
            value: params.value,
            formatParams: {
              value: moneyFormatParams,
            },
          })
          return formatted
        },
      },
      {
        ...baseColumnDef,
        field: "roiYears",
        align: "right",
        headerAlign: "right",
        headerName: `ROI (Years)`,
        maxWidth: 100,
        valueFormatter: (
          params: GridValueFormatterParams<number | undefined>
        ) => {
          if (!params.value) {
            return emptyDataPlaceholder
          }
          const formatted = t("common:decimal", {
            value: params.value,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
          return formatted
        },
      },
    ]
  }, [co2eUnit, currency, currencySymbol, t])

  return (
    <DataGridPro
      columnHeaderHeight={62}
      columns={columnDefinitions}
      disableRowSelectionOnClick
      disableVirtualization
      initialState={{
        sorting: {
          sortModel: [{ field: "contribution", sort: "desc" }],
        },
      }}
      loading={false}
      rowHeight={48}
      rows={rows}
    />
  )
}
