import { type JSONSchema6 } from "json-schema"
import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class PhysicalDetailType extends ApplicationRecord {
  public static jsonapiType = "physical_detail_types"

  @Attr() public name: string

  @Attr() public description: string | null

  @Attr() public schema: JSONSchema6
}
