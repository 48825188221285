import type { FC } from "react"
import React, { useMemo } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller } from "react-hook-form"

import DriveFileMoveRounded from "@mui/icons-material/DriveFileMoveRounded"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material"

import type OrganizationalUnit from "../../../../../models/organizationalUnit"
import type Site from "../../../../../models/site"
import { TextField } from "../../../../../nzds"
import { DialogTitle } from "../../../../../nzds/feedback/dialog-title/dialog-title"
import { DialogForm } from "../../../../../nzds/forms/form/dialog-form"
import { SubmitButton } from "../../../../../nzds/forms/submit-button/submit-button"
import { useForm } from "../../../../../nzds/forms/use-form/use-form"
import { Autocomplete } from "../../../../../nzds/inputs/autocomplete"
import { useBatchUpdateOrgUnits } from "../../../hooks/use-batch-update-org-units/use-batch-update-org-units"
import { useBatchUpdateSites } from "../../../hooks/use-batch-update-sites/use-batch-update-sites"
import type { MoveItemsDialogProps } from "./move-items-dialog.types"
import type { Schema } from "./schema"
import { schema } from "./schema"
import { buildAutocompleteOptions } from "./utils"

export const MoveItemsDialog: FC<MoveItemsDialogProps> = ({
  selectedRows,
  onClose,
  open,
  organizationalUnits,
}) => {
  const form = useForm({
    schema,
    defaultValues: {
      destination: "",
    },
  })

  const { reset, control } = form
  const { mutateAsync: mutateOrgUnits, isLoading: isUpdateOrgUnitLoading } =
    useBatchUpdateOrgUnits()
  const { mutateAsync: mutateSites, isLoading: isUpdateSiteLoading } =
    useBatchUpdateSites()

  const autocompleteOptions = useMemo(
    () => buildAutocompleteOptions(organizationalUnits, selectedRows),
    [organizationalUnits, selectedRows]
  )

  const handleClose = () => {
    reset()
    onClose()
  }

  const handleSubmit: SubmitHandler<Schema> = async ({ destination }) => {
    const destinationOU = organizationalUnits.find(
      (ou) => ou.name === destination
    )
    const orgUnitsToUpdate: OrganizationalUnit[] = []
    const sitesToUpdate: Site[] = []

    selectedRows.forEach((row) => {
      if (row.type === "ou") {
        const ou = row.model as OrganizationalUnit
        ou.parentId = Number(destinationOU.id)
        orgUnitsToUpdate.push(ou)
      } else if (row.type === "site") {
        const site = row.model as Site
        site.organizationalUnit = destinationOU
        sitesToUpdate.push(site)
      }
    })
    const promises = []
    if (orgUnitsToUpdate.length) {
      promises.push(mutateOrgUnits({ orgUnits: orgUnitsToUpdate }))
    }
    if (sitesToUpdate.length) {
      promises.push(mutateSites({ sites: sitesToUpdate }))
    }
    await Promise.all(promises)
    handleClose()
  }

  return (
    <Dialog
      aria-describedby="move-items-dialog-description"
      aria-labelledby="move-items-dialog-title"
      data-testid="moveItemsDialog"
      fullWidth
      maxWidth="xs"
      open={open}
    >
      <DialogTitle id="move-items-dialog-title" onClose={handleClose}>
        Move selected items
      </DialogTitle>
      <DialogForm<Schema> form={form} onSubmit={handleSubmit}>
        <DialogContent>
          <Stack gap={2}>
            <DialogContentText id="move-items-dialog-description">
              All child elements will be moved with their parent which may
              change access for some users.
            </DialogContentText>

            <Controller
              control={control}
              name="destination"
              render={({ field }) => {
                const { onChange, value } = field
                return (
                  <Autocomplete
                    getOptionLabel={(option: string) => option}
                    onChange={(_event, newValue) => {
                      onChange(newValue ?? null)
                    }}
                    options={autocompleteOptions}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="Select Destination Group (OU)"
                        {...params}
                      />
                    )}
                    value={
                      value
                        ? (autocompleteOptions.find(
                            (option) => option === value
                          ) ?? null)
                        : null
                    }
                  />
                )
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isUpdateOrgUnitLoading || isUpdateSiteLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <SubmitButton endIcon={<DriveFileMoveRounded fontSize="small" />}>
            Move
          </SubmitButton>
        </DialogActions>
      </DialogForm>
    </Dialog>
  )
}
