import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import {
  ComplianceDates,
  ComplianceEmissionFactorName,
  ComplianceEmissionFactorValue,
  ComplianceFines,
  ComplianceReduction,
  ComplianceStandards,
  ComplianceStatus,
  ComplianceStatusReduced,
  ComplianceTargetUsageIntensity,
  ComplianceTotalEmissions,
  ComplianceUsageIntensity,
} from "@/components/compliance"
import { useFormatDecimal } from "@/formatters/useFormatDecimal"
import { useCurrencySymbol } from "@/hooks/useCurrencySymbol/useCurrencySymbol"
import { UnitName } from "@/models/unit"
import { Blur } from "@/nzds"
import {
  DataGridColumnHeader,
  baseColumnDef,
  baseNumberColumnDef,
} from "@/nzds/data-grid"
import { translateUnit } from "@/utils"

import { Stack, Typography } from "@mui/material"
import type {
  GridColDef,
  GridColumnVisibilityModel,
  GridPinnedColumns,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid-pro"

import type { RowData } from "../pages.types"
import {
  calculateIsBlurred,
  useCommonResultsColumns,
} from "./use-common-results-columns"

export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
  annual_volume_electricity: false,
  combined_volume: false,
  electricity_ef: false,
  electricity_source: false,
  natural_gas_ef: false,
  natural_gas_source: false,
  sq_ft: false,
  total_emissions: false,
}

export const defaultPinnedColumns: GridPinnedColumns = {
  left: ["rowNumber", "name"],
  right: ["planning"],
}

export const defaultSortModel: GridSortModel = [
  {
    field: "compliance_penalties",
    sort: "desc",
  },
]

export const useComplianceResultsColumns = (): GridColDef<RowData>[] => {
  const { i18n } = useTranslation()
  const currencySymbol = useCurrencySymbol()

  const { format: formatWholeNumber } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  const { format: formatDecimalToTenths } = useFormatDecimal({
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  })

  const commonResultsColumns = useCommonResultsColumns()

  const columns: GridColDef<RowData>[] = useMemo(() => {
    return [
      {
        ...commonResultsColumns.rowNumber,
      },
      {
        ...commonResultsColumns.siteName,
      },
      {
        ...baseColumnDef,
        field: "in_compliance",
        headerName: "Compliance Status",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceStatusReduced regulations={params.row.regulations} />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Compliance Status"
            field="in_compliance"
            headerName="Compliance Status"
          />
        ),
        width: 160,
      },
      {
        ...baseColumnDef,
        field: "regulation_name",
        headerName: "Compliance Standard",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceStandards regulations={params.row.regulations} />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Compliance Standard"
            field="regulation_name"
            headerName="Compliance Standard"
            tooltipContent={
              <>
                <Typography mb={1.5} variant="body2">
                  Indicates the estimated extent to which each site aligns with
                  state and federal regulations or standards.
                </Typography>
                <Stack mb={1} ml={0.5} spacing={0.5}>
                  <ComplianceStatus isCompliant={true} />
                  <ComplianceStatus isCompliant={false} />
                </Stack>
              </>
            }
          />
        ),
        sortable: false,
      },
      {
        ...baseColumnDef,
        field: "effective_date",
        headerName: "Compliance Period",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceDates regulations={params.row.regulations} />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Compliance Period"
            field="effective_date"
            headerName="Compliance Period"
          />
        ),
        sortable: false,
        width: 160,
      },
      {
        ...baseNumberColumnDef,
        field: "compliance_penalties",
        headerName: "Potential Fines",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceFines regulations={params.row.regulations} />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Potential Fines"
            field="compliance_penalities"
            headerName="Potential Fines"
            unit={currencySymbol}
          />
        ),
        width: 152,
      },
      {
        ...baseNumberColumnDef,
        field: "reduction_required",
        headerName: "Reduction Required",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceReduction regulations={params.row.regulations} />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Reduction Required"
            field="reduction_required"
            headerName="Reduction Required"
            unit="%"
          />
        ),
        sortable: false,
        width: 180,
      },
      {
        ...baseNumberColumnDef,
        field: "total_emissions",
        headerName: "Total Emissions",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceTotalEmissions regulations={params.row.regulations} />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Total Emissions"
            field="total_emissions"
            headerName="Total Emissions"
            unit={translateUnit(
              i18n.language,
              UnitName.MetricTonsOfCarbonDioxideEquivalent
            )}
          />
        ),
        sortable: false,
        width: 160,
      },
      {
        ...baseNumberColumnDef,
        field: "calculated_eui",
        headerName: "Energy Usage Intensity",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceUsageIntensity
              regulations={params.row.regulations}
              usageIntensityType="eui"
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Energy Usage Intensity"
            field="calculated_eui"
            headerName="Energy Usage Intensity"
            unit={translateUnit(
              i18n.language,
              UnitName.KiloBritishThermalUnitPerSquareFoot
            )}
          />
        ),
        sortable: false,
      },
      {
        ...baseNumberColumnDef,
        field: "target_eui",
        headerName: "Target EUI",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceTargetUsageIntensity
              regulations={params.row.regulations}
              usageIntensityType="eui"
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Target EUI"
            field="target_eui"
            headerName="Target EUI"
            unit={translateUnit(
              i18n.language,
              UnitName.KiloBritishThermalUnitPerSquareFoot
            )}
          />
        ),
        sortable: false,
        width: 140,
      },
      {
        ...baseNumberColumnDef,
        field: "calculated_cui",
        headerName: "Carbon Usage Intensity",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceUsageIntensity
              regulations={params.row.regulations}
              usageIntensityType="cui"
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Carbon Usage Intensity"
            field="calculated_cui"
            headerName="Carbon Usage Intensity"
            unit="kg CO2e / sq ft" // Purposely not translated per locale
          />
        ),
        sortable: false,
      },
      {
        ...baseNumberColumnDef,
        field: "target_cui",
        headerName: "Target CUI",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceTargetUsageIntensity
              regulations={params.row.regulations}
              usageIntensityType="cui"
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Target CUI"
            field="target_cui"
            headerName="Target CUI"
            unit="kg CO2e / sq ft" // Purposely not translated per locale
          />
        ),
        sortable: false,
        width: 140,
      },
      {
        ...commonResultsColumns.combinedVolume,
      },
      {
        ...baseColumnDef,
        field: "electricity_source",
        headerName: "Electricity EF",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur
            isBlurred={calculateIsBlurred(params.row.id)}
            sx={{ maxWidth: "100%" }}
          >
            <ComplianceEmissionFactorName
              emissionFactorType="electricity"
              regulations={params.row.regulations}
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Electricity EF"
            field="electricity_source"
            headerName="Electricity EF"
          />
        ),
        sortable: false,
        width: 120,
      },
      {
        ...baseNumberColumnDef,
        field: "electricity_ef",
        headerName: "Electricity EF Value",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceEmissionFactorValue
              emissionFactorType="electricity"
              regulations={params.row.regulations}
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Electricity EF Value"
            field="electricity_ef"
            headerName="Electricity EF Value"
            unit="kg CO2e / kWh" // Purposely not translated per locale
          />
        ),
        sortable: false,
        width: 160,
      },
      {
        ...baseColumnDef,
        field: "natural_gas_source",
        headerName: "Natural Gas EF",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur
            isBlurred={calculateIsBlurred(params.row.id)}
            sx={{ maxWidth: "100%" }}
          >
            <ComplianceEmissionFactorName
              emissionFactorType="natural_gas"
              regulations={params.row.regulations}
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Natural Gas EF"
            field="natural_gas_source"
            headerName="Natural Gas EF"
          />
        ),
        sortable: false,
        width: 132,
      },
      {
        ...baseNumberColumnDef,
        field: "natural_gas_ef",
        headerName: "Natural Gas EF Value",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <ComplianceEmissionFactorValue
              emissionFactorType="natural_gas"
              regulations={params.row.regulations}
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Natural Gas EF Value"
            field="natural_gas_ef"
            headerName="Natural Gas EF Value"
            unit="kg CO2e / kBtu" // Purposely not translated per locale
          />
        ),
        sortable: false,
        width: 172,
      },
      {
        ...commonResultsColumns.buildingArea,
      },
      {
        ...commonResultsColumns.planning,
      },
    ]
  }, [
    commonResultsColumns.rowNumber,
    commonResultsColumns.siteName,
    commonResultsColumns.combinedVolume,
    commonResultsColumns.buildingArea,
    commonResultsColumns.planning,
    currencySymbol,
    formatWholeNumber,
    i18n.language,
    formatDecimalToTenths,
  ])

  return columns
}
