import React, { useCallback } from "react"

import type { Moment } from "moment"

import { Box, Typography } from "@mui/material"

import type { IDateFormat } from "../../models/date/date"
import type { SitePremiseFinancialSummary } from "../../models/sitePremiseFinancialSummary"
import { UnitName } from "../../models/unit"
import { calculateTotalCost, calculateTotalVolume } from "../../utils"
import { TIME_RANGES } from "../../utils/constants"
import { getDateFormatFromTimeRange } from "../../utils/date"
import { pxToRem } from "../../utils/unit"
import { CurrencyWithPlaceholder } from "../currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { DateDisplay } from "../date/dateDisplay/dateDisplay"
import { DateRangeDisplay } from "../date/dateRangeDisplay/dateRangeDisplay"
import { DecimalWithPlaceholder } from "../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { If } from "../if/if"
import { ProvideChildrenUnlessLoading } from "../if/loading/provideChildrenUnlessLoading"
import { Panel } from "../panel"
import { BundledSiteFinancialSummaryCard } from "./bundledSiteFinancialSummaryCard"

const calculateTotal = (
  premiseFinancialSummaryResults: SitePremiseFinancialSummary[] | undefined,
  subReducer: (props: SitePremiseFinancialSummary) => number
): number | undefined =>
  premiseFinancialSummaryResults?.reduce(
    (sum, premiseFinancialSummary: SitePremiseFinancialSummary) =>
      sum + subReducer(premiseFinancialSummary),
    0
  )

export interface IBundledSiteFinancialSummaryPanel {
  isParentLoading: boolean
  searchEndDate: Moment
  searchStartDate: Moment
  siteMetersCount: number
  summaryContainerPremises: SitePremiseFinancialSummary[] | undefined
  timeWindow: {
    endDate
    startDate
    timeRange: TIME_RANGES
  }
}

export const BundledSiteFinancialSummaryPanel = ({
  timeWindow,
  searchStartDate,
  searchEndDate,
  siteMetersCount,
  summaryContainerPremises,
  isParentLoading,
}: IBundledSiteFinancialSummaryPanel) => {
  const dateFormat: IDateFormat = getDateFormatFromTimeRange(
    timeWindow.timeRange
  )

  const regularParagraphClassName =
    "summary-measure paragraph--small paragraph--small__regular"
  const summaryValueClassName = "summary-measure-value"

  const ComponentForPremise = useCallback(
    (premiseFinancialSummary: SitePremiseFinancialSummary, index: number) => (
      <BundledSiteFinancialSummaryCard
        key={index}
        isExpanded={false}
        sitePremiseFinancialSummary={premiseFinancialSummary}
      />
    ),
    []
  )

  return (
    <Panel
      buttons={null}
      className="financial-summary-container"
      midSubtitle={null}
      midTitle={null}
      title={null}
    >
      <Typography sx={{ mb: 2 }} variant="h2">
        Billing
      </Typography>
      <ProvideChildrenUnlessLoading loading={isParentLoading}>
        <div className="sub-container" style={{ display: "block" }}>
          <div
            className="left-containers"
            style={{ width: "100%", padding: 0 }}
          >
            <div
              className="summary"
              style={{ height: "auto", margin: `0 0 ${pxToRem(16)} 0` }}
            >
              <Box className="summary-panel" sx={{ borderRadius: 2 }}>
                <Typography
                  className="summary-date paragraph--small paragraph--small__regular"
                  component="div"
                  sx={{
                    padding: pxToRem(20),
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  }}
                >
                  <If
                    condition={
                      timeWindow.timeRange === TIME_RANGES.DAY ||
                      timeWindow.timeRange === TIME_RANGES.YEAR
                    }
                  >
                    <DateDisplay format={dateFormat} value={searchStartDate} />
                  </If>
                  <If
                    condition={
                      timeWindow.timeRange === TIME_RANGES.MONTH ||
                      timeWindow.timeRange === TIME_RANGES.RANGE
                    }
                  >
                    <DateRangeDisplay
                      end={searchEndDate}
                      format={dateFormat}
                      start={searchStartDate}
                    />
                  </If>
                </Typography>
                <div
                  className="summary-measure-container"
                  style={{ padding: 0, width: "100%" }}
                >
                  <p
                    className={regularParagraphClassName}
                    id="meters"
                    style={{
                      padding: pxToRem(20),
                      width: "auto",
                      margin: `0 ${pxToRem(24)} 0 0`,
                    }}
                  >
                    Meters on Property:&nbsp;
                    <span className={summaryValueClassName}>
                      {siteMetersCount}
                    </span>
                  </p>
                  <p
                    className={regularParagraphClassName}
                    id="premises"
                    style={{
                      padding: pxToRem(20),
                      width: "auto",
                      margin: `0 ${pxToRem(24)} 0 0`,
                    }}
                  >
                    Premises on Property:&nbsp;
                    <span className={summaryValueClassName}>
                      {summaryContainerPremises?.length}
                    </span>
                  </p>
                  <p
                    className={regularParagraphClassName}
                    id="total-usage"
                    style={{
                      padding: pxToRem(20),
                      width: "auto",
                      margin: `0 ${pxToRem(24)} 0 0`,
                    }}
                  >
                    Total Usage:&nbsp;
                    <span className={summaryValueClassName}>
                      <DecimalWithPlaceholder
                        format={{ precision: 2 }}
                        unit={UnitName.KilowattHour}
                        value={calculateTotal(
                          summaryContainerPremises,
                          calculateTotalVolume
                        )}
                      />
                    </span>
                  </p>
                  <p
                    className={regularParagraphClassName}
                    id="total-cost"
                    style={{
                      padding: pxToRem(20),
                      width: "auto",
                      margin: `0 ${pxToRem(24)} 0 0`,
                    }}
                  >
                    Total Cost:&nbsp;
                    <span className={summaryValueClassName}>
                      <CurrencyWithPlaceholder
                        value={calculateTotal(
                          summaryContainerPremises,
                          calculateTotalCost
                        )}
                      />
                    </span>
                  </p>
                </div>
              </Box>
            </div>
            {summaryContainerPremises?.map(ComponentForPremise)}
          </div>
        </div>
      </ProvideChildrenUnlessLoading>
    </Panel>
  )
}
