import type { ReactNode } from "react"
import React from "react"

import {
  DEFAULT_DECIMAL_PLACEHOLDER,
  isDecimalEmpty,
} from "../../../utils/decimal"
import { Decimal } from "../decimal/decimal"

export interface IDecimalPlaceholderProps {
  children?: ReactNode
  placeholder?: string
  replaceZeroWithPlaceholder?: boolean
  value: number | string | null | undefined
}

/**
 * A component that conditionally displays its children or a placeholder based on a numeric input
 *
 * @param props {IDecimalPlaceholderProps} - The component properties
 * @param props.children {IDecimalPlaceholderProps["children"]} - The child nodes to render
 * @param props.placeholder {string} - The placeholder to render instead of the decimal
 * @param props.replaceZeroWithPlaceholder {boolean} - Whether to consider 0 as an empty value
 * @param props.value {number | string | null | undefined} - The numeric value to use to determine what to display
 * @example
 * return (
 *   <DecimalPlaceholder value={waterUsage}>
 *     <Decimal value={waterUsage} />
 *   </DecimalPlaceholder>
 * )
 */
export const DecimalPlaceholder = ({
  children,
  replaceZeroWithPlaceholder,
  placeholder = DEFAULT_DECIMAL_PLACEHOLDER,
  value,
}: IDecimalPlaceholderProps) => {
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  React.Children.forEach(children, (child, _index) => {
    if (React.isValidElement(child) && child.type !== Decimal) {
      throw new Error(
        `${DecimalPlaceholder.name} children must be of type ${Decimal.name}`
      )
    }
  })

  if (isDecimalEmpty(value, { includeZero: replaceZeroWithPlaceholder })) {
    return <>{placeholder}</>
  }

  return <>{children}</>
}
