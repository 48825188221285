import z from "zod"

export const schema = z.object({
  yearBuilt: z.string().trim().min(1, { message: "Year built is required" }),
  sqFt: z.string().trim().min(1, { message: "Square footage is required" }),
  numberOfFloors: z
    .string()
    .trim()
    .min(1, { message: "Number of floors is required" }),
  buildingType: z
    .string()
    .trim()
    .min(1, { message: "Building type is required" }),
  locationId: z.string().trim().optional(),
  budget: z.string().trim().optional(),
  reduction_target: z
    .string()
    .optional()
    .refine((target) => Number(target) <= 100, {
      message: "Reduction target cannot exceed 100%",
    }),
  excluded_interventions: z.string().array().optional(),
  physical_details: z.any().array().optional(),
  address: z
    .object({
      address_line_1: z
        .string()
        .trim()
        .min(1, { message: "Address line 1 is required" }),
      address_line_2: z.string().trim().optional(),
      city: z.string().trim().min(1, { message: "City is required" }),
      state: z.string().trim().optional(),
      country: z.string().trim().min(1, { message: "Country is required" }),
      postal_code: z
        .string()
        .trim()
        .min(1, { message: "Zip code is required" }),
    })
    .refine(
      (address) =>
        address.country &&
        ["US", "CA"].includes(address.country) &&
        address.state,
      {
        message: "State is required",
        path: ["state"],
      }
    ),
})

export type Schema = z.infer<typeof schema>
