/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useMemo } from "react"

import type { SelectProps } from "@mui/material"
import { MenuItem as MenuItemMUI, Select as SelectMUI } from "@mui/material"

import { FieldWrapper } from "../../../../../components/form/fieldWrapper"
import type { SelectOptionProp } from "../../../../../components/form/utils/selectOptions"
import { parseSelectOption } from "../../../../../components/form/utils/selectOptions"

interface TamSelectFieldProps extends SelectProps {
  dataE2E?: string
  error?: boolean
  errorMessage?: string | null
  id?: string
  label: string
  name: string
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: any) => void
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void
  options: SelectOptionProp[] | readonly SelectOptionProp[]
  readOnly?: boolean
  required?: boolean
  value?: string
}

export const TamSelectField = forwardRef<HTMLDivElement, TamSelectFieldProps>(
  (
    {
      label,
      name,
      readOnly,
      dataE2E,
      disabled,
      options,
      error,
      errorMessage,
      required,
      onBlur,
      onChange,
      id,
      value,
      ...rest
    },
    ref
  ) => {
    const parsedOptions = useMemo(
      () => options.map(parseSelectOption),
      [options]
    )

    return (
      <FieldWrapper
        errorMessage={error ? errorMessage : null}
        label={label}
        name={name}
        required={required}
      >
        <SelectMUI
          {...rest}
          ref={ref}
          data-e2e={dataE2E}
          disabled={disabled}
          error={error}
          fullWidth
          id={id ?? name}
          margin="none"
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          size="small"
          value={value}
          variant="outlined"
        >
          {parsedOptions.map((option) => (
            <MenuItemMUI key={option.value} value={option.value}>
              {option.label}
            </MenuItemMUI>
          ))}
        </SelectMUI>
      </FieldWrapper>
    )
  }
)
