import React from "react"
import type { FC } from "react"

import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded"
import { LinearProgress, Stack, Typography } from "@mui/material"
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro"
import type { DataGridProProps, GridSortModel } from "@mui/x-data-grid-pro"

const EmptyOverlay = () => (
  <GridOverlay>
    <Stack alignItems="center" color="text.disabled" gap={2}>
      <TableRowsRoundedIcon fontSize="large" />
      <Typography color="text.disabled" variant="h3">
        No data available
      </Typography>
    </Stack>
  </GridOverlay>
)

const defaultColumnHeaderHeight = 62

interface NzDataGridProps
  extends Pick<
    DataGridProProps,
    | "apiRef"
    | "columnHeaderHeight"
    | "columns"
    | "columnVisibilityModel"
    | "disableColumnFilter"
    | "filterMode"
    | "filterModel"
    | "hideFooter"
    | "initialState"
    | "loading"
    | "onColumnOrderChange"
    | "onColumnVisibilityModelChange"
    | "onColumnWidthChange"
    | "onFilterModelChange"
    | "onPinnedColumnsChange"
    | "onProcessRowUpdateError"
    | "onRowEditStop"
    | "onRowsScrollEnd"
    | "onSortModelChange"
    | "pinnedColumns"
    | "processRowUpdate"
    | "rowModesModel"
    | "rows"
    | "slotProps"
    | "slots"
    | "sortModel"
    | "sortingMode"
  > {
  isLoading: boolean
}

export const NzDataGrid: FC<NzDataGridProps> = ({
  apiRef,
  columnHeaderHeight,
  columns,
  columnVisibilityModel,
  disableColumnFilter,
  filterMode,
  hideFooter,
  isLoading,
  initialState,
  onColumnOrderChange,
  onColumnVisibilityModelChange,
  onColumnWidthChange,
  onFilterModelChange,
  onPinnedColumnsChange,
  onProcessRowUpdateError,
  onRowEditStop,
  onRowsScrollEnd,
  onSortModelChange,
  pinnedColumns,
  processRowUpdate,
  rowModesModel,
  rows,
  slotProps,
  slots,
  sortModel,
  sortingMode,
}) => {
  const optionalProps: { sortModel?: GridSortModel } = {}
  if (
    sortModel &&
    sortModel.length > 0 &&
    sortModel[0].field &&
    sortModel[0].sort
  ) {
    optionalProps.sortModel = sortModel
  }
  return (
    <DataGridPro
      apiRef={apiRef}
      columnHeaderHeight={columnHeaderHeight || defaultColumnHeaderHeight}
      columns={columns}
      columnVisibilityModel={columnVisibilityModel}
      disableColumnFilter={disableColumnFilter}
      disableRowSelectionOnClick
      editMode="row"
      hideFooter={hideFooter === false ? hideFooter : true}
      filterMode={filterMode}
      initialState={initialState}
      loading={isLoading}
      onColumnOrderChange={onColumnOrderChange}
      onFilterModelChange={onFilterModelChange}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      onColumnWidthChange={onColumnWidthChange}
      onPinnedColumnsChange={onPinnedColumnsChange}
      onProcessRowUpdateError={onProcessRowUpdateError}
      onRowEditStop={onRowEditStop}
      onRowsScrollEnd={onRowsScrollEnd}
      onSortModelChange={onSortModelChange}
      pinnedColumns={pinnedColumns}
      processRowUpdate={processRowUpdate}
      rowHeight={48}
      rowModesModel={rowModesModel}
      rows={rows}
      scrollEndThreshold={1}
      slotProps={{
        ...slotProps,
        baseButton: {
          color: "neutral",
        },
        baseTextField: {
          variant: "outlined",
        },
      }}
      slots={{
        ...slots,
        loadingOverlay: LinearProgress,
        noRowsOverlay: EmptyOverlay,
      }}
      sortingMode={sortingMode}
      sortingOrder={["desc", "asc"]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...optionalProps}
    />
  )
}

export default NzDataGrid
