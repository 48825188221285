import React, { useCallback } from "react"

import { Grid, Typography } from "@mui/material"

import {
  CancelButton,
  CreateEntityButton,
} from "../../../../../../../components/form"
import { TYPOGRAPHY_VARIANT } from "../../../../../../../models/typography"
import { useCreateBillingGroup } from "../../../../../../../services"
import type { OrgViewDialogCreateContentProps } from "../../../../../models/onboarding/onboarding"
import { TamForm, useTamForm } from "../../../../tamForm"
import { TamFormTextField } from "../../../../tamForm/formFields"
import type { BillingGroup } from "../../../../tamForm/schemas/billingGroup"
import { schema } from "../../../../tamForm/schemas/billingGroup"

const OrgViewDialogBillingGroupCreateContent = ({
  objectName,
  parentObjectIds,
  onClose,
  onSuccess,
}: OrgViewDialogCreateContentProps): React.JSX.Element => {
  const { createOneBillingGroup } = useCreateBillingGroup({ onSuccess })

  const handleFormSubmit = useCallback(
    (newAttributes) => {
      // TODO: Refactor logic in order to allow type Partial<BillingGroup>
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const attributes = {
        ...newAttributes,
        siteId: parentObjectIds.siteId,
      }
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      createOneBillingGroup(null, attributes)
      onClose()
    },
    [parentObjectIds.siteId, createOneBillingGroup, onClose]
  )

  const form = useTamForm({ schema })

  return (
    <TamForm
      data-testid="billingGroupCreateForm"
      form={form}
      name="billingGroup"
      onSubmit={handleFormSubmit}
    >
      {/* This styling is needed since the actions will be part of the content */}
      <Grid container sx={{ mb: 3 }}>
        {/* This styling is needed to provide space between the form and required text */}
        <Grid container item spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <TamFormTextField<BillingGroup>
              dataE2E="billing-group-name-input"
              label="Billing Group Name"
              name="groupName"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TamFormTextField<BillingGroup>
              dataE2E="billing-group-alias-input"
              label="Billing Group Alias"
              name="alias"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={TYPOGRAPHY_VARIANT.body2}>*required</Typography>
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-end" spacing={1}>
        <Grid item>
          <CancelButton onCancel={onClose} />
        </Grid>
        <Grid item>
          <CreateEntityButton entityName={objectName} />
        </Grid>
      </Grid>
    </TamForm>
  )
}

export default OrgViewDialogBillingGroupCreateContent
