import React from "react"
import type { FC } from "react"

import { ContactDialog } from "@/components/contact-dialog"
import { useDisclosure } from "@/nzds"

import { Box, Button } from "@mui/material"

export const ResultsGridFooterCta: FC = () => {
  const {
    isOpen: contactDialogIsOpen,
    onClose: contactDialogOnClose,
    onOpen: contactDialogOnOpen,
  } = useDisclosure()

  return (
    <Box
      py={2}
      sx={(theme) => ({
        borderTop: `1px solid ${theme.palette.grey[100]}`,
        textAlign: "center",
      })}
    >
      <Button
        color="secondary"
        onClick={contactDialogOnOpen}
        size="small"
        variant="contained"
      >
        Contact Us to Access All Sites
      </Button>
      <ContactDialog
        open={contactDialogIsOpen}
        onClose={contactDialogOnClose}
        type="decarbonize"
      />
    </Box>
  )
}
