// @ts-strict-ignore
import type { Ref } from "react"
import React, { forwardRef } from "react"

import { Autocomplete as MuiAutocomplete } from "@mui/material"

import type { WithForwardRefType } from "./autocomplete.types"

export const Autocomplete: WithForwardRefType = forwardRef(
  (props, ref: Ref<unknown>) => <MuiAutocomplete ref={ref} {...props} />
)
