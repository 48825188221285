import type Currency from "../../../../models/currency"
import type { CurrencyCode } from "../../../../models/currencyCode"
import { Translation } from "../../../../models/i18n"
import type { IUserSettings } from "../../contexts/userSettings.provider"
import type { IUserSettingsData } from "../../contexts/userSettings.store"

export const currenciesToCurrencyCodes = (
  currencies: Currency[]
): CurrencyCode[] =>
  currencies.map((currency) => currency.alphaCode as CurrencyCode)

export const isSupportedCurrency = (currencyCode: CurrencyCode): boolean => {
  const supportedCurrencies = Object.keys(
    Translation.Common.Currency
  ) as CurrencyCode[]

  return supportedCurrencies.includes(currencyCode)
}

/**
 * Returns all data-only user settings properties from a user settings object
 *
 * @param userSettings - The userSettings object
 * @returns - The userSettingsData object
 */
export const getDataFromUserSettings = (
  userSettings: IUserSettings
): IUserSettingsData => ({
  currency: userSettings.currency,
  hasLoadedSupportedCurrencies: userSettings.hasLoadedSupportedCurrencies,
  isLoadingSupportedCurrencies: userSettings.isLoadingSupportedCurrencies,
  supportedCurrencies: userSettings.supportedCurrencies,
})
