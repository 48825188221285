import type { UseMutationOptions } from "react-query"
import { useMutation, useQueryClient } from "react-query"

import { ApiQueryName } from "@/models/api"
import type { Paths } from "@/services/types"

import { createGoal } from "./create-goal"
import type { CreateGoalBody } from "./create-goal"

interface UseCreateGoalOptions {
  mutationOptions?: Omit<
    UseMutationOptions<
      Paths["/api/v1/recommendations/goals"]["post"]["responses"]["200"]["content"]["application/json"],
      Paths["/api/v1/recommendations/goals"]["post"]["responses"]["422"]["content"]["application/json"],
      CreateGoalBody
    >,
    "mutationFn"
  >
}

/**
 * Creates a goal
 *
 * @example
 * const { mutateAsync, data, isLoading, error, isError } =
 * useCreateGoal({
 *   queryOptions // react query options
 * });
 *
 * await mutateAsync({ subject_type: "Site", subject_id: 123, ....})
 */
export const useCreateGoal = (options?: UseCreateGoalOptions) => {
  const mutationOptions = options?.mutationOptions
  const queryClient = useQueryClient()

  const mutation = useMutation(createGoal, {
    ...(mutationOptions ?? {}),
    onSuccess: (data, variables, context) => {
      if (mutationOptions?.onSuccess) {
        void mutationOptions.onSuccess(data, variables, context)
      }

      void queryClient.invalidateQueries([
        ApiQueryName.RecommendationsGoals,
        "getMany",
      ])
    },
  })
  return mutation
}
