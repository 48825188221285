import {
  cerulean25,
  cerulean50,
  cerulean100,
  cerulean200,
  cerulean300,
  cerulean400,
  cerulean500,
  cerulean600,
  cerulean700,
  cerulean800,
  green25,
  green50,
  green100,
  green200,
  green300,
  green400,
  green500,
  green600,
  green700,
  green800,
  mustard25,
  mustard50,
  mustard100,
  mustard200,
  mustard300,
  mustard400,
  mustard500,
  mustard600,
  mustard700,
  mustard800,
  peach25,
  peach50,
  peach100,
  peach200,
  peach300,
  peach400,
  peach500,
  peach600,
  peach700,
  peach800,
  primary25,
  primary50,
  primary100,
  primary200,
  primary300,
  primary400,
  primary500,
  primary600,
  primary700,
  primary800,
  purple25,
  purple50,
  purple100,
  purple200,
  purple300,
  purple400,
  purple500,
  purple600,
  purple700,
  purple800,
  red25,
  red50,
  red100,
  red200,
  red300,
  red400,
  red500,
  red600,
  red700,
  red800,
} from "../utils/colors"
import type { Spectrum, SpectrumColor } from "./styles.types"

export const spectrum: Record<keyof Spectrum, SpectrumColor> = {
  blue: {
    25: primary25.hex(),
    50: primary50.hex(),
    100: primary100.hex(),
    200: primary200.hex(),
    300: primary300.hex(),
    400: primary400.hex(),
    500: primary500.hex(),
    600: primary600.hex(),
    700: primary700.hex(),
    800: primary800.hex(),
  },
  cerulean: {
    25: cerulean25.hex(),
    50: cerulean50.hex(),
    100: cerulean100.hex(),
    200: cerulean200.hex(),
    300: cerulean300.hex(),
    400: cerulean400.hex(),
    500: cerulean500.hex(),
    600: cerulean600.hex(),
    700: cerulean700.hex(),
    800: cerulean800.hex(),
  },
  green: {
    25: green25.hex(),
    50: green50.hex(),
    100: green100.hex(),
    200: green200.hex(),
    300: green300.hex(),
    400: green400.hex(),
    500: green500.hex(),
    600: green600.hex(),
    700: green700.hex(),
    800: green800.hex(),
  },
  mustard: {
    25: mustard25.hex(),
    50: mustard50.hex(),
    100: mustard100.hex(),
    200: mustard200.hex(),
    300: mustard300.hex(),
    400: mustard400.hex(),
    500: mustard500.hex(),
    600: mustard600.hex(),
    700: mustard700.hex(),
    800: mustard800.hex(),
  },
  peach: {
    25: peach25.hex(),
    50: peach50.hex(),
    100: peach100.hex(),
    200: peach200.hex(),
    300: peach300.hex(),
    400: peach400.hex(),
    500: peach500.hex(),
    600: peach600.hex(),
    700: peach700.hex(),
    800: peach800.hex(),
  },
  purple: {
    25: purple25.hex(),
    50: purple50.hex(),
    100: purple100.hex(),
    200: purple200.hex(),
    300: purple300.hex(),
    400: purple400.hex(),
    500: purple500.hex(),
    600: purple600.hex(),
    700: purple700.hex(),
    800: purple800.hex(),
  },
  red: {
    25: red25.hex(),
    50: red50.hex(),
    100: red100.hex(),
    200: red200.hex(),
    300: red300.hex(),
    400: red400.hex(),
    500: red500.hex(),
    600: red600.hex(),
    700: red700.hex(),
    800: red800.hex(),
  },
}
