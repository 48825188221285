import React from "react"
import { useTranslation } from "react-i18next"

import { area, curveLinear, line, select } from "d3"
import type { Moment } from "moment"
import moment from "moment"
import max from "ramda/src/max"
import reduce from "ramda/src/reduce"
import type { PersistedSpraypaintRecord } from "spraypaint"

import Price from "../models/price"
import { UnitName } from "../models/unit"
import { dayAheadPrice } from "../utils"
import { getHourEnding } from "../utils/date"
import { transformPricePerWhIntoUnit } from "../utils/formatters"
import { getKeyByValue } from "../utils/object"
import { DateDisplay } from "./date/dateDisplay/dateDisplay"
import { If } from "./if/if"
import { PriceDisplay } from "./priceDisplay"
import { Unit } from "./unit/unit"

const DrawDayAheadCostGraphic = ({ prices }) => {
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (prices.length === 0) {
    return
  }

  const HEIGHT = 50
  const WIDTH = 110
  const BUFFER = 5
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const widthModifier = WIDTH / prices.length

  // migration to strict mode batch disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const maxPrice = reduce(
    max,
    0,
    // Mass lint disable
    // Mass eslint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
    prices.map((price) => price.lmp)
  )
  const heightModifier = HEIGHT / maxPrice

  // migration to strict mode batch disable
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const pricingTrendData = prices.map((price, idx) => [
    idx * widthModifier,
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    price.lmp * heightModifier,
  ])

  const svg = select(".day-ahead-pricing .graphic")
    .attr("height", HEIGHT + BUFFER)
    .attr("width", WIDTH)

  svg.selectAll("*").remove()

  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const pricingTrendLine = line().curve(curveLinear)(pricingTrendData)

  // Draw the line
  svg.append("path").attr("class", "line").attr("d", pricingTrendLine)

  // Draw the circle indicating most recent price
  const startOfThisHour = moment().startOf("hour")
  // migration to strict mode batch disable
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const indexForThisHour = prices.findIndex((price) =>
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    startOfThisHour.isSame(price.hour)
  )
  if (indexForThisHour > -1) {
    // migration to strict mode batch disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const thisHourPricePoint = pricingTrendData[indexForThisHour]

    // Draw the area
    svg
      .append("path")
      .attr("class", "area")
      .attr(
        "d",
        // Mass lint disable
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        area().y0(HEIGHT)(pricingTrendData.slice(0, indexForThisHour + 1))
      )

    svg
      .append("circle")
      .attr("class", "circle")
      // Mass lint disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      .attr("cx", thisHourPricePoint[0])
      // Mass lint disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      .attr("cy", thisHourPricePoint[1])
      .attr("r", "4")
  }
}

export const DayAheadPricing = ({
  prices,
  whDisplayUnit,
}: {
  prices: PersistedSpraypaintRecord<Price>[]
  whDisplayUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const { t } = useTranslation()

  const titleWithDate = (dateTime: Moment) => (
    <div className="title-with-note">
      <h4 className="title-with-note__title">Day Ahead</h4>
      <If condition={dateTime}>
        <p className="title-with-note__note">
          <DateDisplay value={dateTime} />
        </p>
      </If>
    </div>
  )

  React.useEffect(() => {
    DrawDayAheadCostGraphic({ prices })
  }, [prices])

  const price: Price | null = dayAheadPrice(prices)
  const date: Moment | null = price ? moment(price.hour) : null
  const priceDate: Moment | null = date ? date.clone().add(1, "day") : null

  return (
    <div className="day-ahead-pricing">
      {titleWithDate(priceDate)}
      <PriceDisplay
        amount={transformPricePerWhIntoUnit(
          Number.parseFloat((price || new Price({ lmpMajor: "" })).lmpMajor),
          UnitName.MegawattHour,
          whDisplayUnit
        )}
      >
        <span>
          Day Ahead LMP (
          <Unit
            unit={
              UnitName[
                `CurrencyPer${getKeyByValue<string>(
                  UnitName,
                  whDisplayUnit
                )}` as keyof typeof UnitName
              ]
            }
          />
          )
        </span>
        <span className="price-display__label__right">
          HE: {priceDate && getHourEnding(date, t)}
        </span>
      </PriceDisplay>
    </div>
  )
}
