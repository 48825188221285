import { useQuery } from "react-query"

import { ApiQueryName } from "../../models/api"
import { fetchPendo } from "./usePendoUtils"

/**
 * The usePendo service/hook loads and provides pendo
 *
 * @returns Pendo
 * @example
 * const {
 *   isPendoFetched,
 *   pendo,
 * } = usePendo()
 */
export const usePendo = () => {
  return useQuery({
    // Loading pendo (a third-party script) only needs to be done once
    cacheTime: Infinity,
    queryFn: fetchPendo,
    queryKey: [ApiQueryName.Pendo],
    staleTime: Infinity,
  })
}
