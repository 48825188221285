/**
 * Process a request in multiple smaller chunks/pages
 *
 * @template TResponse
 * @param request {function} - A function that returns the request
 * @param chunkSize {number} - The max size of the chunks that the request will be broken down into
 * @param totalSize {number} - The total number of the expected records
 * @returns {Promise<TResponse[]>} - The final combined request
 * @example
 * chunkRequests(getSites, 500, totalSiteCount)
 */

export const chunkRequests = async <TResponse>(
  request: (pageNumber: number, pageSize: number) => Promise<TResponse[]>,
  chunkSize: number,
  totalSize: number
) => {
  const pageSize: number = chunkSize
  const pageCount: number = Math.ceil(totalSize / pageSize)
  const pageNumbers: number[] = [...Array(pageCount + 1).keys()]
  // We want the first page to be 1, not 0
  pageNumbers.shift()

  const requests: Promise<TResponse[]>[] = pageNumbers.map((pageNumber) =>
    request(pageNumber, pageSize)
  )

  const responses: TResponse[][] = await Promise.all(requests)

  return responses.reduce(
    (combinedResponse: TResponse[], currentResponse: TResponse[]) =>
      combinedResponse.concat(currentResponse),
    []
  )
}
