import React from "react"
import { Link } from "react-router-dom"

import knowledgeImageSrc from "@/assets/images/recommendations/knowledge.png"
import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"
import { PageCard } from "@/components/page-card"
import { PageLoading } from "@/components/page-loading/page-loading"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { HStack } from "@/nzds/layout"

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded"
import { Button, IconButton, Stack, Typography } from "@mui/material"

import { PotentialSitesGrid } from "../../components/potential-sites-card/potential-sites-grid"
import { useGetPotentialSites } from "../../services/use-get-potential-sites/use-get-potential-sites"

export const RecommendationsPlanRoute = () => {
  const { organization } = useOrganizationContext()

  const hasOrg = Boolean(organization)

  const { data: potentialSites, isLoading: isPotentialSitesLoading } =
    useGetPotentialSites()

  const isLoading = !hasOrg || isPotentialSitesLoading

  return (
    <PageLoading isLoading={isLoading}>
      <PageHeader title="Start Decarbonization Planning" />
      <PageHeaderActionBar hasTabs={false}>
        <HStack gap={2}>
          <IconButton
            aria-label="Back to planning overview"
            color="neutral"
            component={Link}
            to="../"
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
        </HStack>
      </PageHeaderActionBar>
      <Page>
        {potentialSites && potentialSites.length > 0 ? (
          <PotentialSitesGrid sites={potentialSites} />
        ) : (
          <PageCard>
            <Stack gap={4}>
              <HStack alignItems="stretch" gap={3}>
                <img
                  alt="books"
                  height={150}
                  role="presentation"
                  src={knowledgeImageSrc as string}
                  width={150}
                />
                <Stack data-testid="rec-index-empty-hero" gap={1}>
                  <Typography variant="h1">No sites found</Typography>

                  <Typography variant="body1">
                    We are working on creating recommendations for your sites.
                    Please check back later.
                  </Typography>

                  <Button
                    color="primary"
                    component={Link}
                    sx={{ alignSelf: "flex-start", mt: "auto" }}
                    to="../"
                    variant="outlined"
                  >
                    View planning overview
                  </Button>
                </Stack>
              </HStack>
            </Stack>
          </PageCard>
        )}
      </Page>
    </PageLoading>
  )
}
