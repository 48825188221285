import type { UseMutationResult } from "react-query"

import type { ApiQueryName } from "../models/api"
import type { components, operations, paths } from "./generated/schema"

export type Schemas = components["schemas"]
export type Operations = operations
export type Paths = paths

/**
 * The action types used to categorize the functionality of services.
 */

export const ServiceActions = [
  "getOne",
  "getMany",
  "createOne",
  "updateOne",
  "deleteOne",
] as const

export type ServiceAction = (typeof ServiceActions)[number]

export const ServiceActionsWithData = ["getOne", "getMany"] as const

export const ServiceActionsWithMutation = [
  "createOne",
  "updateOne",
  "deleteOne",
] as const

/**
 * A unique identifier for a CRUD grouping of service hooks.
 * Use only one ServiceAction per ServiceName, except in cases of multiple getMany configurations.
 */
export type ServiceName = ApiQueryName | `${ApiQueryName}` // Accept enum or string literal.

/**
 * The server ID of a particular resource.
 */
export type ServiceEntityKey = string

/**
 * The returned datum value of a service function.
 */
// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ServiceValue = Record<string, any>

/**
 * The returned datum value of a service function.
 */
export type ServiceData<
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
> = undefined | ActionT extends (typeof ServiceActionsWithData)[number]
  ? ActionT extends "getMany"
    ? ValueT[]
    : ValueT
  : undefined

/**
 * A unique identifier for a respective service query.
 */
export type ServiceQueryKey<
  NameT extends ServiceName = ServiceName,
  ActionT extends ServiceAction = ServiceAction,
  IdT extends null | ServiceEntityKey = ServiceEntityKey,
> = [NameT, ActionT, null | IdT, null | string]

/**
 * Used in filtering values from the database.
 */
// TODO: Add Spraypaint-specific wheres, but not using string interpolation.
// e.g., don't use `entity_or`.
// see https://www.graphiti.dev/js/reads/filtering
export type ServiceWhereOptions<ValueT extends ServiceValue> = Partial<ValueT>

/**
 * Used in sorting values either on the client or on the server / database.
 */
export interface ServiceSortOptions<ValueT extends ServiceValue> {
  key: keyof ValueT
  order: "desc" | "asc"
}

/**
 * Standard options shared by all services. Can be extended.
 */
// TODO: Rename to ServiceBaseOptions
export type ServiceOptions<ActionT extends ServiceAction> = ActionT extends
  | "getOne"
  | "getMany"
  ? {
      disabled?: boolean
    }
  : object

export const mutationStatusNames = [
  "idle",
  "loading",
  "success",
  "error",
] as const

export const queryAttributePropNames = [
  "updatedAt",
  "error",
  "errorUpdatedAt",
  "errorUpdateCount",
  "failureCount",
  "status",
] as const

export const queryFunctionPropNames = ["remove", "refetch"] as const

export const queryStatusPropNames = [
  "error",
  "fetched",
  "fetchedAfterMount",
  "fetching",
  "idle",
  "loading",
  "loadingError",
  "placeholderData",
  "previousData",
  "refetchError",
  "refetching",
  "stale",
  "success",
] as const

export type MutationInputStatus = Pick<
  UseMutationResult,
  `is${Capitalize<(typeof mutationStatusNames)[number]>}`
>

export interface QueryInputData<
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
> {
  data: ServiceData<ActionT, ValueT>
}

export interface InputMutation<
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
> {
  mutate: QueryMutation<ActionT, ValueT>
}

export type MutationOutputStatus<
  NameT extends ServiceName,
  ActionT extends ServiceAction,
> = Record<
  `is${Capitalize<ActionT>}${Capitalize<NameT>}${Capitalize<
    (typeof mutationStatusNames)[number]
  >}`,
  boolean
>

export type RenamedQueryStatusProps<NameT extends ServiceName> = Record<
  `is${Capitalize<NameT>}${Capitalize<(typeof queryStatusPropNames)[number]>}`,
  boolean
>

export type RenamedQueryAttributeProps<NameT extends ServiceName> = Record<
  `${NameT}${Capitalize<(typeof queryAttributePropNames)[number]>}`,
  unknown
>

export type RenamedQueryFunctionProps<NameT extends ServiceName> = Record<
  `${(typeof queryFunctionPropNames)[number]}${Capitalize<NameT>}`,
  unknown
>

export type RenamedQueryProps<NameT extends ServiceName> =
  RenamedQueryStatusProps<NameT> &
    RenamedQueryAttributeProps<NameT> &
    RenamedQueryFunctionProps<NameT>

export type QueryData<
  NameT extends ServiceName,
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
> = Record<NameT, ServiceData<ActionT, ValueT>>

export type OutputMutation<
  NameT extends ServiceName,
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
  ServiceMutationT extends ServiceMutation<ActionT, ValueT>,
> = Record<`${ActionT}${Capitalize<NameT>}`, ServiceMutationT>

export type ServiceMutationAttributes<
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
> = ActionT extends "createOne"
  ? Omit<ValueT, "id">
  : ActionT extends "updateOne"
    ? Partial<ValueT>
    : undefined

/**
 * The argument object passed to react-query useMutation.
 * Different from the parsed MutationHandle mutation.
 */
export type QueryMutation<
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
> = ActionT extends "updateOne"
  ? (mutationArg: {
      newAttributes: ServiceMutationAttributes<ActionT, ValueT>
      serviceEntityKey: ServiceEntityKey
    }) => void
  : ActionT extends "createOne"
    ? (mutationArg: {
        newAttributes: ServiceMutationAttributes<ActionT, ValueT>
      }) => void
    : ActionT extends "deleteOne"
      ? (mutationArg: {
          newAttributes?: undefined
          serviceEntityKey: ServiceEntityKey
        }) => void
      : undefined

/**
 * Used by services that mutate a value.
 */
export type ServiceMutation<
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
> = ActionT extends "createOne" | "updateOne"
  ? (
      serviceEntityKey: ServiceEntityKey,
      newAttributes: ServiceMutationAttributes<ActionT, ValueT>
    ) => void
  : ActionT extends "deleteOne"
    ? (serviceEntityKey: ServiceEntityKey) => void
    : undefined

/**
 * Extract the value type from a service's return value.
 */
export type InferredQueryServiceValue<
  ServiceFn extends (...args: unknown[]) => {
    [ServiceOutput in keyof ReturnType<ServiceFn>]: unknown
  },
  ServiceOutput extends keyof ReturnType<ServiceFn>,
> = ReturnType<ServiceFn>[ServiceOutput]

/**
 * Used to provide options towards the deletion of an entity.
 */
export interface UseDeleteEntityOptions {
  onError?: () => void
  onSuccess: () => void
}

/**
 * Used to provide options towards the creation of an entity.
 */
export interface UseCreateEntityOptions {
  onError?: (errorMessage: string) => void
  onSuccess?: (newEntityId: string) => void
}
