import type { Schemas } from "@/services/types"

export const buildErrorMessage = (
  uploadError: Schemas["EmissionProfileUploadError"][] | null
): string => {
  if (!uploadError) {
    return ""
  }

  if (Array.isArray(uploadError)) {
    return "The following rows contain errors. Please resolve and upload again:"
  }

  return "An error occurred. Please try again."
}
