import React, { useCallback } from "react"

import { Grid, Typography } from "@mui/material"

import {
  CancelButton,
  CreateEntityButton,
} from "../../../../../../../components/form"
import { TYPOGRAPHY_VARIANT } from "../../../../../../../models/typography"
import { useCreateDepartment } from "../../../../../../../services"
import type { OrgViewDialogCreateContentProps } from "../../../../../models/onboarding/onboarding"
import { TamForm, useTamForm } from "../../../../tamForm"
import { TamFormTextField } from "../../../../tamForm/formFields"
import type { Department } from "../../../../tamForm/schemas/department"
import { schema } from "../../../../tamForm/schemas/department"

const OrgViewDialogDepartmentCreateContent = ({
  objectName,
  parentObjectIds,
  onClose,
  onSuccess,
}: OrgViewDialogCreateContentProps): JSX.Element => {
  const { createOneDepartment } = useCreateDepartment({ onSuccess })

  const handleFormSubmit = useCallback(
    (newAttributes) => {
      // TODO: Refactor logic in order to allow type Partial<Department>
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const attributes = {
        ...newAttributes,
        organizationId: parentObjectIds.organizationId,
      }
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      createOneDepartment(null, attributes)
      onClose()
    },
    [createOneDepartment, onClose, parentObjectIds.organizationId]
  )

  const form = useTamForm({ schema })

  return (
    <TamForm
      data-testid="departmentCreateForm"
      form={form}
      name="department"
      onSubmit={handleFormSubmit}
    >
      {/* This styling is needed since the actions will be part of the content */}
      <Grid container sx={{ mb: 3 }}>
        {/* This styling is needed to provide space between the form and required text */}
        <Grid container item spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <TamFormTextField<Department>
              dataE2E="department-name-input"
              label="Department Name"
              name="name"
              required
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={TYPOGRAPHY_VARIANT.body2}>*required</Typography>
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-end" spacing={1}>
        <Grid item>
          <CancelButton onCancel={onClose} />
        </Grid>
        <Grid item>
          <CreateEntityButton entityName={objectName} />
        </Grid>
      </Grid>
    </TamForm>
  )
}

export default OrgViewDialogDepartmentCreateContent
