/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import type { FieldValues, Path } from "react-hook-form"
import { useController } from "react-hook-form"

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { z } from "zod"

import type { SelectProps } from "@mui/material"

import type { SelectOptionProp } from "../../../../../components/form/utils/selectOptions"
import { TamSelectField } from "../baseInputs/tamSelectField"

interface TamFormSelectFieldProps<T> extends SelectProps {
  dataE2E?: string
  label: string
  name: Path<T>
  options: SelectOptionProp[] | readonly SelectOptionProp[]
  readOnly?: boolean
}

export const TamFormSelectField = <T extends FieldValues>(
  props: TamFormSelectFieldProps<T>
) => {
  const { label, name, options, dataE2E, readOnly, required, disabled } = props

  const {
    field: { ref, onBlur, onChange, value },
    fieldState: { error },
  } = useController({
    name,
  })

  return (
    <TamSelectField
      ref={ref}
      dataE2E={dataE2E}
      disabled={disabled}
      error={!!error}
      errorMessage={error?.message}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      readOnly={readOnly}
      required={required}
      value={value ?? ""}
    />
  )
}
