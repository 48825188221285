import React from "react"

import classNames from "classnames"

import { IconButton } from "@mui/material"

import checkFilledImage from "../../icons/check-filled.svg"
import closeImage from "../../icons/close.svg"
import warningImage from "../../icons/warning.svg"

import styles from "./alert.module.scss"

interface AlertProps {
  isWarning: boolean
  message: string
  onClose: () => void
}

export const Alert = ({ isWarning, message, onClose }: AlertProps) => (
  <div
    className={classNames(styles.root, {
      [styles.rootWarning]: isWarning,
    })}
  >
    {/* migration to strict mode batch disable */}
    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
    <img alt="" src={isWarning ? warningImage : checkFilledImage} />
    <span>{message}</span>
    <IconButton
      aria-label="close"
      color="neutral"
      onClick={() => {
        onClose()
      }}
      type="button"
    >
      {/* migration to strict mode batch disable */}
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <img alt="close window" src={closeImage} />
    </IconButton>
  </div>
)
