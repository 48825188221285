import { useMemo } from "react"

import { DEFAULT_FORM_FIELD_EMPTY_PLACEHOLDER } from "../../utils/constants"
import type { SelectOptionParsed } from "./utils/selectOptions"

export const useOptions = <V extends Record<string, unknown>>(
  values: V[],
  pathArg: ((value: V) => SelectOptionParsed) | [keyof V, keyof V],
  defaultValue?: null | "" | SelectOptionParsed
): SelectOptionParsed[] =>
  useMemo(() => {
    if (!values) {
      return []
    }
    return (
      (defaultValue === undefined
        ? []
        : [
            defaultValue === null || defaultValue === ""
              ? {
                  label: DEFAULT_FORM_FIELD_EMPTY_PLACEHOLDER,
                  value: defaultValue,
                }
              : defaultValue,
          ]) as SelectOptionParsed[]
    ).concat(
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      values.map((value) => {
        if (typeof pathArg === "function") {
          return pathArg(value)
        }
        return {
          label: value[pathArg[0]],
          value: value[pathArg[1] ?? pathArg[0]],
        }
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any
    )
  }, [defaultValue, pathArg, values])
