import React from "react"

import { useTheme } from "@mui/material"

export const CurtailmentIcon = ({
  xPadding,
  margin,
  x,
  barXPos,
  barYPos,
  yPadding,
}) => {
  const theme = useTheme()
  const [showTooltip, setShowTooltip] = React.useState(false)

  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const barWidth = x.bandwidth() - (xPadding + margin)
  const xOffset = xPadding / 2 + margin / 2 + barWidth / 2
  const xPos = barXPos + xOffset - 2
  const yPos = barYPos + yPadding - 10

  return (
    <>
      <g className="curtailment-icon" style={{ cursor: "default" }}>
        <circle
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          cx={barXPos + xOffset}
          cy={barYPos + yPadding - 15}
          fill={theme.palette.common.black}
          onMouseEnter={() => {
            setShowTooltip(true)
          }}
          onMouseOut={() => {
            setShowTooltip(false)
          }}
          r="9"
        />
        <text
          fill={theme.palette.common.white}
          fontFamily="Helvetica"
          fontSize="12"
          fontWeight="normal"
        >
          <tspan x={xPos} y={yPos}>
            !
          </tspan>
        </text>
      </g>
      {showTooltip && (
        <g className="curtailment-tooltip">
          <rect
            fill={theme.palette.common.black}
            height="22"
            rx="3"
            width="135"
            x={xPos + 20}
            y={yPos - 22}
          />
          <text
            fill={theme.palette.common.white}
            fontFamily="Helvetica"
            fontSize="12"
            fontWeight="normal"
            x={xPos + 25}
            y={yPos - 6}
          >
            <tspan>Unresolved curtailment</tspan>
          </text>
        </g>
      )}
    </>
  )
}
