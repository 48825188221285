import type { Key } from "react"
import React from "react"

import { Link } from "@mui/material"

import { HStack } from "../../../../../../nzds"
import { getDataE2EAttribute } from "../../../../../../utils/e2e"
import type { IOrgViewDisplayCount } from "../../../../models/onboarding/onboarding"

interface IOrgViewLinkSectionProps {
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  counts: IOrgViewDisplayCount<any>[]
  id: string
}

const OrgViewDetailLinkSection = ({
  counts,
  id,
}: IOrgViewLinkSectionProps): JSX.Element => (
  <HStack spacing={3}>
    {counts.map((count) => (
      <Link
        key={count?.key as Key}
        data-e2e={`${getDataE2EAttribute(count?.name)}-link`}
        href={`${count.href}${id}`}
      >
        {count?.name} {Number.isFinite(count?.value) && `(${count?.value})`}
      </Link>
    ))}
  </HStack>
)

export default OrgViewDetailLinkSection
