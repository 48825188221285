import { useMutation } from "react-query"
import type { UseMutationOptions } from "react-query"

import type { AxiosError, AxiosResponse } from "axios"

import type { UserCreate } from "../../models/user"
import { createUser as createUserApi } from "../../utils/api"
import type { SuccessData } from "../../utils/api"

export interface CreateUserError extends SuccessData {
  errors: string[]
}

export const useCreateUser = (
  options: UseMutationOptions<
    AxiosResponse<SuccessData>,
    AxiosError<CreateUserError>,
    UserCreate
  >
) => {
  const useMutationOptions = {
    mutationFn: async (newUser: UserCreate) => createUserApi(newUser),
    ...options,
  }

  const { data, isError, isLoading, isSuccess, mutateAsync } =
    useMutation(useMutationOptions)

  return {
    createUser: mutateAsync,
    createUserData: data,
    createUserIsError: isError,
    createUserIsLoading: isLoading,
    createUserIsSuccess: isSuccess,
  }
}
