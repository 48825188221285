import type { ReactElement } from "react"
import React from "react"
import { useTranslation } from "react-i18next"

import { If } from "../../../../../components/if/if"
import type { IMetric } from "../../../../../models/metric"
import type { IResource } from "../../../../../models/resource"
import { Scope } from "../../../../../models/scope"
import type { ElectricityEmissionsFactorRecord } from "../../../../../models/scopeTwo"
import { ElectricityEmissionsFactor } from "../../../../../models/scopeTwo"
import type { UnitName } from "../../../../../models/unit"
import type { DisplayFormatCallback } from "../../../models/resourceSummary"
import ResourceMetricDisplayUnit from "../resourceMetricDisplayUnit/resourceMetricDisplayUnit"

interface ResourceMetricHeaderProps {
  electricityEmissionsFactor?: ElectricityEmissionsFactorRecord
  formatMetric?: DisplayFormatCallback
  isTotal?: boolean
  metric?: IMetric
  resource?: IResource
  resourceDisplayName?: string
  scope?: Scope
  unit?: UnitName
}

/**
 * Displays a localized resource metric header
 *
 * @param props - The component's properties
 * @param props.resource - The displayed resource name (eg. `Fuel`)
 * @param props.resourceDisplayName - The translated displayed resource name (eg. `Water` is replaced by `Water Use`)
 * @param props.scope - The displayed scope (eg. `(Scope 1)`)
 * @param props.electricityEmissionsFactor - the displayed electricity emissions factor { id: "1"}
 * @param props.metric - The displayed metric (eg. `Volume`, `GHG`)
 * @param props.formatMetric - A function which can be used to format the display of the metric
 * @param props.unit - The displayed unit (eg. `lb CO2e`)
 * @param props.isTotal - Adjusts the header layout to display as a "Total"
 * @returns - The localized header element
 */
const ResourceMetricHeader = ({
  resource,
  resourceDisplayName,
  scope,
  metric,
  formatMetric,
  unit,
  isTotal,
  electricityEmissionsFactor,
}: ResourceMetricHeaderProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <span className="paragraph--small paragraph--small__heavy">
      {/* Display the resource name */}
      <If condition={!!resource}>
        {resourceDisplayName ? t(resourceDisplayName) : resource?.name}
        <br />
      </If>

      {/* Display the Location-based Scope */}
      <If
        condition={
          scope === Scope.Two &&
          electricityEmissionsFactor?.id ===
            ElectricityEmissionsFactor.LocationBased.id
        }
      >
        (Scope 2 Location-Based)
        <br />
      </If>

      {/* Display the Scope */}
      <If
        condition={
          !!scope &&
          electricityEmissionsFactor?.id !==
            ElectricityEmissionsFactor.LocationBased.id
        }
      >
        {`(Scope ${scope})`}
        <br />
      </If>

      {/* If not a "total" column, display the metric and base unit on one line */}
      <If condition={!isTotal && (!!metric || !!unit)}>
        <If condition={!!metric}>
          {formatMetric ? formatMetric(metric?.name) : metric?.name}
        </If>
        <If condition={!!metric && !!unit}> </If>
        <If condition={!!unit}>
          (<ResourceMetricDisplayUnit unit={unit} />)
        </If>
      </If>

      {/* If a "total" column, display the total metric and base unit on separate lines */}
      <If condition={isTotal && (!!metric || !!unit)}>
        Total{" "}
        <If condition={!!metric}>
          {formatMetric ? formatMetric(metric?.name) : metric?.name}
          <br />
        </If>
        <If condition={!!unit}>
          (<ResourceMetricDisplayUnit unit={unit} />)
        </If>
      </If>
    </span>
  )
}

export default ResourceMetricHeader
