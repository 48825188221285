import React from "react"
import type { FC } from "react"

import { ComplianceStandard } from "../compliance-standard"
import type { ComplianceStandardsProps } from "./compliance-standards.types"

export const ComplianceStandards: FC<ComplianceStandardsProps> = ({
  regulations,
}) => {
  return !regulations?.length ? (
    <>--</>
  ) : (
    <>
      {regulations.map((regulation) => {
        return (
          <ComplianceStandard
            key={`${regulation.regulation_name}-${regulation.effective_date}`}
            isComingSoon={regulation.is_supported_without_fines}
            isCompliant={regulation.in_compliance}
            isSupported={regulation.regulation_name !== "No Regulation"}
            name={regulation.regulation_name}
          />
        )
      })}
    </>
  )
}
