import type { ReactNode } from "react"
import React, { useEffect } from "react"

import { useAvailableReportDates } from "@/services"
import type { Moment } from "moment"

import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import useFilters from "../../../../hooks/useFilters/useFilters"
import DateFilter from "../../../../models/filter/dateFilter"
import type { IRange } from "../../../../models/range"
import {
  getMostRecentPreviousYearDateRange,
  isValidDateRange,
} from "../../../../utils/date"
import type { IGriFilters } from "../../models/gri"

interface GriResolverProps {
  children: ReactNode
}

/**
 * A component that ensures the Gri report url and form data are set up before rendering the report
 *
 * @param props - The component properties
 * @param props.children - The children to render
 * @example
 * return (
 *   <GriResolver>
 *     <Gri />
 *   </GriResolver>
 * )
 */
const GriResolver = ({ children }: GriResolverProps) => {
  const { organization } = useOrganizationContext()

  const { availableReportDatesData, availableReportDatesIsFetched } =
    useAvailableReportDates(organization?.id)

  const { filters, setFilters } = useFilters<IGriFilters>({
    start: DateFilter,
    end: DateFilter,
  })

  // Initialize "start" and "end" query params to valid values
  useEffect(() => {
    if (
      availableReportDatesData?.start.isValid() &&
      availableReportDatesData.end.isValid() &&
      // Don't rewrite valid date ranges
      !isValidDateRange(
        {
          start: filters.start.value,
          end: filters.end.value,
        },
        availableReportDatesData
      )
    ) {
      const mostRecentPreviousYearDateRange: IRange<Moment> =
        getMostRecentPreviousYearDateRange(availableReportDatesData)

      setFilters(
        {
          start: new DateFilter(mostRecentPreviousYearDateRange.start),
          end: new DateFilter(mostRecentPreviousYearDateRange.end),
        },
        { replace: true }
      )
    }
  }, [
    availableReportDatesData,
    filters.end.value,
    filters.start.value,
    setFilters,
  ])

  return availableReportDatesIsFetched ? <>{children}</> : null
}

export default GriResolver
