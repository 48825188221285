import React, { useCallback, useMemo, useState } from "react"

import { useOrganizations } from "@/services/organization"

import { Table, TableContainer } from "@mui/material"

import { Order } from "../../../../../models/sort"
import type { ITableData } from "../../../../../models/table"
import { getSkeletonTableData } from "../../../../../utils/table"
import type { ITamAdminOrganizationsTableRow } from "../../../models/onboarding/onboarding"
import {
  TamAdminOrganizationsColumnKey,
  tamAdminOrganizationsColumns,
} from "../../../models/onboarding/onboarding"
import { convertOrganizationsToTabularData } from "../../../util/onboarding/onboarding"
import TamAdminOrganizationTableContent from "./tamAdminOrganizationTableContent/tamAdminOrganizationTableContent"
import TamAdminOrganizationTableHeader from "./tamAdminOrganizationTableHeader/tamAdminOrganizationTableHeader"

const NUMBER_OF_SKELETON_ROWS = 8

const TamAdminOrganizationTable = (): JSX.Element => {
  const [order, setOrder] = useState<Order>(Order.asc)
  const [orderBy, setOrderBy] = useState<TamAdminOrganizationsColumnKey>(
    TamAdminOrganizationsColumnKey.Name
  )
  const { organizations, isOrganizationsLoading } = useOrganizations({
    sort: { order, key: orderBy },
  })

  const organizationsTabularData = useMemo<
    ITableData<ITamAdminOrganizationsTableRow>
  >(() => {
    if (!organizations?.length) {
      return getSkeletonTableData<ITamAdminOrganizationsTableRow>(
        tamAdminOrganizationsColumns,
        NUMBER_OF_SKELETON_ROWS
      )
    }
    return convertOrganizationsToTabularData(organizations)
  }, [organizations])

  const onSort = useCallback(
    (property: TamAdminOrganizationsColumnKey): void => {
      const isAsc = orderBy === property && order === Order.asc
      const newOrder = isAsc ? Order.desc : Order.asc
      setOrder(newOrder)
      setOrderBy(property)
    },
    [order, orderBy]
  )

  return (
    <TableContainer>
      <Table>
        <TamAdminOrganizationTableHeader
          columns={organizationsTabularData.columns}
          isLoading={isOrganizationsLoading}
          onSort={onSort}
          order={order}
          orderBy={orderBy}
        />
        <TamAdminOrganizationTableContent
          isLoading={isOrganizationsLoading}
          rows={organizationsTabularData.rows}
        />
      </Table>
    </TableContainer>
  )
}

export default TamAdminOrganizationTable
