import type { FC } from "react"
import React from "react"

import { Button } from "@mui/material"

import RightPanelCloseIcon from "../../../../../components/mui-icons/RightPanelCloseIcon"
import RightPanelOpenIcon from "../../../../../components/mui-icons/RightPanelOpenIcon"

interface SiteInfoButtonProps {
  isOpen: boolean
  onClick: () => void
}

const SiteInfoButton: FC<SiteInfoButtonProps> = ({ isOpen, onClick }) => (
  <Button
    color="neutral"
    endIcon={isOpen ? <RightPanelCloseIcon /> : <RightPanelOpenIcon />}
    onClick={onClick}
    size="small"
  >
    Site Info
  </Button>
)

export default SiteInfoButton
