import React, { forwardRef } from "react"
import { useFormContext } from "react-hook-form"

import { LoadingButton } from "@mui/lab"

import type { SubmitButtonProps } from "./submit-button.types"

export const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
  ({ variant, children, disabled, disableFormStateCheck, ...rest }, ref) => {
    const methods = useFormContext()

    const { isValid, isDirty, isSubmitting } = methods.formState

    let isDisabled: boolean = disabled ?? false
    // request in flight
    if (isSubmitting) {
      isDisabled = true
      // form is not valid, so disable
    } else if (!isValid && !disableFormStateCheck) {
      isDisabled = true
      // form hasn't been altered, so disable
    } else if (!isDirty && !disableFormStateCheck) {
      isDisabled = true
    }

    return (
      <LoadingButton
        {...rest}
        ref={ref}
        disabled={isDisabled}
        type="submit"
        variant={variant ?? "contained"}
      >
        {children}
      </LoadingButton>
    )
  }
)
