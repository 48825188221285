import React from "react"
import type { FC } from "react"
import { NavLink } from "react-router-dom"

import { primary600, primary700 } from "@/utils/colors"
import { datadogRum } from "@datadog/browser-rum"

import {
  Badge,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled,
} from "@mui/material"
import type { BadgeProps } from "@mui/material"

interface NavItemButtonProps {
  dataE2e?: string
  from: string
  icon: JSX.Element
  id: string
  isBeta?: boolean
  isOpen: boolean
  title: string
  to: string
}

const IconBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.0.5)",
    fontSize: "0.55rem",
    fontWeight: theme.typography.fontWeightBold,
    height: "14px",
    padding: "0 5px",
  },
}))

const TextBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.0.5)",
    fontSize: "0.55rem",
    fontWeight: theme.typography.fontWeightBold,
    height: "14px",
    padding: "0 5px",
    right: "-20px",
    top: "10px",
  },
}))

export const NavItemButton: FC<NavItemButtonProps> = ({
  dataE2e,
  from,
  icon,
  isBeta,
  isOpen,
  id,
  title,
  to,
}) => {
  return (
    <ListItem key={title} disablePadding sx={{ display: "block" }}>
      <Tooltip disableHoverListener={isOpen} placement="right" title={title}>
        <ListItemButton
          component={NavLink}
          data-e2e={dataE2e}
          id={id}
          onClick={() => {
            datadogRum.addAction("navigate", {
              description: `main nav: ${title}`,
              from: from,
              to: to,
            })
          }}
          sx={{
            color: (theme) => theme.palette.common.white,
            minHeight: 48,
            justifyContent: isOpen ? "initial" : "center",
            "&.active": {
              backgroundColor: (theme) => theme.palette.primary.dark,
              color: (theme) => theme.palette.common.white,
              ":hover": {
                backgroundColor: primary600.toString(),
              },
            },
            "&.MuiListItemButton-root:not(.active):hover": {
              backgroundColor: primary700.toString(),
            },
          }}
          to={to}
        >
          <IconBadge
            badgeContent="BETA"
            color="primary"
            invisible={!isBeta || isOpen}
          >
            <ListItemIcon
              sx={{
                color: (theme) => theme.palette.common.white,
                minWidth: 0,
                mr: isOpen ? 1 : "auto",
                justifyContent: "center",
              }}
            >
              {icon}
            </ListItemIcon>
          </IconBadge>
          {isOpen ? (
            <TextBadge badgeContent="BETA" color="primary" invisible={!isBeta}>
              <ListItemText
                primary={<Typography variant="body2">{title}</Typography>}
              />
            </TextBadge>
          ) : null}
        </ListItemButton>
      </Tooltip>
    </ListItem>
  )
}
