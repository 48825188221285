import React from "react"
import type { FC } from "react"
import { ErrorBoundary } from "react-error-boundary"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import { ConsultantGuard } from "@/components/route/consultant-guard"
import UserGuard from "@/components/route/userGuard/userGuard"

import OrganizationDashboard from "../organizationDashboard"
import OrganizationDashboardResolver from "../organizationDashboard.resolver"

export const OrganizationDashboardIndexRoute: FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={logError}>
      <UserGuard>
        <ConsultantGuard>
          <OrganizationDashboardResolver>
            <OrganizationDashboard />
          </OrganizationDashboardResolver>
        </ConsultantGuard>
      </UserGuard>
    </ErrorBoundary>
  )
}
