import type { Moment } from "moment"
import moment from "moment"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import type { IRange } from "../../../../models/range"
import ReportingData from "../../../../models/reportingData"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"
import type {
  GresbData,
  GresbDataDto,
  GresbEnergy,
  GresbEnergyDto,
  GresbGhg,
  GresbGhgDto,
  GresbWater,
  GresbWaterDto,
} from "../../models/gresb"

/**
 * Converts GRESB energy DTOs into GRESB energy objects
 *
 * @returns The GRESB energy objects
 * @example
 * getGresbEnergyFromDtos(gresbEnergyDtos)
 */
export const getGresbEnergyFromDtos = (
  energyDtos: GresbEnergyDto[]
): GresbEnergy[] =>
  energyDtos.map((dto) => ({
    ...dto,
    electricityConsumptionKwh: Number.parseFloat(dto.electricityConsumptionKwh),
    fuelConsumptionKwh: Number.parseFloat(dto.fuelConsumptionKwh),
    year: moment(dto.startDate).year(),
  }))

/**
 * Converts GRESB GHG DTOs into GRESB GHG objects
 *
 * @returns The GRESB GHG objects
 * @example
 * getGresbGhgFromDtos(gresbGhgDtos)
 */
export const getGresbGhgFromDtos = (ghgDtos: GresbGhgDto[]): GresbGhg[] =>
  ghgDtos.map((dto) => ({
    ...dto,
    scope1MtCo2E: Number.parseFloat(dto.scope1MtCo2E),
    scope2AdvancedGridStudyMtCo2E: Number.parseFloat(
      dto.scope2AdvancedGridStudyMtCo2E
    ),
    scope2LocationBasedMtCo2E: Number.parseFloat(dto.scope2LocationBasedMtCo2E),
    scope2MarketBasedMtCo2E: Number.parseFloat(dto.scope2MarketBasedMtCo2E),
    scope3MtCo2E: Number.parseFloat(dto.scope3MtCo2E),
    year: moment(dto.startDate).year(),
  }))

/**
 * Converts GRESB water DTOs into GRESB water objects
 *
 * @returns The GRESB water objects
 * @example
 * getGresbWaterFromDtos(gresbWaterDtos)
 */
export const getGresbWaterFromDtos = (
  waterDtos: GresbWaterDto[]
): GresbWater[] =>
  waterDtos.map((dto) => ({
    ...dto,
    waterConsumptionM3: Number.parseFloat(dto.waterConsumptionM3),
    year: moment(dto.startDate).year(),
  }))

/**
 * Normalizes a response's keys into snake-case from ReportingData
 * and converts its values to Energy, Ghg, and Water objects
 */
export const processGresbResponse = (
  value: CollectionProxy<ReportingData>
): GresbData => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const reportDataDto: GresbDataDto = snakeCaseToCamelCase(
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    value.meta.stats.gresb_service.calculate
  )

  const reportData: GresbData = {
    energy: getGresbEnergyFromDtos(reportDataDto.energy),
    ghg: getGresbGhgFromDtos(reportDataDto.ghg),
    water: getGresbWaterFromDtos(reportDataDto.water),
  }

  return reportData
}

/**
 * Fetches GRESB report data for the given organization and timeframe
 *
 * @param orgId - The organization id
 * @param dateRange - The date range for the report data
 * @returns The report data
 * @example
 * fetchGresbReport("1", {
 *   start: moment("2022-01-01"),
 *   end: moment("2022-02-28"),
 * })
 */
export const fetchGresbReport = async (
  orgId: string,
  dateRange: IRange<Moment>
): Promise<GresbData> =>
  ReportingData.where({
    organization_id: Number.parseInt(orgId, 10),
    year: dateRange.start?.year() ?? 2000,
    start_month: (dateRange.start?.month() ?? 0) + 1,
    end_month: (dateRange.end?.month() ?? 0) + 1,
  })
    .stats({
      gresb_service: "calculate",
    })
    .all()
    .then(processGresbResponse)

export default {
  getGresbEnergyFromDtos,
  getGresbGhgFromDtos,
  getGresbWaterFromDtos,
  fetchGresbReport,
}
