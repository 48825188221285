import type { UseQueryOptions } from "react-query"
import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"

import { ApiQueryName } from "../../../../models/api"
import { getPhysicalDetailTypes } from "./get-physical-detail-types"

interface UseGetPhysicalDetailTypesOptions {
  queryOptions?: Omit<UseQueryOptions, "queryKey" | "queryFn">
}

/**
 * returns physical details schema
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetPhysicalDetailTypes({ queryOptions }); // react query options
 */
export const useGetPhysicalDetailTypes = (
  options: UseGetPhysicalDetailTypesOptions = {}
) => {
  const queryKey = useQueryKey(ApiQueryName.PhysicalDetailTypes, "getMany")

  let queryParams = {
    queryKey,
    queryFn: () => getPhysicalDetailTypes(),
  }

  if (options.queryOptions) {
    queryParams = {
      ...options.queryOptions,
      ...queryParams,
    }
  }

  const queryResult = useQuery(queryParams)

  return queryResult
}
