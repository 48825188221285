import { useMemo } from "react"

import type { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro"

import type { UseGridColumnsProps } from "./use-grid-columns.types"

const hydrateSavedColumns = <RowT extends GridValidRowModel>(
  savedColumns: GridColDef<RowT>[],
  columns: GridColDef<RowT>[]
): GridColDef<RowT>[] => {
  const hydratedColumns = savedColumns
    .map((savedCol) => {
      return columns.find((col) => col.field === savedCol.field)
    })
    .filter((col) => col !== undefined) as GridColDef<RowT>[]

  return hydratedColumns
}

/**
 * Returns the new valid columns by taking into account added/removed columns
 *
 * @returns The new valid columns
 * @example
 * const columns = useGridColumns({
 *   savedColumns,
 *   validColumns,
 * })
 */
export const useGridColumns = <RowT extends GridValidRowModel>({
  savedColumns,
  validColumns,
}: UseGridColumnsProps<RowT>): GridColDef<RowT>[] => {
  // compute new columns by taking into account added/removed columns
  const newColumns: GridColDef<RowT>[] = useMemo(() => {
    // if columns have been removed after a deployment
    // remove them from the columns
    const updatedColumns: GridColDef<RowT>[] = savedColumns.filter(
      (savedColumn) => {
        const isValidColumn = Boolean(
          validColumns.find(
            (validColumn) => validColumn.field === savedColumn.field
          )
        )

        return isValidColumn
      }
    )

    // if new columns have been added after a deployment
    // add them to the columns
    validColumns.forEach((validColumn) => {
      const isNewColumn =
        updatedColumns.find((column) => column.field === validColumn.field) ===
        undefined

      if (isNewColumn) {
        updatedColumns.push(validColumn)
      }
    })

    // only basic types are allowed in local storage
    // so we have to re-hydrate the saved columns
    // to revive properties like renderHeader and valueFormatter
    return hydrateSavedColumns(updatedColumns, validColumns)
  }, [savedColumns, validColumns])

  return newColumns
}
