// @ts-strict-ignore
import React from "react"
import type { FC } from "react"
import { Link } from "react-router-dom"

import { Box, Button, Stack } from "@mui/material"

import { SectionHeader } from "../../../../components/section-header/section-header"
import { HStack } from "../../../../nzds"
import { SiteCard } from "../../components/site-card/site-card"
import { RecommendationsPath } from "../../enums"
import type { RecommendationsIndexRecommendedSitesProps } from "./recommendations-index-recommended-sites.types"

export const RecommendationsIndexRecommendedSites: FC<
  RecommendationsIndexRecommendedSitesProps
> = ({ potentialSites, plannedSites }) => {
  const exploreAllSitesButton = (
    <Button
      component={Link}
      sx={{ marginRight: "auto" }}
      to={RecommendationsPath.Plan}
      variant="outlined"
    >
      Explore all sites
    </Button>
  )

  if (!potentialSites) {
    return null
  }

  const hasPlannedSites = plannedSites && plannedSites.length > 0
  return (
    <>
      {potentialSites.length >= 2 ? (
        <Stack gap={2}>
          <SectionHeader
            actions={
              hasPlannedSites ? <Box>{exploreAllSitesButton}</Box> : null
            }
            divider={hasPlannedSites}
            title="Recommended Sites"
          />
          <HStack gap={3}>
            {potentialSites.slice(0, 3).map((pS) => (
              <SiteCard
                key={pS.site_id}
                eui={pS.eui}
                name={pS.site_name}
                portfolioImpact={pS.portfolio_impact}
                siteId={pS.site_id}
                sx={{ flex: 1 }}
              />
            ))}
          </HStack>
          {!hasPlannedSites ? exploreAllSitesButton : null}
        </Stack>
      ) : null}
    </>
  )
}
