import type { ReactNode } from "react"
import React from "react"

import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"

import { Box } from "@mui/material"

import type { ReportCard } from "../../models/report"
import { ReportingLink } from "../reporting-link"

interface ReportLayoutProps {
  actions: ReactNode
  details?: ReactNode
  filters: ReactNode
  notifications?: ReactNode
  report: ReportCard
}

const ReportLayout = ({
  actions,
  details,
  filters,
  notifications,
  report,
}: ReportLayoutProps) => (
  <>
    <PageHeader title={report.title} />
    <PageHeaderActionBar hasTabs={false}>
      <ReportingLink />
    </PageHeaderActionBar>
    <Page>
      {notifications}
      <Box mb={3}>{filters}</Box>
      <Box mb={2}>{actions}</Box>
      {details}
    </Page>
  </>
)

ReportLayout.defaultProps = {
  details: "",
  notifications: "",
}

export default ReportLayout
