import type { UseMutationOptions } from "react-query"
import { useMutation, useQueryClient } from "react-query"

import type { Paths } from "@/services/types"

import { ApiQueryName } from "../../../../models/api"
import type { UploadProfilesCsvParameters } from "./upload-profiles-csv"
import { uploadProfilesCsv } from "./upload-profiles-csv"

// noted to mseverini that this is a type mismatch between the types generated from the backend and
// what is actually returned from the backend.  The backend is returning a string, but the types generated
// an array of objects referring to the second type parameter here
interface UseUploadProfilesCsv {
  mutationOptions?: Omit<
    UseMutationOptions<
      Paths["/api/v1/recommendations/emission_profiles/load"]["post"]["responses"]["200"]["content"]["application/json"],
      Paths["/api/v1/recommendations/emission_profiles/load"]["post"]["responses"]["422"]["content"]["application/json"],
      UploadProfilesCsvParameters
    >,
    "mutationFn"
  >
}

/**
 * Uploads a CSV file of emission profiles.
 *
 * @example
 * const { mutateAsync, data, isLoading, error, isError } =
 * useUploadProfilesCsv({
 *   mutationOptions // react query options
 * });
 *
 * await mutateAsync({})
 */
export const useUploadProfilesCsv = (options?: UseUploadProfilesCsv) => {
  const mutationOptions = options?.mutationOptions
  const queryClient = useQueryClient()

  const mutation = useMutation(uploadProfilesCsv, {
    ...(mutationOptions ?? {}),
    onSuccess: (data, variables, context) => {
      if (mutationOptions?.onSuccess) {
        void mutationOptions.onSuccess(data, variables, context)
      }

      // clear out all of it, so it includes details cache as well
      void queryClient.invalidateQueries([ApiQueryName.EmissionProfiles])
    },
  })
  return mutation
}
