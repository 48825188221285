export interface IMetric {
  id: string
  name: string
  order: number
}

interface IMetricMap {
  GHG: IMetric
  GJ: IMetric
  Volume: IMetric
}

export const Metric: IMetricMap = {
  Volume: {
    id: "1",
    name: "Volume",
    order: 1,
  },
  GHG: {
    id: "2",
    name: "GHG",
    order: 2,
  },
  GJ: {
    id: "3",
    name: "Energy Consumption",
    order: 3,
  },
}

// migration to strict mode batch disable
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const metrics: IMetric[] = Object.values(Metric)
