import type { FC } from "react"
import React from "react"

import { useUnit } from "../../../../components/unit/use-unit"
import { useFormatDecimal } from "../../../../formatters/useFormatDecimal"
import { useFormatMoney } from "../../../../formatters/useFormatMoney"
import { useFormatPercentage } from "../../../../formatters/useFormatPercentage"
import { UnitName } from "../../../../models/unit"
import { HStack } from "../../../../nzds"
import { useSiteStats } from "../../services/use-site-stats/use-site-stats"
import { StatCardContent } from "../stat-card-content/stat-card-content"
import { StatCard } from "../stat-card/stat-card"
import type { PortfolioOverviewStatsProps } from "./portfolio-overview-stats.types"

export const PortfolioOverviewStats: FC<PortfolioOverviewStatsProps> = ({
  plannedSites,
  potentialSites,
}) => {
  const { format: formatPercentage } = useFormatPercentage({
    maximumFractionDigits: 2,
  })
  const { format: formatDecimal } = useFormatDecimal({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const { format: formatMoney } = useFormatMoney({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const stats = useSiteStats(plannedSites, potentialSites)

  const co2eUnit = useUnit(
    UnitName.MetricTonsOfCarbonDioxideEquivalent,
    stats.totalC02MTsRemoved
  )

  return (
    <HStack alignItems="center" gap={3}>
      <StatCard
        sx={{
          flexBasis: (theme) => theme.typography.pxToRem(160),
          flexGrow: 0,
        }}
      >
        <StatCardContent
          title="Sites Planned"
          value={`${stats.plannedSitesCount} / ${stats.totalSitesCount}`}
        />
      </StatCard>
      <StatCard>
        <StatCardContent
          title="Emission Reduction"
          value={formatPercentage(stats.emissionSavingsPercent)}
        />
        <StatCardContent
          title={`Total ${co2eUnit} Removed`}
          value={`${formatDecimal(stats.totalC02MTsRemoved)}`}
        />
      </StatCard>
      <StatCard>
        <StatCardContent
          title="Capital Cost"
          value={formatMoney(stats.totalCapitalCost)}
        />
        <StatCardContent
          title="Annual Savings"
          value={formatMoney(stats.totalAnnualSavings)}
        />
        <StatCardContent
          title="ROI (Years)"
          value={`${formatDecimal(stats.totalRoi)}`}
        />
      </StatCard>
    </HStack>
  )
}
