import React from "react"

import { Stack, Typography } from "@mui/material"

import { Unit } from "../../../../../../../components/unit/unit"
import { UnitName } from "../../../../../../../models/unit"
import { gray50, gray700 } from "../../../../../../../utils/colors"
import { pxToRem } from "../../../../../../../utils/unit"

const styles = {
  headerItem: {
    color: gray700.toString(),
    padding: `${pxToRem(16)} ${pxToRem(12)}`,
  },
  headerAlignRight: {
    textAlign: "right",
  },
}

/**
 * @deprecated This UI is slated to be completely replaced
 */
export const HeaderTable = () => (
  <Stack direction="row" sx={{ backgroundColor: gray50.toString() }}>
    <Typography
      component="span"
      fontWeight="bold"
      sx={{ ...styles.headerItem }}
      variant="body2"
      width={pxToRem(250)}
    >
      Meter
    </Typography>
    <Typography
      component="span"
      fontWeight="bold"
      sx={{ ...styles.headerItem }}
      variant="body2"
      width={pxToRem(100)}
    >
      Meter Type
    </Typography>
    <Typography
      component="span"
      fontWeight="bold"
      sx={{ ...styles.headerItem }}
      variant="body2"
      width={pxToRem(100)}
    >
      Meter ID
    </Typography>
    <Typography
      fontWeight="bold"
      sx={{
        ...styles.headerItem,
        ...styles.headerAlignRight,
      }}
      textAlign="right"
      variant="body2"
      width={pxToRem(162)}
    >
      Total Usage (<Unit unit={UnitName.KilowattHour} />)
    </Typography>
    <Typography
      component="span"
      fontWeight="bold"
      sx={{
        ...styles.headerItem,
        ...styles.headerAlignRight,
      }}
      textAlign="right"
      variant="body2"
      width={pxToRem(162)}
    >
      Estimated Cost
    </Typography>
  </Stack>
)
