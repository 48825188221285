import React from "react"

import { createSvgIcon } from "@mui/material/utils"

export const NZeroLogoIcon = createSvgIcon(
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7195 18.1985L22 10.841C21.9403 10.1865 21.8262 9.54295 21.6525 8.94301C21.4407 8.17944 21.1474 7.46496 20.7836 6.81593C19.9419 5.31606 18.7418 4.12708 17.2213 3.27625C15.6954 2.43087 13.9468 2 12.019 2C10.0912 2 8.3155 2.43087 6.80043 3.27625C5.28537 4.12163 4.08526 5.31606 3.23269 6.81593C2.74939 7.6613 2.40185 8.5994 2.19006 9.59204C2.15748 9.73384 2.19006 9.86474 2.27152 9.96837C2.35297 10.072 2.4833 10.1265 2.62992 10.1265H3.65626C3.97665 10.1265 4.2536 9.89201 4.30247 9.56477C4.57399 7.91219 5.22563 6.53777 6.25197 5.47968C7.64214 4.03981 9.58621 3.30897 12.0136 3.30897C14.4409 3.30897 16.3524 4.03981 17.748 5.47968C18.9644 6.73957 19.6595 8.43033 19.8116 10.5138L19.817 10.5629L12.8336 16.1042L12.8987 5.80147H11.2153L2 13.1044C2.05973 13.7644 2.17377 14.4025 2.34211 15.0079C2.55933 15.7824 2.858 16.5132 3.23812 17.1841C4.09069 18.6839 5.2908 19.8784 6.80587 20.7238C8.32093 21.5691 10.0749 22 12.0244 22C13.9739 22 15.7062 21.5691 17.2267 20.7238C18.7472 19.8784 19.9473 18.6839 20.789 17.1841C21.2506 16.3605 21.5927 15.4606 21.7991 14.5007C21.8317 14.3589 21.8045 14.2225 21.7231 14.1189C21.6416 14.0153 21.5113 13.9607 21.3647 13.9607H20.3275C20.0125 13.9607 19.7464 14.1898 19.6921 14.5061C19.4151 16.1314 18.7635 17.484 17.7535 18.5258C16.3579 19.9656 14.4301 20.6965 12.019 20.6965C9.60793 20.6965 7.64757 19.9656 6.2574 18.5258C5.03014 17.255 4.33505 15.5424 4.18843 13.4371L4.183 13.3881L11.1013 7.90128L11.0361 18.204H12.7195V18.1985Z"
      fill="currentColor"
    />
  </svg>,
  "NZeroLogoIcon"
)
