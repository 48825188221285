import React from "react"
import { Link } from "react-router-dom"

import { FeatureFlags, useFeature } from "@/services/feature"
import { useUrl } from "@/utils/url"

import { ListAlt } from "@mui/icons-material"
import { Box, Button } from "@mui/material"

import { useOrganizationContext } from "../../../contexts/organizationProvider"
import useFilters from "../../../hooks/useFilters/useFilters"
import DateFilter from "../../../models/filter/dateFilter"
import { ReportPath } from "../../../models/route"
import type { Scope } from "../../../models/scope"
import { getQueryStringFromFilters } from "../../../utils/navigation"

interface ScopeReportButtonProps {
  isLimitedAccessUser: boolean
  scope: Scope
}

export const ScopeReportButton = ({
  scope,
  isLimitedAccessUser,
}: ScopeReportButtonProps) => {
  const { buildReportingUrl } = useUrl()
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()
  const isVisible =
    isFeatureEnabled(FeatureFlags.REPORT_ACTIVITY_EMISSIONS, organization) &&
    !isLimitedAccessUser
  const label = `Scope ${scope} Data`
  const { filters } = useFilters({
    start: DateFilter,
    end: DateFilter,
  })

  return (
    isVisible && (
      <Box>
        <Button
          color="neutral"
          component={Link}
          endIcon={<ListAlt />}
          size="small"
          to={`${buildReportingUrl(ReportPath.EmissionsSources)}${getQueryStringFromFilters(filters)}&filter=scope${scope}`}
          variant="outlined"
        >
          {label}
        </Button>
      </Box>
    )
  )
}
