import type { FC } from "react"
import React, { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import type {
  GridColDef,
  GridRenderCellParams,
  GridSortItem,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro"
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro"

import { useUnit } from "../../../../components/unit/use-unit"
import { useFormatDecimal } from "../../../../formatters/useFormatDecimal"
import { useFormatMoney } from "../../../../formatters/useFormatMoney"
import { useFormatPercentage } from "../../../../formatters/useFormatPercentage"
import { useCurrencySymbol } from "../../../../hooks/useCurrencySymbol/useCurrencySymbol"
import { UnitName } from "../../../../models/unit"
import { renderColumnHeader } from "../../../../utils/render-column-header"
import { renderRowNumber } from "../../../../utils/render-row-number"
import { PlanNameCell } from "../plan-name-cell/plan-name-cell"
import { planNameIsRecommendedComparator } from "./recommended-plans-comparator"
import type {
  RecommendedPlansTableProps,
  RowData,
} from "./recommended-plans-table.types"
import { useBuildRowData } from "./utils/use-build-row-data"

const baseColumnDef: Partial<GridColDef> = {
  disableColumnMenu: true,
  resizable: false,
  hideable: false,
  renderHeader: renderColumnHeader,
  disableReorder: true,
  align: "left",
}

const baseColumnWidth = 52 // padding of 10px on left and right + 32px for the sorting button

export const RecommendedPlansTable: FC<RecommendedPlansTableProps> = ({
  allPlans,
  recommendedPlans,
}) => {
  const [searchParams] = useSearchParams()

  const apiRef = useGridApiRef()

  const currencySymbol = useCurrencySymbol()

  const { format: formatMoney } = useFormatMoney({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  const { format: formatPercentage } = useFormatPercentage({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const { format: formatDecimal } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  const rows = useBuildRowData(allPlans, recommendedPlans)

  const sortColumn = useMemo(() => {
    let defaultSort: GridSortItem | undefined

    if (searchParams.get("budget") && !searchParams.get("reduction_target")) {
      defaultSort = { field: "emission_savings", sort: "desc" }
    } else if (
      !searchParams.get("budget") &&
      searchParams.get("reduction_target")
    ) {
      defaultSort = { field: "capital_cost", sort: "asc" }
    } else if (
      searchParams.get("budget") &&
      searchParams.get("reduction_target")
    ) {
      defaultSort = { field: "capital_cost", sort: "asc" }
    }

    return defaultSort
  }, [searchParams])

  useEffect(() => {
    if (sortColumn) {
      apiRef.current.setSortModel([sortColumn])
    }
  }, [apiRef, sortColumn])

  const co2eUnit = useUnit(UnitName.MetricTonsOfCarbonDioxideEquivalent)

  const columnDefinitions: GridColDef[] = useMemo(() => {
    const columns: GridColDef[] = [
      {
        ...baseColumnDef,
        field: "number",
        align: "center",
        headerAlign: "center",
        sortable: false,
        resizable: false,
        headerName: "#",
        width: 52,
        renderCell: renderRowNumber,
      },
      {
        ...baseColumnDef,
        field: "name",
        sortable: false,
        align: "left",
        headerName: "Plan Overview",
        minWidth: 300,
        flex: 1,
        sortComparator: planNameIsRecommendedComparator,
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <PlanNameCell
            isRecommended={params.row.isRecommended}
            name={params.row.name}
            planId={params.row.id}
            siteId={params.row.site_id}
          />
        ),
      },
      {
        ...baseColumnDef,
        field: "emission_savings",
        align: "right",
        headerAlign: "right",
        headerName: "Emissions Reduction (%)",
        width: baseColumnWidth + 152,
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          formatPercentage(params.value),
      },
      {
        ...baseColumnDef,
        resizable: true,
        field: "emissions_diff",
        align: "right",
        headerAlign: "right",
        headerName: `${co2eUnit} Removed`,
        width: baseColumnWidth + 122,
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          formatDecimal(params.value),
      },
      {
        ...baseColumnDef,
        field: "portfolio_impact",
        align: "right",
        headerAlign: "right",
        headerName: "Portfolio Reduction (%)",
        width: baseColumnWidth + 146,
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          formatPercentage(params.value),
      },
      {
        ...baseColumnDef,
        field: "capital_cost",
        align: "right",
        headerAlign: "right",
        headerName: `Capital cost (${currencySymbol})`,
        width: baseColumnWidth + 94,
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          formatMoney(params.value),
      },
      {
        ...baseColumnDef,
        field: "payback_period",
        align: "right",
        headerAlign: "right",
        headerName: "ROI (Years)",
        resizable: false,
        width: baseColumnWidth + 71,
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          formatDecimal(params.value),
      },
    ]

    return columns
  }, [co2eUnit, currencySymbol, formatDecimal, formatMoney, formatPercentage])

  return (
    <DataGridPro
      apiRef={apiRef}
      columns={columnDefinitions}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableRowSelectionOnClick
      initialState={{
        sorting: {
          sortModel: sortColumn ? [sortColumn] : undefined,
        },
        pinnedColumns: { left: ["number", "name"] },
      }}
      rowHeight={48}
      rows={rows}
    />
  )
}
