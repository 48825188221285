import type BooleanFilter from "../../../models/filter/booleanFilter"
import type DateFilter from "../../../models/filter/dateFilter"
import type MultiSelectNumberFilter from "../../../models/filter/multiSelectNumberFilter"
import type SingleSelectNumberFilter from "../../../models/filter/singleSelectNumberFilter"
import type { ITableColumn, ITableRow } from "../../../models/table"

export enum ScopeThreeReportName {
  Category = "Scope 3 Category",
  Summary = "Scope 3 Summary",
}

export interface IScopeThreeFilters {
  category: SingleSelectNumberFilter
  end: DateFilter
  start: DateFilter
  subcategory: MultiSelectNumberFilter
  summary: BooleanFilter
}

interface IScopeThreeReportTableRow extends ITableRow {
  mtCo2E: number
}

export interface IScopeThreeSummaryReportTableRow
  extends IScopeThreeReportTableRow {
  category: string
}

export interface IScopeThreeCategoryReportTableRow
  extends IScopeThreeReportTableRow {
  emissionsName: string
  subcategory: string
}

export enum ScopeThreeReportColumnName {
  Category = "Category",
  EmissionsName = "Emissions name",
  MtCo2E = "MT CO2e",
  Subcategory = "Subcategory",
}

export enum ScopeThreeReportColumnKey {
  Category = "category",
  EmissionsName = "emissionsName",
  MtCo2E = "mtCo2E",
  Subcategory = "subcategory",
}

export const scopeThreeSummaryReportColumns: ITableColumn<IScopeThreeSummaryReportTableRow>[] =
  [
    {
      id: 0,
      order: 0,
      title: ScopeThreeReportColumnName.Category,
      key: "category",
    },
    {
      id: 1,
      order: 1,
      title: ScopeThreeReportColumnName.MtCo2E,
      key: "mtCo2E",
    },
  ]

export const scopeThreeCategoryReportColumns: ITableColumn<IScopeThreeCategoryReportTableRow>[] =
  [
    {
      id: 0,
      order: 0,
      title: ScopeThreeReportColumnName.EmissionsName,
      key: "emissionsName",
    },
    {
      id: 1,
      order: 1,
      title: ScopeThreeReportColumnName.Subcategory,
      key: "subcategory",
    },
    {
      id: 2,
      order: 2,
      title: ScopeThreeReportColumnName.MtCo2E,
      key: "mtCo2E",
    },
  ]

export default {
  scopeThreeCategoryReportColumns,
  ScopeThreeReportName,
  scopeThreeSummaryReportColumns,
}
