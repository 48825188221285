import * as React from "react"
import type { ReactNode } from "react"
import { useEffect, useState } from "react"

import type { i18n } from "i18next"

interface II18nResolverProps {
  children: ReactNode
  i18nService: i18n
}

/**
 * A component that ensures the i18n service is ready before rendering its children who depend on it
 *
 * @param props {I18nResolverProps} - The component properties
 * @param props.children {React.ReactNode} - The child nodes to render
 * @param props.i18nService {i18n} - The i18n service
 * @example
 * return (
 *   <I18nResolver>
 *     <ChildComponent>
 *   </I18nResolver>
 * )
 */
export const I18nResolver = ({ children, i18nService }: II18nResolverProps) => {
  const [isI18nInitialized, setIsI18nInitialized] = useState<boolean>(false)

  // Sets when the i18nService has initialized
  useEffect(() => {
    const onInitialized = (): void => {
      setIsI18nInitialized(i18nService.isInitialized)
    }

    i18nService.on("initialized", onInitialized)

    return () => {
      i18nService.off("initialized", onInitialized)
    }
  }, [i18nService])

  if (!isI18nInitialized) {
    return null
  }
  return <>{children}</>
}

export default I18nResolver
