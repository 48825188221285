import { CANCELED_PROMISE_ERROR_MESSAGE } from "../models/error"

export interface ICancelablePromise<T> {
  cancel: () => void
  promise: Promise<T>
}

export const makeCancelable = <T>(promise: Promise<T>) => {
  let isCanceled = false

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (value) => {
        isCanceled
          ? reject(new Error(CANCELED_PROMISE_ERROR_MESSAGE))
          : resolve(value)
      },
      (error) => {
        isCanceled
          ? reject(new Error(CANCELED_PROMISE_ERROR_MESSAGE))
          : reject(error)
      }
    )
  })

  const cancelablePromise: ICancelablePromise<T> = {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true
    },
  }

  return cancelablePromise
}
