import { billingGroupSchema } from "./billingGroup"
import { departmentSchema } from "./department"
import { meterSchema } from "./meter"
import { organizationSchema } from "./organization"
import { siteSchema } from "./site"
import { generateValidation } from "./utils/schema"

export const billingGroupFormValidation =
  generateValidation(billingGroupSchema).form()

export const departmentFormValidation =
  generateValidation(departmentSchema).form()

export const meterFormValidation = generateValidation(meterSchema).form()

export const organizationFormValidation =
  generateValidation(organizationSchema).form()

export const siteFormValidation = generateValidation(siteSchema).form()
