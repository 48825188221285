import { fetchClient } from "@/services/client"
import type { Params } from "@/services/fetch-client/client"

import type {
  SiteExplorerPaginatedData,
  SiteExplorerResourceResponse,
} from "./site-explorer.types"

export const getSiteExplorer = async (
  params: Params
): Promise<SiteExplorerPaginatedData> => {
  const { data, error } = await fetchClient.GET<SiteExplorerResourceResponse>(
    "/api/v1/site_explorer",
    {
      params,
    }
  )

  if (data && "data" in data) {
    const paginatedData: SiteExplorerPaginatedData = {
      average: data.meta.stats.average?.count ?? null,
      compliant: data.meta.stats.compliant?.count ?? null,
      efficient: data.meta.stats.efficient?.count ?? null,
      inefficient: data.meta.stats.inefficient?.count ?? null,
      items: data.data,
      non_compliant: data.meta.stats.non_compliant?.count ?? null,
      page: data.meta.stats.page.count.number,
      pages: data.meta.stats.page.count.pages,
      size: data.meta.stats.page.count.size,
      total: data.meta.stats.page.count.total,
      total_penalties: data.meta.stats.total_penalties?.sum ?? null,
      very_efficient: data.meta.stats.very_efficient?.count ?? null,
      very_inefficient: data.meta.stats.very_inefficient?.count ?? null,
    }

    return paginatedData
  }

  throw error as Error
}
