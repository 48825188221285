import React, { useCallback } from "react"

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { Autocomplete, Checkbox, Paper, Popper, TextField } from "@mui/material"

import type Department from "../../../../../models/department"

import styles from "./departmentSelector.module.scss"

const CustomPopper = (props) => (
  <Popper {...props} className={styles.popper} placement="bottom-end" />
)

const CustomPaper = (props) => <Paper {...props} />

/**
 * @deprecated Use generic nzds chip selector once it's available
 */
export const DepartmentSelector = ({
  initialValues,
  isUseFlexibleHierarchyEnabled,
  options,
  onChange,
}: {
  initialValues: string[]
  isUseFlexibleHierarchyEnabled: boolean
  onChange: (departmentIds: string[]) => void
  options: Department[]
}) => {
  const onInputChange = useCallback(
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any, values: Department[]): void => {
      onChange(values.map((dept) => dept.id))
    },
    [onChange]
  )

  const getOptionLabel = useCallback(
    (option: Department): string => option.name,
    []
  )

  // TODO: remove this when flexible hierarchy is fully enabled and set to Groups
  const placeHolderText = isUseFlexibleHierarchyEnabled
    ? "Groups"
    : "Departments"

  return (
    <Autocomplete
      className={styles.criteriaField}
      data-testid="test-departments"
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      id="departments"
      multiple
      onChange={onInputChange}
      options={options}
      PaperComponent={CustomPaper}
      PopperComponent={CustomPopper}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          className={styles.criteriaFieldInput}
          data-e2e="reporting-departments"
          inputProps={{
            ...params.inputProps,
            "data-testid": "departments",
          }}
          placeholder={initialValues?.length ? "" : placeHolderText}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            checked={selected}
            checkedIcon={
              <CheckBox className={styles.checked} fontSize="small" />
            }
            className={styles.option}
            icon={<CheckBoxOutlineBlank fontSize="small" />}
          />
          {option.name}
        </li>
      )}
      value={options.filter((dept) =>
        initialValues?.some((selectedId) => selectedId === dept.id)
      )}
    />
  )
}
