import type { Organization } from "@/services/organization"

import type { ITableColumn, ITableData } from "../../../../models/table"
import type {
  IOrgViewDisplayCount,
  ITamAdminOrganizationsTableRow,
} from "../../models/onboarding/onboarding"
import { tamAdminOrganizationsColumns } from "../../models/onboarding/onboarding"

/**
 * Converts the organization data into tabular data
 *
 * @param rowData - The organization data
 * @returns - Tabulated organization data
 * @example
 * convertOrganizationsToTabularData(Organization[])
 */
export const convertOrganizationsToTabularData = (
  rowData: Organization[]
): ITableData<ITamAdminOrganizationsTableRow> => {
  const columns: ITableColumn<ITamAdminOrganizationsTableRow>[] =
    tamAdminOrganizationsColumns.map((col) => ({
      id: col.id,
      order: col.order,
      title: col.title,
      key: col.key,
    }))

  const rows: ITamAdminOrganizationsTableRow[] = rowData.map(
    (organization) => ({
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      name: organization.name,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      id: organization.id,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      locale: organization.locale.name,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      demoOrganization: organization.demoOrganization,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dateCreated: organization.dateCreated,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dateUpdated: organization.dateUpdated,
    })
  )

  return {
    columns,
    rows,
  }
}

/**
 * Converts the raw counts data to display counts
 *
 * @param counts - The counts to convert
 * @returns - The array of display counts
 * @example
 * convertToDisplayCounts<IOrganizationCounts>({
    billingGroups: 1,
    departments: 2,
    meters: 4,
    organizationEmissions: 5,
    sites: 6,
   },
   organizationDisplayCounts)
 */
export const convertToDisplayCounts = <TCounts>(
  counts: TCounts,
  displayCounts: IOrgViewDisplayCount<TCounts>[]
): IOrgViewDisplayCount<TCounts>[] => {
  if (!counts) {
    return []
  }
  return Object.entries(counts).map(([key, value]) => {
    const displayCount: IOrgViewDisplayCount<TCounts> | undefined =
      displayCounts.find((count) => count.key === key)

    return {
      ...displayCount,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value: value ?? NaN,
    }
  })
}

export default {
  convertOrganizationsToTabularData,
  convertToDisplayCounts,
}
