import { z } from "zod"

import type { GetRelationshipMeta, IsRelationship } from "./zodRelationship"
import { getRelationshipMeta, isRelationship } from "./zodRelationship"

type GetRelationshipNames<ZodObjectT extends z.ZodRawShape> = keyof {
  [N in keyof ZodObjectT as IsRelationship<ZodObjectT[N]> extends false
    ? never
    : N]: unknown
}

type GetAttributeNames<ZodObjectT extends z.ZodRawShape> = keyof {
  [N in keyof ZodObjectT as IsRelationship<ZodObjectT[N]> extends true
    ? never
    : N]: unknown
}

export const defineSchema = <
  SchemaNameT extends string,
  ZodObjectT extends z.ZodRawShape,
>(
  schemaName: SchemaNameT,
  zodObject: ZodObjectT
) => {
  const attributeNames: GetAttributeNames<ZodObjectT>[] = []
  const relationshipNames: GetRelationshipNames<ZodObjectT>[] = []
  const relationshipsMeta = {} as {
    [N in GetRelationshipNames<ZodObjectT>]: GetRelationshipMeta<ZodObjectT[N]>
  }

  Object.keys(zodObject).forEach((name) => {
    if (isRelationship(zodObject[name])) {
      relationshipNames.push(name as GetRelationshipNames<ZodObjectT>)
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      relationshipsMeta[name as GetRelationshipNames<ZodObjectT>] =
        getRelationshipMeta(zodObject[name])
    } else {
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      attributeNames.push(name as any)
    }
  })

  const schema = {
    name: schemaName,
    zodSchema: z.object(zodObject),
    attributeNames,
    relationshipNames,
    relationshipsMeta,
    relationshipsByName: {},
    isHydrated: false,
  }

  return schema
}
