import React from "react"

import type { TooltipProps } from "@mui/material"
import { Tooltip, tooltipClasses } from "@mui/material"
import { styled } from "@mui/material/styles"

export const BreakdownTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    maxWidth: theme.typography.pxToRem(200),
    boxShadow: theme.shadows[3],
  },
}))
