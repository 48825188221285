import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import { getCo2eLbsAndCarbonFreeKwh } from "./useEnergyStatsUtils"

export const useEnergyStats = (orgId: string, dateRange: IRange<Moment>) => {
  const queryKey = useQueryKey(ApiQueryName.EnergyStats, "getOne", orgId, {
    start: dateRange?.start?.format("YYYY-MM-DD"),
    end: dateRange?.end?.format("YYYY-MM-DD"),
    ignoreCurrency: true,
  })

  const queryFn = () =>
    getCo2eLbsAndCarbonFreeKwh({
      startTime: dateRange?.start,
      endTime: dateRange?.end,
      organizationId: orgId,
    })

  const queryResult = useQuery({
    queryKey,
    queryFn,
    enabled:
      !!orgId &&
      !!dateRange?.start?.isValid() &&
      !!dateRange?.end?.isValid() &&
      !!dateRange?.end?.isAfter(dateRange?.start),
    keepPreviousData: true, // Prevent flash of previous data on date range change
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
