import React from "react"
import { Outlet } from "react-router-dom"

import { useOrganizationContext } from "@/contexts/organizationProvider"

import { Box } from "@mui/material"

import { useViewport } from "../../../contexts/viewportProvider"
import {
  LEFT_NAV_COLLAPSED_WIDTH,
  LEFT_NAV_EXPANDED_WIDTH,
} from "../../../utils/constants"
import { NavigationDrawer } from "./navigation-drawer/navigation-drawer"

export interface NewLayoutContext {
  collapsed: boolean
}

export const NewLayout = () => {
  const { organization } = useOrganizationContext()

  const { collapsed, setCollapsed } = useViewport()

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const styles = {
    content: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      position: "relative",
    },
    contentOpen: {
      marginLeft: `${LEFT_NAV_EXPANDED_WIDTH}px`,
    },
    contentCollapsed: {
      marginLeft: `${LEFT_NAV_COLLAPSED_WIDTH}px`,
    },
  }

  const contentClass = collapsed ? styles.contentCollapsed : styles.contentOpen

  return (
    <>
      {organization ? (
        <NavigationDrawer
          isOpen={!collapsed}
          organization={organization}
          toggleDrawer={toggleCollapsed}
        />
      ) : null}
      <Box sx={[styles.content, contentClass]}>
        <Outlet context={{ collapsed }} />
      </Box>
    </>
  )
}
