import type { IFilter } from "./filter"

/**
 * A filter for one number
 *
 * @param value - The number
 * @returns The filter instance
 * @example
 * new SingleSelectNumberFilter()
 * new SingleSelectNumberFilter(1)
 * SingleSelectNumberFilter.fromQueryParamValue()
 * SingleSelectNumberFilter.fromQueryParamValue("")
 * SingleSelectNumberFilter.fromQueryParamValue("1")
 */
export default class SingleSelectNumberFilter
  implements IFilter<SingleSelectNumberFilter, number>
{
  value: number | null

  constructor(value?: number) {
    this.value = Number.isInteger(value) ? value : null
  }

  isEqualTo(filter: SingleSelectNumberFilter) {
    return this.value === filter.value
  }

  toQueryParamValue() {
    return this.value?.toString() ?? ""
  }

  static fromQueryParamValue(
    queryParamValue: string
  ): SingleSelectNumberFilter {
    const newValue: number = Number.parseInt(queryParamValue, 10)

    return new SingleSelectNumberFilter(
      Number.isInteger(newValue) ? newValue : undefined
    )
  }
}
