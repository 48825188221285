import React from "react"
import type { FC } from "react"
import { Outlet, useParams } from "react-router-dom"

import { useOrganization } from "@/services/organization"

import { Stack } from "@mui/material"

import Page404 from "../../../../../components/nav/page404/page404"
import { TamAdminPageHeader } from "../../tamAdminPageHeader/tamAdminPageHeader"

interface IOrgLayoutProps {
  isFlexibleHierarchyEnabled: boolean
}

export const OrgLayout: FC<IOrgLayoutProps> = ({
  isFlexibleHierarchyEnabled,
}) => {
  const params = useParams()
  const { orgId } = params
  const { organization } = useOrganization(orgId)

  return (
    <>
      {!organization && <Page404 />}
      {organization && (
        <>
          <TamAdminPageHeader
            isFlexibleHierarchyEnabled={isFlexibleHierarchyEnabled}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            organizationId={organization?.id}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            organizationName={organization?.name}
          />
          <Stack
            alignItems="stretch"
            direction={{ xs: "column", lg: "row" }}
            spacing={2}
            sx={{
              height: { lg: "592px" },
            }}
          >
            <Outlet />
          </Stack>
        </>
      )}
    </>
  )
}
