/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable react/require-default-props */
import React, { forwardRef } from "react"

import type { OutlinedTextFieldProps } from "@mui/material"
import { TextField as TextFieldMUI } from "@mui/material"

import { FieldWrapper } from "../../../../../components/form/fieldWrapper"
import { DEFAULT_FORM_FIELD_EMPTY_PLACEHOLDER } from "../../../../../utils/constants"

interface TamTextFieldProps extends OutlinedTextFieldProps {
  dataE2E?: string
  error?: boolean
  errorMessage?: string | null
  helperText?: string
  id?: string
  label: string
  name: string
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: any) => void
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  value?: string
}

export const TamTextField = forwardRef<HTMLDivElement, TamTextFieldProps>(
  (
    {
      label,
      name,
      readOnly,
      dataE2E,
      disabled,
      helperText,
      placeholder,
      style,
      error,
      errorMessage,
      required,
      onBlur,
      onChange,
      id,
      value,
      ...rest
    },
    ref
  ) => (
    <FieldWrapper
      errorMessage={error ? errorMessage : null}
      label={label}
      name={name}
      required={required}
    >
      <TextFieldMUI
        {...rest}
        ref={ref}
        data-e2e={dataE2E}
        disabled={disabled}
        error={!!error}
        fullWidth
        helperText={helperText}
        id={id ?? name}
        inputProps={{ readOnly, style }}
        margin="none"
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder ?? DEFAULT_FORM_FIELD_EMPTY_PLACEHOLDER}
        required={required}
        size="small"
        value={value}
        variant="outlined"
      />
    </FieldWrapper>
  )
)
