import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import type { ScaleBand, ScaleLinear } from "d3"
import { max, scaleBand, scaleLinear } from "d3"

import { Box } from "@mui/material"

import { useD3 } from "../../../hooks/useD3/useD3"
import { useResizeObserver } from "../../../hooks/useResizeObserver/useResizeObserver"
import { organizationDashboardGraphTheme } from "../../../modules/dashboard/models/dashboard"
import type { ScopeThreeEmissionsGraphItem } from "../../../modules/dashboard/models/dashboard"
import { translateEmissionDecimal } from "../../../utils/decimal"
import { titleize } from "../../../utils/formatters"
import type { GraphPerimeter } from "../../graph/types"
import "./scopeThreeWidget.scss"

const GRAPH_MIN_WIDTH = 310
const GRAPH_HEIGHT = 252
const MIN_HEIGHT_CHART = GRAPH_HEIGHT / 2
const RIGHT_LABEL_WIDTH = 68
const SEPARATION_LABEL_BAR = 4
const INITIAL_X_POSITION_TEXT = 100 // This is to make the text position start from right-outside of the chart to see data entering with animation
const MARGIN = { top: 20, right: 24, left: 24, bottom: 20 } // Refactor this part
const Y_SCALE_PADDING_INNER_MAX = 0.6
const Y_SCALE_PADDING_INNER_MIN = 0.5

const initialPerimeter: GraphPerimeter = {
  height: GRAPH_HEIGHT,
  width: 0,
}

const axisScaleFunctions = (data, width: number) => {
  // migration to strict mode batch disable
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const isAnyValueGreaterThanZero = data.find((d) => d.mtCo2E > 0)

  const yScaleRangeMax =
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    data.length > 2 ? GRAPH_HEIGHT : MIN_HEIGHT_CHART - MARGIN.top
  const yScalePaddingInner =
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    data.length === 1 ? Y_SCALE_PADDING_INNER_MAX : Y_SCALE_PADDING_INNER_MIN

  const xAxisScale = scaleLinear() // x is the scale for the x axis
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    .domain([0, max(data, (d: ScopeThreeEmissionsGraphItem) => d.mtCo2E)])
    .range([0, isAnyValueGreaterThanZero ? width : 0])

  const yAxisScale = scaleBand()
    .range([0, yScaleRangeMax]) // height of the chart
    // Mass lint disable
    // Mass lint disable
    // Mass eslint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
    .domain(data.map((d) => d.categoryName))
    .paddingInner(yScalePaddingInner) // padding between bars
    .align(0) // align the bars to the top

  return { xAxisScale, yAxisScale }
}

const addBar = (
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  g: any,
  yAxisScale: ScaleBand<string>,
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transition: any,
  xAxisScale: ScaleLinear<number, number>,
  fillColor: string
) =>
  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  g
    .append("rect") // bar
    .attr("x", 0) // x position of the bar
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    .attr("y", (d) => yAxisScale(d.categoryName)) // y position of the bar
    .attr("height", yAxisScale.bandwidth()) // height of bar
    .attr("width", 0) // width of bar (will be updated in the next step)
    .call((enterTransition) =>
      // Mass lint disable
      // Mass eslint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      enterTransition
        .transition(transition)
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        .attr("width", (d) => xAxisScale(d.mtCo2E))
    ) // width of bar
    .attr("fill", fillColor)

const addLabelRight = (
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  g: any,
  yAxisScale: ScaleBand<string>,
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transition: any,
  xAxisScale: ScaleLinear<number, number>,
  i18nService
) =>
  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  g
    .append("text") // text label for the bar (next to the bar)
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    .attr("x", (d) => xAxisScale(d.mtCo2E) + INITIAL_X_POSITION_TEXT) // x initial position of the text
    .attr(
      "y",
      (d) =>
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        yAxisScale(d.categoryName) +
        yAxisScale.bandwidth() / 2 +
        SEPARATION_LABEL_BAR
    ) // y position of the text (middle of the bar)
    .call((enterTransition) =>
      // Mass lint disable
      // Mass eslint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      enterTransition
        .transition(transition)
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        .attr("width", (d) => xAxisScale(d.mtCo2E))
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        .attr("x", (d) => xAxisScale(d.mtCo2E) + SEPARATION_LABEL_BAR)
    ) // width of bar
    .classed("right-legend", true)
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    .text((d) => translateEmissionDecimal(i18nService, d.mtCo2E))

// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addLabelTop = (g: any, yAxisScale: ScaleBand<string>, transition: any) =>
  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  g
    .append("text") // text label for the bar (top of the bar)
    .attr("x", -INITIAL_X_POSITION_TEXT)
    .call((enterTransition) =>
      // Mass lint disable
      // Mass eslint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      enterTransition.transition(transition).attr("x", 0)
    ) // width of bar
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    .attr("y", (d) => yAxisScale(d.categoryName) - SEPARATION_LABEL_BAR)
    .classed("top-legend", true)
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    .text((d) => titleize(d.categoryName))

const enterNode = ({
  enter,
  transition,
  xAxisScale,
  yAxisScale,
  i18nService,
  fillColor,
}: {
  enter
  fillColor: string
  i18nService
  transition
  xAxisScale: ScaleLinear<number, number>
  yAxisScale: ScaleBand<string>
}) => {
  // migration to strict mode batch disable
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const g = enter.append("g").classed("group-item", true)
  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  g.call(() => addBar(g, yAxisScale, transition, xAxisScale, fillColor))
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  g.call(() =>
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    addLabelRight(g, yAxisScale, transition, xAxisScale, i18nService)
  )
  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  g.call(() => addLabelTop(g, yAxisScale, transition))
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return g
}

const drawGraph = (
  selection,
  data,
  xAxisScale: ScaleLinear<number, number>,
  yAxisScale: ScaleBand<string>,
  i18nService,
  fillColor: string,
  animate = true
) => {
  const transitionDuration = animate ? 500 : 0
  // migration to strict mode batch disable
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const transition = selection.transition().duration(transitionDuration)
  // add bars
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  selection
    .selectAll("g")
    .data(data)
    .join((enter) =>
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      enterNode({
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        enter,
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        transition,
        xAxisScale,
        yAxisScale,
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        i18nService,
        fillColor,
      })
    )
}

interface IScopeThreeWidgetGraphProps {
  data: ScopeThreeEmissionsGraphItem[]
}

export const ScopeThreeWidgetGraph = ({
  data,
}: IScopeThreeWidgetGraphProps) => {
  const { i18n } = useTranslation()
  const elmRef = useRef()
  const { dimensions } = useResizeObserver(elmRef)
  const [perimeter, setPerimeter] = useState<GraphPerimeter>(initialPerimeter)
  const [hasRunOnce, setHasRunOnce] = useState<boolean>(false)

  // TODO: This may be able to extracted so each graph component wouldn't need this template here
  useEffect(() => {
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const width =
      !dimensions || dimensions?.width < GRAPH_MIN_WIDTH
        ? GRAPH_MIN_WIDTH
        : dimensions?.width
    setPerimeter({
      height: GRAPH_HEIGHT,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      width,
    })
  }, [dimensions])

  useD3(
    (selection, isInitialized, isResizeEvent) => {
      const { xAxisScale, yAxisScale } = axisScaleFunctions(
        data,
        perimeter.width - RIGHT_LABEL_WIDTH
      )

      if (isInitialized && isResizeEvent) {
        setHasRunOnce(true)
      }

      if (!isInitialized || (isInitialized && isResizeEvent)) {
        drawGraph(
          selection,
          data,
          xAxisScale,
          yAxisScale,
          i18n,
          organizationDashboardGraphTheme.scopeThree["Scope 3"],
          !hasRunOnce
        )
      }
    },
    elmRef,
    data,
    {
      height: perimeter.height + MARGIN.top,
      width: perimeter.width,
    },
    [i18n.language, hasRunOnce]
  )

  return <Box ref={elmRef} id="svgScopeThreeModule" width="100%" />
}
