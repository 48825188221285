import React from "react"

import type { Moment } from "moment"

import { useTheme } from "@mui/material"

import type { ISiteSummary, SiteSummary } from "../../../models/monthSummary"
import type Organization from "../../../models/organization"
import type { UnitName } from "../../../models/unit"
import { useSiteTotals } from "../../../modules/financialSummary/services/useSiteTotals/useSiteTotals"
import { useSiteTotalsByOrgUnit } from "../../../modules/financialSummary/services/useSiteTotalsByOrgUnit/useSiteTotalsByOrgUnit"
import { FeatureFlags, useFeature } from "../../../services"
import { LoadingSpinner } from "../../loadingSpinner"
import { SiteRow } from "./siteRow"

/**
 * @deprecated This UI is slated to be replaced and this component will be deleted
 */
export const SitesTable = ({
  groupId,
  groupName,
  isOpen,
  dateRange,
  whDisplayUnit,
  organization,
}: {
  dateRange: Moment[]
  groupId: string | null
  groupName: string | null
  isOpen: boolean
  organization: Organization
  whDisplayUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const theme = useTheme()

  const { isFeatureEnabled } = useFeature()
  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  const { siteTotalsByOrgUnit, isSiteTotalsByOrgUnitFetched } =
    useSiteTotalsByOrgUnit(
      { organizational_unit_id: groupId, organization_id: organization.id },
      {
        start: dateRange[0],
        end: dateRange[1],
      },
      { isDisabled: !groupId || !isUseFlexibleHierarchyEnabled || !isOpen }
    )

  const { siteTotals, isSiteTotalsFetched } = useSiteTotals(
    { department_id: groupId },
    {
      start: dateRange[0],
      end: dateRange[1],
    },
    { isDisabled: !groupId || isUseFlexibleHierarchyEnabled || !isOpen }
  )

  // migration to strict mode batch disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const orderedDepartmentSummaryData: ISiteSummary[] | undefined = siteTotals
    ?.filter(
      (group) =>
        group.departmentName === groupName ||
        (groupName === null && group.departmentName == null)
    )
    .sort((a, b) => b.costMajor - a.costMajor)

  // migration to strict mode batch disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const orderedGroupSummaryData: SiteSummary[] | undefined = siteTotalsByOrgUnit
    ?.filter(
      (group) =>
        group.organizationalUnitName === groupName ||
        (groupName === null && group.organizationalUnitName == null)
    )
    .sort((a, b) => b.costMajor - a.costMajor)

  const summaryData = isUseFlexibleHierarchyEnabled
    ? orderedGroupSummaryData
    : orderedDepartmentSummaryData

  const isTotalsFetched = isUseFlexibleHierarchyEnabled
    ? isSiteTotalsByOrgUnitFetched
    : isSiteTotalsFetched

  return (
    <div style={{ maxHeight: "475px", overflowY: "scroll" }}>
      {!isTotalsFetched && (
        <LoadingSpinner
          dataE2e="site-loading-spinner"
          style={{ minHeight: "200px" }}
        >
          Loading...
        </LoadingSpinner>
      )}
      {isTotalsFetched && (
        <table className="financial-summary__sites-table organization-summary__table">
          <thead
            style={{
              backgroundColor: theme.palette.common.white,
            }}
          >
            <tr className="sites-table__site-row">
              <th data-sites="name" style={{ width: "40%" }}>
                Site Detail
              </th>
              <th data-sites="cost" style={{ width: "20%" }}>
                Total Estimated Cost
              </th>
              <th data-sites="volume" style={{ width: "20%" }}>
                Total Volume
              </th>
              <th data-sites="avg-cost" style={{ width: "20%" }}>
                Avg. Energy Cost
              </th>
            </tr>
          </thead>
          <tbody>
            {summaryData?.map((siteSummaryDatum) => (
              <SiteRow
                // Mass lint disable
                // Mass lint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                key={siteSummaryDatum.siteId}
                dateRange={dateRange}
                hasBundled
                // Mass lint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                siteSummaryDatum={siteSummaryDatum}
                whDisplayUnit={whDisplayUnit}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
