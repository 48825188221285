// @ts-strict-ignore
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

import type { Moment } from "moment"

import { Box } from "@mui/material"

import type { GraphData } from "../../../../../components/graph/graphData"
import useFilters from "../../../../../hooks/useFilters/useFilters"
import type EnergyAllocation from "../../../../../models/energyAllocation"
import DateFilter from "../../../../../models/filter/dateFilter"
import type { LanguageRegion } from "../../../../../models/i18n"
import type Site from "../../../../../models/site"
import type { UnitName } from "../../../../../models/unit"
import { EnergyUnit, TIME_RANGES } from "../../../../../utils/constants"
import type { IFetchEnergyAllocationStats } from "../../../../../utils/spraypaintApi"
import { useForecastDays } from "../../../services/useForecastDays/useForecastDays"
import { useForecastHours } from "../../../services/useForecastHours/useForecastHours"
import EnergyMetrics from "./energyMetrics/energyMetrics"
import { EnergyProfile } from "./energyProfile/energyProfile"

interface DailySummaryPanelProps {
  endDate: Moment
  energyAllocations: EnergyAllocation[]
  isBundled: boolean
  isParentLoading: boolean
  site: Site
  siteEnergyAllocationStats: IFetchEnergyAllocationStats
  startDate: Moment
  timeRange: TIME_RANGES
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
}

const DailySummaryPanel = ({
  isParentLoading,
  isBundled,
  energyAllocations,
  wattUnit,
  siteEnergyAllocationStats,
  startDate,
  endDate,
  site,
  timeRange,
}: DailySummaryPanelProps) => {
  const { i18n } = useTranslation()

  const { setFilters } = useFilters({
    start: DateFilter,
    end: DateFilter,
  })

  const { forecastDays, isForecastDaysFetching } = useForecastDays(
    startDate,
    endDate,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    site?.locationId,
    i18n.language as LanguageRegion,
    {
      isDisabled: timeRange === TIME_RANGES.DAY,
    }
  )

  const { forecastHours, isForecastHoursFetching } = useForecastHours(
    startDate,
    endDate,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    site?.locationId,
    i18n.language as LanguageRegion,
    {
      isDisabled: timeRange !== TIME_RANGES.DAY,
    }
  )

  const isLoading = useMemo(
    () => isParentLoading || isForecastDaysFetching || isForecastHoursFetching,
    [isParentLoading, isForecastDaysFetching, isForecastHoursFetching]
  )

  const handleSelectedBar = useCallback(
    (selectedBar: GraphData) => {
      const { hours } = selectedBar
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!hours?.length) {
        return
      }
      const sortedHours = hours.sort((h1, h2) => h1.unix() - h2.unix())
      const firstHour = sortedHours[0].clone()
      const lastHour = sortedHours[sortedHours.length - 1].clone()
      const fullDayHours =
        lastHour.diff(firstHour, "hours") >= 23 ||
        (firstHour.isDST() !== lastHour.isDST() &&
          lastHour.diff(firstHour, "hours") >= 22)
      if (fullDayHours) {
        setFilters({
          start: new DateFilter(firstHour),
          end: new DateFilter(lastHour),
        })
      }
    },
    [setFilters]
  )

  return (
    // TODO: Remove className when we fully refactor energy profile
    <Box className="visualization-window">
      {isBundled && (
        <EnergyMetrics
          energyAllocationStats={siteEnergyAllocationStats}
          wattUnit={wattUnit}
        />
      )}
      <EnergyProfile
        axisUnit={EnergyUnit.WATTAGE}
        energyAllocations={energyAllocations}
        forecastDays={forecastDays}
        forecastHours={forecastHours}
        isLoading={isLoading}
        onHourSelect={handleSelectedBar}
        timeRange={timeRange}
        wattUnit={wattUnit}
      />
    </Box>
  )
}

export default DailySummaryPanel
