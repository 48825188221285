import type PhysicalDetail from "../../../../models/physcialDetails"
import type PhysicalDetailType from "../../../../models/physical-detail-types"
import type Site from "../../../../models/site"
import { generateShchemaDefaultValues } from "./generate-schema-default-values"

export const buildPhysicalDetailsDefaultValues = (
  physicalDetailTypes: PhysicalDetailType[],
  physicalDetails?: Site["physicalDetails"]
): Pick<PhysicalDetail, "details" | "physicalDetailTypeId">[] => {
  const defaultPhysicalDetails: Pick<
    PhysicalDetail,
    "details" | "physicalDetailTypeId"
  >[] = []
  for (const detailType of physicalDetailTypes) {
    const detail: Pick<PhysicalDetail, "details" | "physicalDetailTypeId"> = {
      physicalDetailTypeId: detailType.id,
      details: {},
    }
    // find site physical detail with same id
    const sitePhysicalDetail = physicalDetails?.find(
      (physicalDetail) =>
        physicalDetail.physicalDetailTypeId?.toString() ===
        detailType.id?.toString()
    )
    if (
      detailType.schema.type === "object" &&
      detailType.schema.properties &&
      sitePhysicalDetail
    ) {
      const details = generateShchemaDefaultValues(
        detailType.schema.properties,
        sitePhysicalDetail.details
      )
      detail.details = details
    }
    defaultPhysicalDetails.push(detail)
  }
  return defaultPhysicalDetails
}
