import moment from "moment"
import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

export enum ReportType {
  DayAhead = "PRC_LMP",
  Realtime = "PRC_RTM_LAP",
}

@Model()
export default class Price extends ApplicationRecord {
  public static jsonapiType = "prices"

  @Attr() public readonly id: string

  @Attr() public hour: moment.Moment

  @Attr() public lmp: number

  @Attr() public lmpMajor: string

  @Attr() public reportType: ReportType
}
