import { useMemo } from "react"

import type { Schemas } from "@/services/types"

import { buildPlanName } from "../../../utils/build-plan-name"
import { calculateEmissionSavingsPercentage } from "../../../utils/calculateEmissionSavingsPercentage"
import type { RowData } from "../recommended-plans-table.types"

const buildRow = (
  plan: Schemas["BuildSimResponse"],
  isPlanRecommended: boolean
): RowData => ({
  id: plan.id,
  site_id: plan.site_id,
  name: buildPlanName(plan) ?? [],
  capital_cost: plan.capital_cost,
  payback_period: plan.payback_period,
  emission_savings: calculateEmissionSavingsPercentage(
    plan.emissions_diff,
    plan.base_total_emissions
  ),
  plan,
  emissions_diff: plan.emissions_diff,
  portfolio_impact: plan.portfolio_impact,
  isRecommended: isPlanRecommended,
})

export const useBuildRowData = (
  allPlans: Schemas["BuildSimResponse"][],
  recommendedPlans: Schemas["BuildSimResponse"][]
) => {
  const rows = useMemo(() => {
    const recommendedRows: RowData[] = []
    const computedRows: RowData[] = []
    for (const plan of allPlans) {
      const isPlanRecommended = recommendedPlans.some(
        (recPlan) => recPlan.id === plan.id
      )
      const row = buildRow(plan, isPlanRecommended)
      if (isPlanRecommended) {
        recommendedRows.push(row)
      } else {
        computedRows.push(row)
      }
    }

    for (const plan of recommendedPlans) {
      const isPlanInRecommended = recommendedRows.some(
        (row) => row.id === plan.id
      )
      if (!isPlanInRecommended) {
        const row = buildRow(plan, true)
        recommendedRows.push(row)
      }
    }

    return [...recommendedRows, ...computedRows]
  }, [allPlans, recommendedPlans])

  return rows
}
