import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Route, Routes } from "react-router-dom"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import { FeatureFlags, useFeature } from "@/services"

import { ErrorBoundaryFallback } from "../../components/error-boundary/error-boundary-fallback"
import { logError } from "../../components/error-boundary/error-boundary-fallback-utils"
import Page404 from "../../components/nav/page404/page404"
import { RepPath } from "./enums"
import { RepComplianceRoute } from "./pages/rep-compliance-route/rep-compliance-route"
import { RepIndexRoute } from "./pages/rep-index-route/rep-index-route"
import { RepPerformanceRoute } from "./pages/rep-performance-route/rep-performance-route"
import { RepResultsRoute } from "./pages/rep-results-route/rep-results-route"

/**
 * All routes included under `/rep`
 */
export const RepRoute = () => {
  const { organization } = useOrganizationContext()
  const { isFeatureEnabled } = useFeature()

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return isFeatureEnabled(FeatureFlags.RECOMMENDATIONS_REP, organization) ? (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={logError}>
      <Routes>
        <Route element={<RepIndexRoute />} index />
        <Route element={<RepResultsRoute />} path={RepPath.Results}>
          <Route element={<RepComplianceRoute />} path={RepPath.Compliance} />
          <Route element={<RepPerformanceRoute />} path={RepPath.Performance} />
        </Route>
        <Route element={<Page404 />} path="*" />
      </Routes>
    </ErrorBoundary>
  ) : (
    <Routes>
      <Route element={<Page404 />} path="*" />
    </Routes>
  )
}
