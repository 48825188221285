import React from "react"
import { useTranslation } from "react-i18next"

import {
  CurrencySymbolPosition,
  DEFAULT_CURRENCY_PLACEHOLDER,
} from "../../../models/currency"
import { useUserSettings } from "../../../modules/settings"
import {
  getCurrencySymbol,
  getCurrencySymbolPosition,
} from "../../../utils/currency"
import type { IDecimalFormat } from "../../decimal/decimal/decimal"
import type { IDecimalWithPlaceholderProps } from "../../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { DecimalWithPlaceholder } from "../../decimal/decimalWithPlaceholder/decimalWithPlaceholder"

const defaultCurrencyFormatConfig: IDecimalFormat = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

type ICurrencyWithPlaceholderProps = Omit<
  IDecimalWithPlaceholderProps,
  "placeholder"
>

/**
 * A component that properly formats and internationalizes the provided currency
 *
 * @param props - The component properties
 * @param props.value - The currency
 * @param props.format - The format configuration for the currency
 * @param props.replaceZeroWithPlaceholder - Whether to consider 0 as an empty value
 * @param props.useAccountingFormatWhenNegative - Whether to wrap negative currencies in parenthesis or not
 * @param props.unit - The unit to display with the currency
 * @example
 * return (
 *   <CurrencyWithPlaceholder value={waterCost} format={{
 *     precision: 0
 *   }} />
 * )
 */
export const CurrencyWithPlaceholder = ({
  value,
  format,
  replaceZeroWithPlaceholder,
  unit,
  useAccountingFormatWhenNegative,
}: ICurrencyWithPlaceholderProps) => {
  const { i18n } = useTranslation()
  const { currency } = useUserSettings()

  const currencyFormatConfig: IDecimalFormat = {
    ...defaultCurrencyFormatConfig,
    ...format,
    style: "currency",
    currency,
  }

  // Ensure precision overrides any fraction digits
  if (Number.isFinite(format?.precision)) {
    delete currencyFormatConfig.minimumFractionDigits
    delete currencyFormatConfig.maximumFractionDigits
  }

  // Internationalize the currency placeholder
  // Some languages display the currency symbol on the right
  const currencySymbol: string = getCurrencySymbol(i18n.language, currency)
  const currencySymbolPosition: CurrencySymbolPosition =
    getCurrencySymbolPosition(i18n.language, currency)
  const currencyPlaceholderLeft = `${currencySymbol}${DEFAULT_CURRENCY_PLACEHOLDER}`
  const currencyPlaceholderRight = `${DEFAULT_CURRENCY_PLACEHOLDER}${currencySymbol}`
  const currencyPlaceholder =
    currencySymbolPosition === CurrencySymbolPosition.Left
      ? currencyPlaceholderLeft
      : currencyPlaceholderRight

  return (
    <DecimalWithPlaceholder
      format={currencyFormatConfig}
      placeholder={currencyPlaceholder}
      replaceZeroWithPlaceholder={replaceZeroWithPlaceholder}
      unit={unit}
      useAccountingFormatWhenNegative={useAccountingFormatWhenNegative}
      value={value}
    />
  )
}
