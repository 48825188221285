import React from "react"

import { CurrencyWithPlaceholder } from "../currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { OverflowTooltip } from "../overflowTooltip/overflowTooltip"

export const BundledSiteFinancialSummaryCardItem = ({
  feeName,
  feeUsage,
  feeRate,
  feeTotal,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  feeType,
}: {
  feeName: string
  feeRate: number
  feeTotal: number
  feeType: string
  feeUsage: number
}) => (
  <div className="financial-summary-panel__item">
    <div className="financial-summary-panel__item-name">
      <OverflowTooltip>
        <p className="financial-summary-panel__item-title-text paragraph--small paragraph--small__regular">
          {feeName}
        </p>
      </OverflowTooltip>
    </div>
    <p className="financial-summary-panel__item-usage paragraph--small paragraph--small__regular">
      {feeUsage}
    </p>
    <p className="financial-summary-panel__item-rate paragraph--small paragraph--small__regular">
      {feeRate}
    </p>
    <p className="financial-summary-panel__item-total paragraph--small paragraph--small__regular">
      <CurrencyWithPlaceholder value={feeTotal} />
    </p>
    <div className="financial-summary-panel__item-command" />
  </div>
)
