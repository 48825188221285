import React, { useState } from "react"
import type { FC } from "react"
import type { SubmitHandler } from "react-hook-form"

import { Form, FormTextField, SubmitButton, useForm } from "@/nzds"
import { DialogTitle } from "@/nzds/feedback/dialog-title/dialog-title"
import { useSendContactEmail } from "@/services/email/useSendContactEmail"

import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material"

import { If } from "../if/if"
import type {
  ContactDialogProps,
  ContactDialogType,
} from "./contact-dialog.types"
import { contactSchema } from "./contact.schema"
import type { ContactSchema } from "./contact.schema"

const typeToTitle: Record<ContactDialogType, string> = {
  bps: "Request a Building Performance Standard",
  decarbonize: "Contact Us",
  support: "Contact Support",
} as const

const typeToDescription: Record<ContactDialogType, string> = {
  bps: "If you’d like to see a building performance standard that has not yet been built out by NZero, tell us below. We’re always looking to improve the reach of our product and your feedback is critical to make sure we’re delivering the most value to our users.",
  decarbonize:
    "If you have questions or would like to schedule a demo, please contact us below and an NZero team member will be happy to connect with you.",
  support:
    "Please let us know how we can assist you. Once submitted, someone from our team will reach out to you shortly.",
} as const

export const ContactDialog: FC<ContactDialogProps> = ({
  onClose,
  open,
  type,
}) => {
  const [isSent, setIsSent] = useState<boolean>(false)

  const defaultFormValues: ContactSchema = {
    message: "",
    type,
  }

  const form = useForm({
    schema: contactSchema,
    defaultValues: defaultFormValues,
  })

  const { sendContactEmail, sendContactEmailIsLoading } = useSendContactEmail({
    onError: (error) => {
      console.error(error)
    },
    onSuccess: () => {
      setIsSent(true)
    },
  })

  const handleClose = () => {
    onClose()
  }

  const handleSubmit: SubmitHandler<ContactSchema> = (
    value: ContactSchema
  ): void => {
    void sendContactEmail({ message: value.message, type: value.type })
  }

  const handleExited = (): void => {
    form.reset()
    setIsSent(false)
  }

  return (
    <Dialog
      aria-describedby="contact-dialog-description"
      aria-labelledby="contact-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      <If condition={!isSent}>
        <DialogTitle id="contact-dialog-title" onClose={handleClose}>
          {typeToTitle[type]}
        </DialogTitle>
        <Form<ContactSchema> form={form} onSubmit={handleSubmit}>
          <DialogContent id="contact-dialog-description">
            <Typography mb={3}>{typeToDescription[type]}</Typography>
            <FormTextField<ContactSchema>
              fullWidth
              id="message"
              label=""
              maxRows={12}
              minRows={4}
              multiline
              name="message"
              placeholder="Anything you’d like to share"
            />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              disabled={sendContactEmailIsLoading}
              loading={sendContactEmailIsLoading}
            >
              Submit
            </SubmitButton>
          </DialogActions>
        </Form>
      </If>

      <If condition={isSent}>
        <DialogTitle id="contact-dialog-title" onClose={handleClose}>
          Submission Successful
        </DialogTitle>
        <DialogContent id="contact-dialog-description">
          <Typography mb={2}>
            Your message has been successfully sent. Someone from the NZero team
            will reach out shortly!
          </Typography>
        </DialogContent>
      </If>
    </Dialog>
  )
}
