export enum Scope {
  None = -1,
  One = 1,
  Two = 2,
  Three = 3,
}

export interface IScopeCategory {
  id: number
  name: string
}

export interface IScopeSubcategory extends IScopeCategory {
  categoryId: number
}

export interface ScopeEmissionBase {
  month: number
  mtCo2E: number
  year: number
}
