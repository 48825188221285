import type { FC } from "react"
import React from "react"

import type { CardProps } from "@mui/material"
import { Card } from "@mui/material"
import { styled } from "@mui/material/styles"

import type { StatCardProps } from "./stat-card.types"

const StyledStatCard = styled(Card)<CardProps>(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  gap: theme.spacing(3),
  display: "flex",
  alignItems: "stretch",
  height: theme.typography.pxToRem(98),
}))

export const StatCard: FC<StatCardProps> = ({ children, sx }) => (
  <StyledStatCard sx={sx} variant="contained">
    {children}
  </StyledStatCard>
)
