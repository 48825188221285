// @ts-strict-ignore

/* eslint-disable react/require-default-props */
import React, { forwardRef } from "react"

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material"

import type { SelectFieldProps } from "./select-field.types"

/**
 * Select field that composes formcontrol, helpertext, formlabel, the same way text field does
 *
 * ```tsx
 * <SelectField
 *   name="test"
 *   options={[{ value: 'value 1', label: 'label 1}]}
 *   onChange={onChange}
 *   value='value 1'
 * />
 * ```
 */
export const SelectField = forwardRef<HTMLDivElement, SelectFieldProps>(
  (
    {
      disabled,
      error,
      fullWidth,
      helperText,
      id,
      label,
      options,
      inputRef,
      name,
      value,
      placeholder,
      sx,
      renderValue,
      multiple,
      required,
      ...rest
    },
    ref
  ) => {
    let renderValueFn = (selected?: string | string[]) => {
      if (!selected && placeholder) {
        return (
          <Box color="text.disabled" component="em">
            {placeholder}
          </Box>
        )
      }
      if (selected && selected.length === 0 && placeholder) {
        return (
          <Box color="text.disabled" component="em">
            {placeholder}
          </Box>
        )
      }
      if (!selected && !placeholder) {
        return ""
      }

      if (multiple && Array.isArray(selected)) {
        return selected
          .map((val) => options.find((option) => option.value === val)?.label)
          .join(", ")
      }

      return options.find((option) => option.value === selected)?.label
    }
    if (renderValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      renderValueFn = renderValue as any
    }
    return (
      <FormControl
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        sx={sx}
      >
        <InputLabel
          data-testid={id ?? name}
          id={id ?? name}
          required={required}
        >
          {label}
        </InputLabel>
        <Select
          {...rest}
          ref={ref}
          displayEmpty
          id={`${id ?? name}-select`}
          input={<OutlinedInput label={label} />}
          inputRef={inputRef}
          labelId={id ?? name}
          multiple={multiple}
          name={name}
          renderValue={renderValueFn}
          value={value}
          variant="outlined"
        >
          {placeholder ? (
            <MenuItem value="">
              <Box color="text.disabled" component="em">
                {placeholder}
              </Box>
            </MenuItem>
          ) : null}

          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    )
  }
)
