/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from "react"

import type { Moment } from "moment"

import type { DateTimePickerProps } from "@mui/x-date-pickers-pro"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment"

import { FieldWrapper } from "../../../../../components/form/fieldWrapper"

interface TamDateTimePickerProps extends DateTimePickerProps<Moment> {
  dataE2E?: string
  error?: boolean
  errorMessage?: string | null
  label: string
  name: string
  onBlur?: () => void
  onChange?: (
    value: Moment | null,
    context?: Parameters<Parameters<typeof DateTimePicker>[0]["onChange"]>[1]
  ) => void
  readOnly?: boolean
  required?: boolean
  value?: Moment | null
}

export const TamDateTimePicker = forwardRef<
  HTMLInputElement,
  TamDateTimePickerProps
>(
  (
    {
      label,
      name,
      readOnly,
      dataE2E,
      disableFuture,
      disablePast,
      disabled,
      error,
      errorMessage,
      required,
      onBlur,
      onClose,
      onChange,
      value,
    },
    ref
  ) => (
    <div style={{ width: "100%" }}>
      <FieldWrapper
        errorMessage={error ? errorMessage : null}
        label={label}
        name={name}
        required={required}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            data-e2e={dataE2E}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            inputRef={ref}
            onChange={onChange}
            onClose={onClose}
            readOnly={readOnly}
            slotProps={{
              textField: {
                fullWidth: true,
                error,
                onBlur,
              },
              actionBar: {
                actions: ["clear", "cancel", "accept"],
              },
            }}
            value={value}
          />
        </LocalizationProvider>
      </FieldWrapper>
    </div>
  )
)
