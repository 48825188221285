import type { ITableColumn, ITableData } from "../models/table"
import { CSV_LIMITER, CSV_NEW_LINE } from "./constants"

/**
 * Converts tabular data to a CSV
 *
 * @param data - The tabular data
 * @returns - The CSV
 * @example
 * convertTabularDataToCSV(tabularData)
 */
// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertTabularDataToCSV = (data: ITableData<any>): string => {
  if (!data?.columns?.length) {
    return ""
  }

  return (
    [
      // Enclose header titles in quotes to preserve commas
      data.columns?.map((header) => `"${header.title}"`),
      ...data.rows.map((row) => {
        const columnKeys = data.columns.map((column) => column.key)

        return (
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          Object.entries(row)
            // Filter out the row data without a corresponding column
            .filter((entry) => {
              const key: string = entry[0]

              return columnKeys.includes(key)
            })
            // Sort the row data by column order
            .sort(([cellAKey], [cellBKey]) => {
              // Mass eslint disable @typescript-eslint/no-explicit-any
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const cellAColumn: ITableColumn<any> | undefined =
                data.columns?.find((column) => column.key === cellAKey)
              // Mass eslint disable @typescript-eslint/no-explicit-any
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const cellBColumn: ITableColumn<any> | undefined =
                data.columns?.find((column) => column.key === cellBKey)

              return (cellAColumn?.order ?? 0) - (cellBColumn?.order ?? 0)
            })
            // Transform the row data to CSV friendly values
            .map((entry) => {
              const value = entry[1]

              // Enclose string cell values in quotes to preserve commas
              if (typeof value === "string") {
                return `"${value.toString()}"`
              }
              if (value instanceof Date) {
                return value.toISOString()
              }
              if (Number.isNaN(value)) {
                return ""
              }
              return value ?? ""
            })
        )
      }),
    ]
      // Separate values with commas and turn into a string
      .map((row) => row.join(CSV_LIMITER))
      // Separate rows with new lines and turn into a string
      .join(CSV_NEW_LINE)
  )
}

/**
 * Creates an anchor used for downloading
 *
 * @param fileData - The file data
 * @param fileName - The file name
 * @example
 * createDownloadElement(new Blob([csv], { type: "text/csv" }), "My Report")
 */
const createDownloadElement = (
  fileData: Blob | MediaSource,
  fileName: string
): HTMLAnchorElement => {
  const anchor: HTMLAnchorElement = document.createElement("a")
  anchor.href = window.URL.createObjectURL(fileData)
  anchor.download = `${fileName}.csv`

  return anchor
}

/**
 * Clicks and removes an anchor used for downloading
 *
 * @param anchor - The anchor
 * @example
 * invokeDownloadAndCleanup(anchor)
 */
const invokeDownloadAndCleanup = (anchor: HTMLAnchorElement): void => {
  anchor.click()
  window.URL.revokeObjectURL(anchor.href)
  anchor.remove()
}

/**
 * Downloads a CSV
 *
 * @param data - The data
 * @param filename - The name of the file
 * @example
 * downloadCsvNew(data, "My Report")
 */
export const downloadCsvNew = (
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: ITableData<any>,
  filename: string
): void => {
  const csv: string = convertTabularDataToCSV(data)
  const downloadElement: HTMLAnchorElement = createDownloadElement(
    new Blob([csv], { type: "text/csv" }),
    filename
  )

  invokeDownloadAndCleanup(downloadElement)
}
