import React, { useEffect, useState } from "react"
import type { FC } from "react"
import { Outlet } from "react-router-dom"

import { useTimeout } from "@/nzds/utils/use-timeout/use-timeout"
import { useAuthentication } from "@/services/authentication"
import { useFeature } from "@/services/feature"
import { animated, easings, useSprings } from "@react-spring/web"

import { NzeroSymbolLeft } from "../../logo/nzero-symbol-left"
import { NzeroSymbolRight } from "../../logo/nzero-symbol-right"

const minLoadingTime = 1000

export const AppLoader: FC = () => {
  const { isFeaturesFetched } = useFeature()

  const { isAuthenticatedUserFetched, isSessionValidFetched } =
    useAuthentication()

  const [minLoadingTimeReached, setMinLoadingTimeReached] =
    useState<boolean>(false)

  const isReady: boolean =
    minLoadingTimeReached &&
    isFeaturesFetched &&
    isAuthenticatedUserFetched &&
    isSessionValidFetched

  const [isAnimationComplete, setIsAnimationComplete] = useState<boolean>(false)

  const springsCount = 2
  const [springs, springsApi] = useSprings(
    springsCount,
    (i) => ({
      from: { opacity: 0, transform: "translateY(60px)" },
      to: { opacity: 1, transform: "translateY(0px)" },
      delay: 200 + i * 40,
      config: (key) => {
        if (key === "opacity") {
          return { duration: 180, easing: easings.easeOutExpo }
        }

        return { duration: 180, easing: easings.easeOutQuart }
      },
    }),
    [springsCount]
  )

  useTimeout(() => {
    setMinLoadingTimeReached(true)
  }, minLoadingTime)

  useEffect(() => {
    if (isReady) {
      springsApi.start((i) => ({
        to: { opacity: 0, transform: "translateY(-60px)" },
        delay: i * 40,
        config: (key) => {
          if (key === "opacity") {
            return { duration: 280, easing: easings.easeInExpo }
          }

          return { duration: 280, easing: easings.easeInQuart }
        },
        onRest: () => {
          if (i === springsCount - 1) {
            setIsAnimationComplete(true)
          }
        },
      }))
    }
  }, [isReady, springsApi])

  return !isAnimationComplete ? (
    <div
      style={{
        alignItems: "center",
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <animated.div style={springs[1]}>
          <NzeroSymbolLeft
            height={112}
            style={{
              transform: "translateY(14px)",
            }}
          />
        </animated.div>
        <animated.div style={springs[0]}>
          <NzeroSymbolRight
            height={112}
            style={{
              transform: "translateY(-14px)",
            }}
          />
        </animated.div>
      </div>
    </div>
  ) : (
    <Outlet />
  )
}
