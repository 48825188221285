import type { i18n } from "i18next"

import { CurrencySymbolPosition } from "../models/currency"
import type { CurrencyCode } from "../models/currencyCode"
import type {
  IDecimalFormat,
  IDecimalOptions,
  IDecimalWithPlaceholderOptions,
} from "./decimal"
import { translateDecimal, translateDecimalWithPlaceholder } from "./decimal"

const defaultCurrencyFormatConfig: IDecimalFormat = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

const getCurrencyFormatConfig = (
  currency: CurrencyCode,
  format?: IDecimalFormat | null
): IDecimalFormat => {
  const formatConfig: IDecimalFormat = {
    ...defaultCurrencyFormatConfig,
    ...format,
    style: "currency",
    currency,
  }

  if (Number.isFinite(formatConfig.precision)) {
    delete formatConfig.minimumFractionDigits
    delete formatConfig.maximumFractionDigits
  }

  return formatConfig
}

export const getCurrencySymbol = (
  locale: string,
  currency: CurrencyCode
): string => {
  const currencySymbol: string = Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  })
    .formatToParts(0)
    .find((part) => part.type === "currency")?.value

  return currencySymbol
}

export const getCurrencySymbolPosition = (
  locale: string,
  currency: CurrencyCode
): CurrencySymbolPosition => {
  const currencyIndex: number = Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  })
    .formatToParts(0)
    .findIndex((part) => part.type === "currency")

  return currencyIndex === 0
    ? CurrencySymbolPosition.Left
    : CurrencySymbolPosition.Right
}

export const translateCurrency = (
  i18nService: i18n,
  currency: CurrencyCode,
  value: number | string | null | undefined,
  format?: IDecimalFormat | null,
  options?: IDecimalOptions
): string => {
  const currencyFormatConfig: IDecimalFormat = getCurrencyFormatConfig(
    currency,
    format
  )

  return translateDecimal(i18nService, value, currencyFormatConfig, options)
}

export const translateCurrencyWithPlaceholder = (
  i18nService: i18n,
  currency: CurrencyCode,
  value: number | string | null | undefined,
  format?: IDecimalFormat | null,
  options?: IDecimalWithPlaceholderOptions
): string => {
  const currencyFormatConfig: IDecimalFormat = getCurrencyFormatConfig(
    currency,
    format
  )

  return translateDecimalWithPlaceholder(
    i18nService,
    value,
    currencyFormatConfig,
    options
  )
}
