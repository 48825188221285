import type { ReactElement } from "react"
import React from "react"

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"

export interface ICheckbox {
  label: string
  value: string
}
interface CheckboxListProps {
  onChange: (options: string[]) => void
  options: ICheckbox[]
  value: string[]
}

/**
 * A generic component that renders a checkbox list.
 * Maintains a list of checked options and calls onChange provided by the parent
 * when the list changes.
 *
 * @param props - The component properties
 * @param props.value - an array of values that are currently checked
 * @param props.onChange - a callback function that is called when one of the checkboxes is clicked
 * @param props.options - an array of objects that contain the label and value of each checkbox
 * @example
 * return (
 *   <CheckboxList
 *      value=["1", "2"]
 *      onChange={onScopeChange}
 *      options={scopeCheckboxes}
 *    />
 * )
 */
export const CheckboxList: React.FC<CheckboxListProps> = ({
  value,
  onChange,
  options,
}: CheckboxListProps): ReactElement => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = [...value]
    if (event.target.checked) {
      newValues.push(event.target.value)
    } else {
      const index = newValues.indexOf(event.target.value)
      newValues.splice(index, 1)
    }
    onChange(newValues)
  }

  return (
    <FormGroup>
      {options.map((checkbox, index) => (
        <FormControlLabel
          key={checkbox.value}
          control={
            <Checkbox
              checked={value.includes(checkbox.value)}
              data-testid={`checkbox-${index}`}
              id={`${checkbox.value.toString()}-${index.toString()}`}
              onChange={handleOnChange}
              value={checkbox.value}
            />
          }
          label={checkbox.label}
        />
      ))}
    </FormGroup>
  )
}
