import React from "react"
import { useFormState } from "react-hook-form"

import { Button } from "@mui/material"

interface TamSubmitButtonProps {
  dataE2E?: string
  submitting?: boolean
}

export const TamSubmitButton = (props: TamSubmitButtonProps) => {
  const { dataE2E, submitting } = props

  const { isDirty, isValid } = useFormState()

  return (
    <Button
      data-e2e={dataE2E}
      disabled={!isDirty || !isValid || submitting}
      type="submit"
      variant="contained"
    >
      Save
    </Button>
  )
}
