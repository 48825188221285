import type { Organization } from "@/models/organization"
import { client } from "@/services/client"
import type { Paths } from "@/services/types"

export interface UpdateGoalParams {
  parameters: Paths["/api/v1/recommendations/goals/{goal_id}"]["put"]["parameters"] & {
    organization_id: Organization["id"]
  }
  requestBody: Paths["/api/v1/recommendations/goals/{goal_id}"]["put"]["requestBody"]["content"]["application/json"] & {
    organization_id: Organization["id"]
  }
}

export const updateGoal = async ({
  requestBody,
  parameters,
}: UpdateGoalParams) => {
  const { data, error } = await client.PUT(
    "/api/v1/recommendations/goals/{goal_id}",
    {
      body: requestBody,
      params: parameters,
    }
  )

  if (data) {
    return data
  }
  throw error as Error
}
