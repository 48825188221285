import EtagHour from "../../../../models/etagHour"
import ServiceHour from "../../../../models/serviceHour"
import type Site from "../../../../models/site"
import { parseFloatMetaStatSum } from "../../../../utils"
import { LBS_PER_METRIC_TON } from "../../../../utils/constants"

interface IFetchEmissionsAndEnergyVolumeWithSiteStats {
  dayStart: string
  site: Site
  yearStart: string
}

export const fetchDirectEmissionsAndEnergyVolume = ({
  site,
  yearStart,
  dayStart,
}: IFetchEmissionsAndEnergyVolumeWithSiteStats) => {
  const fetchCarbonBasedAndFreeStats = EtagHour.carbonBasedAndFreeStats({
    site,
    yearStart,
    dayStart,
  })
  const fetchTotals = ServiceHour.carbonFreeStats({ site, yearStart, dayStart })
  return Promise.all([fetchCarbonBasedAndFreeStats, fetchTotals]).then(
    ([fetchedCarbonBasedAndFreeTotals, fetchedTotals]) => ({
      ytdCarbonEmissionsInMtCo2e:
        parseFloatMetaStatSum(
          // Mass lint disable
          // Mass lint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-condition
          fetchedTotals?.meta?.stats?.carbon_equivalent_emissions?.sum
        ) / LBS_PER_METRIC_TON,
      ytdCarbonBasedKwh: parseFloatMetaStatSum(
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        fetchedCarbonBasedAndFreeTotals?.carbon_based_kwh?.sum
      ),
      ytdCarbonFreeKwh: parseFloatMetaStatSum(
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        fetchedCarbonBasedAndFreeTotals?.carbon_free_kwh?.sum
      ),
    })
  )
}
