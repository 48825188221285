import type { JSONSchema6 } from "json-schema"
import type { Entries } from "type-fest"

import type PhysicalDetail from "../../../../models/physcialDetails"

export function generateShchemaDefaultValues(
  properties: JSONSchema6["properties"],
  details?: PhysicalDetail["details"]
) {
  if (!properties) {
    return {}
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultValues: Record<string, any> = {}

  const entries = Object.entries(properties) as Entries<typeof properties>
  for (const [key, value] of entries) {
    let defaultValue

    if (typeof value !== "boolean") {
      if (
        value.type === "string" ||
        value.type === "number" ||
        value.type === "integer"
      ) {
        if (value.type !== "string") {
          // integer or number
          // convert numeric to string explicitly to mirror the opposite happening on submit
          // this is reliant on the fact that react-number-format is aware of numeric strings as well
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          defaultValue = `${details?.[key] ?? ""}`
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          defaultValue = details?.[key] ?? ""
        }
      }
      if (value.type === "boolean") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultValue =
          details && key in details
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              details[key]
            : false
      }
      if (value.type === "object") {
        defaultValue = generateShchemaDefaultValues(
          value.properties,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          details?.[key]
        )
      }
      if (
        value.type === "array" &&
        value.items &&
        typeof value.items !== "boolean" &&
        !Array.isArray(value.items)
      ) {
        if (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          Array.isArray(details?.[key])
        ) {
          const itemsProperties = value.items.properties
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          defaultValue = details[key].map((sPd: any) =>
            generateShchemaDefaultValues(itemsProperties, sPd)
          )
        } else {
          defaultValue = []
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      defaultValues[key] = defaultValue
    }
  }

  return defaultValues
}
