/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import type { FieldValues, Path } from "react-hook-form"
import { useController } from "react-hook-form"

import type { OutlinedTextFieldProps } from "@mui/material"

import { TamTextField } from "../baseInputs/tamTextField"

interface TamFormTextFieldProps<T>
  extends Omit<OutlinedTextFieldProps, "variant"> {
  capitalize?: boolean
  dataE2E?: string
  helperText?: string
  label: string
  name: Path<T>
  placeholder?: string
  readOnly?: boolean
}

export const TamFormTextField = <T extends FieldValues>(
  props: TamFormTextFieldProps<T>
) => {
  const {
    label,
    name,
    readOnly,
    dataE2E,
    helperText,
    placeholder,
    required,
    disabled,
    capitalize,
  } = props

  const {
    field: { ref, onBlur, onChange, value },
    fieldState: { error },
  } = useController({ name })

  return (
    <TamTextField
      ref={ref}
      dataE2E={dataE2E}
      disabled={disabled}
      error={!!error}
      errorMessage={error?.message}
      helperText={helperText}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      style={capitalize ? { textTransform: "capitalize" } : {}}
      value={value ?? ""}
      variant="outlined"
    />
  )
}
