import type { WhereClause } from "spraypaint"

import type { ISiteSummary } from "../../../../models/monthSummary"
import MonthSummary from "../../../../models/monthSummary"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"
import type { IDepartmentSummaryDto } from "../../../dashboard/services/useOrgSummaryStats/useOrgSummaryStatsUtils"

interface ISiteSummaryDto extends IDepartmentSummaryDto {
  site_id: number
  site_name: string
}

/**
 * This returns the site totals based on the where clause.
 * @deprecated This service will be deleted when we remove the use flexibleHierarchy flag
 *
 * @param where - The where clause to filter the totals by
 * @returns The filtered totals
 *
 * @example
 * ```tsx
 * import { fetchSiteTotals } from '../services/useSiteTotals/useSiteTotalsUtils'
 *
 * const siteTotals = await fetchSiteTotals({ department_id: 3, start_date: '2021-01-01', end_date: '2021-01-31' })
 * ```
 */
const fetchSiteTotals = async (where: WhereClause) =>
  MonthSummary.where(where)
    .stats({
      site_totals: "calculate",
    })
    .per(0)
    .all()
    .then((result) => {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data: ISiteSummary[] = snakeCaseToCamelCase(
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        result.meta.stats.site_totals.calculate.map(
          (siteTotal: ISiteSummaryDto) =>
            // Transform numbers as strings into numbers
            ({
              ...siteTotal,
              confirm_cost_minor: Number.parseFloat(
                siteTotal.confirm_cost_minor
              ),
              cost_major: Number.parseFloat(siteTotal.cost_major),
              energy_cost_minor: Number.parseFloat(siteTotal.energy_cost_minor),
              imbalance_cost_minor: Number.parseFloat(
                siteTotal.imbalance_cost_minor
              ),
              metered_kwh: Number.parseFloat(siteTotal.metered_kwh),
              scheduling_cost_minor: Number.parseFloat(
                siteTotal.scheduling_cost_minor
              ),
              sq_ft: Number.parseFloat(siteTotal.sq_ft),
              transmission_cost_major: Number.parseFloat(
                siteTotal.transmission_cost_major
              ),
            })
        )
      )
      return data
    })

export { fetchSiteTotals }
