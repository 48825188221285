import { useMutation } from "react-query"

import { ApiQueryName } from "../../models/api"
import Department from "../../models/department"
import type { QueryMutation } from "../types"
import { createQueryKey } from "../utils/createQueryKey"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useMutationReturnHandle } from "../utils/useMutationReturnHandle/useMutationReturnHandle"
import { useQueryKey } from "../utils/useQueryKey"

export const useUpdateDepartment = () => {
  const mutationKey = useQueryKey(ApiQueryName.Department, "updateOne")
  const { update } = useSpraypaintModel(Department)
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      serviceEntityKey,
      newAttributes,
    }: Parameters<
      QueryMutation<"updateOne", ModelAttributes<Department>>
    >[0]) => {
      await update(serviceEntityKey, newAttributes)
    },
    onSuccess: (_, { serviceEntityKey }) => {
      invalidateQueries([
        createQueryKey(ApiQueryName.Department, "getOne", serviceEntityKey),
        createQueryKey(ApiQueryName.Departments, "getMany"),
      ])
    },
  })

  return useMutationReturnHandle(mutationKey, mutationResult)
}
