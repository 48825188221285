import React from "react"

import SvgIcon from "@mui/material/SvgIcon"

const RightPanelOpenIcon = (): JSX.Element => (
  <SvgIcon viewBox="0 0 20 20">
    <svg
      height="20"
      viewBox="0 -960 960 960"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M433-380v-200q0-9.917-9.5-13.458Q414-597 407-590l-89 89q-9 9-9 21t9 21l89 89q7 7 16.5 3.458Q433-370.083 
        433-380ZM180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q24.75 0 42.375 
        17.625T840-780v600q0 24.75-17.625 42.375T780-120H180Zm453-60h147v-600H633v600Zm-60 0v-600H180v600h393Zm60 0h147-147Z"
      />
    </svg>
  </SvgIcon>
)

export default RightPanelOpenIcon
