import React, { useContext } from "react"

import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import { useTheme } from "@mui/material"

import MenuRefContext from "../graph-menu/graph-menu-ref-context"
import { StackedAreaGraphTooltip } from "./stacked-area-graph-tooltip/stacked-area-graph-tooltip"
import {
  LEFT_AREA_CHART_ADDITIONAL_SPACING,
  type StackedAreaGraphProps,
} from "./stacked-area-graph.types"

export const StackedAreaGraph: React.FC<StackedAreaGraphProps> = ({
  children,
  colors,
  customTooltip,
  data,
  isTotalHidden,
  keys,
  keysToNotGraph,
  legendAlign,
  legendLayout,
  legendVerticalAlign,
  yAxisLabel,
  xAxes,
}: StackedAreaGraphProps) => {
  const theme = useTheme()
  const defaultColors: string[] = [
    theme.palette.spectrum.cerulean[400],
    theme.palette.spectrum.cerulean[300],
    theme.palette.spectrum.cerulean[50],
  ]

  const graphKeys = keys.filter((key) => !keysToNotGraph?.includes(key))

  const areaRef = useContext(MenuRefContext)
  const stackedAreas = graphKeys.map((key, index) => (
    <Area
      key={key}
      activeDot={false}
      animationDuration={500}
      dataKey={key}
      fill={colors ? colors[index] : defaultColors[index]}
      fillOpacity="1"
      stackId="1"
      stroke="none"
      type="monotone"
    />
  ))

  const tickFormatter = (tick: number): string => {
    if (tick >= 1000) {
      return `${tick / 1000}k`
    }
    return `${tick}`
  }

  const tooltipComponent = customTooltip ?? (
    <StackedAreaGraphTooltip isTotalHidden={isTotalHidden} />
  )

  return (
    <ResponsiveContainer width="100%">
      <AreaChart
        ref={areaRef as React.RefObject<InstanceType<typeof AreaChart>>}
        // Provides additional spacing for the y-axis label
        data={data}
        margin={{ left: LEFT_AREA_CHART_ADDITIONAL_SPACING }}
      >
        <CartesianGrid stroke={theme.palette.grey[100]} strokeDasharray="0" />
        {xAxes?.length ? (
          [...xAxes]
        ) : (
          <XAxis
            dataKey="xaxis"
            dx={7}
            dy={5}
            height={45}
            padding={{ left: 0.5 }}
            stroke={theme.palette.grey[700]}
            style={{ fontSize: "10px" }}
            tickLine={false}
          />
        )}
        <YAxis
          dx={0}
          label={
            <Label
              angle={-90}
              fill={theme.palette.grey[700]}
              fontSize="10px"
              position="left"
              style={{ textAnchor: "middle" }}
              value={yAxisLabel}
            />
          }
          padding={{ bottom: 0.5 }}
          stroke={theme.palette.grey[300]}
          tick={{ fill: theme.palette.grey[600], fontSize: 10 }}
          tickFormatter={tickFormatter}
          tickLine={false}
          width={44}
        />
        {children && (
          <Legend
            align={legendAlign}
            content={() => <>{children}</>}
            layout={legendLayout}
            verticalAlign={legendVerticalAlign}
          />
        )}
        {stackedAreas}
        <Tooltip
          content={tooltipComponent}
          cursor={{ stroke: theme.palette.grey[700] }}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}
