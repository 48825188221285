import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { RootPath, TamAdminPath } from "../../models/route"
import { BillingGroupDetail } from "./components/billing-group-detail/billing-group-detail"
import { MeterDetail } from "./components/meter-detail/meter-detail"
import { SiteDetail } from "./components/site-detail/site-detail"
import { OrgLayout } from "./components/tamAdminOnboarding/orgLayout/orgLayout"
import OrgView from "./components/tamAdminOnboarding/orgView/orgView"
import TamAdminOnboarding from "./components/tamAdminOnboarding/tamAdminOnboarding"
import { DataGridProvider } from "./contexts/data-grid-provider"
import { useIsFlexibleHierarchyEnabled } from "./hooks/useIsFlexibleHierarchyEnabled/useIsFlexibleHierarchyEnabled"
import { OrganizeRoute } from "./pages/organize-route/organize-route"
import { OverviewRoute } from "./pages/overview-route/overview-route"
import { SiteListRoute } from "./pages/site-list-route/site-list-route"

/**
 * All routes included under `/tam-admin`
 * @params props - The component properties
 * @example
 * return (
 *   <AdminRoute />
 * )
 */
const AdminRoute = () => {
  const { isLoading, isFlexibleHierarchyEnabled } =
    useIsFlexibleHierarchyEnabled()

  if (isLoading) {
    return null
  }

  if (isFlexibleHierarchyEnabled) {
    return (
      <Routes>
        <Route
          element={
            <Navigate to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}`} />
          }
          path="/"
        />
        <Route
          element={<TamAdminOnboarding />}
          path={TamAdminPath.Onboarding}
        />
        <Route
          element={
            <OrgLayout
              isFlexibleHierarchyEnabled={isFlexibleHierarchyEnabled}
            />
          }
          path={TamAdminPath.OrgView}
        >
          <Route element={<OverviewRoute />} index />
          <Route
            element={
              <DataGridProvider>
                <OrganizeRoute />
              </DataGridProvider>
            }
            path={TamAdminPath.Organize}
          />
          <Route element={<SiteListRoute />} path={TamAdminPath.SiteList}>
            <Route element={<SiteDetail />} path={TamAdminPath.SiteDetail} />
            <Route
              element={<BillingGroupDetail />}
              path={TamAdminPath.BillingGroupDetail}
            />
            <Route element={<MeterDetail />} path={TamAdminPath.MeterDetail} />
          </Route>
        </Route>
      </Routes>
    )
  }
  return (
    <Routes>
      <Route
        element={
          <Navigate to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}`} />
        }
        path="/"
      />
      <Route element={<TamAdminOnboarding />} path={TamAdminPath.Onboarding} />
      <Route
        element={<OrgView />}
        path={`${TamAdminPath.Onboarding}/:orgId/department?/:departmentId?/site?/:siteId?/billing-group?/:billingGroupId?/meter?/:meterId?`}
      />
    </Routes>
  )
}

export default AdminRoute
