import type { FC } from "react"
import React from "react"

import { PerformanceCell } from "@/components/performance"
import { DataGridCellPopover } from "@/nzds/data-grid"
import { HStack } from "@/nzds/layout"

import { Stack, Typography } from "@mui/material"

import type { CombinedPerformanceCellPopoverProps } from "./combined-performance-cell-popover.types"

export const CombinedPerformanceCellPopover: FC<
  CombinedPerformanceCellPopoverProps
> = ({
  children,
  benchmark_annual_usage_electricity_percentile_value,
  benchmark_annual_usage_ng_percentile_value,
}) => (
  <div>
    <DataGridCellPopover
      content={
        <Stack gap={1}>
          <HStack alignItems="center" gap={1} justifyContent="space-between">
            <Typography variant="body2">Electricity:</Typography>
            <PerformanceCell
              value={benchmark_annual_usage_electricity_percentile_value}
            />
          </HStack>
          <HStack alignItems="center" gap={1} justifyContent="space-between">
            <Typography variant="body2">Natural Gas:</Typography>
            <PerformanceCell
              value={benchmark_annual_usage_ng_percentile_value}
            />
          </HStack>
        </Stack>
      }
    >
      {children}
    </DataGridCellPopover>
  </div>
)
