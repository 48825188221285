import type { ReactElement } from "react"
import React from "react"

import LightOutlinedIcon from "@mui/icons-material/LightOutlined"
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined"

export const useSectionToIconMap = (): Record<string, ReactElement> => ({
  building_envelope_information: <StraightenOutlinedIcon color="neutral" />,
  equipment_information: <LightOutlinedIcon color="neutral" />,
})
