import React from "react"

import { Box, Typography } from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import { pxToRem } from "../../../../utils/unit"
import { EmissionDecimal } from "../../../decimal/emissionDecimal/emissionDecimal"
import type { RowItem } from "../../types"

interface LegendRowProps {
  rowItem: RowItem
}

const LegendRow = ({ rowItem }: LegendRowProps): JSX.Element => (
  <Box
    key={rowItem.label}
    sx={{
      display: "flex",
      justifyContent: "space-between",
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
    }}
  >
    <Box display="flex">
      {rowItem.color && (
        <Box
          sx={{
            width: pxToRem(12),
            height: pxToRem(12),
            marginRight: pxToRem(4),
            borderRadius: "2px",
            alignSelf: "center",
            backgroundColor: rowItem.color,
          }}
        />
      )}
      <Typography variant={TYPOGRAPHY_VARIANT.body2}>
        {rowItem.label}
      </Typography>
    </Box>
    <Box>
      <Typography variant={TYPOGRAPHY_VARIANT.body2}>
        <EmissionDecimal value={rowItem.value} />
      </Typography>
    </Box>
  </Box>
)

export default LegendRow
