import { Attr, HasMany, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"
import type Site from "./site"

export interface UserCreate {
  company_name: User["companyName"]
  email: User["email"]
  first_name: User["firstName"]
  last_name: User["lastName"]
  password: string
  recaptcha: string
  role: "free_user"
  title: User["title"]
}

export enum UserRoleName {
  Admin = "admin",
  AdminReader = "admin_reader",
  BuildingManager = "building_manager",
  EnergyManager = "energy_manager",
  FreeUser = "free_user",
}

export type UserRole = `${UserRoleName}`

@Model()
export class User extends ApplicationRecord {
  public static jsonapiType = "users"

  @HasMany("sites") public sites: Site[]

  @Attr() public email: string

  @Attr() public firstName: string

  @Attr() public lastName: string

  @Attr() public name: string

  @Attr() public phoneNumber: string

  @Attr() public companyName: string

  @Attr() public admin: boolean

  @Attr() public consultantUser: boolean

  @Attr() public freeUser: boolean

  @Attr() public role: UserRole

  @Attr() public title: string

  @Attr() public organizationIds: number[]

  @Attr() public limitedAccessOrganizationIds: number[]
}

/**
 * Fetches the energy manager count for an organization
 *
 * @param orgId - The organization id
 * @returns - The energy manager count
 * @example
 * fetchOrganizationEnergyManagerCount("15")
 */
export const fetchOrganizationEnergyManagerCount = async (
  orgId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await User.where({
    role: "energy_manager",
    organization_id: orgId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}

export default User
