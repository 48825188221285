import type { CSSProperties } from "react"
import React from "react"

import { Grid, Stack, Typography } from "@mui/material"

import { Scope } from "../../../models/scope"
import { TYPOGRAPHY_VARIANT } from "../../../models/typography"
import { UnitName } from "../../../models/unit"
import { CurrencyWithPlaceholder } from "../../currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { DecimalWithPlaceholder } from "../../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { Unit } from "../../unit/unit"
import { OrganizationDashboardMetricTitle } from "../organizationDashboardMetricTitle/organizationDashboardMetricTitle"
import { ScopeReportButton } from "../scopeReportButton/scopeReportButton"
import type { Scope2DataE2eAttributes } from "./scope2CardContent"

interface IScope2MetricsProps {
  averageCost: number
  dataE2e: Scope2DataE2eAttributes
  isLimitedAccessUser: boolean
  totalCost: number
  totalEmissions: number
}

const styles: Record<string, CSSProperties> = {
  column: {
    flexDirection: "column",
  },
  "pr-3": {
    paddingRight: 3,
  },
}

export const Scope2Metrics = ({
  averageCost,
  isLimitedAccessUser,
  totalCost,
  totalEmissions,
  dataE2e,
}: IScope2MetricsProps) => (
  <Grid container spacing={1}>
    <Grid display="flex" item justifyContent="space-between" xs={12}>
      <Stack>
        <Typography variant={TYPOGRAPHY_VARIANT.body2}>
          <OrganizationDashboardMetricTitle
            descriptor={
              <Unit unit={UnitName.MetricTonsOfCarbonDioxideEquivalent} />
            }
            title="Total Emissions"
          />
        </Typography>
        <Typography
          component="span"
          data-e2e={dataE2e.totalEmissionsValue}
          variant={TYPOGRAPHY_VARIANT.h2}
        >
          <DecimalWithPlaceholder
            format={{ precision: 2 }}
            value={totalEmissions}
          />
        </Typography>
      </Stack>
      <ScopeReportButton
        isLimitedAccessUser={isLimitedAccessUser}
        scope={Scope.Two}
      />
    </Grid>
    <Grid item sx={{ ...styles["pr-3"] }}>
      <Typography variant={TYPOGRAPHY_VARIANT.body2}>Total Cost</Typography>
      <Typography
        component="span"
        data-e2e={dataE2e.totalCost}
        variant={TYPOGRAPHY_VARIANT.h2}
      >
        <CurrencyWithPlaceholder format={{ precision: 0 }} value={totalCost} />
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant={TYPOGRAPHY_VARIANT.body2}>Average Cost</Typography>
      <Typography
        component="span"
        data-e2e={dataE2e.averageCost}
        variant={TYPOGRAPHY_VARIANT.h2}
      >
        <CurrencyWithPlaceholder
          format={{ precision: 2 }}
          value={averageCost}
        />
      </Typography>
      <Typography component="span" variant={TYPOGRAPHY_VARIANT.body2}>
        <Unit unit={UnitName.PerKilowattHour} />
      </Typography>
    </Grid>
  </Grid>
)
