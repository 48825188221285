import { client } from "@/services/client"
import type { Paths, Schemas } from "@/services/types"

export const getProfiles = async (
  params: Paths["/api/v1/recommendations/emission_profiles"]["get"]["parameters"]
): Promise<Schemas["EmissionProfilePage"]> => {
  const { data, error, response } = await client.GET(
    "/api/v1/recommendations/emission_profiles",
    {
      params,
    }
  )

  if (data) {
    return data
  }

  // Free users won't have any sites which will result in a 404
  if (response.status === 404) {
    return {
      binned_count: null,
      items: [],
      page: 0,
      pages: 0,
      size: 0,
      total: 0,
    }
  }

  throw error as Error
}
