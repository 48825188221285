import React from "react"
import type { FC } from "react"
import { NavLink } from "react-router-dom"

import { useUrl } from "@/utils/url"

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded"
import { Button } from "@mui/material"

export const ReportingLink: FC = () => {
  const { reportingPath } = useUrl()

  return (
    <Button
      component={NavLink}
      size="small"
      startIcon={<ChevronLeftRoundedIcon />}
      to={reportingPath}
    >
      Report Selection
    </Button>
  )
}
