export const COMPATIBLE_ZOD_TYPES = [
  "ZodString",
  "ZodNumber",
  "ZodNaN",
  "ZodBigInt",
  "ZodBoolean",
  "ZodDate",
  // "ZodSymbol", // Wouldn't get this from database
  "ZodUndefined",
  "ZodNull",
  // "ZodAny", // NOT SUPPORTED BY ZOD-mock
  // "ZodUnknown", // NOT SUPPORTED BY ZOD-mock
  "ZodNever",
  "ZodVoid",
  "ZodArray",
  "ZodObject",
  // "ZodUnion", // NOT SUPPORTED BY ZOD-mock
  // "ZodDiscriminatedUnion", // Holding off to minimize complexity
  // "ZodIntersection", // NOT SUPPORTED BY ZOD-mock
  // "ZodTuple", // NOT SUPPORTED BY ZOD-mock
  // "ZodRecord", // Holding off to minimize complexity
  // "ZodMap", // NOT SUPPORTED BY ZOD-mock
  // "ZodSet", // NOT SUPPORTED BY ZOD-mock
  // "ZodFunction", // NOT SUPPORTED BY ZOD-mock
  // "ZodLazy", // Holding off to minimize complexity
  "ZodLiteral",
  "ZodEnum",
  // "ZodEffects", // Holding off to minimize complexity
  // "ZodNativeEnum", // Holding off to minimize complexity
  "ZodOptional",
  "ZodNullable",
  // "ZodDefault", // NOT SUPPORTED BY ZOD-mock
  // "ZodCatch", // Holding off to minimize complexity
  // "ZodPromise", // NOT SUPPORTED BY ZOD-mock
  // "ZodBranded", // Holding off to minimize complexity
  // "ZodPipeline", // Holding off to minimize complexity
] as const
