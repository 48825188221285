import { FeeType } from "./fee"
import { MeasurementSystemName } from "./measurementSystem"
import type { IToken } from "./token"

export enum GhgWeightUnit {
  Lbs = "lbs",
  Mt = "mt",
}

export enum UnitSeparator {
  Of = "of",
  Per = "per",
}

export type EnergyUnitName = "GJ" | "kBtu"

export interface IUnitSeparator extends IToken {
  displayValue: string
  id: UnitSeparator
}

export const UnitSeparators = new Map<UnitSeparator, IUnitSeparator>([
  [
    UnitSeparator.Of,
    {
      id: UnitSeparator.Of,
      displayValue: " ",
      tokenValue: "-of-",
    },
  ],
  [
    UnitSeparator.Per,
    {
      id: UnitSeparator.Per,
      displayValue: " / ",
      tokenValue: "-per-",
    },
  ],
])

export enum UnitName {
  CarbonDioxideEquivalent = "carbon-dioxide-equivalent",
  CarbonDioxideEquivalentOfMetricTons = "carbon-dioxide-equivalent-of-metric-ton",
  Celsius = "celsius",
  CubicMeter = "cubic-meter",
  Currency = "currency",
  CurrencyPerKilowattHour = "currency-per-kilowatt-hour",
  CurrencyPerMegawattHour = "currency-per-megawatt-hour",
  CurrencyPerSquareFoot = "currency-per-square-foot",
  CurrencyPerSquareMeter = "currency-per-square-meter",
  Fahrenheit = "fahrenheit",
  Gallon = "gallon",
  GigaJoule = "giga-joule",
  GigaJoulePerSquareFoot = "giga-joule-per-square-foot",
  KiloBritishThermalUnit = "kilo-british-thermal-unit",
  KiloBritishThermalUnitPerSquareFoot = "kilo-british-thermal-unit-per-square-foot",
  Kilogram = "kilogram",
  KilogramsOfCarbonDioxideEquivalent = "kilogram-of-carbon-dioxide-equivalent",
  KilogramsOfCarbonDioxideEquivalentPerSquareFoot = "kilogram-of-carbon-dioxide-equivalent-per-square-foot",
  Kilowatt = "kilowatt",
  KilowattHour = "kilowatt-hour",
  KilowattHourPerSquareFoot = "kilowatt-hour-per-square-foot",
  KilowattHourPerSquareMeter = "kilowatt-hour-per-square-meter",
  MegawattHour = "megawatt-hour",
  MegawattHourPerSquareFoot = "megawatt-hour-per-square-foot",
  MegawattHourPerSquareMeter = "megawatt-hour-per-square-meter",
  MetricMillionBritishThermalUnits = "metric-million-british-thermal-units",
  MetricMillionBritishThermalUnitsPerSquareFeet = "metric-million-british-thermal-units-per-square-foot",
  MetricTon = "metric-ton",
  MetricTonsOfCarbonDioxideEquivalent = "metric-ton-of-carbon-dioxide-equivalent",
  MetricTonsOfCarbonDioxideEquivalentPerKilowattHour = "metric-ton-of-carbon-dioxide-equivalent-per-kilowatt-hour",
  MetricTonsOfCarbonDioxideEquivalentPerMegawattHour = "metric-ton-of-carbon-dioxide-equivalent-per-megawatt-hour",
  MetricTonsOfCarbonDioxideEquivalentPerSquareFoot = "metric-ton-of-carbon-dioxide-equivalent-per-square-foot",
  MetricTonsOfCarbonDioxideEquivalentPerSquareMeter = "metric-ton-of-carbon-dioxide-equivalent-per-square-meter",
  PerKilowatt = "-per-kilowatt",
  PerKilowattHour = "-per-kilowatt-hour",
  PerSquareFoot = "-per-square-foot",
  PerSquareMeter = "-per-square-meter",
  Pound = "pound",
  PoundsOfCarbonDioxideEquivalent = "pound-of-carbon-dioxide-equivalent",
  PoundsOfCarbonDioxideEquivalentPerKiloBritishThermalUnit = "pound-of-carbon-dioxide-equivalent-per-kilo-british-thermal-unit",
  PoundsOfCarbonDioxideEquivalentPerMegawattHour = "pound-of-carbon-dioxide-equivalent-per-megawatt-hour",
  PoundsOfCarbonDioxideEquivalentPerSquareFoot = "pound-of-carbon-dioxide-equivalent-per-square-foot",
  RenewableEnergyCertificates = "recs",
  SquareFoot = "square-foot",
  SquareMeter = "square-meter",
  Therm = "therm",
}

export const UnitNameToUSCUnitNameMap = new Map<UnitName, UnitName>([
  [UnitName.Celsius, UnitName.Fahrenheit],
  [UnitName.Kilogram, UnitName.Pound],
  [UnitName.SquareMeter, UnitName.SquareFoot],
  [UnitName.CubicMeter, UnitName.Gallon],
])

export const USCUnitNameToUnitNameMap = new Map<UnitName, UnitName>(
  Array.from(UnitNameToUSCUnitNameMap).map(
    (pair: [UnitName, UnitName]): [UnitName, UnitName] =>
      [...pair].reverse() as [UnitName, UnitName]
  )
)

export const CustomVariableNameToUnitKeyMap = new Map<string, string>([
  ["Sq ft", "SquareFoot"],
])

export const FeeTypeToUnitKeyMap = new Map<FeeType | string, string>([
  [FeeType.Kilowatt, "Kilowatt"],
  [FeeType.KilowattHour, "KilowattHour"],
])

export interface IUnit extends IToken {
  id: UnitName
  isECMAScriptSupported: boolean
  measurementSystem: MeasurementSystemName | null
  shortDisplayValue: string | null
}

export const Units = new Map<UnitName, IUnit>([
  [
    UnitName.CarbonDioxideEquivalent,
    {
      id: UnitName.CarbonDioxideEquivalent,
      isECMAScriptSupported: false,
      measurementSystem: null,
      shortDisplayValue: "CO2e",
      tokenValue: UnitName.CarbonDioxideEquivalent,
    },
  ],
  [
    UnitName.Celsius,
    {
      id: UnitName.Celsius,
      isECMAScriptSupported: true,
      measurementSystem: MeasurementSystemName.SI,
      shortDisplayValue: "", // determined by Intl.NumberFormat
      tokenValue: UnitName.Celsius,
    },
  ],
  [
    UnitName.Currency,
    {
      id: UnitName.Currency,
      isECMAScriptSupported: false,
      measurementSystem: null,
      shortDisplayValue: null, // determined at runtime based on the locale
      tokenValue: UnitName.Currency,
    },
  ],
  [
    UnitName.Fahrenheit,
    {
      id: UnitName.Fahrenheit,
      isECMAScriptSupported: true,
      measurementSystem: MeasurementSystemName.USC,
      shortDisplayValue: "", // determined by Intl.NumberFormat
      tokenValue: UnitName.Fahrenheit,
    },
  ],
  [
    UnitName.Kilogram,
    {
      id: UnitName.Kilogram,
      isECMAScriptSupported: true,
      measurementSystem: MeasurementSystemName.SI,
      shortDisplayValue: null, // determined by Intl.NumberFormat
      tokenValue: UnitName.Kilogram,
    },
  ],
  [
    UnitName.Kilowatt,
    {
      id: UnitName.Kilowatt,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.SI,
      shortDisplayValue: "kW",
      tokenValue: "kilowatt",
    },
  ],
  [
    UnitName.KilowattHour,
    {
      id: UnitName.KilowattHour,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.SI,
      shortDisplayValue: "kWh",
      tokenValue: UnitName.KilowattHour,
    },
  ],
  [
    UnitName.MegawattHour,
    {
      id: UnitName.MegawattHour,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.SI,
      shortDisplayValue: "MWh",
      tokenValue: UnitName.MegawattHour,
    },
  ],
  [
    UnitName.MetricTon,
    {
      id: UnitName.MetricTon,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.SI,
      shortDisplayValue: "MT",
      tokenValue: UnitName.MetricTon,
    },
  ],
  [
    UnitName.Pound,
    {
      id: UnitName.Pound,
      isECMAScriptSupported: true,
      measurementSystem: MeasurementSystemName.USC,
      shortDisplayValue: null, // determined by Intl.NumberFormat
      tokenValue: UnitName.Pound,
    },
  ],
  [
    UnitName.RenewableEnergyCertificates,
    {
      id: UnitName.RenewableEnergyCertificates,
      isECMAScriptSupported: false,
      measurementSystem: null,
      shortDisplayValue: "RECs",
      tokenValue: UnitName.RenewableEnergyCertificates,
    },
  ],
  [
    UnitName.SquareFoot,
    {
      id: UnitName.SquareFoot,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.USC,
      shortDisplayValue: "sq ft",
      tokenValue: UnitName.SquareFoot,
    },
  ],
  [
    UnitName.SquareMeter,
    {
      id: UnitName.SquareMeter,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.SI,
      shortDisplayValue: "sq m",
      tokenValue: UnitName.SquareMeter,
    },
  ],
  [
    UnitName.CubicMeter,
    {
      id: UnitName.CubicMeter,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.SI,
      shortDisplayValue: "m³",
      tokenValue: UnitName.CubicMeter,
    },
  ],
  [
    UnitName.Gallon,
    {
      id: UnitName.Gallon,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.USC,
      shortDisplayValue: "gal",
      tokenValue: UnitName.Gallon,
    },
  ],
  [
    UnitName.Therm,
    {
      id: UnitName.Therm,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.BIS,
      shortDisplayValue: "thm",
      tokenValue: UnitName.Therm,
    },
  ],
  [
    UnitName.GigaJoule,
    {
      id: UnitName.GigaJoule,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.SI,
      shortDisplayValue: "GJ",
      tokenValue: UnitName.GigaJoule,
    },
  ],
  [
    UnitName.KiloBritishThermalUnit,
    {
      id: UnitName.KiloBritishThermalUnit,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.BIS,
      shortDisplayValue: "kBtu",
      tokenValue: UnitName.KiloBritishThermalUnit,
    },
  ],
  [
    UnitName.MetricMillionBritishThermalUnits,
    {
      id: UnitName.MetricMillionBritishThermalUnits,
      isECMAScriptSupported: false,
      measurementSystem: MeasurementSystemName.BIS,
      shortDisplayValue: "MMBtu",
      tokenValue: UnitName.MetricMillionBritishThermalUnits,
    },
  ],
])
