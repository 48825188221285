import { Attr, BelongsTo, Model } from "spraypaint"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CollectionProxy } from "spraypaint/lib-esm/proxies"

import ApplicationRecord from "./applicationRecord/applicationRecord"
import type ServiceHour from "./serviceHour"
import type Site from "./site"

interface ICarbonBasedAndFreeStats {
  carbon_based_kwh: { sum: string | undefined } | undefined
  carbon_free_kwh: { sum: string | undefined } | undefined
}
@Model()
export default class EtagHour extends ApplicationRecord {
  public static jsonapiType = "etag_hours"

  @BelongsTo("service_hours") public serviceHour: ServiceHour[]

  @Attr() public generationSource: string

  @Attr() public kwh: number

  @Attr() public serviceHourId: number

  @Attr() public curtailedKwh: number

  static carbonBasedAndFreeStats({
    yearStart,
    dayStart,
    site,
  }: {
    dayStart: string
    site: Site
    yearStart: string
  }): Promise<ICarbonBasedAndFreeStats> {
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      this.stats({
        carbon_based_kwh: "sum",
        carbon_free_kwh: "sum",
      })
        .where({
          start_time: yearStart,
          end_time: dayStart,
          site_id: site.id,
        })
        .per(0)
        .all()
        // Mass eslint disable @typescript-eslint/no-unsafe-return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .then(({ meta }) => meta.stats)
    )
  }
}
