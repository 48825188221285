import { buildSearchParamsString } from "../../utils"

interface ClientOptions {
  baseUrl?: string
  headers?: HeadersInit
}

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
}

interface CoreFetchResposne<T> {
  data?: T | Record<string, never>
  error?: unknown
  response: Response
}

export default function createFetchClient({
  baseUrl = "",
  headers = {},
}: ClientOptions) {
  let baseApiUrl = baseUrl
  let baseHeaders = headers
  if (baseApiUrl.endsWith("/")) {
    baseApiUrl = baseUrl.substring(0, baseUrl.length - 1)
  }
  baseHeaders = mergeHeaders(DEFAULT_HEADERS, baseHeaders)

  type CoreFetchOptions = RequestInit & {
    params: {
      query?: Record<string, unknown>
    }
  }

  async function coreFetch<T>(
    url: string,
    options: CoreFetchOptions
  ): Promise<CoreFetchResposne<T>> {
    const { headers: fetchHeaders, params, ...init } = options

    const finalHeaders = mergeHeaders(baseHeaders, fetchHeaders)

    const requestInit: RequestInit = {
      ...init,
      redirect: "follow",
      headers: finalHeaders,
    }

    if (requestInit.body) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      requestInit.body = JSON.stringify(requestInit.body)
    }

    const finalURL = createFinalURL(url, {
      baseUrl: baseApiUrl,
      params,
    })

    const request = new Request(finalURL, requestInit)
    const response = await fetch(request)

    if (
      response.status === 204 ||
      response.headers.get("Content-Length") === "0"
    ) {
      return response.ok ? { data: {}, response } : { error: {}, response }
    }

    if (response.ok) {
      const data = (await response.json()) as T
      return { data, response }
    }
    let error = await response.text()
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error = JSON.parse(error) // attempt to parse as JSON
    } catch {
      // noop
    }
    return { error, response }
  }

  return {
    async GET<T>(url: string, init: CoreFetchOptions) {
      return coreFetch<T>(url, { ...init, method: "GET" })
    },
  }
}

const mergeHeaders = (baseHeaders: HeadersInit, headers: HeadersInit) => {
  return new Headers({
    ...baseHeaders,
    ...headers,
  })
}

export interface Params {
  query?: Record<string, unknown>
}

interface CreateFinalUrlOptions {
  baseUrl: string
  params: Params
}

export function createFinalURL(
  pathname: string,
  options: CreateFinalUrlOptions
) {
  let finalURL = `${options.baseUrl}${pathname}`
  let search = buildSearchParamsString(options.params.query ?? {})
  if (search.startsWith("?")) {
    search = search.substring(1)
  }
  if (search) {
    finalURL += `?${search}`
  }
  return finalURL
}
