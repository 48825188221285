import React from "react"
import type { FC } from "react"

import { PageHeader } from "@/components/nav/page-header"
import { Page } from "@/components/page"
import { PageCard } from "@/components/page-card"
import { FONT_WEIGHT } from "@/models/typography"

import { Box, Link, Typography } from "@mui/material"

export const RecommendationsTeaserRoute: FC = () => {
  return (
    <>
      <PageHeader title="Planning" />
      <Page>
        <PageCard>
          <Box my={4}>
            <Typography
              align="center"
              fontWeight={FONT_WEIGHT.bold}
              mx="auto"
              mb={3}
              sx={{ maxWidth: 400 }}
              variant="h2"
            >
              Planning is the first of its kind decarbonization tool built for
              consultants.
            </Typography>
            <Typography align="center" mx="auto" mb={4} sx={{ maxWidth: 364 }}>
              To learn more, click through our demo or{" "}
              <Link
                href="https://calendly.com/mattmckillen/nzero-consultant-demo"
                rel="noreferrer"
                target="_blank"
                underline="none"
              >
                contact us
              </Link>{" "}
              to upgrade and gain access to Planning.
            </Typography>
            <script
              async
              src="https://js.storylane.io/js/v2/storylane.js"
            ></script>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                maxHeight: "100%",
                maxWidth: "100%",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "min(1317px, 100%)",
                }}
              >
                <div
                  className="sl-embed"
                  style={{
                    height: 0,
                    paddingBottom: "calc(56.18% + 25px)",
                    position: "relative",
                    transform: "scale(1)",
                    width: "100%",
                  }}
                >
                  <iframe
                    loading="lazy"
                    src="https://app.storylane.io/demo/uvvwkfs8jiyu?embed=inline"
                    name="sl-embed"
                    allow="fullscreen"
                    allowFullScreen
                    style={{
                      border: "none",
                      borderRadius: "10px",
                      left: 0,
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      width: "100%",
                    }}
                    title="Planning demo"
                  ></iframe>
                </div>
              </div>
            </div>
          </Box>
        </PageCard>
      </Page>
    </>
  )
}
