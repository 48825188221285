import React from "react"

import { Box } from "@mui/material"

import type MonthSummary from "../../models/monthSummary"
import type Site from "../../models/site"
import type { UnitName } from "../../models/unit"
import type { EnergyUnit } from "../../utils/constants"
import type { DateRangeWithType } from "../graph/dateRangeWithType"
import { MonthlyEnergyProfileGraphContainer } from "./monthlyEnergyProfileGraphContainer"

interface MonthlySummaryGraphPanelProps {
  axisUnit?: EnergyUnit
  isParentLoading: boolean
  monthSummaries: MonthSummary[] | undefined
  site: Site
  timeWindow: DateRangeWithType
  wattUnit?: UnitName.KilowattHour | UnitName.MegawattHour
}

export const MonthlySummaryGraphPanel = ({
  site,
  timeWindow,
  isParentLoading,
  monthSummaries,
  axisUnit,
  wattUnit,
}: MonthlySummaryGraphPanelProps) => (
  <Box className="monthly-summary-panel">
    <MonthlyEnergyProfileGraphContainer
      axisUnit={axisUnit}
      isBundledEnergyCustomer={site.isBundledEnergyCustomer()}
      isLoading={isParentLoading}
      monthlySummary={monthSummaries}
      timeRange={timeWindow}
      wattUnit={wattUnit}
    />
  </Box>
)
