/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
import type { RefCallback } from "react"
import React, { forwardRef } from "react"

import type { SelectProps } from "@mui/material"
import { MenuItem as MenuItemMUI, Select as SelectMUI } from "@mui/material"

import { FieldWrapper } from "../../../../../components/form/fieldWrapper"
import { DEFAULT_FORM_FIELD_EMPTY_PLACEHOLDER } from "../../../../../utils/constants"

interface TamYesNoFieldProps extends SelectProps {
  dataE2E?: string
  error?: boolean
  errorMessage?: string | null
  id?: string
  inputRef?: RefCallback<HTMLSelectElement>
  isNoDisabled?: boolean
  label: string
  name: string
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: any) => void
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void
  readOnly?: boolean
  required?: boolean
  value?: string
}

export const TamYesNoField = forwardRef<HTMLDivElement, TamYesNoFieldProps>(
  (
    {
      label,
      name,
      readOnly,
      dataE2E,
      disabled,
      error,
      errorMessage,
      required,
      onBlur,
      onChange,
      id,
      value,
      isNoDisabled,
      ...rest
    },
    ref
  ) => (
    <FieldWrapper
      errorMessage={error ? errorMessage : null}
      label={label}
      name={name}
      required={required}
    >
      <SelectMUI
        {...rest}
        ref={ref}
        data-e2e={dataE2E}
        disabled={disabled}
        error={error}
        fullWidth
        id={id ?? name}
        margin="none"
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        required={required}
        size="small"
        value={value}
        variant="outlined"
      >
        {!required && (
          <MenuItemMUI value="null">
            {DEFAULT_FORM_FIELD_EMPTY_PLACEHOLDER}
          </MenuItemMUI>
        )}
        <MenuItemMUI value="true">Yes</MenuItemMUI>
        {!isNoDisabled && <MenuItemMUI value="false">No</MenuItemMUI>}
      </SelectMUI>
    </FieldWrapper>
  )
)
