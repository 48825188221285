import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import {
  getDepartmentTotals,
  getOrganizationChildrenTotals,
} from "./useOrgSummaryStatsUtils"

/**
 * The orgSummaryStats service/hook returns the summary stats for an organization based on
 * the parameter provided
 *
 * @param orgId - The id of the organization to get the summary stats for
 * @param dateRange - The date range from where we get the summary stats
 * @param isUseFlexibleHierarchyEnabled - Whether or not the flexible hierarchy feature is enabled
 * @returns The summary stats for an organization
 *
 * @example
 * ```tsx
 * import { useOrgSummaryStats } from '../services'
 *
 * const {
 *   orgSummaryStats,
 *   isOrgSummaryStatsFetched,
 *   isOrgSummaryStatsLoading
 * } = useOrgSummaryStats(23, { start: moment(), end: moment() })
 * ```
 */
export const useOrgSummaryStats = (
  orgId: string,
  dateRange: IRange<Moment>,
  options?: { isBundled?: boolean; isUseFlexibleHierarchyEnabled?: boolean }
) => {
  const queryKey = useQueryKey(
    ApiQueryName.OrganizationSummaryStats,
    "getMany",
    orgId,
    {
      start: dateRange?.start?.format("YYYY-MM-DD"),
      end: dateRange?.end?.format("YYYY-MM-DD"),
    }
  )

  // TODO: Remove this once the flexible hierarchy feature is enabled by default
  const getGroupTotals = options?.isUseFlexibleHierarchyEnabled
    ? getOrganizationChildrenTotals
    : getDepartmentTotals

  const isFlexibleHierarchyEnabledOrDefault =
    options?.isUseFlexibleHierarchyEnabled ? options?.isBundled : true

  const queryResult = useQuery<
    Awaited<ReturnType<typeof getGroupTotals>> | undefined
  >({
    queryKey,
    queryFn: () => getGroupTotals(orgId, dateRange.start, dateRange.end),
    enabled:
      !!orgId &&
      isFlexibleHierarchyEnabledOrDefault &&
      !!dateRange?.start?.isValid() &&
      !!dateRange?.end?.isValid() &&
      !!dateRange?.end?.isAfter(dateRange?.start),
    keepPreviousData: true,
  })

  // We need to cast queryResult as any because this service can return two different types of data types.
  // After flexible hierarchy is enabled for everyone, we can remove the use of IDepartmentSummary, which
  // should address this issue.
  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  return useRenamedQueryResult(queryKey, queryResult as any)
}
