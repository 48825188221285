import type { FC, ReactNode } from "react"
import React, { useEffect, useRef, useState } from "react"

import { useIsFirstRender } from "@uidotdev/usehooks"

import { CheckRounded, CloseRounded, EditRounded } from "@mui/icons-material"
import {
  Box,
  IconButton,
  InputAdornment,
  Typography,
  styled,
} from "@mui/material"
import { Stack } from "@mui/system"

import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import { useFormatPercentage } from "../../../../formatters/useFormatPercentage"
import { HStack, NumericTextField } from "../../../../nzds"
import { useTimeout } from "../../../../nzds/utils/use-timeout/use-timeout"
import { useCreateGoal } from "../../services/use-create-goal/use-create-goal"
import { useUpdateGoal } from "../../services/use-udpate-goal/use-update-goal"
import type { GoalsProgressBarProps } from "./goals-progress-bar.types"

const StyledInnerProgressBar = styled(Box)`
  ${({ theme }) => `
  transition: ${theme.transitions.create(["width"], {
    duration: theme.transitions.duration.complex,
    easing: theme.transitions.easing.easeInOut,
  })};
  `}
`

const LegendItem = ({
  colorLevel,
  label,
  legendIcon,
}: {
  colorLevel?: number
  label: string
  legendIcon?: ReactNode
}) => (
  <HStack alignItems="center" gap={1}>
    {colorLevel && !legendIcon ? (
      <Box
        bgcolor={`spectrum.purple.${colorLevel}`}
        border={1}
        borderColor="spectrum.purple.500"
        borderRadius={0.5}
        height="1rem"
        width="1rem"
      />
    ) : null}
    {legendIcon && !colorLevel ? legendIcon : null}

    <Typography variant="body2">{label}</Typography>
  </HStack>
)

const StyledInnerProgressBarStriped = styled(Box)`
  ${({ theme }) => `
  background-image: repeating-linear-gradient(45deg, ${theme.palette.spectrum.purple[50]}, ${theme.palette.spectrum.purple[50]} 6px, ${theme.palette.spectrum.purple[200]} 6px, ${theme.palette.spectrum.purple[200]} 7px)
  `}
`

/**
 * Displays percentage complete of total, and allows for a goal percentage(passed as a proportion/decimal)
 *
 * ```tsx
 * import { GoalsProgressBar } from ....
 *
 * <GoalsProgressBar complete={100} total={200} goalPercent={0.75}} />
 * // progress of 50%, goal of 75%
 * ```
 */
export const GoalsProgressBar: FC<GoalsProgressBarProps> = ({
  complete,
  total,
  goal,
}) => {
  const [completedPercentageWidth, setCompletedPercentageWidthWidth] =
    useState(0)

  const isFirstRender = useIsFirstRender()

  const { organization } = useOrganizationContext()

  const { mutateAsync: createGoal } = useCreateGoal()
  const { mutateAsync: updateGoal } = useUpdateGoal()

  const [goalPercentValue, setGoalPercentValue] = useState(
    `${(goal?.reduction_goal ?? 0) * 100}`
  )

  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    if (
      `${(goal?.reduction_goal ?? 0) * 100}` !== goalPercentValue &&
      !isEditMode
    ) {
      setGoalPercentValue(`${(goal?.reduction_goal ?? 0) * 100}`)
    }
  }, [goal?.reduction_goal, goalPercentValue, isEditMode])

  const initialGoalPercentValue = useRef(goal?.reduction_goal)

  const goalInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isEditMode) {
      goalInputRef.current?.focus()
    }
  }, [isEditMode])

  const completeProportion = complete / total
  const completePercentage = completeProportion * 100
  const goalProportion = goal?.reduction_goal ?? 0
  const goalPercentageWidth = goalProportion * 100
  useTimeout(() => {
    setCompletedPercentageWidthWidth(completePercentage)
  }, 150)

  useEffect(() => {
    if (isFirstRender) {
      return
    }
    setCompletedPercentageWidthWidth(completePercentage)
  }, [completePercentage, isFirstRender])

  const { format } = useFormatPercentage({ maximumFractionDigits: 2 })

  return (
    <Stack gap={2}>
      <HStack alignItems="center" gap={2}>
        <LegendItem
          colorLevel={500}
          label={`Emission Reduction from Planned Sites ${format(
            completeProportion
          )}`}
        />
        <HStack alignItems="center" gap={1}>
          <LegendItem
            label={`Target Emission Reduction ${
              isEditMode ? "" : format(Number(goalPercentValue) / 100)
            }`}
            legendIcon={
              <StyledInnerProgressBarStriped
                border={1}
                borderColor="spectrum.purple.500"
                borderRadius={0.5}
                height="1rem"
                width="1rem"
              />
            }
          />
          {isEditMode ? (
            <NumericTextField
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              inputProps={{ "aria-label": "Goal percentage" }}
              inputRef={goalInputRef}
              onChange={(event) => {
                setGoalPercentValue(event.target.value)
              }}
              size="small"
              sx={{ maxWidth: (theme) => theme.typography.pxToRem(75) }}
              value={goalPercentValue}
            />
          ) : null}
          {!isEditMode ? (
            <IconButton
              aria-label="Edit goal"
              onClick={() => {
                setIsEditMode(true)
              }}
              size="small"
            >
              <EditRounded fontSize="small" />
            </IconButton>
          ) : (
            <HStack gap={1}>
              <IconButton
                aria-label="Save goal"
                onClick={() => {
                  if (goal) {
                    void updateGoal({
                      parameters: {
                        organization_id: organization?.id,
                        path: { goal_id: goal.id },
                      },
                      requestBody: {
                        ...goal,
                        reduction_goal: Number(goalPercentValue) / 100,
                        organization_id: organization?.id,
                      },
                    })
                  } else {
                    void createGoal({
                      subject_id: Number(organization?.id),
                      subject_type: "Organization",
                      reduction_goal: Number(goalPercentValue) / 100,
                      organization_id: organization?.id,
                    })
                  }

                  setIsEditMode(false)
                }}
                size="small"
              >
                <CheckRounded fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="Cancel editing"
                onClick={() => {
                  setIsEditMode(false)
                  setGoalPercentValue(
                    `${initialGoalPercentValue.current ?? 0 * 100}`
                  )
                }}
                size="small"
              >
                <CloseRounded fontSize="small" />
              </IconButton>
            </HStack>
          )}
        </HStack>

        <LegendItem
          colorLevel={50}
          label={`Remaining Emission ${format(
            1 - (goalProportion > 0 ? goalProportion : completeProportion)
          )}`}
        />
      </HStack>
      <Stack gap={0.5}>
        <Box
          bgcolor="spectrum.purple.50"
          border={1}
          borderColor="spectrum.purple.500"
          borderRadius={0.5}
          display="flex"
          flex="1"
          minHeight="3.75rem"
          position="relative"
        >
          {goal?.reduction_goal ? (
            <StyledInnerProgressBarStriped
              border={1}
              borderColor="spectrum.purple.500"
              borderRadius={0.5}
              height="3.75rem"
              left="0"
              marginLeft="-1px"
              marginTop="-1px"
              position="absolute"
              top="0"
              width={`calc(${goalPercentageWidth}% + 1px)`}
            />
          ) : null}

          <StyledInnerProgressBar
            bgcolor="spectrum.purple.500"
            borderRadius={0.5}
            height="3.75rem"
            left="0"
            marginLeft="-1px"
            marginTop="-1px"
            position="absolute"
            top="0"
            width={`calc(${completedPercentageWidth}% + 1px)`}
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          position="relative"
          width="100%"
        >
          <Typography variant="dataLabel">{format(0)}</Typography>
          <Typography variant="dataLabel">{format(1)}</Typography>
          {goal?.reduction_goal ? (
            <Box
              alignItems="center"
              display="flex"
              left="0"
              position="absolute"
              top="0"
              width={`calc(${goalPercentageWidth}% + 1px)`}
            >
              <Typography marginLeft="auto" variant="dataLabel">
                {format(goalProportion)}
              </Typography>
            </Box>
          ) : null}
          <StyledInnerProgressBar
            alignItems="center"
            display="flex"
            left="0"
            position="absolute"
            top="0"
            width={`calc(${completedPercentageWidth}% + 1px)`}
          >
            {/* this hides it if its really close to the zero or 100 label */}
            {completedPercentageWidth > 3 && completedPercentageWidth < 97 ? (
              <Typography component="h4" marginLeft="auto" variant="dataLabel">
                {format(completeProportion)}
              </Typography>
            ) : null}
          </StyledInnerProgressBar>
        </Box>
      </Stack>
    </Stack>
  )
}
