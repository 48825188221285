/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import type { IDateFormat } from "@/models/date/date"
import { translateDate } from "@/utils/date"
import type { Moment } from "moment"

import { Typography } from "@mui/material"
import type { TypographyProps } from "@mui/material"

interface IDateDisplayProps extends TypographyProps {
  /**
   * The format configuration for the date
   */
  format?: IDateFormat

  /**
   * The date
   */
  value: Moment | null
}

/**
 * A component that properly formats and internationalizes the provided date
 *
 * @example
 * <DateDisplay value={moment()} variant="body2" format={{
 *   dateStyle: "medium"
 * }} />
 */
export const DateDisplay: FC<IDateDisplayProps> = ({
  value,
  format,
  ...rest
}) => {
  const { i18n } = useTranslation()

  return (
    <Typography {...rest}>
      {value?.isValid() && translateDate(i18n, value, format)}
    </Typography>
  )
}
