import type { Organization } from "@/models/organization"
import type { Operations, Schemas } from "@/services/types"
import { httpClient } from "@/utils/api"

interface GetSiteRecommendationOptions {
  organization_id: Organization["id"]
  path: Operations["get_bs_summary_api_v1_building_simulations__id__get"]["parameters"]["path"]
}

export const getSiteRecommendation = async (
  options: GetSiteRecommendationOptions
): Promise<Schemas["DetailedBuildSimResponse"]> => {
  const response = await httpClient.get<Schemas["DetailedBuildSimResponse"]>(
    `/api/v1/recommendations/building_simulations/${options.path.id}`,
    {
      params: {
        organization_id: options.organization_id,
      },
    }
  )

  return response.data
}
