import type { ReactElement } from "react"
import React from "react"

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"

import { ElectricityEmissionsFactor } from "../../../../../models/scopeTwo"
import {
  FONT_WEIGHT,
  TYPOGRAPHY_VARIANT,
} from "../../../../../models/typography"
import { lightGray, solidGray } from "../../../../../utils/colors"
import { pxToRem } from "../../../../../utils/unit"

const styles = {
  formControlLabel: {
    alignItems: "start",
    border: `${pxToRem(1)} solid ${lightGray}`,
    borderRadius: 1,
    padding: 1,
  },
}

interface AdvancedReportingToolsProps {
  onChange: (value: string) => void
  value: string
}

const AdvancedReportingTools = ({
  onChange,
  value,
}: AdvancedReportingToolsProps): ReactElement => (
  <>
    <Typography mb={2} variant={TYPOGRAPHY_VARIANT.h3}>
      Advanced Reporting Tools
    </Typography>
    <FormControl>
      <FormLabel sx={{ color: solidGray.toString(), marginBottom: 0.5 }}>
        Electricity (Scope 2) Emissions Factor
      </FormLabel>
      <RadioGroup
        aria-labelledby="advanced-reporting-tools-radio-buttons-group"
        onChange={(e) => {
          onChange(e.target.value)
        }}
        value={value}
      >
        <FormControlLabel
          control={<Radio />}
          data-e2e="advanced-grid-study-radio-button"
          data-testid="advanced-grid-study-form-label"
          label={
            <Box pl={1}>
              <Typography fontWeight={FONT_WEIGHT.bold}>
                Advanced grid study (Default)
              </Typography>
              <Typography>
                NZero calculates scope 2 emissions hourly using real-time
                location and market data to provide the most detailed and
                accurate emissions profile for analysis and planning.
              </Typography>
            </Box>
          }
          sx={{ ...styles.formControlLabel, mb: 1 }}
          value={ElectricityEmissionsFactor.AdvancedGridStudy.id}
        />
        <FormControlLabel
          control={<Radio />}
          data-e2e="location-based-radio-button"
          data-testid="location-based-form-label"
          label={
            <Box pl={1}>
              <Typography fontWeight={FONT_WEIGHT.bold}>
                Location-based
              </Typography>
              <Typography>
                Emission factors are based on annual average data aggregated
                across geographical locations. For U.S. sites we use eGRID 2022
                subregions suitable for data from 2022–2024.
              </Typography>
            </Box>
          }
          sx={styles.formControlLabel}
          value={ElectricityEmissionsFactor.LocationBased.id}
        />
      </RadioGroup>
    </FormControl>
  </>
)

export default AdvancedReportingTools
