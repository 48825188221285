import type { Dispatch, SetStateAction } from "react"
import React, { useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../../../models/typography"
import { darkGray, lightGray } from "../../../../../../utils/colors"
import { pxToRem } from "../../../../../../utils/unit"

interface IOrgViewContainerProps {
  children?: JSX.Element
  dataE2e: string
  filterText: string
  isContentPresent: boolean
  setFilterText: Dispatch<SetStateAction<string>>
  title: string
}

const OrgViewContainer = ({
  title,
  isContentPresent,
  filterText,
  setFilterText,
  children,
  dataE2e,
}: IOrgViewContainerProps): JSX.Element => {
  const theme = useTheme()
  const [isSearchClicked, setIsSearchClicked] = useState<boolean>(false)

  return (
    <Box
      sx={{
        minWidth: "31%",
        overflowY: "auto",
        "&:not(:first-of-type)": {
          borderLeft: `1px solid ${lightGray.toString()}`,
        },
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-start"
          pb={1}
          pl={2}
          pt={2.5}
          spacing={1}
        >
          <Typography
            color={darkGray.toString()}
            variant={TYPOGRAPHY_VARIANT.h5}
          >
            {title}
          </Typography>
          <IconButton
            data-e2e={`${dataE2e}-search-icon-button`}
            disabled={!isContentPresent}
            onClick={() => {
              setIsSearchClicked(true)
            }}
            size="small"
            sx={[
              { mt: pxToRem(8) },
              !isSearchClicked && {
                display: "inline-flex",
              },
              isSearchClicked && {
                display: "none",
              },
            ]}
          >
            <SearchIcon />
          </IconButton>
        </Stack>
        <TextField
          data-e2e={`${dataE2e}-search-input`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-e2e={`${dataE2e}-clear-input-button`}
                  disableTouchRipple
                  onClick={() => {
                    setFilterText("")
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setFilterText(e.target.value)
          }}
          sx={[
            {
              mt: pxToRem(4),
              mx: pxToRem(12),
            },
            isSearchClicked && {
              display: "flex",
            },
            !isSearchClicked && {
              display: "none",
            },
          ]}
          value={filterText}
        />
      </Box>
      {children}
    </Box>
  )
}
export default OrgViewContainer
