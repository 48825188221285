import type { FC } from "react"
import React from "react"

import { Box, Stack, Typography } from "@mui/material"

import { useInterventionTypeColors } from "../../services/useInterventionTypeColors/use-intervention-type-colors"
import { useInterventionTypeLabels } from "../../services/useInterventionTypeLabels/use-intervention-type-labels"
import type { InterventionTypeCellProps } from "./intervention-type-cell.types"

export const InterventionTypeCell: FC<InterventionTypeCellProps> = ({
  interventionType,
}) => {
  const colorMap = useInterventionTypeColors()
  const labelMap = useInterventionTypeLabels()
  return (
    <Stack alignItems="center" direction="row" gap={2}>
      <Box
        bgcolor={colorMap[interventionType].foreground}
        borderRadius={0.5}
        height="1rem"
        width="1rem"
      />
      <Typography variant="body2">{labelMap[interventionType]}</Typography>
    </Stack>
  )
}
