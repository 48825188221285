import type { FC, ReactNode } from "react"
import React, { createContext, useContext, useMemo, useState } from "react"

import type OrganizationalUnit from "../../../models/organizationalUnit"

interface DataGridContextProps {
  editedOU?: OrganizationalUnit
  setEditedOU?: (ou: OrganizationalUnit) => void
}

interface DataGridProviderProps {
  children?: ReactNode
}

export const DataGridContext = createContext<DataGridContextProps>({
  editedOU: null,
  setEditedOU: () => null,
})

export const DataGridProvider: FC<DataGridProviderProps> = ({ children }) => {
  const [editedOU, setEditedOU] = useState<OrganizationalUnit | null>()

  const dataGridContextValue = useMemo(
    () => ({
      editedOU,
      setEditedOU,
    }),
    [editedOU, setEditedOU]
  )

  return (
    <DataGridContext.Provider value={dataGridContextValue}>
      {children}
    </DataGridContext.Provider>
  )
}

export const useDataGridContext = () => {
  const { editedOU, setEditedOU } = useContext(DataGridContext)
  return { editedOU, setEditedOU }
}
