import type { IFilter } from "./filter"

const cleanValue = (value: string[]): string[] =>
  value.filter((element) => element.trim() !== "")

/**
 * A filter for multiple string
 *
 * @param value - An array of strings
 * @returns The filter instance
 * @example
 * new MultiSelectStringFilter()
 * new MultiSelectStringFilter([])
 * new MultiSelectStringFilter(["one", "two", "three"])
 * MultiSelectStringFilter.fromQueryParamValue()
 * MultiSelectStringFilter.fromQueryParamValue("")
 * MultiSelectStringFilter.fromQueryParamValue("one,two,three")
 */
export default class MultiSelectStringFilter
  implements IFilter<MultiSelectStringFilter, string[]>
{
  value: string[] | null

  constructor(value?: string[]) {
    this.value = value ? cleanValue(value) : null
  }

  isEqualTo(filter: MultiSelectStringFilter) {
    return (
      (this.value === null && filter.value === null) ||
      (this.value?.length === filter.value?.length &&
        this.value.every((value) => filter.value.includes(value)))
    )
  }

  toQueryParamValue() {
    return this.value?.join(",") ?? ""
  }

  static fromQueryParamValue(queryParamValue: string): MultiSelectStringFilter {
    const newValue: string[] | undefined =
      queryParamValue?.split(",") || undefined

    return new MultiSelectStringFilter(
      newValue ? cleanValue(newValue) : newValue
    )
  }
}
