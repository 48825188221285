export interface IMeasurementSystem {
  id: string
  name: string
  order: number
}

interface IMeasurementSystemMap {
  SI: IMeasurementSystem
  // TODO: Change USCU to USC
  USCU: IMeasurementSystem
}

export enum MeasurementSystemName {
  BIS = "BIS", // United States Customary System
  SI = "SI", // International System of Units
  USC = "USC", // British Imperial System
}

export const MeasurementSystem: IMeasurementSystemMap = {
  // TODO: Change USCU to USC
  USCU: {
    id: "1",
    name: MeasurementSystemName.USC,
    order: 1,
  },
  SI: {
    id: "2",
    name: MeasurementSystemName.SI,
    order: 2,
  },
}

// migration to strict mode batch disable
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const measurementSystems: IMeasurementSystem[] =
  Object.values(MeasurementSystem)
