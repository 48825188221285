import React from "react"

import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded"
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded"
import { useTheme } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

import type { OrgViewDetailMenuButtonProps } from "./types"

export const OrgViewDetailMenuButton = ({
  datUrl,
  deleteButton,
  webAppUrl,
}: OrgViewDetailMenuButtonProps) => {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-controls={open ? "menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-label="menu"
        id="menu-button"
        onClick={handleClick}
        role="button"
      >
        <MoreVertRoundedIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        id="menu"
        MenuListProps={{
          "aria-labelledby": "menu-button",
          "aria-hidden": !open,
          role: "menu",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { minWidth: theme.typography.pxToRem(180), elevation: 4 },
        }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {deleteButton}

        {webAppUrl ? (
          <MenuItem
            aria-label="View in web-app"
            component="a"
            href={webAppUrl}
            role="menuitem"
            sx={{
              py: theme.typography.pxToRem(4),
              px: theme.typography.pxToRem(8),
            }}
          >
            <OpenInNewRoundedIcon
              fontSize="small"
              sx={{
                color: theme.palette.neutral.main,
                mr: theme.typography.pxToRem(8),
              }}
            />
            <ListItemText>View in web-app</ListItemText>
          </MenuItem>
        ) : null}

        <MenuItem
          aria-label="View in DAT"
          component="a"
          href={datUrl}
          role="menuitem"
          sx={{
            py: theme.typography.pxToRem(4),
            px: theme.typography.pxToRem(8),
          }}
        >
          <OpenInNewRoundedIcon
            fontSize="small"
            sx={{
              color: theme.palette.neutral.main,
              mr: theme.typography.pxToRem(8),
            }}
          />
          <ListItemText>View in DAT</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
