// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchPendo = (): Promise<pendo.Pendo> =>
  new Promise((resolve) => {
    const handleOnPendoLoad = () => {
      resolve(window.pendo as pendo.Pendo)
    }

    // Script from Pendo
    /* eslint-disable */
    ;(function (apiKey) {
      ;(function (p, e, n, d, o) {
        var v, w, x, y, z
        o = p[d] = p[d] || {}
        o._q = o._q || []
        v = ["initialize", "identify", "updateOptions", "pageLoad", "track"]
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? "unshift" : "push"](
                  [m].concat([].slice.call(arguments, 0))
                )
              }
          })(v[w])
        y = e.createElement(n)
        y.async = !0
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js"
        y.onload = handleOnPendoLoad
        z = e.getElementsByTagName(n)[0]
        z.parentNode.insertBefore(y, z)
      })(window, document, "script", "pendo")
    })("633f4ca3-095e-4357-73f4-d167c32faab1")
    /* eslint-enable */
  })
