import { useTranslation } from "react-i18next"

import type { UnitName } from "../../models/unit"
import { useUserSettings } from "../../modules/settings/contexts/userSettings.provider"
import { translateUnit } from "../../utils/unit"

/**
 * Hook to get the string for a unit label in the current language.
 * A value is needed in some cases to determine the appropriate label by getting the unit
 * portion of Intl's `formatToParts` method.
 *
 * You must also import UnitName from the models/unit file.
 *
 * @example
 * const co2eUnit = useUnit(UnitName.MetricTonsOfCarbonDioxideEquivalent)
 *
 * @example
 * const co2eUnit = useUnit(UnitName.MetricTonsOfCarbonDioxideEquivalent, 2000)
 */
export const useUnit = (unit: UnitName, value?: number | string | null) => {
  const { i18n } = useTranslation()
  const { currency } = useUserSettings()

  return translateUnit(i18n.language, unit, currency, value)
}
