import React from "react"
import { Link } from "react-router-dom"

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  useTheme,
} from "@mui/material"

import {
  darkGray, // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lightestTeal,
  offWhite,
} from "../../../../../../utils/colors"
import { pxToRem } from "../../../../../../utils/unit"
import type { IOrgViewListContent } from "../../../../models/onboarding/onboarding"
import {
  LIST_ITEM_NAME_PROPERTY,
  NUMBER_OF_SKELETON_ROWS,
} from "../../../../models/onboarding/onboarding"

interface IOrgViewListProps<TContent> {
  content: TContent[]
  dataE2e: string
  isLoading: boolean
  nameProperty: LIST_ITEM_NAME_PROPERTY
  selectedItemId: string
  url: string
}

const OrgViewList = <TContent extends IOrgViewListContent>({
  content,
  isLoading,
  url,
  selectedItemId,
  nameProperty,
  dataE2e,
}: IOrgViewListProps<TContent>): JSX.Element => {
  const theme = useTheme()

  if (!isLoading && !content) {
    return null
  }
  const fixedContent = isLoading
    ? Array.from({ length: NUMBER_OF_SKELETON_ROWS }).map((_value, index) => ({
        id: index,
      }))
    : content
  return (
    <List>
      {fixedContent.map((item, index) => {
        if (isLoading) {
          return (
            // Mass lint disable
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            <ListItem key={item.id}>
              <Skeleton sx={{ width: "100%" }} />
            </ListItem>
          )
        }

        return (
          <ListItemButton
            // Mass lint disable
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            key={item.id}
            component={Link}
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            data-e2e={`${dataE2e}-${item.id}`}
            data-testid="orgViewListButton"
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            selected={item.id === selectedItemId}
            sx={{
              color: darkGray.toString(),
              background:
                index % 2 === 0
                  ? theme.palette.common.white
                  : offWhite.toString(),
              "&:active": {
                background: "spectrum.blue.100",
              },
              "&.Mui-selected": {
                background: "spectrum.blue.100",
              },
            }}
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            to={`${url}/${item.id}`}
          >
            <ListItemText
              disableTypography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // This is made to address the vertical cutoff from overflow
                margin: "0 auto",
                padding: `${pxToRem(4)} 0 ${pxToRem(4)} ${pxToRem(4)}`,
              }}
            >
              {/* Mass lint disable */}
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
              {nameProperty === LIST_ITEM_NAME_PROPERTY.Name && item?.name}
              {nameProperty === LIST_ITEM_NAME_PROPERTY.GroupName &&
                // Mass lint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                item?.groupName}
              {nameProperty === LIST_ITEM_NAME_PROPERTY.MeterCode &&
                // Mass lint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                item?.meterCode}
            </ListItemText>
          </ListItemButton>
        )
      })}
    </List>
  )
}

export default OrgViewList
