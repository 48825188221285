import ReactDOMServer from "react-dom/server"

import { select } from "d3"
import type { i18n } from "i18next"

import type { IUserSettingsData } from "../../modules/settings"
import { DEFAULT_DECIMAL_PLACEHOLDER } from "../../utils/decimal"
import { translateUnit } from "../../utils/unit"
import { EmissionDecimal } from "../decimal/emissionDecimal/emissionDecimal"
import type { DialGraphDataInterFace } from "../dialGraph"

interface OverlayGroupInterface {
  i18nService: i18n
  overlayData: DialGraphDataInterFace
  svg: React.MutableRefObject<undefined>
  userSettings: IUserSettingsData
  viewHeight: number
  viewWidth: number
}

export const drawOverlayGroup = ({
  svg: svgRef,
  viewWidth,
  viewHeight,
  overlayData,
  i18nService,
  userSettings,
}: OverlayGroupInterface) => {
  const overlayText: string = ReactDOMServer.renderToString(
    EmissionDecimal({ value: overlayData.displayValue })
  )

  const overlayUnitsText: string = translateUnit(
    i18nService.language,
    overlayData.units,
    userSettings.currency
  )

  const svg = select(svgRef.current)
    .attr("width", viewWidth)
    .attr("height", viewHeight)
  const graphOverlay = svg
    .append("g")
    .attr("class", "dial-graph__graph-overlay")

  const labelText = graphOverlay
    .append("text")
    .attr("x", "50%")
    .attr("y", "69.5%")
    .text(overlayData.name)
    .attr("class", "graph-overlay text-box__label")
  labelText.attr("dx", (_d, i, nodes) =>
    String(-nodes[i].getBoundingClientRect().width / 2)
  )

  const valueText = graphOverlay
    .append("text")
    .attr("x", "50%")
    .attr("y", "80%")
    .text(overlayText)
    .attr(
      "class",
      `graph-overlay text-box__value${
        overlayText === DEFAULT_DECIMAL_PLACEHOLDER ? "--empty" : ""
      }`
    )
    .attr("fill", overlayData.color.toString())
  valueText.attr("dx", (_d, i, nodes) =>
    String(-nodes[i].getBoundingClientRect().width / 2)
  )

  const unitsText = graphOverlay
    .append("text")
    .attr("x", "50%")
    .attr("y", "88%")
    .html(overlayUnitsText)
    .attr("class", "graph-overlay text-box__units")
  unitsText.attr("dx", (_d, i, nodes) =>
    String(-nodes[i].getBoundingClientRect().width / 2)
  )
}
