import type { FC } from "react"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded"
import RoomIcon from "@mui/icons-material/Room"
import StorageRoundedIcon from "@mui/icons-material/StorageRounded"
import { Box, Button, Link, Stack, Tab, Tabs, Typography } from "@mui/material"

import { RootPath, TamAdminPath } from "../../../../models/route"
import { HStack } from "../../../../nzds"
import { useTabIndex } from "../../hooks/useTabIndex/useTabIndex"
import type { TamAdminPageHeaderProps } from "./tamAdminPageHeader.types"

export const TamAdminPageHeader: FC<TamAdminPageHeaderProps> = ({
  organizationId,
  organizationName,
  isFlexibleHierarchyEnabled,
}) => {
  const tabIndex = useTabIndex()

  return isFlexibleHierarchyEnabled ? (
    <Stack paddingBottom={2}>
      <HStack alignItems="center" gap={0.25} paddingBottom={1}>
        <Button
          color="neutral"
          component={RouterLink}
          size="small"
          startIcon={<ArrowBackIosNewIcon />}
          sx={{ padding: 0.75 }}
          to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}`}
          variant="text"
        >
          All Organizations
        </Button>
        <Typography paddingRight={1} sx={{ fontSize: "1rem", fontWeight: 400 }}>
          /
        </Typography>
        <Typography variant="h1">{organizationName}</Typography>
      </HStack>
      <Box width="100%">
        <Tabs aria-label="org navigation tabs" value={tabIndex}>
          <Tab
            component={RouterLink}
            icon={<CorporateFareRoundedIcon />}
            iconPosition="start"
            label="Overview"
            to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}`}
          />
          <Tab
            component={RouterLink}
            icon={<StorageRoundedIcon />}
            iconPosition="start"
            label="Organize"
            to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}/organize`}
          />
          <Tab
            component={RouterLink}
            icon={<RoomIcon />}
            iconPosition="start"
            label="Sites"
            to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}/sites`}
          />
        </Tabs>
      </Box>
    </Stack>
  ) : (
    <>
      <Box
        pb={1.5}
        pt={2}
        sx={{ borderBottom: "1px solid", borderColor: "grey.100" }}
      >
        <Button
          component={RouterLink}
          startIcon={<ArrowBackIosNewIcon />}
          to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}`}
        >
          Back to Org Selection
        </Button>
      </Box>
      <Link
        component={RouterLink}
        sx={{
          display: "inline-block",
        }}
        to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}`}
      >
        <Typography
          sx={{ mt: 2.5, mb: 1.5, textTransform: "capitalize" }}
          variant="h2"
        >
          {organizationName}
        </Typography>
      </Link>
    </>
  )
}
