import type { ReactElement } from "react"
import React from "react"

import { DataAlertPreviewText } from "../../models/report"
import DataAlertBase from "../dataAlertBase/dataAlertBase"

const DataAlertPreview = (): ReactElement => (
  <DataAlertBase
    body={DataAlertPreviewText.Body}
    title={DataAlertPreviewText.Title}
  />
)

export default DataAlertPreview
