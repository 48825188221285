import { useMemo } from "react"
import { matchPath, useLocation } from "react-router-dom"

import {
  billingGroupDetailMatchPattern,
  meterDetailMatchPattern,
  organizeMatchPattern,
  overviewMatchPattern,
  siteDetailMatchPattern,
  siteListMatchPattern,
} from "../../models/onboarding/onboarding"

/**
 *
 * Syncs the state of the nav tabs in `TamAdminPageHeader` with the current route
 * @returns 0 | 1 | 2
 */
export const useTabIndex = () => {
  const location = useLocation()

  const tabIndex = useMemo(() => {
    const tabPaths = [
      { path: overviewMatchPattern, index: 0 },
      { path: organizeMatchPattern, index: 1 },
      { path: siteListMatchPattern, index: 2 },
      { path: siteDetailMatchPattern, index: 2 },
      { path: billingGroupDetailMatchPattern, index: 2 },
      { path: meterDetailMatchPattern, index: 2 },
    ]
    const match = tabPaths.find(({ path }) =>
      matchPath(path, location.pathname)
    )
    return match?.index ?? 0
  }, [location.pathname])

  return tabIndex
}
