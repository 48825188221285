import type { NavigateFunction } from "react-router-dom"

import type { DateRangeWithType } from "../components/graph/dateRangeWithType"

export const constructDownloadLink = ({ downloadType, siteId }) =>
  `/api/v1/reports.${downloadType}?type=daily_costs&site_id=${siteId}`

export const getSearch = (
  searchParams: URLSearchParams | undefined
): string => {
  if (searchParams?.toString()) {
    return `?${searchParams.toString()}`
  }

  return ""
}

interface IGoToTimeRangeProps {
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any
  navigate: NavigateFunction
  timeRange: DateRangeWithType
}

/**
 * @deprecated Use useFilters hook
 * @param {timeRange, navigate, location}
 */
export const goToTimeRange = ({
  timeRange,
  navigate,
  location: activeLocation,
}: IGoToTimeRangeProps) => {
  const params = new URLSearchParams({
    start: timeRange.startDate.format("YYYY-MM-DD"),
    end: timeRange.endDate.format("YYYY-MM-DD"),
  })
  const newURL = new URL(
    // Mass lint disable
    // eslint-disable-next-line no-restricted-globals, @typescript-eslint/no-unsafe-member-access
    `${location.protocol}//${location.host}${activeLocation.pathname}`
  )
  newURL.search = params.toString()
  navigate(newURL.pathname + newURL.search, { replace: true })
}

/**
 * allows sending an object to buld query string params
 * with, allowing a key to be an array value that will
 * repeat the key and value in the final result
 *
 * @example
 * const params = { site_id: '1234', interventions: ['Heat pump', 'Solar']}
 * const searchParamsString = buildSearchParamsString(params)
 * // ?site_id=1234&interventions=Roof+Insulation&interventions=Air+Source+Heat+Pump+%28model+2%29
 *
 */
export const buildSearchParamsString = (
  data: unknown,
  initialSearchParams?: URLSearchParams
) => {
  const params = initialSearchParams
    ? new URLSearchParams(initialSearchParams)
    : new URLSearchParams()

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (params.has(key)) {
        params.delete(key)
      }
      value.forEach((val) => {
        if (val) {
          // Mass lint disable
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          params.append(key, val.toString())
        }
      })
    } else if (value) {
      if (params.has(key)) {
        // Mass lint disable
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        params.set(key, value.toString())
      } else {
        // Mass lint disable
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        params.append(key, value.toString())
      }
      // key sent with no value/falsy value like empty string
    } else if (params.has(key)) {
      params.delete(key)
    }
  })

  return params.toString()
}

/**
 * Get the current pathname and any search params
 * @returns Current app url
 */
export const getCurrentAppUrl = (): string =>
  `${window.location.pathname}${window.location.search}`
