import type { FC } from "react"
import React from "react"

import { Divider, Stack, Typography } from "@mui/material"

import { HStack } from "../../../../nzds"
import { snakeToTitleCase } from "../../utils/snakeToTitleCase"
import { SchemaFieldInput } from "./schema-field-input"
import type { SchemaFieldProps } from "./schema-field.types"

type Entries<T> = {
  [K in keyof T]: [key: K, value: T[K]]
}[keyof T][]

export const SchemaField: FC<SchemaFieldProps> = ({ schema, name }) => {
  const { type, properties, required } = schema

  const entries =
    type === "object" && properties
      ? (Object.entries(properties) as Entries<typeof properties>)
      : null

  return (
    <HStack alignItems="center" flexWrap="wrap" gap={2} sx={{ width: "100%;" }}>
      {entries && entries.length > 0 ? (
        <>
          {entries.map(([key, value]) => {
            if (value && typeof value !== "boolean") {
              const label = value.title ?? snakeToTitleCase(key)
              if (value.type === "object") {
                return (
                  <Stack key={key} flexBasis="100%" gap={2}>
                    <Typography
                      component="h3"
                      fontWeight="fontWeightBold"
                      variant="body1"
                    >
                      {label}
                    </Typography>
                    <Divider />
                    <SchemaField name={`${name}.${key}`} schema={value} />
                  </Stack>
                )
              }

              return (
                <SchemaFieldInput
                  key={key}
                  name={`${name}.${key}`}
                  required={required}
                  schema={value}
                />
              )
            }
            return null
          })}
        </>
      ) : null}
    </HStack>
  )
}
