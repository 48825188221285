import type { FC, MouseEvent } from "react"
import React, { useEffect, useState } from "react"

import { PageHeader } from "@/components"
import { ContactDialog } from "@/components/contact-dialog"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { HStack, useDisclosure } from "@/nzds"

import { FileDownloadOutlined, MoreVert } from "@mui/icons-material"
import { Button, IconButton, Menu, MenuItem } from "@mui/material"

import { useUploadProfilesCsv } from "../../../services/use-upload-profiles-csv/use-upload-profiles-csv"
import { templateExportLink } from "../../constants"
import { buildErrorMessage } from "../../utils/build-error-message"
import { ErrorDialogContent } from "../error-dialog-content/error-dialog-content"
import { ErrorDialog } from "../error-dialog/error-dialog"
import { TemplateGuidelinesDialog } from "../template-guidelines-dialog/template-guidelines-dialog"
import { UploadCsvDialog } from "../upload-csv-dialog/upload-csv-dialog"
import type { HeaderProps } from "./header.types"

export const Header: FC<HeaderProps> = ({ navigation }) => {
  const { organization } = useOrganizationContext()

  const [moreMenuAnchor, setMoreMenuAnchor] = useState<null | HTMLElement>(null)
  const isMoreMenuOpen = Boolean(moreMenuAnchor)

  const handleMoreMenuButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMoreMenuAnchor(event.currentTarget)
  }

  const closeMoreMenu = (): void => {
    setMoreMenuAnchor(null)
  }

  const handleMoreMenuClick = (): void => {
    closeMoreMenu()
  }

  const handleMoreMenuClose = (): void => {
    closeMoreMenu()
  }

  const {
    isOpen: uploadDialogIsOpen,
    onOpen: uploadDialogOnOpen,
    onClose: uploadDialogOnClose,
  } = useDisclosure()

  const {
    isOpen: errorDialogIsOpen,
    onOpen: errorDialogOnOpen,
    onClose: errorDialogOnClose,
  } = useDisclosure()

  const {
    isOpen: templateGuidelinesDialogIsOpen,
    onClose: templateGuidelinesDialogOnClose,
    onOpen: templateGuidelinesDialogOnOpen,
  } = useDisclosure()

  const {
    isOpen: contactDialogIsOpen,
    onClose: contactDialogOnClose,
    onOpen: contactDialogOnOpen,
  } = useDisclosure()

  const {
    mutate,
    error: uploadError,
    isLoading: uploadIsLoading,
    isSuccess: uploadIsSuccess,
    reset: uploadReset,
  } = useUploadProfilesCsv()

  const errorContent = buildErrorMessage(uploadError)

  const handleUpload = (file: File) => {
    if (!organization?.id) {
      return
    }

    mutate({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      requestBody: { file } as any,
      parameters: { query: { organization_id: Number(organization.id) } },
    })
  }

  // close upload dialog and open error dialog if there was an error
  useEffect(() => {
    if (uploadError) {
      uploadDialogOnClose()
      errorDialogOnOpen()
    }
  }, [uploadDialogOnClose, errorDialogOnOpen, uploadError])

  // close upload dialog if the upload was successful
  useEffect(() => {
    if (uploadIsSuccess) {
      uploadDialogOnClose()
    }
  }, [uploadIsSuccess, uploadDialogOnClose])

  const handlePrioritizeRegulationsClick = (): void => {
    contactDialogOnOpen()
  }

  return (
    <PageHeader
      actions={
        <HStack alignItems="center" gap={1} justifyContent="space-between">
          <Button
            color="primary"
            component="a"
            href={`/api/v1/recommendations/emission_profiles?format=csv&organization_id=${organization?.id}`}
            size="small"
            startIcon={<FileDownloadOutlined />}
            variant="contained"
          >
            Download Results
          </Button>
          <HStack alignItems="center" gap={1}>
            <Button
              color="primary"
              onClick={handlePrioritizeRegulationsClick}
              size="small"
              variant="outlined"
            >
              Help Us Prioritize Regulations
            </Button>
            <ContactDialog
              open={contactDialogIsOpen}
              onClose={contactDialogOnClose}
              type="bps"
            />
            <IconButton
              aria-controls={isMoreMenuOpen ? "more-menu" : undefined}
              aria-expanded={isMoreMenuOpen ? "true" : undefined}
              aria-haspopup="true"
              aria-label="more actions"
              id="more-button"
              onClick={handleMoreMenuButtonClick}
              size="small"
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="more-menu"
              anchorEl={moreMenuAnchor}
              open={isMoreMenuOpen}
              onClick={handleMoreMenuClick}
              onClose={handleMoreMenuClose}
              MenuListProps={{
                "aria-labelledby": "more-button",
              }}
            >
              <MenuItem onClick={uploadDialogOnOpen}>
                Upload a Different CSV
              </MenuItem>
              <MenuItem component="a" href={templateExportLink}>
                Download Template
              </MenuItem>
              <MenuItem onClick={templateGuidelinesDialogOnOpen}>
                Template Guidelines
              </MenuItem>
            </Menu>
          </HStack>

          <UploadCsvDialog
            hasUploaded={true}
            isSubmitting={uploadIsLoading}
            onClose={uploadDialogOnClose}
            onUpload={handleUpload}
            open={uploadDialogIsOpen}
          />

          <ErrorDialog
            confirmText="Re-upload"
            details={<ErrorDialogContent error={uploadError} />}
            id="upload-error"
            isOpen={errorDialogIsOpen}
            message={errorContent}
            onClose={() => {
              uploadReset()
              errorDialogOnClose()
            }}
            onConfirm={() => {
              uploadDialogOnOpen()
            }}
            title="Upload Error"
          />

          <TemplateGuidelinesDialog
            onClose={templateGuidelinesDialogOnClose}
            open={templateGuidelinesDialogIsOpen}
          />
        </HStack>
      }
      navigation={navigation}
      title="Rapid Emissions Profile"
    />
  )
}
