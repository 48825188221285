import { useTheme } from "@mui/material"

import type { InterventionToColorMap } from "./use-intervention-type-colors.types"

export const useInterventionTypeColors = (): InterventionToColorMap => {
  const theme = useTheme()

  return {
    behavioral: {
      hover: theme.palette.spectrum.purple[500],
      background: theme.palette.spectrum.purple[200],
      foreground: theme.palette.spectrum.purple[400],
    },
    energy_efficiency: {
      hover: theme.palette.spectrum.peach[400],
      background: theme.palette.spectrum.peach[100],
      foreground: theme.palette.spectrum.peach[300],
    },
    energy_transition: {
      hover: theme.palette.spectrum.cerulean[300],
      background: theme.palette.spectrum.cerulean[50],
      foreground: theme.palette.spectrum.cerulean[200],
    },
  }
}
