/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import type { FieldValues, Path } from "react-hook-form"
import { useController } from "react-hook-form"

import type { Moment } from "moment"
import moment from "moment"

import type { DateTimePickerProps } from "@mui/x-date-pickers-pro"

import { TamDateTimePicker } from "../baseInputs/tamDateTimePicker"

interface TamFormDateTimePickerProps<T> extends DateTimePickerProps<Moment> {
  dataE2E?: string
  label: string
  name: Path<T>
  readOnly?: boolean
  required?: boolean
}

export const TamFormDateTimePicker = <T extends FieldValues>(
  props: TamFormDateTimePickerProps<T>
) => {
  const {
    label,
    name,
    readOnly,
    dataE2E,
    disableFuture,
    disablePast,
    disabled,
    required,
  } = props

  const {
    field: { ref, onBlur, onChange, value },
    fieldState: { error },
  } = useController({ name })

  const handleChange = (newValue: moment.Moment | null) => {
    const formattedDate = newValue ? newValue.format() : null
    onChange(formattedDate)
  }

  return (
    <TamDateTimePicker
      ref={ref}
      dataE2E={dataE2E}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      error={!!error}
      errorMessage={error?.message}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onClose={onBlur}
      readOnly={readOnly}
      required={required}
      value={value ? moment(value, moment.ISO_8601) : null}
    />
  )
}
