import React from "react"

import { GoogleMap, OverlayView } from "@react-google-maps/api"

import PlaceIcon from "@mui/icons-material/Place"
import { Box, SvgIcon, Typography } from "@mui/material"

import { useCalculateCoordinates } from "../../../../services"

interface SiteMapProps {
  address: string
}
export const SiteMap: React.FC<SiteMapProps> = ({ address }) => {
  const { coordinates } = useCalculateCoordinates(address)
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "grey.100",
        borderRadius: 2,
        display: "flex",
        height: "224px",
        justifyContent: "center",
        minHeight: "224px",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* migrate directories to strict mode */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {coordinates ? (
        <GoogleMap
          center={coordinates}
          mapContainerStyle={{
            margin: "auto",
            width: "100%",
            height: "100%",
          }}
          options={{
            disableDefaultUI: true,
            styles: [
              {
                featureType: "poi",
                elementType: "all",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
          zoom={11}
        >
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_LAYER}
            position={coordinates}
          >
            <SvgIcon
              component={PlaceIcon}
              sx={{
                fontSize: 40,
                color: "spectrum.cerulean.300",
                transform: "translate(-50%, -50%)",
              }}
            />
          </OverlayView>
        </GoogleMap>
      ) : (
        <>
          <PlaceIcon sx={{ mr: 1, color: "grey.700" }} />
          <Typography sx={{ color: "grey.700" }} variant="body2">
            No Address Found
          </Typography>
        </>
      )}
    </Box>
  )
}
