import React, { forwardRef } from "react"

import TextField from "@mui/material/TextField"

import { NumericFormatCustom } from "./numeric-format-custom"
import type { NumericTextFieldProps } from "./numeric-text-field.types"

/**
 * Wrapper around TextField component that uses [react-number-format](https://s-yadav.github.io/react-number-format/) under the hood
 *
 * You can configure react-number-format via the `NumericFormatProps` prop, which allows you to fully configure react-number-format
 * The component defaults to `decimalScale = 2`, `thousandSeparator = true`, and `valueIsNumericString = true`
 *
 * ```tsx
 * <NumericTextField
 *  name="budget"
 *  id="budget"
 *  label="Enter max budget"
 *  onChange={(event) => {
 *    console.log(event)
 *  }}
 *  NumericFormatProps={{
 *    allowNegative: false
 *  }}
 *  inputRef={refYouWant}
 * />
 * ```
 */
export const NumericTextField = forwardRef<
  HTMLDivElement,
  NumericTextFieldProps
>(({ NumericFormatProps, InputProps, inputProps, inputRef, ...rest }, ref) => (
  <TextField
    {...rest}
    ref={ref}
    InputProps={{
      ...InputProps,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      inputComponent: NumericFormatCustom as any,
    }}
    inputProps={{
      ...inputProps,
      NumericFormatProps,
    }}
    inputRef={inputRef}
  />
))
