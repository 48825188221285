// @ts-strict-ignore
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import Meter from "../../../../models/meter"

export interface AdditionalMeterData {
  billingGroupId: typeof Meter.prototype.billingGroupId
  childMeterNamesAndIds: typeof Meter.prototype.childMeterNamesAndIds
  energyAllocatorId: typeof Meter.prototype.energyAllocatorId
  id: typeof Meter.prototype.id
  meterCode: typeof Meter.prototype.meterCode
  meterType: typeof Meter.prototype.meterType
  name: typeof Meter.prototype.name
}

interface ParentMeterDataWithChildMeterData {
  meterCount: number
  parentMeterAndChildData: AdditionalMeterData[]
}

const getTypedAttributesFromMeterCollection = (
  resolvedResponse: CollectionProxy<Meter>
): ParentMeterDataWithChildMeterData => {
  const meters = resolvedResponse.data
  const parentMeterAndChildData =
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    meters === undefined
      ? undefined
      : meters.map(
          ({
            id,
            typedAttributes: {
              name,
              meterCode,
              childMeterNamesAndIds,
              billingGroupId,
              energyAllocatorId,
              meterType,
            },
          }) => ({
            id,
            name,
            meterCode,
            billingGroupId,
            energyAllocatorId,
            childMeterNamesAndIds,
            meterType,
          })
        )
  const meterCount = Number.parseInt(
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    resolvedResponse.meta.stats.electricity_meters.count,
    10
  )
  return {
    parentMeterAndChildData,
    meterCount,
  }
}

export const fetchElectricityMeter = (
  siteId: string
): Promise<ParentMeterDataWithChildMeterData> =>
  Meter.where({
    site_id: siteId,
    meter_source_type: "Electricity",
    full_meter: true,
  })
    .stats({ electricity_meters: "count" })
    .all()
    .then(getTypedAttributesFromMeterCollection)
    .then(({ parentMeterAndChildData, meterCount }) => ({
      parentMeterAndChildData,
      meterCount,
    }))
