import { useQuery } from "react-query"

import { ApiQueryName } from "../../models/api"
import Locale from "../../models/locale"
import type {
  ServiceOptions,
  ServiceSortOptions,
  ServiceWhereOptions,
} from "../types"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

/**
 * The useLocales service/hook returns the available locales
 *
 * @returns The available locales
 * @example
 * const {
 *   locales,
 *   isLocalesFetched,
 *   isLocalesLoading
 * } = useLocales()
 */
export const useLocales = ({
  disabled: isDisabled,
  where,
  sort,
}: ServiceOptions<"getMany"> & {
  sort?: ServiceSortOptions<ModelAttributes<Locale>>
  where?: ServiceWhereOptions<ModelAttributes<Locale>>
} = {}) => {
  const { getMany } = useSpraypaintModel(Locale)

  const queryKey = useQueryKey(ApiQueryName.Locales, "getMany", null, {
    sort,
    where,
  })

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => getMany({ sort, where }),
    enabled: !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
