import React from "react"

import { stack, stackOffsetNone, stackOrderNone, transpose } from "d3"
import { map } from "lodash-es"
import moment from "moment"

import { useTheme } from "@mui/material"

import { offWhite } from "../../utils/colors"
import type { EnergyChartBarData } from "./barGraphComponent"
import Product from "./product"
import { constructXAxis } from "./xAxis"

const backgroundShading = (showMeterData, palette) => (d) => {
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (d.lineValue > 0) {
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    return palette.common.white
  }
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (d.lineValue === null) {
    return "url(#missingData)"
  }
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  if (showMeterData && d.hour.isBefore(moment().startOf("day"))) {
    return "url(#missingData)"
  }
  return offWhite.toString()
}

export const BarGraphBackground = ({
  graphDataGroup,
  outerPadding,
  middlePadding,
  graphWidth,
  groupSize,
  height,
  idx,
  showMeterData,
  isMonthlySummary = false,
}) => {
  const theme = useTheme()
  // migration to strict mode batch disable
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  const [xAxis, setXAxis] = React.useState<any | null>(null)

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const graphData = graphDataGroup
  const secondGraph = idx === 1
  let startWidth = graphWidth * idx
  if (secondGraph) {
    startWidth += middlePadding
  }
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const minWidth = startWidth + outerPadding
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const maxWidth = startWidth + outerPadding + graphWidth

  React.useEffect(() => {
    const constructedXAxis = constructXAxis({
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      minWidth,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      maxWidth,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      groupSize,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      graphData,
    })

    setXAxis(constructedXAxis)
  }, [graphData])

  // migration to strict mode batch disable
  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  const products: Product[][] = map(graphData, (datum) => datum.products)
  const stackNames = Product.sortedIds(products)
  const dataset = transpose(
    stack().keys(stackNames).order(stackOrderNone).offset(stackOffsetNone)(
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      graphData
    )
  )

  if (!xAxis) {
    return <g />
  }

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { x } = xAxis

  return (
    <>
      {dataset.map((productData: EnergyChartBarData[]) => (
        <g key={`bgBar-${productData[0].data.hour.unix()}`} className="bgBars">
          <g key={`bgBar-g-${productData[0].data.hour.unix()}`}>
            {productData.map((d, i) => (
              <rect
                key={`bgBar-${i}-${productData[0].data.hour.unix()}`}
                className="bgBar"
                fill={
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  isMonthlySummary
                    ? "none"
                    : backgroundShading(showMeterData, theme.palette)(d.data)
                }
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                height={height}
                // migration to strict mode batch disable
                // Mass lint disable
                // Mass eslint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                width={x.bandwidth()}
                // migration to strict mode batch disable
                // Mass eslint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
                x={x(d.data.hour.unix())}
                y="0"
              />
            ))}
          </g>
        </g>
      ))}
    </>
  )
}
