import type { FC } from "react"
import React, { useMemo } from "react"
import { useParams } from "react-router-dom"

import { useDialog } from "@/services/dialog"
import { isNil, sortBy } from "lodash"

import { Paper } from "@mui/material"

import {
  DeveloperAdminPath,
  RootPath,
  TamAdminPath,
} from "../../../../models/route"
import type { BillingGroupCounts } from "../../../../services"
import {
  useBillingGroup,
  useBillingGroupCounts,
  useMeters,
} from "../../../../services"
import type { IOrgViewDisplayCount } from "../../models/onboarding/onboarding"
import { billingGroupDisplayCounts } from "../../models/onboarding/onboarding"
import { convertToDisplayCounts } from "../../util/onboarding/onboarding"
import OrgViewBillingGroupDetail from "../tamAdminOnboarding/orgView/orgViewBillingGroupDetail/orgViewBillingGroupDetail"
import OrgViewDeleteDialog from "../tamAdminOnboarding/orgView/orgViewDeleteDialog/orgViewDeleteDialog"
import OrgViewDetail from "../tamAdminOnboarding/orgView/orgViewDetail/orgViewDetail"
import OrgViewDetailCreateButton from "../tamAdminOnboarding/orgView/orgViewDetail/orgViewDetailCreateButton/orgViewDetailCreateButton"
import OrgViewDetailEntityDeleteButton from "../tamAdminOnboarding/orgView/orgViewDetail/orgViewDetailEntityDeleteButton/orgViewDetailEntityDeleteButton"
import OrgViewDetailLinkSection from "../tamAdminOnboarding/orgView/orgViewDetailLinkSection/orgViewDetailLinkSection"
import OrgViewDialog from "../tamAdminOnboarding/orgView/orgViewDialog/orgViewDialog"

export const BillingGroupDetail: FC = () => {
  const params = useParams()
  const { siteId, orgId, billingGroupId } = params
  const { billingGroup } = useBillingGroup(billingGroupId)
  const { billingGroupCounts } = useBillingGroupCounts(billingGroupId)

  const currentBillingGroupDisplayCounts: IOrgViewDisplayCount<BillingGroupCounts>[] =
    useMemo(
      () =>
        sortBy(
          convertToDisplayCounts<BillingGroupCounts>(
            billingGroupCounts,
            billingGroupDisplayCounts
          ),
          "order"
        ),
      [billingGroupCounts]
    )

  const { meters, isMetersLoading } = useMeters({
    sort: { key: "meterCode", order: "asc" },
    // TODO: Verify / fix typing for where clause.
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    where: {
      billing_group_id: billingGroupId,
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    disabled: isNil(billingGroupId),
  })
  const {
    onDialogOpen: onCreateDialogOpen,
    onDialogClose: onCreateDialogClose,
    dialogTitle: createDialogTitle,
    dialogContent: createDialogContent,
    isDialogOpen: isCreateDialogOpen,
  } = useDialog()

  const {
    onDialogOpen: onDeleteDialogOpen,
    onDialogClose: onDeleteDialogClose,
    dialogActions: deleteDialogActions,
    dialogContent: deleteDialogContent,
    dialogTitle: deleteDialogTitle,
    isDialogOpen: isDeleteDialogOpen,
  } = useDialog()

  const canEntityBeDeleted = (numberOfChildObjects: number) =>
    !numberOfChildObjects

  return (
    <>
      <Paper
        sx={{
          flex: 1,
          maxWidth: { lg: "calc(50% - 8px)" },
          padding: (theme) => theme.typography.pxToRem(24),
        }}
        variant="outlined"
      >
        <OrgViewDetail
          createButton={
            <OrgViewDetailCreateButton
              createObjectName="Meter"
              onDialogClose={onCreateDialogClose}
              onDialogOpen={onCreateDialogOpen}
              parentObjectIds={{ siteId, billingGroupId }}
              redirectPath={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites/${siteId}/billing-group/${billingGroupId}/meter`}
            />
          }
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          dateCreated={billingGroup?.dateCreated}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          dateUpdated={billingGroup?.dateUpdated}
          datUrl={`${RootPath.DeveloperAdmin}${DeveloperAdminPath.BillingGroup}/${billingGroup?.id}`}
          deleteButton={
            <OrgViewDetailEntityDeleteButton
              canEntityBeDeleted={canEntityBeDeleted(meters?.length)}
              entityId={billingGroupId}
              entityName="Billing Group"
              isButtonDisabled={isMetersLoading}
              navLink={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites/${siteId}`}
              onDeleteDialogClose={onDeleteDialogClose}
              onDeleteDialogOpen={onDeleteDialogOpen}
            />
          }
          entityName="Billing Group"
        >
          <OrgViewBillingGroupDetail
            billingGroupId={billingGroupId}
            linkSection={
              <OrgViewDetailLinkSection
                counts={currentBillingGroupDisplayCounts}
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                id={billingGroup?.id}
              />
            }
          />
        </OrgViewDetail>
      </Paper>
      <OrgViewDialog
        content={createDialogContent}
        onClose={onCreateDialogClose}
        open={isCreateDialogOpen}
        title={createDialogTitle}
      />
      <OrgViewDeleteDialog
        actions={deleteDialogActions}
        content={deleteDialogContent}
        onClose={onDeleteDialogClose}
        open={isDeleteDialogOpen}
        title={deleteDialogTitle}
      />
    </>
  )
}
