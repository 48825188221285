import React from "react"

import { Box, Paper, Typography, useTheme } from "@mui/material"

import { getMetricFormattingComponent } from "../../../models/decimal/decimal"
import StackedAreaGraphTooltipRows from "../../../nzds/graphs/stacked-area-graph/stacked-area-graph-tooltip/stacked-area-graph-tooltip-rows"
import type {
  StackedAreaGraphTooltipPayload,
  TooltipPayload,
} from "../../../nzds/graphs/stacked-area-graph/stacked-area-graph-tooltip/stacked-area-graph-tooltip.types"

export interface ScopeTwoGraphTooltipPayload
  extends StackedAreaGraphTooltipPayload {
  payload: TooltipPayload & {
    costMajor: number
  }
}

interface Scope2TooltipProps {
  active?: boolean
  payload?: ScopeTwoGraphTooltipPayload[]
}

const Scope2Tooltip: React.FC<Scope2TooltipProps> = ({ active, payload }) => {
  const theme = useTheme()

  if (!active || payload?.length <= 0) {
    return null
  }

  const { tooltipLabelsMap, tooltipColumn1Header, costMajor } =
    payload[0].payload

  const rows = [
    {
      label: tooltipLabelsMap.value.label,
      value: payload[0].value,
      decimalComponent: getMetricFormattingComponent(
        tooltipLabelsMap.value.metricFormattingComponentId
      ),
    },
    {
      label: tooltipLabelsMap.costMajor.label,
      value: costMajor,
      decimalComponent: getMetricFormattingComponent(
        tooltipLabelsMap.costMajor.metricFormattingComponentId
      ),
    },
  ]

  return (
    <Paper
      sx={{
        border: `1px solid ${theme.palette.grey[500]}`,
        transition: "opacity 0.45s",
        width: theme.typography.pxToRem(148),
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.grey[100],
          borderRadius: `${theme.typography.pxToRem(
            8
          )} ${theme.typography.pxToRem(8)} 0 0`, // needed for the background color to go over the border
          display: "flex",
          justifyContent: "space-between",
          whiteSpace: "nowrap",
          p: 1,
        }}
      >
        <Typography fontWeight="bold" variant="dataLabel">
          {tooltipColumn1Header}
        </Typography>
      </Box>
      <StackedAreaGraphTooltipRows rows={rows} />
    </Paper>
  )
}

export default Scope2Tooltip
