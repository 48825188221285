import React from "react"
import type { FC } from "react"

import { Typography } from "@mui/material"

import type { EditSiteConfirmContentProps } from "./edit-site-confirm-content.types"

export const EditSiteConfirmContent: FC<EditSiteConfirmContentProps> = ({
  siteName,
}) => {
  return (
    <Typography>
      Please ensure the accuracy of your changes before confirming, as this will
      influence retrofit recommendations and data simulations for{" "}
      <strong>{siteName}</strong>.
    </Typography>
  )
}
