import React from "react"
import type { FC } from "react"

import { PerformanceTier } from "@/components/performance/performance-tier"
import { useFormatDecimal } from "@/formatters"
import { DataGridLegend, DataGridLegendItem } from "@/nzds/data-grid"

import type { PerformanceGridLegendProps } from "./performance-grid-legend.types"

/**
 * Displays a legend of the performance tiers and counts for sites
 *
 * ```tsx
 * import { PerformanceGridLegend } from "@/components/performance/performance-grid-legend"
 *
 * <PerformanceGridLegend
 *   siteCount={1}
 *   extremelyInefficientCount={1}
 *   inefficientCount={1}
 *   atBenchmarkCount={1}
 *   efficientCount={1}
 *   highlyEfficientCount={1}
 *  />
 * ```
 */
export const PerformanceGridLegend: FC<PerformanceGridLegendProps> = ({
  siteCount,
  extremelyInefficientCount,
  inefficientCount,
  atBenchmarkCount,
  efficientCount,
  highlyEfficientCount,
}) => {
  const { format: formatInteger } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  return (
    <DataGridLegend itemCount={siteCount} itemLabel="Sites">
      <DataGridLegendItem
        data-testid="extremelyInefficient-count"
        label={<PerformanceTier tier="extremelyInefficient" />}
        value={formatInteger(extremelyInefficientCount)}
      />

      <DataGridLegendItem
        data-testid="inefficient-count"
        label={<PerformanceTier tier="inefficient" />}
        value={formatInteger(inefficientCount)}
      />

      <DataGridLegendItem
        data-testid="atBenchmark-count"
        label={<PerformanceTier tier="atBenchmark" />}
        value={formatInteger(atBenchmarkCount)}
      />

      <DataGridLegendItem
        data-testid="efficient-count"
        label={<PerformanceTier tier="efficient" />}
        value={formatInteger(efficientCount)}
      />

      <DataGridLegendItem
        data-testid="highlyEfficient-count"
        label={<PerformanceTier tier="highlyEfficient" />}
        value={formatInteger(highlyEfficientCount)}
      />
    </DataGridLegend>
  )
}
