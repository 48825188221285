import type { Organization } from "@/models/organization"
import type { Operations } from "@/services/types"
import { httpClient } from "@/utils/api"

export type CreateSitePlanProps =
  Operations["create_site_plan_api_v1_plans_post"]["requestBody"]["content"]["application/json"] & {
    organization_id: Organization["id"]
  }

/**
 * makes a post request to save a plan to a site for an org
 */
export const createSitePlan = async (
  body: CreateSitePlanProps
): Promise<
  Operations["create_site_plan_api_v1_plans_post"]["responses"]["200"]
> => {
  const response = await httpClient.post<
    Operations["create_site_plan_api_v1_plans_post"]["responses"]["200"]
  >(`/api/v1/recommendations/plans`, body)

  return response.data
}
