import { useMemo } from "react"

import { upperFirst } from "lodash-es"

import type { RenamedQueryResult } from "./useRenameQueryResult.types"

export const useRenameQueryResult = <TQueryResult, TPrefix extends string>(
  queryResult: TQueryResult,
  prefix: TPrefix
): RenamedQueryResult<TQueryResult, TPrefix> => {
  const props = useMemo(() => {
    return Object.fromEntries(
      Object.entries(queryResult).map(([key, value]) => [
        `${prefix}${upperFirst(key)}`,
        value,
      ])
    )
  }, [queryResult, prefix])

  return props as RenamedQueryResult<TQueryResult, TPrefix>
}
