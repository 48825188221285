import type { ReactNode } from "react"
import React from "react"

import { Typography } from "@mui/material"

import warning from "../icons/warning.svg"
import { percentageChange } from "../utils"
import { CurrencyWithPlaceholder } from "./currency/currencyWithPlaceholder/currencyWithPlaceholder"

const upArrow = () => (
  <svg height="12" width="7" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 0L0 12h7z" fill="#1D1D1D" />
  </svg>
)
const downArrow = () => (
  <svg height="12" width="7" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 12L7 0H0z" fill="#1D1D1D" />
  </svg>
)

export interface PriceDisplayProps {
  amount: number
  children?: ReactNode
  previousAmount?: number
}

export const PriceDisplay = ({
  children,
  amount,
  previousAmount = null,
}: PriceDisplayProps) => {
  let dollars
  let cents
  if (amount === null || amount === undefined) {
    ;[dollars, cents] = ["--", "--"]
  } else {
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ;[dollars, cents] = amount.toFixed(2).split(".")
  }

  let changePercent = null
  let changeIcon = null
  if (previousAmount && previousAmount > 0) {
    changePercent = percentageChange(previousAmount, amount) || 0
    changeIcon = changePercent && changePercent > 0 ? upArrow() : downArrow()
  }

  return (
    <div className="price-display">
      <div className="price-display__label">{children}</div>
      <div className="price-display__money">
        <Typography variant="hero">
          <CurrencyWithPlaceholder value={amount} />
        </Typography>
      </div>
      <svg
        className={`graphic${amount === null ? " graphic--unavailable" : ""}`}
      />
      {previousAmount != null && (
        <div className="price-display__change">
          <div className="price-display__change__arrow">{changeIcon}</div>
          {/* Mass lint disable */}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
          <div>{Math.abs(changePercent).toFixed(1)}%</div>
        </div>
      )}
      {amount == null && (
        <div className="price-display__unavailable">
          <span className="error-message-summary__icon">
            <img
              alt=""
              className="price-display__unavailable--icon"
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              src={warning}
            />
          </span>
          <span>Unavailable</span>
        </div>
      )}
    </div>
  )
}
