import React from "react"
import type { FC } from "react"

import { If } from "@/components/if/if"

import { Box } from "@mui/material"

import type { ComplianceIconProps } from "./complianceIcon.types"

const computeColor = (isCompliant: boolean | null): string => {
  if (isCompliant === null) {
    return ""
  }

  return isCompliant ? "spectrum.green.400" : "spectrum.red.400"
}

export const ComplianceIcon: FC<ComplianceIconProps> = ({ isCompliant }) => {
  return (
    <If condition={isCompliant !== null}>
      <Box
        bgcolor={computeColor(isCompliant)}
        borderRadius="50%"
        minHeight={16}
        width={16}
      />
    </If>
  )
}
