import { useCallback } from "react"
import { useSearchParams } from "react-router-dom"

import type { GridFilterModel } from "@mui/x-data-grid-pro"

import { buildSearchParamsString } from "../../../utils"

export const useGridFilterModelChange = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  /**
   * onModel change, syncs up to the search params
   */
  const handleOnFilterModelChange = useCallback(
    (model: GridFilterModel) => {
      const { quickFilterValues } = model
      const newSearchParamsString = buildSearchParamsString(
        {
          qF: quickFilterValues,
        },
        searchParams
      )
      setSearchParams(newSearchParamsString)
    },
    [searchParams, setSearchParams]
  )

  return handleOnFilterModelChange
}
