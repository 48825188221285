// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Attr, HasMany, Model, WhereClause } from "spraypaint"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import ApplicationRecord, { // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  IFetchApplicationRecordOptions,
} from "./applicationRecord/applicationRecord"
import type Site from "./site"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Order } from "./sort"

export const DEFAULT_LOCATION_PLACEHOLDER = "_.__"

@Model()
export default class Location extends ApplicationRecord {
  public static jsonapiType = "locations"

  @HasMany("sites") public sites: Site[]

  @Attr() public name: string

  @Attr() public latitude: number

  @Attr() public longitude: number

  @Attr() public timezone: string
}

export const fetchLocations = async (): Promise<Location[]> => {
  const response: CollectionProxy<Location> = await Location.all()
  return response.data
}
