import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

/**
 * Defines a search parameter lookup configuration object.
 */
interface SearchParamLookup {
  /**
   * @type {boolean} - When true, the URLSearchParams `getAll()` function will be used instead of
   * `get()`. This will return an array of all found parameter values
   */
  getAll?: boolean

  /**
   * @type {boolean} - When true, will parse the comma separated search value as an array
   */
  isArray?: boolean

  /**
   * @type {string} - The name of the search parameter
   */
  param: string
}

type UseSearchParams = Record<string, string | string[]>

interface IFindSearchParamsProps {
  search: string
  searchParams: SearchParamLookup[]
}

/**
 * @deprecated Use useFilters hook
 * @param root0
 * @param root0.searchParams
 * @param root0.search
 */
const findSearchParams = ({
  searchParams,
  search,
}: IFindSearchParamsProps): UseSearchParams => {
  const foundSearchParams: UseSearchParams = {}
  const urlSearchParams = new URLSearchParams(decodeURIComponent(search))

  searchParams.forEach((_searchParam) => {
    if (_searchParam.getAll && _searchParam.isArray) {
      foundSearchParams[_searchParam.param] = urlSearchParams
        .getAll(_searchParam.param)
        .map((foundSearchParam) => foundSearchParam.split(","))
        .flat()
    } else if (_searchParam.getAll) {
      foundSearchParams[_searchParam.param] = urlSearchParams.getAll(
        _searchParam.param
      )
    } else if (!_searchParam.getAll && _searchParam.isArray) {
      foundSearchParams[_searchParam.param] = urlSearchParams
        .get(_searchParam.param)
        .split(",")
    } else {
      foundSearchParams[_searchParam.param] = urlSearchParams.get(
        _searchParam.param
      )
    }
  })

  return foundSearchParams
}

/**
 * Use to look up URL search parameters.
 *
 * @deprecated Use useFilters hook
 * @param searchParams - The search parameter lookup configuration, used to look up a
 * single search parameter. Must be a stable object, declaring in-line in a component's render
 * logic will create an infinite loop.
 * @returns - The found search params
 * @example
 * // if the URL contains the search parameters: ?&site=1,2,3
 * const { site } = useSearchParams([{ param: "site", isArray: true }])
 * // site = ["1", "2", "3"]
 *
 * // if the URL contains the search parameters: ?&site=1&site=2&site=3
 * const { site } = useSearchParams([{ param: "site", isArray: true, getAll: true }])
 * // site = ["1", "2", "3"]
 */
export const useSearchParams = (
  searchParams: SearchParamLookup[]
): UseSearchParams => {
  const { search } = useLocation()
  const [searchParamsState, setSearchParamsState] = useState<UseSearchParams>(
    findSearchParams({ searchParams, search })
  )

  useEffect(() => {
    setSearchParamsState(findSearchParams({ searchParams, search }))
  }, [searchParams, search])

  return searchParamsState
}
