import React from "react"

import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton, Menu, MenuItem, Stack } from "@mui/material"

import { useGraphToPng } from "../graph-to-png/graph-to-png"
import type { GraphMenuContainerProps } from "./graph-menu-container.types"
import MenuRefContext from "./graph-menu-ref-context"

/**
 * A component that wraps a graph (child) and provides a menu for exporting the graph as a PNG
 * For example:
 *  return (
 *    <GraphMenuContainer fileName={exportFileTitle}>
 *     <StackedAreaGraph
 *       colors={colors}
 *       data={data}
 *       keys={keys}
 *       xAxes={xAxes}
 *       yAxisLabel={yAxisLabel}
 *     />
 *    </GraphMenuContainer>
 *  )
 */

export const GraphMenuContainer: React.FC<GraphMenuContainerProps> = ({
  children,
  exportHiddenElements,
  fileName,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  )
  const open = Boolean(menuAnchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const { savePng, ref: graphRef } = useGraphToPng()

  const exportPNG = () => {
    handleMenuClose()
    savePng(fileName, exportHiddenElements).catch((error) => {
      console.error(error)
    })
  }

  return (
    <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
      <MenuRefContext.Provider value={graphRef}>
        {children}
      </MenuRefContext.Provider>
      <IconButton
        color="neutral"
        onClick={handleMenuClick}
        size="small"
        sx={{ ml: 0.5, width: 3, height: 3 }}
        title="Graph Menu"
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={handleMenuClose}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem key="export_stacked_area" onClick={exportPNG}>
          Export PNG
        </MenuItem>
      </Menu>
    </Stack>
  )
}
