import type { UseQueryOptions } from "react-query"
import { useQuery } from "react-query"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import { ApiQueryName } from "@/models/api"
import { useQueryKey } from "@/services/utils/useQueryKey"

import { getSiteRecommendation } from "./get-site-recommendation"

interface UseGetSiteRecommendationOptions {
  queryOptions?: Omit<UseQueryOptions, "queryKey" | "queryFn">
}

/**
 * Returns a recommendation by id
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetSiteRecommendation(227, { queryOptions });
 */
export const useGetSiteRecommendation = (
  planId: number,
  options: UseGetSiteRecommendationOptions = {}
) => {
  const { organization } = useOrganizationContext()

  const queryKey = useQueryKey(
    ApiQueryName.RecommendationSite,
    "getOne",
    null,
    {
      planId,
    }
  )

  let queryParams = {
    queryKey,
    queryFn: () =>
      getSiteRecommendation({
        organization_id: organization?.id,
        path: {
          id: planId,
        },
      }),
  }

  if (options.queryOptions) {
    queryParams = {
      ...options.queryOptions,
      ...queryParams,
    }
  }

  const query = useQuery(queryParams)

  return query
}
