import { getPseudoEndValue } from "@/services/utils/utils"
import moment from "moment"

import type { MonthlyNaturalGasGraphDatum } from "../../../../components/graph/types"
import type { ResourceSummaryMonthRecord } from "../../../report/models/resourceSummary"

const getMonthlySiteConsumptionGraphData = (
  monthlySiteData: ResourceSummaryMonthRecord[],
  siteId: string
): MonthlyNaturalGasGraphDatum[] => {
  const monthlyDataForSite = monthlySiteData
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ?.filter((record) => record.siteId === siteId)
    .map((record, i) => ({
      date: moment([record.year, record.month - 1]).format("YYYY-MM-DD"),
      index: i,
      kwh: record.kwh,
      naturalGasThms: record.naturalGasThms,
      waterGallons: record.waterGallons,
    }))

  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (monthlyDataForSite?.length === 1) {
    const duplicatedRecord: MonthlyNaturalGasGraphDatum = getPseudoEndValue(
      monthlyDataForSite[0]
    ) as MonthlyNaturalGasGraphDatum
    monthlyDataForSite.push(duplicatedRecord)
  }

  return monthlyDataForSite
}

export { getMonthlySiteConsumptionGraphData }
