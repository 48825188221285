import { useTranslation } from "react-i18next"

import { EMPTY_DATA_PLACEHOLDER } from "../utils"
import { shouldShowPlaceholder } from "./utils"

export const useFormatPercentage = (options: Intl.NumberFormatOptions = {}) => {
  const { t } = useTranslation()

  const format = (value?: string | number | null) => {
    if (shouldShowPlaceholder(value)) {
      return EMPTY_DATA_PLACEHOLDER
    }
    return t("common:percentage", {
      value,
      formatParams: {
        value: {
          ...options,
        },
      },
    })
  }

  return { format }
}
