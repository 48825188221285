import React from "react"

import { Box, Grid, Typography, useTheme } from "@mui/material"

import { HStack } from "../../../../nzds"
import type { AreaGraphProperties } from "../../../graph/types"

interface OrganizationDashboardScopeOneLegendProps {
  legendItems: AreaGraphProperties[]
}

const OrganizationDashboardScopeOneLegend: React.FC<
  OrganizationDashboardScopeOneLegendProps
> = ({ legendItems }) => {
  const theme = useTheme()
  return (
    // Require negative margin to align with graph
    <Grid
      columnSpacing={3}
      container
      mb={2}
      ml={-4.5}
      mt={-0.25}
      rowSpacing={1}
    >
      {legendItems.map((key) => (
        <Grid key={key.label} item>
          <HStack alignItems="center" spacing={1}>
            <Box
              sx={{
                backgroundColor: key.color,
                borderRadius: 0.5,
                height: theme.typography.pxToRem(12),
                width: theme.typography.pxToRem(12),
              }}
            />
            <Typography textTransform="capitalize" variant="body2">
              {key.label}
            </Typography>
          </HStack>
        </Grid>
      ))}
    </Grid>
  )
}

export default OrganizationDashboardScopeOneLegend
