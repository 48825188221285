// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { WhereClause } from "spraypaint"
import { Attr, BelongsTo, Model } from "spraypaint"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { IFetchApplicationRecordOptions } from "./applicationRecord/applicationRecord"
import ApplicationRecord from "./applicationRecord/applicationRecord"
import Organization from "./organization"
import { fetchDepartmentSiteCount } from "./site"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Order } from "./sort"

export interface IDepartmentCounts {
  sites: number
}

@Model()
export default class Department extends ApplicationRecord {
  public static jsonapiType = "departments"

  @BelongsTo("organizations") public organization: Organization

  @Attr() public readonly name: string

  @Attr() public readonly dateCreated: string

  @Attr() public readonly dateUpdated: string

  @Attr() public readonly organizationId: number
}

/**
 * Fetches the department count for an organization
 *
 * @param orgId - The organization id
 * @returns - The department count
 * @example
 * fetchOrganizationDepartmentCount("15")
 */
export const fetchOrganizationDepartmentCount = async (
  orgId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await Department.where({
    organization_id: orgId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}

/**
 * Fetches the counts for an department
 *
 * @param departmentId - The department id
 * @returns - The department counts
 * @example
 * fetchDepartmentCounts("15")
 */
export const fetchDepartmentCounts = async (
  departmentId: string
): Promise<IDepartmentCounts> => {
  const sites = await fetchDepartmentSiteCount(departmentId)

  return { sites }
}
