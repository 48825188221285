// @ts-strict-ignore
import ForecastHour from "@/models/forecastHour"

/**
 * Fetches forecast hours
 * @param whereClause - Where clause to filter the data.
 * @param selectExtraClause - Select extra clause to include extra data.
 * @returns A promise that resolves to an array like container of ForecastHour.
 * */
export const fetchForecastHours = async ({ whereClause, selectExtraClause }) =>
  ForecastHour.where(whereClause)
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    .selectExtra(selectExtraClause)
    .order("hour")
    // Page size should be passed in instead of hardcoded
    .per(100)
    .all()
    .then((response) => response.data)

export default { fetchForecastHours }
