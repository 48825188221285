import type { Dispatch, FC, SetStateAction } from "react"
import React, { useEffect, useMemo, useRef, useState } from "react"

import MoreVertIcon from "@mui/icons-material/MoreVert"
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"

interface GraphSelectProps {
  graphIsLog: boolean
  setGraphIsLog: Dispatch<SetStateAction<boolean>>
}

export const GraphSelect: FC<GraphSelectProps> = ({
  graphIsLog,
  setGraphIsLog,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const graphSelectRef = useRef<any | null>(null)

  const theme = useTheme()

  const handleMenuButtonClick = () => {
    if (isMenuVisible) {
      setIsMenuVisible(false)
    } else {
      setIsMenuVisible(true)
    }
  }

  const handleOutsideGraphSelectClick = (event) => {
    if (
      graphSelectRef.current &&
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      !graphSelectRef.current.contains(event.target)
    ) {
      setIsMenuVisible(false)
    }
  }

  const handleToggleButtonGroupChange = (
    _event,
    newToggleValue: "log" | "linear" | null
  ) => {
    if (!newToggleValue) {
      return
    }
    setGraphIsLog(newToggleValue === "log")
  }

  const toggleButtonGroupValue = useMemo(
    () => (graphIsLog ? "log" : "linear"),
    [graphIsLog]
  )

  useEffect(() => {
    document.addEventListener("click", handleOutsideGraphSelectClick, true)
    return () => {
      document.removeEventListener("click", handleOutsideGraphSelectClick, true)
    }
  })

  return (
    <Box ref={graphSelectRef} sx={{ position: "relative" }}>
      <IconButton
        color="neutral"
        onClick={handleMenuButtonClick}
        size="small"
        sx={{
          position: "absolute",
          right: 0,
        }}
      >
        <MoreVertIcon />
      </IconButton>
      {isMenuVisible && (
        <Box
          data-active={isMenuVisible}
          sx={{
            position: "absolute",
            padding: theme.spacing(1.5),
            right: 0,
            top: theme.spacing(5),
            border: `1px solid ${theme.palette.grey[500]}`,
            background: theme.palette.background.paper,
            borderRadius: theme.spacing(0.5),
          }}
        >
          <Typography mb={0.5} variant="body2">
            Y-Axis Scale
          </Typography>
          <ToggleButtonGroup
            exclusive
            onChange={handleToggleButtonGroupChange}
            value={toggleButtonGroupValue}
          >
            <ToggleButton value="linear">Linear</ToggleButton>
            <ToggleButton value="log">Log</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
    </Box>
  )
}
