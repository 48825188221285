// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import { Page, PageHeaderActionBar } from "@/components"
import { groupBy } from "lodash-es"
import type { Moment } from "moment"
import moment from "moment"

import { Box, Collapse, Paper, Typography, useTheme } from "@mui/material"

import { useDashboardContext } from "../../../../components/dashboard/useDashboardContext"
import { BundledSiteFinancialSummaryPanel } from "../../../../components/financialSummary/bundledSiteFinancialSummaryPanel"
import { MonthlySummaryGraphPanel } from "../../../../components/monthlySummary/monthlySummaryGraphPanel"
import { SiteOverview } from "../../../../components/siteOverview"
import type { IUseFiltersSetOptions } from "../../../../hooks/useFilters/useFilters"
import useFilters from "../../../../hooks/useFilters/useFilters"
import type EnergyAllocation from "../../../../models/energyAllocation"
import { ElectricityType } from "../../../../models/energyAllocation"
import DateFilter from "../../../../models/filter/dateFilter"
import type { IRange } from "../../../../models/range"
import { UnitName } from "../../../../models/unit"
import { HStack } from "../../../../nzds"
import { DateRangePicker } from "../../../../nzds/inputs/date-range-picker"
import { MonthRangePicker } from "../../../../nzds/inputs/month-range-picker"
import { lightGray } from "../../../../utils/colors"
import {
  EnergyUnit,
  MAX_CUSTOMER_BILLING_PERIOD_DAYS,
  TIME_RANGES,
} from "../../../../utils/constants"
import {
  clampDateRange,
  getDaysInRange,
  getRangeType,
  isDateRangeWithinBounds,
} from "../../../../utils/date"
import { getWattUnit, saveWattUnit } from "../../../../utils/localStorage"
import { pxToRem } from "../../../../utils/unit"
import type { MeterWithEnergyAllocator } from "../../models/site"
import { useBundledEmissionsAndEnergyVolume } from "../../services/useBundledEmissionsAndEnergyVolume/useBundledEmissionsAndEnergyVolume"
import { useDirectEmissionsAndEnergyVolume } from "../../services/useDirectEmissionsAndEnergyVolume/useDirectEmissionsAndEnergyVolume"
import { useEia } from "../../services/useEia/useEia"
import { useElectricityMeter } from "../../services/useElectricityMeter/useElectricityMeter"
import { useMonthSummaries } from "../../services/useMonthSummaries/useMonthSummaries"
import { useMonthSummariesMinMaxDates } from "../../services/useMonthSummariesMinMaxDates/useMonthSummariesMinMaxDates"
import { useMonthlyElectricityDateRange } from "../../services/useMonthlyElectricityDateRange/useMonthlyElectricityDateRange"
import { useNonMonthlyElectricityDateRange } from "../../services/useNonMonthlyElectricityDateRange/useNonMonthlyElectricityDateRange"
import { useServiceHourMinMaxDates } from "../../services/useServiceHourMinMaxDates/useServiceHourMinMaxDates"
import { useSiteEnergyAllocation } from "../../services/useSiteEnergyAllocation/useSiteEnergyAllocation"
import { useSiteExtraAttributes } from "../../services/useSiteExtraAttributes/useSiteExtraAttributes"
import { useSiteFinancialSummaries } from "../../services/useSiteFinancialSummaries/useSiteFinancialSummaries"
import { useYtdMeteredKwh } from "../../services/useYtdMeteredKwh/useYtdMeteredKwh"
import DailySummaryPanel from "./dailySummaryPanel/dailySummaryPanel"
import ElectricityHeader from "./electricityHeader/electricityHeader"
import SitePerformance from "./sitePerformance/sitePerformance"

interface SiteElectricityFilters {
  end: DateFilter
  start: DateFilter
}

const isAnyKWhIsLessThan1k = (energyAllocations: EnergyAllocation[]) => {
  const groupedByDate: Record<string, EnergyAllocation[]> = groupBy(
    energyAllocations.filter((ea) => !Number.isNaN(ea.meteredKwh)),
    (ea) => moment(ea.hour).format("YYYY-MM-DD")
  )

  return Object.values(groupedByDate).some((dayEnergyAllocations) => {
    const meteredKwhSum = dayEnergyAllocations.reduce(
      (sum, energyAllocation) => sum + Number(energyAllocation.meteredKwh),
      0
    )
    return meteredKwhSum > 0 && meteredKwhSum < 1000
  })
}

const Electricity: React.FC = () => {
  const theme = useTheme()
  const params = useParams()
  const { site, isSiteInfoOpen, siteInfoButton } = useDashboardContext()
  const { monthlyElectricityDateRange, setMonthlyElectricityDateRange } =
    useMonthlyElectricityDateRange()

  const { nonMonthlyElectricityDateRange, setNonMonthlyElectricityDateRange } =
    useNonMonthlyElectricityDateRange()
  const {
    siteExtraAttributes: {
      hasAmiMeter,
      hasAmrMeter,
      hasSubMeter,
      probeMeter,
    } = {
      hasAmiMeter: null,
      hasAmrMeter: null,
      hasSubMeter: null,
      probeMeter: null,
    },
    isSiteExtraAttributesLoading,
  } = useSiteExtraAttributes(params.siteId)

  const { filters, setFilters } = useFilters<SiteElectricityFilters>({
    start: DateFilter,
    end: DateFilter,
  })

  const {
    serviceHourMinMaxDates,
    isServiceHourMinMaxDatesFetched,
    isServiceHourMinMaxDatesLoading,
  } = useServiceHourMinMaxDates(site)

  const [openModalSiteMetersDetails, setOpenModalSiteMetersDetails] =
    useState<boolean>(false)
  const [wattUnit, setWattUnit] = useState<
    UnitName.KilowattHour | UnitName.MegawattHour
  >(getWattUnit())

  const isBundled: boolean = useMemo(
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison, @typescript-eslint/no-unnecessary-condition
    () => site?.electricityType === ElectricityType.Bundled,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    [site?.electricityType]
  )

  const isMonthlySummaryVisible: boolean = useMemo(
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => hasAmrMeter && !hasAmiMeter,
    [hasAmiMeter, hasAmrMeter]
  )

  const startDate: Moment = filters.start.value
  const endDate: Moment = filters.end.value

  const onEnergyUsageUnitChange = useCallback(
    (unit: UnitName.KilowattHour | UnitName.MegawattHour): void => {
      setWattUnit(unit)
      saveWattUnit(unit)
    },
    []
  )

  const timeRange = useMemo(
    () =>
      getRangeType({
        startDate,
        endDate,
      }),
    [endDate, startDate]
  )

  const updateDate = useCallback(
    (value: IRange<Moment>, options?: IUseFiltersSetOptions): void => {
      setFilters(
        {
          start: new DateFilter(value.start),
          end: new DateFilter(value.end),
        },
        options
      )
      if (isMonthlySummaryVisible) {
        setMonthlyElectricityDateRange(value)
      } else {
        setNonMonthlyElectricityDateRange(value)
      }
    },
    [
      isMonthlySummaryVisible,
      setMonthlyElectricityDateRange,
      setFilters,
      setNonMonthlyElectricityDateRange,
    ]
  )

  const handleOnDateRangeChange = useCallback(
    (value: IRange<Moment>): void => {
      updateDate(value)
    },
    [updateDate]
  )

  const {
    siteEnergyAllocation,
    isSiteEnergyAllocationFetched,
    isSiteEnergyAllocationFetching,
  } = useSiteEnergyAllocation(startDate, endDate, site, {
    isDisabled:
      isMonthlySummaryVisible ||
      getDaysInRange(startDate, endDate) > MAX_CUSTOMER_BILLING_PERIOD_DAYS,
  })

  const yearToDateRange: IRange<Moment> = useMemo(
    () => ({
      start: moment().startOf("year"),
      end: moment().endOf("day"),
    }),
    []
  )

  const { eia } = useEia({
    site,
    startDate: yearToDateRange.start,
    endDate: yearToDateRange.end,
  })

  const { bundledEmissionsAndEnergyVolume } =
    useBundledEmissionsAndEnergyVolume({
      site,
      startDate: yearToDateRange.start,
      endDate: yearToDateRange.end,
    })

  const { directEmissionsAndEnergyVolume } = useDirectEmissionsAndEnergyVolume(
    site,
    {
      start: yearToDateRange.start,
      end: yearToDateRange.end,
    }
  )

  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { ytdMeteredKwh } = useYtdMeteredKwh(yearToDateRange, site?.id)

  const { monthSummaries, isMonthSummariesFetching } = useMonthSummaries(
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    site?.id,
    {
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      start: startDate?.clone()?.format("YYYY-MM-DD"),
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      end: endDate?.clone()?.format("YYYY-MM-DD"),
    },
    {
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      isDisabled: !isMonthlySummaryVisible || startDate?.isAfter(endDate),
    }
  )

  const { monthSummariesMinMaxDates, isMonthSummariesMinMaxDatesFetched } =
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useMonthSummariesMinMaxDates(site?.id, {
      isDisabled: !isMonthlySummaryVisible,
    })

  const { electricityMeter, isElectricityMeterLoading } = useElectricityMeter(
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    site?.id
  )
  const { siteFinancialSummaries, isSiteFinancialSummariesLoading } =
    useSiteFinancialSummaries(
      site,
      startDate,
      endDate,
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      electricityMeter?.meterCount,
      !!hasAmiMeter, // double negation can be removed with strict null checking
      {
        isDisabled:
          getDaysInRange(startDate, endDate) > MAX_CUSTOMER_BILLING_PERIOD_DAYS,
      }
    )

  // TODO: Remove this once sqFt returns as a number
  const squareFoot: number | null = useMemo(() => {
    const { sqFt } = site
    if (typeof sqFt === "string" && sqFt !== "") {
      return Number(sqFt)
    }
    return null
  }, [site])

  const timeWindow = {
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    startDate: startDate?.clone(),
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    endDate: endDate?.clone(),
    timeRange,
  }

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const electricityMetersWithEnergyAllocator:
    | MeterWithEnergyAllocator[]
    | undefined = useMemo(() => {
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (electricityMeter?.parentMeterAndChildData && siteFinancialSummaries) {
      // migration to strict mode batch disable
      // Mass eslint disable
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-condition
      const meters = electricityMeter?.parentMeterAndChildData.map((meter) => {
        // migration to strict mode batch disable
        // Mass eslint disable
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-condition
        const premise = siteFinancialSummaries?.find(
          (premises) =>
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Number(premises.billingGroupId) === Number(meter.billingGroupId)
        )
        // migration to strict mode batch disable
        // Mass eslint disable
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        const energyAllocator = premise?.energyAllocatorList?.find(
          (allocator) =>
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Number(allocator.energyAllocatorId) ===
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Number(meter.energyAllocatorId)
        )
        return {
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          id: Number(meter.id),
          // migration to strict mode batch disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          meterType: meter.meterType,
          // migration to strict mode batch disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          name: meter.name,
          // migration to strict mode batch disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          meterCode: meter.meterCode,
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          energyAllocator,
          // migration to strict mode batch disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          childMeterNamesAndIds: meter.childMeterNamesAndIds,
        }
      })
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return meters
    }
    return undefined
  }, [electricityMeter?.parentMeterAndChildData, siteFinancialSummaries])

  const isDailySummaryPanelDataLoading: boolean = useMemo(
    () =>
      isSiteExtraAttributesLoading ||
      isElectricityMeterLoading ||
      isSiteEnergyAllocationFetching,
    [
      isElectricityMeterLoading,
      isSiteEnergyAllocationFetching,
      isSiteExtraAttributesLoading,
    ]
  )

  const isMonthlySummaryGraphPanelDataLoading: boolean = useMemo(
    () =>
      isSiteExtraAttributesLoading ||
      isElectricityMeterLoading ||
      isMonthSummariesFetching,
    [
      isElectricityMeterLoading,
      isMonthSummariesFetching,
      isSiteExtraAttributesLoading,
    ]
  )

  const isMwhSelected = useMemo(
    () => wattUnit === UnitName.MegawattHour,
    [wattUnit]
  )

  // Adjust watt unit if kWh is less than 1k for visualization window
  useEffect(() => {
    if (isSiteEnergyAllocationFetched && !isMonthlySummaryVisible) {
      setWattUnit(
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        isAnyKWhIsLessThan1k(siteEnergyAllocation?.energyAllocations)
          ? UnitName.KilowattHour
          : UnitName.MegawattHour
      )
    }
  }, [
    isSiteEnergyAllocationFetched,
    siteEnergyAllocation,
    isMonthlySummaryVisible,
  ])

  // Ensure url has a valid start and end date for visualization window
  useEffect(() => {
    if (
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      startDate &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      endDate &&
      isServiceHourMinMaxDatesFetched &&
      !isMonthlySummaryVisible
    ) {
      // Defaulting start and end date to month with most recent
      // data if outside of service hour min max dates
      let clampedDateRange = {
        start: startDate,
        end: endDate,
      }
      if (
        !isDateRangeWithinBounds(clampedDateRange, {
          start: serviceHourMinMaxDates.start,
          end: serviceHourMinMaxDates.end,
        }) ||
        startDate.isAfter(endDate)
      ) {
        clampedDateRange = {
          start: serviceHourMinMaxDates.end.clone().startOf("month"),
          end: serviceHourMinMaxDates.end.clone().endOf("day"),
        }
      }

      // Clamp date range if start date is before service hour min date
      clampedDateRange = clampDateRange(clampedDateRange, {
        start: serviceHourMinMaxDates.start.clone().startOf("day"),
        end: serviceHourMinMaxDates.end.clone().endOf("day"),
      })

      const daysInRange = getDaysInRange(
        clampedDateRange.start,
        clampedDateRange.end
      )

      // Clamp date range if it is greater than 35 days
      if (
        timeRange === TIME_RANGES.MONTH &&
        daysInRange > MAX_CUSTOMER_BILLING_PERIOD_DAYS
      ) {
        const over35DaysUrlFilterEndValue = clampedDateRange.start
          .clone()
          .add(MAX_CUSTOMER_BILLING_PERIOD_DAYS - 1, "days")
          .endOf("day")
        const isNewEndValueBeforeAvailableData =
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          over35DaysUrlFilterEndValue.isBefore(serviceHourMinMaxDates?.end)
        clampedDateRange = clampDateRange(clampedDateRange, {
          start: clampedDateRange.start,
          end: isNewEndValueBeforeAvailableData
            ? over35DaysUrlFilterEndValue
            : // migrate directories to strict mode
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              serviceHourMinMaxDates?.end?.clone().endOf("day"),
        })
      }
      // Update url if the date range was clamped
      if (
        !clampedDateRange.start.isSame(startDate, "day") ||
        !clampedDateRange.end.isSame(endDate, "day")
      ) {
        updateDate(
          {
            start: clampedDateRange.start,
            end: clampedDateRange.end,
          },
          {
            replace: true,
          }
        )
      }
    }
  }, [
    endDate,
    startDate,
    timeRange,
    updateDate,
    serviceHourMinMaxDates.end,
    serviceHourMinMaxDates.start,
    isServiceHourMinMaxDatesFetched,
    isMonthlySummaryVisible,
  ])

  // Set start and end date for visualization window if they are not set in the url
  useEffect(() => {
    if (
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (!startDate || !endDate) &&
      isServiceHourMinMaxDatesFetched &&
      !isMonthlySummaryVisible
    ) {
      // Defaulting start and end date to month with most recent
      if (
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !nonMonthlyElectricityDateRange?.start?.isValid() ||
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !nonMonthlyElectricityDateRange?.end?.isValid() ||
        !isDateRangeWithinBounds(
          {
            start: nonMonthlyElectricityDateRange.start,
            end: nonMonthlyElectricityDateRange.end,
          },
          {
            start: serviceHourMinMaxDates.start,
            end: serviceHourMinMaxDates.end,
          }
        ) ||
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !nonMonthlyElectricityDateRange?.start?.isBefore(
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          nonMonthlyElectricityDateRange?.end
        )
      ) {
        let dateRange = {
          start: serviceHourMinMaxDates.end.clone().startOf("month"),
          end: serviceHourMinMaxDates.end.clone().endOf("day"),
        }

        // Clamp date range if start date is before service hour min date
        dateRange = clampDateRange(dateRange, {
          start: serviceHourMinMaxDates.start.clone().startOf("day"),
          end: serviceHourMinMaxDates.end.clone().endOf("day"),
        })

        updateDate(dateRange, {
          replace: true,
        })
      }
      // Set filters to stored electricityDateRange values
      else {
        updateDate(
          {
            start: nonMonthlyElectricityDateRange.start,
            end: nonMonthlyElectricityDateRange.end,
          },
          {
            replace: true,
          }
        )
      }
    }
  }, [
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    nonMonthlyElectricityDateRange?.end,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    nonMonthlyElectricityDateRange?.start,
    endDate,
    isMonthlySummaryVisible,
    isServiceHourMinMaxDatesFetched,
    serviceHourMinMaxDates.end,
    serviceHourMinMaxDates.start,
    startDate,
    updateDate,
  ])

  // Ensure url has a valid start and end date for monthly summary
  useEffect(() => {
    if (
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      startDate &&
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      endDate &&
      isMonthSummariesMinMaxDatesFetched &&
      isMonthlySummaryVisible
    ) {
      // Defaulting start and end date to current year start and most recent month of
      // data if outside of service hour min max dates
      let clampedDateRange = {
        start: startDate,
        end: endDate,
      }
      if (
        !isDateRangeWithinBounds(clampedDateRange, monthSummariesMinMaxDates) ||
        !startDate.isBefore(endDate)
      ) {
        clampedDateRange = {
          // migration to strict mode batch disable
          // Mass eslint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          start: monthSummariesMinMaxDates.end.clone().startOf("year"),
          // migration to strict mode batch disable
          // Mass eslint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          end: monthSummariesMinMaxDates.end.clone().endOf("month"),
        }
      }

      // Clamp date range if start date is before service hour min date
      clampedDateRange = clampDateRange(clampedDateRange, {
        // migration to strict mode batch disable
        // Mass eslint disable
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        start: monthSummariesMinMaxDates.start.clone().startOf("month"),
        // migration to strict mode batch disable
        // Mass eslint disable
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        end: monthSummariesMinMaxDates.end.clone().endOf("month"),
      })

      // Update url if the date range was clamped
      if (
        !clampedDateRange.start.isSame(startDate, "day") ||
        !clampedDateRange.end.isSame(endDate, "day")
      ) {
        updateDate(
          {
            start: clampedDateRange.start,
            end: clampedDateRange.end,
          },
          {
            replace: true,
          }
        )
      }
    }
  }, [
    endDate,
    isMonthSummariesMinMaxDatesFetched,
    isMonthlySummaryVisible,
    monthSummariesMinMaxDates,
    startDate,
    updateDate,
  ])

  // Set start and end date for monthly summary if they are not set in the url
  useEffect(() => {
    if (
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (!startDate || !endDate) &&
      isMonthSummariesMinMaxDatesFetched &&
      isMonthlySummaryVisible
    ) {
      // Defaulting start and end date to current year start and most recent month
      if (
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !monthlyElectricityDateRange?.start?.isValid() ||
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !monthlyElectricityDateRange?.end?.isValid() ||
        !isDateRangeWithinBounds(
          {
            start: monthlyElectricityDateRange.start,
            end: monthlyElectricityDateRange.end,
          },
          monthSummariesMinMaxDates
        ) ||
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !monthlyElectricityDateRange?.start?.isBefore(
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          monthlyElectricityDateRange?.end
        )
      ) {
        let dateRange = {
          // migration to strict mode batch disable
          // Mass eslint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          start: monthSummariesMinMaxDates.end.clone().startOf("year"),
          // migration to strict mode batch disable
          // Mass eslint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          end: monthSummariesMinMaxDates.end.clone().endOf("month"),
        }

        // Clamp date range if start date is before service hour min date
        dateRange = clampDateRange(dateRange, {
          // migration to strict mode batch disable
          // Mass eslint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          start: monthSummariesMinMaxDates.start.clone().startOf("month"),
          // migration to strict mode batch disable
          // Mass eslint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          end: monthSummariesMinMaxDates.end.clone().endOf("month"),
        })

        updateDate(dateRange, {
          replace: true,
        })
      }
      // Set filters to stored electricityDateRange values
      else {
        updateDate(
          {
            start: monthlyElectricityDateRange.start,
            end: monthlyElectricityDateRange.end,
          },
          {
            replace: true,
          }
        )
      }
    }
  }, [
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    monthlyElectricityDateRange?.end,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    monthlyElectricityDateRange?.start,
    endDate,
    isMonthSummariesMinMaxDatesFetched,
    isMonthlySummaryVisible,
    monthSummariesMinMaxDates,
    setMonthlyElectricityDateRange,
    startDate,
    updateDate,
  ])

  return (
    <>
      <PageHeaderActionBar>
        <HStack alignItems="center" justifyContent="space-between">
          {!isMonthlySummaryVisible && (
            <HStack alignItems="center" spacing={1}>
              <DateRangePicker
                isLoading={isServiceHourMinMaxDatesLoading}
                maxDate={serviceHourMinMaxDates.end}
                minDate={serviceHourMinMaxDates.start}
                onChange={updateDate}
                value={[startDate, endDate]}
              />
              <Typography
                component="span"
                sx={{ color: theme.palette.grey[400] }}
                variant="caption"
              >
                35 Day Max
              </Typography>
            </HStack>
          )}
          {isMonthlySummaryVisible && (
            <MonthRangePicker
              availableMaxMinMonths={monthSummariesMinMaxDates}
              onChange={handleOnDateRangeChange}
              value={{
                start: startDate,
                end: endDate,
              }}
            />
          )}
          <HStack>
            {siteInfoButton}
            <Collapse in={isSiteInfoOpen} orientation="horizontal">
              <Box width={pxToRem(320)} />
            </Collapse>
          </HStack>
        </HStack>
      </PageHeaderActionBar>
      <Page>
        <Paper sx={{ p: 3 }}>
          <ElectricityHeader
            dateRange={{ start: startDate, end: endDate }}
            electricityMetersWithEnergyAllocator={
              electricityMetersWithEnergyAllocator
            }
            isBundled={isBundled}
            onEnergyUsageUnitChange={onEnergyUsageUnitChange}
            openModalSiteMetersDetails={openModalSiteMetersDetails}
            setOpenModalSiteMetersDetails={setOpenModalSiteMetersDetails}
            siteHasSubMeter={hasSubMeter}
            siteIsAmi={hasAmiMeter}
            siteIsAmr={hasAmrMeter}
            siteIsProbe={probeMeter}
            wattUnit={wattUnit}
          />
          <Box
            sx={{ borderBottom: `1px solid ${lightGray.toString()}`, pb: 2 }}
          >
            {/* We should not load this component until the timeWindow is properly set */}
            {/* migrate directories to strict mode */}
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {timeWindow.startDate?.isValid() &&
              timeWindow.endDate.isValid() && (
                <>
                  {isMonthlySummaryVisible && (
                    <MonthlySummaryGraphPanel
                      axisUnit={EnergyUnit.WATTAGE}
                      isParentLoading={isMonthlySummaryGraphPanelDataLoading}
                      monthSummaries={monthSummaries}
                      site={site}
                      timeWindow={{
                        ...timeWindow,
                        timeRange: TIME_RANGES.YEAR,
                      }}
                      wattUnit={wattUnit}
                    />
                  )}
                  {!isMonthlySummaryVisible && (
                    <DailySummaryPanel
                      endDate={endDate}
                      // migration to strict mode batch disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      energyAllocations={
                        siteEnergyAllocation?.energyAllocations as EnergyAllocation[]
                      }
                      isBundled={isBundled}
                      isParentLoading={isDailySummaryPanelDataLoading}
                      site={site}
                      // migration to strict mode batch disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      siteEnergyAllocationStats={siteEnergyAllocation?.stats}
                      startDate={startDate}
                      timeRange={timeRange}
                      wattUnit={wattUnit}
                    />
                  )}
                </>
              )}
          </Box>
          <SitePerformance
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            currentYear={yearToDateRange?.start?.year()}
            emissionsAndEnergyVolume={
              // Each of these services has mutually exclusive enabling criteria that
              // would only allow one of them to be defined when they both complete
              // migrate directories to strict mode
              // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison, @typescript-eslint/no-unnecessary-condition
              site?.electricityType === ElectricityType.DirectAccess
                ? directEmissionsAndEnergyVolume
                : bundledEmissionsAndEnergyVolume
            }
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            gridRate={eia?.gridRate}
            isBundled={isBundled}
            isMwhSelected={isMwhSelected}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            siteEnergyAllocationStats={siteEnergyAllocation?.stats}
            siteIsAmi={hasAmiMeter}
            squareFoot={squareFoot}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            ytdMeteredKwh={ytdMeteredKwh?.meteredKwh}
          />
          {/* migrate directories to strict mode */}
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {!hasAmrMeter &&
            site.electricityType === "bundled" &&
            !Number.isNaN(electricityMeter?.meterCount) && (
              <BundledSiteFinancialSummaryPanel
                isParentLoading={
                  isSiteFinancialSummariesLoading || isElectricityMeterLoading
                }
                searchEndDate={endDate}
                searchStartDate={startDate}
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                siteMetersCount={electricityMeter?.meterCount}
                summaryContainerPremises={siteFinancialSummaries}
                timeWindow={timeWindow}
              />
            )}
          {!isBundled &&
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            timeWindow.startDate?.isValid() &&
            // migrate directories to strict mode
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            timeWindow.endDate?.isValid() && (
              <SiteOverview
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                energyAllocations={siteEnergyAllocation?.energyAllocations}
                isParentLoading={isDailySummaryPanelDataLoading}
                site={site}
                timeWindow={timeWindow}
              />
            )}
        </Paper>
      </Page>
    </>
  )
}

Electricity.defaultProps = {
  usageData: [],
}

export default Electricity
