// @ts-strict-ignore
import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"

import { ApiQueryName } from "../../../../models/api"
import { getSiteDailyNaturalGasEmissions } from "./useSiteDailyNaturalGasEmissionsUtils"

/**
  * Provides Site Emission day records for Natural Gas
  * @example
  * const { siteDailyNaturalGasEmissions, isSiteDailyNaturalGasEmissionsLoading } =
      useSiteDailyNaturalGasEmissions("1", {
          start: moment(),
          end: moment(),
      })
*/
export const useSiteDailyNaturalGasEmissions = (siteId, { dateRange }) => {
  const queryKey = useQueryKey(
    ApiQueryName.SiteDailyNaturalGasEmissions,
    "getMany",
    null,
    {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      siteId,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dateRange,
    }
  )

  const queryResult = useQuery({
    keepPreviousData: true,
    queryKey,
    queryFn: async () => getSiteDailyNaturalGasEmissions(siteId, dateRange),
    enabled:
      !!siteId &&
      !!dateRange &&
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      !!dateRange.start &&
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      !!dateRange.end &&
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      !!dateRange.start?.isBefore(dateRange.end),
  })
  return useRenamedQueryResult(queryKey, queryResult)
}
