import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { CombinedVolumePercent } from "@/components/combined-volume-percent"
import { useFormatDecimal } from "@/formatters/useFormatDecimal"
import { UnitName } from "@/models/unit"
import {
  DataGridCellPopover,
  DataGridColumnHeader,
  baseNumberColumnDef,
} from "@/nzds/data-grid"
import { Blur } from "@/nzds/ui-effects/blur"
import { translateUnit } from "@/utils/unit"

import { Stack, Typography } from "@mui/material"
import type {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"

type CommonSiteDataGridColumnName = "combinedEnergyUsage"

interface CommonSiteDataGridRow extends GridValidRowModel {
  annual_volume_electricity?: number | null
  annual_volume_nat_gas?: number | null
  combined_volume?: number | null
  id?: number | string
}

const calculateIsBlurred = (rowId: number | string): boolean => {
  return Number(rowId) < 0
}

interface UseCommonSiteDataGridColumnsProps {
  isBlurEnabled?: boolean
}

export const useCommonSiteDataGridColumns = <
  TRow extends CommonSiteDataGridRow,
>(
  { isBlurEnabled }: UseCommonSiteDataGridColumnsProps = {
    isBlurEnabled: false,
  }
): Record<CommonSiteDataGridColumnName, GridColDef<TRow>> => {
  const { i18n } = useTranslation()

  const { format: formatWholeNumber } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  const columns = useMemo(() => {
    return {
      combinedEnergyUsage: {
        ...baseNumberColumnDef,
        field: "combined_volume",
        headerName: "Combined Energy Usage",
        renderCell: (params: GridRenderCellParams<TRow>) => (
          <Blur
            isBlurred={
              isBlurEnabled ? calculateIsBlurred(params.row.id) : false
            }
          >
            <DataGridCellPopover
              content={
                <Stack gap={1}>
                  <Typography variant="body2">
                    Electricity:{" "}
                    {formatWholeNumber(
                      params.row.annual_volume_electricity ?? 0
                    )}{" "}
                    {translateUnit(i18n.language, UnitName.KilowattHour)}{" "}
                    <CombinedVolumePercent
                      total={params.row.combined_volume}
                      unit={UnitName.KilowattHour}
                      value={params.row.annual_volume_electricity}
                    />
                  </Typography>
                  <Typography variant="body2">
                    Natural Gas:{" "}
                    {formatWholeNumber(params.row.annual_volume_nat_gas ?? 0)}{" "}
                    {translateUnit(i18n.language, UnitName.Therm)}{" "}
                    <CombinedVolumePercent
                      total={params.row.combined_volume}
                      unit={UnitName.Therm}
                      value={params.row.annual_volume_nat_gas}
                    />
                  </Typography>
                </Stack>
              }
            >
              <span>{formatWholeNumber(params.row.combined_volume)}</span>
            </DataGridCellPopover>
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            field="combined_volume"
            headerName="Combined Energy Usage"
            tooltipContent="Sum of electricity
          and natural gas usage."
            unit={translateUnit(i18n.language, UnitName.KiloBritishThermalUnit)}
          />
        ),
        width: 220,
      },
    }
  }, [formatWholeNumber, i18n.language, isBlurEnabled])

  return columns
}
