import React, { useCallback, useEffect } from "react"

import { Grid, TextField as TextFieldMUI } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { useOptions } from "../../../../../../components/form"
import { FieldWrapper } from "../../../../../../components/form/fieldWrapper"
import { HStack } from "../../../../../../nzds"
import type { Meter as MeterModel } from "../../../../../../services"
import {
  useEnergyAllocators,
  useMeter,
  useMeterSourceTypes,
  useUpdateMeter,
} from "../../../../../../services"
import { METER_TYPE_OPTIONS } from "../../../../models/onboarding/onboarding"
import {
  TamForm,
  TamResetButton,
  TamSubmitButton,
  useTamForm,
} from "../../../tamForm"
import {
  TamFormDateTimePicker,
  TamFormSelectField,
  TamFormTextField,
  TamFormYesNoField,
} from "../../../tamForm/formFields"
import type { Meter } from "../../../tamForm/schemas/meter"
import { schema } from "../../../tamForm/schemas/meter"
import OrgViewDetailFooter from "../orgViewDetail/orgViewDetailFooter/orgViewDetailFooter"

interface OrgViewMeterDetailProps {
  linkSection: React.JSX.Element
  meterId: MeterModel["id"]
}

const OrgViewMeterDetail = ({
  meterId,
  linkSection,
}: OrgViewMeterDetailProps) => {
  const theme = useTheme()
  const { meter, isMeterLoading } = useMeter(meterId)
  const { updateOneMeter, isUpdateOneMeterLoading, isUpdateOneMeterSuccess } =
    useUpdateMeter()
  const { meterSourceTypes, isMeterSourceTypesLoading } = useMeterSourceTypes()

  const hasChildMeters = meter?.childMeterNamesAndIds?.length > 0

  const meterSourceTypeOptions = useOptions(
    meterSourceTypes,
    ({ name, id }) => ({
      label: name,
      value: id,
    })
  )

  const { energyAllocators, isEnergyAllocatorsLoading } = useEnergyAllocators()
  const energyAllocatorOptions = useOptions(
    energyAllocators,
    ({ name, id }) => ({
      label: name,
      value: id,
    })
  )

  const handleFormSubmit = useCallback(
    (newAttributes) => {
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      updateOneMeter(meterId, newAttributes)
    },
    [meterId, updateOneMeter]
  )
  const form = useTamForm({ schema, values: meter })

  useEffect(() => {
    if (isUpdateOneMeterSuccess) {
      form.reset({}, { keepValues: true })
    }
  }, [form, isUpdateOneMeterSuccess])

  if (
    isMeterSourceTypesLoading ||
    isMeterLoading ||
    isEnergyAllocatorsLoading
  ) {
    return null
  }

  return (
    <TamForm form={form} name="meter" onSubmit={handleFormSubmit}>
      <HStack
        alignItems="flex-start"
        flexGrow={1}
        gap={0.5}
        sx={{
          overflowY: "auto",
          py: 1,
          pr: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<Meter>
                dataE2E="meter-name-input"
                label="Meter Name"
                name="name"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<Meter>
                dataE2E="meter-id-input"
                disabled
                label="ID"
                name="id"
                readOnly
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<Meter>
                dataE2E="meter-status-input"
                disabled
                label="Meter Status"
                name="meterStatus"
                readOnly
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<Meter>
                dataE2E="meter-code-input"
                label="Meter Code"
                name="meterCode"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<Meter>
                dataE2E="meter-provider-input"
                label="Meter Provider"
                name="meterProvider"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormSelectField<Meter>
                dataE2E="meter-type-input"
                label="Meter Type"
                name="meterType"
                options={METER_TYPE_OPTIONS}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormSelectField<Meter>
                dataE2E="energy-allocator-input"
                label="Energy Allocator"
                name="energyAllocatorId"
                options={energyAllocatorOptions}
              />
            </Grid>
            {/* TO-DO: Remove readOnly when we better understand full meters */}
            <Grid item xs={2}>
              <TamFormYesNoField<Meter>
                dataE2E="full-meter-input"
                disabled
                label="Full Meter"
                name="fullMeter"
                readOnly
              />
            </Grid>
            {/* TO-DO: Remove readonly when we fix energy allocator relationship issue. */}
            <Grid item xs={4}>
              <TamFormSelectField<Meter>
                disabled
                label="Meter Source Type"
                name="meterSourceTypeId"
                options={meterSourceTypeOptions}
                readOnly
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={2}>
              <TamFormYesNoField<Meter>
                dataE2E="net-meter-input"
                label="Net Meter?"
                name="isNetMeter"
              />
            </Grid>
            <Grid item xs={4}>
              <TamFormYesNoField<Meter>
                dataE2E="on-site-generation-input"
                label="On site generation?"
                name="isOnSiteGeneration"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormYesNoField<Meter>
                dataE2E="probe-meter-input"
                label="Probe Meter?"
                name="probeMeter"
              />
            </Grid>
            {/* TO-DO: Rework this field when child meters is running properly */}
            <Grid item xs={3}>
              <FieldWrapper label="Has Child Meters?" name="hasChildMeter">
                <TextFieldMUI
                  data-e2e="child-meter-input"
                  disabled
                  fullWidth
                  inputProps={{ readOnly: true }}
                  size="small"
                  value={hasChildMeters ? "Yes" : "No"}
                />
              </FieldWrapper>
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xl={8} xs={10}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  height: "100%",
                }}
              >
                <TamFormDateTimePicker<Meter>
                  dataE2E="meter-start-date-input"
                  label="Start Date"
                  name="startDate"
                />
                <div
                  style={{
                    width: theme.typography.pxToRem(30),
                    height: theme.typography.pxToRem(1),
                    backgroundColor: theme.palette.neutral.light,
                    marginLeft: theme.typography.pxToRem(8),
                    marginRight: theme.typography.pxToRem(8),
                    marginTop: theme.typography.pxToRem(50),
                  }}
                />
                <TamFormDateTimePicker<Meter>
                  dataE2E="meter-end-date-input"
                  label="End Date"
                  name="endDate"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </HStack>
      <OrgViewDetailFooter linkSection={linkSection}>
        <TamResetButton dataE2E="meter-reset-button" />
        <TamSubmitButton
          dataE2E="meter-submit-button"
          submitting={isUpdateOneMeterLoading}
        />
      </OrgViewDetailFooter>
    </TamForm>
  )
}

export default OrgViewMeterDetail
