// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ElectricityEmissionsFactorRecord } from "../../../models/scopeTwo"
import type { ITableColumn, ITableRow } from "../../../models/table"
import { UnitName } from "../../../models/unit"
import type { Report } from "./report"
import type { ResourceSummaryMonthRecord } from "./resourceSummary"

interface MonthlySiteReportResultBase
  extends Omit<
    ResourceSummaryMonthRecord,
    | "advancedGridStudyCo2ELbs"
    | "locationElectricityCo2ELbs"
    | "naturalGasCo2ELbs"
    | "fuelCo2ELbs"
    | "refrigerantCo2ELbs"
  > {
  advancedGridStudyMtCo2e: number
  fuelMtCo2e: number
  locationElectricityMtCo2e: number
  naturalGasMtCo2e: number
  refrigerantMtCo2e: number
  totalMtCo2e: number
}

type MonthlySiteReportResult = MonthlySiteReportResultBase &
  Record<string, string>

type MonthlySiteReport = Report<MonthlySiteReportTableRow>

type MonthlySiteReportTableRow = MonthlySiteReportResult & ITableRow

// Design currently only specifies these columns for display
type DisplayColumnAttributes =
  | "siteName"
  | "siteId"
  | "kwh"
  | "advancedGridStudyMtCo2e"
  | "electricityEmissionsFactorMtCo2e"
  | "locationElectricityMtCo2e"
  | "naturalGasMtCo2e"
  | "naturalGasThms"
  | "totalMtCo2e"
type ValidDisplayColumnAttributes = keyof Pick<
  MonthlySiteReportResult,
  DisplayColumnAttributes
>

/*
  This type is used to ensure that the key types are coming from the MonthlySiteReportResult
  attributes and, are the ones that design specifies, and that they are capitalized
*/
type DisplayMonthlySiteReportKeys = {
  [K in ValidDisplayColumnAttributes as Capitalize<K>]: K
}

// Used for accessing column header titles
enum MonthlySitesReportColumnName {
  ElectricityEmissionsFactorMtCo2e = "Electricity Emissions",
  Kwh = "Electricity Usage",
  NaturalGasMtCo2e = "Natural Gas Emissions",
  NaturalGasThms = "Natural Gas Usage",
  SiteId = "Site ID",
  SiteName = "Site Name",
  TotalMtCo2e = "Total Emissions",
}

enum MonthlySitesReportColumnUnit {
  AdvancedGridStudyMtCo2e = UnitName.MetricTonsOfCarbonDioxideEquivalent,
  ElectricityEmissionsFactorMtCo2e = UnitName.MetricTonsOfCarbonDioxideEquivalent,
  Kwh = UnitName.KilowattHour,
  LocationElectricityMtCo2e = UnitName.MetricTonsOfCarbonDioxideEquivalent,
  NaturalGasMtCo2e = UnitName.MetricTonsOfCarbonDioxideEquivalent,
  NaturalGasThms = UnitName.Therm,
  TotalMtCo2e = UnitName.MetricTonsOfCarbonDioxideEquivalent,
}

// Used for mapping columns to data properties by key
const MonthlySitesReportKey: DisplayMonthlySiteReportKeys = {
  AdvancedGridStudyMtCo2e: "advancedGridStudyMtCo2e",
  ElectricityEmissionsFactorMtCo2e: "electricityEmissionsFactorMtCo2e",
  Kwh: "kwh",
  LocationElectricityMtCo2e: "locationElectricityMtCo2e",
  NaturalGasMtCo2e: "naturalGasMtCo2e",
  NaturalGasThms: "naturalGasThms",
  SiteId: "siteId",
  SiteName: "siteName",
  TotalMtCo2e: "totalMtCo2e",
}

const defaultMonthlySiteReportColumns: ITableColumn<MonthlySiteReportTableRow>[] =
  [
    {
      id: 0,
      order: 0,
      title: MonthlySitesReportColumnName.SiteName,
      key: "siteName",
    },
    {
      id: 1,
      order: 1,
      title: MonthlySitesReportColumnName.SiteId,
      key: "siteId",
    },
  ]

enum MonthlySiteReportReportName {
  MonthlySiteReport = "Monthly Site Report",
}

const emissionOptions: string[] = [
  MonthlySitesReportKey.TotalMtCo2e,
  MonthlySitesReportKey.Kwh,
  MonthlySitesReportKey.ElectricityEmissionsFactorMtCo2e,
  MonthlySitesReportKey.NaturalGasMtCo2e,
  MonthlySitesReportKey.NaturalGasThms,
]

const emissionOptionLabel: Record<string, string> = {
  [MonthlySitesReportKey.Kwh]: MonthlySitesReportColumnName.Kwh,
  [MonthlySitesReportKey.ElectricityEmissionsFactorMtCo2e]:
    MonthlySitesReportColumnName.ElectricityEmissionsFactorMtCo2e,
  [MonthlySitesReportKey.NaturalGasThms]:
    MonthlySitesReportColumnName.NaturalGasThms,
  [MonthlySitesReportKey.NaturalGasMtCo2e]:
    MonthlySitesReportColumnName.NaturalGasMtCo2e,
  [MonthlySitesReportKey.TotalMtCo2e]: MonthlySitesReportColumnName.TotalMtCo2e,
}

export {
  emissionOptions,
  emissionOptionLabel,
  MonthlySitesReportColumnName,
  type MonthlySiteReportResult,
  MonthlySitesReportColumnUnit,
  defaultMonthlySiteReportColumns,
  type MonthlySiteReportTableRow,
  MonthlySiteReportReportName,
  type MonthlySiteReport,
  MonthlySitesReportKey,
}
