/* eslint-disable react/require-default-props */
import React, { forwardRef, useState } from "react"

import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material"
import type { SelectChangeEvent } from "@mui/material/Select"

import {
  ITEM_HEIGHT,
  ITEM_PADDING_TOP,
} from "./multi-select-checkbox-field.constants"
import type { MultiSelectCheckboxFieldProps } from "./multi-select-checkbox-field.types"

const DefaultMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      marginTop: "0.25rem",
    },
  },
}

/**
 * Select field with multiple checkboxes
 */
export const MultiSelectCheckboxField = forwardRef<
  HTMLDivElement,
  MultiSelectCheckboxFieldProps
>(
  (
    {
      checkboxes,
      disabled,
      error,
      fullWidth,
      helperText,
      id,
      label,
      name,
      onChange,
      renderValue,
      MenuProps,
      inputRef,
      sx,
      value = [],
      ...rest
    },
    ref
  ) => {
    let renderValueFn = renderValue
    if (!renderValueFn) {
      renderValueFn = (selected: string[]) =>
        selected
          .map(
            (val) =>
              checkboxes.find((checkbox) => checkbox.value === val)?.label
          )
          .join(", ")
    }
    const handleChange = (event: SelectChangeEvent<typeof value>) => {
      const {
        target: { value: eventValue },
      } = event
      if (onChange && Array.isArray(eventValue)) {
        onChange(eventValue)
      }
    }

    const [open, setOpen] = useState(false)

    const handleToggleSelect = () => {
      setOpen((prevOpen) => !prevOpen)
    }

    const handleOpenSelectIfNeeded = () => {
      if (open) {
        return
      }
      setOpen(true)
    }

    const handleKeyDownFormControl = (
      event: React.KeyboardEvent<HTMLDivElement>
    ) => {
      switch (event.code) {
        case "Enter": {
          handleOpenSelectIfNeeded()
          break
        }
        case "Space": {
          handleOpenSelectIfNeeded()
          break
        }

        default:
          break
      }
    }

    return (
      <FormControl
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        onKeyDown={handleKeyDownFormControl}
        sx={sx}
      >
        <InputLabel data-testid={id} id={id} onClick={handleToggleSelect}>
          {label}
        </InputLabel>
        <Select
          {...rest}
          ref={ref}
          id={`${id}-select`}
          input={<OutlinedInput label={label} />}
          inputRef={inputRef}
          labelId={id}
          MenuProps={MenuProps ?? DefaultMenuProps}
          multiple
          name={name}
          onChange={handleChange}
          onClick={handleOpenSelectIfNeeded}
          onClose={handleToggleSelect}
          open={open}
          renderValue={renderValueFn}
          value={value}
          variant="outlined"
        >
          {checkboxes.map((checkbox) => (
            <MenuItem key={checkbox.value} value={checkbox.value}>
              <Checkbox checked={value.includes(checkbox.value)} />
              <ListItemText primary={checkbox.label} />
            </MenuItem>
          ))}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    )
  }
)
