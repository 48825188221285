import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { useUrl } from "@/utils/url"

import { Link, Tooltip, useTheme } from "@mui/material"

import { EmissionDecimal } from "../../../../../../components/decimal/emissionDecimal/emissionDecimal"
import { If } from "../../../../../../components/if/if"
import type {
  ResourceSummaryReportTableColumn,
  ResourceSummaryReportTableRow,
} from "../../../../models/resourceSummary"
import { extraRows, specialSiteIds } from "../../../../models/resourceSummary"
import { isOrgLevelSite, isUniqueSite } from "../../../../util/util"

import styles from "./virtualizedReportingTableContent.module.scss"

interface ComponentProps {
  columnIndex: number
  columns: ResourceSummaryReportTableColumn[]
  // TODO: Remove isUseFlexibleHierarchyEnabled prop when flexible hierarchy is fully enabled
  isUseFlexibleHierarchyEnabled: boolean
  rowIndex: number
  rows: ResourceSummaryReportTableRow[]
  style: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
}

export const VirtualizedReportingTableContent = ({
  rows,
  columns,
  columnIndex,
  rowIndex,
  style,
  isUseFlexibleHierarchyEnabled,
}: ComponentProps) => {
  const theme = useTheme()
  const { buildSiteUrl } = useUrl()

  if (!rows.length) {
    return <></>
  }

  const row = rows[rowIndex - extraRows]
  const isOrg: boolean = isOrgLevelSite(row.siteId)
  const isUnique: boolean = isUniqueSite(row)

  const rowValues: (number | string | null)[] = columns.map(
    (column) => row[column.key]
  )

  const uniqueSites: ResourceSummaryReportTableRow[] = rows.filter((site) =>
    isUniqueSite(site)
  )

  const uniqueRowClass: string =
    (isUnique && row === uniqueSites[uniqueSites.length - 1]) ||
    (isOrg && uniqueSites.length === 0)
      ? styles.uniqueRowContent
      : styles.nonUniqueRowContent

  switch (columnIndex) {
    case 0:
      return (
        <>
          <If condition={isOrg || isUnique}>
            <div
              className={`${uniqueRowClass} ${styles.rowContent} ${styles.stickyColumn}`}
              style={style}
            >
              <Tooltip placement="bottom-start" title={row.siteName}>
                <span className="paragraph--small paragraph--small__regular ">
                  {row?.siteName}
                </span>
              </Tooltip>
            </div>
          </If>
          <If condition={!isOrg && !isUnique}>
            <div
              className={`${uniqueRowClass} ${styles.rowContent} ${styles.stickyColumn} paragraph--small paragraph--small__regular`}
              data-e2e="site-name-row"
              style={style}
            >
              <Link
                component={RouterLink}
                rel="noopener noreferrer"
                sx={{
                  whiteSpace: "nowrap",
                  maxWidth: theme.typography.pxToRem(250),
                  display: "inline-block",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                target="_blank"
                to={buildSiteUrl(row.siteId)}
              >
                <Tooltip placement="bottom-start" title={row?.siteName}>
                  <span>{row?.siteName}</span>
                </Tooltip>
              </Link>
            </div>
          </If>
        </>
      )
    case 1:
      return (
        <div
          className={`${uniqueRowClass} ${styles.rowContent}`}
          data-e2e="table-content"
          style={style}
        >
          <If condition={!specialSiteIds.includes(row.siteId)}>
            <span className="paragraph--small paragraph--small__regular">
              {row?.siteId}
            </span>
          </If>
        </div>
      )
    case 2:
      return (
        <div
          className={`${uniqueRowClass} ${styles.rowContent} ${styles.department}`}
          style={style}
        >
          <span className="paragraph--small paragraph--small__regular">
            {/* TODO: remove departmentName use when flexible hierarchy is fully enabled  */}
            {isUseFlexibleHierarchyEnabled
              ? row?.organizationalUnitName
              : row?.departmentName}
          </span>
        </div>
      )

    default:
      return (
        <div className={`${uniqueRowClass} ${styles.rowContent}`} style={style}>
          <span
            className={`paragraph--small paragraph--small__regular ${styles.visibleRowContent}`}
          >
            <EmissionDecimal value={rowValues[columnIndex]} />
          </span>
        </div>
      )
  }
}
