import type { Moment } from "moment"
import moment from "moment"

import Organization from "../../../../models/organization"
import type { IRange } from "../../../../models/range"

/**
 * This returns the the min and max dates of
 * metered hour with cost data for an organization.
 *
 * @param organizationId - The organization id
 * @returns IRange<Moment> The available min and max dates of metered hour with cost data for an organization
 *
 * @example
 * ```tsx
 * import { fetchMinMaxMeteredCostRange } from '../services/useMinMaxMeteredCostRange/useMinMaxMeteredCostRangeUtils'
 *
 * const minMaxMeteredCostRange = await fetchMinMaxMeteredCostRange(8)
 * ```
 */
const fetchMinMaxMeteredCostRange = async (
  organizationId: string
): Promise<IRange<Moment>> => {
  const orgIdx = 0
  const hourIdx = 1
  const stat = "metered_hour_with_cost"
  return Organization.where({
    id: organizationId,
  })
    .stats({
      [stat]: ["maximum", "minimum"],
    })
    .all()
    .then(({ meta }) => {
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const maximumMeterHours: [number, string][] = meta.stats[stat].maximum
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const minimumMeterHours: [number, string][] = meta.stats[stat].minimum
      const maxHour = maximumMeterHours.find(
        (value) => value[orgIdx] === Number(organizationId)
      )[hourIdx]
      const minHour = minimumMeterHours.find(
        (value) => value[orgIdx] === Number(organizationId)
      )[hourIdx]

      if (moment(maxHour) > moment()) {
        return {
          start: moment(minHour),
          end: moment(),
        }
      }

      return {
        start: moment(minHour),
        end: moment(maxHour),
      }
    })
}

export { fetchMinMaxMeteredCostRange }
