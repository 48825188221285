import { pick } from "lodash"
import { z } from "zod"

import type {
  GetAttributeFields,
  GetRelationshipIdFields,
  GetRelationshipNames,
  SchemaDefinition,
} from "./types"

export type FormValidation<
  SchemaT extends SchemaDefinition,
  IncludesT extends GetRelationshipNames<SchemaT>,
> = z.ZodObject<
  GetAttributeFields<SchemaT> & GetRelationshipIdFields<SchemaT, IncludesT>
>

export const generateValidation = <
  SchemaT extends SchemaDefinition,
  IncludesT extends GetRelationshipNames<SchemaT>,
>(
  schema: SchemaT,
  includes: null | IncludesT[] = null
) => ({
  form: () =>
    z.object({
      // Deliberately omitting full relationships here.
      ...pick(schema.zodSchema.shape, schema.attributeNames),
      ...schema.relationshipNames.reduce((obj, fieldName) => {
        // Mass lint disable
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
        if (includes !== null && !includes.includes(fieldName as any)) {
          return obj
        }
        const { isArray } = schema.relationshipsMeta[fieldName]
        const baseSchema =
          schema.zodSchema.shape[fieldName] instanceof z.ZodNullable
            ? z.string().nullable()
            : z.string()

        return {
          ...obj,
          [isArray ? `${fieldName}Ids` : `${fieldName}Id`]: schema
            .relationshipsMeta[fieldName].isArray
            ? z.array(baseSchema)
            : baseSchema,
        }
      }, {}),
    }) as FormValidation<SchemaT, IncludesT>,
})
