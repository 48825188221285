/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react"
import type { FC } from "react"

import { HStack } from "@/nzds"
import { DialogTitle } from "@/nzds/feedback/dialog-title/dialog-title"
import { usePrevious } from "@uidotdev/usehooks"
import { filesize } from "filesize"

import CloseIconRounded from "@mui/icons-material/CloseRounded"
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material"
import { visuallyHidden } from "@mui/utils"

import type { UploadCsvDialogProps } from "./upload-csv-dialog.types"

export const UploadCsvDialog: FC<UploadCsvDialogProps> = ({
  hasUploaded,
  isSubmitting,
  onClose,
  onUpload,
  open,
}) => {
  const [file, setFile] = React.useState<File | undefined>(undefined)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target.files?.[0]
    if (fileInput) {
      setFile(fileInput)
    }
  }

  const previousOpen = usePrevious(open)

  useEffect(() => {
    if (previousOpen && !open) {
      setFile(undefined)
    }
  }, [open, previousOpen])

  const readableSize = file
    ? filesize(file.size, { standard: "jedec" })
    : undefined

  const handleUpload = () => {
    if (file) {
      onUpload(file)
    }
  }

  const handleClose = () => {
    setFile(undefined)
    onClose()
  }

  return (
    <Dialog
      aria-describedby="upload-dialog-description"
      aria-labelledby="upload-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="upload-dialog-title" onClose={handleClose}>
        Upload CSV
      </DialogTitle>
      <DialogContent id="upload-dialog-description">
        <Stack gap={2}>
          {hasUploaded ? (
            <Typography>
              Uploading a new CSV will replace the current CSV used in REP. This
              action cannot be undone.
            </Typography>
          ) : null}
          <Card variant="contained">
            <CardContent>
              {file ? (
                <Stack gap={2}>
                  <HStack justifyContent="space-between">
                    <Stack gap={1}>
                      <Typography fontWeight="fontWeightBold" variant="body1">
                        {file.name}
                      </Typography>
                      <Typography variant="body2">{readableSize}</Typography>
                    </Stack>
                    {!isSubmitting ? (
                      <IconButton
                        aria-label={`Remove file ${file.name}`}
                        onClick={() => {
                          setFile(undefined)
                        }}
                        size="small"
                      >
                        <CloseIconRounded />
                      </IconButton>
                    ) : null}
                  </HStack>
                  {isSubmitting ? <LinearProgress /> : null}
                </Stack>
              ) : (
                <>
                  <Box
                    accept=".csv"
                    component="input"
                    id="contained-button-file"
                    onChange={handleChange}
                    sx={visuallyHidden}
                    type="file"
                  />
                  <HStack alignItems="center" gap={3}>
                    <label htmlFor="contained-button-file">
                      <Button component="span" variant="contained">
                        Choose file
                      </Button>
                    </label>
                    <Typography variant="body2">No File Selected</Typography>
                  </HStack>
                </>
              )}
            </CardContent>
          </Card>
          <HStack gap={2} justifyContent="space-between">
            <Typography variant="body2">Supported Format: CSV</Typography>
            <Typography variant="body2">Max File Size: 20MB</Typography>
          </HStack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!file || isSubmitting}
          onClick={handleUpload}
          variant="contained"
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  )
}
