export enum FeatureActorType {
  Organization = "Organization",
}

export interface FeatureGate {
  key: string
  name: string
  value: string[] | null
}

export enum FeatureState {
  Conditional = "conditional",
  Off = "off",
  On = "on",
}

export interface Feature {
  gates: FeatureGate[]
  key: string
  state: FeatureState
}
