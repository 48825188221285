import React from "react"

import type { UnitName } from "../../../models/unit"
import { If } from "../../if/if"
import { Unit } from "../../unit/unit"
import type { IDecimalProps } from "../decimal/decimal"
import { Decimal } from "../decimal/decimal"
import { DecimalPlaceholder } from "../decimalPlaceholder/decimalPlaceholder"

export interface IDecimalWithPlaceholderProps extends IDecimalProps {
  placeholder?: string
  replaceZeroWithPlaceholder?: boolean
  unit?: UnitName
}

/**
 * A component that properly formats and internationalizes the provided decimal and displays a placeholder
 *
 * @param props - The component properties
 * @param props.value - The decimal
 * @param props.format - The format configuration for the decimal
 * @param props.placeholder - The placeholder to render instead of the decimal
 * @param props.replaceZeroWithPlaceholder - Whether to consider 0 as an empty value
 * @param props.unit - The unit to display
 * @param props.useAccountingFormatWhenNegative - Whether to wrap negative decimals in parenthesis or not
 * @example
 * return (
 *   <DecimalWithPlaceholder value={waterUse} format={{
 *     precision: 2
 *   }}
 *   replaceZeroWithPlaceholder={true} />
 * )
 */
export const DecimalWithPlaceholder = ({
  value,
  format,
  replaceZeroWithPlaceholder,
  placeholder,
  unit,
  useAccountingFormatWhenNegative,
}: IDecimalWithPlaceholderProps) => (
  <>
    <DecimalPlaceholder
      placeholder={placeholder}
      replaceZeroWithPlaceholder={replaceZeroWithPlaceholder}
      value={value}
    >
      <Decimal
        format={format}
        useAccountingFormatWhenNegative={useAccountingFormatWhenNegative}
        value={value}
      />
    </DecimalPlaceholder>
    <If condition={unit}>
      {" "}
      <Unit unit={unit} value={value} />
    </If>
  </>
)
