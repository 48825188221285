export const allConsultantsTeamName = "All Consultants"
export const allCustomersTeamName = "All Customers"
export const allQATeamName = "All QA"
export const allSitesTeamName = "All Sites"
export const allConsultantsWorkspaceName = allConsultantsTeamName
export const allCustomersWorkspaceName = allCustomersTeamName
export const allQAWorkspaceName = allQATeamName
export const allSitesWorkspaceName = allSitesTeamName

export const consultantTeamNames = [
  allConsultantsWorkspaceName,
  allQAWorkspaceName,
  allSitesWorkspaceName,
]
export const consultantWorkspaceNames = [...consultantTeamNames]

export interface SigmaAccessToken {
  accessToken: string
  expiresIn: number
  refreshToken: string
  tokenType: string
}

export type SigmaEmbedSourceType = "page" | "workbook"

export interface SigmaPaginatedResponse<ResponseT> {
  entries: ResponseT[]
  hasMore: boolean
  nextPage: string | null
  total: number
}

export type SigmaTeamVisibility = "public" | "private"

export interface SigmaTeam {
  createdAt: string
  createdBy: string
  description: string
  isArchived: boolean
  name: string
  teamId: string
  updatedAt: string
  updatedBy: string
  visibility: SigmaTeamVisibility
}

export interface SigmaWorkbook {
  createdAt: string
  createdBy: string
  id: string // We're adding id to the response, we shouldn't do this
  latestVersion: number
  name: string
  ownerId: string
  path: string // e.g. "Boyd", "Engineering", "ENVS"
  updatedAt: string
  updatedBy: string
  url: string
  workbookId: string
  workbookUrlId: string
}

export interface SigmaWorkbookEmbed {
  embedId: string
  embedUrl: string
  public: boolean
  sourceId: string
  sourceName: string
  sourceType: SigmaEmbedSourceType
}
