import { isNull, isNumber, isString } from "lodash-es"

export type SelectValue = null | number | string
export type SelectOptionProp =
  | SelectValue
  | { label?: string; value: SelectValue }
export interface SelectOptionParsed {
  label: string
  value: SelectValue
}

const isPrimitive = (subject: unknown) =>
  isNull(subject) || isNumber(subject) || isString(subject)

// Mass lint disable
// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
const isOption = (subject: unknown) => (subject as any)?.value !== undefined

/**
 * Extract a value from an option prop.
 *
 * @param subject An option `{ value: 'foo', label?: 'Foo' }` or a value primitive
 * @returns A value primitive
 */
export const getSelectOptionValue = (
  subject: SelectOptionProp
): SelectValue => {
  if (isPrimitive(subject)) {
    return subject as SelectValue
  }
  if (isOption(subject)) {
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    return (subject as any).value
  }
  throw new Error("Invalid option")
}

/**
 * Extract a label from an option prop.
 *
 * @param subject An option `{ value: 'foo', label?: 'Foo' }` or a value primitive
 * @returns A label string, defaulting to the value
 */
export const getSelectOptionLabel = (
  subject: undefined | SelectOptionProp
): string => {
  if (isPrimitive(subject)) {
    return String(subject)
  }
  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  if (typeof (subject as any).label === "string") {
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    return (subject as any).label
  }
  const value = getSelectOptionValue(subject)
  return value ? String(value) : ""
}

/**
 * Convert an option prop to a strict option object.
 *
 * @param subject An value primitive or an option `{ value: 'foo', label?: 'Foo' }`
 * @returns An parsed option object with a value and a label
 */
export const parseSelectOption = (
  subject: SelectOptionProp
): SelectOptionParsed => ({
  value: getSelectOptionValue(subject),
  label: getSelectOptionLabel(subject),
})

/**
 * Finds an option in an array of options matching a value or an option prop.
 *
 * @param options An array of option props to search IN
 * @param option A value or an option prop to search WITH
 * @returns An parsed option object with a value and a label
 */
export const findSelectOption = (
  options: SelectOptionProp[],
  option: SelectOptionProp
): undefined | SelectOptionParsed => {
  const { value } = parseSelectOption(option)
  const thisOption = options.find((o) => parseSelectOption(o).value === value)
  if (thisOption === undefined) {
    return undefined
  }
  return parseSelectOption(thisOption)
}

/**
 * Checks if two option props have the same value.
 *
 * @param optionA An option prop
 * @param optionB An option prop
 * @returns Whether or not they are equal
 */
export const isOptionsEqual = (
  optionA: SelectOptionProp,
  optionB: SelectOptionProp
): boolean => getSelectOptionValue(optionA) === getSelectOptionValue(optionB)
