import type DateFilter from "../../../models/filter/dateFilter"
import type MultiSelectStringFilter from "../../../models/filter/multiSelectStringFilter"
import { Scope as ScopeEnum } from "../../../models/scope"
import type { ITableColumn, ITableRow } from "../../../models/table"
import { getReportMultiSelectStringFilterByScope } from "../util/util"
import type { Report } from "./report"

export interface ActivityEmissionsFilters {
  end: DateFilter
  filter: MultiSelectStringFilter
  start: DateFilter
}

interface ActivityEmissionsReportResult {
  amountConsumed: number
  category: string
  emissionSource: string
  mtCo2E: number
  scope: ScopeEnum
  unitsForAmountConsumed: string
}

type ActivityEmissionsReport = Report<ActivityEmissionsReportTableRow>

type ActivityEmissionsReportTableRow = ActivityEmissionsReportResult & ITableRow

// Columns
enum ActivityEmissionsReportColumnName {
  ActivityData = "Activity Data",
  Category = "Category",
  EmissionSource = "Emission Source",
  Scope = "Scope",
  TotalEmissionsMtCo2e = "Total Emissions (MT CO2e)",
  Unit = "Unit",
}

enum ActivityEmissionsReportKey {
  AmountConsumed = "amountConsumed",
  Category = "category",
  EmissionSource = "emissionSource",
  MtCo2e = "mtCo2E",
  Scope = "scope",
  Unit = "unitsForAmountConsumed",
}

const activityEmissionsReportColumns: ITableColumn<ActivityEmissionsReportTableRow>[] =
  [
    {
      id: 0,
      order: 0,
      title: ActivityEmissionsReportColumnName.EmissionSource,
      key: ActivityEmissionsReportKey.EmissionSource,
    },
    {
      id: 1,
      order: 1,
      title: ActivityEmissionsReportColumnName.Scope,
      key: ActivityEmissionsReportKey.Scope,
    },
    {
      id: 2,
      order: 2,
      title: ActivityEmissionsReportColumnName.Category,
      key: ActivityEmissionsReportKey.Category,
    },
    {
      id: 3,
      order: 3,
      title: ActivityEmissionsReportColumnName.TotalEmissionsMtCo2e,
      key: ActivityEmissionsReportKey.MtCo2e,
    },
    {
      id: 4,
      order: 4,
      title: ActivityEmissionsReportColumnName.ActivityData,
      key: ActivityEmissionsReportKey.AmountConsumed,
    },
    {
      id: 5,
      order: 5,
      title: ActivityEmissionsReportColumnName.Unit,
      key: ActivityEmissionsReportKey.Unit,
    },
  ]

const ActivityEmissionsScopeCheckboxes = [
  {
    label: `Scope ${ScopeEnum.One} (Direct Emissions)`,
    value: getReportMultiSelectStringFilterByScope(ScopeEnum.One),
  },
  {
    label: `Scope ${ScopeEnum.Two} (Indirect Emissions)`,
    value: getReportMultiSelectStringFilterByScope(ScopeEnum.Two),
  },
  {
    label: `Scope ${ScopeEnum.Three} (Indirect Emissions)`,
    value: getReportMultiSelectStringFilterByScope(ScopeEnum.Three),
  },
  {
    label: "Water",
    value: "water",
  },
]

enum ActivityEmissionsReportName {
  EmissionsSources = "Emissions Sources",
}

export {
  type ActivityEmissionsReportResult,
  ActivityEmissionsScopeCheckboxes,
  activityEmissionsReportColumns,
  ActivityEmissionsReportColumnName,
  type ActivityEmissionsReportTableRow,
  ActivityEmissionsReportName,
  type ActivityEmissionsReport,
  ActivityEmissionsReportKey,
}
