import type { ScopeEmissionBase } from "../../../../models/scope"
import ScopeOneReportingData from "../../../../models/scopeOne"
import { snakeCaseToCamelCase } from "../../../../utils"

interface FetchScopeOneEmissionsProps {
  endMonth: string
  organizationId: number
  startMonth: string
  year: string
}

export interface FetchScopeOneEmissionsValue extends ScopeEmissionBase {
  categoryId: number
  categoryName: string
  subcategoryId: number
  subcategoryName: string
}

interface FetchScopeOneReportingDataFilters {
  end_month: string
  organization_ids: number
  start_month: string
  year: string
}

export const fetchScopeOneEmissions = ({
  organizationId,
  startMonth,
  endMonth,
  year,
}: FetchScopeOneEmissionsProps): Promise<FetchScopeOneEmissionsValue[]> => {
  const filters: FetchScopeOneReportingDataFilters = {
    organization_ids: organizationId,
    year,
    start_month: startMonth,
    end_month: endMonth,
  }

  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return ScopeOneReportingData.where(filters)
    .stats({
      scope_one_reporting_data: "calculate",
    })
    .all()
    .then((result) => {
      const stats = result.meta.stats as Record<
        string,
        Record<string, FetchScopeOneEmissionsValue[]>
      >
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return (
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        snakeCaseToCamelCase(stats.scope_one_reporting_data.calculate).map(
          (emission: FetchScopeOneEmissionsValue) => ({
            ...emission,
            // The API returns mtCo2E as a string, but it's a number
            mtCo2E: Number.parseFloat(emission.mtCo2E as unknown as string),
          })
        )
      )
    })
}
