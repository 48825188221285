// @ts-strict-ignore
import { useCallback, useMemo } from "react"
import { useQuery, useQueryClient } from "react-query"

import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"

interface MonthlyElectricityDateRangeValue {
  monthlyElectricityDateRange: IRange<Moment>
  setMonthlyElectricityDateRange: (dateRange: IRange<Moment>) => void
}

/**
 * A service for saving and providing the date range for a site's monthly electricity
 *
 * @returns - The monthlyElectricity date range service
 * @example
 * const { monthlyElectricityDateRange, setMonthlyElectricityDateRange } = useMonthlyElectricityDateRange()
 */
export const useMonthlyElectricityDateRange =
  (): MonthlyElectricityDateRangeValue => {
    const queryClient = useQueryClient()
    const queryKey = ApiQueryName.MonthlyElectricityDateRange

    const { data } = useQuery({
      cacheTime: Infinity,
      queryFn: (): Promise<IRange<Moment>> =>
        Promise.resolve(queryClient.getQueryData(queryKey)),
      queryKey,
      staleTime: Infinity,
    })

    const monthlyElectricityDateRange = useMemo(
      () => data,
      [data]
    ) as unknown as IRange<Moment>

    const setMonthlyElectricityDateRange = useCallback(
      (dateRange: IRange<Moment>) => {
        queryClient.setQueryData(queryKey, dateRange)
      },
      [queryClient, queryKey]
    )

    return useMemo(
      () => ({
        monthlyElectricityDateRange,
        setMonthlyElectricityDateRange,
      }),
      [monthlyElectricityDateRange, setMonthlyElectricityDateRange]
    )
  }
