import type { Organization } from "@/models/organization"
import type { Site } from "@/models/site"
import type { Operations } from "@/services/types"
import { httpClient } from "@/utils"

export interface DeleteSitePlanProps {
  organizationId: Organization["id"]
  siteId: Site["id"]
}

/**
 * makes a delete request to delete a site plan
 */
export const deleteSitePlan = async ({
  organizationId,
  siteId,
}: DeleteSitePlanProps): Promise<
  Operations["delete_site_plan_api_v1_plans__site_id__delete"]["responses"]["200"]
> => {
  const response = await httpClient.delete<
    Operations["delete_site_plan_api_v1_plans__site_id__delete"]["responses"]["200"]
  >(`/api/v1/recommendations/plans/${siteId}`, {
    params: {
      organization_id: organizationId,
    },
  })

  return response.data
}
