import React from "react"

import { useTheme } from "@mui/material"

import type { CustomXAxisTickProps } from "./monthly-stacked-area-graph.types"

/**
 *
 * @param param0
 * @returns This is a custom x-axis tick component for the monthly stacked area graph.
 * It displays the year and adds a gap between the years
 */
export const CustomXAxisTick: React.FC<CustomXAxisTickProps> = ({
  x,
  y,
  payload,
}: CustomXAxisTickProps) => {
  const gapWidth = 8
  const theme = useTheme()
  if (payload.value) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          dx={11}
          dy={16}
          fill={theme.palette.grey[800]}
          fontSize="10px"
          textAnchor="middle"
        >
          {payload.value}
        </text>
        <rect
          fill={theme.palette.common.white}
          height={2}
          width={gapWidth}
          x={-gapWidth}
          y={0}
        />
      </g>
    )
  }
  return null
}
