import { client } from "@/services/client"
import type { Paths } from "@/services/types"

export const getGoals = async (
  params: Paths["/api/v1/recommendations/goals"]["get"]["parameters"]
) => {
  const { data, error } = await client.GET("/api/v1/recommendations/goals", {
    params,
  })

  if (data) {
    return data
  }

  throw error as Error
}
