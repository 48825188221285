import type { QueryKey } from "react-query"
import { useQuery } from "react-query"

import { ApiQueryName } from "../../models/api"
import Organization from "../../models/organization"
import { useAuthentication } from "../authentication/useAuthentication"

// TODO: We should have/use a single fetchOrganizations function
// that takes all necessary parameters
export const fetchAuthorizedOrganizations = async (): Promise<
  Organization[]
> => {
  try {
    const organizations = await Organization.order("name")
      .all()
      .then((response) => response.data)

    return organizations
  } catch (e) {
    console.error(e)
    return []
  }
}

// Export so that we can reset this query upon logout
export const useAuthorizedOrganizationsQueryKey: QueryKey = [
  ApiQueryName.AuthorizedOrganizations,
]

/**
 * Returns the authorized organizations for the authenticated user
 *
 * @example
 * const { data: authorizedOrganizations } = useAuthorizedOrganizations()
 */
export const useAuthorizedOrganizations = () => {
  const { authenticatedUser, isAuthenticated } = useAuthentication()

  const queryFn = async (): Promise<Organization[]> =>
    await fetchAuthorizedOrganizations()

  const queryResult = useQuery<Organization[]>({
    cacheTime: Infinity,
    // Both isAuthenticated and authenticatedUser could be undefined
    enabled: Boolean(isAuthenticated) && Boolean(authenticatedUser),
    queryKey: useAuthorizedOrganizationsQueryKey,
    queryFn,
    staleTime: Infinity,
  })

  return queryResult
}
