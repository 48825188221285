import { filter, flatMap, groupBy, keys, map, pick, sum } from "lodash-es"
import type { Moment } from "moment"
import moment from "moment"
import { Attr, Model } from "spraypaint"
import { CollectionProxy } from "spraypaint/lib-esm/proxies/collection-proxy"
import type { FieldArg, IncludeScope } from "spraypaint/lib-esm/scope"

import Product from "../components/graph/product"
import {
  renameKeysToSnakeCase,
  snakeCaseToCamelCase,
} from "../utils/formatters"
import ApplicationRecord from "./applicationRecord/applicationRecord"
import type Confirm from "./confirm"
import type Site from "./site"
import type { SitePremiseFinancialSummary } from "./sitePremiseFinancialSummary"

interface ICarbonFreeOrBasedStatsProps {
  dayStart: string
  site: Site
  yearStart: string
}

export interface Allocation {
  carbonIntensity: number | null
  co2eLbs: number | null
  confirmCostCents: number
  confirmLineLossCostCents: number
  displayKwh: number
  hour: moment.Moment
  hours: moment.Moment[]
  id: number
  imbalanceCostCents: number
  imbalanceLineLossCostCents: number
  meteredKwh: number | null
  products: Product[]
  schedulingCostCents: number
  serviceHourIds: number[]
}

export enum ElectricityType {
  Bundled = "bundled",
  DirectAccess = "direct_access",
}

interface ICarbonBasedAndFreeStats {
  carbon_stats: {
    calculate: {
      carbon_based_kwh: string | undefined
      carbon_free_kwh: string | undefined
      co2e_lbs: string | undefined
    }[]
  }
}

@Model()
export default class EnergyAllocation extends ApplicationRecord {
  public static jsonapiType = "energy_allocations"

  @Attr() public kwh: number

  @Attr() public hour: string

  @Attr() public meteredKwh: number

  @Attr() public taggedKwh: number

  @Attr() public imbalanceKwh: number

  @Attr() public isCurtailed: boolean

  @Attr() public serviceHourId: number

  @Attr() public etagHourId: number

  @Attr() public billingGroupId: string

  @Attr() public emissionsRates: {
    carbon_equivalent_emissions?: string
  } | null

  @Attr() public confirmId: number

  @Attr() public imbalancePriceCents: number

  @Attr() public imbalanceCostCents: number

  @Attr() public confirmPriceCents: number

  @Attr() public confirmLineLossCostCents: number

  @Attr() public imbalanceLineLossCostCents: number

  @Attr() public confirmCostCents: number

  @Attr() public contractPriceCents: number

  @Attr() public schedulingCostCents: number

  @Attr() public allocationId: string

  @Attr() public obligationCents: number

  @Attr() public generationSource: string

  @Attr() public isFirm: boolean

  @Attr() public isResourceBased: boolean

  @Attr() public confirm: Confirm | null

  @Attr() public stackOrder: number

  @Attr() public electricityType: string

  @Attr() public energyProviderName: string

  @Attr() public co2eLbs: number

  public productOrder: number

  // Derived attribute: carbonIntensity
  public get carbonIntensity(): number | null {
    if (
      this.meteredKwh !== null &&
      this.co2eLbs !== null &&
      this.co2eLbs !== 0
    ) {
      return this.meteredKwh / this.co2eLbs
    }
    return null
  }

  static fetchBillingGroups = ({
    siteId,
    searchStartDate,
    searchEndDate,
  }: {
    searchEndDate: Moment
    searchStartDate: Moment
    siteId: string | number
  }): Promise<number[]> =>
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    EnergyAllocation.where({
      site_id: siteId,
      start_time: searchStartDate.format(),
      end_time: searchEndDate.endOf("day").format(),
    })
      .stats({
        billing_groups: "sum",
      })
      .per(0)
      .all()
      .then(
        // Mass lint disable
        // Mass eslint disable @typescript-eslint/no-unsafe-return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
        (resolvedResponse) => resolvedResponse.meta.stats.billing_groups.sum
      )

  static fetchFinancialSummary = ({
    siteId,
    searchStartDate,
    searchEndDate,
    billingGroupId,
  }): Promise<SitePremiseFinancialSummary> =>
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    EnergyAllocation.where({
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      site_id: siteId,
      // migration to strict mode batch disable
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      start_time: searchStartDate.format(),
      // migration to strict mode batch disable
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      end_time: searchEndDate.format(),
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      billing_group_id: billingGroupId,
    })
      .stats({
        financial_summaries_for_premise: "calculate",
      })
      .per(0)
      .all()
      .then((resolvedFinancialSummariesPerPremise) =>
        // Mass eslint disable @typescript-eslint/no-unsafe-return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        snakeCaseToCamelCase(
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          resolvedFinancialSummariesPerPremise.meta.stats
            .financial_summaries_for_premise.calculate
        )
      )

  static reduceToSalesFactors(energyAllocations: readonly EnergyAllocation[]): {
    hour: moment.Moment
    meteredKwh: number
    totalCostCents: number
  } {
    return energyAllocations.reduce(
      (
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        acc: { hour: any; meteredKwh: number; totalCostCents: number },
        current: EnergyAllocation
      ) => ({
        hour: current.hour,
        totalCostCents: (current.obligationCents || 0) + acc.totalCostCents,
        meteredKwh: (current.meteredKwh || 0) + acc.meteredKwh,
      }),
      { hour: null, totalCostCents: 0, meteredKwh: 0 }
    )
  }

  static async fetchInPages({
    clause,
    includes = "",
    selects = [],
    stats = {},
  }: {
    clause: {
      [x: string]: string
      endTime: string
      organizationId?: string
      siteId?: string
      startTime: string
    }
    includes?: IncludeScope
    selects?: FieldArg
    stats?: Record<string, string>
  }): Promise<CollectionProxy<EnergyAllocation>> {
    const defaultStats = { total: "count" }
    const whereObj = renameKeysToSnakeCase(clause)
    const metaParams = pick(whereObj, [
      "start_time",
      "end_time",
      "site_id",
      "organization_id",
    ])
    const energyAllocationsMeta = await EnergyAllocation.where(metaParams)
      .stats(defaultStats)
      .per(0)
      .all()

    // migration to strict mode batch disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    let recordCount = energyAllocationsMeta.meta.stats.total.count
    const promises: Promise<CollectionProxy<EnergyAllocation>>[] = []
    let pageCount = 1
    while (recordCount > 0) {
      promises.push(
        EnergyAllocation.where(whereObj)
          .stats(stats)
          .includes(includes)
          .order("id")
          .select(selects)
          .per(1000)
          .page(pageCount)
          .all()
      )
      pageCount += 1
      recordCount -= 1000
    }
    const energyAllocations = await Promise.all(promises)
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const energyAllocationStats: Record<string, Record<string, number>>[] =
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      energyAllocations.map((ea) => ea.meta.stats)
    const reduceStats = (statsArray: typeof energyAllocationStats) => {
      const statKeys: string[][] = Object.entries(stats)
      if (statsArray.length === 0) {
        return
      }

      return statsArray.reduce((accumulatedStats, current) => {
        statKeys.forEach(([statKey, statKeyType]) => {
          if (
            accumulatedStats &&
            statKey in accumulatedStats &&
            statKeyType in accumulatedStats[statKey] &&
            current &&
            statKey in current &&
            statKeyType in current[statKey]
          ) {
            accumulatedStats[statKey][statKeyType] =
              current[statKey][statKeyType] +
              accumulatedStats[statKey][statKeyType]
          } else if (
            current &&
            statKey in current &&
            statKeyType in current[statKey] &&
            accumulatedStats &&
            !(statKey in accumulatedStats)
          ) {
            accumulatedStats[statKey] = current[statKey]
          }
        })
        return accumulatedStats
      })
    }

    return new CollectionProxy<EnergyAllocation>(
      flatMap(energyAllocations, (ea) => ea.data),
      {
        data: flatMap(
          energyAllocations,
          (energyAllocationsCollection) => energyAllocationsCollection.raw.data
        ),
        meta: { stats: reduceStats(energyAllocationStats) },
      }
    )
  }

  static getLossAdjustedConfirmCosts(
    energyAllocations: readonly Allocation[]
  ): number {
    return energyAllocations.reduce((acc: number, allocation: Allocation) => {
      const lossAdjustedConfirmCost =
        (allocation.confirmCostCents || 0) -
        (allocation.confirmLineLossCostCents || 0)
      return lossAdjustedConfirmCost / 100.0 + acc
    }, 0)
  }

  static getLossAdjustedImbalanceCosts(
    energyAllocations: Allocation[]
  ): number {
    return energyAllocations.reduce((acc, allocation) => {
      const lossAdjustedImbalanceCost =
        (allocation.imbalanceCostCents || 0.0) -
        (allocation.imbalanceLineLossCostCents || 0.0)
      return lossAdjustedImbalanceCost / 100.0 + acc
    }, 0)
  }

  static getLineLossCosts(energyAllocations: Allocation[]): number {
    return energyAllocations.reduce((acc, allocation) => {
      const lineLossCosts =
        (allocation.confirmLineLossCostCents || 0) +
        (allocation.imbalanceLineLossCostCents || 0)
      return lineLossCosts / 100.0 + acc
    }, 0)
  }

  static getSchedulingCosts(energyAllocations: Allocation[]): number {
    return energyAllocations.reduce(
      (acc, allocation) => (allocation.schedulingCostCents || 0) / 100.0 + acc,
      0
    )
  }

  static confirmCostsSum(
    allocations: { confirmCostCents: number | null }[]
  ): number {
    return sum(map(allocations, (a) => a.confirmCostCents || 0))
  }

  static meteredKwhSum(allocations: { meteredKwh: number | null }[]): number {
    return sum(map(allocations, (a) => a.meteredKwh || 0))
  }

  static confirmLineLossCostSum(
    allocations: { confirmLineLossCostCents: number | null }[]
  ): number {
    return sum(map(allocations, (a) => a.confirmLineLossCostCents || 0))
  }

  static imbalanceCostSum(
    allocations: { imbalanceCostCents: number | null }[]
  ): number {
    return sum(map(allocations, (a) => a.imbalanceCostCents || 0))
  }

  static imbalanceLineLossCostSum(
    allocations: { imbalanceLineLossCostCents: number | null }[]
  ): number {
    return sum(map(allocations, (a) => a.imbalanceLineLossCostCents || 0))
  }

  static schedulingCostSum(
    allocations: { schedulingCostCents: number | null }[]
  ): number {
    return sum(map(allocations, (a) => a.schedulingCostCents || 0))
  }

  static displayKwh(allocations): number {
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    return sum(map(allocations, (a) => Math.abs(a.kwh)))
  }

  static displayCo2eLbs(allocations): number {
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    return sum(map(allocations, (a) => Math.abs(a.co2eLbs)))
  }

  static meteredAllocations(allocations): EnergyAllocation[] {
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return filter(
      allocations,
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (alloc) => alloc.meteredKwh != null && alloc.meteredKwh !== 0
    )
  }

  static groupId(allocations): number {
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
    return allocations[0].serviceHourId
  }

  static aggregateByHour(energyAllocations: EnergyAllocation[]): Allocation[] {
    const energyAllocationsByHour = groupBy(
      energyAllocations,
      (allocation) => allocation.hour
    )
    const allocationHours = keys(energyAllocationsByHour)
    const sortedAllocationHours = allocationHours.sort((a, b) =>
      moment(a).diff(moment(b))
    )

    return sortedAllocationHours.map((hour) => {
      const hourAllocations = energyAllocationsByHour[hour]
      const displayKwh = EnergyAllocation.displayKwh(hourAllocations)
      const co2eLbs = EnergyAllocation.displayCo2eLbs(hourAllocations)
      const id = EnergyAllocation.groupId(hourAllocations)
      const serviceHourIds = [id]
      const meteredAllocations =
        EnergyAllocation.meteredAllocations(hourAllocations)
      const meteredKwh = meteredAllocations
        ? EnergyAllocation.meteredKwhSum(meteredAllocations)
        : null
      const carbonIntensity = co2eLbs / displayKwh
      const products = Product.sort(
        Product.productsFromEnergyAllocations(hourAllocations)
      )
      return {
        hour: moment(hour),
        hours: [moment(hour)],
        id,
        serviceHourIds,
        products,
        displayKwh,
        meteredKwh,
        co2eLbs,
        carbonIntensity,
        confirmCostCents: EnergyAllocation.confirmCostsSum(hourAllocations),
        confirmLineLossCostCents:
          EnergyAllocation.confirmLineLossCostSum(hourAllocations),
        imbalanceCostCents: EnergyAllocation.imbalanceCostSum(hourAllocations),
        imbalanceLineLossCostCents:
          EnergyAllocation.imbalanceLineLossCostSum(hourAllocations),
        schedulingCostCents:
          EnergyAllocation.schedulingCostSum(hourAllocations),
      }
    })
  }

  static aggregateByDay(energyAllocations: EnergyAllocation[]): Allocation[] {
    const energyAllocationsByDay = groupBy(energyAllocations, (alloc) =>
      moment(alloc.hour).startOf("day").toISOString()
    )
    const days = keys(energyAllocationsByDay)
    const sortedDays = days.sort((a, b) => moment(a).diff(moment(b)))

    return sortedDays.map((day) => {
      const dayAllocations = energyAllocationsByDay[day]
      const meteredAllocations =
        EnergyAllocation.meteredAllocations(dayAllocations)
      const meteredKwh = EnergyAllocation.meteredAllocations(dayAllocations)
        ? EnergyAllocation.meteredKwhSum(meteredAllocations)
        : null
      const displayKwh = EnergyAllocation.displayKwh(dayAllocations)
      const co2eLbs = EnergyAllocation.displayCo2eLbs(dayAllocations)
      const hour = moment(dayAllocations[0].hour)
      const hours = dayAllocations.map((serviceHour) =>
        moment(serviceHour.hour)
      )
      const serviceHourIds = map(dayAllocations, (a) => a.serviceHourId)
      const id = serviceHourIds[0]

      const carbonIntensity = co2eLbs / displayKwh

      const products = Product.sort(
        Product.productsFromEnergyAllocations(dayAllocations)
      )

      return {
        hour,
        hours,
        displayKwh,
        co2eLbs,
        products,
        id,
        serviceHourIds,
        meteredKwh,
        carbonIntensity,
        confirmCostCents: EnergyAllocation.confirmCostsSum(dayAllocations),
        confirmLineLossCostCents:
          EnergyAllocation.confirmLineLossCostSum(dayAllocations),
        imbalanceCostCents: EnergyAllocation.imbalanceCostSum(dayAllocations),
        imbalanceLineLossCostCents:
          EnergyAllocation.imbalanceLineLossCostSum(dayAllocations),
        schedulingCostCents: EnergyAllocation.schedulingCostSum(dayAllocations),
      }
    })
  }

  static firstAllocationForEachConfirm(
    energyAllocations: EnergyAllocation[]
  ): EnergyAllocation[] {
    const allocationsWithConfirms = energyAllocations.filter((allocation) =>
      allocation.isConfirmAllocation()
    )
    const allocationsGroupedByConfirm = groupBy(
      allocationsWithConfirms,
      (allocation) => allocation.confirmId
    )
    return map(
      keys(allocationsGroupedByConfirm),
      (confirmId) => allocationsGroupedByConfirm[confirmId][0]
    )
  }

  static carbonBasedAndFreeStats({
    yearStart,
    dayStart,
    site,
  }: ICarbonFreeOrBasedStatsProps): Promise<ICarbonBasedAndFreeStats> {
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      this.stats({
        carbon_stats: "calculate",
      })
        .where({
          start_time: yearStart,
          end_time: dayStart,
          site_id: site.id,
        })
        .per(0)
        .all()
        // Mass eslint disable @typescript-eslint/no-unsafe-return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .then(({ meta }) => meta.stats)
    )
  }

  displayPrice() {
    if (this.allocationId === "imbalance") {
      return this.imbalancePriceCents
    }
    if (this.isBundledEnergyCustomer()) {
      return this.imbalancePriceCents
    }

    return this.contractPriceCents
  }

  displayCost() {
    if (this.obligationCents !== undefined) {
      return this.obligationCents
    }
    if (this.allocationId.includes("confirm")) {
      return this.confirmCostCents
    }
    if (this.allocationId === "imbalance") {
      return this.imbalanceCostCents
    }
    if (this.allocationId === "curtailment") {
      return this.confirmCostCents
    }
  }

  isConfirmAllocation() {
    return this.confirm !== null && this.allocationId.includes("confirm")
  }

  isBundledEnergyCustomer(): boolean {
    return this.electricityType === ElectricityType.Bundled
  }
}
