import type { ChangeEventHandler, FC } from "react"
import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"

import { Button, InputAdornment, Stack } from "@mui/material"

import { useCurrencySymbol } from "../../../../hooks/useCurrencySymbol/useCurrencySymbol"
import { HStack, NumericTextField } from "../../../../nzds"
import { buildSearchParamsString } from "../../../../utils/urls"
import type { FilterBarProps } from "./filter-bar.types"

function isValidTarget(reduction_target: string | undefined) {
  return Number(reduction_target) <= 100
}

export const FilterBar: FC<FilterBarProps> = ({ goal }) => {
  const currencySymbol = useCurrencySymbol()

  const [searchParams, setSearchParams] = useSearchParams()

  // the inputs get the default values from site goal, but if a search param exists that
  // takes precedence

  const [budget, setBudget] = useState(
    searchParams.has("budget")
      ? (searchParams.get("budget") ?? undefined)
      : goal?.budget
        ? `${goal.budget}`
        : ""
  )

  const [reduction_target, setReductionTarget] = useState(
    searchParams.get("reduction_target")
      ? (searchParams.get("reduction_target") ?? undefined)
      : goal?.reduction_goal
        ? `${goal.reduction_goal * 100}`
        : ""
  )

  const [reduction_target_error, setReductionTargetError] = useState("")

  const handleChangeMaxBudget: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setBudget(event.target.value)
  }

  const handleChangeReductionTarget: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setReductionTarget(event.target.value)
    if (reduction_target_error && isValidTarget(event.target.value)) {
      setReductionTargetError("")
    }
  }

  const handleValidateReductionTarget = () => {
    if (!isValidTarget(reduction_target)) {
      setReductionTargetError("Reduction target must be less than 100%")
    } else {
      setReductionTargetError("")
    }
  }

  const handleUpdate = () => {
    if (reduction_target_error) {
      return
    }
    const params: Record<string, string | string[] | undefined> = {
      budget,
      reduction_target,
    }
    const newSearchParamsString = buildSearchParamsString(params, searchParams)
    setSearchParams(newSearchParamsString)
  }

  return (
    <Stack gap={2} mb={2}>
      <HStack alignItems="flex-start" gap={2} minHeight={85}>
        <NumericTextField
          error={Boolean(reduction_target_error)}
          helperText={reduction_target_error}
          id="reduction_target"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="Emission Reduction"
          name="reduction_target"
          NumericFormatProps={{
            decimalScale: 0,
            allowNegative: false,
          }}
          onBlur={handleValidateReductionTarget}
          onChange={handleChangeReductionTarget}
          sx={{ flex: 1 }}
          value={reduction_target}
        />
        <NumericTextField
          fullWidth
          id="budget"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{currencySymbol}</InputAdornment>
            ),
          }}
          label="Max budget"
          name="budget"
          NumericFormatProps={{
            decimalScale: 0,
            allowNegative: false,
          }}
          onChange={handleChangeMaxBudget}
          sx={{ flex: 1 }}
          value={budget}
        />

        <Button
          color="primary"
          onClick={handleUpdate}
          sx={{
            marginTop: "28px",
          }}
          variant="outlined"
        >
          Update
        </Button>
      </HStack>
    </Stack>
  )
}
