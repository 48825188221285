import React from "react"

interface ComponentProps {
  children?: React.ReactNode
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: any
}

/**
 * A component that conditionally displays its children
 *
 * @param props {ComponentProps} - The component properties
 * @param props.children {React.ReactNode} - The child nodes to render
 * @param props.condition {any} - The condition that determines if the children are rendered
 * @example
 * return (
 *   <If condition={hasPermission}>
 *     <ManageUsers />
 *   </If>
 * )
 */
export const If = ({ children, condition }: ComponentProps) => (
  <>{!!condition && children}</>
)
