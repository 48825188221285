import { useMemo } from "react"

import type { Moment } from "moment"

import type { IRange } from "../../../../models/range"
import { useEnergyStats } from "../useEnergyStats/useEnergyStats"
import { useOrgSummaryStats } from "../useOrgSummaryStats/useOrgSummaryStats"
import type {
  IDepartmentSummary,
  OrganizationChildrenSummary,
} from "../useOrgSummaryStats/useOrgSummaryStatsUtils"

const useScope2CarbonIntensity = (
  orgId: string,
  dateRange: IRange<Moment>,
  options?: { isBundled?: boolean; isUseFlexibleHierarchyEnabled?: boolean }
): {
  isScope2CarbonIntensityFetched: boolean
  isScope2CarbonIntensityFetching: boolean
  isScope2CarbonIntensityLoading: boolean
  scope2CarbonIntensityStats: {
    energyStats: Awaited<ReturnType<typeof useEnergyStats>["energyStats"]>
    organizationSummaryStats:
      | OrganizationChildrenSummary[]
      | IDepartmentSummary[]
  }
} => {
  const {
    energyStats,
    isEnergyStatsFetched,
    isEnergyStatsFetching,
    isEnergyStatsLoading,
  } = useEnergyStats(orgId, dateRange)
  const {
    organizationSummaryStats,
    isOrganizationSummaryStatsFetched,
    isOrganizationSummaryStatsFetching,
    isOrganizationSummaryStatsLoading,
  } = useOrgSummaryStats(orgId, dateRange, {
    isUseFlexibleHierarchyEnabled: options?.isUseFlexibleHierarchyEnabled,
    isBundled: options?.isBundled,
  })

  // TODO: Remove this once the flexible hierarchy feature is enabled by default
  const typedOrganizationSummaryStats = organizationSummaryStats as
    | OrganizationChildrenSummary[]
    | IDepartmentSummary[]

  return useMemo(
    () => ({
      scope2CarbonIntensityStats: {
        energyStats,
        organizationSummaryStats: typedOrganizationSummaryStats,
      },
      isScope2CarbonIntensityFetching:
        isOrganizationSummaryStatsFetching || isEnergyStatsFetching,
      isScope2CarbonIntensityLoading:
        isEnergyStatsLoading || isOrganizationSummaryStatsLoading,
      isScope2CarbonIntensityFetched:
        isEnergyStatsFetched && isOrganizationSummaryStatsFetched,
    }),
    [
      energyStats,
      typedOrganizationSummaryStats,
      isOrganizationSummaryStatsFetching,
      isEnergyStatsFetching,
      isEnergyStatsLoading,
      isOrganizationSummaryStatsLoading,
      isEnergyStatsFetched,
      isOrganizationSummaryStatsFetched,
    ]
  )
}

export { useScope2CarbonIntensity }
