// @ts-strict-ignore
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react"
import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"

import { ApiQueryName } from "../../../../models/api"
import type Site from "../../../../models/site"
import { fetchPricesForLAP } from "./useSiteLoadAggregationPointAndPricesUtils"

/**
 * Provides prices for a site's load aggregation point filtered by
 *  14 days in the past unless otherwise specified
 *
 * @example
 * const {
 *  siteLoadAggregationPointPrices,
 *  isSiteLoadAggregationPointPricesLoading,
 *  isSiteLoadAggregationPointPricesIdle,
 *  isSiteLoadAggregationPointPricesSuccess,
 *  isSiteLoadAggregationPointPricesFetched,
 *  isSiteLoadAggregationPointPricesFetching,
 * } = useLAPPrices(site)
 */

export const useLAPPrices = (site: Site) => {
  const queryKey = useQueryKey(
    ApiQueryName.SiteLoadAggregationPointPrices,
    "getMany",
    null,
    site
  )

  const queryResult = useQuery({
    keepPreviousData: true,
    queryKey,
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    queryFn: async () => fetchPricesForLAP(site?.loadAggregationPointId, 14),
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    enabled: !!site?.loadAggregationPointId,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
