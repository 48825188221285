import { spawn } from "redux-saga/effects"

import { watchUserSettings } from "../modules/settings"

// TODO: let's figure out the best way to start the sagas in our app:
// https://redux-saga.js.org/docs/advanced/RootSaga/

/**
 * The application's root saga. All of the app's sagas are conbined here.
 *
 * @yields
 */
export default function* rootSaga() {
  yield spawn(watchUserSettings)
}
