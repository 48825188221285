import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Page, PageCard, PageHeader, PageLoading } from "@/components"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { HStack } from "@/nzds"
import { useDisclosure } from "@/nzds/utils/use-disclosure/use-disclosure"

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import {
  Button,
  Divider,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material"

import { useGetProfiles } from "../../services/use-get-profiles/use-get-profiles"
import { useUploadProfilesCsv } from "../../services/use-upload-profiles-csv/use-upload-profiles-csv"
import { ErrorDialogContent } from "../components/error-dialog-content/error-dialog-content"
import { ErrorDialog } from "../components/error-dialog/error-dialog"
import { TemplateGuidelinesDialog } from "../components/template-guidelines-dialog/template-guidelines-dialog"
import { UploadCsvDialog } from "../components/upload-csv-dialog/upload-csv-dialog"
import { templateExportLink } from "../constants"
import { buildErrorMessage } from "../utils/build-error-message"
import { RepIndexHero } from "./components/rep-index-hero/rep-index-hero"

export const RepIndexRoute = () => {
  const { organization } = useOrganizationContext()

  const {
    isOpen: templateGuidelinesIsOpen,
    onClose: templateGuidelinesOnClose,
    onOpen: templateGuidelinesOnOpen,
  } = useDisclosure()

  const {
    isOpen: uploadIsOpen,
    onOpen: uploadOnOpen,
    onClose: uploadOnClose,
  } = useDisclosure()

  const {
    isOpen: errorIsOpen,
    onOpen: errorOnOpen,
    onClose: errorOnClose,
  } = useDisclosure()

  const { isLoading, data, isFetched } = useGetProfiles({
    parameters: { query: { organization_id: Number(organization?.id) } },
    queryOptions: { enabled: Boolean(organization) },
  })

  const {
    mutate,
    reset: resetUpload,
    isLoading: isUploading,
    error: uploadError,
  } = useUploadProfilesCsv()

  const errorContent = buildErrorMessage(uploadError)

  const navigate = useNavigate()

  // close upload dialog and open error dialog if there was an error
  useEffect(() => {
    if (uploadError) {
      uploadOnClose()
      errorOnOpen()
    }
    if (data?.items && data.items.length > 0) {
      navigate("/rep/results", { replace: true })
    }
  }, [data, errorOnOpen, navigate, resetUpload, uploadError, uploadOnClose])

  const steps = [
    {
      label: "Download Template",
      description:
        "Download our template, input your site data along with other essential information, and export the file in CSV format.",
      action: (
        <HStack gap={1}>
          <Button
            color="secondary"
            component="a"
            download
            href={templateExportLink}
            onClick={templateGuidelinesOnOpen}
            size="small"
            startIcon={<FileDownloadOutlinedIcon />}
            variant="contained"
          >
            Download Template
          </Button>
          <Button
            color="primary"
            onClick={templateGuidelinesOnOpen}
            size="small"
            variant="outlined"
          >
            Template Guidelines
          </Button>
        </HStack>
      ),
    },
    {
      label: "Upload Populated CSV Sheet",
      description:
        "Upload the completed CSV sheet for on the spot analysis of performance and regulatory risk.",
      action: (
        <Button
          color="primary"
          onClick={uploadOnOpen}
          size="small"
          startIcon={<FileUploadOutlinedIcon />}
          variant="outlined"
        >
          Upload
        </Button>
      ),
    },
    {
      label: "Explore Potential Sites",
      description:
        "Explore sites identified as potential hotspots by our data models, comparing them to benchmarks and their respective locations.",
    },
  ]

  const handleUpload = (file: File) => {
    if (!organization?.id) {
      return
    }

    mutate({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      requestBody: { file } as any,
      parameters: { query: { organization_id: Number(organization.id) } },
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <PageHeader title="Rapid Emissions Profile" />
      {isFetched && data?.items && data.items.length === 0 ? (
        <Page>
          <PageCard>
            <Stack gap={3}>
              <RepIndexHero />
              <Divider />
              <Stepper orientation="vertical">
                {steps.map((step) => (
                  <Step key={step.label} active expanded>
                    <StepLabel
                      data-testid={`step-label-${step.label
                        .toLowerCase()
                        .replace(/\s/g, "-")}`}
                      sx={{
                        typography: "body1",
                        fontWeight: "fontWeightBold",
                      }}
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Stack alignItems="flex-start" gap={2}>
                        <Typography variant="body2">
                          {step.description}
                        </Typography>
                        {step.action ? <>{step.action}</> : null}
                      </Stack>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </PageCard>
          <TemplateGuidelinesDialog
            onClose={templateGuidelinesOnClose}
            open={templateGuidelinesIsOpen}
          />
          <UploadCsvDialog
            isSubmitting={isUploading}
            onClose={uploadOnClose}
            onUpload={handleUpload}
            open={uploadIsOpen}
          />
          <ErrorDialog
            confirmText="Re-upload"
            details={<ErrorDialogContent error={uploadError} />}
            id="upload-error"
            isOpen={errorIsOpen}
            message={errorContent}
            onClose={() => {
              resetUpload()
              errorOnClose()
            }}
            onConfirm={() => {
              uploadOnOpen()
            }}
            title="Upload Error"
          />
        </Page>
      ) : null}
    </PageLoading>
  )
}
