import React from "react"
import type { FC } from "react"

import { HStack } from "@/nzds/layout"

import { PerformanceTierIcon } from "./performance-tier-icon"
import type {
  PerformanceTierId,
  PerformanceTierProps,
} from "./performance-tier.types"

const tierToName: Record<PerformanceTierId, string> = {
  extremelyInefficient: "Highly Inefficient",
  inefficient: "Inefficient",
  atBenchmark: "Average",
  efficient: "Efficient",
  highlyEfficient: "Highly Efficient",
}

/**
 * A component for displaying the performance tier of a site
 *
 * ```tsx
 * import { PerformanceTier } from "@/components/performance/performance-tier"
 *
 * <PerformanceTier tier="atBenchmark" />
 * ```
 */
export const PerformanceTier: FC<PerformanceTierProps> = ({ tier }) => {
  return (
    <HStack alignItems="center" gap={0.5}>
      <PerformanceTierIcon tier={tier} />
      {tierToName[tier]}
    </HStack>
  )
}
