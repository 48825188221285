import { z } from "zod"

export const schema = z.object({
  name: z.string().min(1, "Organization name is required"),
  id: z.string().optional(),
  localeId: z.string().min(1, "Required"),
  notes: z.string().nullable().optional(),
})

export type Organization = z.infer<typeof schema>
