import React from "react"

import type { IDecimalFormat } from "../decimal/decimal"
import { DecimalWithPlaceholder } from "../decimalWithPlaceholder/decimalWithPlaceholder"

interface IEnergyUnitDecimalProps {
  placeholder?: string
  value: number | string | null
}

/**
 * A component that properly formats and internationalizes the provided decimal for displaying an energy unit
 *
 * @param props {IEnergyUnitDecimalProps} - The component properties
 * @param children: {never[]} - The children
 * @param props.value {number | string | null} - The decimal
 * @param props.placeholder {string} - The placeholder to render instead of the decimal
 * @example
 * return (
 *   <EnergyUnitDecimal value={energy} />
 * )
 */
export const EnergyUnitDecimal = ({
  value,
  placeholder,
}: IEnergyUnitDecimalProps) => {
  let format: IDecimalFormat

  if (Number(value) < 1) {
    format = { maximumFractionDigits: 6, maximumSignificantDigits: 4 }
  } else {
    format = { precision: 2 }
  }

  return (
    <DecimalWithPlaceholder
      format={format}
      placeholder={placeholder}
      value={value}
    />
  )
}
