import React, { useContext, useEffect, useMemo, useState } from "react"

import {
  LEFT_NAV_COLLAPSED_WIDTH,
  LEFT_NAV_EXPANDED_WIDTH,
} from "../utils/constants"

const viewportContext = React.createContext({
  collapsed: true,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCollapsed: (collapsed: boolean) => undefined,
  isOpenValues: {},
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsOpenValues: (openValues) => undefined,
})

export const ViewportProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true)
  const [isOpenValues, setIsOpenValues] = useState({})

  const value = useMemo(
    () => ({ collapsed, setCollapsed, isOpenValues, setIsOpenValues }),
    [collapsed, isOpenValues]
  )

  return (
    <viewportContext.Provider value={value}>
      {children}
    </viewportContext.Provider>
  )
}

export const useViewport = () => {
  const { collapsed, setCollapsed, isOpenValues, setIsOpenValues } =
    useContext(viewportContext)
  const [effectiveWidth, setEffectiveWidth] = useState(0)

  useEffect(() => {
    const updatePageWidth = () => {
      const containerPadding = 80
      const leftNavWidth = collapsed
        ? LEFT_NAV_COLLAPSED_WIDTH
        : LEFT_NAV_EXPANDED_WIDTH
      const pageGutters = 100
      const remainingOffset = 15
      setEffectiveWidth(
        Math.max(document.documentElement.clientWidth, window.innerWidth || 0) -
          containerPadding -
          leftNavWidth -
          pageGutters -
          remainingOffset
      )
    }
    updatePageWidth()
    window.addEventListener("resize", updatePageWidth)
    return () => {
      window.removeEventListener("resize", updatePageWidth)
    }
  }, [collapsed])

  const setIsOpen = (newUniqueId: string, newisOpen: boolean) => {
    setIsOpenValues({
      ...isOpenValues,
      [newUniqueId]: newisOpen,
    })
  }

  return {
    collapsed,
    setCollapsed,
    effectiveWidth,
    isOpenValues,
    setIsOpenValues,
    setIsOpen,
  }
}
