import { useCallback, useMemo } from "react"

import type { SpraypaintBase } from "spraypaint"
import type { ServiceSortOptions, ServiceWhereOptions } from "../../types"
import type ApplicationRecord from "../../../models/applicationRecord/applicationRecord"
import type {
  Includes,
  ModelAttributes,
  ModelAttributesShallow,
  ModelId,
  ModelRelationshipKeys,
} from "./types"

// TODO: Pagination

interface GetOneOptions<ModelT extends SpraypaintBase> {
  extra?: (keyof ModelAttributesShallow<ModelT>)[],
  include?: ModelRelationshipKeys<ModelT>[]
}

interface GetManyOptions<ModelT extends SpraypaintBase> {
  include?: ModelRelationshipKeys<ModelT>[],
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sort?: any,
  // TODO: Eventually add this to getAttributes():
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  where?: any
  // where?: undefined | null | ServiceWhereOptions<ModelAttributes<ModelT>>
  // sort?: undefined | null | ServiceSortOptions<ModelAttributes<ModelT>>
}

type ReturnedValue<
  ModelT extends SpraypaintBase,
  OptionsT
> = ModelAttributes<
  ModelT,
  "include" extends keyof OptionsT
    ? OptionsT["include"] extends (infer R extends Includes<ModelT>)[]
      ? R
      : never
    : never
>

const createSpraypaintSort = <ModelT extends SpraypaintBase>(
  Model: ModelT,
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceSortOptions: undefined | null | ServiceSortOptions<any>
) => {
  if (!serviceSortOptions?.key || !serviceSortOptions?.order) {
    return
  }
  // eslint-disable-next-line consistent-return
  return { [serviceSortOptions.key]: serviceSortOptions.order }
}

const createSpraypaintWhere = <ModelT extends SpraypaintBase>(
  Model: ModelT,
  serviceWhereOptions:
    | undefined
    | null
    | ServiceWhereOptions<ModelAttributes<ModelT>>
) => {
  if (!serviceWhereOptions) {
    return undefined
  }
  return serviceWhereOptions
  // TODO: Make sure we don't have to change our relational key names.
  //  e.g., relation to relation_id
}

/**
 * Given a Spraypaint model class (NOT an instance), returns a set of React-friendly methods for
 * reading and writing data to that model.
 *
 * @param SpraypaintModel An uninstantiated Spraypaint model class.
 * @returns A memoized object containing getters and setters associated with the provided model.
 * @example
 * const { getOne } = useSpraypaintModel(Person)
 */
export const useSpraypaintModel = <ModelT extends typeof ApplicationRecord>(
  Model: ModelT
) => {
  const getInstance = useCallback(
    async (
      entityId: ModelId,
      {include, extra }: GetOneOptions<InstanceType<ModelT>> = {}
    ) => {
      if (!entityId) {
        return undefined
      }
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      return Model.fetchInstances({ include, extra, where: { id: entityId } } as any)
        .then((arr) => arr[0])
    },
    [Model]
  )

  const getInstances = useCallback(
    async (options: GetManyOptions<InstanceType<ModelT>> = {}) => {
      const where = createSpraypaintWhere(
        Model as InstanceType<ModelT>,
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        options.where)
      
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const sort = createSpraypaintSort(Model as InstanceType<ModelT>, options.sort)

      // We need to cast this because we should expect this to be an array of strings
      const include = options.include as string[]

      const instances = await Model.fetchInstances({ where, sort, include })
      return instances
    },
    [Model]
  )

  return useMemo(
    () => ({
      getOne: async <
        OptionsT extends
          | undefined
          | GetOneOptions<InstanceType<ModelT>> = undefined
      >(
        id: ModelId,
        options?: OptionsT
      ) => (await getInstance(id, options))?.getValues() as unknown as undefined |
            ReturnedValue<InstanceType<ModelT>, OptionsT>,

      getMany: async <
        OptionsT extends
          | undefined
          | GetManyOptions<InstanceType<ModelT>> = undefined
      >(
        options?: OptionsT
      ) =>
        (await getInstances(options))?.map((instance) =>
          instance.getValues()
        ) as unknown as undefined | ReturnedValue<InstanceType<ModelT>, OptionsT>[],

      update: async (
        id: ModelId,
        newAttributes: Partial<ModelAttributes<InstanceType<ModelT>>>
      ): Promise<void> => {
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const include = Model.getRelationshipNames().filter((attributeName) => {
          const { idName } = Model.getRelationshipInfo(attributeName)
          return newAttributes[idName] !== undefined
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any
        
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const instance = await getInstance(id, { include })
        if (!instance) {
          throw new Error("Instance not found.")
        }
        await instance.saveValues({id, ...newAttributes})
      },
      
      delete: async (
        id: ModelId,
      ): Promise<void> => {
        const instance = await getInstance(id)
        if (!instance) {
          throw new Error("Instance not found.")
        }
        await instance.destroy()
      },

      // TO-DO: Provide proper return type
      create: async (
        newAttributes: Partial<ModelAttributes<InstanceType<ModelT>>>
      ) => {
        const instance = new Model(newAttributes)
        await instance.saveValues(newAttributes)
        return instance.getValues() as unknown as undefined |
        ReturnedValue<InstanceType<ModelT>, undefined>
      },
    }),
    [Model, getInstance, getInstances]
  )
}
