import { Country } from "country-state-city"

interface CountryOptions {
  label: string
  value: string
}

/**
 * Returns a list of countries as options for a select field, where
 * the label is the country name and the value is the ISO code.
 *
 * @example
 * import { useCountryOptions } from "nzds/utils/useCountryOptions"
 * const countryOptions = useCountryOptions()
 *
 */
export const useCountryOptions = (): CountryOptions[] =>
  Country.getAllCountries().map(({ name, isoCode }) => ({
    label: name,
    value: isoCode,
  }))
