import { useCallback, useMemo, useState } from "react"

export const useDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [dialogTitle, setDialogTitle] = useState<string>("")
  const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null)
  const [dialogActions, setDialogActions] = useState<JSX.Element | null>(null)

  const onDialogClose = useCallback(() => {
    setIsDialogOpen(false)
  }, [setIsDialogOpen])

  const onDialogOpen = useCallback(
    (title: string, content: JSX.Element, actions: JSX.Element): void => {
      setIsDialogOpen(true)
      setDialogTitle(title)
      setDialogContent(content)
      setDialogActions(actions)
    },
    [setIsDialogOpen, setDialogTitle, setDialogContent, setDialogActions]
  )

  return useMemo(
    () => ({
      isDialogOpen,
      dialogTitle,
      dialogContent,
      dialogActions,
      onDialogOpen,
      onDialogClose,
    }),
    [
      isDialogOpen,
      dialogTitle,
      dialogContent,
      dialogActions,
      onDialogOpen,
      onDialogClose,
    ]
  )
}
