import React from "react"

import { Box } from "@mui/material"

import AdminRoute from "../../routes"
import { TamAdminAppBar } from "../tamAdminAppBar/tamAdminAppBar"

const TamAdmin = (): JSX.Element => (
  <>
    <TamAdminAppBar />
    <Box px={7} py={8}>
      <AdminRoute />
    </Box>
  </>
)

export default TamAdmin
