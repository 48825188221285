import { useCallback, useEffect } from "react"

import { snakeCaseToCamelCase } from "@/utils/formatters"
import type { AxiosInstance } from "axios"
import axios from "axios"

import type {
  SigmaPaginatedResponse,
  SigmaTeam,
  SigmaTeamVisibility,
  SigmaWorkbook,
  SigmaWorkbookEmbed,
} from "./sigma.types"
import { useSigmaAccessToken } from "./useSigmaAccessToken/useSigmaAccessToken"

export const sigmaHttpClient: AxiosInstance = axios.create({
  baseURL: process.env.SIGMA_API_URL,
})

sigmaHttpClient.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  (response) => snakeCaseToCamelCase(response),
  (error) => Promise.reject(snakeCaseToCamelCase(error))
)

export interface FetchSigmaTeamsOptions {
  description?: string
  limit?: number
  name?: string
  page?: number
  visibility?: SigmaTeamVisibility
}

/**
 * Provides the Sigma api configured with an access token
 *
 * @returns The Sigma api
 *
 * @example
 * const {
 *  fetchSigmaTeams,
 *  fetchSigmaWorkbook,
 *  fetchSigmaWorkbooks,
 *  fetchSigmaWorkbookEmbeds,
 *  sigmaAccessTokenIsFetched,
 * } = useSigmaApi()
 */
export const useSigmaApi = () => {
  const { sigmaAccessTokenData, sigmaAccessTokenIsFetched } =
    useSigmaAccessToken()

  // Update request interceptor with latest access token
  useEffect(() => {
    const requestInterceptor = sigmaHttpClient.interceptors.request.use(
      (config) => ({
        ...config,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          ...config.headers,
          Authorization: `Bearer ${sigmaAccessTokenData?.accessToken}`,
          accept: "application/json",
        },
      })
    )

    // Clean up previous request interceptor so we're not adding infinitely
    return () => {
      sigmaHttpClient.interceptors.request.eject(requestInterceptor)
    }
  }, [sigmaAccessTokenData?.accessToken])

  const fetchSigmaTeams = useCallback(
    async (
      options: FetchSigmaTeamsOptions = {}
    ): Promise<SigmaPaginatedResponse<SigmaTeam> | null> => {
      try {
        if (sigmaAccessTokenIsFetched) {
          const response = await sigmaHttpClient.get<
            Promise<SigmaPaginatedResponse<SigmaTeam> | null>
          >(`/teams`, { params: options })

          return response.data
        }
      } catch (error) {
        return null
      }

      return null
    },
    [sigmaAccessTokenIsFetched]
  )

  const fetchSigmaWorkbook = useCallback(
    async (
      workbookId: SigmaWorkbook["workbookId"]
    ): Promise<SigmaWorkbook | null> => {
      try {
        if (sigmaAccessTokenIsFetched) {
          const response = await sigmaHttpClient.get<SigmaWorkbook>(
            `/workbooks/${workbookId}`
          )

          return response.data
        }
      } catch (error) {
        return null
      }

      return null
    },
    [sigmaAccessTokenIsFetched]
  )

  const fetchSigmaWorkbooks =
    useCallback(async (): Promise<SigmaPaginatedResponse<SigmaWorkbook> | null> => {
      try {
        if (sigmaAccessTokenIsFetched) {
          const response = await sigmaHttpClient.get<
            SigmaPaginatedResponse<SigmaWorkbook>
          >("/workbooks", { params: { limit: 500 } })

          return {
            ...response.data,
            entries: response.data.entries.map((entry) => ({
              ...entry,
              id: entry.workbookId,
            })),
          }
        }
      } catch (error) {
        return null
      }

      return null
    }, [sigmaAccessTokenIsFetched])

  const fetchSigmaWorkbookEmbeds = useCallback(
    async (workbookId: string): Promise<SigmaWorkbookEmbed[]> => {
      try {
        if (sigmaAccessTokenIsFetched) {
          const response = await sigmaHttpClient.get<
            SigmaPaginatedResponse<SigmaWorkbookEmbed>
          >(`/workbooks/${workbookId}/embeds`)

          return response.data.entries
        }
      } catch (error) {
        return null
      }

      return null
    },
    [sigmaAccessTokenIsFetched]
  )

  return {
    fetchSigmaTeams,
    fetchSigmaWorkbook,
    fetchSigmaWorkbooks,
    fetchSigmaWorkbookEmbeds,
    sigmaAccessTokenIsFetched,
  }
}
