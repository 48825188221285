// @ts-strict-ignore
import ForecastDay from "@/models/forecastDay"

/**
 * Fetches forecast days
 * @param whereClause - Where clause to filter the data.
 * @param selectExtraClause - Select extra clause to include extra data.
 * @returns A promise that resolves to an array like container of ForecastDay.
 * */
export const fetchForecastDays = async ({ whereClause, selectExtraClause }) =>
  ForecastDay.where(whereClause)
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    .selectExtra(selectExtraClause)
    .order({ day: "desc" })
    // Page size should be passed in instead of hardcoded
    .per(100)
    .all()
    .then((response) => response.data)

export default { fetchForecastDays }
