import React from "react"

import { EmissionDecimal } from "../../../../../../components/decimal/emissionDecimal/emissionDecimal"
import type {
  ResourceSummaryReportTableColumn,
  ResourceSummaryReportTableRow,
} from "../../../../models/resourceSummary"

import styles from "./virtualizedReportingTableTotalRow.module.scss"

interface ComponentProps {
  columnIndex: number
  columns: ResourceSummaryReportTableColumn[]
  rows: ResourceSummaryReportTableRow[]
  style: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
}

export const VirtualizedReportingTableTotalRow = ({
  rows,
  columns,
  columnIndex,
  style,
}: ComponentProps) => {
  const totals: (number | string)[] = columns.map((column) =>
    rows
      .map((row) => row[column.key])
      .reduce((prev: number, curr: number) => prev + curr, 0)
  )

  switch (columnIndex) {
    case 0:
      return (
        <div
          className={`${styles.rowContent} ${styles.stickyColumn}`}
          style={style}
        >
          <span className="paragraph--small paragraph--small__regular">
            Totals
          </span>
        </div>
      )
    case 1:
      return <div className={`${styles.rowContent}`} style={style} />
    case 2:
      return (
        <div
          className={`${styles.rowContent} ${styles.visibleRowContent}`}
          style={style}
        />
      )

    default:
      return (
        <div
          key={columns[columnIndex].key}
          className={`${styles.rowContent}`}
          style={style}
        >
          <span
            className={`paragraph--small paragraph--small__regular ${styles.visibleRowContent}`}
          >
            <EmissionDecimal value={totals[columnIndex]} />
          </span>
        </div>
      )
  }
}
