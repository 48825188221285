import React from "react"
import { useTranslation } from "react-i18next"

import { translateDecimal } from "../../../utils/decimal"

export interface IDecimalFormat extends Intl.NumberFormatOptions {
  precision?: number
}

export interface IDecimalProps {
  format?: IDecimalFormat
  useAccountingFormatWhenNegative?: boolean
  value: number | string | null
}

/**
 * A component that properly formats and internationalizes the provided decimal
 *
 * @param props {IDecimalProps} - The component properties
 * @param props.value {number | string | null} - The decimal
 * @param props.format {IDecimalFormat} - The format configuration for the decimal
 * @param props.useAccountingFormatWhenNegative {boolean} - Whether to wrap negative decimals in parenthesis or not
 * @example
 * return (
 *   <Decimal value={waterUse} format={{
 *     precision: 2
 *   }} />
 * )
 */
export const Decimal = ({
  value,
  format,
  useAccountingFormatWhenNegative = true,
}: IDecimalProps) => {
  const { i18n } = useTranslation()
  return (
    <>
      {translateDecimal(i18n, value, format, {
        useAccountingFormatWhenNegative,
      })}
    </>
  )
}
