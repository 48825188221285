import { useTranslation } from "react-i18next"

import { useUserSettings } from "../modules/settings"
import { EMPTY_DATA_PLACEHOLDER } from "../utils"
import { shouldShowPlaceholder } from "./utils"

const defaultOptions: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}

export const useFormatMoney = (
  options: Intl.NumberFormatOptions = defaultOptions
) => {
  const { t } = useTranslation()
  const { currency } = useUserSettings()

  const format = (value?: string | number | null) => {
    if (shouldShowPlaceholder(value)) {
      return EMPTY_DATA_PLACEHOLDER
    }

    return t("common:money", {
      value,
      formatParams: {
        value: {
          ...options,
          currency,
        },
      },
    })
  }

  return { format }
}
