import React from "react"
import { Link } from "react-router-dom"

import moment from "moment"

import {
  Box,
  Skeleton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"

import { DateDisplay } from "../../../../../../components/date/dateDisplay/dateDisplay"
import { RootPath, TamAdminPath } from "../../../../../../models/route"
import { TYPOGRAPHY_VARIANT } from "../../../../../../models/typography"
import { darkestTeal } from "../../../../../../utils/colors"
import { convertDateStringDashesToSlashes } from "../../../../../../utils/date"
import { pxToRem } from "../../../../../../utils/unit"
import type { ITamAdminOrganizationsTableRow } from "../../../../models/onboarding/onboarding"
import { TamAdminOrganizationsColumnKey } from "../../../../models/onboarding/onboarding"

interface ITamAdminOrganizationTableContentProps {
  isLoading: boolean
  rows: ITamAdminOrganizationsTableRow[]
}

const TamAdminOrganizationTableContent = ({
  rows,
  isLoading,
}: ITamAdminOrganizationTableContentProps): JSX.Element => (
  <TableBody>
    {rows.map((row: ITamAdminOrganizationsTableRow) => (
      <TableRow key={row.id}>
        {Object.keys(row).map((key) => {
          if (isLoading) {
            return (
              <TableCell key={key}>
                <Skeleton height={pxToRem(24)} />
              </TableCell>
            )
          }
          return (
            <TableCell key={key}>
              {
                // Name column
                key === TamAdminOrganizationsColumnKey.Name && (
                  <Box
                    component={Link}
                    data-e2e={`organization-${row.id}`}
                    sx={{
                      color: darkestTeal.toString(),
                      whiteSpace: "nowrap",
                      display: "inline-block",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${row.id}`}
                  >
                    <>{row.name}</>
                  </Box>
                )
              }
              {
                // Id column
                key === TamAdminOrganizationsColumnKey.Id && (
                  <Typography variant={TYPOGRAPHY_VARIANT.body2}>
                    <>{row.id?.toString()}</>
                  </Typography>
                )
              }
              {
                // Locale column
                key === TamAdminOrganizationsColumnKey.Locale && (
                  <Typography variant={TYPOGRAPHY_VARIANT.body2}>
                    <>{row.locale?.toString()}</>
                  </Typography>
                )
              }
              {
                // Demo organization column
                key === TamAdminOrganizationsColumnKey.DemoOrganization && (
                  <Typography variant={TYPOGRAPHY_VARIANT.body2}>
                    <>{row.demoOrganization?.toString()}</>
                  </Typography>
                )
              }
              {
                // Date created column
                key === TamAdminOrganizationsColumnKey.DateCreated && (
                  <DateDisplay
                    format={{
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    }}
                    value={
                      row.dateCreated
                        ? moment.tz(
                            // TO-DO: Remove deprecated function when backend passes date string in proper format
                            new Date(
                              convertDateStringDashesToSlashes(row.dateCreated)
                            ).toISOString(),
                            "UTC"
                          )
                        : null
                    }
                  />
                )
              }
              {
                // Date updated column
                key === TamAdminOrganizationsColumnKey.DateUpdated && (
                  <DateDisplay
                    format={{
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    }}
                    value={
                      row.dateUpdated
                        ? moment.tz(
                            // TO-DO: Remove deprecated function when backend passes date string in proper format
                            new Date(
                              convertDateStringDashesToSlashes(row.dateUpdated)
                            ).toISOString(),
                            "UTC"
                          )
                        : null
                    }
                  />
                )
              }
            </TableCell>
          )
        })}
      </TableRow>
    ))}
  </TableBody>
)

export default TamAdminOrganizationTableContent
