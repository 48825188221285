import React from "react"

import { useAuthentication } from "@/services/authentication"

import Page404 from "../../nav/page404/page404"

interface AdminGuardProps {
  children: JSX.Element
}

const AdminGuard = ({ children }: AdminGuardProps): JSX.Element => {
  const { authenticatedUser } = useAuthentication()
  if (authenticatedUser?.admin) {
    return children
  }

  return <Page404 />
}

export default AdminGuard
