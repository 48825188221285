import React, { useCallback, useMemo } from "react"

import classNames from "classnames"

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import type { SelectChangeEvent } from "@mui/material"
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material"

import type {
  IScopeCategory,
  IScopeSubcategory,
} from "../../../../../models/scope"
import { TYPOGRAPHY_VARIANT } from "../../../../../models/typography"

import styles from "./scopeThreeDataLevel.module.scss"

interface ScopeThreeDataLevelProps {
  categoryData: IScopeCategory[]
  hasScopeThreeEmissions: boolean
  initialCategoryValue: number | null
  initialSubcategoryValue: number[] | null
  isSummaryPresent: boolean
  onCategoryChange: (categoryId: number, subcategoryIds: number[]) => void
  onSubcategoryChange: (subcategoryIds: number[]) => void
  onSummaryChange: () => void
  subcategoryData: IScopeSubcategory[]
}

const categoryPlaceholderId = -1

export const ScopeThreeDataLevel = ({
  isSummaryPresent,
  categoryData,
  subcategoryData,
  onSummaryChange,
  onCategoryChange,
  onSubcategoryChange,
  initialCategoryValue,
  initialSubcategoryValue,
  hasScopeThreeEmissions,
}: ScopeThreeDataLevelProps): JSX.Element => {
  const labelClass = classNames(
    "paragraph--medium",
    "paragraph--medium__regular"
  )

  // Ensure the selected category id is a number and it is valid
  const selectedCategoryId: number = useMemo(() => {
    if (
      !categoryData?.length ||
      !categoryData.find((category) => category.id === initialCategoryValue)
    ) {
      return categoryPlaceholderId
    }

    return initialCategoryValue
  }, [categoryData, initialCategoryValue])

  // Ensure the selected subcategory ids are numbers and that they are valid
  const selectedSubcategoryIds: number[] = useMemo(() => {
    const selectedCategory: IScopeCategory | undefined = categoryData.find(
      (category) => category.id === selectedCategoryId
    )

    if (
      !categoryData?.length ||
      !subcategoryData?.length ||
      !selectedCategory ||
      !initialSubcategoryValue?.length
    ) {
      return []
    }

    return (
      // Filter out subcategories that aren't part of the selected category
      initialSubcategoryValue?.filter((id) => {
        const subcategory: IScopeSubcategory | undefined = subcategoryData.find(
          (s) => s.id === id
        )

        return subcategory?.categoryId === selectedCategory?.id
      }) ?? []
    )
  }, [
    categoryData,
    initialSubcategoryValue,
    selectedCategoryId,
    subcategoryData,
  ])

  // Handle the category dropdown change event
  const onCategorySelectChange = useCallback(
    (event: SelectChangeEvent): void => {
      const category: IScopeCategory | undefined = categoryData?.find(
        (c) => c.id === Number.parseInt(event.target.value, 10)
      )
      const subcategoryIds: number[] = subcategoryData
        ?.filter((subcategory) => subcategory.categoryId === category?.id)
        .map((subcategory) => subcategory.id)

      onCategoryChange(category?.id ?? null, subcategoryIds)
    },
    [categoryData, onCategoryChange, subcategoryData]
  )

  // Handle the subcategories autocomplete change event
  const onSubcategoryAutocompleteChange = useCallback(
    (_event, selectedSubcategoryNames): void => {
      onSubcategoryChange(
        // Mass lint disable
        // Mass lint disable
        // Mass eslint disable
        // Mass eslint disable @typescript-eslint/no-unsafe-return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
        selectedSubcategoryNames.map((subcategory) => subcategory.id)
      )
    },
    [onSubcategoryChange]
  )

  // Handle the summary report checkbox change event
  const onSummaryToggle = useCallback((): void => {
    onSummaryChange()
  }, [onSummaryChange])

  return (
    <Box sx={{ pt: 3 }}>
      <Typography
        className={styles.dataLevelTitle}
        variant={TYPOGRAPHY_VARIANT.h3}
      >
        Select data level
      </Typography>
      <FormControlLabel
        className={styles.dataSummaryButtonContainer}
        control={
          <Checkbox
            checked={isSummaryPresent}
            data-testid="dataSummaryButton"
            disabled={!hasScopeThreeEmissions}
            onChange={onSummaryToggle}
          />
        }
        label="All Scope 3 Data - Summary"
      />
      <div className={styles.selectionsContainer}>
        <div className={styles.categoryContainer}>
          <Box
            className={classNames(
              labelClass,
              styles.categoryTitle,
              styles.titles
            )}
            component="label"
            htmlFor="selectCategory"
          >
            Data by Category
          </Box>
          <Select
            className={styles.categorySelect}
            disabled={isSummaryPresent || !hasScopeThreeEmissions}
            id="selectCategory"
            inputProps={{ "data-testid": "categorySelect" }}
            native
            onChange={onCategorySelectChange}
            value={selectedCategoryId.toString()}
          >
            <option
              key={categoryPlaceholderId}
              disabled
              value={categoryPlaceholderId}
            >
              Select category
            </option>
            {categoryData?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <Box
            className={classNames(labelClass, styles.titles)}
            component="label"
            htmlFor="selectSubcategory"
          >
            Subcategories
          </Box>
          <Autocomplete
            className={styles.criteriaField}
            data-testid="autocomplete"
            disableCloseOnSelect
            disabled={
              selectedCategoryId === categoryPlaceholderId ||
              !hasScopeThreeEmissions
            }
            // Mass lint disable
            // Mass eslint disable @typescript-eslint/no-unsafe-return
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
            getOptionLabel={(option) => option.name}
            id="selectSubcategory"
            multiple
            onChange={onSubcategoryAutocompleteChange}
            options={subcategoryData
              .filter(
                (subcategory) => subcategory.categoryId === selectedCategoryId
              )
              .sort((a, b) => a.name.localeCompare(b.name))}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "autocompleteInput",
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  checked={selected}
                  checkedIcon={
                    <CheckBox className={styles.checked} fontSize="small" />
                  }
                  className={styles.option}
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                />
                {/* Mass lint disable */}
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                {option.name}
              </li>
            )}
            value={selectedSubcategoryIds
              .map((id) =>
                subcategoryData.find((subcategory) => subcategory.id === id)
              )
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
        </div>
      </div>
    </Box>
  )
}

export default ScopeThreeDataLevel
