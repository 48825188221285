import { useEffect, useState } from "react"

import { useGoogleMaps } from "./useGoogleMaps"

interface UseCalculateCoordinatesValue {
  coordinates: google.maps.LatLngLiteral
}
/**
 * The useCalculateCoordinates service/hook uses google maps
 * api to convert address to coordinates
 *
 * @returns coordinates
 * @example
 * const {
 *   coordinates
 * } = useCalculateCoordinates("address")
 */
export const useCalculateCoordinates = (
  address: string
): UseCalculateCoordinatesValue => {
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [coordinates, setCoordinates] = useState(null)
  const { isLoaded, googleMaps } = useGoogleMaps()

  useEffect(() => {
    if (isLoaded) {
      const geocoder = new googleMaps.Geocoder()
      if (geocoder) {
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        geocoder.geocode({ address }, (results, status) => {
          if (status === googleMaps.GeocoderStatus.OK) {
            setCoordinates(results[0].geometry.location.toJSON())
          }
        })
      }
    }
  }, [address, isLoaded, googleMaps])
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return { coordinates }
}
