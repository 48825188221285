import { UNITLESS_ROW_HEIGHT } from "@/components/nzDataGrid"

import "@mui/lab/themeAugmentation"
import type { ThemeOptions } from "@mui/material/styles"
import { createTheme } from "@mui/material/styles"
import type {} from "@mui/x-data-grid-pro/themeAugmentation"
import type {} from "@mui/x-date-pickers/themeAugmentation"

import { FONT_WEIGHT } from "../models/typography"
import {
  bgColor,
  black,
  black87,
  darkTeal,
  gray25,
  gray50,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  gray700,
  gray800,
  green300,
  green400,
  green500,
  lightGray,
  mustard200,
  mustard300,
  mustard500,
  newBlue,
  primary100,
  primary300,
  primary400,
  primary500,
  red100,
  red400,
  red700,
  solidGray,
  warningYellowAccent,
  white,
} from "../utils/colors"
import { pxToRem } from "../utils/unit"
import { spectrum } from "./spectrum"
import type { Spectrum } from "./styles.types"

declare module "@mui/material" {
  interface Color {
    "25": string
  }
}

declare module "@mui/material/Card" {
  interface CardPropsVariantOverrides {
    contained: true
  }
}
declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    contained: true
  }
}
// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    neutral: true
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    neutral: true
  }
}

declare module "@mui/material/Switch" {
  interface SwitchPropsColorOverrides {
    neutral: true
  }
}

// Update the IconButton's color prop options
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    neutral: true
  }
}

declare module "@mui/material/ToggleButtonGroup" {
  interface ToggleButtonGroupPropsColorOverrides {
    neutral: true
  }
}

// Update the Icon's color prop options
declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    neutral: true
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"]
    spectrum: Spectrum
  }

  interface PaletteOptions {
    neutral?: PaletteOptions["primary"]
    spectrum?: Partial<Spectrum>
  }

  // allow configuration using `createTheme`
  interface TypographyVariants {
    body100?: React.CSSProperties
    body200?: React.CSSProperties
    body300?: React.CSSProperties
    body400?: React.CSSProperties
    dataLabel?: React.CSSProperties
    header100?: React.CSSProperties
    header200?: React.CSSProperties
    header300?: React.CSSProperties
    hero?: React.CSSProperties
    overview?: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    body100?: React.CSSProperties
    body200?: React.CSSProperties
    body300?: React.CSSProperties
    body400?: React.CSSProperties
    dataLabel?: React.CSSProperties
    header100?: React.CSSProperties
    header200?: React.CSSProperties
    header300?: React.CSSProperties
    hero?: React.CSSProperties
    overview?: React.CSSProperties
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body100: true
    body200: true
    body300: true
    body400: true
    dataLabel: true
    header100: true
    header200: true
    header300: true
    hero: true
    overview: true
  }
}

const { palette, typography } = createTheme()
const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      light: primary100.hex(),
      main: primary400.hex(),
      dark: primary500.hex(),
    },
    secondary: {
      main: primary100.hex(),
    },
    neutral: palette.augmentColor({
      color: {
        light: gray300.hex(),
        main: gray500.hex(),
        dark: gray700.hex(),
      },
    }),
    success: {
      light: green300.hex(),
      main: green400.hex(),
      dark: green500.hex(),
    },
    error: {
      light: red100.hex(),
      main: red400.hex(),
      dark: red700.hex(),
    },
    warning: {
      light: mustard200.hex(),
      main: mustard300.hex(),
      dark: mustard500.hex(),
    },
    info: {
      light: primary300.hex(),
      main: primary400.hex(),
      dark: primary500.hex(),
    },
    common: {
      black: black.hex(),
      white: white.hex(),
    },
    grey: {
      "25": gray25.hex(),
      "50": gray50.hex(),
      "100": gray100.hex(),
      "200": gray200.hex(),
      "300": gray300.hex(),
      "400": gray400.hex(),
      "500": gray500.hex(),
      "600": gray600.hex(),
      "700": gray700.hex(),
      "800": gray800.hex(),
      "900": gray800.darken(0.1).hex(),
    },
    text: { primary: gray800.hex() },
    spectrum,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: "inherit",
          padding: theme.spacing(0, 1),
        }),
        icon: {
          // Necessary for specificity
          "&.MuiAlert-icon": {
            marginRight: "10px",
            padding: "6px 0",
          },
        },
        standardWarning: {
          border: `1px solid ${warningYellowAccent}`,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: bgColor.toString(),
          height: "100vh",
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: "neutral",
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: ({ theme }) => ({
          "&.MuiButton-sizeSmall": {
            marginLeft: theme.spacing(-0.5),
          },
          "&.MuiButton-sizeMedium": {
            marginLeft: theme.spacing(-1),
          },
          "&.MuiButton-sizeLarge": {
            marginLeft: theme.spacing(-1.5),
          },
        }),
        endIcon: ({ theme }) => ({
          "&.MuiButton-sizeSmall": {
            marginRight: theme.spacing(-0.5),
          },
          "&.MuiButton-sizeMedium": {
            marginRight: theme.spacing(-1),
          },
          "&.MuiButton-sizeLarge": {
            marginRight: theme.spacing(-1.5),
          },
        }),
        root: {
          boxShadow: "none",
          fontWeight: FONT_WEIGHT.bold,
          textTransform: "unset",
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeSmall: ({ theme }) => ({
          fontSize: pxToRem(14),
          lineHeight: pxToRem(20),
          padding: `${pxToRem(6)} ${theme.spacing(2)}`,
          "&.MuiButton-outlined": {
            padding: `calc(${pxToRem(6)} - 1px) ${theme.spacing(2)}`,
          },
        }),
        sizeMedium: ({ theme }) => ({
          fontSize: pxToRem(16),
          lineHeight: pxToRem(24),
          padding: theme.spacing(1, 2.5),
          "&.MuiButton-outlined": {
            padding: `calc(${theme.spacing(1)} - 1px) ${theme.spacing(2.5)}`,
          },
        }),
        sizeLarge: ({ theme }) => ({
          lineHeight: pxToRem(24),
          padding: theme.spacing(1.5, 3),
          "&.MuiButton-outlined": {
            padding: `calc(${theme.spacing(1.5)} - 1px) ${theme.spacing(3)}`,
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          height: "32px",
          minHeight: "32px", // Ensure button doesn't collapse when in a smaller container
          minWidth: "32px",
          width: "32px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "unset",
          "&.MuiToggleButton-root": {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.grey[800],
            borderColor: theme.palette.grey[200],
          },
          "&.Mui-selected.MuiToggleButton-root": {
            borderColor: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[100],
          },
        }),
        sizeSmall: ({ theme }) => ({
          padding: `calc(${theme.spacing(0.75)} - 1px) ${theme.spacing(1.5)}`,
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.spacing(2.5),
        }),
        sizeMedium: ({ theme }) => ({
          padding: `calc(${theme.spacing(1)} - 1px) ${theme.spacing(1.5)}`,
          fontSize: theme.typography.pxToRem(16),
          lineHeight: theme.spacing(3),
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          "&:not(:first-of-type):not(.Mui-selected)": {
            borderLeft: "none",
            marginLeft: 0,
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: ({ theme }) => ({
          borderColor: theme.palette.grey[100],
        }),
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.grey[100],
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiStepLabel-labelContainer .Mui-active": {
            fontWeight: theme.typography.fontWeightBold,
          },
        }),
        label: ({ theme }) => ({
          "&.MuiStepLabel-alternativeLabel": {
            marginTop: theme.spacing(1),
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
        label: {
          color: solidGray.toString(),
        },
        labelPlacementTop: ({ theme }) => ({
          "& .MuiFormControlLabel-label": {
            alignSelf: "flex-start",
            marginBottom: theme.spacing(0.5),
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.neutral.dark,
          "&.Mui-focused": {
            color: theme.palette.neutral.dark,
          },
          "&.Mui-error": {
            color: theme.palette.neutral.dark,
          },
        }),
        outlined: {
          transform: "none",
          position: "relative",
          marginBottom: pxToRem(4),
          pointerEvents: "auto",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.neutral.dark,
          "&.Mui-focused": {
            color: theme.palette.neutral.dark,
          },
          "&.Mui-error": {
            color: theme.palette.neutral.dark,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: ({ theme }) => ({
          lineHeight: 1.2,
          marginLeft: theme.spacing(0.5),
          marginTop: theme.spacing(0.5),
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: "24px",
        },
        colorSecondary: {
          color: newBlue.toString(),
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "5px",
          },
        },
        inputRoot: {
          height: "auto",
        },
        clearIndicator: {
          color: solidGray.toString(),
        },
        popupIndicator: {
          color: solidGray.toString(),
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[800],
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          marginTop: 0,
          minWidth: "auto",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: black87.toString(),
          background: bgColor.toString(),
          boxShadow: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`, // this is a hack to override the specificity of the Mui-focused class
          },
        }),
        notchedOutline: {
          top: "0",
          borderWidth: "1px !important",
          "& legend": {
            display: "none",
          },
        },
        input: {
          padding: "8.5px",
        },
        inputSizeSmall: {
          padding: "4.5px",
        },
      },
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true,
      },
      styleOverrides: {
        root: {
          border: `1px solid ${lightGray.toString()}`,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          border: `1px solid ${lightGray.toString()}`,
          borderTop: "none",
          borderBottomLeftRadius: pxToRem(4),
          borderBottomRightRadius: pxToRem(4),
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2,
      },
      styleOverrides: {
        root: {
          borderRadius: pxToRem(8),
        },
        outlined: {
          borderColor: lightGray.toString(),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: "inherit",
          letterSpacing: "inherit",
          lineHeight: "inherit",
          color: "inherit",
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: theme.palette.primary.main,
            },
        }),
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: gray25.hex(),
            borderRadius: "0.5rem",
          },
        },
      ],
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:last-child": {
            padding: theme.spacing(2),
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          "&.MuiChip-colorPrimary.MuiChip-filledPrimary": {
            backgroundColor: theme.palette.spectrum.blue[100],
          },
          "&.MuiChip-colorPrimary.MuiChip-outlinedPrimary": {
            backgroundColor: theme.palette.spectrum.blue[100],
            borderColor: theme.palette.spectrum.blue[400],
          },
          "&.MuiChip-colorSecondary.MuiChip-filledSecondary": {
            backgroundColor: theme.palette.spectrum.blue[50],
          },
          "&.MuiChip-colorSecondary.MuiChip-outlinedSecondary": {
            backgroundColor: theme.palette.spectrum.blue[50],
            borderColor: theme.palette.spectrum.blue[100],
          },
          "&.MuiChip-colorError.MuiChip-filledError": {
            backgroundColor: theme.palette.spectrum.red[100],
          },
          "&.MuiChip-colorError.MuiChip-outlinedError": {
            backgroundColor: theme.palette.spectrum.red[100],
            borderColor: theme.palette.spectrum.red[400],
          },
          "&.MuiChip-colorSuccess.MuiChip-filledSuccess": {
            backgroundColor: theme.palette.spectrum.green[100],
          },
          "&.MuiChip-colorSuccess.MuiChip-outlinedSuccess": {
            backgroundColor: theme.palette.spectrum.green[100],
            borderColor: theme.palette.spectrum.green[400],
          },
          "&.MuiChip-colorWarning.MuiChip-filledWarning": {
            backgroundColor: theme.palette.spectrum.mustard[100],
          },
          "&.MuiChip-colorWarning.MuiChip-outlinedWarning": {
            backgroundColor: theme.palette.spectrum.mustard[100],
            borderColor: theme.palette.spectrum.mustard[400],
          },
          "&.MuiChip-colorNeutral.MuiChip-filledNeutral": {
            backgroundColor: theme.palette.grey[100],
          },
          "&.MuiChip-colorNeutral.MuiChip-outlinedNeutral": {
            backgroundColor: theme.palette.grey[100],
            borderColor: theme.palette.grey[400],
          },
          "&.MuiChip-colorInfo.MuiChip-filledInfo": {
            backgroundColor: theme.palette.spectrum.blue[100],
          },
          "&.MuiChip-colorInfo.MuiChip-outlinedInfo": {
            backgroundColor: theme.palette.spectrum.blue[100],
            borderColor: theme.palette.spectrum.blue[400],
          },
        }),
        labelSmall: ({ theme }) => ({
          fontSize: theme.typography.pxToRem(14),
        }),
        labelMedium: ({ theme }) => ({
          fontSize: theme.typography.pxToRem(16),
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: theme.typography.pxToRem(40),
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: theme.typography.pxToRem(40),
          minHeight: theme.typography.pxToRem(40),
          textTransform: "none",
          fontSize: theme.typography.pxToRem(16),
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.neutral.dark,
          "&:hover": {
            backgroundColor: theme.palette.grey[50],
          },
          "&.Mui-selected": {
            color: theme.palette.primary.main,
          },
          "&:hover.Mui-selected": {
            backgroundColor: theme.palette.spectrum.blue[50],
          },
        }),
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& button:not(.Mui-selected):focus": {
            color: white.toString(),
            backgroundColor: darkTeal.toString(),
            opacity: 0.4,
          },
          "& button:hover": {
            color: white.toString(),
            backgroundColor: darkTeal.toString(),
            opacity: 0.4,
          },
          "& button.Mui-selected": {
            color: white.toString(),
            backgroundColor: darkTeal.toString(),
            opacity: 1,
          },
          "& button.Mui-selected:focus": {
            color: white.toString(),
            backgroundColor: darkTeal.toString(),
            opacity: 1,
          },
          "& button.Mui-selected:hover": {
            color: white.toString(),
            backgroundColor: darkTeal.toString(),
            opacity: 0.4,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          gap: theme.spacing(2),
          paddingTop: theme.spacing(1),
          paddingRight: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.h2,
          color: gray800.toString(),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0.5),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rounded: ({ theme }) => ({
          borderRadius: theme.spacing(1),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[4],
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          fontSize: theme.typography.pxToRem(14),
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.grey[100],
        }),
        row: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          "&:hover": {
            backgroundColor: theme.palette.spectrum.blue[50],
          },
          "&.Mui-hovered": {
            backgroundColor: theme.palette.spectrum.blue[50],
          },
          borderColor: theme.palette.grey[50],
        }),
        columnHeaders: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
        }),
        columnHeaderTitle: ({ theme }) => ({
          ...theme.typography.body1,
          fontWeight: theme.typography.fontWeightBold,
        }),
        columnHeaderTitleContainer: ({ theme, ownerState }) => ({
          alignItems:
            ownerState?.columnHeaderHeight === UNITLESS_ROW_HEIGHT
              ? "center"
              : "flex-start",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingTop:
              ownerState?.columnHeaderHeight === UNITLESS_ROW_HEIGHT
                ? 0
                : theme.spacing(1),
            paddingBottom:
              ownerState?.columnHeaderHeight === UNITLESS_ROW_HEIGHT
                ? 0
                : theme.spacing(1),
          },
        }),
        columnSeparator: ({ theme, ownerState }) => ({
          justifyContent:
            ownerState?.columnHeaderHeight === UNITLESS_ROW_HEIGHT
              ? "center"
              : "flex-start",
          paddingTop:
            ownerState?.columnHeaderHeight === UNITLESS_ROW_HEIGHT
              ? 0
              : theme.spacing(1),
        }),
        menuIcon: ({ theme, ownerState }) => ({
          alignItems:
            ownerState?.columnHeaderHeight === UNITLESS_ROW_HEIGHT
              ? "center"
              : "flex-start",
          paddingTop:
            ownerState?.columnHeaderHeight === UNITLESS_ROW_HEIGHT
              ? 0
              : theme.spacing(0.5),
        }),
        iconButtonContainer: ({ theme, ownerState }) => ({
          paddingTop:
            ownerState?.columnHeaderHeight === UNITLESS_ROW_HEIGHT
              ? 0
              : theme.spacing(0.5),
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: () => ({
          borderRight: "none",
        }),
      },
    },
  },
  typography: {
    fontFamily: "Lato, Arial",
    hero: {
      lineHeight: 1.6,
      fontSize: pxToRem(32),
      fontWeight: FONT_WEIGHT.normal,
    },
    h1: {
      lineHeight: 1.6,
      fontSize: pxToRem(26),
      fontWeight: FONT_WEIGHT.bold,
    },
    h2: {
      lineHeight: 1.4,
      fontSize: pxToRem(20),
      fontWeight: FONT_WEIGHT.normal,
    },
    h3: {
      lineHeight: 1.5,
      fontSize: pxToRem(18),
      fontWeight: FONT_WEIGHT.normal,
    },
    h4: {
      lineHeight: 1.5,
      fontSize: pxToRem(16),
      fontWeight: FONT_WEIGHT.normal,
    },
    h5: {
      lineHeight: 1.4,
      fontSize: pxToRem(14),
      fontWeight: FONT_WEIGHT.bold,
    },
    h6: {
      lineHeight: 1.4,
      fontSize: pxToRem(14),
      fontWeight: FONT_WEIGHT.normal,
    },
    body1: {
      fontSize: pxToRem(16),
      lineHeight: 1.5,
      fontWeight: FONT_WEIGHT.normal,
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 1.4,
      fontWeight: FONT_WEIGHT.normal,
    },
    caption: {
      fontSize: pxToRem(12),
      lineHeight: 1.2,
      fontWeight: FONT_WEIGHT.normal,
    },
    dataLabel: {
      fontSize: pxToRem(10),
      lineHeight: 1.2,
      fontWeight: FONT_WEIGHT.normal,
    },
    header100: {
      fontSize: typography.pxToRem(26),
      lineHeight: 41.6 / 26,
      fontWeight: typography.fontWeightBold,
    },
    header200: {
      fontSize: typography.pxToRem(20),
      lineHeight: 32 / 20,
      fontWeight: typography.fontWeightRegular,
    },
    header300: {
      fontSize: typography.pxToRem(18),
      lineHeight: 27 / 18,
      fontWeight: typography.fontWeightRegular,
    },
    body100: {
      fontSize: typography.pxToRem(16),
      lineHeight: 24 / 16,
      fontWeight: typography.fontWeightRegular,
    },
    body200: {
      fontSize: typography.pxToRem(14),
      lineHeight: 20 / 14,
      fontWeight: typography.fontWeightRegular,
    },
    body300: {
      fontSize: typography.pxToRem(12),
      lineHeight: 1.2,
      fontWeight: typography.fontWeightRegular,
    },
    body400: {
      fontSize: typography.pxToRem(10),
      lineHeight: 1.2,
      fontWeight: typography.fontWeightRegular,
    },
    overview: {
      fontSize: typography.pxToRem(32),
      lineHeight: 51.2 / 32,
      fontWeight: typography.fontWeightRegular,
    },
  },
}

const MainTheme = createTheme(themeOptions)

export default MainTheme
