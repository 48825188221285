import { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

// This should not be reaching into a module, lift this constant up
import { ASC, DESC } from "@/modules/rep/pages/constants"
import { buildSearchParamsString } from "@/utils"

import type {
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"

import type { UseGridSortModelProps } from "./use-grid-sort-model.types"

const computeValidSortModel = (
  sortModel: GridSortModel,
  validColumns: GridColDef[]
): GridSortModel => {
  const validSortModel = sortModel.filter((sort) => {
    return Boolean(
      validColumns.find((validColumn) => validColumn.field === sort.field)
    )
  })

  return validSortModel
}

/**
 * Returns the new sort model by taking into account removed columns
 * Only supports single sort at this time
 *
 * @returns The new sort model
 * @example
 * const sortModel = useGridSortModel({
 *   defaultSortModel,
 *   savedSortModel,
 *   validColumns,
 * })
 */
export const useGridSortModel = <RowT extends GridValidRowModel>({
  defaultSortModel,
  savedSortModel,
  validColumns,
}: UseGridSortModelProps<RowT>): GridSortModel => {
  const [searchParams, setSearchParams] = useSearchParams()

  const fieldSearchParam: string | null = searchParams.get("orderBy")
  const sortSearchParam: string | null = searchParams.get("order")

  // compute new sort model by taking into account removed columns
  const sortModel: GridSortModel = useMemo(() => {
    let newSortModel: GridSortModel

    if (fieldSearchParam && sortSearchParam) {
      const urlSortModel: GridSortModel = [
        {
          field: fieldSearchParam,
          sort: sortSearchParam === ASC ? ASC : DESC,
        },
      ]

      newSortModel = computeValidSortModel(urlSortModel, validColumns)
    } else {
      newSortModel = computeValidSortModel(savedSortModel, validColumns)
    }

    if (newSortModel.length === 0) {
      newSortModel = defaultSortModel
    }

    return newSortModel
  }, [
    defaultSortModel,
    fieldSearchParam,
    savedSortModel,
    sortSearchParam,
    validColumns,
  ])

  // Add sort to url if not present
  useEffect(() => {
    if (!fieldSearchParam || !sortSearchParam) {
      const sortItem: GridSortItem = sortModel[0]

      const newSearchParamsString = buildSearchParamsString(
        {
          orderBy: sortItem.field,
          order: sortItem.sort,
        },
        searchParams
      )

      setSearchParams(newSearchParamsString, { replace: true })
    }
  }, [
    fieldSearchParam,
    searchParams,
    setSearchParams,
    sortModel,
    sortSearchParam,
  ])

  return sortModel
}
