import type { ScopeEmissionBase } from "../../models/scope"
import ScopeThreeReportingData from "../../models/scopeThree"
import { snakeCaseToCamelCase } from "../../utils/formatters"

interface FetchScopeThreeEmissionsProps {
  endMonth: string
  organizationId: number
  startMonth: string
  year: string
}

export interface FetchScopeThreeEmissionsValue extends ScopeEmissionBase {
  categoryId: number
  categoryName: string
  ghgEmissionsFactorsName: string
  siteId: number
  subcategoryId: number
  subcategoryName: string
}

interface FetchScopeThreeReportingDataFilters {
  end_month: string
  organization_id: number
  start_month: string
  year: string
}

export const fetchScopeThreeEmissions = ({
  organizationId,
  startMonth,
  endMonth,
  year,
}: FetchScopeThreeEmissionsProps): Promise<FetchScopeThreeEmissionsValue[]> => {
  const filters: FetchScopeThreeReportingDataFilters = {
    organization_id: organizationId,
    year,
    start_month: startMonth,
    end_month: endMonth,
  }

  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return ScopeThreeReportingData.where(filters)
    .stats({
      scope_three_reporting_data: "calculate",
    })
    .all()
    .then((result) => {
      const stats = result.meta.stats as Record<
        string,
        Record<string, FetchScopeThreeEmissionsValue[]>
      >
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return (
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        snakeCaseToCamelCase(stats.scope_three_reporting_data.calculate).map(
          (emission: FetchScopeThreeEmissionsValue) => ({
            ...emission,
            // The API returns mtCo2E as a string, but it's a number
            mtCo2E: Number.parseFloat(emission.mtCo2E as unknown as string),
          })
        )
      )
    })
}
