import { cloneDeep, omit, sortBy } from "lodash-es"

import type { CurrencyCode } from "../../../../models/currencyCode"
import type { Language, LanguageRegion } from "../../../../models/i18n"
import { MeasurementSystem } from "../../../../models/measurementSystem"
import ReportingData from "../../../../models/reportingData"
import { ElectricityEmissionsFactor } from "../../../../models/scopeTwo"
import type { Order } from "../../../../models/sort"
import type { ITableColumnMetadata } from "../../../../models/table"
import { GhgWeightUnit, UnitName } from "../../../../models/unit"
import {
  gallonsToCubicMeter,
  getMeasurementSystem,
  poundsToKilogram,
  poundsToMetricTon,
  translateUnit,
} from "../../../../utils"
import { GJ_PER_KWH, GJ_PER_THERM } from "../../../../utils/constants"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"
import type {
  IGetReportDataCriteria,
  ResourceSummaryMonthRecord,
  ResourceSummaryMonthRecordDto,
  ResourceSummaryMonthRecordWithTotalGhg,
  ResourceSummaryRecord,
  ResourceSummaryReportTableColumn,
} from "../../models/resourceSummary"
import {
  FLEET_SITE_ID_RAW,
  FLEET_SITE_NAME,
  ORG_LEVEL_SITE_ID,
  ORG_LEVEL_SITE_NAME,
  ResourceSummaryReportColumn,
  energyColumns, // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resourceSummaryReportColumns,
  volumeColumns,
} from "../../models/resourceSummary"
import { isOrgLevelSite, isUniqueSite } from "../../util/util"

export const addGjValuesToReportData = (
  reportData: ResourceSummaryRecord[]
): ResourceSummaryRecord[] => {
  const reportDataWithGj = reportData.map((data) => {
    let totalGj = 0
    const dataWithGj: ResourceSummaryRecord = { ...data }

    volumeColumns.forEach((column, index) => {
      let energyValue: number
      if (column.key === ResourceSummaryReportColumn.kwh.key) {
        energyValue = Number(data[column.key]) * GJ_PER_KWH
      }
      if (column.key === ResourceSummaryReportColumn.naturalGasThms.key) {
        energyValue = Number(data[column.key]) * GJ_PER_THERM
      }
      dataWithGj[energyColumns[index].key.toString()] = energyValue
      totalGj += energyValue
    })
    dataWithGj.totalGj = totalGj

    return dataWithGj
  })

  return reportDataWithGj
}

const buildColumnTitle = (
  metadata: ITableColumnMetadata,
  t,
  language: string,
  currency: CurrencyCode,
  weightUnit: string
): string => {
  // Otherwise dynamically build the header string parts
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const resourcePart = metadata.resourceDisplayName
    ? // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      t(metadata.resourceDisplayName)
    : // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      metadata.resource?.name || ""

  const locationBasedPart =
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    metadata?.electricityEmissionsFactor?.id ===
    ElectricityEmissionsFactor.LocationBased.id
      ? " Location-Based"
      : ""

  const scopePart = metadata.scope
    ? `(Scope ${metadata.scope}${locationBasedPart})`
    : ""

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const metricPart =
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    metadata.formatMetric && metadata.metric?.name
      ? // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        metadata.formatMetric(metadata.metric?.name)
      : // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        metadata.metric?.name || ""

  // Convert to the user-selected weight display unit if necessary
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedDisplayUnit =
    weightUnit === GhgWeightUnit.Mt &&
    metadata.unit === UnitName.PoundsOfCarbonDioxideEquivalent
      ? UnitName.MetricTon
      : metadata.unit

  // Translate the unit if necessary
  const unitPart = selectedDisplayUnit
    ? // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      `(${translateUnit(language, selectedDisplayUnit, currency)})`
    : ""

  // Form the full header, cleaning up any unnecessary spaces
  const title = `${
    metadata.isTotal ? "Total " : ""
  } ${resourcePart} ${scopePart} ${metricPart} ${unitPart}`
    .replace(/\s+/g, " ")
    .trim()

  return title
}

/**
 * Handles conversion to/from `lb`, `kg`, and `MT` for weight units and `gal`, `cu m` for liquid
 * volume units of report data values. Returns a new array.
 *
 * @param data - The original data set, weight values are expected to be in
 * `lb CO2e` and water volume should be in `gal`
 * @param selectedWeightUnit - The selected report display weight unit (`MT` or
 * `lb`)
 * @param language - The selected language determines the conversion calculation
 * @returns The converted/localized data values
 */
export const convertReportDataUnits = (
  data: ResourceSummaryRecord[],
  selectedWeightUnit: GhgWeightUnit,
  language: LanguageRegion | Language
): ResourceSummaryRecord[] => {
  const newData = cloneDeep(data)

  if (selectedWeightUnit === GhgWeightUnit.Mt) {
    // `MT` is selected, convert weight units to MT
    newData.forEach((datum) => {
      datum.advancedGridStudyCo2ELbs = poundsToMetricTon(
        datum.advancedGridStudyCo2ELbs
      )
      datum.fuelCo2ELbs = poundsToMetricTon(datum.fuelCo2ELbs)
      datum.locationElectricityCo2ELbs = poundsToMetricTon(
        datum.locationElectricityCo2ELbs
      )
      datum.naturalGasCo2ELbs = poundsToMetricTon(datum.naturalGasCo2ELbs)
      datum.refrigerantCo2ELbs = poundsToMetricTon(datum.refrigerantCo2ELbs)
      datum.totalGhgLbsCO2e = poundsToMetricTon(datum.totalGhgLbsCO2e)
    })
  } else if (getMeasurementSystem(language) !== MeasurementSystem.USCU) {
    // `lbs` is selected and org uses non-USC measurement system, convert to SI units
    newData.forEach((datum) => {
      datum.advancedGridStudyCo2ELbs = poundsToKilogram(
        datum.advancedGridStudyCo2ELbs
      )
      datum.fuelCo2ELbs = poundsToKilogram(datum.fuelCo2ELbs)
      datum.locationElectricityCo2ELbs = poundsToKilogram(
        datum.locationElectricityCo2ELbs
      )
      datum.naturalGasCo2ELbs = poundsToKilogram(datum.naturalGasCo2ELbs)
      datum.refrigerantCo2ELbs = poundsToKilogram(datum.refrigerantCo2ELbs)
      datum.totalGhgLbsCO2e = poundsToKilogram(datum.totalGhgLbsCO2e)
    })
  }

  if (getMeasurementSystem(language) !== MeasurementSystem.USCU) {
    // org uses non-USC measurement system, convert gallons to `cu m`
    newData.forEach((datum) => {
      datum.waterGallons = gallonsToCubicMeter(datum.waterGallons)
    })
  }

  return newData
}

/**
 * Returns the provided Resource Summary columns with their titles properly formatted
 *
 * @param columns - The array of Resource Summary columns
 * @param t - The translation function
 * @param language - The language (e.g. "en-US")
 * @param currency - The currency code (e.g. "USD")
 * @param weightUnit - The weight unit (e.g. "MT")
 * @returns The columns
 * @example
 *   getFormattedColumns(
 *     resourceSummaryReportColumns,
 *     t,
 *     i18n.language,
 *     currency,
 *     GhgWeightUnit.Mt)
 */
export const getFormattedColumns = (
  columns: ResourceSummaryReportTableColumn[],
  t,
  language: string,
  currency: CurrencyCode,
  weightUnit: string
): ResourceSummaryReportTableColumn[] =>
  columns.map((column) => ({
    ...column,
    title: !column.metadata?.isTitleDynamic
      ? column.title
      : buildColumnTitle(column.metadata, t, language, currency, weightUnit),
  }))

/**
 * Returns whether a site is considered empty or not
 *
 * @param site - The site
 * @param columns - The array of resource summary report columns
 * @returns Whether the site is empty or not
 * @example
 *   isSiteEmpty(site, resourceSummaryReportColumns)
 */
export const isSiteEmpty = (
  site: ResourceSummaryRecord,
  columns: ResourceSummaryReportTableColumn[]
): boolean =>
  columns
    .filter(
      (column) =>
        column.id !== ResourceSummaryReportColumn.siteName.id &&
        column.id !== ResourceSummaryReportColumn.siteId.id &&
        column.id !== ResourceSummaryReportColumn.departmentName.id &&
        column.id !== ResourceSummaryReportColumn.organizationalUnitName.id
    )
    .every((column) => site[column.key] === 0)

export const getSiteTotalGhgLbsCo2e = (
  site: ResourceSummaryMonthRecord,
  electricityEmissionsFactor: string
): number => {
  const isOrg: boolean = isOrgLevelSite(site.siteId)
  const isAdvancedGridStudySelected: boolean =
    electricityEmissionsFactor ===
    ElectricityEmissionsFactor.AdvancedGridStudy.id

  let totalGhgLbsCO2e: number = site.naturalGasCo2ELbs + site.refrigerantCo2ELbs

  if (!isOrg) {
    totalGhgLbsCO2e += site.fuelCo2ELbs
  }

  totalGhgLbsCO2e += isAdvancedGridStudySelected
    ? site.advancedGridStudyCo2ELbs
    : site.locationElectricityCo2ELbs

  return totalGhgLbsCO2e
}

export const sortReportData = (
  rows: ResourceSummaryRecord[],
  order: Order,
  orderBy: keyof ResourceSummaryRecord
): ResourceSummaryRecord[] => {
  // There will only ever be 1 org site
  const orgSites: ResourceSummaryRecord[] = rows.filter((site) =>
    isOrgLevelSite(site.siteId)
  )

  const uniqueSites: ResourceSummaryRecord[] = rows.filter((site) =>
    isUniqueSite(site)
  )

  const nonUniqueSites: ResourceSummaryRecord[] = rows.filter(
    (site) => !isOrgLevelSite(site.siteId) && !isUniqueSite(site)
  )

  const sortedUniqueSites = sortBy(uniqueSites, [
    ResourceSummaryReportColumn.siteName.key,
  ])

  const sortedNonUniqueSites: ResourceSummaryRecord[] = sortBy(nonUniqueSites, [
    orderBy,
  ])

  if (order === "desc") {
    sortedNonUniqueSites.reverse()
  }

  const sortedAllSites: ResourceSummaryRecord[] = [
    ...orgSites,
    ...sortedUniqueSites,
    ...sortedNonUniqueSites,
  ]

  return sortedAllSites
}

export const sumReportData = (
  data: ResourceSummaryMonthRecordWithTotalGhg[]
): ResourceSummaryRecord[] => {
  const summedReportData: Record<string, ResourceSummaryRecord> = data.reduce(
    (summedData, siteDataForMonth): Record<string, ResourceSummaryRecord> => {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const summedSiteData: ResourceSummaryRecord | undefined =
        summedData[siteDataForMonth.siteId]

      if (!summedSiteData) {
        return {
          ...summedData,
          [siteDataForMonth.siteId]: omit(siteDataForMonth, ["month", "year"]),
        }
      }

      return {
        ...summedData,
        [siteDataForMonth.siteId]: {
          ...summedSiteData,
          advancedGridStudyCo2ELbs:
            summedSiteData.advancedGridStudyCo2ELbs +
            siteDataForMonth.advancedGridStudyCo2ELbs,
          fuelCo2ELbs:
            summedSiteData.fuelCo2ELbs + siteDataForMonth.fuelCo2ELbs,
          kwh: summedSiteData.kwh + siteDataForMonth.kwh,
          locationElectricityCo2ELbs:
            summedSiteData.locationElectricityCo2ELbs +
            siteDataForMonth.locationElectricityCo2ELbs,
          naturalGasCo2ELbs:
            summedSiteData.naturalGasCo2ELbs +
            siteDataForMonth.naturalGasCo2ELbs,
          naturalGasThms:
            summedSiteData.naturalGasThms + siteDataForMonth.naturalGasThms,
          refrigerantCo2ELbs:
            summedSiteData.refrigerantCo2ELbs +
            siteDataForMonth.refrigerantCo2ELbs,
          siteId: summedSiteData.siteId,
          siteName: summedSiteData.siteName,
          totalGhgLbsCO2e:
            summedSiteData.totalGhgLbsCO2e + siteDataForMonth.totalGhgLbsCO2e,
          waterGallons:
            summedSiteData.waterGallons + siteDataForMonth.waterGallons,
        },
      }
    },
    {}
  )

  return Object.values(summedReportData)
}

export const getFormattedRecords = (
  records: ResourceSummaryMonthRecord[],
  language: LanguageRegion,
  weightUnit: GhgWeightUnit,
  electricityEmissionsFactor: string
): ResourceSummaryRecord[] => {
  // Add special "Fleet" site to records
  const fleetSiteRecords: ResourceSummaryMonthRecord[] = records
    .filter((site) => isOrgLevelSite(site.siteId))
    .map(
      (site): ResourceSummaryMonthRecord => ({
        ...site,
        advancedGridStudyCo2ELbs: 0,
        kwh: 0,
        locationElectricityCo2ELbs: 0,
        naturalGasCo2ELbs: 0,
        naturalGasThms: 0,
        refrigerantCo2ELbs: 0,
        siteId: FLEET_SITE_ID_RAW,
        siteName: FLEET_SITE_NAME,
        waterGallons: 0,
      })
    )

  const recordsWithFleetSites: ResourceSummaryMonthRecord[] = [
    ...records,
    ...fleetSiteRecords,
  ]

  // Adjust prop types, calculate total GHG, and adjust org level site data
  const adjustedRecords: ResourceSummaryMonthRecordWithTotalGhg[] =
    recordsWithFleetSites.map(
      (site): ResourceSummaryMonthRecordWithTotalGhg => {
        const isOrg: boolean = isOrgLevelSite(site.siteId)

        const adjustedSite: ResourceSummaryMonthRecordWithTotalGhg = {
          ...site,
          departmentId: site.departmentId?.toString(),
          siteId: site.siteId?.toString(),
          totalGhgLbsCO2e: getSiteTotalGhgLbsCo2e(
            site,
            electricityEmissionsFactor
          ),
        }

        if (isOrg) {
          adjustedSite.fuelCo2ELbs = 0
          adjustedSite.siteId = ORG_LEVEL_SITE_ID
          adjustedSite.siteName = ORG_LEVEL_SITE_NAME
        }

        return adjustedSite
      }
    )

  // Transform into summed report records
  const reportRecords: ResourceSummaryRecord[] = sumReportData(adjustedRecords)

  // Add the Energy Consumption filter's columns
  const reportRecordsWithGjValues = addGjValuesToReportData(reportRecords)

  // Convert according to localization and selected display unit
  const localizedReportRecords = convertReportDataUnits(
    reportRecordsWithGjValues,
    weightUnit,
    language
  )

  return localizedReportRecords
}

/**
 * Fetches Resource Summary month records used to generate the report
 *
 * @param orgId - The organization id
 * @param criteria - The criteria
 * @param criteria.departments - The department ids
 * @param criteria.dateRange - The date range
 * @returns - A promise of the report data
 * @example
 * fetchResourceSummaryMonthRecords("1", {
 *   dateRange: {
 *    start: moment("2022-01-01"),
 *    end: moment("2022-02-28"),
 *   },
 *   departments: ["1", "2", "3"],
 *   groupIds: ["1", "2", "3"],
 * })
 */
export const fetchResourceSummaryMonthRecords = async (
  orgId: string,
  criteria: IGetReportDataCriteria
): Promise<ResourceSummaryMonthRecord[]> => {
  const { dateRange, departments, groupIds } = criteria
  const { start, end } = dateRange
  try {
    return await ReportingData.where({
      organization_id: Number(orgId),
      start_date: start.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
      department_ids: departments.map((id) => Number(id)),
      organizational_unit_ids: groupIds.map((id) => Number(id)),
    })
      .stats({
        data: "calculate",
      })
      .all()
      .then((response) => {
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const parsedRecords: ResourceSummaryMonthRecordDto[] = JSON.parse(
          // Mass lint disable
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          response.meta.stats.data.calculate
        )

        return (
          snakeCaseToCamelCase(parsedRecords) as ResourceSummaryMonthRecordDto[]
        ).map(
          (record): ResourceSummaryMonthRecord => ({
            ...record,
            departmentId: record.departmentId?.toString() ?? null,
            siteId: record.siteId?.toString() ?? null,
          })
        )
      })
  } catch {
    return []
  }
}

export default {
  fetchResourceSummaryMonthRecords,
  addGjValuesToReportData,
  buildColumnTitle,
  getFormattedColumns,
  getFormattedRecords,
  getSiteTotalGhgLbsCo2e,
  isSiteEmpty,
  sortReportData,
  sumReportData,
}
