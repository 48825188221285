import React from "react"

import { Chip, Typography } from "@mui/material"

import type { Option } from "../../../../nzds"
import { HStack } from "../../../../nzds"
import { selectedInterventionsThreshold } from "../../constants"

export function renderInterventionValue(
  interventionOptions: Option[]
): ((value: string[]) => React.ReactNode) | undefined {
  return function renderer(selected?: string[]) {
    if (selected && selected.length > 0) {
      let moreMessage
      if (selected.length > selectedInterventionsThreshold) {
        moreMessage = `+${selected.length - selectedInterventionsThreshold}`
      }
      return (
        <HStack
          alignItems="center"
          direction="row"
          gap={0.5}
          justifyContent="space-between"
        >
          <HStack gap={0.5} overflow="hidden">
            {selected.slice(0, selectedInterventionsThreshold).map((val) => {
              const label = interventionOptions.find(
                (checkbox) => checkbox.value === val
              )?.label
              return (
                <Chip
                  key={label}
                  color="neutral"
                  label={label}
                  size="small"
                  variant="filled"
                />
              )
            })}
          </HStack>

          {moreMessage ? (
            <Typography mr={1} variant="body2">
              {moreMessage}
            </Typography>
          ) : null}
        </HStack>
      )
    }

    return null
  }
}
