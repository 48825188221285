// @ts-strict-ignore
import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { useController } from "react-hook-form"

import { MultiSelectCheckboxField } from "../../inputs/multi-select-checkbox-field"
import type {
  FormMultiSelectCheckboxFieldProps,
  WithForwardRefType,
} from "./form-multi-select-checkbox-field.types"

export const FormMultiSelectCheckboxField: WithForwardRefType = forwardRef<
  HTMLInputElement,
  FormMultiSelectCheckboxFieldProps
>(({ name, id, helperText, ...rest }, ref) => {
  const { field, fieldState } = useController({
    name,
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const innerRef = useRef<any>()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  useImperativeHandle(ref, () => innerRef.current)

  return (
    <MultiSelectCheckboxField
      {...rest}
      {...field}
      error={Boolean(fieldState.error)}
      helperText={fieldState.error ? fieldState.error.message : helperText}
      id={id ?? name}
      inputRef={(node) => {
        field.ref(node)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        innerRef.current = node
      }}
    />
  )
})
