import React from "react"

import { Button } from "@mui/material"

import { useE2E } from "./utils/useE2E"
import { useFormContext } from "./utils/useFormContext"

interface ResetButtonProps {
  // eslint-disable-next-line react/no-unused-prop-types
  "data-e2e"?: string
}

const ResetButton = (props: ResetButtonProps) => {
  const {
    reset,
    formProps: { readOnly },
    formState: { isDirty },
  } = useFormContext()

  const dataE2E = useE2E({ name: "reset", ...props }, "button")

  if (readOnly) {
    return null
  }

  return (
    <Button
      color="neutral"
      data-e2e={dataE2E}
      disabled={!isDirty}
      onClick={() => {
        reset()
      }}
      variant="outlined"
    >
      Cancel
    </Button>
  )
}

export default ResetButton
