import { reject } from "lodash-es"
import type { WhereClause } from "spraypaint"
import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import { chunkRequests } from "../utils"
import Address from "./address"
import type { IFetchApplicationRecordOptions } from "./applicationRecord/applicationRecord"
import ApplicationRecord from "./applicationRecord/applicationRecord"
import { fetchSiteBillCount } from "./bill"
import { fetchSiteBillingGroupCount } from "./billingGroup"
import Currency from "./currency"
import Department from "./department"
import LoadAggregationPoint from "./loadAggregationPoint"
// Cannot yet be imported as a type
// because the class must be imported somewhere for it to be added to the modelRegistry
// See spraypaint.js/src/decorators.js -> modelFactory() -> ModelClass.registerType()
import Location from "./location"
// Cannot yet be imported as a type
// because the class must be imported somewhere for it to be added to the modelRegistry
// See spraypaint.js/src/decorators.js -> modelFactory() -> ModelClass.registerType()
import Node from "./node"
import Organization from "./organization"
import OrganizationalUnit from "./organizationalUnit"
import PhysicalDetail from "./physcialDetails"
// Cannot yet be imported as a type
// because the class must be imported somewhere for it to be added to the modelRegistry
// See spraypaint.js/src/decorators.js -> modelFactory() -> ModelClass.registerType()
import Sink from "./sink"
import { fetchSiteSiteEmissionCount } from "./siteEmission"
import { Order } from "./sort"

export interface SiteInterface {
  readonly carbonEquivalentEmissionsGoal: number
  readonly lineLossFactor: number
  readonly locationId: string
  readonly name: string
  readonly tariffDollars: number
}

interface CustomVariablesInterface {
  description: string | null
  name: string
  value: number
}

export interface ISitePreviewDto {
  departmentName: string | null
  electricityType: string
  siteId: string
  siteName: string
  timezone: string
  topOrgUnitName: string
}

export interface ISitePreview {
  departmentName: string | null
  electricityType: string
  id: string
  name: string
  timezone: string
  topOrgUnitName: string
}

export interface ISiteCounts {
  billingGroups: number
  bills: number
  siteEmissions: number
}

@Model()
export class Site extends ApplicationRecord {
  public static jsonapiType = "sites"

  @BelongsTo("currencies") public currency: Currency

  // Cannot yet be written as a string (e.g. @BelongsTo("locations"))
  // because the class must be imported somewhere for it to be added to the modelRegistry
  @BelongsTo(Location) public location: Location

  @BelongsTo("organizations") public organization: Organization

  @BelongsTo("organizational_units")
  public organizationalUnit: OrganizationalUnit

  @BelongsTo("departments") public department: Department

  // Cannot yet be written as a string (e.g. @BelongsTo("sinks"))
  // because the class must be imported somewhere for it to be added to the modelRegistry
  @BelongsTo(Sink) public sink: Sink

  // Cannot yet be written as a string (e.g. @BelongsTo("nodes"))
  // because the class must be imported somewhere for it to be added to the modelRegistry
  @HasMany(Node) public nodes: Node[]

  @HasOne("load_aggregation_points")
  public loadAggregationPoint: LoadAggregationPoint

  // Cannot yet be written as a string (e.g. @BelongsTo("addresses"))
  // because the class must be imported somewhere for it to be added to the modelRegistry
  @HasOne(Address)
  public address: Address | undefined

  // Cannot yet be written as a string (e.g. @BelongsTo("nodes"))
  // because the class must be imported somewhere for it to be added to the modelRegistry
  @HasMany(PhysicalDetail)
  public physicalDetails: PhysicalDetail[] | undefined

  @Attr() public readonly dateCreated: string

  @Attr() public readonly dateUpdated: string

  @Attr() public readonly granularity: string

  @Attr() public readonly lineLossFactor: number

  @Attr() public readonly notes: string

  @Attr() public readonly tariffDollars: number

  @Attr() public readonly tariffMajor: number

  @Attr() public yearBuilt?: number

  @Attr() public readonly name: string

  @Attr() public readonly baCode: string

  @Attr() public readonly timezone: string

  @Attr() public readonly loadAggregationPointId: number

  @Attr() public locationId?: string

  @Attr() public readonly carbonEquivalentEmissionsGoal: number

  @Attr() public readonly organizationId: number

  @Attr() public readonly organizationalUnitId: number

  @Attr() public readonly electricityType: string

  @Attr() public readonly departmentId: number

  @Attr() public readonly departmentName: string

  @Attr() public readonly hasAmiMeter: boolean

  @Attr() public readonly hasAmrMeter: boolean

  @Attr() public readonly hasSubMeter: boolean

  @Attr() public readonly probeMeter: boolean

  @Attr() public readonly customVariables: CustomVariablesInterface[]

  @Attr() public readonly locationName: string

  @Attr() public readonly billingGroupNames: string[]

  @Attr() public readonly siteAlias: string

  @Attr() public sqFt?: string | null

  @Attr() public numberOfFloors?: number

  @Attr() public buildingType?: string | null

  isBundledEnergyCustomer(): boolean {
    return this.electricityType === "bundled"
  }
}

export default Site

/**
 * Fetches the site count for an organization
 *
 * @param orgId - The organization id
 * @returns - The site count
 * @example
 * fetchOrganizationSiteCount("15")
 */
export const fetchOrganizationSiteCount = async (
  orgId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await Site.where({
    organization_id: orgId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}

/**
 * Fetches the counts for an site
 *
 * @param siteId - The site id
 * @returns - The site counts
 * @example
 * fetchSiteCounts("15")
 */
export const fetchSiteCounts = async (siteId: string): Promise<ISiteCounts> => {
  const [bills, siteEmissions, billingGroups] = await Promise.all([
    fetchSiteBillCount(siteId),
    fetchSiteSiteEmissionCount(siteId),
    fetchSiteBillingGroupCount(siteId),
  ])
  const counts: ISiteCounts = {
    bills,
    siteEmissions,
    billingGroups,
  } as ISiteCounts

  return counts
}

/**
 * Fetches the site count for a department
 *
 * @param departmentId - The department id
 * @returns - The site count
 * @example
 * fetchDepartmentSiteCount("15")
 */
export const fetchDepartmentSiteCount = async (
  departmentId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await Site.where({
    department_id: departmentId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}

export const filterOrganizationSites = (
  organizationSites: Site[],
  departmentId
): Site[] =>
  departmentId
    ? reject(
        organizationSites,
        (site) => site.departmentId !== Number(departmentId)
      )
    : organizationSites

export const fetchSites = async (
  clause: WhereClause,
  options?: Partial<IFetchApplicationRecordOptions>
): Promise<Site[]> => {
  const mergedOptions: IFetchApplicationRecordOptions = {
    ...{
      order: Order.asc,
      orderBy: "name",
      pageSize: 1000,
      pageNumber: 1,
    },
    ...options,
  }

  if (!clause) {
    return []
  }

  const response: CollectionProxy<Site> = await Site.where(clause)
    .includes([
      "currency",
      "nodes",
      "sink",
      "location",
      "billing_groups",
      "address",
    ])
    .selectExtra(["location_name", "billing_group_names", "sq_ft"])
    .order({ [mergedOptions.orderBy]: mergedOptions.order })
    .per(mergedOptions.pageSize)
    .page(mergedOptions.pageNumber)
    .all()
  return response.data
}

export const fetchAllSites = async (
  departmentId: string,
  chunkSize: number,
  totalCount: number
): Promise<Site[]> => {
  if (!Number.isInteger(totalCount)) {
    return []
  }
  const request = (pageNumber: number, pageSize: number) =>
    fetchSites(
      { department_id: departmentId },
      { orderBy: "name", pageNumber, pageSize }
    )
  return chunkRequests(request, chunkSize, totalCount)
}
