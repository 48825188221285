import React from "react"

import type { Moment } from "moment"

import { useTheme } from "@mui/material"

import { LoadingSpinner } from "../../../components/loadingSpinner"
import { SiteRow } from "../../../components/organizationSummary/sitesTable/siteRow"
import type { ISiteSummary } from "../../../models/monthSummary"
import type { UnitName } from "../../../models/unit"

/**
 * @deprecated This UI is slated to be replaced and this component will be deleted
 */
export const DirectAccessSitesTable = ({
  dateRange,
  directAccessSiteTotals,
  isDirectAccessSiteTotalsFetched,
  whDisplayUnit,
}: {
  dateRange: Moment[]
  directAccessSiteTotals: ISiteSummary[]
  isDirectAccessSiteTotalsFetched: boolean
  whDisplayUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const theme = useTheme()

  const orderedGroupSummaryData: ISiteSummary[] | undefined =
    directAccessSiteTotals?.sort((a, b) => b.costMajor - a.costMajor)

  return (
    <div>
      {!isDirectAccessSiteTotalsFetched && (
        <LoadingSpinner
          dataE2e="site-loading-spinner"
          style={{ minHeight: "200px" }}
        >
          Loading...
        </LoadingSpinner>
      )}
      {isDirectAccessSiteTotalsFetched && (
        <table className="financial-summary__sites-table organization-summary__table">
          <thead
            style={{
              backgroundColor: theme.palette.common.white,
            }}
          >
            <tr className="sites-table__site-row">
              <th data-sites="name">Site Detail</th>
              <th data-sites="cost">Total Cost</th>
              <th data-sites="volume">Total Volume</th>
              <th data-sites="avg-cost">Avg. Energy Cost</th>
              <th data-sites="imbalance">Imbalance</th>
            </tr>
          </thead>
          <tbody>
            {orderedGroupSummaryData?.map((siteSummaryDatum) => (
              <SiteRow
                key={siteSummaryDatum.siteId}
                dateRange={dateRange}
                hasBundled={false}
                siteSummaryDatum={siteSummaryDatum}
                whDisplayUnit={whDisplayUnit}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
