import React, { useMemo } from "react"
import { Link, useLocation } from "react-router-dom"

import { Stack, Tab, Tabs, Typography } from "@mui/material"

import FeatureReleaseCallout, {
  FeatureReleaseCalloutTypes,
} from "../../../../components/featureReleaseCallout/featureReleaseCallout"
import { RootPath, TamAdminPath } from "../../../../models/route"
import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import { pxToRem } from "../../../../utils/unit"
import { getActiveTabIndex } from "../../util/tamAdmin/tamAdmin"
import TamAdminOrganizationTable from "./tamAdminOrganizationTable/tamAdminOrganizationTable"

const TamAdminOnboarding = () => {
  const location = useLocation()
  const activeTabIndex: number = useMemo(
    () => getActiveTabIndex(location.pathname),
    [location]
  )
  return (
    <>
      <Stack alignItems="center" direction="row" pb={2.5} pt={2} spacing={1}>
        <Typography variant={TYPOGRAPHY_VARIANT.h1}>Admin Tool</Typography>
        <FeatureReleaseCallout
          sx={{ height: pxToRem(20) }}
          type={FeatureReleaseCalloutTypes.BETA}
        />
      </Stack>
      <Tabs sx={{ mb: 2.5 }} value={activeTabIndex}>
        <Tab
          component={Link}
          label="All Orgs"
          to={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}`}
        />
      </Tabs>
      <TamAdminOrganizationTable />
    </>
  )
}

export default TamAdminOnboarding
