import type { FC } from "react"
import React, { memo, useEffect, useRef } from "react"

import { LinearProgress } from "@mui/material"
import type { DataGridProProps } from "@mui/x-data-grid-pro"
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro"

import { HierarchyGroupingCell } from "./hierarchy-grouping-cell/hierarchy-grouping-cell"
import type { HierarchyGroupingCellProps } from "./hierarchy-grouping-cell/hierarchy-grouping-cell.types"
import type { OrganizeDataGridProps } from "./organize-data-grid.types"

export const OrganizeDataGrid: FC<OrganizeDataGridProps> = memo(
  ({ isLoading, rows, onRowSelect }) => {
    const apiRef = useGridApiRef()
    const initialRowsRef = useRef(rows)

    // This useEffect clears any checkbox selections when the structure of the
    // hierarchy is changed, but the selected row ID still exists.
    useEffect(() => {
      if (rows.length && rows !== initialRowsRef.current) {
        apiRef.current.setRowSelectionModel([])
        initialRowsRef.current = rows
      }
    }, [apiRef, initialRowsRef, rows])

    const groupingColDef: DataGridProProps["groupingColDef"] = {
      headerName: "Organizational Units (OU)",
      hideDescendantCount: true,
      flex: 1,
      filterable: true,
      disableColumnMenu: false,
      resizable: false,
      mainGroupingCriteria: "name",
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      valueGetter: ({ row }) => row.name,
      renderCell: (props: HierarchyGroupingCellProps) => (
        <HierarchyGroupingCell {...props} />
      ),
    }

    return (
      <DataGridPro
        apiRef={apiRef}
        checkboxSelection
        columns={[]}
        disableRowSelectionOnClick
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        getRowId={(row) => `${row.type}-${row.id}`}
        // Mass lint disable
        // Mass eslint disable @typescript-eslint/no-unsafe-return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
        getTreeDataPath={(row) => row.path}
        groupingColDef={groupingColDef}
        loading={isLoading}
        onRowSelectionModelChange={onRowSelect}
        rows={rows}
        slots={{ loadingOverlay: LinearProgress }}
        sx={{ backgroundColor: "white" }}
        treeData
      />
    )
  }
)
