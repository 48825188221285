import { useCallback } from "react"

import type { Organization } from "@/models/organization"
import { useAuthentication } from "@/services/authentication"
import { useLocalStorage } from "@uidotdev/usehooks"

interface UserState {
  organizationId: string
}

type UsersState = Record<string, UserState>

interface UseUserStateValue {
  saveActiveOrganization: (organizationId: Organization["id"]) => void
  userState: UserState
}

export const emptyUserState: UserState = {
  organizationId: "",
}

export const useUserState = (): UseUserStateValue => {
  const { authenticatedUser } = useAuthentication()

  const [usersState, setUserState] = useLocalStorage<UsersState>(
    `${process.env.REACT_APP_ENV}UserSessionInfo`,
    {}
  )

  const saveActiveOrganization = useCallback(
    (organizationId: Organization["id"]): void => {
      const newUserState: UserState = {
        ...(usersState[authenticatedUser?.id] ?? emptyUserState),
        organizationId,
      }

      setUserState({
        ...usersState,
        [authenticatedUser?.id]: newUserState,
      })
    },
    [authenticatedUser?.id, setUserState, usersState]
  )

  return {
    saveActiveOrganization,
    userState: usersState[authenticatedUser?.id] ?? emptyUserState,
  }
}
