import { Attr, BelongsTo, HasMany, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"
import Organization from "./organization"
import type Site from "./site"

@Model()
export default class OrganizationalUnit extends ApplicationRecord {
  public static jsonapiType = "organizational_units"

  @BelongsTo("organizations") public organization: Organization

  @HasMany("sites") public sites: Site[]

  @Attr() public name: string

  @Attr() public parentId: number

  @Attr() public organizationId: number
}

/**
 * Fetches the OU count for an organization
 * @param orgId - The organization id
 * @returns The total number of OUs assocaited with the organization
 * @example
 * const count = await fetchOrganizationalUnitCount("15")
 */
export const fetchOrganizationalUnitCount = async (
  orgId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await OrganizationalUnit.where({
    organization_id: orgId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}
