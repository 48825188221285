import type { Organization } from "@/models/organization"
import type { Schemas } from "@/services/types"
import { httpClient } from "@/utils/api"
import type { StreamingAdapterObserver } from "@nlux/react"
import { SSE } from "sse.js"
import type { ReadyStateEvent, SSEvent } from "sse.js"

import type { Thread } from "../../assistant.types"
import type { SSEMessageData } from "./assistant-utils.types"

/** Gets a file encoded in base64 */
const getBase64EncodedFile = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  organization_id: Organization["id"]
): Promise<string> => {
  // Image is hardcoded until Rails API is implemented
  const response = await Promise.resolve({
    data: {
      content:
        "PHN2ZyB3aWR0aD0iMTEyIiBoZWlnaHQ9IjY1IiB2aWV3Qm94PSIwIDAgMTEyIDY1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEyIDI2QzExMiAzLjc0NjA0IDc5LjAxNjEgMCA1NiAwVjEzQzMyLjk4MzkgMTMgMCAxNi43NDYgMCAzOUMwIDYxLjI1NCAzMi45ODM5IDY1IDU2IDY1VjUyQzc5LjAxNjEgNTIgMTEyIDQ4LjI1NCAxMTIgMjZaTTU2IDUyVjM5Qzc2Ljk2MTEgMzkgMTA3IDM3LjEyNyAxMDcgMjZDMTA3IDE0Ljg3MyA3Ni45NjExIDEzIDU2IDEzVjI2QzM1LjAzODkgMjYgNSAyNy44NzMgNSAzOUM1IDUwLjEyNyAzNS4wMzg5IDUyIDU2IDUyWiIgZmlsbD0iIzE4MTgxQiIvPgo8L3N2Zz4K",
    },
  })

  // const response = await httpClient.get<string>(
  //   `/api/v1/recommendations/assistant_files/${id}`,
  //   {
  //     params: {
  //       organization_id,
  //     },
  //   }
  // )

  return response.data.content
}

type CreateThreadParams = Omit<Schemas["ThreadRequest"], "assistant_user_id">

/**
 * Initializes an assistant thread and returns prior messages
 */
export const createThread = async (
  params: CreateThreadParams
): Promise<Thread> => {
  const response = await httpClient.post<Thread>(
    "/api/v1/recommendations/assistant/threads",
    params
  )

  return response.data
}

type SendMessageParams = Omit<
  Schemas["AssistantRequest"],
  "organization_name" | "version"
>

/**
 * Sends a message to the assistant which streams the response using server-sent events
 */
export const sendMessage = (
  observer: StreamingAdapterObserver,
  params: SendMessageParams
): void => {
  const sse = new SSE("/api/v1/recommendations/assistant/conversation", {
    headers: { "Content-Type": "application/json" },
    payload: JSON.stringify(params),
  })

  sse.addEventListener("message", async (e: SSEvent): Promise<void> => {
    let data: SSEMessageData | undefined

    try {
      data = (
        JSON.parse(e.data) as {
          data: SSEMessageData | undefined
        }
      ).data
    } catch (error) {
      const message = "Failed to parse assistant conversation response"

      console.error(message)
      observer.error(new Error(message))
    }

    if (data?.delta?.content?.length) {
      for (const content of data.delta.content) {
        if (content.type === "image_file" && content.image_file) {
          try {
            const image = await getBase64EncodedFile(
              content.image_file.file_id,
              params.organization_id
            )

            // Markdown reference: `![${content.image_file.detail ?? ""}](data:image/svg+xml;base64,${image})`
            // HTML reference: `<div><img src="data:image/svg+xml;base64,${image}" alt="${content.image_file.detail ?? ""}" /></div>`
            observer.next(
              `<div><img src="data:image/svg+xml;base64,${image}" alt="${content.image_file.detail ?? ""}" /></div>`
            )
          } catch (error) {
            const message = "Failed to request image"

            console.error(message)
            observer.error(new Error(message))
          }
        }

        if (content.type === "text" && content.text?.value) {
          observer.next(content.text.value)
        }
      }
    }
  })

  sse.addEventListener("readystatechange", (e: ReadyStateEvent) => {
    if (e.readyState === sse.CLOSED) {
      sse.close()
      observer.complete()
    }
  })

  sse.addEventListener("error", () => {
    const message = "Failed to stream assistant conversation"

    console.error(message)
    observer.error(new Error(message))
  })
}
