import React, { useCallback } from "react"
import type { FC } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import type Organization from "@/models/organization"

import { Box, MenuItem, Typography, useTheme } from "@mui/material"

import type { UserMenuOrganizationsProps } from "./user-menu-organizations.types"

export const UserMenuOrganizations: FC<UserMenuOrganizationsProps> = ({
  activeOrganization,
  heading,
  onOrganizationClick,
  organizations,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()

  const handleMenuItemClick = useCallback(
    (organization: Organization): void => {
      onOrganizationClick?.(organization)

      if (organization.id !== activeOrganization?.id) {
        const toUrl = location.pathname.replace(
          `/${activeOrganization?.id}`,
          `/${organization.id}`
        )

        navigate(toUrl)
      }
    },
    [activeOrganization?.id, location.pathname, navigate, onOrganizationClick]
  )

  return (
    <Box mb={1}>
      <Typography
        color="grey.700"
        fontSize={theme.typography.body2.fontSize}
        my={1}
        px={1}
      >
        {heading}
      </Typography>
      {organizations.map((org) => (
        <MenuItem
          key={org.id}
          onClick={() => {
            handleMenuItemClick(org)
          }}
          selected={org.id === activeOrganization?.id}
          sx={{
            pl: 3,
            pr: 1,
            py: 1,
            whiteSpace: "normal",
          }}
        >
          {org.name}
        </MenuItem>
      ))}
    </Box>
  )
}
