import { CIN_BELL_ID } from "../models/organization"
import type User from "../models/user"

export const isCinBellUser = (user: User): boolean => {
  const cinBellEmailRegex = /.+@cinbell.com/
  const hasCinBellEmail: boolean = cinBellEmailRegex.test(user.email)
  const hasCinBellAccess: boolean = user.organizationIds.includes(
    Number(CIN_BELL_ID)
  )

  return hasCinBellEmail && hasCinBellAccess && !user.admin
}
