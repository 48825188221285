import { useMemo } from "react"

import type {
  InputMutation,
  MutationInputStatus,
  ServiceAction,
  ServiceName,
  ServiceValue,
} from "../../types"
import { createOutputStatus } from "../createMutationOutputStatus/createMutationOutputStatus"
import { createOutputMutation } from "../createOutputMutation/createOutputMutation"

/**
 * @deprecated Use {@link UseRenameQueryResult} instead
 *
 * Create a renamed and memoized return object for a particular service.
 * Renames data to serviceName and isLoading to isServiceGetOneLoading, etc.
 *
 * @param serviceName The name of the service.
 * @param serviceAction The action of the service.
 * @param inputObject The input object to be remapped.
 * @returns The return object.
 * @example
 * useMutationReturnHandle(["test", "updateOne"], {
 * isIdle: false,
 * isLoading: false,
 * isSuccess: true,
 * isError: false,
 * })
 */
export const useMutationReturnHandle = <
  NameT extends ServiceName,
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
>(
  // Accept a query key, but allow any other tuple as well:
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [serviceName, serviceAction]: [NameT, ActionT, ...any],
  // Formatted to accept the return value of react-query's useMutation.
  inputObject: MutationInputStatus & InputMutation<ActionT, ValueT>
) => {
  const outputStatus = useMemo(
    () => createOutputStatus(serviceName, serviceAction, inputObject),
    [inputObject, serviceAction, serviceName]
  )

  const outputMutation = useMemo(
    () => createOutputMutation(serviceName, serviceAction, inputObject),
    [inputObject, serviceAction, serviceName]
  )

  return useMemo(
    () => ({
      ...outputStatus,
      ...outputMutation,
    }),
    [outputStatus, outputMutation]
  )
}
