import type { WhereClause } from "spraypaint"
import { Attr, Model } from "spraypaint"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import type { IFetchApplicationRecordOptions } from "./applicationRecord/applicationRecord"
import ApplicationRecord from "./applicationRecord/applicationRecord"
import { fetchBillingGroupMeterCount } from "./meter"
import { Order } from "./sort"

export interface IBillingGroupCounts {
  meters: number
}

@Model()
export default class BillingGroup extends ApplicationRecord {
  public static jsonapiType = "billing_groups"

  @Attr() public readonly groupName: string

  @Attr() public readonly siteId: number

  @Attr() public readonly alias: string

  @Attr() public readonly dateCreated: string

  @Attr() public readonly dateUpdated: string
}

/**
 * Fetches the counts for an billing group
 *
 * @param billingGroupId - The billing group id
 * @returns - The billing group counts
 * @example
 * fetchBillingGroupCounts("15")
 */
export const fetchBillingGroupCounts = async (
  billingGroupId: string
): Promise<IBillingGroupCounts> => {
  const meters = await fetchBillingGroupMeterCount(billingGroupId)

  return { meters }
}

/**
 * Fetches the billing group count for a site
 *
 * @param siteId - The site id
 * @returns - The billing group count
 * @example
 * fetchSiteBillingGroupCount("15")
 */
export const fetchSiteBillingGroupCount = async (
  siteId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await BillingGroup.where({
    site_id: siteId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}

/**
 * Fetches the billing group count for an organization
 *
 * @param orgId - The organization id
 * @returns - The billing group count
 * @example
 * fetchOrganizationBillingGroupCount("15")
 */
export const fetchOrganizationBillingGroupCount = async (
  orgId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await BillingGroup.where({
    organization_id: orgId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}

export const fetchBillingGroups = async (
  clause: WhereClause | null,
  options?: Partial<IFetchApplicationRecordOptions>
): Promise<BillingGroup[]> => {
  const mergedOptions: IFetchApplicationRecordOptions = {
    ...{
      order: Order.asc,
      orderBy: "groupName",
      pageSize: 1000,
      pageNumber: 1,
    },
    ...options,
  }

  if (!clause) {
    return []
  }

  const response: CollectionProxy<BillingGroup> = await BillingGroup.where(
    clause
  )
    .order({ [mergedOptions.orderBy]: mergedOptions.order })
    .per(mergedOptions.pageSize)
    .page(mergedOptions.pageNumber)
    .all()
  return response.data
}
