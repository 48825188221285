// @ts-strict-ignore
import React, { useMemo, useState } from "react"
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"

import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"
import { StyledPageCard } from "@/components/page-card/styled-page-card"
import { PageLoading } from "@/components/page-loading/page-loading"
import { SectionHeader } from "@/components/section-header/section-header"
import { HStack } from "@/nzds/layout"
import { useDisclosure } from "@/nzds/utils/use-disclosure/use-disclosure"
import type { Schemas } from "@/services/types"
import { useUrl } from "@/utils/url"
import { datadogRum } from "@datadog/browser-rum"

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded"
import { Box, Button, IconButton, Stack } from "@mui/material"

import SiteInfoButton from "../../../site/components/siteInfo/siteInfoButton/siteInfoButton"
import { InterventionsTable } from "../../components/interventions-table/interventions-table"
import type { RowData } from "../../components/interventions-table/interventions-table.types"
import { PlanConfirmationDialog } from "../../components/plan-confirmation-dialog/plan-confirmation-dialog"
import { PlanDetailsSavingsBreakdown } from "../../components/plan-details-savings-breakdown/plan-details-savings-breakdown"
import type { Breakdown } from "../../components/plan-details-savings-breakdown/plan-details-savings-breakdown.types"
import { PlanStatsBar } from "../../components/plan-stats-bar/plan-stats-bar"
import { PlanningSteps } from "../../components/planning-steps/planning-steps"
import { SiteInfoDrawer } from "../../components/site-info-drawer/site-info-drawer"
import {
  drawerContainerGap,
  drawerWidth,
} from "../../components/site-info-drawer/site-info-drawer.constants"
import { RecommendationsPath } from "../../enums"
import { useCreateSitePlan } from "../../services/useCreateSitePlan/use-create-site-plan"
import { useGetSite } from "../../services/useGetSite/use-get-site"
import { useGetSiteRecommendation } from "../../services/useGetSiteRecommendation/use-get-site-recommendation"
import { useBuildPlanNameString } from "../../utils/build-plan-name"

export const SitePlanDetailsEditRoute = () => {
  const { recommendationsPath } = useUrl()
  const { siteId, planId } = useParams<Record<"siteId" | "planId", string>>()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const {
    onToggle: onToggleDrawer,
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: site } = useGetSite(siteId!)

  const location = useLocation()

  const state = location.state as
    | { goBackTo?: string; searchParams?: string }
    | undefined

  const { mutateAsync, isLoading: isCreateSitePlanLoading } =
    useCreateSitePlan()

  const {
    data: plan,
    isFetching: planIsFetching,
    isLoading: planIsloading,
  } = useGetSiteRecommendation(Number(planId))

  const emissionsSavingsBreakdown = useMemo(() => {
    const breakDown: Breakdown[] = []
    if (plan?.interventions) {
      for (const group in plan.interventions) {
        // calc total value
        let value = 0
        // reformat interventions
        const interventions = []

        for (const intervention in plan.interventions[group]) {
          interventions.push({
            name: intervention,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            ...plan.interventions[group][intervention].contribution,
          })
          value +=
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            plan.interventions[group][intervention].contribution
              .emission_savings_ratio
        }

        breakDown.push({
          type: group,
          value,
          interventions,
        })
      }
    }
    return breakDown
  }, [plan?.interventions])

  const rows = useMemo(() => {
    const computedRows: RowData[] = []
    if (plan?.interventions) {
      for (const group in plan.interventions) {
        for (const intervention in plan.interventions[group]) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const interventionData: Schemas["InterventionContribution"] =
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            plan.interventions[group][intervention]

          const {
            annual_emissions_savings,
            capital_cost,
            annual_cost_savings,
            emission_savings_ratio,
          } = interventionData.contribution

          computedRows.push({
            interventionType: group,
            name: intervention,
            id: intervention,
            annualEmissionsSavings: annual_emissions_savings,
            capitalCost: capital_cost,
            annualCostSavings: annual_cost_savings,
            percentEmissionSavings: emission_savings_ratio,
            roiYears: capital_cost / annual_cost_savings,
          })
        }
      }
    }

    return computedRows
  }, [plan?.interventions])

  const handleClickSave = async () => {
    await mutateAsync({
      organization_id: site?.organizationId.toString(),
      plan_id: Number(planId),
      site_id: Number(siteId),
    })
    datadogRum.addAction("Plan saved", {
      interventions: rows.map((row) => row.name).join("|"),
      inverentionsCount: rows.length,
      emissionsDiff: plan?.emissions_diff,
      costDiff: plan?.cost_diff,
    })
    setOpen(true)
  }

  const handleOnClose = () => {
    setOpen(false)
    navigate(`${recommendationsPath}`)
  }

  const isLoading = planIsFetching || planIsloading

  const siteRecommendationsTo = `${generatePath(
    `${recommendationsPath}/${RecommendationsPath.Site}`,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { siteId: siteId! }
  )}${state?.searchParams ? `?${state.searchParams}` : ""}`

  const backButtonTo = state?.goBackTo ?? siteRecommendationsTo

  const { buildPlanNameString } = useBuildPlanNameString()
  const planNameFromInterventions = buildPlanNameString(plan)

  return (
    <PageLoading isLoading={isLoading}>
      <>
        {site ? (
          <>
            <PageHeader title={`Plan details for ${site.name}`} />
            <PageHeaderActionBar hasTabs={false}>
              <HStack gap={2} justifyContent="space-between">
                <IconButton
                  aria-label="Back to select a site plan"
                  color="neutral"
                  component={Link}
                  to={backButtonTo}
                >
                  <ArrowBackIosNewRoundedIcon />
                </IconButton>
                <SiteInfoButton
                  isOpen={isDrawerOpen}
                  onClick={onToggleDrawer}
                />
              </HStack>
            </PageHeaderActionBar>
            <Page>
              <HStack gap={2} width="100%">
                <StyledPageCard
                  offset={drawerWidth + drawerContainerGap}
                  open={isDrawerOpen}
                >
                  {!isLoading && plan ? (
                    <Stack alignItems="stretch" gap={3}>
                      <PlanningSteps
                        activeStep={3}
                        planId={planId}
                        siteId={siteId}
                      />
                      {planNameFromInterventions ? (
                        <SectionHeader
                          actions={
                            <Button
                              color="primary"
                              disabled={isCreateSitePlanLoading}
                              onClick={() => {
                                void handleClickSave()
                              }}
                              variant="contained"
                            >
                              Save Plan
                            </Button>
                          }
                          title={planNameFromInterventions}
                        />
                      ) : null}

                      <PlanStatsBar count={rows.length} plan={plan} />
                      <Box my={2}>
                        <PlanDetailsSavingsBreakdown
                          breakdown={emissionsSavingsBreakdown}
                        />
                      </Box>
                      <InterventionsTable rows={rows} />
                    </Stack>
                  ) : null}
                </StyledPageCard>
                <SiteInfoDrawer
                  onClose={onDrawerClose}
                  open={isDrawerOpen}
                  site={site}
                />
              </HStack>
            </Page>
          </>
        ) : null}
        <PlanConfirmationDialog onClose={handleOnClose} open={open} />
      </>
    </PageLoading>
  )
}
