import type Color from "color"
import {
  flatten,
  groupBy,
  includes,
  isNull,
  last,
  map,
  max,
  min,
  sortBy,
  toPairs,
  values,
} from "lodash-es"

import type EnergyAllocation from "../../models/energyAllocation"
import { ElectricityType } from "../../models/energyAllocation"
import {
  black,
  getProductColor,
  darkSlateBlue as highCarbonBundledColor,
  cometBlue as highMidCarbonBundledColor,
  darkGray as highestCarbonBundledColor,
  lightSolarOrange,
  lightSlateBlue as lowMidCarbonBundledColor,
  linkWater as lowerCarbonBundledColor,
  ghostWhite as lowestCarbonBundledColor,
  transparent,
} from "../../utils/colors"

const aggregatePrice = (
  aggregateProduct: Product,
  currAllocation: EnergyAllocation
) => {
  const price = currAllocation.displayPrice()

  let maxPrice = null
  let minPrice = null
  let priceRange = null

  const currentPriceAndNotAggregatePrice: boolean =
    Number.isFinite(price) && !Number.isFinite(aggregateProduct.price)

  const priceChangedAndNoPriceRange: boolean =
    aggregateProduct.priceRange === null && price !== aggregateProduct.price

  const priceIsValidAndRangeIsNotNull: boolean =
    Number.isFinite(price) && aggregateProduct.priceRange !== null

  if (currentPriceAndNotAggregatePrice && priceChangedAndNoPriceRange) {
    priceRange = {
      min: Math.min(price, aggregateProduct.price),
      max: Math.min(price, aggregateProduct.price),
    }
  }

  if (priceIsValidAndRangeIsNotNull) {
    maxPrice = Math.max(price, aggregateProduct.priceRange.max)
    minPrice = Math.min(price, aggregateProduct.priceRange.min)
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    priceRange = { max: maxPrice, min: minPrice }
  }

  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return { price, priceRange }
}

const aggregateImbalance = (aggregateProduct, currAllocation) => {
  if (
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    aggregateProduct.imbalanceKwh == null ||
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    currAllocation.imbalanceKwh == null
  ) {
    return null
  }

  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  return currAllocation.imbalanceKwh + aggregateProduct.imbalanceKwh
}

const aggregateCo2eLbs = (aggregateProduct, currAllocation) => {
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (aggregateProduct.co2eLbs == null || currAllocation.co2eLbs == null) {
    return null
  }

  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  return currAllocation.co2eLbs + aggregateProduct.co2eLbs
}

const aggregateCost = (aggregateProduct, currAllocation) => {
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  if (aggregateProduct.cost == null || currAllocation.displayCost() == null) {
    return null
  }

  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  return currAllocation.displayCost() + aggregateProduct.cost
}

const idLongImbalance = (aggregateProductForAllocation) => {
  if (
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    aggregateProductForAllocation.id === "imbalance" &&
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    aggregateProductForAllocation.imbalanceKwh != null &&
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    aggregateProductForAllocation.imbalanceKwh < 0
  ) {
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    aggregateProductForAllocation.id = "longImbalance"
  }
}

/**
 * Sets productOrder on each Energy Allocation based on the minimum stack order value found in the collection
 *
 * @param allocationsByIds - A dictionary of energy allocations grouped by allocationId
 */
const setStackOrder = (allocationsByIds: EnergyAllocation[][]) => {
  allocationsByIds.forEach((allocationsById) => {
    const minStackOrder = min(allocationsById.map((alloc) => alloc.stackOrder))
    allocationsById.forEach((allocationById) => {
      allocationById.productOrder = minStackOrder
    })
  })
}

/**
 * Sets the stack order for all Energy Allocations in each hour
 *
 * @param allocationsByHours - A dictionary of energy allocations without productOrder set
 */
const setMaxProductOrder = (allocationsByHours: EnergyAllocation[][]) => {
  allocationsByHours.forEach((allocationsForHour) => {
    const allocationsByIds = values(
      groupBy(
        allocationsForHour,
        (allocationForHour) => allocationForHour.allocationId
      )
    )
    setStackOrder(allocationsByIds)
  })
}

export {
  lowestCarbonBundledColor,
  lowerCarbonBundledColor,
  lowMidCarbonBundledColor,
  highMidCarbonBundledColor,
  highCarbonBundledColor,
  highestCarbonBundledColor,
}

interface PriceRange {
  max: number | null
  min: number | null
}

export enum ProductType {
  Curtailment = "curtailment",
  DeliveredBundled = "delivered",
  FixedConfirm = "fixed",
  LongImbalance = "longImbalance",
  MarketConfirm = "market",
  ReceivedBundled = "received",
  ResourceBasedConfirm = "resource",
  ShortImbalance = "shortImbalance",
}

const productsUsingImbalanceKwh = [
  ProductType.ShortImbalance,
  ProductType.LongImbalance,
  ProductType.ReceivedBundled,
  ProductType.DeliveredBundled,
]

export default class Product {
  public confirmId?: number | null

  public lineLossCost?: number | null

  public kwh: number

  public taggedKwh: number

  public dailyAvgKwh: number

  public imbalanceKwh: number | null

  public cost: number | null

  public priceRange: PriceRange | null

  public price: number | null

  public order: number

  public productType: ProductType

  public electricityType: ElectricityType

  public energyProviderName: string | null

  public co2eLbs: number | null

  public carbonIntensity: number | null

  public emissionsRates?: Record<
    "carbon_equivalent_emissions" | string,
    string
  > | null

  public confirmName: string

  private allocationId: string

  public get displayName() {
    const parsedAllocationId = this.allocationId.split("-")
    if (this.shortImbalance()) {
      return "Short Imbalance"
    }
    if (this.longImbalance()) {
      return "Long Imbalance"
    }
    if (this.curtailment()) {
      return "Curtailment"
    }
    if (this.receivedEnergy()) {
      return "To Grid"
    }
    if (this.deliveredEnergy()) {
      return `${parsedAllocationId[0]}`
    }
    return this.confirmName
  }

  public get id() {
    return this.allocationId
  }

  public set id(newId) {
    this.allocationId = newId
    this.productType = this.deriveProductType()
    this.setCost(newId)
  }

  private setCost(allocationId) {
    if (allocationId === ProductType.LongImbalance) {
      this.cost = -this.cost
    }
  }

  constructor(
    attrs = {
      cost: 0,
      kwh: 0,
      co2eLbs: 0,
      id: "",
      imbalanceKwh: 0,
      price: null,
      priceRange: null,
      taggedKwh: null,
      order: null,
      electricityType: null,
    },
    emissionsRates?: Record<string, string>
  ) {
    this.cost = attrs.cost
    this.kwh = attrs.kwh
    this.co2eLbs = attrs.co2eLbs
    this.id = attrs.id
    this.imbalanceKwh = attrs.imbalanceKwh
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.price = attrs.price
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.priceRange = attrs.priceRange
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.taggedKwh = attrs.taggedKwh
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.order = attrs.order
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.electricityType = attrs.electricityType
    this.emissionsRates = emissionsRates
    this.carbonIntensity = attrs.co2eLbs / attrs.kwh
  }

  static empty(allocationId = "") {
    const newProduct = new Product()

    newProduct.cost = 0
    newProduct.kwh = 0
    newProduct.co2eLbs = 0
    newProduct.id = allocationId
    newProduct.imbalanceKwh = 0
    newProduct.price = null
    newProduct.priceRange = null
    newProduct.taggedKwh = 0
    newProduct.order = -Infinity
    newProduct.electricityType = null
    newProduct.emissionsRates = null

    return newProduct
  }

  static toStrokeColors(products: Product[][]): Color[] {
    const sortedProductIds = this.sortedIds(products)
    const flattenedProducts = flatten(products)
    const sortedProducts = sortedProductIds.map((productId) =>
      flattenedProducts.find((product) => product.id === productId)
    )

    return sortedProducts.map(
      (product: Product): Color => product.strokeColor()
    )
  }

  static toColors(products: Product[][]): Color[] {
    const sortedProductIds = this.sortedIds(products)
    const flattenedProducts = flatten(products)
    const sortedProducts = sortedProductIds.map((productId) =>
      flattenedProducts.find((product) => product.id === productId)
    )
    return sortedProducts.map((product: Product): Color => product.fillColor())
  }

  static sortedIds(productArrays: Product[][]): string[] {
    const flattenedProducts = flatten(productArrays)
    const productsById = groupBy(flattenedProducts, (product) => product.id)
    const productIdPairs = toPairs(productsById)
    const maxOrders: [string, number][] = productIdPairs.map(
      ([productId, products]) => {
        const displayOrders = map(products, (p) => p.displaySortOrder())
        return [productId, max(displayOrders)]
      }
    )
    const sortedMaxOrders = sortBy(maxOrders, (maxOrder) => last(maxOrder))

    return sortedMaxOrders.map((maxOrder) => maxOrder[0])
  }

  static sort(products: Product[]): Product[] {
    return sortBy(products, (product) => product.displaySortOrder())
  }

  static aggregateProductFromEnergyAllocations(
    energyAllocations: EnergyAllocation[],
    allocationId: string
  ): Product {
    const defaultProduct = this.empty(allocationId)
    return energyAllocations.reduce(
      (aggregateProduct: Product, currAllocation: EnergyAllocation) => {
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { price, priceRange } = aggregatePrice(
          aggregateProduct,
          currAllocation
        )

        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        aggregateProduct.priceRange = priceRange
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        aggregateProduct.cost = aggregateCost(aggregateProduct, currAllocation)
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        aggregateProduct.imbalanceKwh = aggregateImbalance(
          aggregateProduct,
          currAllocation
        )
        aggregateProduct.price = price
        aggregateProduct.kwh = productsUsingImbalanceKwh.includes(
          aggregateProduct.productType
        )
          ? aggregateProduct.imbalanceKwh
          : currAllocation.kwh + aggregateProduct.kwh
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        aggregateProduct.co2eLbs = aggregateCo2eLbs(
          aggregateProduct,
          currAllocation
        )
        aggregateProduct.id = currAllocation.isResourceBased
          ? `${allocationId}-resource`
          : allocationId
        aggregateProduct.order = Math.max(
          currAllocation.productOrder,
          aggregateProduct.order
        )
        aggregateProduct.taggedKwh += currAllocation.taggedKwh
        aggregateProduct.confirmName = currAllocation.confirm
          ? currAllocation.confirm.name
          : null
        aggregateProduct.electricityType =
          currAllocation.electricityType as ElectricityType
        aggregateProduct.energyProviderName = currAllocation.energyProviderName

        aggregateProduct.emissionsRates = currAllocation.emissionsRates

        aggregateProduct.carbonIntensity =
          aggregateProduct.co2eLbs / aggregateProduct.kwh

        return aggregateProduct
      },
      defaultProduct
    )
  }

  static productsFromEnergyAllocations(
    energyAllocations: EnergyAllocation[]
  ): Product[] {
    const allocationsByHours = groupBy(energyAllocations, (alloc) => alloc.hour)
    setMaxProductOrder(values(allocationsByHours))
    const allocationsById = groupBy(
      energyAllocations,
      (alloc) => alloc.allocationId
    )

    return toPairs(allocationsById).map((keyValuePair) => {
      const [allocationId, allocationsForId] = keyValuePair
      const productFromAllocations: Product =
        this.aggregateProductFromEnergyAllocations(
          allocationsForId,
          allocationId
        )
      idLongImbalance(productFromAllocations)
      return productFromAllocations
    })
  }

  confirmBased() {
    if (this.isBundledEnergyCustomer()) {
      return false
    }
    return !(
      this.shortImbalance() ||
      this.longImbalance() ||
      this.curtailment()
    )
  }

  // TODO: add check for bundled or direct access as qualfiers for these types (..later)
  curtailment() {
    return this.productType === ProductType.Curtailment
  }

  shortImbalance() {
    return this.productType === ProductType.ShortImbalance
  }

  longImbalance() {
    return this.productType === ProductType.LongImbalance
  }

  fixed() {
    return this.productType === ProductType.FixedConfirm
  }

  market() {
    return this.productType === ProductType.MarketConfirm
  }

  resourceBased() {
    return this.productType === ProductType.ResourceBasedConfirm
  }

  deliveredEnergy() {
    return this.productType === ProductType.DeliveredBundled
  }

  receivedEnergy() {
    return this.productType === ProductType.ReceivedBundled
  }

  isDirectAccessEnergyCustomer() {
    return this.electricityType === ElectricityType.DirectAccess
  }

  isBundledEnergyCustomer() {
    return this.electricityType === ElectricityType.Bundled
  }

  displaySortOrder(): number {
    if (this.curtailment()) {
      return 10000
    }
    if (this.shortImbalance() || this.longImbalance()) {
      return 10001
    }
    return this.order
  }

  fillColor(): Color {
    if (isNull(this.electricityType)) {
      return this.directAccessFillColor()
    }
    if (this.isBundledEnergyCustomer()) {
      return this.bundledFillColor()
    }
    if (this.isDirectAccessEnergyCustomer()) {
      return this.directAccessFillColor()
    }
  }

  bundledFillColor(): Color {
    if (this.receivedEnergy()) {
      return getProductColor("bundled_received")
    }

    const bundledTokens = this.allocationId.split("-")
    if (bundledTokens[1] !== "grid") {
      return getProductColor("resource") // since resource is only for solar currently
    }

    return getProductColor("bundled_delivered")
  }

  directAccessFillColor(): Color {
    if (this.resourceBased()) {
      return getProductColor("resource")
    }
    if (this.shortImbalance()) {
      return getProductColor("short_imbalance")
    }
    if (this.longImbalance()) {
      return getProductColor("long_imbalance")
    }
    if (this.curtailment()) {
      return getProductColor("curtailment")
    }
    if (this.fixed()) {
      return getProductColor("fixed")
    }
    if (this.market()) {
      return getProductColor("market")
    }

    return lightSolarOrange
  }

  strokeColor(): Color {
    if (
      this.isDirectAccessEnergyCustomer() &&
      (this.longImbalance() || this.shortImbalance())
    ) {
      return getProductColor("short_imbalance")
    }
    if (this.curtailment()) {
      return black
    }
    if (this.isBundledEnergyCustomer() && this.receivedEnergy()) {
      return lightSolarOrange
    }

    if (this.isDirectAccessEnergyCustomer()) {
      return this.directAccessFillColor()
    }
    if (this.isBundledEnergyCustomer()) {
      return this.bundledFillColor()
    }

    return transparent
  }

  private deriveProductType(): ProductType {
    if (includes(this.id, "resource")) {
      return ProductType.ResourceBasedConfirm
    }
    if (this.id === "longImbalance") {
      return ProductType.LongImbalance
    }
    if (this.id === "shortImbalance" || this.id === "imbalance") {
      return ProductType.ShortImbalance
    }
    if (this.id === "curtailment") {
      return ProductType.Curtailment
    }
    if (includes(this.id, "fixed")) {
      return ProductType.FixedConfirm
    }
    if (includes(this.id, "market")) {
      return ProductType.MarketConfirm
    }
    if (includes(this.id, "Received")) {
      return ProductType.ReceivedBundled
    }
    if (includes(this.id, "Delivered")) {
      return ProductType.DeliveredBundled
    }
  }
}
