import { arc } from "d3"

export const generateSemiAnnularPath = (
  startAngle: number,
  endAngle: number,
  outerRadius: number,
  innerRadius: number
) =>
  arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(startAngle)
    .endAngle(endAngle)
