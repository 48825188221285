import React from "react"

import { useTheme } from "@mui/material"

import type { StackedAreaGraphCustomLabelProps } from "./monthly-stacked-area-graph.types"

const LABEL_LINE_Y_OFFSET = 16
/**
 *
 * @param param0
 * @returns a solid line to be used as a custom label for the monthly stacked area graph x axis
 */
export const CustomLabel: React.FC<StackedAreaGraphCustomLabelProps> = ({
  viewBox,
}) => {
  const theme = useTheme()
  return (
    <g>
      <rect
        fill={theme.palette.grey[100]}
        height="2"
        width={viewBox?.width ?? 0}
        x={viewBox?.x ?? 0}
        y={(viewBox?.y ?? 0) + LABEL_LINE_Y_OFFSET}
      />
    </g>
  )
}
