import React from "react"
import { useNavigate } from "react-router-dom"

import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded"
import {
  Button,
  Divider,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material"

import useEntityDelete from "./useEntityDelete/useEntityDelete"

interface OrgViewDetailEntityDeleteButtonProps {
  canEntityBeDeleted: boolean
  entityId: string
  entityName: string
  isButtonDisabled: boolean
  navLink: string
  onDeleteDialogClose: () => void
  onDeleteDialogOpen: (
    title: string,
    content: React.JSX.Element,
    actions: React.JSX.Element
  ) => void
}

const OrgViewDetailEntityDeleteButton = ({
  entityName,
  entityId,
  navLink,
  canEntityBeDeleted,
  isButtonDisabled,
  onDeleteDialogClose,
  onDeleteDialogOpen,
}: OrgViewDetailEntityDeleteButtonProps): React.JSX.Element => {
  const lowerCasedEntityName = entityName.toLowerCase()

  const navigate = useNavigate()
  const onSuccess = () => {
    navigate(navLink, { replace: true })
  }

  const onError = () => {
    onDeleteDialogOpen(
      "Could not delete",
      <Typography>
        Additional records are dependent on this {entityName.toLowerCase()}.
      </Typography>,
      <Button onClick={onDeleteDialogClose}>Cancel</Button>
    )
  }

  const { deleteOne } = useEntityDelete({
    entityName,
    onSuccess,
    onError,
  })

  const onDelete = () => {
    onDeleteDialogClose()
    if (!canEntityBeDeleted) {
      onDeleteDialogOpen(
        "Could not delete",
        <Typography>
          Additional records are dependent on this {lowerCasedEntityName}.
        </Typography>,
        <Button onClick={onDeleteDialogClose}>Cancel</Button>
      )
    } else {
      deleteOne(entityId)
    }
  }

  const dialogTitle = `Delete ${entityName}`
  const dialogContent = (
    <Typography>
      Are you sure you want to delete this {lowerCasedEntityName}? This action
      cannot be undone.
    </Typography>
  )
  const dialogActions = (
    <>
      <Button color="neutral" onClick={onDeleteDialogClose}>
        Cancel
      </Button>
      <Button color="error" onClick={onDelete} variant="outlined">
        Yes, Delete
      </Button>
    </>
  )

  return (
    <>
      <MenuItem
        aria-label={`Delete ${entityName}`}
        disabled={isButtonDisabled}
        onClick={() => {
          onDeleteDialogOpen(dialogTitle, dialogContent, dialogActions)
        }}
        role="menuitem"
        sx={{
          py: (theme) => theme.typography.pxToRem(4),
          px: (theme) => theme.typography.pxToRem(8),
        }}
      >
        <DeleteRoundedIcon
          fontSize="small"
          sx={{
            color: (theme) => theme.palette.error.main,
            mr: (theme) => theme.typography.pxToRem(8),
          }}
        />
        <ListItemText sx={{ color: (theme) => theme.palette.error.dark }}>
          Delete {entityName}
        </ListItemText>
      </MenuItem>
      <Divider style={{ margin: 0 }} />
    </>
  )
}

export default OrgViewDetailEntityDeleteButton
