import type OrganizationalUnit from "../../../../../models/organizationalUnit"
import type {
  HierarchyDataGridRow,
  OUTreeNode,
} from "../../../hooks/use-hierarchy-tree-data/types"

const checkChildrenForEligibility = (children: OUTreeNode[]) => {
  const ineligibleOrgUnitIDs: string[] = []

  children.forEach((child) => {
    if (child.type === "ou") {
      ineligibleOrgUnitIDs.push(child.id.toString())
      checkChildrenForEligibility(child.children)
    }
    ineligibleOrgUnitIDs.push(child.parent_id?.toString())
  })

  return ineligibleOrgUnitIDs
}

/**
 * Utility function for filtering out the selected OUs and any of their descendants from the possible
 * list of "destination" OUs.
 * @param orgUnits
 * @param selectedRows
 * @returns A mapped array of OU names for our `Autocomplete` component
 */
export const buildAutocompleteOptions = (
  orgUnits: OrganizationalUnit[],
  selectedRows: HierarchyDataGridRow[]
): string[] => {
  const ineligibleOrgUnitIDs: string[] = []

  selectedRows.forEach((row) => {
    if (row.type === "ou") {
      ineligibleOrgUnitIDs.push(row.id.toString())
      ineligibleOrgUnitIDs.push(...checkChildrenForEligibility(row.children))
    }
    ineligibleOrgUnitIDs.push(row.parentId?.toString())
  })

  const options = orgUnits.filter((ou) => !ineligibleOrgUnitIDs.includes(ou.id))
  return options.map((ou) => ou.name)
}
