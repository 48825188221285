import type PhysicalDetail from "../../../../models/physcialDetails"
import type PhysicalDetailType from "../../../../models/physical-detail-types"
import { convertModelValuesToSchemaValues } from "./convert-model-values-to-schema-values"

export const buildPhysicalDetailsModelValues = (
  physicalDetailTypes: PhysicalDetailType[],
  physicalDetails: Pick<PhysicalDetail, "details" | "physicalDetailTypeId">[]
): Pick<PhysicalDetail, "details" | "physicalDetailTypeId">[] => {
  const processedPhysicalDetails: Pick<
    PhysicalDetail,
    "details" | "physicalDetailTypeId"
  >[] = []
  for (const detailType of physicalDetailTypes) {
    const detail: Pick<PhysicalDetail, "details" | "physicalDetailTypeId"> = {
      physicalDetailTypeId: detailType.id,
      details: {},
    }
    // find site physical detail with same id
    const sitePhysicalDetail = physicalDetails.find(
      (physicalDetail) =>
        physicalDetail.physicalDetailTypeId?.toString() ===
        detailType.id?.toString()
    )
    if (
      sitePhysicalDetail &&
      detailType.schema.type === "object" &&
      detailType.schema.properties
    ) {
      const details = convertModelValuesToSchemaValues(
        detailType.schema.properties,
        sitePhysicalDetail.details as Pick<PhysicalDetail, "details">
      )
      detail.details = details
    }
    processedPhysicalDetails.push(detail)
  }
  return processedPhysicalDetails
}
