import { Attr, HasMany, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"
import Site from "./site"

@Model()
export default class Node extends ApplicationRecord {
  public static jsonapiType = "nodes"

  @HasMany("sites") public site: Site

  @Attr() public readonly name: string
}
