import type { FC } from "react"
import React from "react"

import CloseIconRounded from "@mui/icons-material/CloseRounded"
import type { DialogTitleProps as MuiDialogTitleProps } from "@mui/material"
import { IconButton, DialogTitle as MuiDialogTitle } from "@mui/material"

interface DialogTitleProps extends MuiDialogTitleProps {
  /**
   * provide an id for accessibility, see https://mui.com/material-ui/react-modal/#accessibility
   */
  id: string
  onClose: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const DialogTitle: FC<DialogTitleProps> = ({
  children,
  onClose,
  id,
}) => (
  <MuiDialogTitle id={id}>
    {children}
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        color: "grey.800",
        ml: 1,
      }}
    >
      <CloseIconRounded />
    </IconButton>
  </MuiDialogTitle>
)
