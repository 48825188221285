import type { Moment } from "moment"

import type { IRange } from "../../../models/range"
import type { Scope } from "../../../models/scope"
import type { ITableRow } from "../../../models/table"
import { APPNAME } from "../../../utils/constants"
import type { ResourceSummaryRecord } from "../models/resourceSummary"
import { FLEET_SITE_NAME, ORG_LEVEL_SITE_ID } from "../models/resourceSummary"

/**
 * Generates a file name for a report
 *
 * @param reportName - The main title of the report
 * @param dateRange - The start and end dates of the report
 * @params isDataUnderReview - Whether the data is under review or not
 * @returns The file name
 * @example
 * const getReportFileName(
 *   "Gri",
 *   {
 *     start: moment("2022-01-01"),
 *     end: moment("2022-02-28")
 *   },
 *   true)
 */
export const getReportFileName = (
  reportName: string,
  dateRange: IRange<Moment> | null | undefined,
  isDataReady?: boolean
): string => {
  if (!dateRange?.start?.isValid() || !dateRange?.end?.isValid()) {
    return ""
  }

  return `${
    isDataReady ? "" : "(preview) "
  }${APPNAME} ${reportName} ${dateRange.start?.format(
    "YYYY-MM-DD"
  )} through ${dateRange.end?.format("YYYY-MM-DD")}`
}

/**
 * Adds an id to the provided row data so it implements the ITableRow interface
 *
 * @param data - The row data
 * @returns The row data implementing the ITableRow interface
 * @example
 * getReportRows(gresbEnergyData)
 */
export const getReportRows = <TData>(data: TData[]): (TData & ITableRow)[] =>
  data.map((datum, index) => ({
    id: index,
    ...datum,
  }))

export const getReportMultiSelectStringFilterByScope = (scope: Scope) =>
  `scope${scope}`

/**
 * Returns whether a site is considered an org level site or not
 *
 * @param site - The site
 * @returns Whether the site is org level or not
 * @example
 *   isOrgLevelSite(site)
 */
export const isOrgLevelSite = (siteId: number | string | null): boolean =>
  siteId === null || siteId === ORG_LEVEL_SITE_ID

/**
 * Returns whether a site is considered unique or not
 *
 * @param site - The site
 * @returns Whether the site is unique or not
 * @example
 *   isUniqueSite(site)
 */
export const isUniqueSite = (site: ResourceSummaryRecord): boolean =>
  site.siteName === FLEET_SITE_NAME

export default {
  getReportFileName,
  getReportRows,
  isOrgLevelSite,
  isUniqueSite,
}
