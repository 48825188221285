// @ts-strict-ignore
export type MeterDataType =
  | "siteHasSubMeter"
  | "siteIsAmi"
  | "siteIsAmr"
  | "siteIsProbe"

export type MeterDataContent = [MeterDataType, boolean]
interface SiteDetails {
  containsAllAreAmi: boolean
  containsAllAreAmr: boolean
  containsAllAreProbe: boolean
  containsAllSubMeters: boolean
  containsAmiAndAmr: boolean
  containsAnyAmi: boolean
  containsAnyAmr: boolean
  containsAnyProbe: boolean
  containsAnySubMeters: boolean
}

interface MeterDataDescriptionType {
  siteHasSubMeter: string
  siteIsAmi: string
  siteIsAmr: string
  siteIsProbe: string
}

export const MeterDataTypeDescription: MeterDataDescriptionType = {
  siteHasSubMeter:
    "Sub-Meter Data: This site gathers improved data granularity through a sub-meter. This data is collected daily and displayed in hourly increments.",
  siteIsAmi:
    "AMI Meter: This meter transmits data to your utility on a daily basis. Data is processed once received by your utility.",
  siteIsAmr:
    "AMR Meter: This legacy utility meter transmits data to your utility on a monthly basis. Data is processed once received by your utility.",
  siteIsProbe:
    "Probe Meter: This meter type collects data hourly but transmits to your utility on a monthly basis.  Data is processed once received by your utility.",
}

export const enum LabelText {
  Daily = "Daily Data",
  Improved = "Improved Data",
  Mixed = "Mixed Interval Data",
  Monthly = "Monthly Data",
}

const initialSiteDetailDefaults: SiteDetails = {
  containsAllSubMeters: true,
  containsAnySubMeters: undefined,
  containsAllAreAmi: undefined,
  containsAllAreAmr: undefined,
  containsAllAreProbe: undefined,
  containsAnyAmi: undefined,
  containsAnyAmr: undefined,
  containsAnyProbe: undefined,
  containsAmiAndAmr: undefined,
}

const containsAnyOfType = ({
  previousValueOfType,
  typePresent,
}: {
  previousValueOfType: boolean | undefined
  typePresent: boolean
}): boolean => {
  if (previousValueOfType) {
    return previousValueOfType
  }
  return typePresent
}

const containsAllOfType = ({
  previousValueOfType,
  typePresent,
}: {
  previousValueOfType: boolean | undefined
  typePresent: boolean
}): boolean => {
  if (typePresent && previousValueOfType !== undefined) {
    return previousValueOfType
  }
  return typePresent
}

const currentIsType = ({
  currentValue,
  type,
}: {
  currentValue: MeterDataContent
  type: MeterDataType
}): boolean => currentValue[0] === type

/**
 * @description Iterates through a collection to determine all or any presence for required data points
 * @param previousValue a value that starts with every key set as undefined except containsAllSubMeters is true until proven otherwise
 * @param currentValue an array of string and boolean where the string is used for determining meter type display
 * @returns a mutated version of the initial value
 * @usage [["siteHasSubMeter", undefined]].reduce(dataContents)
 * @example
 * {
    containsAllSubMeters: true;
    containsAnySubMeters: true;
    containsAllAreAmi: false;
    containsAllAreAmr: false;
    containsAllAreProbe: false;
    containsAnyAmi: false;
    containsAnyAmr: false;
    containsAnyProbe: false;
  }
 */
const reduceToSiteDetails = (
  previousValue: SiteDetails,
  currentValue: MeterDataContent
): SiteDetails => {
  const { siteHasSubMeter, siteIsAmi, siteIsAmr, siteIsProbe } =
    Object.fromEntries(
      ["siteHasSubMeter", "siteIsAmi", "siteIsAmr", "siteIsProbe"].map(
        (type: MeterDataType) => [
          type,
          currentIsType({
            currentValue,
            type,
          }),
        ]
      )
    )

  const { allAreAmr, allAreAmi, allAreProbe } = {
    allAreAmr: containsAllOfType({
      previousValueOfType: previousValue.containsAllAreAmr,
      typePresent: siteIsAmr,
    }),
    allAreAmi: containsAllOfType({
      previousValueOfType: previousValue.containsAllAreAmi,
      typePresent: siteIsAmi,
    }),
    allAreProbe: containsAllOfType({
      previousValueOfType: previousValue.containsAllAreProbe,
      typePresent: siteIsProbe,
    }),
  }

  const { anyProbe, anyAmi, anyAmr } = {
    anyProbe: containsAnyOfType({
      previousValueOfType: previousValue.containsAnyProbe,
      typePresent: siteIsProbe,
    }),
    anyAmi: containsAnyOfType({
      previousValueOfType: previousValue.containsAnyAmi,
      typePresent: siteIsAmi,
    }),
    anyAmr: containsAnyOfType({
      previousValueOfType: previousValue.containsAnyAmr,
      typePresent: siteIsAmr,
    }),
  }

  const containsAllSubMeters =
    previousValue.containsAllSubMeters && siteHasSubMeter
  const containsAnySubMeters = previousValue.containsAnySubMeters
    ? previousValue.containsAnySubMeters
    : siteHasSubMeter

  return {
    containsAllSubMeters,
    containsAnySubMeters,
    containsAllAreAmi: allAreAmi,
    containsAllAreAmr: allAreAmr,
    containsAllAreProbe: allAreProbe,
    containsAnyAmi: anyAmi,
    containsAnyAmr: anyAmr,
    containsAnyProbe: anyProbe,
    containsAmiAndAmr: anyAmi && anyAmr,
  }
}

/**
 * @description Iterates through a collection to determine the correct ButtonText
 * @param matrixOfPresentSiteDetails an array of meter types with unused booleans
 * @returns the selected text based on available meters
 * @usage getPopoverButtonText([["siteHasSubMeter", undefined]])
 * @example "Improved Data"
 */
export const getPopoverButtonText = (
  matrixOfPresentSiteDetails: MeterDataContent[]
): LabelText => {
  const siteDetails =
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    matrixOfPresentSiteDetails.reduce(
      reduceToSiteDetails,
      initialSiteDetailDefaults
    ) ?? ({} as SiteDetails)

  if (siteDetails.containsAllAreAmi) {
    return LabelText.Daily
  }
  if (siteDetails.containsAllSubMeters) {
    return LabelText.Improved
  }
  if (
    (siteDetails.containsAnySubMeters &&
      (siteDetails.containsAnyProbe || siteDetails.containsAnyAmi)) ||
    (siteDetails.containsAnyProbe && siteDetails.containsAnyAmi) ||
    siteDetails.containsAmiAndAmr
  ) {
    return LabelText.Mixed
  }
  if (
    siteDetails.containsAllAreAmr ||
    siteDetails.containsAllAreProbe ||
    siteDetails.containsAnyAmr ||
    siteDetails.containsAnyProbe
  ) {
    return LabelText.Monthly
  }
}
