import type { ReactNode } from "react"
import React from "react"

import { Box, CircularProgress } from "@mui/material"

interface PageLoadingProps {
  children?: ReactNode
  isLoading: boolean
  showContent?: boolean
}

/**
 *  Shows page loading spinner in the middle of the page
 *  by default it will show a spinner when isLoading is true
 *  and show the children contents when isLoading is false
 *
 *  you can optionally show the child contents by setting showContent
 *  to true while isLoading is also true, for example after the initial
 *  data fetch and you are refetching some new data
 *
 * ```tsx
 * import { PageLoading } from 'page-loading'
 *
 * <PageLoading isLoading={true}><SomeContentComponent /></PageLoading>
 */
export const PageLoading: React.FC<PageLoadingProps> = ({
  isLoading,
  showContent,
  children,
}) => {
  // default behavior
  if (isLoading && !showContent) {
    return (
      <Box
        alignItems="center"
        bgcolor="rgba(255,255,255,0.30)"
        bottom="0"
        display="flex"
        height="100vh"
        justifyContent="center"
        left="0"
        marginLeft="88px"
        position="fixed"
        right="0"
        top="0"
        width="calc(100vw - 88px)"
        zIndex="fab"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!isLoading && !showContent) {
    return <>{children}</>
  }

  if (showContent) {
    return (
      <>
        {isLoading ? (
          <Box
            alignItems="center"
            bgcolor="rgba(255,255,255,0.30)"
            bottom="0"
            display="flex"
            height="100vh"
            justifyContent="center"
            left="0"
            marginLeft="88px"
            position="fixed"
            right="0"
            top="0"
            width="calc(100vw - 88px)"
            zIndex="fab"
          >
            <CircularProgress />
          </Box>
        ) : null}
        {children}
      </>
    )
  }

  return null
}
