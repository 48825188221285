import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useBaCodes } from "@/services/baCode"
import { useCurrencies } from "@/services/currency"
import { useLocations } from "@/services/location"
import { useUpdateSiteWithAddress } from "@/services/site/useUpdateSite"
import { State } from "country-state-city"

import { Divider, Grid, Typography } from "@mui/material"

import { useOptions } from "../../../../../../components/form"
import { HStack } from "../../../../../../nzds"
import { useForm } from "../../../../../../nzds/forms/use-form/use-form"
import { useCountryOptions } from "../../../../../../nzds/utils/useCountryOptions/useCountryOptions"
import type { Site as SiteModel } from "../../../../../../services"
import { useSite } from "../../../../../../services"
import { TamForm, TamResetButton, TamSubmitButton } from "../../../tamForm"
import {
  TamFormSelectField,
  TamFormTextField,
} from "../../../tamForm/formFields"
import type { Site } from "../../../tamForm/schemas/site"
import { isAddressEmpty, schema } from "../../../tamForm/schemas/site"
import OrgViewDetailFooter from "../orgViewDetail/orgViewDetailFooter/orgViewDetailFooter"

interface OrgViewSiteDetailProps {
  linkSection: React.JSX.Element
  siteId: SiteModel["id"]
}

const OrgViewSiteDetail = ({ siteId, linkSection }: OrgViewSiteDetailProps) => {
  const { site, isSiteLoading } = useSite(siteId)
  const { updateOneSite, isUpdateOneSiteLoading, isUpdateOneSiteSuccess } =
    useUpdateSiteWithAddress()

  const { currencies, isCurrenciesLoading } = useCurrencies()
  const currencyOptions = useOptions(currencies, ({ name, alphaCode, id }) => ({
    label: `${name} (${alphaCode})`,
    value: id,
  }))

  const countryOptions = useCountryOptions()

  const [stateOptions, setStateOptions] = useState([])

  const { locations, isLocationsLoading } = useLocations()
  const locationOptions = useOptions(locations, ({ name, id }) => ({
    label: name,
    value: id,
  }))

  const { baCodes, isBaCodesLoading } = useBaCodes()
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const baCodeOptions = useOptions(
    baCodes,
    ({ name, id }) => ({
      label: name,
      value: id,
    }),
    null
  )

  const electricityTypeDisplayName: string = useMemo(() => {
    if (site?.electricityType === "bundled") {
      return "Bundled"
    }
    if (site?.electricityType === "direct_access") {
      return "Direct Access"
    }
    return ""
  }, [site?.electricityType])

  const handleFormSubmit = (newAttributes) => {
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { address, ...rest } = newAttributes
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const currency = currencies.find(({ id }) => id === rest.currencyId)
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const attributes = {
      ...rest,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currency,
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
      address: isAddressEmpty(address) ? null : address,
    }
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    updateOneSite(siteId, attributes)
  }

  const form = useForm({
    schema,
    values: site as unknown as Site,
    mode: "all",
    reValidateMode: "onChange",
  })

  const addressDirty = form.getFieldState("address").isDirty
  const watchCountry = form.watch("address.country")
  const countryIsUSOrCA = ["US", "CA"].includes(watchCountry)

  const filterStatesAndProvinces = useCallback(
    (country: string) => {
      const states = State.getStatesOfCountry(country)?.map(
        ({ name, isoCode }) => ({
          label: name,
          value: isoCode,
        })
      )
      // Filters out minor territories which conveniently have an ISO code of 3 or more characters.
      if (countryIsUSOrCA) {
        return states.filter(({ value }) => value.length === 2)
      }
      return states
    },
    [countryIsUSOrCA]
  )

  // This effect updates the state select field options when the country changes.
  // If there is a selected state that is not in the new list of states, it is
  // reset to null.
  useEffect(() => {
    if (!watchCountry) {
      return
    }
    const selectedState = form.getValues("address.state")
    const states = filterStatesAndProvinces(watchCountry)
    setStateOptions(states)

    if (
      selectedState &&
      !states?.find(({ value }) => value === selectedState)
    ) {
      form.resetField("address.state", { defaultValue: "" })
    }
  }, [watchCountry, form, filterStatesAndProvinces])

  useEffect(() => {
    if (isUpdateOneSiteSuccess) {
      form.reset({}, { keepValues: true })
    }
  }, [form, isUpdateOneSiteSuccess])

  if (
    isSiteLoading ||
    isCurrenciesLoading ||
    isLocationsLoading ||
    isBaCodesLoading
  ) {
    return null
  }

  return (
    <TamForm form={form} name="site" onSubmit={handleFormSubmit}>
      <HStack
        borderBottom={1}
        borderTop={1}
        flexGrow={1}
        gap={0.5}
        sx={{
          overflowY: "auto",
          py: 1,
          pr: 2,
          borderColor: "grey.100",
        }}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<Site>
                dataE2E="site-name-input"
                label="Site Name"
                name="name"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<Site>
                dataE2E="site-id-input"
                disabled
                label="ID"
                name="id"
                readOnly
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormSelectField<Site>
                dataE2E="site-location-input"
                label="Location"
                name="locationId"
                options={locationOptions}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<Site>
                dataE2E="site-alias-input"
                label="Site Alias"
                name="siteAlias"
              />
            </Grid>
            <Grid item xs={6}>
              <TamFormTextField<Site>
                dataE2E="ba-code-input"
                disabled
                label="BA Code"
                name="baCode"
                readOnly
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<Site>
                capitalize
                dataE2E="electricity-type-input"
                disabled
                label="Electricity"
                name="electricityType"
                readOnly
                value={electricityTypeDisplayName}
              />
            </Grid>
            <Grid item xs={6}>
              <TamFormTextField<Site>
                capitalize
                dataE2E="granularity-input"
                disabled
                label="Granularity"
                name="granularity"
                readOnly
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <TamFormSelectField<Site>
                dataE2E="site-currency-input"
                label="Currency"
                name="currencyId"
                options={currencyOptions}
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<Site>
                dataE2E="tariff-major-input"
                label="Tariff Major"
                name="tariffMajor"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<Site>
                dataE2E="year-built-input"
                label="Year Built"
                name="yearBuilt"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<Site>
                dataE2E="square-feet-input"
                label="Square Feet"
                name="sqFt"
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<Site>
                dataE2E="notes-input"
                label="Notes"
                name="notes"
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider orientation="horizontal" sx={{ borderWidth: "1px" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography>Address (Optional)</Typography>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={4}>
              <TamFormSelectField<Site>
                dataE2E="site-country-input"
                label="Country"
                name="address.country"
                options={countryOptions}
                required={addressDirty}
              />
            </Grid>
            <Grid item xs={4}>
              <TamFormTextField<Site>
                dataE2E="address-line1-input"
                label="Site Address Line 1"
                name="address.address_line_1"
                required={addressDirty}
              />
            </Grid>
            <Grid item xs={4}>
              <TamFormTextField<Site>
                dataE2E="address-line2-input"
                label="Site Address Line 2"
                name="address.address_line_2"
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={4}>
              <TamFormTextField<Site>
                dataE2E="site-city-input"
                label="City"
                name="address.city"
                required={addressDirty}
              />
            </Grid>
            <Grid item xs={4}>
              <TamFormSelectField<Site>
                dataE2E="site-state-input"
                label="State"
                name="address.state"
                options={stateOptions}
                required={addressDirty && countryIsUSOrCA}
              />
            </Grid>
            <Grid item xs={4}>
              <TamFormTextField<Site>
                dataE2E="site-postal-input"
                label="Postal Code"
                name="address.postal_code"
              />
            </Grid>
          </Grid>
        </Grid>
      </HStack>
      <OrgViewDetailFooter linkSection={linkSection}>
        <TamResetButton dataE2E="site-reset-button" />
        <TamSubmitButton
          dataE2E="site-submit-button"
          submitting={isUpdateOneSiteLoading}
        />
      </OrgViewDetailFooter>
    </TamForm>
  )
}

export default OrgViewSiteDetail
