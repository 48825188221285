import type DateFilter from "../../../models/filter/dateFilter"
import type { ITableColumn, ITableRow } from "../../../models/table"
import type { Report } from "./report"

export interface GriEntityDto {
  disclosure: string
}

export interface Gri302Dto extends GriEntityDto {
  electricityConsumptionGj: string
  heatingConsumptionGj: string
  nonRenewableFuelConsumptionGj: string
  renewableFuelConsumptionGj: string
  totalEnergyConsumptionGj: string
}

export interface Gri303Dto extends GriEntityDto {
  waterConsumptionMl: string
}

export interface Gri305Dto extends GriEntityDto {
  grossScope1MtCo2E: string
  grossScope2AdvancedGridStudyMtCo2E: string
  grossScope2LocationBasedMtCo2E: string
  grossScope2MarketBasedMtCo2E: string
  grossScope3MtCo2E: string
  scope3Category10MtCo2E: string
  scope3Category11MtCo2E: string
  scope3Category12MtCo2E: string
  scope3Category13MtCo2E: string
  scope3Category14MtCo2E: string
  scope3Category15MtCo2E: string
  scope3Category1MtCo2E: string
  scope3Category2MtCo2E: string
  scope3Category3MtCo2E: string
  scope3Category4MtCo2E: string
  scope3Category5MtCo2E: string
  scope3Category6MtCo2E: string
  scope3Category7MtCo2E: string
  scope3Category8MtCo2E: string
  scope3Category9MtCo2E: string
}

export interface Gri306Dto extends GriEntityDto {
  wasteWeightMt: string
}

export interface GriDataDto {
  gri302Energy: Gri302Dto[]
  gri303Water: Gri303Dto[]
  gri305Emissions: Gri305Dto[]
  gri306Waste: Gri306Dto[]
}

export interface GriEntity extends GriEntityDto {
  source: string
}

export interface Gri302 extends GriEntity {
  electricityConsumptionGj: number
  heatingConsumptionGj: number
  nonRenewableFuelConsumptionGj: number
  renewableFuelConsumptionGj: number
  totalEnergyConsumptionGj: number
}

export interface Gri303 extends GriEntity {
  waterConsumptionMl: number
}

export interface Gri305 extends GriEntity {
  gases: string
  grossScope1MtCo2E: number
  grossScope2AdvancedGridStudyMtCo2E: number
  grossScope2LocationBasedMtCo2E: number
  grossScope2MarketBasedMtCo2E: number
  grossScope3MtCo2E: number
  scope3Category10MtCo2E: number
  scope3Category11MtCo2E: number
  scope3Category12MtCo2E: number
  scope3Category13MtCo2E: number
  scope3Category14MtCo2E: number
  scope3Category15MtCo2E: number
  scope3Category1MtCo2E: number
  scope3Category2MtCo2E: number
  scope3Category3MtCo2E: number
  scope3Category4MtCo2E: number
  scope3Category5MtCo2E: number
  scope3Category6MtCo2E: number
  scope3Category7MtCo2E: number
  scope3Category8MtCo2E: number
  scope3Category9MtCo2E: number
}

export interface Gri306 extends GriEntity {
  wasteWeightMt: number
}

export interface GriData {
  gri302: Gri302[]
  gri303: Gri303[]
  gri305: Gri305[]
  gri306: Gri306[]
}

// Report
export enum GriReportName {
  Gri302 = "GRI 302",
  Gri303 = "GRI 303",
  Gri305 = "GRI 305",
  Gri306 = "GRI 306",
}

export type Gri302ReportTableRow = Gri302 & ITableRow

export type Gri303ReportTableRow = Gri303 & ITableRow

export type Gri305ReportTableRow = Gri305 & ITableRow

export type Gri306ReportTableRow = Gri306 & ITableRow

export interface GriReport {
  gri302: Report<Gri302ReportTableRow>
  gri303: Report<Gri303ReportTableRow>
  gri305: Report<Gri305ReportTableRow>
  gri306: Report<Gri306ReportTableRow>
}

// Columns
export enum Gri302ReportColumnName {
  Disclosure = "Disclosure",
  Electricity = "Electricity consumption",
  Energy = "Total energy consumption within the organization, in gigajoules or multiples",
  Heating = "Heating consumption",
  NonRenewable = "Total fuel consumption within the organization from non-renewable sources (gigajoules)",
  Renewable = "Total fuel consumption within the organization from renewable sources (gigajoules)",
  Source = "GRI STANDARD/ OTHER SOURCE",
}

export enum Gri303ReportColumnName {
  Disclosure = "Disclosure",
  Source = "GRI STANDARD/ OTHER SOURCE",
  Water = "Total water consumption from all areas in megaliters",
}

export enum Gri305ReportColumnName {
  Disclosure = "Disclosure",
  Gases = "Gases included in the calculation; whether CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, or all",
  GrossScope1 = "Gross Scope 1",
  GrossScope2AdvancedGridStudy = "Gross Scope 2: Advanced Grid Study",
  GrossScope2Location = "Gross Scope 2: Location Based",
  GrossScope2Market = "Gross Scope 2: Market Based",
  GrossScope3 = "Gross Scope 3",
  Scope3Category1 = "Scope 3: Category 1",
  Scope3Category10 = "Scope 3: Category 10",
  Scope3Category11 = "Scope 3: Category 11",
  Scope3Category12 = "Scope 3: Category 12",
  Scope3Category13 = "Scope 3: Category 13",
  Scope3Category14 = "Scope 3: Category 14",
  Scope3Category15 = "Scope 3: Category 15",
  Scope3Category2 = "Scope 3: Category 2",
  Scope3Category3 = "Scope 3: Category 3",
  Scope3Category4 = "Scope 3: Category 4",
  Scope3Category5 = "Scope 3: Category 5",
  Scope3Category6 = "Scope 3: Category 6",
  Scope3Category7 = "Scope 3: Category 7",
  Scope3Category8 = "Scope 3: Category 8",
  Scope3Category9 = "Scope 3: Category 9",
  Source = "GRI STANDARD/ OTHER SOURCE",
}

export enum Gri306ReportColumnName {
  Disclosure = "Disclosure",
  Source = "GRI STANDARD/ OTHER SOURCE",
  Waste = "Total weight of waste generated in metric tons, and a breakdown of this total by composition of the waste",
}

export enum GriEntitySourceName {
  Gri302 = "GRI 302: Energy 2016",
  Gri303 = "GRI 303: Water and Effluents 2018",
  Gri305 = "GRI 305: Emissions 2016",
  Gri306 = "GRI 306: Waste 2020",
}

export const gri302ReportColumns: ITableColumn<Gri302ReportTableRow>[] = [
  {
    id: 0,
    order: 0,
    title: Gri302ReportColumnName.Source,
    key: "source",
  },
  {
    id: 1,
    order: 1,
    title: Gri302ReportColumnName.Disclosure,
    key: "disclosure",
  },
  {
    id: 2,
    order: 2,
    title: Gri302ReportColumnName.NonRenewable,
    key: "nonRenewableFuelConsumptionGj",
  },
  {
    id: 3,
    order: 3,
    title: Gri302ReportColumnName.Renewable,
    key: "renewableFuelConsumptionGj",
  },
  {
    id: 4,
    order: 4,
    title: Gri302ReportColumnName.Electricity,
    key: "electricityConsumptionGj",
  },
  {
    id: 5,
    order: 5,
    title: Gri302ReportColumnName.Heating,
    key: "heatingConsumptionGj",
  },
  {
    id: 6,
    order: 6,
    title: Gri302ReportColumnName.Energy,
    key: "totalEnergyConsumptionGj",
  },
]

export const gri303ReportColumns: ITableColumn<Gri303ReportTableRow>[] = [
  {
    id: 0,
    order: 0,
    title: Gri303ReportColumnName.Source,
    key: "source",
  },
  {
    id: 1,
    order: 1,
    title: Gri303ReportColumnName.Disclosure,
    key: "disclosure",
  },
  {
    id: 2,
    order: 2,
    title: Gri303ReportColumnName.Water,
    key: "waterConsumptionMl",
  },
]

export const gri305ReportColumns: ITableColumn<Gri305ReportTableRow>[] = [
  {
    id: 0,
    order: 0,
    title: Gri305ReportColumnName.Source,
    key: "source",
  },
  {
    id: 1,
    order: 1,
    title: Gri305ReportColumnName.Disclosure,
    key: "disclosure",
  },
  {
    id: 2,
    order: 2,
    title: Gri305ReportColumnName.Gases,
    key: "gases",
  },
  {
    id: 3,
    order: 3,
    title: Gri305ReportColumnName.GrossScope1,
    key: "grossScope1MtCo2E",
  },
  {
    id: 4,
    order: 4,
    title: Gri305ReportColumnName.GrossScope2Location,
    key: "grossScope2LocationBasedMtCo2E",
  },
  {
    id: 5,
    order: 5,
    title: Gri305ReportColumnName.GrossScope2Market,
    key: "grossScope2MarketBasedMtCo2E",
  },
  {
    id: 6,
    order: 6,
    title: Gri305ReportColumnName.GrossScope2AdvancedGridStudy,
    key: "grossScope2AdvancedGridStudyMtCo2E",
  },
  {
    id: 7,
    order: 7,
    title: Gri305ReportColumnName.GrossScope3,
    key: "grossScope3MtCo2E",
  },
  {
    id: 8,
    order: 8,
    title: Gri305ReportColumnName.Scope3Category1,
    key: "scope3Category1MtCo2E",
  },
  {
    id: 9,
    order: 9,
    title: Gri305ReportColumnName.Scope3Category2,
    key: "scope3Category2MtCo2E",
  },
  {
    id: 10,
    order: 10,
    title: Gri305ReportColumnName.Scope3Category3,
    key: "scope3Category3MtCo2E",
  },
  {
    id: 11,
    order: 11,
    title: Gri305ReportColumnName.Scope3Category4,
    key: "scope3Category4MtCo2E",
  },
  {
    id: 12,
    order: 12,
    title: Gri305ReportColumnName.Scope3Category5,
    key: "scope3Category5MtCo2E",
  },
  {
    id: 13,
    order: 13,
    title: Gri305ReportColumnName.Scope3Category6,
    key: "scope3Category6MtCo2E",
  },
  {
    id: 14,
    order: 14,
    title: Gri305ReportColumnName.Scope3Category7,
    key: "scope3Category7MtCo2E",
  },
  {
    id: 15,
    order: 15,
    title: Gri305ReportColumnName.Scope3Category8,
    key: "scope3Category8MtCo2E",
  },
  {
    id: 16,
    order: 16,
    title: Gri305ReportColumnName.Scope3Category9,
    key: "scope3Category9MtCo2E",
  },
  {
    id: 17,
    order: 17,
    title: Gri305ReportColumnName.Scope3Category10,
    key: "scope3Category10MtCo2E",
  },
  {
    id: 18,
    order: 18,
    title: Gri305ReportColumnName.Scope3Category11,
    key: "scope3Category11MtCo2E",
  },
  {
    id: 19,
    order: 19,
    title: Gri305ReportColumnName.Scope3Category12,
    key: "scope3Category12MtCo2E",
  },
  {
    id: 20,
    order: 20,
    title: Gri305ReportColumnName.Scope3Category13,
    key: "scope3Category13MtCo2E",
  },
  {
    id: 21,
    order: 21,
    title: Gri305ReportColumnName.Scope3Category14,
    key: "scope3Category14MtCo2E",
  },
  {
    id: 22,
    order: 22,
    title: Gri305ReportColumnName.Scope3Category15,
    key: "scope3Category15MtCo2E",
  },
]

export const gri306ReportColumns: ITableColumn<Gri306ReportTableRow>[] = [
  {
    id: 0,
    order: 0,
    title: Gri306ReportColumnName.Source,
    key: "source",
  },
  {
    id: 1,
    order: 1,
    title: Gri306ReportColumnName.Disclosure,
    key: "disclosure",
  },
  {
    id: 2,
    order: 2,
    title: Gri306ReportColumnName.Waste,
    key: "wasteWeightMt",
  },
]

export interface IGriFilters {
  end: DateFilter
  start: DateFilter
}
