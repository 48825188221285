import { useAuthentication } from "../authentication"
import { useActiveOrganizationId } from "../organization"

interface UseUserAccessValue {
  isLimitedAccessUser: boolean | undefined
}

export const useUserAccess = (): UseUserAccessValue => {
  const { authenticatedUser } = useAuthentication()
  const { activeOrganizationId } = useActiveOrganizationId()

  const isLimitedAccessUser: boolean | undefined =
    !authenticatedUser || !activeOrganizationId
      ? undefined
      : authenticatedUser.limitedAccessOrganizationIds.includes(
          Number(activeOrganizationId)
        )

  return {
    isLimitedAccessUser,
  }
}
