import type { FC } from "react"
import React from "react"
import type { SubmitHandler } from "react-hook-form"

import { useUpdateOrganizationalUnit } from "@/services/organizationalUnit"

import SaveIconRounded from "@mui/icons-material/Save"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material"

import { DialogTitle } from "../../../../../nzds/feedback/dialog-title/dialog-title"
import { FormTextField } from "../../../../../nzds/forms/form-text-field/form-text-field"
import { DialogForm } from "../../../../../nzds/forms/form/dialog-form"
import { SubmitButton } from "../../../../../nzds/forms/submit-button/submit-button"
import { useForm } from "../../../../../nzds/forms/use-form/use-form"
import type { EditOUDialogProps } from "./edit-ou-dialog.types"
import type { Schema } from "./schema"
import { schema } from "./schema"

export const EditOUDialog: FC<EditOUDialogProps> = ({
  open,
  onClose,
  organizationalUnit,
}) => {
  const form = useForm({
    schema,
    defaultValues: {
      name: organizationalUnit.name,
    },
  })

  const { reset } = form
  const { mutateAsync, isLoading } = useUpdateOrganizationalUnit()

  const handleClose = () => {
    reset()
    onClose()
  }

  const handleSubmit: SubmitHandler<Schema> = async ({ name }) => {
    try {
      // eslint-disable-next-line no-param-reassign
      organizationalUnit.name = name
      await mutateAsync({ organizationalUnit })
      handleClose()
    } catch (error) {
      console.error(error)
      // TODO: show alert or snackbar?
    }
  }

  return (
    <Dialog
      aria-labelledby="edit-ou-dialog-title"
      data-testid="editOUDialog"
      fullWidth
      maxWidth="xs"
      open={open}
    >
      <DialogTitle id="edit-ou-dialog-title" onClose={handleClose}>
        Rename group (OU)
      </DialogTitle>
      <DialogForm<Schema> form={form} onSubmit={handleSubmit}>
        <DialogContent>
          <Stack gap={2}>
            <FormTextField<Schema> label="Group Name" name="name" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <SubmitButton startIcon={<SaveIconRounded fontSize="small" />}>
            Update
          </SubmitButton>
        </DialogActions>
      </DialogForm>
    </Dialog>
  )
}
