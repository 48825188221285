import { Moment } from "moment"
import moment from "moment"

import type { IFilter } from "./filter"

/**
 * A filter for a date
 *
 * @param value {Moment} - A Moment date
 * @returns {DateFilter} - The filter instance
 * @example
 * new DateFilter(moment("2022-01-31"))
 */
export default class DateFilter implements IFilter<DateFilter, Moment> {
  value: Moment | null

  constructor(value?: Moment) {
    this.value = value || null
  }

  isEqualTo(filter: DateFilter) {
    return (
      (this.value === null && filter.value === null) ||
      !!this.value?.isSame(filter.value)
    )
  }

  toQueryParamValue() {
    return this.value?.format("YYYY-MM-DD") || ""
  }

  static fromQueryParamValue(queryParamValue: string): DateFilter {
    const value: Moment | undefined = queryParamValue
      ? moment(queryParamValue)
      : undefined

    return new DateFilter(value)
  }
}
