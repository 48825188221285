import { useEffect } from "react"

import { useActiveOrganizationId, useAvailableReportDates } from "@/services"
import type { Moment } from "moment"

import useFilters from "../../../../hooks/useFilters/useFilters"
import DateFilter from "../../../../models/filter/dateFilter"
import type { IRange } from "../../../../models/range"
import {
  getMostRecentPreviousYearDateRange,
  isValidCrossYearDateRange,
} from "../../../../utils/date"
import type { ActivityEmissionsFilters } from "../../models/activityEmissions"

interface ActivityEmissionsResolverProps {
  children: JSX.Element
}

/**
 * A component that ensures the ActivityEmissions report url and
 * form data are set up before rendering the report
 *
 * @param props - The component properties
 * @param props.children - The children to render
 * @example
 * return (
 *  <ActivityEmissionsResolver>
 *   <ActivityEmissions />
 * </ActivityEmissionsResolver>
 * )
 */

const ActivityEmissionsResolver = ({
  children,
}: ActivityEmissionsResolverProps) => {
  const { activeOrganizationId } = useActiveOrganizationId()

  const { availableReportDatesData, availableReportDatesIsFetched } =
    useAvailableReportDates(activeOrganizationId)

  const { filters, setFilters } = useFilters<
    Omit<ActivityEmissionsFilters, "filter">
  >({
    start: DateFilter,
    end: DateFilter,
  })

  // Initialize "start" and "end" query params to valid values
  useEffect(() => {
    if (
      availableReportDatesData?.start.isValid() &&
      availableReportDatesData.end.isValid() &&
      // Don't rewrite valid date ranges
      !isValidCrossYearDateRange(
        {
          start: filters.start.value,
          end: filters.end.value,
        },
        availableReportDatesData
      )
    ) {
      const mostRecentPreviousYearDateRange: IRange<Moment> =
        getMostRecentPreviousYearDateRange(availableReportDatesData)

      setFilters(
        {
          start: new DateFilter(mostRecentPreviousYearDateRange.start),
          end: new DateFilter(mostRecentPreviousYearDateRange.end),
        },
        { replace: true }
      )
    }
  }, [
    availableReportDatesData,
    filters.end.value,
    filters.start.value,
    setFilters,
  ])

  if (!availableReportDatesIsFetched) {
    return null
  }

  return children
}

export default ActivityEmissionsResolver
