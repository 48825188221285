import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { ContactDialog } from "@/components/contact-dialog"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { Translation } from "@/models/i18n"
import type Organization from "@/models/organization"
import { RootPath } from "@/models/route"
import { HStack, useDisclosure } from "@/nzds"
import { useAuthorizedOrganizations } from "@/services"
import { useAuthentication } from "@/services/authentication"
import { useLogoutContext } from "@/utils/logout-context"

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Link as MUILink,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"

import { UserMenuOrganizations } from "./user-menu-organizations/user-menu-organizations"

export const UserMenu = () => {
  const theme = useTheme()
  const { logout } = useLogoutContext()
  const { t } = useTranslation()
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  )
  const isMenuOpen = Boolean(menuAnchorEl)
  const { authenticatedUser, isConsultantUser, isFreeUser } =
    useAuthentication()
  const { data: authorizedOrganizations } = useAuthorizedOrganizations()
  const { organization } = useOrganizationContext()

  const {
    isOpen: contactDialogIsOpen,
    onClose: contactDialogOnClose,
    onOpen: contactDialogOnOpen,
  } = useDisclosure()

  const getUserInitials = (userName: string): string => {
    const name = userName.split(" ")
    return `${name[0][0]}${name[1][0]}`
  }

  const closeMenu = (): void => {
    setMenuAnchorEl(null)
  }

  const openMenu = (element: HTMLElement): void => {
    setMenuAnchorEl(element)
  }

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>): void => {
    openMenu(event.currentTarget)
  }

  const handleOrganizationClick = (): void => {
    closeMenu()
  }

  const paidOrganizations: Organization[] =
    authorizedOrganizations?.filter((org) => !org.isMarketTest) ?? []

  const freeOrganizations: Organization[] =
    authorizedOrganizations?.filter((org) => org.isMarketTest) ?? []

  return (
    <>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="current user"
        color="neutral"
        data-e2e="current-user-button"
        onClick={handleAvatarClick}
        sx={{ padding: 0 }}
      >
        <Avatar
          alt={authenticatedUser?.name}
          sx={{
            bgcolor: theme.palette.spectrum.peach[300],
            fontSize: theme.typography.pxToRem(18),
          }}
        >
          {getUserInitials(authenticatedUser?.name)}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id="menu-appbar"
        onClose={closeMenu}
        open={isMenuOpen}
        sx={{
          mt: 1,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack
          sx={{
            maxHeight: "calc(100vh - 92px)",
            width: theme.typography.pxToRem(216),
          }}
        >
          <div>
            <HStack alignItems="flex-start" gap={1} pb={1.25} px={1}>
              <Avatar
                sx={{
                  bgcolor: theme.palette.spectrum.peach[300],
                  height: theme.typography.pxToRem(36),
                  mt: 0.5,
                  mx: 0.5,
                  width: theme.typography.pxToRem(36),
                }}
              />
              <Box>
                <Typography>{authenticatedUser?.name}</Typography>
                <Typography color="grey.500" component="p" variant="caption">
                  {organization?.name}
                </Typography>
              </Box>
            </HStack>
            <Divider />
          </div>
          {authorizedOrganizations?.length > 1 ? (
            <>
              <Box sx={{ flex: 1, overflowX: "hidden", overflowY: "auto" }}>
                {paidOrganizations.length ? (
                  <UserMenuOrganizations
                    activeOrganization={organization}
                    heading="Paid Organizations"
                    onOrganizationClick={handleOrganizationClick}
                    organizations={paidOrganizations}
                  />
                ) : null}
                {paidOrganizations.length && freeOrganizations.length ? (
                  <Divider />
                ) : null}
                {freeOrganizations.length ? (
                  <UserMenuOrganizations
                    activeOrganization={organization}
                    heading="Free Organizations"
                    onOrganizationClick={handleOrganizationClick}
                    organizations={freeOrganizations}
                  />
                ) : null}
              </Box>
              <Divider />
            </>
          ) : null}
          <Box mt={1}>
            {!isConsultantUser && !isFreeUser && (
              <MenuItem
                component={Link}
                onClick={closeMenu}
                sx={{
                  p: 1,
                }}
                to={RootPath.Settings}
              >
                <Typography noWrap>
                  {t(Translation.Settings.SettingsTitle)}
                </Typography>
              </MenuItem>
            )}
            <MenuItem
              onClick={contactDialogOnOpen}
              sx={{
                p: 1,
              }}
            >
              <Typography noWrap>Contact Support</Typography>
            </MenuItem>
            {authenticatedUser?.admin && (
              <MenuItem
                component={MUILink}
                href={RootPath.DeveloperAdmin}
                onClick={closeMenu}
                sx={{
                  p: 1,
                }}
              >
                <Typography noWrap>Administration Settings</Typography>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                logout().catch((e) => {
                  console.error(e)
                })
              }}
              sx={{
                p: 1,
              }}
            >
              <Typography noWrap>Sign Out</Typography>
            </MenuItem>
          </Box>
        </Stack>
      </Menu>

      <ContactDialog
        open={contactDialogIsOpen}
        onClose={contactDialogOnClose}
        type="support"
      />
    </>
  )
}
