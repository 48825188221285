import React from "react"
import type { FieldValues } from "react-hook-form"
import { FormProvider } from "react-hook-form"

import { Box } from "@mui/material"

import type { FormProps } from "./form.types"

export const Form = <TFieldValues extends FieldValues = FieldValues>({
  form,
  children,
  onSubmit,
  sx,
}: FormProps<TFieldValues>) => {
  const onSubmitInternal = onSubmit ? form.handleSubmit(onSubmit) : undefined
  return (
    <FormProvider {...form}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Box component="form" onSubmit={onSubmitInternal} sx={sx}>
        {children}
      </Box>
    </FormProvider>
  )
}
