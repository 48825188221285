import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class BaCode extends ApplicationRecord {
  public static jsonapiType = "ba_codes"

  @Attr() public readonly name: string
}
