import type { FC } from "react"
import React from "react"

import { useFormatDecimal } from "@/formatters/useFormatDecimal"

import type { ChipProps } from "@mui/material"
import { Chip } from "@mui/material"

import type {
  PerformanceCellProps,
  PerformanceLevel,
} from "./performance-cell.types"

const computeLevelFromValue = (
  value?: number | null
): PerformanceLevel | undefined => {
  if (!Number.isFinite(value)) {
    return undefined
  }
  if (value >= 0.8) {
    return "extremelyInefficient"
  }
  if (value >= 0.6) {
    return "inefficient"
  }
  if (value >= 0.4) {
    return "atBenchmark"
  }
  if (value >= 0.2) {
    return "efficient"
  }
  return "highlyEfficient"
}

const computeColorSchemeFromLevel = (
  level: PerformanceLevel
): ChipProps["color"] => {
  let colorScheme: ChipProps["color"]
  switch (level) {
    case "extremelyInefficient":
      colorScheme = "error"
      break
    case "inefficient":
      colorScheme = "error"
      break
    case "atBenchmark":
      colorScheme = "warning"
      break
    case "efficient":
      colorScheme = "success"
      break
    case "highlyEfficient":
      colorScheme = "success"
      break

    default:
      colorScheme = undefined
  }
  return colorScheme
}

/**
 * Shows a chip in different colors based on the performance level value,
 * which should be a percentile based number between 0 and 1.
 *
 * ```tsx
 * import { PerformanceCell } from "@/components/performance/performance-cell"
 *
 * <PerformanceCell value={0.5} />
 * ```
 */
export const PerformanceCell: FC<PerformanceCellProps> = ({
  value,
  ...rest
}) => {
  const { format } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  const label = format(Number.isFinite(value) ? value * 100 : undefined)
  const level = computeLevelFromValue(value)
  const colorScheme = level ? computeColorSchemeFromLevel(level) : "default"
  return (
    <Chip
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      color={colorScheme}
      label={label}
      size="small"
      sx={{
        bgcolor: (theme) => {
          if (level === "inefficient") {
            return `${theme.palette.spectrum.red[50]} !important`
          }
          if (level === "efficient") {
            return `${theme.palette.spectrum.green[50]} !important`
          }
          return undefined
        },
      }}
      variant="outlined"
    />
  )
}
