import type { ReactElement } from "react"
import React from "react"

import reportInProgress from "../../assets/images/report-in-progress.svg"
import { DataAlertInProgressText } from "../../models/report"
import DataAlertBase from "../dataAlertBase/dataAlertBase"

const DataAlertInProgress = (): ReactElement => (
  <DataAlertBase
    altText={DataAlertInProgressText.AltText}
    body={DataAlertInProgressText.Body}
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    img={reportInProgress}
    title={DataAlertInProgressText.Title}
  />
)

export default DataAlertInProgress
