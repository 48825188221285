import { upperFirst } from "lodash-es"

import type {
  MutationInputStatus,
  MutationOutputStatus,
  ServiceAction,
  ServiceName,
} from "../../types"
import { mutationStatusNames } from "../../types"

/**
 * Creates an object of React Query based status names customized to the entity and action.
 * @param serviceName The name of the service.
 * @param serviceAction The action of the service.
 * @param inputObject The input object to be remapped.
 * @returns The remapped object.
 * @example
 * createOutputStatus("test", "updateOne", {
 *  isIdle: false,
 * isLoading: false,
 * isSuccess: true,
 * isError: false,
 * })
 */
export const createOutputStatus = <
  NameT extends ServiceName,
  ActionT extends ServiceAction,
>(
  serviceName: NameT,
  serviceAction: ActionT,
  inputObject: MutationInputStatus
) =>
  ({
    // is[NameActionStatus] (e.g. isCreateOneDepartmentLoading)
    ...Object.fromEntries(
      mutationStatusNames.map((statusKey) => [
        `is${upperFirst(serviceAction)}${upperFirst(serviceName)}${upperFirst(
          statusKey
        )}`,
        inputObject[`is${upperFirst(statusKey)}`],
      ])
    ),
  }) as MutationOutputStatus<NameT, ActionT>
