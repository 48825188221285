import React from "react"
import type { FC } from "react"

import { Box } from "@mui/material"
import type { SxProps } from "@mui/material"

import { useBlur } from "../use-blur"
import type { BlurProps } from "./blur.types"

export const Blur: FC<BlurProps> = ({
  blurRadius = 4,
  children,
  component = "div",
  isBlurred = true,
  sx = {},
}) => {
  const blurStyles: SxProps = useBlur({ blurRadius, isBlurred })
  const styles = {
    ...sx,
    ...blurStyles,
  } as unknown as SxProps

  return (
    <Box component={component} sx={styles}>
      {children}
    </Box>
  )
}
