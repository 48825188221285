import type { ChangeEvent, FC, FormEvent } from "react"
import React, { useMemo, useState } from "react"

import { Box, Button, Fade, TextField, Typography } from "@mui/material"

import { FeatureFlags, useFeature } from "../../services"
import { sendForgotPasswordEmail } from "../../utils"
import { Alert } from "../alert/alert"
import { If } from "../if/if"
import { WhiteBox } from "../whiteBox"
import { validateEmail } from "./forgot-password-utils"

enum EmailStatus {
  Success,
  Unknown,
  Warning,
}

export const ForgotPassword: FC = () => {
  const [email, setEmail] = useState<string>("")
  const [emailStatus, setEmailStatus] = useState<EmailStatus>(
    EmailStatus.Unknown
  )
  const [isValidEmail, setIsValidEmail] = useState(true)

  const { isFeatureEnabled } = useFeature()

  const isCognitoSSOEnabled = isFeatureEnabled(FeatureFlags.COGNITO_SSO, null)

  const isNzeroUser: boolean =
    email.includes("@nzero.com") || email.includes("@ledger8760.com")

  const isNzeroUserAndCognitoEnabled = isCognitoSSOEnabled && isNzeroUser

  const handleAlertClose = (): void => {
    setEmailStatus(EmailStatus.Unknown)
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmailStatus(EmailStatus.Unknown)
    setEmail(event.currentTarget.value)
  }

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      const response = await sendForgotPasswordEmail({ email })

      setEmailStatus(
        response.success ? EmailStatus.Success : EmailStatus.Warning
      )
    } catch (error) {
      setEmailStatus(EmailStatus.Warning)
      console.error(error)
    }
  }

  const emailHelperText: string = useMemo(() => {
    if (isNzeroUserAndCognitoEnabled) {
      return "Please sign in using your SSO credentials"
    }
    if (!isValidEmail) {
      return "Please enter a valid email address"
    }
    return ""
  }, [isNzeroUserAndCognitoEnabled, isValidEmail])

  return (
    <>
      <If condition={emailStatus === EmailStatus.Warning}>
        <Box data-testid="forgot-password-message-container" mb={4}>
          <Alert
            isWarning
            message="There was a problem sending the email. Please check the email address and try again."
            onClose={handleAlertClose}
          />
        </Box>
      </If>
      <Fade in={true}>
        <div>
          <WhiteBox>
            <Typography align="center" mb={4} variant="h1">
              Forgot Password
            </Typography>

            <If condition={emailStatus !== EmailStatus.Success}>
              <Typography mb={4}>
                Please enter your email address to reset your password. Be sure
                to check your spam folder and ensure that emails from
                no-reply@nzero.com are not blocked.
              </Typography>
              <form
                onSubmit={(event) => {
                  void handleSubmit(event)
                }}
              >
                <Box mb={5}>
                  <TextField
                    error={isNzeroUserAndCognitoEnabled || !isValidEmail}
                    fullWidth
                    id="email"
                    helperText={emailHelperText}
                    inputProps={{
                      type: "email",
                    }}
                    label="Email"
                    onBlur={(event) => {
                      setIsValidEmail(validateEmail(event.currentTarget.value))
                    }}
                    onChange={handleEmailChange}
                  />
                </Box>
                <Box textAlign="center">
                  <Button
                    color="primary"
                    disabled={
                      !email.length ||
                      isNzeroUserAndCognitoEnabled ||
                      !isValidEmail
                    }
                    fullWidth
                    id="submit-button"
                    type="submit"
                    variant="contained"
                  >
                    Send Password Reset Link
                  </Button>
                </Box>
              </form>
            </If>

            <If condition={emailStatus === EmailStatus.Success}>
              <Typography align="center" mb={4}>
                If {email} exists, we&rsquo;ll send you an email with a password
                reset link. Please check your inbox.
              </Typography>
              <Box textAlign="center">
                <Button
                  color="primary"
                  onClick={(event) => {
                    void handleSubmit(event)
                  }}
                  type="button"
                  variant="outlined"
                >
                  Resend Email
                </Button>
              </Box>
            </If>
          </WhiteBox>
        </div>
      </Fade>
    </>
  )
}
