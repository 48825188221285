import { sortBy } from "lodash-es"

import type MultiSelectNumberFilter from "../../../../models/filter/multiSelectNumberFilter"
import type SingleSelectNumberFilter from "../../../../models/filter/singleSelectNumberFilter"
import type {
  IScopeCategory,
  IScopeSubcategory,
} from "../../../../models/scope"
import type { ITableData } from "../../../../models/table"
import type { FetchScopeThreeEmissionsValue } from "../../../../services"
import type {
  IScopeThreeCategoryReportTableRow,
  IScopeThreeFilters,
  IScopeThreeSummaryReportTableRow,
} from "../../models/scopeThree"
import {
  ScopeThreeReportColumnKey,
  ScopeThreeReportName,
  scopeThreeCategoryReportColumns,
  scopeThreeSummaryReportColumns,
} from "../../models/scopeThree"

/**
 * Returns an array of categories when given Scope Three emissions
 *
 * @param emissions - The array of emissions
 * @returns The array of categories
 * @example
 * getScopeThreeCategories(emissions)
 */
export const getScopeThreeCategories = (
  emissions: FetchScopeThreeEmissionsValue[] | undefined
): IScopeCategory[] => {
  if (!emissions?.length) {
    return []
  }
  const scopeCategoriesById: Map<string, IScopeCategory> = emissions.reduce(
    (categoriesById, emission) => {
      if (!categoriesById.has(emission.categoryName)) {
        categoriesById.set(emission.categoryName, {
          id: emission.categoryId,
          name: emission.categoryName,
        })
      }

      return categoriesById
    },
    new Map<string, IScopeCategory>()
  )

  return [...scopeCategoriesById.values()].sort((a, b) =>
    a.name.localeCompare(b.name)
  )
}

/**
 * Returns an array of subcategories ordered by name (ascending)
 * when given Scope Three emissions
 *
 * @param emissions - The array of emissions
 * @returns The array of subcategories
 * @example
 * getScopeThreeSubcategories(emissions)
 */
export const getScopeThreeSubcategories = (
  emissions: FetchScopeThreeEmissionsValue[] | undefined
): IScopeSubcategory[] => {
  if (!emissions?.length) {
    return []
  }
  const scopeSubcategoriesById: Map<number, IScopeSubcategory> =
    emissions.reduce((subcategoriesById, emission) => {
      if (!subcategoriesById.has(emission.subcategoryId)) {
        subcategoriesById.set(emission.subcategoryId, {
          categoryId: emission.categoryId,
          id: emission.subcategoryId,
          name: emission.subcategoryName,
        })
      }

      return subcategoriesById
    }, new Map<number, IScopeSubcategory>())

  return [...scopeSubcategoriesById.values()].sort((a, b) =>
    a.name.localeCompare(b.name)
  )
}

/**
 * Generates a scope three report title
 *
 * @param isSummary - Whether the report is a summary report or not
 * @param category - The category
 * @returns The scope 3 report title
 * @example
 * getScopeThreeReportTitle(isSummaryReport, selectedCategory)
 */
export const getScopeThreeReportTitle = (
  isSummary: boolean,
  category: IScopeCategory | undefined
): string => {
  const summaryReportName: string = ScopeThreeReportName.Summary
  const categoryReportName = `${
    ScopeThreeReportName.Category
  } (${category?.name.replace(".", "")})`

  return `${isSummary ? summaryReportName : categoryReportName}`
}

/**
 * Determines whether the category filter is valid or not
 *
 * @param categoryFilter - The category filter
 * @param categories - The array of possible categories
 * @returns Whether the category filter is valid or not
 * @example
 * isScopeThreeCategoryFilterValid(filters.category, categories)
 */
export const isScopeThreeCategoryFilterValid = (
  categoryFilter: SingleSelectNumberFilter,
  categories: IScopeCategory[]
): boolean =>
  !!categories?.find((category) => category.id === categoryFilter.value)

/**
 * Determines whether the subcategory filter is valid or not
 *
 * @param subcategoryFilter - The subcategory filter
 * @param subcategories - The array of possible subcategories
 * @param categoryFilter - The category filter
 * @param categories - The array of possible categories
 * @returns Whether the subcategory filter is valid or not
 * @example
 * isScopeThreeSubcategoryFilterValid(
 *   filters.subcategory,
 *   subcategories,
 *   filters.category,
 *   categories
 * )
 */
export const isScopeThreeSubcategoryFilterValid = (
  subcategoryFilter: MultiSelectNumberFilter,
  subcategories: IScopeSubcategory[],
  categoryFilter: SingleSelectNumberFilter,
  categories: IScopeCategory[]
): boolean => {
  const subcategoryFilterValue: number[] = !subcategoryFilter.value?.length
    ? []
    : subcategoryFilter.value

  if (
    !subcategoryFilter.value?.length ||
    !subcategories?.length ||
    categoryFilter.value === null ||
    !categories?.length
  ) {
    return false
  }

  return !!subcategoryFilterValue.filter((id) => {
    const subcategory: IScopeSubcategory | undefined = subcategories.find(
      (s) => s.id === id
    )

    return subcategory?.categoryId === categoryFilter.value
  }).length
}

/**
 * Determines whether the filters are valid or not
 *
 * @param filters - The array of emissions
 * @param categories - The array of possible categories
 * @param subcategories - The array of possible subcategories
 * @returns Whether the filters are valid or not
 * @example
 * areScopeThreeReportFiltersValid(filters, categories, subcategories)
 */
export const areScopeThreeReportFiltersValid = (
  filters: IScopeThreeFilters,
  categories: IScopeCategory[],
  subcategories: IScopeSubcategory[]
): boolean => {
  const isStartDateValid = !!filters.start.value?.isValid()
  const isEndDateValid = !!filters.end.value?.isValid()
  const isCategoryValid: boolean = isScopeThreeCategoryFilterValid(
    filters.category,
    categories
  )
  const isSubcategoryValid: boolean = isScopeThreeSubcategoryFilterValid(
    filters.subcategory,
    subcategories,
    filters.category,
    categories
  )

  if (filters.summary.value) {
    return isStartDateValid && isEndDateValid
  }
  return (
    isStartDateValid && isEndDateValid && isCategoryValid && isSubcategoryValid
  )
}

/**
 * Returns a category report when given Scope Three emissions
 *
 * @param emissions - The array of emissions
 * @param subcategories - The array of subcategory ids to include in the report
 * @returns The category report
 * @example
 * getScopeThreeCategoryReport(emissions)
 */
export const getScopeThreeCategoryReport = (
  emissions: FetchScopeThreeEmissionsValue[] | undefined,
  subcategories: number[]
): ITableData<IScopeThreeCategoryReportTableRow> => {
  if (!emissions?.length) {
    return {} as ITableData<IScopeThreeCategoryReportTableRow>
  }
  // Get rows
  const rowData: Map<string, IScopeThreeCategoryReportTableRow> = emissions
    .filter((emission) => subcategories.includes(emission.subcategoryId))
    .reduce((data, emission, index) => {
      const emissionNameSubcategoryNameKey = `${emission.ghgEmissionsFactorsName} ${emission.subcategoryName}`

      if (data.has(emissionNameSubcategoryNameKey)) {
        const row = data.get(emissionNameSubcategoryNameKey)

        data.set(emissionNameSubcategoryNameKey, {
          ...row,
          mtCo2E: row.mtCo2E + emission.mtCo2E,
        })
      } else {
        data.set(emissionNameSubcategoryNameKey, {
          id: index,
          emissionsName: emission.ghgEmissionsFactorsName,
          subcategory: emission.subcategoryName,
          mtCo2E: emission.mtCo2E,
        })
      }

      return data
    }, new Map<string, IScopeThreeCategoryReportTableRow>())

  const rows: IScopeThreeCategoryReportTableRow[] = sortBy(
    [...rowData.values()],
    ScopeThreeReportColumnKey.MtCo2E
  ).reverse()

  return {
    columns: scopeThreeCategoryReportColumns,
    rows,
  }
}

/**
 * Returns a summary report when given Scope Three emissions
 *
 * @param emissions - The array of emissions
 * @returns The summary report
 * @example
 * getScopeThreeSummaryReport(emissions)
 */
export const getScopeThreeSummaryReport = (
  emissions: FetchScopeThreeEmissionsValue[] | undefined
): ITableData<IScopeThreeSummaryReportTableRow> => {
  if (!emissions?.length) {
    return {} as ITableData<IScopeThreeSummaryReportTableRow>
  }
  // Get rows
  const rowData: Map<string, IScopeThreeSummaryReportTableRow> =
    emissions.reduce((data, emission, index) => {
      if (data.has(emission.categoryName)) {
        const row = data.get(emission.categoryName)

        data.set(emission.categoryName, {
          ...row,
          mtCo2E: row.mtCo2E + emission.mtCo2E,
        })
      } else {
        data.set(emission.categoryName, {
          id: index,
          category: emission.categoryName,
          mtCo2E: emission.mtCo2E,
        })
      }

      return data
    }, new Map<string, IScopeThreeSummaryReportTableRow>())

  const rows: IScopeThreeSummaryReportTableRow[] = sortBy(
    [...rowData.values()],
    ScopeThreeReportColumnKey.MtCo2E
  ).reverse()

  return {
    columns: scopeThreeSummaryReportColumns,
    rows,
  }
}

export default {
  areScopeThreeReportFiltersValid,
  getScopeThreeCategoryReport,
  getScopeThreeReportTitle,
  getScopeThreeSummaryReport,
  isScopeThreeCategoryFilterValid,
  isScopeThreeSubcategoryFilterValid,
}
