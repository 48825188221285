import { string } from "zod"

import { defineSchema } from "./defineSchema"
import { hydrateSchemas } from "./hydrateSchemas"
import { belongsTo, hasMany, hasOne } from "./zodRelationship"

// TODO: Include allowed zod methods

export const s = {
  string,
  belongsTo,
  hasMany,
  hasOne,
  define: defineSchema,
  hydrate: hydrateSchemas,
}
