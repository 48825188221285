import React from "react"

import { Box, Stack, Typography } from "@mui/material"

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TYPOGRAPHY_VARIANT } from "../../../models/typography"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { solidGray } from "../../../utils/colors"
import { pxToRem } from "../../../utils/unit"

interface IOrganizationDashboardNoDataProps {
  altText: string
  illustration: string
  scope: string | number
}
export const OrganizationDashboardNoData = ({
  illustration,
  scope,
  altText,
}: IOrganizationDashboardNoDataProps): JSX.Element => (
  <Stack alignItems="center" direction="column" gap={2} justifyContent="center">
    <Typography color="grey.600" sx={{ px: 2, py: 3 }} variant="h3">
      There is no scope {scope} data available for this selected time range.
    </Typography>
    <Box
      alt={altText}
      component="img"
      src={illustration}
      sx={{ width: pxToRem(216) }}
    />
  </Stack>
)
