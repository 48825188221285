// @ts-strict-ignore
import type { Moment } from "moment"

import EnergyAllocation from "../../../../models/energyAllocation"
import type { IRange } from "../../../../models/range"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type Site from "../../../../models/site"

interface FetchYtdMeteredKwhValue {
  meteredKwh: number
}

export const fetchYtdMeteredKwh = async ({
  timeWindow,
  siteId,
}: {
  siteId: string
  timeWindow: IRange<Moment>
}): Promise<FetchYtdMeteredKwhValue> =>
  EnergyAllocation.stats({
    metered_kwh: "sum",
  })
    .where({
      start_time: timeWindow.start.format(),
      end_time: timeWindow.end.format(),
      site_id: siteId,
    })
    .per(0)
    .all()
    .then((result) => ({
      // Mass lint disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      meteredKwh: Number.parseFloat(result.meta.stats.metered_kwh.sum ?? "0"),
    }))
