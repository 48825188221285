import React, { useEffect, useMemo, useReducer, useRef, useState } from "react"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import { useSitePreviews } from "@/services/useSitePreviews/useSitePreviews"
import type { Moment } from "moment"
import moment from "moment"

import { AccountBalanceRounded } from "@mui/icons-material"
import { Typography } from "@mui/material"

import { useSelectedDateRange } from "../../contexts/dateSelectionProvider"
import type { ISiteSummary } from "../../models/monthSummary"
import type { IRange } from "../../models/range"
import { TYPOGRAPHY_VARIANT } from "../../models/typography"
import { UnitName } from "../../models/unit"
import { useEnergyStats } from "../../modules/dashboard/services/useEnergyStats/useEnergyStats"
import { useOrgSummaryStats } from "../../modules/dashboard/services/useOrgSummaryStats/useOrgSummaryStats"
import type {
  GroupSummary,
  IDepartmentSummary,
  OrganizationChildrenSummary,
} from "../../modules/dashboard/services/useOrgSummaryStats/useOrgSummaryStatsUtils"
import { DirectAccessSitesTable } from "../../modules/financialSummary/components/directAccessSitesTable"
import { useMinMaxMeteredCostRange } from "../../modules/financialSummary/services/useMinMaxMeteredCostRange/useMinMaxMeteredCostRange"
import { useSiteTotals } from "../../modules/financialSummary/services/useSiteTotals/useSiteTotals"
import { FeatureFlags, useFeature } from "../../services"
import {
  CENTS_PER_DOLLAR,
  CUSTOMER_MTD_OFFSET_DAYS,
  KWH_PER_MWH,
} from "../../utils/constants"
import { handleError } from "../../utils/error"
import type { ICancelablePromise } from "../../utils/promise"
import { makeCancelable } from "../../utils/promise"
import { fetchShortImbalance } from "../../utils/spraypaintApi"
import { DataGuard } from "../data-guard"
import { DateRangeDisplay } from "../date/dateRangeDisplay/dateRangeDisplay"
import { MonthRangeWithPeriodsSelector } from "../date/monthRangeWithPeriodsSelector/monthRangeWithPeriodsSelector"
import { LoadingSpinner } from "../loadingSpinner"
import { PageHeader } from "../nav/page-header"
import { PageHeaderActionBar } from "../nav/page-header-action-bar"
import { Page } from "../page"
import { PageCard } from "../page-card"
import { FinancialSummaryMetrics } from "./financialSummaryMetrics"
import { GraphSelect } from "./graphSelect/graphSelect"
import { GroupViews } from "./groupViews"
import { OrganizationBarGraph } from "./organizationBarGraph"

const fixedMoments = {
  ytd: [moment().startOf("year"), moment()],
  mtd: [moment().startOf("month"), moment()],
}

export const monthlyArray = (
  start: Moment,
  end: Moment,
  months = []
): Moment[] => {
  if (end.isBefore(start, "month")) {
    return [end, start]
  }
  if (start.isSame(end, "month")) {
    return [start]
  }
  let currentDate = start
  while (currentDate.isSameOrBefore(end, "month")) {
    months.push(currentDate.clone())
    currentDate = currentDate.clone().add(1, "month")
  }

  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return months
}

export interface IOrgSummary {
  energyCost: number
  imbalanceCost: number
  meteredMwh: number
  schedulingCost: number
  totalCost: number
  transmissionCost: number
}

type SiteSummarySums = Pick<
  IDepartmentSummary,
  | "costMajor"
  | "energyCostMinor"
  | "imbalanceCostMinor"
  | "meteredKwh"
  | "schedulingCostMinor"
  | "transmissionCostMajor"
>

const getDateRange = (timeframe: IRange<Moment>): Moment[] => [
  timeframe.start ?? moment().startOf("month"),
  timeframe.end ?? moment().endOf("month"),
]

const getDefaultTimeframe = (
  mostRecentMonthWithData: Moment | undefined,
  mtdMonth: Moment
): IRange<Moment> => {
  const mtdDateRange: IRange<Moment> = {
    start: moment().startOf("month"),
    end: moment().endOf("hour"),
  }

  if (!mostRecentMonthWithData?.isValid()) {
    return {
      start: moment(null),
      end: moment(null),
    }
  }

  if (mostRecentMonthWithData.isSame(mtdMonth, "month")) {
    return moment().date() <= CUSTOMER_MTD_OFFSET_DAYS
      ? {
          start: moment().subtract(1, "month").startOf("month"),
          end: moment().subtract(1, "month").endOf("month"),
        }
      : mtdDateRange
  }
  // We can't display MTD because data isn't available for the current month

  return {
    start: mostRecentMonthWithData.clone().startOf("month"),
    end: mostRecentMonthWithData.clone().endOf("month"),
  }
}

export const OrganizationSummary = () => {
  const mountedRef = useRef(true)
  const { organization } = useOrganizationContext()
  const { hasBundled } = organization
  const { isFeatureEnabled } = useFeature()

  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )
  const [orgSummaryData, setOrgSummaryData] = useState<IOrgSummary | null>(null)

  const [graphIsLog, setGraphIsLog] = useState<boolean>(false)

  const [graphData, setGraphData] = useState<Map<string, number> | null>(null)
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [whDisplayUnit, _setWhDisplayUnit] = useState<
    UnitName.KilowattHour | UnitName.MegawattHour
  >(UnitName.MegawattHour)
  const { selectedDateRange, setSelectedDateRange } = useSelectedDateRange()

  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shortImbalanceKwh, setShortImbalanceKwh] = useState<number | null>(
    null
  )
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shortImbalanceCo2eLbs, setShortImbalanceCo2eLbs] = useState<
    number | null
  >(null)

  const [loadingState, setLoadingState] = useReducer(
    (
      s: { electricityEmissions: boolean },
      a: { electricityEmissions: boolean }
    ) => ({ ...s, ...a }),
    {
      electricityEmissions: true,
    }
  )

  // A computed property for the date range used to query data to avoid managing another piece of state
  const dateRange: Moment[] = useMemo(
    () => getDateRange(selectedDateRange),
    [selectedDateRange]
  )

  const { minMaxMeteredCostRange } = useMinMaxMeteredCostRange(organization.id)
  const {
    siteTotals: directAccessSiteTotals,
    isSiteTotalsFetched: isDirectAccessSiteTotalsFetched,
  } = useSiteTotals(
    { organization_id: organization.id },
    {
      start: dateRange[0],
      end: dateRange[1],
    },
    { isDisabled: !organization.id || hasBundled }
  )

  const { sitePreviewsData, sitePreviewsIsFetched } = useSitePreviews(
    organization?.id
  )

  // TODO: Remove this boolean prop once the flexible hierarchy feature is enabled by default
  const { organizationSummaryStats, isOrganizationSummaryStatsLoading } =
    useOrgSummaryStats(
      organization.id,
      {
        start: dateRange[0],
        end: dateRange[1],
      },
      { isUseFlexibleHierarchyEnabled, isBundled: hasBundled }
    )

  // TODO: Remove this once the flexible hierarchy feature is enabled by default
  const typedOrganizationSummaryStats = organizationSummaryStats as
    | OrganizationChildrenSummary[]
    | IDepartmentSummary[]
    | undefined

  const isApiLoading = useMemo(() => {
    if (
      Object.values(loadingState).every((state) => state === false) &&
      !isOrganizationSummaryStatsLoading
    ) {
      return false
    }
    return true
  }, [loadingState, isOrganizationSummaryStatsLoading])
  const { energyStats } = useEnergyStats(organization.id, {
    start: dateRange[0],
    end: dateRange[1],
  })

  const displaySummary: boolean =
    orgSummaryData !== null && graphData !== null && !isApiLoading

  const onMonthRangeChange = (value: IRange<moment.Moment>) => {
    setSelectedDateRange(value)
  }

  const generateOrgSummaryData = (
    groupSummaryDatums: IDepartmentSummary[] | OrganizationChildrenSummary[]
  ): IOrgSummary => {
    // This is a hack in order to get around eslint error with using reduce
    const siteSummarySums: SiteSummarySums = (
      groupSummaryDatums as GroupSummary[]
    ).reduce(
      (result: SiteSummarySums, deptData) => ({
        costMajor: result.costMajor + deptData.costMajor,
        energyCostMinor: result.energyCostMinor + deptData.energyCostMinor,
        imbalanceCostMinor:
          result.imbalanceCostMinor + deptData.imbalanceCostMinor,
        meteredKwh: result.meteredKwh + deptData.meteredKwh,
        schedulingCostMinor:
          result.schedulingCostMinor + deptData.schedulingCostMinor,
        transmissionCostMajor:
          result.transmissionCostMajor + deptData.transmissionCostMajor,
      }),
      {
        costMajor: 0,
        energyCostMinor: 0,
        imbalanceCostMinor: 0,
        meteredKwh: 0,
        schedulingCostMinor: 0,
        transmissionCostMajor: 0,
      }
    )

    const orgData: IOrgSummary = {
      totalCost: siteSummarySums.costMajor,
      energyCost: siteSummarySums.energyCostMinor / CENTS_PER_DOLLAR,
      imbalanceCost: siteSummarySums.imbalanceCostMinor / CENTS_PER_DOLLAR,
      meteredMwh: siteSummarySums.meteredKwh / KWH_PER_MWH,
      schedulingCost: siteSummarySums.schedulingCostMinor / CENTS_PER_DOLLAR,
      transmissionCost: siteSummarySums.transmissionCostMajor,
    }

    return orgData
  }

  const generateOrgSummaryDataFromSites = (
    siteSummaryDatums: ISiteSummary[]
  ): IOrgSummary => {
    const siteSummarySums: SiteSummarySums = siteSummaryDatums.reduce(
      (result, deptData) => ({
        costMajor: result.costMajor + deptData.costMajor,
        energyCostMinor: result.energyCostMinor + deptData.energyCostMinor,
        imbalanceCostMinor:
          result.imbalanceCostMinor + deptData.imbalanceCostMinor,
        meteredKwh: result.meteredKwh + deptData.meteredKwh,
        schedulingCostMinor:
          result.schedulingCostMinor + deptData.schedulingCostMinor,
        transmissionCostMajor:
          result.transmissionCostMajor + deptData.transmissionCostMajor,
      }),
      {
        costMajor: 0,
        energyCostMinor: 0,
        imbalanceCostMinor: 0,
        meteredKwh: 0,
        schedulingCostMinor: 0,
        transmissionCostMajor: 0,
      }
    )

    const orgData: IOrgSummary = {
      totalCost: siteSummarySums.costMajor,
      energyCost: siteSummarySums.energyCostMinor / CENTS_PER_DOLLAR,
      imbalanceCost: siteSummarySums.imbalanceCostMinor / CENTS_PER_DOLLAR,
      meteredMwh: siteSummarySums.meteredKwh / KWH_PER_MWH,
      schedulingCost: siteSummarySums.schedulingCostMinor / CENTS_PER_DOLLAR,
      transmissionCost: siteSummarySums.transmissionCostMajor,
    }

    return orgData
  }

  useEffect(() => {
    if (!sitePreviewsData?.length) {
      return
    }
    if (!dateRange[0].isValid() || !dateRange[1].isValid()) {
      return
    }

    // Mass eslint disable @typescript-eslint/require-await
    // eslint-disable-next-line @typescript-eslint/require-await
    const setBundledSiteData = async () => {
      setShortImbalanceCo2eLbs(energyStats?.stats?.co2ELbs?.sum)
    }

    const setDirectSiteData = async () => {
      // migration to strict mode batch disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      const sitePreviewIds = sitePreviewsData.map((s) => s.id)
      await Promise.all([fetchShortImbalance(sitePreviewIds, dateRange)]).then(
        ([shortImbalances]) => {
          setShortImbalanceKwh(shortImbalances.shortImbalanceKwh)
          setShortImbalanceCo2eLbs(shortImbalances.shortImbalanceCo2eLbs)
        }
      )
    }

    setLoadingState({ electricityEmissions: true })
    let setData: ICancelablePromise<void>

    if (hasBundled) {
      setData = makeCancelable(setBundledSiteData())
    } else {
      setData = makeCancelable(setDirectSiteData())
    }

    setData.promise
      .then(() => {
        setLoadingState({ electricityEmissions: false })
      })
      .catch((error) => {
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        handleError(error)
      })

    return () => {
      setLoadingState({ electricityEmissions: false })
      setData.cancel()
    }
  }, [
    sitePreviewsData,
    dateRange,
    energyStats?.stats?.co2ELbs?.sum,
    hasBundled,
  ])

  // Get all necessary starting data
  useEffect(() => {
    setSelectedDateRange(
      getDefaultTimeframe(minMaxMeteredCostRange?.end, fixedMoments.mtd[1])
    )
  }, [minMaxMeteredCostRange?.end, setSelectedDateRange])

  useEffect(
    () => () => {
      mountedRef.current = false
    },
    []
  )

  useEffect(() => {
    if (hasBundled) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!typedOrganizationSummaryStats) {
        return
      }
      // This is for bundled customers
      const orgData: IOrgSummary = generateOrgSummaryData(
        typedOrganizationSummaryStats
      )
      setOrgSummaryData(orgData)
      // This is a hack in order to get around eslint error with using reduce
      let groupData
      if (isUseFlexibleHierarchyEnabled) {
        groupData =
          typedOrganizationSummaryStats as OrganizationChildrenSummary[]
      } else {
        groupData = typedOrganizationSummaryStats as IDepartmentSummary[]
      }
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const groupAggCost: Map<string, number> = groupData.reduce(
        (agg, group) => {
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const departmentName =
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            group.departmentName === null ? "Other" : group.departmentName

          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const organizationalUnitName =
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            group.organizationalUnitName === null
              ? "Other"
              : // Mass lint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                group.organizationalUnitName

          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const groupName = isUseFlexibleHierarchyEnabled
            ? organizationalUnitName
            : departmentName

          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          if (!agg.has(groupName)) {
            // Mass lint disable
            // Mass eslint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            agg.set(groupName, group.costMajor)
          } else {
            // Mass lint disable
            // Mass eslint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            agg.set(groupName, agg.get(groupName) + group.costMajor)
          }

          // Mass eslint disable @typescript-eslint/no-unsafe-return
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return agg
        },
        new Map()
      )

      groupAggCost.forEach((value, key, dAggCost) => {
        if (value === 0) {
          dAggCost.delete(key)
        }
      })

      const maxGroupCostCount = 10
      const sortedGroupCost = new Map<string, number>(
        [...groupAggCost.entries()]
          .sort((a, b) => b[1] - a[1])
          .slice(0, maxGroupCostCount)
      )

      setGraphData(sortedGroupCost)
    } else {
      if (!directAccessSiteTotals?.length) {
        return
      }

      // this is for direct access customers
      const orgData = generateOrgSummaryDataFromSites(directAccessSiteTotals)
      setOrgSummaryData(orgData)

      const graphDataMap = new Map([
        ["Energy", orgData.energyCost],
        ["Imbalance", Math.abs(orgData.imbalanceCost)],
        ["Scheduling Fees", orgData.schedulingCost],
        ["Transmission & Distribution", orgData.transmissionCost],
      ])
      graphDataMap.forEach((value, key, gDataMap) => {
        if (value === 0) {
          gDataMap.delete(key)
        }
      })
      setGraphData(graphDataMap)
    }
  }, [
    typedOrganizationSummaryStats,
    directAccessSiteTotals,
    hasBundled,
    isUseFlexibleHierarchyEnabled,
  ])

  const FinancialSummary = () =>
    !displaySummary ? (
      <LoadingSpinner
        className="financial-summary__loading-spinner"
        dataE2e="financial-summary-loading-spinner"
      >
        Loading...
      </LoadingSpinner>
    ) : (
      <>
        <div className="organization-summary__header">
          <div
            className="organization-summary__header--title"
            data-e2e="organization-summary-header-title"
          >
            <AccountBalanceRounded sx={{ mr: 1.5 }} />
            <Typography variant={TYPOGRAPHY_VARIANT.h2}>
              {organization.name}, Electricity Financial Summary
            </Typography>
          </div>
          <div className="selected-dates" data-e2e="selected-dates">
            <DateRangeDisplay end={dateRange[1]} start={dateRange[0]} />
          </div>
        </div>
        <FinancialSummaryMetrics
          hasBundled={hasBundled}
          orgSummaryData={orgSummaryData}
          whDisplayUnit={whDisplayUnit}
        />
        <GraphSelect graphIsLog={graphIsLog} setGraphIsLog={setGraphIsLog} />
        <OrganizationBarGraph
          data={graphData}
          graphIsLog={graphIsLog}
          height={350}
        />
        {hasBundled ? (
          <GroupViews
            dateRange={dateRange}
            groupsSummaryData={typedOrganizationSummaryStats}
            hasBundled={hasBundled}
            organization={organization}
            sitePreviews={sitePreviewsData}
            whDisplayUnit={whDisplayUnit}
          />
        ) : (
          <DirectAccessSitesTable
            dateRange={dateRange}
            directAccessSiteTotals={directAccessSiteTotals}
            isDirectAccessSiteTotalsFetched={isDirectAccessSiteTotalsFetched}
            whDisplayUnit={whDisplayUnit}
          />
        )}
      </>
    )

  return (
    <>
      <PageHeader title="Financial Overview" />
      <DataGuard
        hasData={Boolean(sitePreviewsData?.length)}
        isLoading={!sitePreviewsIsFetched}
      >
        <PageHeaderActionBar hasTabs={false}>
          <MonthRangeWithPeriodsSelector
            availableMaxMinMonths={minMaxMeteredCostRange}
            onChange={onMonthRangeChange}
            value={selectedDateRange}
          />
        </PageHeaderActionBar>
        <Page>
          <PageCard
            sx={{
              position: "relative",
              maxWidth: "1200px",
            }}
          >
            <FinancialSummary />
          </PageCard>
        </Page>
      </DataGuard>
    </>
  )
}
