import moment from "moment"

import MonthSummary from "../../../../models/monthSummary"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"

interface MonthSummaryMinMaxDatesValue {
  end: moment.Moment
  start: moment.Moment
}

export const fetchMonthSummariesMinMaxDates = async (
  siteId: string
): Promise<MonthSummaryMinMaxDatesValue> => {
  const result = await MonthSummary.where({
    siteId,
  })
    .stats({
      min_max_months: "calculate",
    })
    .per(0)
    .all()
  // migration to strict mode batch disable
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const data = snakeCaseToCamelCase(result.meta.stats.min_max_months.calculate)
  return {
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    start: moment(data.start),
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    end: moment(data.end),
  }
}
