import React from "react"

import moment from "moment"

import { Box } from "@mui/material"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { DateDisplay } from "../../../../../../../components/date/dateDisplay/dateDisplay"
import { convertDateStringDashesToSlashes } from "../../../../../../../utils/date"
import { OrgViewDetailMenuButton } from "../orgViewDetailMenuButton/orgViewDetailMenuButton"
import type { OrgViewDetailHeaderProps } from "./types"

export const OrgViewDetailHeader = ({
  dateCreated,
  dateUpdated,
  createButton,
  deleteButton,
  datUrl,
  webAppUrl,
}: OrgViewDetailHeaderProps) => (
  <Stack sx={{ mb: 1 }}>
    <Stack
      alignItems="flex-start"
      direction="row"
      justifyContent="space-between"
      sx={{ mb: 1 }}
    >
      <Box
        color="grey.600"
        component="div"
        display="flex"
        flex="1.7"
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        <Box
          alignItems="center"
          component="div"
          display="flex"
          justifyContent="space-around"
        >
          <Typography variant="body2">Created:&nbsp;</Typography>
          {dateCreated ? (
            <DateDisplay
              format={{
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              }}
              value={moment.tz(
                // TO-DO: Remove deprecated function when backend passes date string in proper format
                new Date(
                  convertDateStringDashesToSlashes(dateCreated)
                ).toISOString(),
                "UTC"
              )}
              variant="body2"
            />
          ) : null}
        </Box>
        <Box component="span">&nbsp;&nbsp;/&nbsp;&nbsp;</Box>
        <Box
          alignItems="center"
          component="div"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          <Typography variant="body2">Updated:&nbsp;</Typography>
          {dateUpdated ? (
            <DateDisplay
              format={{
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              }}
              value={moment.tz(
                // TO-DO: Remove deprecated function when backend passes date string in proper format
                new Date(
                  convertDateStringDashesToSlashes(dateUpdated)
                ).toISOString(),
                "UTC"
              )}
              variant="body2"
            />
          ) : null}
        </Box>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flex="1"
        gap={1}
        justifyContent="flex-end"
      >
        {createButton}
        <OrgViewDetailMenuButton
          datUrl={datUrl}
          deleteButton={deleteButton}
          webAppUrl={webAppUrl}
        />
      </Box>
    </Stack>
  </Stack>
)
