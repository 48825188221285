import type { Organization } from "@/models/organization"
import type { Schemas } from "@/services/types"
import { httpClient } from "@/utils/api"

/**
 * Gets list of interventions to use in input fields
 */
export const getInterventions = async (
  organizationId: Organization["id"]
): Promise<Schemas["InterventionResponse"][]> => {
  const response = await httpClient.get<Schemas["InterventionResponse"][]>(
    `/api/v1/recommendations/interventions`,
    {
      params: {
        organization_id: organizationId,
      },
    }
  )

  return response.data
}
