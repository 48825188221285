// @ts-strict-ignore
import { useCallback, useMemo } from "react"
import { useQuery, useQueryClient } from "react-query"

import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"

interface NonMonthlyElectricityDateRangeValue {
  nonMonthlyElectricityDateRange: IRange<Moment>
  setNonMonthlyElectricityDateRange: (dateRange: IRange<Moment>) => void
}

/**
 * A service for saving and providing the date range for a site's non-monthly electricity
 *
 * @returns - The nonMonthlyElectricity date range service
 * @example
 * const { nonMonthlyElectricityDateRange, setNonMonthlyElectricityDateRange }
 * = useNonMonthlyElectricityDateRange()
 */
export const useNonMonthlyElectricityDateRange =
  (): NonMonthlyElectricityDateRangeValue => {
    const queryClient = useQueryClient()
    const queryKey = ApiQueryName.NonMonthlyElectricityDateRange

    const { data } = useQuery({
      cacheTime: Infinity,
      queryFn: (): Promise<IRange<Moment>> =>
        Promise.resolve(queryClient.getQueryData(queryKey)),
      queryKey,
      staleTime: Infinity,
    })

    const nonMonthlyElectricityDateRange = useMemo(
      () => data,
      [data]
    ) as unknown as IRange<Moment>

    const setNonMonthlyElectricityDateRange = useCallback(
      (dateRange: IRange<Moment>) => {
        queryClient.setQueryData(queryKey, dateRange)
      },
      [queryClient, queryKey]
    )

    return useMemo(
      () => ({
        nonMonthlyElectricityDateRange,
        setNonMonthlyElectricityDateRange,
      }),
      [nonMonthlyElectricityDateRange, setNonMonthlyElectricityDateRange]
    )
  }
