// @ts-strict-ignore
import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"

import { ApiQueryName } from "../../../../models/api"
import { fetchMonthSummariesMinMaxDates } from "./useMonthSummariesMinMaxDatesUtils"

export const useMonthSummariesMinMaxDates = (
  siteId: string | undefined,
  options: { isDisabled?: boolean } = {}
) => {
  const queryKey = useQueryKey(
    ApiQueryName.MonthSummariesMinMaxDates,
    "getOne",
    siteId
  )

  const queryResult = useQuery({
    keepPreviousData: true,
    queryKey,
    queryFn: async () => fetchMonthSummariesMinMaxDates(siteId),
    enabled: !!siteId && !options.isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
