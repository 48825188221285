import type { TabsProps } from "@mui/material"
import { Tabs } from "@mui/material"
import { styled } from "@mui/material/styles"

export const ContainedTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  minHeight: theme.typography.pxToRem(32),
  "& div.MuiTabs-scroller": {
    "& .MuiTabs-flexContainer": {
      gap: theme.spacing(1),
    },
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}))
