import React from "react"

import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../../../models/typography"

interface OrgViewDeleteDialogProps {
  actions: JSX.Element
  content: JSX.Element
  onClose: () => void
  open: boolean
  title: string
}

const OrgViewDeleteDialog = ({
  open,
  title,
  onClose,
  content,
  actions,
}: OrgViewDeleteDialogProps): JSX.Element => (
  <Dialog data-testid="orgViewDialog" fullWidth maxWidth="xs" open={open}>
    <DialogTitle component="div" data-testid="orgViewDialogTitle">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant={TYPOGRAPHY_VARIANT.h2}>{title}</Typography>
        <IconButton data-testid="closeButton" onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
    </DialogTitle>
    <DialogContent data-testid="orgViewDialogContent">{content}</DialogContent>
    <DialogActions data-testid="orgViewDialogActions">{actions}</DialogActions>
  </Dialog>
)

export default OrgViewDeleteDialog
