import React from "react"
import type { FC } from "react"
import { Outlet } from "react-router-dom"

import { UnauthenticatedAppHeader } from "@/components/unauthenticatedAppHeader/unauthenticatedAppHeader"

import { Box } from "@mui/material"

export const UnauthenticatedRoute: FC = () => {
  return (
    <>
      <UnauthenticatedAppHeader />
      <Box sx={{ mx: 2 }}>
        <Box
          mb={8}
          sx={{
            maxWidth: 728,
            mx: "auto",
            py: 9,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  )
}
