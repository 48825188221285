import type { TabProps } from "@mui/material"
import { Tab } from "@mui/material"
import { alpha, styled } from "@mui/material/styles"

export const ContainedTab = styled(Tab)<TabProps>(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: theme.typography.pxToRem(16),
  borderRadius: theme.typography.pxToRem(24),
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.grey[700],
  fontWeight: theme.typography.fontWeightRegular,
  border: `1px solid ${theme.palette.grey[100]}`,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
  "&:active": {
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
  "&.MuiButtonBase-root": {
    minHeight: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    padding: theme.spacing(0.5, 1),
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.spectrum.blue[50],
    color: theme.palette.spectrum.blue[700],
    border: `1px solid ${theme.palette.spectrum.blue[600]}`,
    "&:hover": {
      backgroundColor: alpha(theme.palette.spectrum.blue[400], 0.2),
    },
    "&:active": {
      backgroundColor: alpha(theme.palette.spectrum.blue[400], 0.2),
    },
  },
})) as unknown as typeof Tab
