import React, { useEffect, useMemo, useState } from "react"
import type { FC } from "react"
import { useSearchParams } from "react-router-dom"

import { NzDataGrid } from "@/components/nzDataGrid"
import { PageCard } from "@/components/page-card/page-card"
import { Page } from "@/components/page/page"
import { PerformanceGridLegend } from "@/components/performance"
import { useOrganizationContext } from "@/contexts"
import {
  usePageScrollChange,
  usePaginatedScroll,
  useSaveGridModel,
} from "@/nzds/data-grid"
import type { Schemas } from "@/services/types"

import { Alert, Box } from "@mui/material"
import { useGridApiRef } from "@mui/x-data-grid-pro"

import { useInfiniteGetProfiles } from "../../services/use-get-profiles/use-infinite-get-profiles"
import type { UseInfiniteGetProfilesOptions } from "../../services/use-get-profiles/use-infinite-get-profiles"
import { ResultsGridFooterCta } from "../components/results-grid-footer-cta/results-grid-footer-cta"
import { ASC, DESC } from "../constants"
import { useComputedRows } from "../utils/use-computed-rows/use-computed-rows"
import {
  defaultColumnVisibilityModel,
  defaultPinnedColumns,
  defaultSortModel,
  usePerformanceResultsColumns,
} from "../utils/use-performance-results-columns"

export const RepPerformanceRoute: FC = () => {
  const { organization } = useOrganizationContext()

  const gridApiRef = useGridApiRef()

  const [searchParams] = useSearchParams()

  const [computing, setComputing] = useState(false)

  const defaultColumns = usePerformanceResultsColumns()

  const {
    gridModel: { columnVisibilityModel, columns, pinnedColumns, sortModel },
    handleColumnOrderChange,
    handleColumnVisibilityModelChange,
    handlePinnedColumnsChange,
    handleSortModelChange,
  } = useSaveGridModel({
    gridName: "repPerformance",
    gridModel: {
      columns: defaultColumns,
      columnVisibilityModel: defaultColumnVisibilityModel,
      pinnedColumns: defaultPinnedColumns,
      sortModel: defaultSortModel,
    },
  })

  const descendingQueryParam: UseInfiniteGetProfilesOptions["parameters"]["query"]["descending"] =
    useMemo(() => {
      switch (sortModel[0].sort) {
        case ASC:
          return false
        case DESC:
          return true
        default:
          return undefined
      }
    }, [sortModel])

  const {
    isFetching,
    data,
    fetchNextPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    fetchPreviousPage,
  } = useInfiniteGetProfiles({
    parameters: {
      query: {
        descending: descendingQueryParam,
        order_by: sortModel[0]
          .field as UseInfiniteGetProfilesOptions["parameters"]["query"]["order_by"],
        organization_id: Number(organization?.id),
      },
    },
    initialPage: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1,
    queryOptions: {
      enabled: Boolean(organization?.id),
      refetchInterval: computing ? 30000 : undefined,
    },
  })

  const handleScrollPageChange = usePageScrollChange()

  usePaginatedScroll<Schemas["EmissionProfilePage"]>({
    isEnabled: Boolean(organization?.id),
    apiRef: gridApiRef,
    data,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    onScrollPageChange: handleScrollPageChange,
    currentPage: Number(searchParams.get("page")),
  })

  const computedRows = useComputedRows(data)

  const binnedCount = data?.pages[0].binned_count

  // Manage computing state (if server is processing the benchmark data)
  useEffect(() => {
    if (computedRows.length === 0) {
      return
    }
    if (
      !Number.isFinite(computedRows[0].benchmark_annual_combined_percentile)
    ) {
      setComputing(true)
    }

    if (Number.isFinite(computedRows[0].benchmark_annual_combined_percentile)) {
      setComputing(false)
    }
  }, [computedRows])

  return (
    <Page fullHeight>
      <PageCard
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {computing ? (
          <Alert severity="info" sx={{ mb: 2 }}>
            We are currently generating the benchmark data for your
            organization. The data will be available shortly.
          </Alert>
        ) : null}

        <Box mb={3}>
          <PerformanceGridLegend
            atBenchmarkCount={binnedCount?.average}
            efficientCount={binnedCount?.efficient}
            extremelyInefficientCount={binnedCount?.very_inefficient}
            highlyEfficientCount={binnedCount?.very_efficient}
            inefficientCount={binnedCount?.inefficient}
            siteCount={data?.pages[0]?.total}
          />
        </Box>

        <Box flex={1} position="relative" width="100%">
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <NzDataGrid
              apiRef={gridApiRef}
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              disableColumnFilter
              hideFooter={true}
              isLoading={isFetching}
              onColumnOrderChange={handleColumnOrderChange}
              onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
              onPinnedColumnsChange={handlePinnedColumnsChange}
              onSortModelChange={handleSortModelChange}
              pinnedColumns={pinnedColumns}
              rows={computedRows}
              slots={{
                footer: ResultsGridFooterCta,
              }}
              sortingMode="server"
              sortModel={sortModel}
            />
          </Box>
        </Box>
      </PageCard>
    </Page>
  )
}
