import { useQuery } from "react-query"
import type { UseQueryResult } from "react-query"

import { ApiQueryName } from "@/models/api"

import { useQueryKey, useRenameQueryResult } from "../utils"
import type { SigmaPaginatedResponse, SigmaTeam } from "./sigma.types"
import { useSigmaApi } from "./useSigmaApi"
import type { FetchSigmaTeamsOptions } from "./useSigmaApi"

type UseSigmaTeamsOptions = FetchSigmaTeamsOptions

/**
 * Gets the Sigma teams optionally filtered by team name
 *
 * @returns The Sigma teams
 *
 * @example
 * const {
 *   sigmaTeamsData,
 * } = useSigmaTeams()
 *
 * @example
 * const {
 *   sigmaTeamsData,
 * } = useSigmaTeams({
 *   name: "ABC Inc.",
 * })
 */
export const useSigmaTeams = (options: UseSigmaTeamsOptions = {}) => {
  const { fetchSigmaTeams, sigmaAccessTokenIsFetched } = useSigmaApi()

  const enabled = sigmaAccessTokenIsFetched

  const queryKey = useQueryKey(
    ApiQueryName.SigmaWorkbooks,
    "getMany",
    null,
    options
  )

  const queryResult: UseQueryResult<SigmaPaginatedResponse<SigmaTeam>> =
    useQuery<SigmaPaginatedResponse<SigmaTeam>>({
      enabled,
      queryFn: async () => fetchSigmaTeams(options),
      queryKey,
    })

  return useRenameQueryResult(queryResult, ApiQueryName.SigmaTeams)
}
