import { cloneDeep, range, sortBy } from "lodash-es"
import type { Moment } from "moment"
import moment from "moment"

import type { IRange } from "../../../models/range"
import { Scope } from "../../../models/scope"
import type { ScopeEmissionBase } from "../../../models/scope"
import type { FetchScopeThreeEmissionsValue } from "../../../services"
import { formatToYYYYMMDD, getYearMonthDayISOString } from "../../../utils"
import type {
  EmissionRecord,
  GraphData,
  ScopeEmissionsGraphItem,
  ScopeEmissionsScopeTotal,
  ScopeGraphKeyNames,
  ScopeOneEmissionData,
  ScopeOneEmissionsGraphItem,
  ScopeThreeEmissionsGraphItem,
  ScopeTwoEmissionsGraphItem,
} from "../models/dashboard"
import {
  organizationDashboardGraphTheme,
  scopeOneCategoryToGraphKeyMap,
} from "../models/dashboard"
import type { FetchScopeOneEmissionsValue } from "../services/useScopeOneEmissions/useScopeOneEmissionsUtils"
import type { FetchScopeTwoEmissionsValue } from "../services/useScopeTwoEmissions/useScopeTwoEmissionsUtils"

export const getPseudoEndValue = (
  data:
    | ScopeOneEmissionsGraphItem
    | ScopeTwoEmissionsGraphItem
    | ScopeEmissionsGraphItem
):
  | ScopeOneEmissionsGraphItem
  | ScopeTwoEmissionsGraphItem
  | ScopeEmissionsGraphItem => {
  const dateValue = data.date
  const endDate = moment(dateValue).utc()
  const increasedEndDate = endDate.clone().endOf("month")

  return {
    ...data,
    index: data.index + 1,
    date: formatToYYYYMMDD(increasedEndDate),
  }
}

// TODO: refactor; better define the interface and rename
export const defaultGraphOutput = <TGraphItem>(
  emissionsByMonth
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
): TGraphItem[] => Object.values(emissionsByMonth)

export const getGraphDataWithMissingMonths = (
  dateRange: IRange<Moment>,
  graphData: GraphData,
  graphKeys: string[]
): GraphData => {
  if (
    !Object.keys(graphData).length ||
    !dateRange?.start?.isValid() ||
    !dateRange?.end?.isValid()
  ) {
    return {} as GraphData
  }

  const initialGraphKeysData: Record<string, null> = graphKeys.reduce(
    (acc, key) => {
      acc[key] = null
      return acc
    },
    {}
  )

  const graphDataWithMissingMonths: GraphData = range(
    dateRange.start.month() + 1,
    dateRange.end.month() + 2 // Must add 2 because end value is not included in generated range
  ).reduce<GraphData>(
    (
      graphDataIncludingMissingMonths: GraphData,
      monthIndex: number
    ): GraphData => {
      const graphDatum = graphData[monthIndex] ?? initialGraphKeysData

      graphDataIncludingMissingMonths[monthIndex] = {
        date: formatToYYYYMMDD(
          dateRange.start
            .clone()
            .add(monthIndex - dateRange.start.month() - 1, "month")
        ),
        index: monthIndex,
        ...graphDatum,
      }

      return graphDataIncludingMissingMonths
    },
    {}
  )

  // This is needed for the graph to display correctly.
  // If there is only one month, a duplicate is created
  // with an end date set to the end of the month.
  if (Object.keys(graphDataWithMissingMonths).length === 1) {
    const additionalDataPointKey: number =
      Number(Object.keys(graphDataWithMissingMonths)[0]) + 1

    graphDataWithMissingMonths[additionalDataPointKey] = getPseudoEndValue(
      Object.values(graphDataWithMissingMonths)[0]
    )
  }

  return graphDataWithMissingMonths
}

export const getEmissionByMonth = <TEmission>({
  emissionsByMonthAcc,
  emissionMonth,
}: {
  emissionMonth: number
  emissionsByMonthAcc: EmissionRecord<TEmission>
}): TEmission | undefined => emissionsByMonthAcc[emissionMonth]

export const setScopeTwoEmissionsByMonth = (
  scopeTwoEmissionsByMonth: EmissionRecord<ScopeTwoEmissionsGraphItem>,
  emission: FetchScopeTwoEmissionsValue,
  value: number,
  costMajor: number
): EmissionRecord<ScopeTwoEmissionsGraphItem> => {
  const newScopeTwoEmissionsByMonth: EmissionRecord<ScopeTwoEmissionsGraphItem> =
    cloneDeep(scopeTwoEmissionsByMonth)
  // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  newScopeTwoEmissionsByMonth[emission.month] = {
    // Mass eslint disable for @typescript-eslint/no-unsafe-argument
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    date: getYearMonthDayISOString(emission.year, emission.month),
    value,
    costMajor,
  }

  return newScopeTwoEmissionsByMonth
}

export const getScopeTotalEmissions = (
  scopeEmissions: ScopeEmissionBase[] | undefined
): number | null => {
  if (scopeEmissions?.every((emission) => !Number.isFinite(emission.mtCo2E))) {
    return null
  }

  return scopeEmissions?.reduce(
    (total: number, emission: FetchScopeOneEmissionsValue) => {
      const newTotal = total + (emission.mtCo2E || 0)

      return newTotal
    },
    0
  )
}

export const getScopeOneEmissionsCategories = (
  scopeOneEmissions: FetchScopeOneEmissionsValue[] | undefined
): string[] => {
  if (!scopeOneEmissions) {
    return []
  }
  const scopeOneEmissionCategories = scopeOneEmissions.reduce(
    (
      categories: ScopeOneEmissionData[],
      emission: FetchScopeOneEmissionsValue
    ) => {
      const includesCategoryName = categories
        .map((category) => category.name)
        .includes(emission.categoryName.toLowerCase())
      if (!includesCategoryName) {
        categories.push(
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          scopeOneCategoryToGraphKeyMap[emission.categoryName.toLowerCase()]
        )
      }
      return categories
    },
    []
  )
  const sortedScopeOneEmissionCategories = sortBy(
    scopeOneEmissionCategories,
    "order"
  )

  return sortedScopeOneEmissionCategories.map((category) => category.name)
}

export const getScopeOneEmissionsGraphData = (
  dateRange: IRange<Moment>,
  scopeOneEmissions: FetchScopeOneEmissionsValue[],
  graphKeys: string[]
): ScopeOneEmissionsGraphItem[] => {
  if (
    !scopeOneEmissions ||
    !dateRange.start?.isValid() ||
    !dateRange.end?.isValid()
  ) {
    return []
  }
  const scopeOneEmissionsByMonth: Record<number, ScopeOneEmissionsGraphItem> =
    scopeOneEmissions.reduce(
      (
        scopeOneEmissionsByMonthAcc: Record<number, ScopeOneEmissionsGraphItem>,
        emission: FetchScopeOneEmissionsValue
      ) => {
        // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!scopeOneEmissionsByMonthAcc[emission.month]) {
          // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          scopeOneEmissionsByMonthAcc[emission.month] = {
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            date: getYearMonthDayISOString(emission.year, emission.month),
          }
        }

        if (
          // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          !scopeOneEmissionsByMonthAcc[emission.month][
            emission.categoryName.toLowerCase()
          ]
        ) {
          // Create the category record
          scopeOneEmissionsByMonthAcc[emission.month][
            emission.categoryName.toLowerCase()
          ] = emission.mtCo2E || 0

          graphKeys.forEach((key) => {
            if (
              !Object.keys(
                // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                scopeOneEmissionsByMonthAcc[emission.month]
              ).includes(key)
            ) {
              // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              scopeOneEmissionsByMonthAcc[emission.month][key] = 0
            }
          })
        } else {
          // Otherwise add to the existing record
          scopeOneEmissionsByMonthAcc[emission.month][
            emission.categoryName.toLowerCase()
          ] += emission.mtCo2E || 0
        }

        return scopeOneEmissionsByMonthAcc
      },
      {}
    )

  const graphDataWithMissingMonths = getGraphDataWithMissingMonths(
    dateRange,
    scopeOneEmissionsByMonth,
    graphKeys
  )

  return defaultGraphOutput<ScopeOneEmissionsGraphItem>(
    graphDataWithMissingMonths
  )
}

export const getScopeTwoTotalEmissions = (
  scopeTwoEmissions: FetchScopeTwoEmissionsValue[] | undefined
): number | null => {
  if (
    scopeTwoEmissions?.every((emission) => !Number.isFinite(emission.mtCo2E))
  ) {
    return null
  }
  return scopeTwoEmissions?.reduce(
    (total: number, emission: FetchScopeTwoEmissionsValue) =>
      total + (emission.mtCo2E || 0),
    0
  )
}

export const getScopeTwoTotalKwh = (
  scopeTwoEmissions: FetchScopeTwoEmissionsValue[] | undefined
) =>
  Number(
    scopeTwoEmissions?.reduce((pv, cv) => pv + Number(cv?.meteredKwh ?? 0), 0)
  )

export const getScopeTwoEmissionsGraphData = (
  dateRange: IRange<Moment>,
  scopeTwoEmissions: FetchScopeTwoEmissionsValue[] | undefined
): ScopeTwoEmissionsGraphItem[] => {
  if (!scopeTwoEmissions) {
    return []
  }

  const scopeTwoEmissionsByMonth: EmissionRecord<ScopeTwoEmissionsGraphItem> =
    scopeTwoEmissions?.reduce(
      (
        scopeTwoEmissionsByMonthAcc: EmissionRecord<ScopeTwoEmissionsGraphItem>,
        emission: FetchScopeTwoEmissionsValue
      ) => {
        if (
          !getEmissionByMonth<ScopeTwoEmissionsGraphItem>({
            emissionsByMonthAcc: scopeTwoEmissionsByMonthAcc,
            // Mass eslint disable for @typescript-eslint/no-unsafe-assignment
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            emissionMonth: emission.month,
          })
        ) {
          // If an emission record for the month doesn't exist yet, create it
          scopeTwoEmissionsByMonthAcc = setScopeTwoEmissionsByMonth(
            scopeTwoEmissionsByMonthAcc,
            emission,
            emission.mtCo2E,
            emission.costMajor
          )
        } else {
          // Otherwise, add to the existing month emission value
          scopeTwoEmissionsByMonthAcc[emission.month].value += emission.mtCo2E
          // Mass eslint disable for @typescript-eslint/no-unsafe-member-access
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          scopeTwoEmissionsByMonthAcc[emission.month].costMajor +=
            emission.costMajor
        }

        return scopeTwoEmissionsByMonthAcc
      },
      {}
    )

  const graphDataWithMissingMonths = getGraphDataWithMissingMonths(
    dateRange,
    scopeTwoEmissionsByMonth,
    ["value", "costMajor"]
  )

  return defaultGraphOutput<ScopeTwoEmissionsGraphItem>(
    graphDataWithMissingMonths
  )
}

export const getScopeThreeEmissionsGraphData = (
  scopeThreeEmissions: FetchScopeThreeEmissionsValue[] | undefined
): ScopeThreeEmissionsGraphItem[] => {
  if (!scopeThreeEmissions) {
    return []
  }
  const scopeThreeEmissionsByCategory: Record<
    string,
    ScopeThreeEmissionsGraphItem
  > = scopeThreeEmissions.reduce(
    (
      scopeThreeEmissionsByCategoryAcc: Record<
        string,
        ScopeThreeEmissionsGraphItem
      >,
      emission: FetchScopeThreeEmissionsValue
    ) => {
      if (!scopeThreeEmissionsByCategoryAcc[emission.categoryName]) {
        scopeThreeEmissionsByCategoryAcc[emission.categoryName] = {
          categoryName: emission.categoryName,
          mtCo2E: 0,
        }
      }

      scopeThreeEmissionsByCategoryAcc[emission.categoryName].mtCo2E +=
        emission.mtCo2E || 0

      return scopeThreeEmissionsByCategoryAcc
    },
    {}
  )

  const sortedScopeThreeItems = sortBy(
    Object.values(scopeThreeEmissionsByCategory),
    ["mtCo2E"]
  ).reverse()

  // Return the top 5 items
  return sortedScopeThreeItems.slice(0, 5)
}

export const addScopeEmissionsByMonth = (
  emissions: ScopeEmissionBase[],
  scope: Scope,
  scopeEmissionsByMonth: Record<number, ScopeEmissionsGraphItem>
): void => {
  emissions.forEach((emission) => {
    if (!scopeEmissionsByMonth[emission.month]) {
      // A record for this month does not exist, create a default record with this scope

      scopeEmissionsByMonth[emission.month] = {
        date: getYearMonthDayISOString(emission.year, emission.month),
        "Scope 1": 0,
        "Scope 2": 0,
        "Scope 3": 0,
      }
    }

    scopeEmissionsByMonth[emission.month][`Scope ${scope}`] +=
      emission.mtCo2E || 0

    return scopeEmissionsByMonth
  }, {})
}

export const getScopeEmissionsGraphData = (
  dateRange: IRange<Moment>,
  scope1Emissions?: ScopeEmissionBase[],
  scope2Emissions?: ScopeEmissionBase[],
  scope3Emissions?: ScopeEmissionBase[]
): ScopeEmissionsGraphItem[] => {
  const scopeEmissionsByMonth: Record<number, ScopeEmissionsGraphItem> = {}

  if (scope1Emissions) {
    addScopeEmissionsByMonth(scope1Emissions, Scope.One, scopeEmissionsByMonth)
  }

  if (scope2Emissions) {
    addScopeEmissionsByMonth(scope2Emissions, Scope.Two, scopeEmissionsByMonth)
  }

  if (scope3Emissions) {
    addScopeEmissionsByMonth(
      scope3Emissions,
      Scope.Three,
      scopeEmissionsByMonth
    )
  }

  const graphDataWithMissingMonths = getGraphDataWithMissingMonths(
    dateRange,
    scopeEmissionsByMonth,
    Object.keys(organizationDashboardGraphTheme.emissionsByScope)
  )

  return defaultGraphOutput<ScopeEmissionsGraphItem>(graphDataWithMissingMonths)
}

export const getAllScopeEmissionsCategories = (
  scope1EmissionsCount: number,
  scope2EmissionsCount: number,
  scope3EmissionsCount: number
): ScopeGraphKeyNames[] => {
  const categories: ScopeGraphKeyNames[] = []

  // Don't like the hard-coded category names, but works for now
  if (scope1EmissionsCount) {
    categories.push("Scope 1")
  }

  if (scope2EmissionsCount) {
    categories.push("Scope 2")
  }

  if (scope3EmissionsCount) {
    categories.push("Scope 3")
  }

  return categories
}

export const sortedScopeTotals = (
  scopeOneTotalEmissions: number,
  scopeTwoTotalEmissions: number,
  scopeThreeTotalEmissions: number
): ScopeEmissionsScopeTotal[] =>
  sortBy(
    [
      { order: 2, scope: Scope.One, value: scopeOneTotalEmissions },
      { order: 1, scope: Scope.Two, value: scopeTwoTotalEmissions },
      {
        order: 0,
        scope: Scope.Three,
        value: scopeThreeTotalEmissions,
      },
    ],
    "order"
  ) // ascending by order, descending by scope
