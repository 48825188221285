import React from "react"
import type { FC } from "react"

import { useFormatDecimal } from "@/formatters/useFormatDecimal"
import { UnitName } from "@/models/unit"
import { KBTU_PER_KWH, KBTU_PER_THERM } from "@/utils/constants"

import type { CombinedVolumePercentProps } from "./combined-volume-percent.types"

export const CombinedVolumePercent: FC<CombinedVolumePercentProps> = ({
  total,
  unit,
  value,
}) => {
  const { format: formatDecimalToTenths } = useFormatDecimal({
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  })

  let conversionFactor = 1

  if (unit === UnitName.KilowattHour) {
    conversionFactor = KBTU_PER_KWH
  }

  if (unit === UnitName.Therm) {
    conversionFactor = KBTU_PER_THERM
  }

  const convertedValue: number = value ? value * conversionFactor : 0

  const percent: number = total ? (convertedValue / total) * 100 : 0

  return <>({formatDecimalToTenths(percent)}%)</>
}
