import { client } from "@/services/client"
import type { Paths } from "@/services/types"

export interface UploadProfilesCsvParameters {
  parameters: Paths["/api/v1/recommendations/emission_profiles/load"]["post"]["parameters"]
  requestBody: Paths["/api/v1/recommendations/emission_profiles/load"]["post"]["requestBody"]["content"]["multipart/form-data"]
}

export const uploadProfilesCsv = async ({
  parameters,
  requestBody,
}: UploadProfilesCsvParameters) => {
  const { data, error } = await client.POST(
    "/api/v1/recommendations/emission_profiles/load",
    {
      body: requestBody,
      params: { query: parameters.query },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      bodySerializer: (body) => {
        const formData = new FormData()
        formData.append("file", body.file)

        return formData
      },
    }
  )

  if (data) {
    return data
  }

  return Promise.reject(error)
}
