import React from "react"

import { Divider } from "@mui/material"

import { gray50 } from "../../../../utils/colors"
import { getDataE2EAttribute } from "../../../../utils/e2e"
import { pxToRem } from "../../../../utils/unit"
import type { RowItem } from "../../types"
import LegendRow from "../legendRow/legendRow"

interface LegendRowsProps {
  rows: RowItem[]
}

const LegendRows = ({ rows }: LegendRowsProps): JSX.Element => (
  <>
    {rows.map((rowItem, i, array) => (
      <div
        key={rowItem.label}
        data-e2e={`${getDataE2EAttribute(rowItem.label)}-emissions`}
      >
        <LegendRow rowItem={rowItem} />
        {array.length - 1 !== i && (
          <Divider
            sx={{
              marginBottom: pxToRem(1),
              borderColor: gray50.toString(),
            }}
          />
        )}
      </div>
    ))}
  </>
)

export default LegendRows
