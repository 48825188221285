import React from "react"

import type { ScopeThreeEmissionsData } from "../../../modules/dashboard/components/organizationDashboardScopeSection/organizationDashboardScopeSection"
import { ScopeThreeMetrics } from "./scopeThreeMetrics"
import { ScopeThreeWidgetGraph } from "./scopeThreeWidgetGraph"

export interface IOrganizationDashboardScopeThreeProps {
  data: ScopeThreeEmissionsData
  dataE2e: string
  isLimitedAccessUser: boolean
}
export const OrganizationDashboardScopeThree = ({
  data,
  dataE2e,
  isLimitedAccessUser,
}: IOrganizationDashboardScopeThreeProps): JSX.Element => (
  <>
    <ScopeThreeMetrics
      dataE2e={dataE2e}
      isLimitedAccessUser={isLimitedAccessUser}
      totalEmissions={data.totalEmissions}
    />
    <ScopeThreeWidgetGraph data={data.graphData} />
  </>
)
