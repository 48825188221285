import React from "react"

import { useUserAccess } from "@/services/user"

import { useOrganizationContext } from "../../../contexts/organizationProvider"
import { FeatureFlags, useFeature } from "../../../services"
import Page404 from "../../nav/page404/page404"

interface UserGuardProps {
  children: JSX.Element
}

const UserGuard = ({ children }: UserGuardProps): JSX.Element => {
  const { organization } = useOrganizationContext()
  const { isFeatureEnabled } = useFeature()
  const { isLimitedAccessUser } = useUserAccess()

  const isLimitedAccessUserBasicsEnabled = isFeatureEnabled(
    FeatureFlags.LIMITED_ACCESS_USER_BASICS,
    organization
  )

  if (
    isLimitedAccessUser === false ||
    (isLimitedAccessUser && isLimitedAccessUserBasicsEnabled)
  ) {
    return children
  }

  if (isLimitedAccessUser && !isLimitedAccessUserBasicsEnabled) {
    return <Page404 />
  }

  return null
}

export default UserGuard
