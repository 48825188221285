import { useTranslation } from "react-i18next"

import { useUserSettings } from "../../modules/settings"
import { getCurrencySymbol } from "../../utils/currency"

/**
 * The useCurrencySymbol service returns the currency symbol string that should displayed based on the user's settings
 *
 * @returns The currency symbol string
 * @example
 * const currencySymbol = useCurrencySymbol()
 */
export const useCurrencySymbol = (): string => {
  const { currency } = useUserSettings()
  const { i18n } = useTranslation()
  const symbol = getCurrencySymbol(i18n.language, currency)
  return symbol
}
