import { Attr, BelongsTo, HasMany, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"
import { fetchOrganizationBillingGroupCount } from "./billingGroup"
import type Confirm from "./confirm"
import { fetchOrganizationDepartmentCount } from "./department"
import Locale from "./locale"
import { fetchOrganizationMeterCount } from "./meter"
import { fetchOrganizationEmissionsCount } from "./organizationEmissions"
import type OrganizationalUnit from "./organizationalUnit"
import { fetchOrganizationalUnitCount } from "./organizationalUnit"
import type Site from "./site"
import { fetchOrganizationSiteCount } from "./site"
import { fetchOrganizationEnergyManagerCount } from "./user"

export const CIN_BELL_ID = "15"
export const demoOrganizations: string[] = ["City of Avalon"]

export interface IOrganizationCounts {
  billingGroups: number
  departments: number
  energyManagers: number
  meters: number
  organizationEmissions: number
  organizationalUnits: number
  sites: number
}

@Model()
export class Organization extends ApplicationRecord {
  public static jsonapiType = "organizations"

  @BelongsTo(Locale) public locale: Locale

  @HasMany("confirms") public confirms: Confirm[]

  @HasMany("sites") public sites: Site[]

  @HasMany("organizational_units")
  public organizationalUnits: OrganizationalUnit[]

  @Attr() public readonly name: string

  @Attr() public readonly demoOrganization: boolean

  @Attr() public readonly dateCreated: string

  @Attr() public readonly dateUpdated: string

  @Attr() public localeId: string

  @Attr() public readonly siteIds: number[]

  @Attr() public readonly displayNullState: boolean

  @Attr() public readonly hasBundled: boolean

  @Attr() public readonly isMarketTest: boolean

  @Attr() public readonly notes: string

  @Attr() public readonly hierarchy: string
}

export default Organization

/**
 * Fetches the counts for an organization
 *
 * @param orgId - The organization id
 * @returns - The organization counts
 * @example
 * fetchOrganizationCounts("15")
 */
export const fetchOrganizationCounts = async (
  orgId: string
): Promise<IOrganizationCounts> => {
  const [
    billingGroups,
    departments,
    organizationalUnits,
    meters,
    organizationEmissions,
    sites,
    energyManagers,
  ] = await Promise.allSettled([
    fetchOrganizationBillingGroupCount(orgId),
    fetchOrganizationDepartmentCount(orgId),
    fetchOrganizationalUnitCount(orgId),
    fetchOrganizationMeterCount(orgId),
    fetchOrganizationEmissionsCount(orgId),
    fetchOrganizationSiteCount(orgId),
    fetchOrganizationEnergyManagerCount(orgId),
  ]).then((results) =>
    results.map((result) => {
      if (result.status === "fulfilled") {
        return result.value
      }
      console.error(`Failed to fetch organization count: ${result.reason}`)
      return null
    })
  )

  const counts: IOrganizationCounts = {
    billingGroups,
    departments,
    organizationalUnits,
    meters,
    organizationEmissions,
    sites,
    energyManagers,
  }

  return counts
}
