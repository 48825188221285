import type { FC } from "react"
import React from "react"

import type { Option } from "../../../../nzds"
import { FormNumericTextField } from "../../../../nzds/forms/form-numeric-text-field/form-numeric-text-field"
import { FormSelectField } from "../../../../nzds/forms/form-select-field/form-select-field"
import { FormTextField } from "../../../../nzds/forms/form-text-field/form-text-field"
import { snakeToTitleCase } from "../../utils/snakeToTitleCase"
import { SchemaFieldArray } from "./schema-field-array"
import type { SchemaFieldProps } from "./schema-field.types"

export const SchemaFieldInput: FC<SchemaFieldProps> = ({
  schema,
  name,
  required,
}) => {
  const lastPathString = name.split(".").pop()
  const label = schema.title ?? snakeToTitleCase(lastPathString)
  const width = "calc(100% / 2 - (1rem * 1 / 2))"

  let isFieldRequired = false
  if (lastPathString && required?.includes(lastPathString)) {
    isFieldRequired = true
  }

  if (schema.type === "boolean") {
    return (
      <FormSelectField
        label={label}
        name={name}
        options={
          [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ] as Option[]
        }
        placeholder="Select option"
        required={isFieldRequired}
        sx={{ width }}
      />
    )
  }
  if (schema.type === "integer") {
    return (
      <FormNumericTextField
        label={label}
        name={name}
        NumericFormatProps={{
          decimalScale: 0,
          allowNegative: false,
        }}
        required={isFieldRequired}
        sx={{ width }}
        type="number"
      />
    )
  }
  if (schema.type === "number") {
    return (
      <FormNumericTextField
        label={label}
        name={name}
        NumericFormatProps={{
          decimalScale: 2,
          allowNegative: false,
        }}
        required={isFieldRequired}
        sx={{ width }}
      />
    )
  }
  if (schema.type === "string") {
    if (schema.oneOf && schema.oneOf.length > 0) {
      // render select
      const options = schema.oneOf.map((opt) => ({
        label: typeof opt !== "boolean" ? (opt.title ?? opt.const) : "",
        value: typeof opt !== "boolean" ? opt.const : "",
      }))
      return (
        <FormSelectField
          label={label}
          name={name}
          options={options as Option[]}
          placeholder="Select option"
          required={isFieldRequired}
          sx={{ width }}
        />
      )
    }
    return (
      <FormTextField
        label={label}
        name={name}
        required={isFieldRequired}
        sx={{ width }}
      />
    )
  }

  if (schema.type === "array") {
    return <SchemaFieldArray name={name} schema={schema} />
  }

  // if we get here there's a case we don't have yet :(
  return null
}
