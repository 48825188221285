import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class OrganizationEmissionDay extends ApplicationRecord {
  public static jsonapiType = "organization_emission_days"

  @Attr() public amount: string

  @Attr() public category: string

  @Attr() public kgCo2e: string

  @Attr() public name: string

  @Attr() public scope: string

  @Attr() public subCategory: string

  get parsedKgCo2e() {
    return parseFloat(this.kgCo2e)
  }
}
