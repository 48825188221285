import type { UseQueryOptions } from "react-query"
import { useQuery } from "react-query"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import { ApiQueryName } from "@/models/api"
import type { Operations, Schemas } from "@/services/types"
import { useQueryKey } from "@/services/utils/useQueryKey"

import { getSiteRecommendations } from "./get-site-recommendations"

interface UseGetSiteRecommendationsOptions {
  query: Omit<
    Operations["get_bs_list_api_v1_building_simulations_get"]["parameters"]["query"],
    "site_ids"
  >
  useQueryOptions?: Omit<
    UseQueryOptions<Schemas["BuildSimResponse"][]>,
    "queryKey" | "queryFn"
  >
}

/**
 * Returns a sites list of recommendations
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetSiteRecommendations(227, { query, useQueryOptions });
 */
export const useGetSiteRecommendations = (
  siteId: number,
  options: UseGetSiteRecommendationsOptions
) => {
  const { organization } = useOrganizationContext()

  const queryKey = useQueryKey(
    ApiQueryName.RecommendationsSite,
    "getMany",
    null,
    {
      siteId,
      ...options.query,
    }
  )

  const queryOptions = {
    queryKey,
    queryFn: () =>
      getSiteRecommendations({
        site_ids: [siteId],
        ...options.query,
        organization_id: organization?.id,
      }),
    ...(options.useQueryOptions ?? {}),
  }

  const queryResult = useQuery<Schemas["BuildSimResponse"][]>(queryOptions)

  return queryResult
}
