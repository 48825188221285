import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import { getEnergyConsumption } from "./useEnergyConsumptionUtils"

/**
 * The useEnergyConsumption hook fetches the total energy consumption for a given organization
 *
 * @param orgId - The id of the organization whose dates to get
 * @param dateRange - The date range to get the report dates for
 * @returns The total energy consumption of the top-most user accessible group for the given organization
 *
 * @example
 * ```tsx
 * import { useEnergyConsumption } from '../services'
 *
 * const {
 *   energyConsumption,
 *   isEnergyConsumptionFetched,
 *   isEnergyConsumptionLoading
 * } = useEnergyConsumption(orgId, { start: moment(), end: moment() })
 * ```
 */
export const useEnergyConsumption = (
  orgId: string,
  dateRange: IRange<Moment>
) => {
  const startDate = dateRange?.start?.format("YYYY-MM-DD")
  const endDate = dateRange?.end?.format("YYYY-MM-DD")
  const queryKey = useQueryKey(
    ApiQueryName.EnergyConsumption,
    "getOne",
    orgId,
    {
      startDate,
      endDate,

      ignoreCurrency: true,
    }
  )

  const queryFn = () =>
    getEnergyConsumption({
      organizationId: orgId,
      startDate,
      endDate,
    })

  const queryResult = useQuery({
    queryKey,
    queryFn,
    enabled:
      !!orgId && !!dateRange?.start?.isValid() && !!dateRange?.end?.isValid(),
    keepPreviousData: true, // Prevent flash of previous data on date range change
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
