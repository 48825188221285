import { useQuery } from "react-query"

import { ApiQueryName } from "../../models/api"
import { useRenameQueryResult } from "../utils"
import { createQueryKey } from "../utils/createQueryKey"
import type {
  SigmaEmbedSourceType,
  SigmaWorkbook,
  SigmaWorkbookEmbed,
} from "./sigma.types"
import { useSigmaApi } from "./useSigmaApi"

// Filtering is necessary because the workbook
// may have multiple embeds
const filterEmbeds = (
  embeds: SigmaWorkbookEmbed[],
  type: SigmaEmbedSourceType
): SigmaWorkbookEmbed[] => {
  const filteredEmbeds = embeds.filter((embed) => embed.sourceType === type)

  return filteredEmbeds
}

/**
 * Gets the Sigma embeds for the requested workbook
 *
 * @returns The Sigma embeds
 *
 * @example
 * const {
 *   sigmaWorkbookEmbedsData,
 * } = useSigmaWorkbookEmbeds("asdf-1234")
 */
export const useSigmaWorkbookEmbeds = (
  workbookId: SigmaWorkbook["workbookId"] | undefined
) => {
  const { fetchSigmaWorkbookEmbeds, sigmaAccessTokenIsFetched } = useSigmaApi()

  const enabled = sigmaAccessTokenIsFetched && Boolean(workbookId)

  const queryKey = createQueryKey(
    ApiQueryName.SigmaWorkbookEmbeds,
    "getOne",
    workbookId
  )

  const queryResult = useQuery<SigmaWorkbookEmbed[]>({
    enabled,
    queryKey: [queryKey],
    queryFn: () => fetchSigmaWorkbookEmbeds(workbookId),
    select: (data) => {
      return data && filterEmbeds(data, "workbook")
    },
  })

  return useRenameQueryResult(queryResult, ApiQueryName.SigmaWorkbookEmbeds)
}
