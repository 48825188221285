import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import classNames from "classnames"

import { useTheme } from "@mui/material"

import type { CircularGraph, CircularGraphData } from "../models/circularGraph"
import type { UnitName } from "../models/unit"
import { useUserSettings } from "../modules/settings"
import { drawCircularAxisLabels } from "./graph/drawCircularAxisLabels"
import { drawGraphNeedle } from "./graph/drawGraphNeedle"
import { drawOverlayGroup } from "./graph/drawOverlayGroup"
import { drawSemiAnnularGroup } from "./graph/drawSemiAnnularGroup"

export interface DialGraphDataInterFace extends CircularGraphData {
  fill?: string
  stroke?: boolean
  units?: UnitName
}

export interface DialGraphInterface extends CircularGraph {
  data: Map<string, DialGraphDataInterFace>
  domain: [number, number]
  intervals: number
  overlayData: DialGraphDataInterFace
  range: [number, number]
}

export const DialGraph = ({
  graphData,
  children,
}: {
  children?: JSX.Element
  graphData: DialGraphInterface
}): React.ReactElement => {
  const theme = useTheme()
  const { i18n } = useTranslation()
  const userSettings = useUserSettings()
  const ref = useRef()

  const doubledOutline = graphData.outlineThickness * 2
  const outerRadius = graphData.viewWidth / 2 - doubledOutline
  const innerRadius =
    outerRadius - outerRadius / graphData.annulusThicknessRatio

  useEffect(() => {
    // TODO: Will refactor and remove the need for this ref in the future
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ref.current) {
      const currentRef: SVGElement = ref.current
      if (currentRef.childElementCount > 0) {
        currentRef.innerHTML = ""
      }
    }
    drawSemiAnnularGroup({
      svg: ref,
      viewWidth: graphData.viewWidth,
      viewHeight: graphData.viewHeight,
      range: graphData.range,
      data: graphData.data,
      innerRadius,
      outerRadius,
    })
    drawOverlayGroup({
      svg: ref,
      viewWidth: graphData.viewWidth,
      viewHeight: graphData.viewHeight,
      overlayData: graphData.overlayData,
      i18nService: i18n,
      userSettings,
    })
    drawGraphNeedle({
      svg: ref,
      viewWidth: graphData.viewWidth,
      viewHeight: graphData.viewHeight,
      domain: graphData.domain,
      range: graphData.range,
      ratio: graphData.overlayData.ratio,
      palette: theme.palette,
    })
    drawCircularAxisLabels({
      svg: ref,
      domain: graphData.domain,
      range: graphData.range,
      intervals: graphData.intervals,
      innerRadius,
    })
  }, [graphData])
  return (
    <>
      {children}
      <svg
        ref={ref}
        className={classNames(
          `${graphData.classNamePrepend} dial-graph`,
          "dial-graph--dashboard"
        )}
        style={{
          minWidth: graphData.viewWidth,
          minHeight: graphData.viewHeight,
        }}
        viewBox={`0 0 ${graphData.viewWidth} ${graphData.viewHeight}`}
        xmlns="http://www.w3.org/2000/svg"
      />
    </>
  )
}
