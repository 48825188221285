import React from "react"

import { Box } from "@mui/material"

import { DecimalWithPlaceholder } from "../../../../../components/decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { If } from "../../../../../components/if/if"
import { Unit } from "../../../../../components/unit/unit"
import { UnitName } from "../../../../../models/unit"
import DataSummary from "../../dataSummary/dataSummary"

interface ScopeThreeDataPreviewProps {
  hasScopeThreeEmissions: boolean
  isSummaryReport: boolean
  itemCount: number
}

const ScopeThreeDataPreview = ({
  isSummaryReport,
  itemCount,
  hasScopeThreeEmissions,
}: ScopeThreeDataPreviewProps) => {
  const renderPreviewText = (): string => {
    if (!hasScopeThreeEmissions) {
      return "Your organization does not have Scope 3 data in the NZero application for selected range"
    }
    if (isSummaryReport) {
      return "Your CSV download data will be aggregated into scope 3 categories and only include categories for which we have data."
    }
    return "Your CSV download will include all emissions within the selected subcategories for which you have data."
  }

  return (
    <DataSummary>
      <Box component="p" sx={{ mt: 0 }}>
        {renderPreviewText()}
        <If condition={hasScopeThreeEmissions}>
          {" "}
          Data will be shown in{" "}
          <Unit unit={UnitName.MetricTonsOfCarbonDioxideEquivalent} />.
        </If>
      </Box>
      <If condition={hasScopeThreeEmissions}>
        <Box component="p" sx={{ mb: 0 }}>
          <DecimalWithPlaceholder
            replaceZeroWithPlaceholder
            value={itemCount}
          />{" "}
          emissions {isSummaryReport ? "categories" : ""} (
          <DecimalWithPlaceholder
            replaceZeroWithPlaceholder
            value={itemCount}
          />{" "}
          rows)
        </Box>
      </If>
    </DataSummary>
  )
}

export default ScopeThreeDataPreview
