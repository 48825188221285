import React, { useCallback, useMemo, useState } from "react"
import { useMatch, useParams } from "react-router-dom"

import type { BillingGroupCounts } from "@/services/billingGroup"
import {
  useBillingGroup,
  useBillingGroupCounts,
  useBillingGroups,
} from "@/services/billingGroup"
import type { DepartmentCounts } from "@/services/department"
import {
  useDepartment,
  useDepartmentCounts,
  useDepartments,
} from "@/services/department"
import { useDialog } from "@/services/dialog"
import type { MeterCounts } from "@/services/meter"
import { useMeter, useMeterCounts, useMeters } from "@/services/meter"
import { useOrganization } from "@/services/organization"
import type { SiteCounts } from "@/services/site"
import { useSite, useSiteCounts, useSites } from "@/services/site"
import { isNil, sortBy } from "lodash-es"

import { Paper, Stack } from "@mui/material"

import Page404 from "../../../../../components/nav/page404/page404"
import {
  DeveloperAdminPath,
  OrgViewPath,
  RootPath,
  TamAdminPath,
} from "../../../../../models/route"
import type { IOrgViewDisplayCount } from "../../../models/onboarding/onboarding"
import {
  LIST_ITEM_NAME_PROPERTY,
  billingGroupDisplayCounts,
  billingGroupRouteMatchPattern,
  departmentDisplayCounts,
  departmentRouteMatchPattern,
  meterDisplayCounts,
  meterRouteMatchPattern,
  routeMatchPattern,
  siteDisplayCounts,
  siteRouteMatchPattern,
} from "../../../models/onboarding/onboarding"
import { convertToDisplayCounts } from "../../../util/onboarding/onboarding"
import { TamAdminPageHeader } from "../../tamAdminPageHeader/tamAdminPageHeader"
import OrgViewBillingGroupDetail from "./orgViewBillingGroupDetail/orgViewBillingGroupDetail"
import OrgViewContainer from "./orgViewContainer/orgViewContainer"
import OrgViewDeleteDialog from "./orgViewDeleteDialog/orgViewDeleteDialog"
import OrgViewDepartmentDetail from "./orgViewDepartmentDetail/orgViewDepartmentDetail"
import OrgViewDetail from "./orgViewDetail/orgViewDetail"
import OrgViewDetailCreateButton from "./orgViewDetail/orgViewDetailCreateButton/orgViewDetailCreateButton"
import OrgViewDetailEntityDeleteButton from "./orgViewDetail/orgViewDetailEntityDeleteButton/orgViewDetailEntityDeleteButton"
import OrgViewDetailLinkSection from "./orgViewDetailLinkSection/orgViewDetailLinkSection"
import OrgViewDialog from "./orgViewDialog/orgViewDialog"
import OrgViewList from "./orgViewList/orgViewList"
import OrgViewMeterDetail from "./orgViewMeterDetail/orgViewMeterDetail"
import OrgViewOrganizationDetail from "./orgViewOrganizationDetail/orgViewOrganizationDetail"
import OrgViewSiteDetail from "./orgViewSiteDetail/orgViewSiteDetail"

interface IOrgViewDataE2eAttributes {
  billingGroupsValue: string
  departmentsValue: string
  metersValue: string
  sitesValue: string
}

const orgViewE2eAttributes: IOrgViewDataE2eAttributes = {
  departmentsValue: "departments",
  sitesValue: "sites",
  billingGroupsValue: "billing-groups",
  metersValue: "meters",
}

/**
 * @deprecated `OrgView` is being phased out with the new Flexible Hierarchy UI.
 * Any new changes should be made to `OrgLayout` instead.
 */
const OrgView = () => {
  // Route Matches
  const params = useParams()
  const {
    isDialogOpen: isDeleteDialogOpen,
    dialogTitle: deleteDialogTitle,
    dialogContent: deleteDialogContent,
    dialogActions: deleteDialogActions,
    onDialogOpen: onDeleteDialogOpen,
    onDialogClose: onDeleteDialogClose,
  } = useDialog()
  const routeMatch = useMatch(routeMatchPattern)
  const departmentRouteMatch = useMatch(departmentRouteMatchPattern)
  const siteRouteMatch = useMatch(siteRouteMatchPattern)
  const billingGroupRouteMatch = useMatch(billingGroupRouteMatchPattern)
  const meterRouteMatch = useMatch(meterRouteMatchPattern)

  const [filterDepartmentText, setFilterDepartmentText] = useState<string>("")
  const [filterSiteText, setFilterSiteText] = useState<string>("")
  const [filterBillingGroupText, setFilterBillingGroupText] =
    useState<string>("")
  const [filterMeterText, setFilterMeterText] = useState<string>("")

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [dialogTitle, setDialogTitle] = useState<string>("")
  const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null)

  const {
    orgId: organizationId,
    billingGroupId,
    departmentId,
    siteId,
    meterId,
  } = params
  const { organization } = useOrganization(organizationId)
  const { department } = useDepartment(departmentId)

  const { departments, isDepartmentsLoading } = useDepartments({
    sort: { key: "name", order: "asc" },
    // TODO: Verify / fix typing for where clause.
    // migration to strict mode batch disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    where: { organization_id: organizationId } as any,
    disabled: isNil(organizationId),
  })
  const { departmentCounts, isDepartmentCountsLoading } =
    useDepartmentCounts(departmentId)

  const { site } = useSite(siteId)
  const { sites, isSitesLoading } = useSites(organizationId, {
    clause: { department_id: departmentId },
    enabled: !!departmentId,
  })
  const { siteCounts } = useSiteCounts(siteId)

  const { billingGroup } = useBillingGroup(billingGroupId)
  const { billingGroups, isBillingGroupsLoading } = useBillingGroups({
    sort: { key: "groupName", order: "asc" },
    // TODO: Verify / fix typing for where clause.
    // migration to strict mode batch disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    where: { site_id: siteId } as any,
    disabled: isNil(siteId),
  })
  const { billingGroupCounts } = useBillingGroupCounts(billingGroupId)

  const { meter } = useMeter(meterId)
  const { meters, isMetersLoading } = useMeters({
    sort: { key: "meterCode", order: "asc" },
    // TODO: Verify / fix typing for where clause.
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    where: {
      billing_group_id: billingGroupId,
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    disabled: isNil(billingGroupId),
  })
  const { meterCounts } = useMeterCounts(meterId)

  const currentDepartmentDisplayCounts: IOrgViewDisplayCount<DepartmentCounts>[] =
    useMemo(
      () =>
        sortBy(
          convertToDisplayCounts<DepartmentCounts>(
            departmentCounts,
            departmentDisplayCounts
          ),
          "order"
        ),
      [departmentCounts]
    )

  const currentSiteDisplayCounts: IOrgViewDisplayCount<SiteCounts>[] = useMemo(
    () =>
      sortBy(
        convertToDisplayCounts<SiteCounts>(siteCounts, siteDisplayCounts),
        "order"
      ),
    [siteCounts]
  )

  const currentBillingGroupDisplayCounts: IOrgViewDisplayCount<BillingGroupCounts>[] =
    useMemo(
      () =>
        sortBy(
          convertToDisplayCounts<BillingGroupCounts>(
            billingGroupCounts,
            billingGroupDisplayCounts
          ),
          "order"
        ),
      [billingGroupCounts]
    )

  const currentMeterDisplayCounts: IOrgViewDisplayCount<MeterCounts>[] =
    useMemo(
      () =>
        sortBy(
          convertToDisplayCounts<MeterCounts>(meterCounts, meterDisplayCounts),
          "order"
        ),
      [meterCounts]
    )

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filteredDepartmentResults = useMemo(() => {
    if (filterDepartmentText?.length < 1) {
      return departments
    }
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return departments.filter((filteredDepartment) =>
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      filteredDepartment?.name
        .toLowerCase()
        .includes(filterDepartmentText.toLowerCase())
    )
  }, [departments, filterDepartmentText])

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filteredSiteResults = useMemo(() => {
    if (filterSiteText?.length < 1) {
      return sites
    }
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return sites.filter((filteredSite) =>
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      filteredSite?.name.toLowerCase().includes(filterSiteText.toLowerCase())
    )
  }, [sites, filterSiteText])

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filteredBillingGroupResults = useMemo(() => {
    if (filterBillingGroupText?.length < 1) {
      return billingGroups
    }
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return billingGroups.filter((filteredBillingGroup) =>
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      filteredBillingGroup?.groupName
        .toLowerCase()
        .includes(filterBillingGroupText.toLowerCase())
    )
  }, [billingGroups, filterBillingGroupText])

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filteredMeterResults = useMemo(() => {
    if (filterMeterText?.length < 1) {
      return meters
    }
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return meters.filter((filteredMeter) =>
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      filteredMeter?.meterCode
        .toLowerCase()
        .includes(filterMeterText.toLowerCase())
    )
  }, [meters, filterMeterText])

  const onDialogOpen = useCallback(
    (title: string, content: JSX.Element): void => {
      setIsDialogOpen(true)
      setDialogTitle(title)
      setDialogContent(content)
    },
    [setIsDialogOpen, setDialogTitle, setDialogContent]
  )

  const onDialogClose = useCallback((): void => {
    setIsDialogOpen(false)
  }, [setIsDialogOpen])

  const canEntityBeDeleted = (numberOfChildObjects: number) =>
    !numberOfChildObjects

  return (
    <>
      {!organization && <Page404 />}
      {organization && (
        <>
          <TamAdminPageHeader
            isFlexibleHierarchyEnabled={false}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            organizationId={organization?.id}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            organizationName={organization?.name}
          />
          <Stack
            alignItems="stretch"
            direction={{ xs: "column", lg: "row" }}
            spacing={2}
            sx={{
              height: { lg: "592px" },
            }}
          >
            <Paper
              data-e2e="org-view-container"
              sx={{
                flex: 1,
                maxWidth: { lg: "calc(50% - 8px)" },
                overflowX: "auto",
              }}
              variant="outlined"
            >
              <Stack
                direction="row"
                sx={{ height: { lg: "100%", xs: "592px" } }}
              >
                <OrgViewContainer
                  dataE2e={orgViewE2eAttributes.departmentsValue}
                  filterText={filterDepartmentText}
                  isContentPresent={departments?.length > 0}
                  setFilterText={setFilterDepartmentText}
                  title="Departments"
                >
                  <OrgViewList
                    // migration to strict mode batch disable
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    content={filteredDepartmentResults}
                    dataE2e={orgViewE2eAttributes.departmentsValue}
                    isLoading={isDepartmentsLoading}
                    nameProperty={LIST_ITEM_NAME_PROPERTY.Name}
                    selectedItemId={departmentId}
                    url={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}`}
                  />
                </OrgViewContainer>
                <OrgViewContainer
                  dataE2e={orgViewE2eAttributes.sitesValue}
                  filterText={filterSiteText}
                  isContentPresent={sites?.length > 0}
                  setFilterText={setFilterSiteText}
                  title="Sites"
                >
                  {departmentId && (
                    <OrgViewList
                      // migration to strict mode batch disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      content={filteredSiteResults}
                      dataE2e={orgViewE2eAttributes.sitesValue}
                      isLoading={
                        isSitesLoading ||
                        isDepartmentCountsLoading ||
                        isDepartmentsLoading
                      }
                      nameProperty={LIST_ITEM_NAME_PROPERTY.Name}
                      selectedItemId={siteId}
                      url={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}/${departmentId}${OrgViewPath.Site}`}
                    />
                  )}
                </OrgViewContainer>
                <OrgViewContainer
                  dataE2e={orgViewE2eAttributes.billingGroupsValue}
                  filterText={filterBillingGroupText}
                  isContentPresent={billingGroups?.length > 0}
                  setFilterText={setFilterBillingGroupText}
                  title="Billing Groups"
                >
                  {siteId && (
                    <OrgViewList
                      // migration to strict mode batch disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      content={filteredBillingGroupResults}
                      dataE2e={orgViewE2eAttributes.billingGroupsValue}
                      isLoading={isBillingGroupsLoading}
                      nameProperty={LIST_ITEM_NAME_PROPERTY.GroupName}
                      selectedItemId={params.billingGroupId}
                      url={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}/${departmentId}${OrgViewPath.Site}/${siteId}${OrgViewPath.BillingGroup}`}
                    />
                  )}
                </OrgViewContainer>
                <OrgViewContainer
                  dataE2e={orgViewE2eAttributes.metersValue}
                  filterText={filterMeterText}
                  isContentPresent={meters?.length > 0}
                  setFilterText={setFilterMeterText}
                  title="Meters"
                >
                  {billingGroupId && (
                    <OrgViewList
                      // migration to strict mode batch disable
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      content={filteredMeterResults}
                      dataE2e={orgViewE2eAttributes.metersValue}
                      isLoading={isMetersLoading}
                      nameProperty={LIST_ITEM_NAME_PROPERTY.MeterCode}
                      selectedItemId={meterId}
                      url={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}/${departmentId}${OrgViewPath.Site}/${siteId}${OrgViewPath.BillingGroup}/${params.billingGroupId}${OrgViewPath.Meter}`}
                    />
                  )}
                </OrgViewContainer>
              </Stack>
            </Paper>
            <Paper
              sx={{
                flex: 1,
                maxWidth: { lg: "calc(50% - 8px)" },
                padding: (theme) => theme.typography.pxToRem(24),
              }}
              variant="outlined"
            >
              {routeMatch && (
                <OrgViewDetail
                  createButton={
                    <OrgViewDetailCreateButton
                      createObjectName="Department"
                      onDialogClose={onDialogClose}
                      onDialogOpen={onDialogOpen}
                      parentObjectIds={{ organizationId }}
                      redirectPath={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}`}
                    />
                  }
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateCreated={organization?.dateCreated}
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateUpdated={organization?.dateUpdated}
                  datUrl={`${RootPath.DeveloperAdmin}${DeveloperAdminPath.Organization}/${organization?.id}`}
                  entityName="Organization"
                  webAppUrl={`/${organization?.id}/${RootPath.Dashboard}`}
                >
                  <OrgViewOrganizationDetail organizationId={organizationId} />
                </OrgViewDetail>
              )}
              {departmentRouteMatch && (
                <OrgViewDetail
                  createButton={
                    <OrgViewDetailCreateButton
                      createObjectName="Site"
                      onDialogClose={onDialogClose}
                      onDialogOpen={onDialogOpen}
                      parentObjectIds={{ organizationId, departmentId }}
                      redirectPath={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}/${departmentId}${OrgViewPath.Site}`}
                    />
                  }
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateCreated={department?.dateCreated}
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateUpdated={department?.dateUpdated}
                  datUrl={`${RootPath.DeveloperAdmin}${DeveloperAdminPath.Department}/${department?.id}`}
                  deleteButton={
                    <OrgViewDetailEntityDeleteButton
                      canEntityBeDeleted={canEntityBeDeleted(sites?.length)}
                      entityId={departmentId}
                      entityName="Department"
                      isButtonDisabled={isSitesLoading}
                      navLink={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}`}
                      onDeleteDialogClose={onDeleteDialogClose}
                      onDeleteDialogOpen={onDeleteDialogOpen}
                    />
                  }
                  entityName="Department"
                >
                  <OrgViewDepartmentDetail
                    departmentId={departmentId}
                    linkSection={
                      <OrgViewDetailLinkSection
                        counts={currentDepartmentDisplayCounts}
                        // migration to strict mode batch disable
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        id={department?.id}
                      />
                    }
                  />
                </OrgViewDetail>
              )}
              {siteRouteMatch && (
                <OrgViewDetail
                  createButton={
                    <OrgViewDetailCreateButton
                      createObjectName="Billing Group"
                      onDialogClose={onDialogClose}
                      onDialogOpen={onDialogOpen}
                      parentObjectIds={{ siteId }}
                      redirectPath={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}/${departmentId}${OrgViewPath.Site}/${siteId}${OrgViewPath.BillingGroup}`}
                    />
                  }
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateCreated={site?.dateCreated}
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateUpdated={site?.dateUpdated}
                  datUrl={`${RootPath.DeveloperAdmin}${DeveloperAdminPath.Site}/${site?.id}`}
                  deleteButton={
                    <OrgViewDetailEntityDeleteButton
                      canEntityBeDeleted={canEntityBeDeleted(
                        billingGroups?.length
                      )}
                      entityId={siteId}
                      entityName="Site"
                      isButtonDisabled={isBillingGroupsLoading}
                      navLink={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}/${departmentId}`}
                      onDeleteDialogClose={onDeleteDialogClose}
                      onDeleteDialogOpen={onDeleteDialogOpen}
                    />
                  }
                  entityName="Site"
                  webAppUrl={`/${organizationId}/${RootPath.Site}/${site?.id}`}
                >
                  <OrgViewSiteDetail
                    linkSection={
                      <OrgViewDetailLinkSection
                        counts={currentSiteDisplayCounts}
                        id={siteId}
                      />
                    }
                    siteId={siteId}
                  />
                </OrgViewDetail>
              )}
              {billingGroupRouteMatch && (
                <OrgViewDetail
                  createButton={
                    <OrgViewDetailCreateButton
                      createObjectName="Meter"
                      onDialogClose={onDialogClose}
                      onDialogOpen={onDialogOpen}
                      parentObjectIds={{ siteId, billingGroupId }}
                      redirectPath={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}/${departmentId}${OrgViewPath.Site}/${siteId}${OrgViewPath.BillingGroup}/${billingGroupId}${OrgViewPath.Meter}`}
                    />
                  }
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateCreated={billingGroup?.dateCreated}
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateUpdated={billingGroup?.dateUpdated}
                  datUrl={`${RootPath.DeveloperAdmin}${DeveloperAdminPath.BillingGroup}/${billingGroup?.id}`}
                  deleteButton={
                    <OrgViewDetailEntityDeleteButton
                      canEntityBeDeleted={canEntityBeDeleted(meters?.length)}
                      entityId={billingGroupId}
                      entityName="Billing Group"
                      isButtonDisabled={isMetersLoading}
                      navLink={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}/${departmentId}${OrgViewPath.Site}/${siteId}`}
                      onDeleteDialogClose={onDeleteDialogClose}
                      onDeleteDialogOpen={onDeleteDialogOpen}
                    />
                  }
                  entityName="Billing Group"
                >
                  <OrgViewBillingGroupDetail
                    billingGroupId={billingGroupId}
                    linkSection={
                      <OrgViewDetailLinkSection
                        counts={currentBillingGroupDisplayCounts}
                        // migration to strict mode batch disable
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        id={billingGroup?.id}
                      />
                    }
                  />
                </OrgViewDetail>
              )}
              {meterRouteMatch && (
                <OrgViewDetail
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateCreated={meter?.dateCreated}
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dateUpdated={meter?.dateUpdated}
                  datUrl={`${RootPath.DeveloperAdmin}${DeveloperAdminPath.Meter}/${meter?.id}`}
                  deleteButton={
                    <OrgViewDetailEntityDeleteButton
                      canEntityBeDeleted={canEntityBeDeleted(
                        meter?.childMeterNamesAndIds?.length
                      )}
                      entityId={meterId}
                      entityName="Meter"
                      isButtonDisabled={isMetersLoading}
                      navLink={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${organizationId}${OrgViewPath.Department}/${departmentId}${OrgViewPath.Site}/${siteId}${OrgViewPath.BillingGroup}/${params.billingGroupId}`}
                      onDeleteDialogClose={onDeleteDialogClose}
                      onDeleteDialogOpen={onDeleteDialogOpen}
                    />
                  }
                  entityName="Meter"
                >
                  <OrgViewMeterDetail
                    linkSection={
                      <OrgViewDetailLinkSection
                        counts={currentMeterDisplayCounts}
                        // migration to strict mode batch disable
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        id={meter?.id}
                      />
                    }
                    meterId={meterId}
                  />
                </OrgViewDetail>
              )}
            </Paper>
          </Stack>
          <OrgViewDialog
            content={dialogContent}
            onClose={onDialogClose}
            open={isDialogOpen}
            title={dialogTitle}
          />
          <OrgViewDeleteDialog
            actions={deleteDialogActions}
            content={deleteDialogContent}
            onClose={onDeleteDialogClose}
            open={isDeleteDialogOpen}
            title={deleteDialogTitle}
          />
        </>
      )}
    </>
  )
}

export default OrgView
