import React, { useCallback } from "react"

import moment from "moment"
import { Label, XAxis } from "recharts"

import { useTheme } from "@mui/material"

import { useTranslateUnit } from "../../../hooks/useTranslateUnit/useTranslateUnit"
import type { StackedAreaGraphDataPoint } from "../../../nzds"
import {
  GraphMenuContainer,
  StackedAreaGraph,
  getGraphExportFileName,
} from "../../../nzds"
import { CustomLabel } from "./monthly-stacked-area-graph-label"
import { CustomXAxisTick } from "./monthly-stacked-area-graph-tick"
import { formatStackedAreaGraphData } from "./monthly-stacked-area-graph-util"
import type {
  CustomXAxisTickProps,
  MonthlyStackedAreaGraphProps,
} from "./monthly-stacked-area-graph.types"

export const MonthlyStackedAreaGraph: React.FC<
  MonthlyStackedAreaGraphProps
> = ({
  children,
  colors,
  customTooltip,
  exportHiddenElements,
  isTotalHidden,
  keys,
  keysToNotGraph,
  legendAlign,
  legendLayout,
  legendVerticalAlign,
  rawData,
  tooltipLabelsMap,
  title,
  unit,
}: MonthlyStackedAreaGraphProps) => {
  const theme = useTheme()
  const unitLabel: string = useTranslateUnit(unit)
  const data: StackedAreaGraphDataPoint[] = formatStackedAreaGraphData(
    rawData,
    keys,
    unitLabel,
    tooltipLabelsMap
  )

  const exportFileTitle: string = getGraphExportFileName(title, {
    start: moment(rawData[0].date),
    end: moment(rawData[rawData.length - 1].date),
  })

  const yAxisLabel: string = unitLabel

  const renderTick = useCallback(
    (props: CustomXAxisTickProps) => (
      <CustomXAxisTick payload={props.payload} x={props.x} y={props.y} />
    ),
    []
  )
  // 3 x axes to display months, separator line, and years
  const xAxes: React.ReactNode[] = [
    <XAxis
      key="xAxisMonths"
      dataKey="month"
      dx={7}
      dy={0}
      height={10}
      padding={{ left: 0.5 }}
      stroke={theme.palette.grey[300]}
      tick={{ fill: theme.palette.grey[600], fontSize: 10 }}
      tickLine={false}
    />,
    <XAxis
      key="xAxisSeparator"
      axisLine={false}
      dx={7}
      dy={16}
      height={8}
      label={<Label content={<CustomLabel />} position="insideBottomLeft" />}
      padding={{ left: 0.5 }}
      tick={false}
      tickLine={false}
      xAxisId="separator"
    />,
    <XAxis
      key="xAxisYear"
      axisLine={false}
      dataKey="year"
      padding={{ left: 0.5 }}
      tick={renderTick}
      tickLine={false}
      xAxisId="xAxisYear"
    />,
  ]

  return (
    <GraphMenuContainer
      exportHiddenElements={exportHiddenElements}
      fileName={exportFileTitle}
    >
      <StackedAreaGraph
        colors={colors}
        customTooltip={customTooltip}
        data={data}
        isTotalHidden={isTotalHidden}
        keys={keys}
        keysToNotGraph={keysToNotGraph}
        legendAlign={legendAlign}
        legendLayout={legendLayout}
        legendVerticalAlign={legendVerticalAlign}
        xAxes={xAxes}
        yAxisLabel={yAxisLabel}
      >
        {children}
      </StackedAreaGraph>
    </GraphMenuContainer>
  )
}
