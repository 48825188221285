import React from "react"
import type { FallbackProps } from "react-error-boundary"
import { useLocation, useNavigate } from "react-router-dom"

import imgSrc from "@/assets/images/recommendations/city-street.png"

import { Button, Link, Stack, Typography } from "@mui/material"

export const ErrorBoundaryFallback = ({ error }: FallbackProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentUrl = `${location.pathname}${location.search}`

  console.error(error)

  return (
    <Stack
      alignItems="center"
      direction="column"
      justifyContent="center"
      minHeight="calc(100vh - 88px)"
      role="alert"
      spacing={2}
      width="100%"
    >
      <img alt="city street" src={imgSrc as string} width={200} />
      <Typography align="center" color="neutral.main" variant="h1" width="80%">
        Oops&hellip; Something went wrong
      </Typography>
      <Button
        component={Link}
        href={currentUrl}
        onClick={() => {
          navigate(0)
        }}
        variant="contained"
      >
        Refresh Page
      </Button>
    </Stack>
  )
}
