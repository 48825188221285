import React from "react"
import type { FC, ReactNode } from "react"

import { Paper, useTheme } from "@mui/material"

interface WhiteBoxProps {
  children: ReactNode
}

export const WhiteBox: FC<WhiteBoxProps> = ({ children }) => {
  const theme = useTheme()

  return (
    <Paper
      elevation={0}
      sx={{
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: 3,
        p: 8,
      }}
    >
      {children}
    </Paper>
  )
}
