// @ts-strict-ignore
import type { GraphData } from "@/components/graph/graphData"
import type Product from "@/components/graph/product"
import { weatherUrls } from "@/components/weatherIcon"
import helpUrl from "@/icons/help.svg"
import EnergyAllocation from "@/models/energyAllocation"
import ForecastDay from "@/models/forecastDay"
import ForecastHour from "@/models/forecastHour"
import type { LanguageRegion } from "@/models/i18n"
import { MeasurementSystem } from "@/models/measurementSystem"
import { calculateMeteredKwhByProducts } from "@/utils"
import { darkGray, dustyGray } from "@/utils/colors"
import { CENTS_PER_DOLLAR, KWH_PER_MWH, TIME_RANGES } from "@/utils/constants"
import { translateDate } from "@/utils/date"
import { getMeasurementSystem } from "@/utils/measurementSystem"
import { HoverLabelUnit, constructHoverLabel } from "@/utils/timeHelpers"
import type { i18n } from "i18next"
import { max, uniqBy } from "lodash-es"
import moment from "moment"

export const avgCost = (meteredKwh: number, products: Product[]) => {
  const costCents = products.reduce((sum, p) => sum + p.cost, 0)
  return costCents / CENTS_PER_DOLLAR / (meteredKwh / KWH_PER_MWH)
}

export const energyAllocationsToDailyGraphData = (
  energyAllocations: EnergyAllocation[]
): GraphData[] =>
  EnergyAllocation.aggregateByHour(energyAllocations).map(
    ({
      hour,
      hours,
      id,
      serviceHourIds,
      products,
      displayKwh,
      meteredKwh,
    }) => ({
      barLabel: (moment(hour).hour() + 1).toString().padStart(2, "0"),
      barLabelColor: meteredKwh ? darkGray : dustyGray,
      hour,
      hours,
      hoverLabel: constructHoverLabel(moment(hour)),
      id,
      serviceHourIds,
      lineValue: meteredKwh,
      products,
      displayKwh,
      avgCost: avgCost(meteredKwh, products),
      meteredKwh: calculateMeteredKwhByProducts(products),
      co2eLbs: products.reduce((sum, p) => sum + p.co2eLbs, 0),
      carbonIntensity:
        products.reduce((sum, p) => sum + p.co2eLbs, 0) / meteredKwh,
    })
  )

export const energyAllocationsToMultiDayData = (
  energyAllocations: EnergyAllocation[],
  i18nService: i18n
): GraphData[] =>
  EnergyAllocation.aggregateByDay(energyAllocations).map((energyAllocation) => {
    const {
      hour,
      hours,
      id,
      meteredKwh,
      displayKwh,
      products,
      serviceHourIds,
    } = energyAllocation

    const graphDatum: GraphData = {
      barLabel: translateDate(i18nService, hour, {
        day: "numeric",
        month: "numeric",
      }),
      barLabelColor: meteredKwh ? darkGray : dustyGray,
      hours: uniqBy(hours, (hourMoment) => hourMoment.format()),
      hour: moment(hour),
      hoverLabel: constructHoverLabel(moment(hour), HoverLabelUnit.Day),
      id,
      lineValue: meteredKwh,
      products,
      displayKwh,
      serviceHourIds,
      avgCost: avgCost(meteredKwh, products),
      meteredKwh: calculateMeteredKwhByProducts(products),
      co2eLbs: products.reduce((sum, p) => sum + p.co2eLbs, 0),
      carbonIntensity:
        products.reduce((sum, p) => sum + p.co2eLbs, 0) / meteredKwh,
    }

    return graphDatum
  })

export const selectDataGroupByTimeKey = (timeRange: TIME_RANGES) => {
  if (timeRange === TIME_RANGES.MONTH) {
    return () => ""
  }
  return (datum: GraphData) => datum.hour.format("YYY/MM/DD")
}

export const getMaxVolumeDomain = (graphData: GraphData[]): number => {
  if (graphData.length === 0) {
    return NaN
  }

  const totalKwhs = graphData.map((graphDatum) =>
    Math.max(
      Math.abs(graphDatum.lineValue) || 0,
      Math.abs(graphDatum.displayKwh)
    )
  )
  return max(totalKwhs) * 1.1
}

export const getMaxAvgCostDomain = (graphData: GraphData[]): number => {
  if (graphData.length === 0) {
    return NaN
  }

  const avgCosts = graphData.map((datum) => datum.avgCost)
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return (max(avgCosts) || 0) * 2
}

export const getMaxAvgCarbonIntensityDomain = (
  graphData: GraphData[]
): number => {
  if (graphData.length === 0) {
    return NaN
  }

  const avgCarbonIntensities = graphData.map((datum) => datum.carbonIntensity)
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return (max(avgCarbonIntensities) || 0) * 2
}

export const getGraphData = (opts: {
  energyAllocations: EnergyAllocation[]
  i18nService: i18n
  timeRange: TIME_RANGES
}) => {
  const { timeRange, energyAllocations, i18nService } = opts

  if (timeRange === TIME_RANGES.DAY) {
    return energyAllocationsToDailyGraphData(energyAllocations)
  }

  return energyAllocationsToMultiDayData(energyAllocations, i18nService)
}

interface GetWeatherProps {
  activeHour: number | null
  forecastDays: ForecastDay[] | undefined
  forecastHours: ForecastHour[] | undefined
  languageRegion: LanguageRegion
  timeRange: TIME_RANGES
}

interface Weather {
  humidity: string
  icon: unknown
  temp: string | number
}

export const getWeather = ({
  activeHour,
  forecastDays,
  forecastHours,
  timeRange,
  languageRegion,
}: GetWeatherProps): Weather => {
  let temp: number
  const emptyForecast: Weather = {
    humidity: "--",
    icon: helpUrl,
    temp: "--",
  }

  const forecast: ForecastDay | ForecastHour | undefined =
    timeRange === TIME_RANGES.DAY
      ? forecastHours?.find((forecastHour) =>
          moment(activeHour * 1000).isSame(moment(forecastHour.hour))
        )
      : forecastDays?.find((forecastDay) =>
          moment(activeHour * 1000).isSame(moment(forecastDay.day), "day")
        )

  if (!forecast) {
    return emptyForecast
  }

  const isFahrenheit: boolean =
    getMeasurementSystem(languageRegion) === MeasurementSystem.USCU
  const isForecastDay: boolean = forecast instanceof ForecastDay
  const isForecastHour: boolean = forecast instanceof ForecastHour

  if (isForecastDay) {
    const localForecast = forecast as ForecastDay
    temp = isFahrenheit ? localForecast.high : localForecast.highC
  } else if (isForecastHour) {
    const localForecast = forecast as ForecastHour
    temp = isFahrenheit ? localForecast.temp : localForecast.tempC
  }

  return {
    humidity: (
      Math.ceil(forecast.humidity * 100) || emptyForecast.humidity
    ).toString(),
    icon: weatherUrls[forecast.icon] || emptyForecast.icon,
    temp: Math.round(temp) || emptyForecast.temp,
  }
}

export default {
  avgCost,
  energyAllocationsToDailyGraphData,
  energyAllocationsToMultiDayData,
  getMaxVolumeDomain,
  getMaxAvgCostDomain,
  getMaxAvgCarbonIntensityDomain,
  getGraphData,
  getWeather,
  selectDataGroupByTimeKey,
}
