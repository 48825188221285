import type { Context, Dispatch, ReactNode, SetStateAction } from "react"
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

import moment from "moment"
import type { RecordProxy } from "spraypaint/lib-esm/proxies"

import Site from "../models/site"

interface ISiteProviderProps extends Partial<ISiteContext> {
  children: ReactNode
  sitesById?: ISitesById
}

export type ISitesById = Record<string, Site>

interface ISiteContext {
  activeSite: Site | null
  getSiteById: (id: string) => Promise<Site | undefined>
  setActiveSite: Dispatch<SetStateAction<Site | null>>
}

// sitesById is a private data store that must persist between renders
// It's kept alive by a closure and doesn't use "useState" because we don't want to trigger a re-render when it's updated
let sitesById: ISitesById = {}

// Mass lint disable
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const SiteContext: Context<ISiteContext> = createContext(undefined)

/**
 * A component that provides the site context/service
 *
 * @deprecated Use site services instead - either global site services located in services/site or module site services located in modules/site/services
 * @example
 * return (
 *   <SiteProvider>
 *     <Home />
 *   </SiteProvider>
 * )
 */

export const SiteProvider = (props: ISiteProviderProps) => {
  const [activeSite, setActiveSite] = useState<Site | null>(null)

  const customSetActiveSite = useCallback((site: Site | null): void => {
    if (site?.timezone) {
      moment.tz.setDefault(site.timezone)
    }

    setActiveSite(site)
  }, [])

  /**
   * A function that returns the site that matches the provided site id
   *
   * @param id {string} - The site id
   * @returns {Promise<Site | undefined>} - A promise of the site
   * @example
   * getSiteById("1")
   */
  const getSiteById = useCallback(
    async (id: string): Promise<Site | undefined> => {
      if (!id) {
        return undefined
      }

      const cachedSite: Site | undefined = sitesById[id]

      // If we have it in cache, return it
      if (cachedSite) {
        return cachedSite
      }
      // If we don't have it in cache, go get it, store it, and return it

      try {
        const response: RecordProxy<Site> = await Site.includes([
          "organization",
          "address",
          "location",
        ])
          .selectExtra([
            "department_name",
            "load_aggregation_point_id",
            "sq_ft",
            "number_of_floors",
            "building_type",
          ])
          .find(id)
        const site: Site = response.data

        sitesById = {
          ...sitesById,
          [id]: site,
        }

        return site
      } catch (error) {
        console.error(error)
        return undefined
      }
    },
    []
  )

  if (props.sitesById) {
    sitesById = props.sitesById
  }

  const value: ISiteContext = useMemo(
    () => ({
      activeSite,
      getSiteById,
      setActiveSite: customSetActiveSite,
    }),
    [activeSite, customSetActiveSite, getSiteById]
  )

  return (
    <SiteContext.Provider value={value}>{props.children}</SiteContext.Provider>
  )
}

/**
 * @deprecated Use site services instead - either global site services located in services/site or module site services located in modules/site/services
 */

export const useSiteContext = (): ISiteContext => {
  const context = useContext(SiteContext)

  if (context === undefined) {
    throw new Error("useSiteContext must be used within a SiteProvider")
  }

  return context
}
