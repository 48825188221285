import React from "react"
import type { FC } from "react"

import { Box } from "@mui/material"

import type { PageHeaderActionBarProps } from "./page-header-action-bar.types"

/**
 * Used to display action buttons under the page header
 * Use if you need to display action buttons under the page header, and are not passing
 * any actions to the page header itself
 */
export const PageHeaderActionBar: FC<PageHeaderActionBarProps> = ({
  children,
  hasTabs = true,
}) => {
  return (
    <Box
      bgcolor="common.white"
      borderBottom={1}
      borderColor="divider"
      position="sticky"
      px={3}
      py={2}
      top={hasTabs ? 117 : 77}
      zIndex="appBar"
    >
      {children}
    </Box>
  )
}
