import { useCallback } from "react"
import { useSearchParams } from "react-router-dom"

import DateFilter from "@/models/filter/dateFilter"
import type { IRange } from "@/models/range"
import type { Moment } from "moment"

import { buildSearchParamsString } from "../urls"

/**
 *
 * @returns A callback that updates the date range in the URL search params
 */
export const useDateRangeChange = (): ((value: IRange<Moment>) => void) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleDateRangeChange = useCallback(
    (value: IRange<Moment>): void => {
      const newSearchParamsString = buildSearchParamsString(
        {
          start: new DateFilter(value.start).toQueryParamValue(),
          end: new DateFilter(value.end).toQueryParamValue(),
        },
        searchParams
      )
      setSearchParams(newSearchParamsString)
    },
    [searchParams, setSearchParams]
  )

  return handleDateRangeChange
}
