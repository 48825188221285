import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class EiaCalculatedHour extends ApplicationRecord {
  public static jsonapiType = "eia_calculated_hours"

  @Attr() public readonly co2eFactorCalculated: string
}
