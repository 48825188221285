import type { FC } from "react"
import React from "react"

import { HStack } from "@/nzds/layout"

import { ComplianceIcon } from "../compliance-icon"
import type { ComplianceStatusProps } from "./compliance-status.types"

type ComplianceStatus = "--" | "Compliant" | "Non-compliant"

const computeStatus = (isCompliant: boolean | null): ComplianceStatus => {
  if (isCompliant === null) {
    return "--"
  }

  return isCompliant ? "Compliant" : "Non-compliant"
}

export const ComplianceStatus: FC<ComplianceStatusProps> = ({
  isCompliant,
}) => {
  return (
    <HStack alignItems="center" gap={0.5}>
      <ComplianceIcon isCompliant={isCompliant} />
      {computeStatus(isCompliant)}
    </HStack>
  )
}
