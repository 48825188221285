// @ts-strict-ignore
import { useMemo } from "react"

import useMonthlySiteData from "@/services/useMonthlySiteData/useMonthlySiteData"
import type { Moment } from "moment"

import type { IRange } from "../../../../models/range"
import { getMonthlySiteEmissionsGraphData } from "./useMonthlyEmissionsGraphDataUtil"

/**
 * Provide monthly emissions in MT CO2e from natural gas and electricity
 * for a given organization and date range
 *
 * @param orgId  - The organization id to return data for
 * @param dateRange - The range to return data for
 * @param siteId - The ID to restrict results by
 * @example
 *  const { monthlyEmissionsGraphData, isMonthlyEmissionsGraphDataLoading } =
    useMonthlyEmissionsGraphData(
      "1",
      {
        start: moment(),
        end: moment(),
      },
      {
        siteId: "1",
      }
    )
 */

const useMonthlyEmissionsGraphData = (
  orgId: string,
  dateRange: IRange<Moment>,
  { siteId }
): {
  isMonthlyEmissionsGraphDataFetched: boolean
  isMonthlyEmissionsGraphDataFetching: boolean
  isMonthlyEmissionsGraphDataLoading: boolean
  monthlyEmissionsGraphData
} => {
  const {
    monthlySiteData,
    isMonthlySiteDataFetched,
    isMonthlySiteDataFetching,
    isMonthlySiteDataLoading,
  } = useMonthlySiteData(orgId, dateRange, {})

  return useMemo(
    () => ({
      monthlyEmissionsGraphData: getMonthlySiteEmissionsGraphData(
        monthlySiteData,
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        siteId
      ),
      isMonthlyEmissionsGraphDataLoading: isMonthlySiteDataLoading,
      isMonthlyEmissionsGraphDataFetching: isMonthlySiteDataFetching,
      isMonthlyEmissionsGraphDataFetched: isMonthlySiteDataFetched,
    }),
    [
      monthlySiteData,
      siteId,
      isMonthlySiteDataLoading,
      isMonthlySiteDataFetching,
      isMonthlySiteDataFetched,
    ]
  )
}

export { useMonthlyEmissionsGraphData }
