import type { FC } from "react"
import React, { useEffect, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { useParams } from "react-router-dom"

import { Fade, Typography } from "@mui/material"

import type { TokenUser } from "../../utils"
import { getUserByPasswordResetToken, updateUserPassword } from "../../utils"
import { If } from "../if/if"
import { WhiteBox } from "../whiteBox"
import { ResetPasswordForm } from "./reset-password-form/reset-password-form"
import type { ResetPasswordSchema } from "./reset-password-form/reset-password-form.schema"
import { ResetPasswordInvalid } from "./reset-password-invalid/reset-password-invalid"
import { ResetPasswordSuccess } from "./reset-password-success/reset-password-success"

export const ResetPassword: FC = () => {
  const { token } = useParams<{ token: string }>()
  const [user, setUser] = useState<TokenUser | null | undefined>(undefined)
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false)

  const changePassword = async (password: string): Promise<void> => {
    try {
      const data = await updateUserPassword(token, {
        password,
      })

      if (data.success) {
        setIsPasswordReset(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit: SubmitHandler<ResetPasswordSchema> = (value): void => {
    void changePassword(value.password)
  }

  // TODO: Remove useEffect and create/use React Query hook
  // e.g. useResetPasswordAuthentication(token)
  // Fetch user by token
  useEffect(() => {
    if (!token) {
      setUser(null)
      return
    }

    const getUserByToken = async (): Promise<void> => {
      try {
        const fetchedUser = await getUserByPasswordResetToken(token)

        setUser(fetchedUser)
      } catch (error) {
        console.error(error)
      }
    }

    void getUserByToken()
  }, [token])

  return (
    <Fade in={user !== undefined}>
      <div>
        <WhiteBox>
          <Typography mb={4} textAlign="center" variant="h1">
            Reset Password
          </Typography>

          <If condition={user === null}>
            <ResetPasswordInvalid />
          </If>

          <If condition={user && !isPasswordReset}>
            <ResetPasswordForm onSubmit={handleSubmit} user={user} />
          </If>

          <If condition={isPasswordReset}>
            <Fade in={isPasswordReset}>
              <div>
                <ResetPasswordSuccess />
              </div>
            </Fade>
          </If>
        </WhiteBox>
      </div>
    </Fade>
  )
}
