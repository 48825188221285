import { combineReducers } from "@reduxjs/toolkit"

import userSettingsReducer from "../modules/settings/contexts/userSettings.store"

/**
 * The root Redux reducer. All of the app's reducers are combined here.
 */
const rootReducer = combineReducers({
  userSettings: userSettingsReducer,
})

export default rootReducer
