import { useMemo } from "react"

import { useOrganizationalUnits } from "@/services/organizationalUnit"
import { useSites } from "@/services/site"

import { useOrganizationalHierarchy } from "./use-organizational-hierarchy"
import {
  convertSitesToTreeNodes,
  flattenOrganizationalUnits,
  generateDataGridRows,
} from "./utils"

/**
 * This hook generates an array of rows that are used to populate our MUI Tree data grid.
 * The tree is generated from the hierarchy attribute of the organization, which is hydrated
 * with OU and Site models from spraypaint. The resulting array of tree data represents the
 * organizational hierarchy with all of its sites and OUs, excluding the root OU.
 * @param organizationId
 * @returns An array of rows used to populate our `<OrganizeDataGrid />` component.
 * @tutorial https://mui.com/x/react-data-grid/tree-data/
 */
export const useHierarchyTreeData = (organizationId: string) => {
  const { organizationHierarchy, isOrganizationHierarchyLoading } =
    useOrganizationalHierarchy(organizationId)
  const { organizationalUnits, isOrganizationalUnitsLoading } =
    useOrganizationalUnits(organizationId)
  const { sites, isSitesLoading } = useSites(organizationId)

  return useMemo(() => {
    // migration to strict mode batch disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const rootOU = organizationalUnits?.find((ou) => !ou.parentId)
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const rootID = rootOU?.id
    // Need to make a deep copy of the hierarchy so that subsequent calls to this hook
    // do not use the same object references as a starting point when rebuilding the tree.
    const flattenedHierarchy = structuredClone(
      flattenOrganizationalUnits(organizationHierarchy)
    )
    const siteNodes = convertSitesToTreeNodes(sites)

    // Here we take our sites, which have been converted to tree nodes, and add
    // them to the appropriate OU's children in our flattened OU array. If the site does not have a parent,
    // or it belongs to the root OU, then we push it to the flattened array, representing the top level of the tree.
    siteNodes.forEach((siteNode) => {
      const siteParent = flattenedHierarchy.find(
        (node) => node.type === "ou" && node.id === siteNode.parent_id
      )
      if (
        siteParent &&
        !siteParent.children.some(
          (node) => node.type === "site" && node.id === siteNode.id
        )
      ) {
        siteParent.children.push(siteNode)
      } else if (
        !flattenedHierarchy.some(
          (node) => node.type === "site" && node.id === siteNode.id
        )
      ) {
        flattenedHierarchy.push(siteNode)
      }
    })
    // Now that our sites have been added to the flattened hierarchy, we want to filter out
    // any nodes in the array that do not belong at the top level of the tree. Filtering elements out
    // here does not remove the OU or site from the tree, since these elements are just references to the
    // objects further down in the hierarchy.
    const hierarchyTree = flattenedHierarchy.filter(
      (node) => node.parent_id === Number(rootID) || !node.parent_id
    )

    const rows = generateDataGridRows(
      hierarchyTree,
      organizationId,
      organizationalUnits,
      sites
    )
    return {
      rows,
      isHierarchyLoading:
        isOrganizationHierarchyLoading ||
        isOrganizationalUnitsLoading ||
        isSitesLoading,
    }
  }, [
    organizationHierarchy,
    organizationId,
    organizationalUnits,
    sites,
    isOrganizationHierarchyLoading,
    isOrganizationalUnitsLoading,
    isSitesLoading,
  ])
}
