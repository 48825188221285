/**
 * @param groupSize
 */
export const calculateFillTarget = (groupSize: number) => {
  if (groupSize <= 2) {
    return 24
  }
  if ([7, 24, 30, 31].includes(groupSize)) {
    return groupSize
  }
  return 30
}
