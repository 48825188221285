import { z } from "zod"

import { s } from "../utils/schema"

// This schema currently supports only "create" and "edit" form validation
// It is not ready to be used to generate TypeScript types
export const meterSchema = s.define("meter", {
  name: z.string().nullable().optional(),
  meterCode: z.string(),
  meterType: z.string(),
  meterProvider: z.string(),
  // We would like to use optional() on energyAllocator, but it returns undefined.
  energyAllocator: s.hasOne("energyAllocator"),
  fullMeter: z.boolean(),
  probeMeter: z.boolean(),
  isOnSiteGeneration: z.boolean(),
  isNetMeter: z.boolean(),
  isChildMeter: z.boolean().optional(),
  meterSourceType: s.belongsTo("meterSourceType"),
})
