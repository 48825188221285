import { useCallback } from "react"
import { useSearchParams } from "react-router-dom"

import { buildSearchParamsString } from "../../../utils"

export const usePageScrollChange = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const handleScrollPageChange = useCallback(
    (page: number) => {
      const newSearchParamsString = buildSearchParamsString(
        {
          page,
        },
        searchParams
      )
      setSearchParams(newSearchParamsString, { replace: true })
    },
    [searchParams, setSearchParams]
  )

  return handleScrollPageChange
}
