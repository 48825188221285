import React from "react"
import type { FC } from "react"

import { useAuthentication } from "@/services/authentication"

import Page404 from "../../nav/page404/page404"
import type { ConsultantGuardProps } from "./consultant-guard.types"

export const ConsultantGuard: FC<ConsultantGuardProps> = ({ children }) => {
  const { isConsultantUser } = useAuthentication()

  // Hide UI from consultants
  return <>{isConsultantUser ? <Page404 /> : children}</>
}
