import React from "react"
import { useFormState } from "react-hook-form"

import AddRoundedIcon from "@mui/icons-material/AddRounded"
import { Button } from "@mui/material"

interface CreateEntityButtonProps {
  entityName: string
}

const CreateEntityButton = ({
  entityName,
}: CreateEntityButtonProps): JSX.Element => {
  const { isDirty, isValid } = useFormState()

  return (
    <Button
      disabled={!isDirty || !isValid}
      startIcon={<AddRoundedIcon />}
      type="submit"
      variant="contained"
    >
      Create {entityName}
    </Button>
  )
}

export default CreateEntityButton
