import React from "react"
import type { FC } from "react"

import { Divider, Stack, Typography } from "@mui/material"

import { HStack } from "../../nzds"
import type { SectionHeaderProps } from "./section-header.types"

export const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  divider = true,
  actions,
  sx,
}) => (
  <Stack gap={1.5} sx={sx}>
    <HStack alignItems="center" gap={2} justifyContent="space-between">
      <Typography variant="h3">{title}</Typography>
      {actions ?? null}
    </HStack>
    {divider ? <Divider /> : null}
  </Stack>
)
