import React, { useMemo } from "react"

import { Box, Stack, Typography, useTheme } from "@mui/material"

import { MetricType } from "../../../models/decimal/decimal"
import { Scope } from "../../../models/scope"
import { TYPOGRAPHY_VARIANT } from "../../../models/typography"
import { UnitName } from "../../../models/unit"
import type { ScopeOneEmissionsData } from "../../../modules/dashboard/components/organizationDashboardScopeSection/organizationDashboardScopeSection"
import { EmissionDecimal } from "../../decimal/emissionDecimal/emissionDecimal"
import { MonthlyStackedAreaGraph } from "../../graph/monthly-stacked-area-graph/monthly-stacked-area-graph"
import type {
  AreaGraphMap,
  AreaGraphProperties,
  MonthlyAreaGraphDatum,
} from "../../graph/types"
import { Unit } from "../../unit/unit"
import { OrganizationDashboardMetricTitle } from "../organizationDashboardMetricTitle/organizationDashboardMetricTitle"
import { ScopeReportButton } from "../scopeReportButton/scopeReportButton"
import OrganizationDashboardScopeOneLegend from "./organizationDashboardScopeOneLegend/organizationDashboardScopeOneLegend"

interface OrganizationDashboardScopeOneProps {
  dataE2e: string
  isLimitedAccessUser: boolean
  scopeOneData: ScopeOneEmissionsData
}

const emissionsByScopeTooltipLabelsMap = {
  costMajor: {
    label: "Process",
    metricFormattingComponentId: MetricType.Emission,
  },
  fugitive: {
    label: "Fugitive",
    metricFormattingComponentId: MetricType.Emission,
  },
  "stationary combustion": {
    label: "Stationary Combustion",
    metricFormattingComponentId: MetricType.Emission,
  },
  "mobile combustion": {
    label: "Mobile Combustion",
    metricFormattingComponentId: MetricType.Emission,
  },
}

export const OrganizationDashboardScopeOne = ({
  scopeOneData,
  isLimitedAccessUser,
  dataE2e,
}: OrganizationDashboardScopeOneProps): JSX.Element => {
  const theme = useTheme()

  const { graphKeys, graphData } = scopeOneData

  const colors: string[] = [
    theme.palette.spectrum.blue[300],
    theme.palette.spectrum.blue[700],
    theme.palette.spectrum.blue[50],
    theme.palette.spectrum.blue[500],
  ]

  const scopeOneMap: AreaGraphMap = {
    "mobile combustion": {
      label: "Mobile Combustion",
      color: colors[3],
    },
    "stationary combustion": {
      label: "Stationary Combustion",
      color: colors[2],
    },
    fugitive: {
      label: "Fugitive",
      color: colors[1],
    },
    process: {
      label: "Process",
      color: colors[0],
    },
  }

  // Ensure each graph item returns a number value
  const data = useMemo(
    () =>
      graphData.map((datum) => {
        const newDatum = { ...datum } as MonthlyAreaGraphDatum
        graphKeys.forEach((key) => {
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          newDatum[key] = datum[key] ?? 0
        })
        return newDatum
      }),
    [graphData, graphKeys]
  )

  const legendItems: AreaGraphProperties[] = useMemo(
    () =>
      graphKeys.map((key) => ({
        label: scopeOneMap[key].label,
        color: scopeOneMap[key].color,
      })),
    [graphKeys, scopeOneMap]
  )

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant={TYPOGRAPHY_VARIANT.body2}>
            <OrganizationDashboardMetricTitle
              descriptor={
                <Unit unit={UnitName.MetricTonsOfCarbonDioxideEquivalent} />
              }
              title="Total Emissions"
            />
          </Typography>
          <Typography
            component="span"
            data-e2e={dataE2e}
            variant={TYPOGRAPHY_VARIANT.h2}
          >
            <EmissionDecimal value={scopeOneData.totalEmissions} />
          </Typography>
        </Stack>
        <ScopeReportButton
          isLimitedAccessUser={isLimitedAccessUser}
          scope={Scope.One}
        />
      </Stack>
      <Box height={theme.typography.pxToRem(328)}>
        <MonthlyStackedAreaGraph
          colors={colors}
          keys={[
            "process",
            "fugitive",
            "stationary combustion",
            "mobile combustion",
          ]}
          legendAlign="left"
          legendLayout="horizontal"
          legendVerticalAlign="top"
          rawData={data}
          title="Scope 1"
          tooltipLabelsMap={emissionsByScopeTooltipLabelsMap}
          unit={UnitName.MetricTonsOfCarbonDioxideEquivalent}
        >
          <OrganizationDashboardScopeOneLegend legendItems={legendItems} />
        </MonthlyStackedAreaGraph>
      </Box>
    </>
  )
}
