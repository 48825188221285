import type { JSONSchema6 } from "json-schema"
import type { Entries } from "type-fest"

import type PhysicalDetail from "../../../../models/physcialDetails"

export function convertModelValuesToSchemaValues(
  properties: JSONSchema6["properties"],
  details?: PhysicalDetail["details"]
) {
  if (!properties || !details) {
    return {}
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultValues: Record<string, any> = {}

  const entries = Object.entries(properties) as Entries<typeof properties>
  for (const [key, value] of entries) {
    let convertedValue

    if (typeof value !== "boolean") {
      if (
        value.type === "string" ||
        value.type === "number" ||
        value.type === "integer"
      ) {
        if (value.type !== "string") {
          // integer or number
          // convert string from form back to number explicitly to mirror the opposite happening on building defaults
          // this is reliant on the fact that react-number-format is aware of numeric strings as well
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          convertedValue = details[key]
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              Number(details[key])
            : undefined
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          convertedValue = details[key]
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              details[key]
            : undefined
        }
      }
      if (value.type === "boolean") {
        convertedValue = undefined
        if (key in details && typeof key === "string") {
          const detailsValue = (details as Record<string, string | undefined>)[
            key
          ]
          if (detailsValue === "yes") {
            convertedValue = true
          }
          if (detailsValue === "no") {
            convertedValue = false
          }
        }
      }
      if (value.type === "object") {
        convertedValue = convertModelValuesToSchemaValues(
          value.properties,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          details[key]
        )
      }
      if (
        value.type === "array" &&
        value.items &&
        typeof value.items !== "boolean" &&
        !Array.isArray(value.items) &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        Array.isArray(details[key])
      ) {
        const itemsProperties = value.items.properties
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
        convertedValue = details[key].map((sPd: any) =>
          convertModelValuesToSchemaValues(itemsProperties, sPd)
        )
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      defaultValues[key] = convertedValue
    }
  }

  return defaultValues
}
