import React from "react"
import type { FC } from "react"

import { DateDisplay } from "@/components/date/dateDisplay/dateDisplay"
import moment from "moment"

import type { ComplianceDatesProps } from "./compliance-dates.types"

export const ComplianceDates: FC<ComplianceDatesProps> = ({ regulations }) => {
  return !regulations?.length ? (
    <>--</>
  ) : (
    <>
      {regulations.map((regulation, index) => (
        <span
          key={`${regulation.regulation_name}-${regulation.effective_date}`}
        >
          {index > 0 && ", "}
          {
            // This logic is unnecessarily complicated
            // The api should not be returning effective dates when there is no regulation
            regulation.regulation_name === "No Regulation" ? (
              <>--</>
            ) : (
              <DateDisplay
                value={moment(regulation.effective_date)}
                variant="body2"
              />
            )
          }
        </span>
      ))}
    </>
  )
}
