import React, { useEffect, useMemo, useState } from "react"

import classNames from "classnames"

import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material"
import { Box, Collapse } from "@mui/material"

import { Fee, FeeType } from "../../models/fee"
import type { SitePremiseFinancialSummary } from "../../models/sitePremiseFinancialSummary"
import { UnitName } from "../../models/unit"
import { SOLAR_TO_GRID } from "../../utils/constants"
import { getUnitKeyFromFeeType } from "../../utils/unit"
import { CurrencyWithPlaceholder } from "../currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { DecimalWithPlaceholder } from "../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { OverflowTooltip } from "../overflowTooltip/overflowTooltip"
import { BundledSiteFinancialSummaryCardItem } from "./bundledSiteFinancialSummaryCardItem"
import { BundledSiteFinancialSummaryCardTitle } from "./bundledSiteFinancialSummaryCardTitle"

export const BundledSiteFinancialSummaryCard = ({
  sitePremiseFinancialSummary,
  isExpanded,
}: {
  isExpanded: boolean
  sitePremiseFinancialSummary: SitePremiseFinancialSummary
}) => {
  const [isOpenValues, setIsOpenValues] = useState({})
  const sortedEnergyAllocatorList = useMemo(
    () =>
      sitePremiseFinancialSummary.energyAllocatorList.sort(
        (ea1, ea2) => ea1.displayOrder - ea2.displayOrder
      ),
    [sitePremiseFinancialSummary]
  )

  useEffect(() => {
    const initialValuesIsOpen = {}
    sortedEnergyAllocatorList.forEach(
      (energyAllocator, energyAllocatorIndex) => {
        initialValuesIsOpen[energyAllocatorIndex] = isExpanded
      }
    )
    setIsOpenValues(initialValuesIsOpen)
  }, [sitePremiseFinancialSummary])

  const setIsOpen = (newUniqueId, newisOpen) => {
    setIsOpenValues({
      ...isOpenValues,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [newUniqueId]: newisOpen,
    })
  }

  const isLastTotalVisible = sortedEnergyAllocatorList?.length > 1

  return (
    <div className="financial-summary-panel" style={{ padding: 0 }}>
      <Box
        className="financial-summary-panel__head"
        sx={{ borderTopRightRadius: 8, borderTopLeftRadius: 8 }}
      >
        <h4>
          {sitePremiseFinancialSummary.billingGroupAlias} (Premise{" "}
          {sitePremiseFinancialSummary.billingGroupName})
        </h4>
      </Box>
      {sortedEnergyAllocatorList.map(
        (energyAllocator, energyAllocatorIndex) => {
          const firstVariableFee =
            energyAllocator.variableFees[0] ??
            new Fee({
              feeCost: 0,
              feeName: "",
              feeRate: 0,
              feeType: "",
              feeUsage: 0,
            })

          return (
            <div key={energyAllocatorIndex}>
              {energyAllocator.energyAllocatorType === "solar" && (
                <Box
                  key={energyAllocator.energyAllocatorType}
                  className="financial-summary-panel__item submenu"
                  sx={
                    !isLastTotalVisible && {
                      borderBottomRightRadius: 8,
                      borderBottomLeftRadius: 8,
                    }
                  }
                >
                  <div className="financial-summary-panel__item-title">
                    <Box
                      className="fuel-type-box fuel-type-box__solar financial-summary-panel__item-title-indicator"
                      sx={{
                        borderRadius: 1,
                      }}
                    />
                    <OverflowTooltip>
                      <p className="financial-summary-panel__item-title-text paragraph--small paragraph--small__regular">
                        {energyAllocator.rateScheduleName}
                      </p>
                    </OverflowTooltip>
                  </div>
                  <p className="financial-summary-panel__item-usage paragraph--small paragraph--small__regular">
                    <DecimalWithPlaceholder
                      format={{ precision: 0 }}
                      replaceZeroWithPlaceholder
                      unit={
                        UnitName[
                          getUnitKeyFromFeeType(
                            firstVariableFee.feeType
                          ) as keyof typeof UnitName
                        ]
                      }
                      value={firstVariableFee.feeUsage}
                    />
                  </p>
                  <p className="financial-summary-panel__item-rate paragraph--small paragraph--small__regular">
                    <CurrencyWithPlaceholder
                      format={{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 4,
                      }}
                      unit={
                        UnitName[
                          `Per${getUnitKeyFromFeeType(
                            firstVariableFee.feeType
                          )}` as keyof typeof UnitName
                        ]
                      }
                      value={firstVariableFee.feeRate}
                    />
                  </p>
                  <p className="financial-summary-panel__item-title-total paragraph--small paragraph--small__regular">
                    <CurrencyWithPlaceholder value={firstVariableFee.feeCost} />
                  </p>
                  <div className="financial-summary-panel__item-command" />
                </Box>
              )}
              {energyAllocator.energyAllocatorType === "grid" && (
                <>
                  <Box
                    key={energyAllocator.energyAllocatorType}
                    className="financial-summary-panel__item submenu"
                    onClick={() => {
                      setIsOpen(
                        energyAllocatorIndex,
                        !isOpenValues[energyAllocatorIndex]
                      )
                    }}
                    sx={
                      !isOpenValues[energyAllocatorIndex] && !isLastTotalVisible
                        ? {
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                          }
                        : undefined
                    }
                  >
                    <div className="financial-summary-panel__item-title">
                      {energyAllocator.variableFees.some(
                        (cost) => cost.feeName === SOLAR_TO_GRID
                      ) && (
                        <Box
                          className="fuel-type-box fuel-type-box__solar-to-grid financial-summary-panel__item-title-indicator"
                          sx={{
                            borderRadius: 1,
                          }}
                        />
                      )}
                      <Box
                        className={classNames(
                          "fuel-type-box",
                          "financial-summary-panel__item-title-indicator"
                        )}
                        sx={{
                          backgroundColor: "spectrum.cerulean.300",
                          borderRadius: 1,
                        }}
                      />
                      <OverflowTooltip>
                        <p className="financial-summary-panel__item-title-text paragraph--small paragraph--small__regular">
                          {energyAllocator.rateScheduleName}
                        </p>
                      </OverflowTooltip>
                    </div>
                    <div className="financial-summary-panel__item-title-filler" />
                    <p className="financial-summary-panel__item-title-total paragraph--small paragraph--small__regular">
                      <CurrencyWithPlaceholder value={energyAllocator.total} />
                    </p>
                    <div className="financial-summary-panel__item-command">
                      {isOpenValues[energyAllocatorIndex] ? (
                        <ExpandLessRounded
                          sx={{
                            color: "grey.500",
                            display: "flex",
                          }}
                        />
                      ) : (
                        <ExpandMoreRounded
                          sx={{
                            color: "grey.500",
                            display: "flex",
                          }}
                        />
                      )}
                    </div>
                  </Box>
                  {/* migration to strict mode batch disable */}
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  <Collapse in={isOpenValues[energyAllocatorIndex]}>
                    <BundledSiteFinancialSummaryCardTitle
                      titleName="Variable Cost"
                      titleTotal={
                        energyAllocator.variableFees?.length > 0
                          ? energyAllocator.variableFees.reduce(
                              (sum, item) => sum + Number(item.feeCost),
                              0
                            )
                          : NaN
                      }
                    />
                    {energyAllocator.variableFees.map((variableCost) => (
                      <div
                        key={variableCost.feeName}
                        className="financial-summary-panel__item"
                      >
                        <div className="financial-summary-panel__item-name">
                          {variableCost.feeName === SOLAR_TO_GRID && (
                            <Box
                              className="fuel-type-box fuel-type-box__solar-to-grid financial-summary-panel__item-title-indicator"
                              sx={{
                                borderRadius: 1,
                              }}
                            />
                          )}
                          {variableCost.feeName !== SOLAR_TO_GRID &&
                            variableCost.feeType !== FeeType.Kilowatt && (
                              <Box
                                className={classNames(
                                  "fuel-type-box",
                                  "financial-summary-panel__item-title-indicator"
                                )}
                                sx={{
                                  backgroundColor: "spectrum.cerulean.300",
                                  borderRadius: 1,
                                }}
                              />
                            )}
                          <OverflowTooltip>
                            <p className="financial-summary-panel__item-title-text paragraph--small paragraph--small__regular">
                              {`${energyAllocator.rateScheduleName} (${variableCost.feeName})`}
                            </p>
                          </OverflowTooltip>
                        </div>
                        <p className="financial-summary-panel__item-usage paragraph--small paragraph--small__regular">
                          <DecimalWithPlaceholder
                            format={{ precision: 0 }}
                            replaceZeroWithPlaceholder
                            unit={
                              UnitName[
                                getUnitKeyFromFeeType(
                                  variableCost.feeType
                                ) as keyof typeof UnitName
                              ]
                            }
                            value={variableCost.feeUsage}
                          />
                        </p>
                        <p className="financial-summary-panel__item-rate paragraph--small paragraph--small__regular">
                          <CurrencyWithPlaceholder
                            format={{
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 4,
                            }}
                            unit={
                              UnitName[
                                `Per${getUnitKeyFromFeeType(
                                  variableCost.feeType
                                )}` as keyof typeof UnitName
                              ]
                            }
                            value={variableCost.feeRate}
                          />
                        </p>
                        <p className="financial-summary-panel__item-total paragraph--small paragraph--small__regular">
                          <CurrencyWithPlaceholder
                            value={variableCost.feeCost}
                          />
                        </p>
                        <div className="financial-summary-panel__item-command" />
                      </div>
                    ))}
                    <BundledSiteFinancialSummaryCardTitle
                      titleName="Fixed Cost"
                      titleTotal={
                        energyAllocator.fixedFees?.length > 0
                          ? energyAllocator.fixedFees.reduce(
                              (sum, item) => sum + Number(item.feeCost),
                              0
                            )
                          : NaN
                      }
                    />
                    {energyAllocator.fixedFees.map((fixedCost) => (
                      <BundledSiteFinancialSummaryCardItem
                        key={fixedCost.feeName}
                        feeName={fixedCost.feeName}
                        feeRate={null}
                        feeTotal={fixedCost.feeCost}
                        feeType={fixedCost.feeType}
                        feeUsage={null}
                      />
                    ))}
                    <BundledSiteFinancialSummaryCardTitle
                      titleName="Taxes &amp; Fees"
                      titleTotal={
                        energyAllocator.taxes?.length > 0
                          ? energyAllocator.taxes.reduce(
                              (sum, item) => sum + Number(item.feeCost),
                              0
                            )
                          : NaN
                      }
                    />
                    {energyAllocator.taxes.map((tax) => (
                      <BundledSiteFinancialSummaryCardItem
                        key={tax.feeName}
                        feeName={tax.feeName}
                        feeRate={null}
                        feeTotal={tax.feeCost}
                        feeType={tax.feeType}
                        feeUsage={null}
                      />
                    ))}
                    {!isLastTotalVisible && (
                      <BundledSiteFinancialSummaryCardTitle
                        titleName="Total Estimated Cost"
                        titleTotal={sitePremiseFinancialSummary.energyAllocatorList.reduce(
                          (sumOfTotal, energyAllocatorItem) =>
                            sumOfTotal + Number(energyAllocatorItem.total),
                          0
                        )}
                      />
                    )}
                  </Collapse>
                </>
              )}
            </div>
          )
        }
      )}
      {isLastTotalVisible && (
        <BundledSiteFinancialSummaryCardTitle
          titleName="Total Estimated Cost"
          titleTotal={sitePremiseFinancialSummary.energyAllocatorList.reduce(
            (sumOfTotal, energyAllocatorItem) =>
              sumOfTotal + Number(energyAllocatorItem.total),
            0
          )}
        />
      )}
    </div>
  )
}
