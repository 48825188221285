import React, { useLayoutEffect, useRef } from "react"
import type { ChangeEvent, KeyboardEvent } from "react"

import { TextField } from "@mui/material"
import { useGridApiContext } from "@mui/x-data-grid-pro"
import type { GridValidRowModel } from "@mui/x-data-grid-pro"

import type { DataGridNumberEditCellProps } from "./data-grid-number-edit-cell.types"

export const DataGridNumberEditCell = <TRowData extends GridValidRowModel>({
  allowNegative,
  field,
  hasFocus,
  id,
  value,
}: DataGridNumberEditCellProps<TRowData>) => {
  const apiRef = useGridApiContext()
  const ref = useRef<HTMLInputElement>()

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (!allowNegative && event.key === "-") {
      event.preventDefault()
    }
  }

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value
    void apiRef.current.setEditCellValue({ field, id, value: newValue }, event)
  }

  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current?.focus()
    }
  }, [hasFocus])

  return (
    <TextField
      inputRef={ref}
      inputProps={{
        min: 0,
        style: { textAlign: "right" },
      }}
      onChange={handleValueChange}
      onKeyDown={handleKeyDown}
      type="number"
      value={value ?? "0"}
      variant="outlined"
    />
  )
}
