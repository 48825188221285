import React from "react"

import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material"

import { Order } from "../../../../../../models/sort"
import type { ITableColumn } from "../../../../../../models/table"
import { TYPOGRAPHY_VARIANT } from "../../../../../../models/typography"
import { lighterGray, solidGray } from "../../../../../../utils/colors"
import type {
  ITamAdminOrganizationsTableRow,
  TamAdminOrganizationsColumnKey,
} from "../../../../models/onboarding/onboarding"

interface ITamAdminOrganizationTableHeaderProps {
  columns: ITableColumn<ITamAdminOrganizationsTableRow>[]
  isLoading: boolean
  onSort: (property: TamAdminOrganizationsColumnKey) => void
  order: Order
  orderBy: TamAdminOrganizationsColumnKey
}
const TamAdminOrganizationTableHeader = ({
  order,
  orderBy,
  columns,
  isLoading,
  onSort,
}: ITamAdminOrganizationTableHeaderProps): JSX.Element => (
  <TableHead>
    <TableRow>
      {columns.map((col) => (
        <TableCell
          key={col.key}
          sx={{
            background: lighterGray.toString(),
            color: solidGray.toString(),
          }}
        >
          <TableSortLabel
            active={orderBy === col.key}
            data-testid={col.key}
            direction={orderBy === col.key ? order : Order.asc}
            onClick={
              isLoading
                ? undefined
                : () => {
                    onSort(col.key as TamAdminOrganizationsColumnKey)
                  }
            }
          >
            <Typography variant={TYPOGRAPHY_VARIANT.h5}>{col.title}</Typography>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
)

export default TamAdminOrganizationTableHeader
