import React from "react"
import type { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

import { Box, Button, Typography } from "@mui/material"

import { RootPath } from "../../../models/route"

export const ResetPasswordInvalid: FC = () => {
  return (
    <Box textAlign="center">
      <Typography mb={4}>
        This URL is invalid. Please try resetting your password again.
      </Typography>
      <Button
        color="primary"
        component={RouterLink}
        to={RootPath.ForgotPassword}
        variant="contained"
      >
        Forgot Password
      </Button>
    </Box>
  )
}
