import React from "react"
import type { FC } from "react"

import { useFormatDecimal } from "@/formatters/useFormatDecimal"

import type { ComplianceTotalEmissionsProps } from "./compliance-total-emissions.types"

export const ComplianceTotalEmissions: FC<ComplianceTotalEmissionsProps> = ({
  regulations,
}) => {
  const { format: formatWholeNumber } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  return !regulations?.length ? (
    <>--</>
  ) : (
    <>
      {regulations.map((regulation, index) => (
        <span
          key={`${regulation.regulation_name}-${regulation.effective_date}`}
        >
          {index > 0 && ", "}
          {formatWholeNumber(regulation.total_emissions_mt_co2e)}
        </span>
      ))}
    </>
  )
}
