import { metrics } from "../../../../models/metric"
import { resources } from "../../../../models/resource"
import type { ResourceSummaryReportTableColumn } from "../../models/resourceSummary"
import {
  ResourceSummaryReportColumn,
  resourceSummaryReportColumns,
} from "../../models/resourceSummary"

/**
 * Returns the resource summary report columns that are currently visible
 *
 * @param resourceIds - The ids of the active resources
 * @param metricIds - The ids of the active metrics
 * @param electricityEmissionsFactorId - The id of the active electricity emissions factor record
 * @returns The list of visible resource summary report columns
 * @example
 *   getVisibleColumns([1, 2, 3], [1, 2], "1")
 */
export const getVisibleColumns = (
  resourceIds: string[],
  metricIds: string[],
  electricityEmissionsFactorId: string,
  isUseFlexibleHierarchyEnabled: boolean // TO-DO: Remove this prop when we delete the use_flexible_hierarchy_for_site_ownerships feature flag
): ResourceSummaryReportTableColumn[] => {
  const selectedResources = resourceIds.map((id) =>
    resources.find((resource) => resource.id === id)
  )
  const selectedMetrics = metricIds.map((id) =>
    metrics.find((metric) => metric.id === id)
  )

  const newVisibleStatColumns: ResourceSummaryReportTableColumn[] =
    resourceSummaryReportColumns.filter((column) => {
      const hasAResource = !!column.metadata?.resource
      const hasAMetric = !!column.metadata?.metric
      const hasElectricityEmissionsRecord =
        !!column.metadata?.electricityEmissionsFactor
      const resourceIsSelected = !!selectedResources.find(
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (resource) => resource?.id === column.metadata?.resource?.id
      )
      const metricIsSelected = !!selectedMetrics.find(
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (metric) => metric?.id === column.metadata?.metric?.id
      )
      const electricityEmissionsRecordIsSelected =
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        column.metadata?.electricityEmissionsFactor?.id ===
        electricityEmissionsFactorId

      if (
        hasElectricityEmissionsRecord &&
        !electricityEmissionsRecordIsSelected
      ) {
        return false
      }

      if (
        hasAResource &&
        resourceIsSelected &&
        hasAMetric &&
        metricIsSelected
      ) {
        return true
      }
      if (!hasAMetric && hasAResource && resourceIsSelected) {
        return true
      }
      if (!hasAResource && hasAMetric && metricIsSelected) {
        return true
      }
      return false
    })

  return [
    ResourceSummaryReportColumn.siteName,
    ResourceSummaryReportColumn.siteId,
    isUseFlexibleHierarchyEnabled
      ? ResourceSummaryReportColumn.organizationalUnitName
      : ResourceSummaryReportColumn.departmentName,
    ...newVisibleStatColumns,
  ]
}

export default {
  getVisibleColumns,
}
