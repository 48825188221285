import type { SxProps } from "@mui/material"

import type { UseBlurProps } from "./use-blur.types"

export const useBlur = ({
  blurRadius = 4,
  isBlurred = true,
}: UseBlurProps): SxProps => {
  return {
    filter: isBlurred ? `blur(${blurRadius}px)` : "none",
    pointerEvents: isBlurred ? "none" : "auto",
  }
}
