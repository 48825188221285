import type { ReactElement } from "react"
import React from "react"

import { Unit } from "../../../../../components/unit/unit"
import { useSearchParams } from "../../../../../hooks/useSearchParams/useSearchParams"
import { GhgWeightUnit, UnitName } from "../../../../../models/unit"

const SEARCH_PARAMS = [{ param: "weightUnit" }]

interface ResourceMetricDisplayUnitProps {
  unit: UnitName | string
}

/**
 * Displays the expected unit. If it's a GHG weight type and the display unit type is set to `MT`,
 * display 'MT' as the weight unit.
 *
 * @param props - The component's props
 * @param props.unit - A UnitName (auto converted) or string representing the unit
 * @returns The display unit component
 */
const ResourceMetricDisplayUnit = ({
  unit,
}: ResourceMetricDisplayUnitProps): ReactElement => {
  const { weightUnit } = useSearchParams(SEARCH_PARAMS)

  const displayUnit =
    weightUnit === GhgWeightUnit.Mt &&
    unit === UnitName.PoundsOfCarbonDioxideEquivalent
      ? UnitName.MetricTon
      : unit

  return <Unit unit={displayUnit as UnitName} />
}

export default ResourceMetricDisplayUnit
