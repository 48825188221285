import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class PhysicalDetail extends ApplicationRecord {
  public static jsonapiType = "physical_details"

  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Attr() public details?: any

  @Attr() public subjectType: string // for v1 just 'Site'

  @Attr() public physicalDetailType?: string

  @Attr() public physicalDetailTypeId?: string

  @Attr() public readonly subjectId: string // for v1 make the site.id
}
