import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { CurrencyCode } from "../../../models/currencyCode"
import { defaultCurrencyCode } from "../../../models/currencyCode"
import type { LanguageRegion } from "../../../models/i18n"

export const getUserSettingsSelector = (
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: Record<string, any>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
): IUserSettingsData => state.userSettings

export interface IUserSettingsData {
  currency: CurrencyCode
  hasLoadedSupportedCurrencies: boolean
  isLoadingSupportedCurrencies: boolean
  supportedCurrencies: CurrencyCode[]
}

const initialState: IUserSettingsData = {
  currency: defaultCurrencyCode,
  hasLoadedSupportedCurrencies: false,
  isLoadingSupportedCurrencies: false,
  supportedCurrencies: [],
}

export type ChangeCurrencyAction = PayloadAction<CurrencyCode>
export type SetInitialUserCurrencyAction = PayloadAction<CurrencyCode>
export type ChangeLanguageAction = PayloadAction<LanguageRegion>
export type SetInitialUserLanguageAction = PayloadAction<LanguageRegion>

const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    changeCurrency: (state, action: ChangeCurrencyAction) => {
      state.currency = action.payload
    },
    setInitialUserCurrency: (state, action: SetInitialUserCurrencyAction) => {
      state.currency = action.payload
    },
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeLocale: (_state, _action: ChangeLanguageAction) => {
      // State is not modified, this used to execute a side effect (saga)
    },
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setInitialUserLocale: (_state, _action: SetInitialUserLanguageAction) => {
      // State is not modified, this used to execute a side effect (saga)
    },
    loadSupportedCurrencies: (state) => {
      state.isLoadingSupportedCurrencies = true
    },
    loadSupportedCurrenciesFail: (state) => {
      state.isLoadingSupportedCurrencies = false
    },
    loadSupportedCurrenciesSuccess: (
      state,
      action: PayloadAction<CurrencyCode[]>
    ) => {
      state.isLoadingSupportedCurrencies = false
      state.hasLoadedSupportedCurrencies = true
      state.supportedCurrencies = action.payload
    },
    setUserSettings: (state, action: PayloadAction<IUserSettingsData>) => {
      state.currency = action.payload.currency
      state.hasLoadedSupportedCurrencies =
        action.payload.hasLoadedSupportedCurrencies
      state.isLoadingSupportedCurrencies =
        action.payload.isLoadingSupportedCurrencies
      state.supportedCurrencies = action.payload.supportedCurrencies
    },
  },
})

export const {
  changeCurrency,
  changeLocale,
  loadSupportedCurrencies,
  loadSupportedCurrenciesFail,
  loadSupportedCurrenciesSuccess,
  setInitialUserLocale,
  setInitialUserCurrency,
  setUserSettings,
} = userSettingsSlice.actions

export default userSettingsSlice.reducer
