import createSagaMiddleware from "@redux-saga/core"
import type { Reducer } from "@reduxjs/toolkit"
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import type { i18n as Ii18n } from "i18next"
import type { Saga } from "redux-saga"

import type { EnvironmentName } from "../models/environment.types"
import rootReducer from "./reducers"
import rootSaga from "./sagas"

/**
 * The available Saga context keys
 */
export enum SagaContextKeys {
  i18n = "i18n",
}

export interface SagaContext {
  i18n?: Ii18n
}

interface ICreateStoreOptions {
  reducers?: Record<string, Reducer>
  saga?: Saga
  sagaMiddlewareContext?: SagaContext
}

/**
 * Creates a new Redux store optionally configured with a root reducer, root saga, and saga
 * middleware
 *
 * @param options - The configuration options
 * @param options.reducers - A collection of reducers to override the app's root reducer
 * @param options.saga - A root saga to override the app's root saga
 * @param options.sagaMiddlewareContext - A saga context object to override the app's saga middleware
 * @returns A configured redux store
 */
const createStore = (options?: ICreateStoreOptions) => {
  const sagaMiddleware = createSagaMiddleware({
    context: options?.sagaMiddlewareContext
      ? options.sagaMiddlewareContext
      : {},
  })

  const reducer = options?.reducers
    ? combineReducers(options.reducers)
    : rootReducer

  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false, thunk: false }).concat(
        sagaMiddleware
      ),
    devTools:
      process?.env?.REACT_APP_ENV !== ("fed" satisfies EnvironmentName) &&
      process?.env?.REACT_APP_ENV !== ("production" satisfies EnvironmentName),
  })

  sagaMiddleware.run(options?.saga ? options.saga : rootSaga)

  return store
}

export default createStore
