import type { FC } from "react"
import React from "react"

import { useDeleteOrganizationalUnit } from "@/services/organizationalUnit"
import { useConfirm } from "material-ui-confirm"

import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded"
import { Button } from "@mui/material"

import type OrganizationalUnit from "../../../../../models/organizationalUnit"
import type { DeleteGroupsButtonProps } from "./delete-groups-button.types"

export const DeleteGroupsButton: FC<DeleteGroupsButtonProps> = ({
  selectedRows,
}) => {
  const confirm = useConfirm()
  const { mutateAsync } = useDeleteOrganizationalUnit()

  const rowsElibleForDelete = selectedRows.filter((row) => row.type === "ou")
  const selectedRowsContainSites = selectedRows.some(
    (row) => row.type === "site"
  )
  const deleteButtonDisabled =
    selectedRowsContainSites || rowsElibleForDelete.length === 0

  const confirmThenDeleteGroups = async (orgUnits: OrganizationalUnit[]) => {
    await confirm({
      title: `Permanently delete groups?`,
      description: "Deleting groups cannot be undone.",
      confirmationText: "Delete",
      confirmationButtonProps: {
        variant: "contained",
        color: "error",
        endIcon: <DeleteRoundedIcon />,
      },
      cancellationText: "Cancel",
      cancellationButtonProps: { variant: "text", color: "neutral" },
    })
    const deletePromises = orgUnits.map((ou) => mutateAsync(ou.id))
    await Promise.all(deletePromises)
  }

  const preventDeleteGroups = async () => {
    await confirm({
      title: `Cannot be deleted`,
      description:
        "One or more groups contain child elements. To delete these groups, you must remove all child elements.",
      confirmationText: "Close",
      hideCancelButton: true,
      confirmationButtonProps: {
        variant: "text",
        color: "primary",
      },
    })
  }

  const handleClick = () => {
    let canDeleteOUs = true
    rowsElibleForDelete.forEach((row) => {
      if (row.children.length) {
        canDeleteOUs = false
      }
    })

    if (!canDeleteOUs) {
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      preventDeleteGroups()
    } else {
      const orgUnits = rowsElibleForDelete.map(
        (row) => row.model
      ) as OrganizationalUnit[]
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      confirmThenDeleteGroups(orgUnits)
    }
  }

  return (
    <Button
      color="error"
      disabled={deleteButtonDisabled}
      onClick={handleClick}
      size="small"
      startIcon={<DeleteRoundedIcon />}
      variant="text"
    >
      Delete Selected Groups
    </Button>
  )
}
