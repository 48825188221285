// @ts-strict-ignore
import moment from "moment"
import type { StatsScope, WhereClause } from "spraypaint"

import { monthlyArray } from "../../../../components/organizationSummary/organizationSummary"
import SiteEmissionDay from "../../../../models/siteEmissionDay"
import { sharedDropdownFormat as ngDateFormat } from "../../../../utils/formatters"

export const fetchNaturalGasAvailableMonths = async (
  siteId: string
): Promise<moment.Moment[]> => {
  try {
    const whereClause: WhereClause = {
      site_id: siteId,
      ghg_emissions_factor: {
        eq: {
          category: "Stationary Combustion",
          sub_category: "Natural Gas",
          unit: "mmbtu",
        },
      },
    }
    const statsScope: StatsScope = {
      available_months: ["minimum", "maximum"],
    }

    const getMetaMonths = (meta): moment.Moment[] => {
      const monthArray = monthlyArray(
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        moment(meta.stats.available_months.minimum, ngDateFormat),
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        moment(meta.stats.available_months.maximum, ngDateFormat)
      )
      return monthArray
    }
    return await SiteEmissionDay.where(whereClause)
      .stats(statsScope)
      .all()
      .then(({ meta }) => getMetaMonths(meta))
  } catch {
    return null
  }
}
