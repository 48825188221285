import MonthSummary from "../../../../models/monthSummary"
import type { IRange } from "../../../../models/range"

export const fetchMonthSummaries = async (
  siteId: string,
  dateRange: IRange<string>
): Promise<MonthSummary[]> => {
  const fetchedMonthlySummaries = await MonthSummary.where({
    siteId,
    startDate: dateRange.start,
    endDate: dateRange.end,
  })
    .all()
    .then((result) => result.data)
  return fetchedMonthlySummaries
}
