import React from "react"
import type { FC } from "react"

import { PageCard } from "@/components/page-card"
import { FONT_WEIGHT } from "@/models/typography"

import { Box, Typography } from "@mui/material"

export const DataUploadRoute: FC = () => {
  return (
    <PageCard>
      <Box mx="auto" my={12} sx={{ maxWidth: 480, textAlign: "center" }}>
        <Typography fontWeight={FONT_WEIGHT.bold} mb={3} variant="h2">
          Self service data uploads are coming soon!
        </Typography>
        <Typography>
          You&apos;ll be able to upload your building data, resource
          consumption, and emissions with the click of a button.
        </Typography>
      </Box>
    </PageCard>
  )
}
