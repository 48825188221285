import type { FC } from "react"
import React from "react"
import { Link, Navigate, Outlet, useLocation } from "react-router-dom"

import { DataGuard } from "@/components/data-guard"
import { PageHeader } from "@/components/nav/page-header"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { SitePath } from "@/models/route"
import { useSitePreviews } from "@/services/useSitePreviews/useSitePreviews"
import { useUrl } from "@/utils/url"

import { Tab, Tabs } from "@mui/material"

export const SitesIndexRoute: FC = () => {
  const { buildSitesUrl, sitesPath } = useUrl()
  const { organization } = useOrganizationContext()
  const { sitePreviewsData, sitePreviewsIsFetched } = useSitePreviews(
    organization?.id
  )
  const { pathname } = useLocation()

  if (pathname === sitesPath) {
    return <Navigate to={SitePath.Overview} replace={true} />
  }

  return (
    <>
      <PageHeader
        navigation={
          sitePreviewsData?.length ? (
            <Tabs
              aria-label="Site Explorer navigation"
              role="navigation"
              sx={{ px: 3 }}
              value={pathname}
            >
              <Tab
                component={Link}
                label="Overview"
                to={SitePath.Overview}
                value={buildSitesUrl(SitePath.Overview)}
              />
              <Tab
                component={Link}
                label="Performance"
                to={SitePath.Performance}
                value={buildSitesUrl(SitePath.Performance)}
              />
              <Tab
                component={Link}
                label="Compliance"
                to={SitePath.Compliance}
                value={buildSitesUrl(SitePath.Compliance)}
              />
            </Tabs>
          ) : null
        }
        title="Site Explorer"
      />
      <DataGuard
        hasData={Boolean(sitePreviewsData?.length)}
        isLoading={!sitePreviewsIsFetched}
      >
        <Outlet />
      </DataGuard>
    </>
  )
}
