// @ts-strict-ignore
import EiaCalculatedHour from "../../../../models/eiaCalculatedHour"

export interface EiaStats {
  gridRate: number
}

export const fetchEia = (site, yearStart, dayStart) =>
  EiaCalculatedHour.where({
    // migration to strict mode batch disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    ba_code: site.baCode,
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    start_time: yearStart,
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    end_time: dayStart,
  })
    .stats({
      co2e_factor_calculated: "average",
    })
    .all()
    .then((promise) =>
      // Mass lint disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      parseFloat(promise.meta.stats.co2e_factor_calculated.average)
    )
