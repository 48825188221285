// @ts-strict-ignore
import type { UseQueryResult } from "react-query"
import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type moment from "moment"

import { ApiQueryName } from "../../../../models/api"
import type Site from "../../../../models/site"
import { LBS_PER_METRIC_TON } from "../../../../utils/constants"
import type { EiaStats } from "./useEiaUtils"
import { fetchEia } from "./useEiaUtils"

interface UseEiaProps {
  endDate: moment.Moment
  site: Site
  startDate: moment.Moment
}

export const useEia = ({ site, startDate, endDate }: UseEiaProps) => {
  const queryKey = useQueryKey(ApiQueryName.Eia, "getOne", null, {
    site,
    startDate,
    endDate,
  })

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => fetchEia(site, startDate.format(), endDate.format()),
    enabled:
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!site && !!startDate && !!endDate && !!startDate?.isBefore(endDate),
  })

  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const data = queryResult?.data

  // TODO: Remove the type conversion when we have this properly typed
  const filteredQueryResult = {
    ...queryResult,
    data: {
      gridRate: data / LBS_PER_METRIC_TON,
    },
  } as UseQueryResult<EiaStats>
  return useRenamedQueryResult(queryKey, filteredQueryResult)
}
