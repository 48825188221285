// @ts-strict-ignore
import type { FC } from "react"
import React from "react"
import { useTranslation } from "react-i18next"

import { Box, Stack, Typography, styled } from "@mui/material"

import { useInterventionTypeColors } from "../../services/useInterventionTypeColors/use-intervention-type-colors"
import { useInterventionTypeLabels } from "../../services/useInterventionTypeLabels/use-intervention-type-labels"
import { BreakdownTooltip } from "./components/tooltip/tooltip"
import type { PlanDetailsSavingsBreakdownProps } from "./plan-details-savings-breakdown.types"

const DataGroup = styled(Box)`
  ${({ theme }) => `
  border-radius: 2px;
  padding: ${theme.spacing(0.25)};
  display: flex;
  transition: ${theme.transitions.create(["background-color"], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  })};
  `}
`

export const PlanDetailsSavingsBreakdown: FC<
  PlanDetailsSavingsBreakdownProps
> = ({ breakdown }) => {
  const typeToColors = useInterventionTypeColors()
  const labelMap = useInterventionTypeLabels()

  const { t } = useTranslation()

  let breakdownRemaining = 1
  for (const dataGroup of breakdown) {
    if (dataGroup.value > breakdownRemaining) {
      breakdownRemaining = 0
      break
    }
    breakdownRemaining -= Number(dataGroup.value.toPrecision(2))
  }

  return (
    <Stack gap={3}>
      <Stack alignItems="center" direction="row" gap={2}>
        {breakdown.map((group) => (
          <Stack key={group.type} alignItems="center" direction="row" gap={1}>
            <Box
              bgcolor={typeToColors[group.type].foreground}
              borderRadius={0.5}
              height="1rem"
              width="1rem"
            />
            <Typography variant="body2">{labelMap[group.type]}</Typography>
            <Typography variant="body2">
              {t("common:percentage", {
                value: group.value,
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
        ))}
        {breakdownRemaining > 0 ? (
          <Stack alignItems="center" direction="row" gap={1}>
            <Box
              bgcolor="grey.200"
              borderRadius={0.5}
              height="1rem"
              width="1rem"
            />
            <Typography variant="body2">Remaining</Typography>
            <Typography variant="body2">
              {t("common:percentage", {
                value: breakdownRemaining,
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
        ) : null}
      </Stack>

      <Stack direction="row" gap={0.25}>
        {breakdown.map((dataGroup) => (
          <DataGroup
            key={dataGroup.type}
            bgcolor={typeToColors[dataGroup.type].background}
            height={(theme) => `${theme.typography.pxToRem(62)}`}
            width={`${dataGroup.value * 100}%`}
          >
            <Stack direction="row" flex="1" gap={0.25}>
              {dataGroup.interventions.map((intervention) => (
                <BreakdownTooltip
                  key={intervention.name}
                  title={
                    <Stack gap={0.5}>
                      <Typography variant="body2">
                        {intervention.name}
                      </Typography>
                      <Typography variant="body2">
                        Type:{" "}
                        <Typography
                          component="span"
                          fontWeight="fontWeightBold"
                          variant="inherit"
                        >
                          {labelMap[dataGroup.type]}
                        </Typography>
                      </Typography>
                      <Typography variant="body2">
                        Emission savings:
                        <Typography
                          component="span"
                          fontWeight="fontWeightBold"
                          variant="inherit"
                        >
                          {t("common:percentage", {
                            value: intervention.emission_savings_ratio,
                            maximumFractionDigits: 2,
                            minimumFactionDigits: 2,
                          })}
                        </Typography>
                      </Typography>
                    </Stack>
                  }
                >
                  <DataGroup
                    alignSelf="stretch"
                    aria-label={`${intervention.name} - ${intervention.emission_savings_ratio}`}
                    bgcolor={typeToColors[dataGroup.type].foreground}
                    sx={{
                      "&:hover": {
                        backgroundColor: typeToColors[dataGroup.type].hover,
                      },
                    }}
                    width={`${
                      (intervention.emission_savings_ratio / dataGroup.value) *
                      100
                    }%`}
                  />
                </BreakdownTooltip>
              ))}
            </Stack>
          </DataGroup>
        ))}
        {breakdownRemaining > 0 ? (
          <DataGroup
            bgcolor="grey.200"
            width={`${breakdownRemaining * 100}%`}
          />
        ) : null}
      </Stack>
    </Stack>
  )
}
