import { useMemo } from "react"

import type { GridPinnedColumns, GridValidRowModel } from "@mui/x-data-grid-pro"

import type { UseGridPinnedColumnsProps } from "./use-grid-pinned-columns.types"

/**
 * Returns the new pinned columns by taking into account removed columns
 *
 * @returns The new pinned columns
 * @example
 * const pinnedColumns = useGridPinnedColumns({
 *   savedPinnedColumns,
 *   validColumns,
 * })
 */
export const useGridPinnedColumns = <RowT extends GridValidRowModel>({
  savedPinnedColumns,
  validColumns,
}: UseGridPinnedColumnsProps<RowT>): GridPinnedColumns => {
  // compute new pinned columns by taking into account removed columns
  const newPinnedColumns: GridPinnedColumns = useMemo(() => {
    const filterInvalidColumnKeys = (columnKey: string) => {
      return validColumns.find((validColumn) => validColumn.field === columnKey)
    }

    return {
      left: savedPinnedColumns.left?.filter(filterInvalidColumnKeys),
      right: savedPinnedColumns.right?.filter(filterInvalidColumnKeys),
    }
  }, [savedPinnedColumns, validColumns])

  return newPinnedColumns
}
