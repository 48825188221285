import { Attr, HasMany, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"
import Meter from "./meter"

@Model()
export default class MeterSourceType extends ApplicationRecord {
  public static jsonapiType = "meter_source_types"

  @HasMany("meters") meter: Meter

  @Attr() name: string
}
