import type moment from "moment"

export const friendlyTimeFormat = "MM/DD/YYYY hh:mm a"
export const shortDayFormat = "MM/DD/YYYY"
export const monthYearFormat = "MMMM YYYY"

export enum HoverLabelUnit {
  Day,
  Hour,
}

export const constructHoverLabel = (
  hour: moment.Moment,
  unit: HoverLabelUnit = HoverLabelUnit.Hour
): string => {
  if (unit === HoverLabelUnit.Hour) {
    return `HOUR END ${(hour.hour() + 1).toString().padStart(2, "0")}`
  }
  if (unit === HoverLabelUnit.Day) {
    return hour.format("M/D")
  }
}

export const formatToYYYYMMDD = (date: moment.Moment) =>
  date.format("YYYY-MM-DD")
