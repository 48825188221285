import { Attr, Model } from "spraypaint"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import ApplicationRecord from "./applicationRecord/applicationRecord"

export const DEFAULT_CURRENCY_PLACEHOLDER = "_.__"

export enum CurrencySymbolPosition {
  Left,
  Right,
}

@Model()
export default class Currency extends ApplicationRecord {
  public static jsonapiType = "currencies"

  @Attr() public readonly type: string

  @Attr() public readonly name: string

  @Attr() public readonly alphaCode: string

  @Attr() public readonly numericCode: number

  @Attr() public readonly minorUnitRatio: number
}

export const fetchCurrencies = async (): Promise<Currency[]> => {
  const response: CollectionProxy<Currency> = await Currency.all()
  return response.data
}
