import React from "react"

import type { IDecimalWithPlaceholderProps } from "../../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { If } from "../../if/if"
import { CurrencyWithPlaceholder } from "../currencyWithPlaceholder/currencyWithPlaceholder"

type ICurrencyWithPreciseFractionProps = Omit<
  IDecimalWithPlaceholderProps,
  "format" | "placeholder"
>

/**
 * A component that properly formats and internationalizes the provided currency
 *
 * @param props - The component properties
 * @param props.value - The currency
 * @param props.replaceZeroWithPlaceholder - Whether to consider 0 as an empty value
 * @param props.useAccountingFormatWhenNegative - Whether to wrap negative currencies in parenthesis or not
 * @example
 * return (
 *   <CurrencyWithPreciseFraction value={waterCost} />
 * )
 */
export const CurrencyWithPreciseFraction = ({
  value,
  replaceZeroWithPlaceholder,
  useAccountingFormatWhenNegative,
}: ICurrencyWithPreciseFractionProps) => (
  <>
    <If condition={Number(value) < 1}>
      <CurrencyWithPlaceholder
        format={{ maximumFractionDigits: 6, maximumSignificantDigits: 4 }}
        replaceZeroWithPlaceholder={replaceZeroWithPlaceholder}
        useAccountingFormatWhenNegative={useAccountingFormatWhenNegative}
        value={value}
      />
    </If>
    <If condition={Number(value) >= 1}>
      <CurrencyWithPlaceholder
        replaceZeroWithPlaceholder={replaceZeroWithPlaceholder}
        useAccountingFormatWhenNegative={useAccountingFormatWhenNegative}
        value={value}
      />
    </If>
  </>
)
