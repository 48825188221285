import { flatMap, pick } from "lodash-es"
import type { Moment } from "moment"
import type { IncludeScope } from "spraypaint"
import { Attr, BelongsTo, Model } from "spraypaint"
import { CollectionProxy } from "spraypaint/lib-esm/proxies"
import type { FieldArg } from "spraypaint/lib-esm/scope"

import type {
  IDepartmentSummary,
  IDepartmentSummaryDto,
  OrganizationChildrenSummary,
} from "../modules/dashboard/services/useOrgSummaryStats/useOrgSummaryStatsUtils"
import {
  renameKeysToSnakeCase,
  snakeCaseToCamelCase,
} from "../utils/formatters"
import ApplicationRecord from "./applicationRecord/applicationRecord"
import Site from "./site"

type EnergyTotals = Record<
  | "carbon_equivalent_emission_lbs"
  | "carbon_free_kwh"
  | "carbon_based_kwh"
  | "battery_based_kwh"
  | "unknown_resource_based_kwh",
  BigDecimal
>

type Date = string

interface ISiteSummaryDto extends IDepartmentSummaryDto {
  site_id: number
  site_name: string
}

export interface ISiteSummary extends IDepartmentSummary {
  siteId: number
  siteName: string
}

export interface SiteSummary extends OrganizationChildrenSummary {
  siteId: number
  siteName: string
}

@Model()
export default class MonthSummary extends ApplicationRecord {
  public static jsonapiType = "month_summaries"

  @BelongsTo("sites") public site: Site

  @Attr() public startDate: Date

  @Attr() public endDate: Date

  @Attr() public avgKwhPerDay: number

  @Attr() public co2eLbs: BigDecimal

  @Attr() public costDollars: string

  @Attr() public meteredKwh: number

  @Attr() public carbonFreeKwh: BigDecimal

  @Attr() public unknownResourceBasedKwh: BigDecimal

  @Attr() public carbonBasedKwh: BigDecimal

  @Attr() public batteryBasedKwh: BigDecimal

  @Attr() public energyTotals: EnergyTotals
}

/**
 * @deprecated
 */
export const getSiteTotals = (
  orgId: string,
  deptId: string,
  startDate: Moment,
  endDate: Moment
): Promise<ISiteSummary[]> => {
  if (deptId != null) {
    return MonthSummary.where({
      department_id: deptId,
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.clone().endOf("month").format("YYYY-MM-DD"),
    })
      .stats({
        site_totals: "calculate",
      })
      .per(0)
      .all()
      .then((result) => {
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data: ISiteSummary[] = snakeCaseToCamelCase(
          // Mass lint disable
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          result.meta.stats.site_totals.calculate.map(
            (siteTotal: ISiteSummaryDto) =>
              // Transform numbers as strings into numbers
              // The BE shouldn't be delivering numbers as strings
              ({
                ...siteTotal,
                confirm_cost_minor: Number.parseFloat(
                  siteTotal.confirm_cost_minor
                ),
                cost_major: Number.parseFloat(siteTotal.cost_major),
                energy_cost_minor: Number.parseFloat(
                  siteTotal.energy_cost_minor
                ),
                imbalance_cost_minor: Number.parseFloat(
                  siteTotal.imbalance_cost_minor
                ),
                metered_kwh: Number.parseFloat(siteTotal.metered_kwh),
                scheduling_cost_minor: Number.parseFloat(
                  siteTotal.scheduling_cost_minor
                ),
                sq_ft: Number.parseFloat(siteTotal.sq_ft),
                transmission_cost_major: Number.parseFloat(
                  siteTotal.transmission_cost_major
                ),
              })
          )
        )

        return data
      })
  }
  return MonthSummary.where({
    organization_id: orgId,
    start_date: startDate.format("YYYY-MM-DD"),
    end_date: endDate.clone().endOf("month").format("YYYY-MM-DD"),
  })
    .stats({
      site_totals: "calculate",
    })
    .per(0)
    .all()
    .then((result) => {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data: ISiteSummary[] = snakeCaseToCamelCase(
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        result.meta.stats.site_totals.calculate.map(
          (siteTotal: ISiteSummaryDto) =>
            // Transform numbers as strings into numbers
            // The BE shouldn't be delivering numbers as strings
            ({
              ...siteTotal,
              confirm_cost_minor: Number.parseFloat(
                siteTotal.confirm_cost_minor
              ),
              cost_major: Number.parseFloat(siteTotal.cost_major),
              energy_cost_minor: Number.parseFloat(siteTotal.energy_cost_minor),
              imbalance_cost_minor: Number.parseFloat(
                siteTotal.imbalance_cost_minor
              ),
              metered_kwh: Number.parseFloat(siteTotal.metered_kwh),
              scheduling_cost_minor: Number.parseFloat(
                siteTotal.scheduling_cost_minor
              ),
              sq_ft: Number.parseFloat(siteTotal.sq_ft),
              transmission_cost_major: Number.parseFloat(
                siteTotal.transmission_cost_major
              ),
            })
        )
      )

      return data
    })
}

export const getMonthSummaries = ({
  organizationId,
  pageSize = 1000,
  ...rest // start_date or end_date
}: {
  organizationId: string | string[]
  pageSize?: number
}) =>
  MonthSummary.where({
    organization_id: organizationId,
    ...rest,
  })
    .per(pageSize)
    .all()
    .then(({ data }) => data)

/**
 * Fetches Month Summary Data in pages, determined by the number of 1000 records that the backend would return
 * See EnergyAllocation.fetchInPages
 *
 * @async
 * @param root0
 * @param root0.clause
 * @param root0.clause.siteId
 * @param root0.clause.organizationId
 * @param root0.includes
 * @param root0.selects
 * @param root0.stats
 * @returns {Promise<CollectionProxy<MonthSummary>>} a collection of Month Summaries that can be greater than 1000 records
 */
export const fetchInPages = async ({
  clause,
  includes = "",
  selects = [],
  stats = {},
}: {
  clause: {
    [x: string]: string | string[]
    organizationId?: string | string[]
    siteId?: string
  }
  includes?: IncludeScope
  selects?: FieldArg
  stats?: Record<string, string>
}): Promise<CollectionProxy<MonthSummary>> => {
  const defaultStats = { total: "count" }
  const whereObj = renameKeysToSnakeCase(clause)
  const metaParams = pick(whereObj, [
    "start_date",
    "end_date",
    "site_id",
    "organization_id",
  ])
  const monthSummaryMeta = await MonthSummary.where(metaParams)
    .stats(defaultStats)
    .per(0)
    .all()

  // migration to strict mode batch disable
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  let recordCount = monthSummaryMeta.meta.stats.total.count
  const promises: Promise<CollectionProxy<MonthSummary>>[] = []
  let pageCount = 1
  while (recordCount > 0) {
    promises.push(
      MonthSummary.where(whereObj)
        .stats(stats)
        .includes(includes)
        .order("id")
        .select(selects)
        .per(1000)
        .page(pageCount)
        .all()
    )
    pageCount += 1
    recordCount -= 1000
  }
  const monthSummaries = await Promise.all(promises)
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const monthSummaryStats: Record<string, Record<string, number>>[] =
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    monthSummaries.map((ea) => ea.meta.stats)
  const reduceStats = (statsArray: typeof monthSummaryStats) => {
    const statKeys: string[][] = Object.entries(stats)
    if (statsArray.length === 0) {
      return
    }

    return statsArray.reduce((accumulatedStats, current) => {
      statKeys.forEach(([statKey, statKeyType]) => {
        if (
          accumulatedStats &&
          statKey in accumulatedStats &&
          statKeyType in accumulatedStats[statKey] &&
          current &&
          statKey in current &&
          statKeyType in current[statKey]
        ) {
          accumulatedStats[statKey][statKeyType] =
            current[statKey][statKeyType] +
            accumulatedStats[statKey][statKeyType]
        } else if (
          current &&
          statKey in current &&
          statKeyType in current[statKey] &&
          accumulatedStats &&
          !(statKey in accumulatedStats)
        ) {
          accumulatedStats[statKey] = current[statKey]
        }
      })
      return accumulatedStats
    })
  }

  return new CollectionProxy<MonthSummary>(
    flatMap(monthSummaries, (ea) => ea.data),
    {
      data: flatMap(monthSummaries, (collection) => collection.raw.data),
      meta: { stats: reduceStats(monthSummaryStats) },
    }
  )
}
