/**
 * This custom hooks uses https://github.com/brammitch/recharts-to-png as the base and adds
 * ability to modify chart before converting to png, and save as a png file.
 * Currently the only modification is to the chart add padding to the chart without affecting the original chart
 * */
import { useCallback, useRef } from "react"

import saveAs from "file-saver"
import html2canvas from "html2canvas"

import { LEFT_AREA_CHART_ADDITIONAL_SPACING } from "../stacked-area-graph/stacked-area-graph.types"
import type { GraphToPng } from "./graph-to-png.types"

export function useGraphToPng(): GraphToPng {
  const ref = useRef<unknown>()
  const savePng = useCallback(
    async (fileName: string, exportHiddenElements?: string[] | undefined) => {
      if (
        typeof ref.current === "object" &&
        ref.current !== null &&
        "container" in ref.current
      ) {
        const ADDITIONAL_PADDING = 48
        // Clone the chart container and add padding to it
        const currentRef = ref.current as { container: HTMLElement }
        const containerElement = currentRef.container
        const exportContainer = document.createElement("div")
        const clonedElement = containerElement.cloneNode(true) as HTMLElement

        const exportContainerStyles = {
          padding: `${ADDITIONAL_PADDING}px ${ADDITIONAL_PADDING}px ${ADDITIONAL_PADDING}px ${
            ADDITIONAL_PADDING - LEFT_AREA_CHART_ADDITIONAL_SPACING
          }px`,
          position: "absolute",
          left: "-9999px",
          top: "0",
          width: `${containerElement.offsetWidth + ADDITIONAL_PADDING * 2}px`,
          height: `${containerElement.offsetHeight + ADDITIONAL_PADDING * 2}px`,
        }
        Object.assign(exportContainer.style, exportContainerStyles)
        clonedElement.style.position = "relative"

        // Hide elements that are not needed in the png
        if (exportHiddenElements) {
          exportHiddenElements.forEach((selector) => {
            const element: HTMLElement | null =
              clonedElement.querySelector(selector)
            if (element) {
              element.style.display = "none"
            }
          })
        }

        exportContainer.appendChild(clonedElement)
        document.body.appendChild(exportContainer)

        const canvas = await html2canvas(exportContainer, { logging: false })
        canvas.toBlob((blob) => {
          if (blob) {
            saveAs(blob, `${fileName}.png`)
          }
        }, "image/png")
        document.body.removeChild(exportContainer)
      }
    },
    [ref]
  )

  return {
    savePng,
    ref,
  }
}
