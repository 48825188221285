import type { FC } from "react"
import React from "react"
import { Link } from "react-router-dom"

import { useUrl } from "@/utils/url"

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material"

import BookshelfImageSrc from "../../../../assets/images/recommendations/book-shelf.png"
import { DialogTitle } from "../../../../nzds/feedback/dialog-title/dialog-title"

interface EditSiteConfirmationProps {
  onClose: () => void
  onContinue: () => void
  open: boolean
}

export const EditSiteConfirmation: FC<EditSiteConfirmationProps> = ({
  open,
  onClose,
  onContinue,
}) => {
  const { recommendationsPath } = useUrl()

  return (
    <Dialog
      aria-describedby="edit-site-confirmation-dialog-description"
      aria-labelledby="edit-site-confirmation-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="edit-site-confirmation-dialog-title" onClose={onClose}>
        Site information successfully saved
      </DialogTitle>
      <DialogContent id="edit-site-confirmation-dialog-description">
        <Box
          alt="success"
          component="img"
          mb={3}
          src={BookshelfImageSrc as string}
          width="266px"
        />
        <Typography>
          New plans, incorporating the added metadata, will be generated within
          24 hours. Check back then for updated plans, or explore the current
          ones in the meantime.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          color="primary"
          component={Link}
          to={recommendationsPath}
          variant="text"
        >
          Go to Portfolio Overview
        </Button>
        <Button color="primary" onClick={onContinue} variant="contained">
          Continue Exploring Plans
        </Button>
      </DialogActions>
    </Dialog>
  )
}
