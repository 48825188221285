import { useMutation } from "react-query"

import { ApiQueryName } from "../../models/api"
import OrganizationalUnit from "../../models/organizationalUnit"
import { createQueryKey } from "../utils/createQueryKey"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useQueryKey } from "../utils/useQueryKey"

export const useCreateOrganizationalUnit = () => {
  const mutationKey = useQueryKey(ApiQueryName.OrganizationalUnit, "createOne")
  const { invalidateQueries } = useInvalidateQueries()
  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async (orgUnitAttributes: Partial<OrganizationalUnit>) => {
      const orgUnit = new OrganizationalUnit(orgUnitAttributes)
      await orgUnit.save()
    },
    onSuccess: () => {
      invalidateQueries([
        createQueryKey(ApiQueryName.OrganizationalUnits, "getMany"),
        createQueryKey(ApiQueryName.OrganizationHierarchy, "getOne"),
      ])
    },
  })

  return mutationResult
}
