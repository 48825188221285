import React, { useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import DownloadIcon from "@mui/icons-material/Download"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import type { Report } from "../../models/report"
import DownloadCsv from "../downloadCsv/downloadCsv"
import inReviewIllustration from "./images/in-review.svg"

interface IDownloadPreviewCsvProps {
  isDisabled: boolean
  isLoading: boolean
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  report: Report<any> | Report<any>[]
}

const DownloadPreviewCsv = ({
  isLoading,
  isDisabled,
  report,
}: IDownloadPreviewCsvProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <LoadingButton
        disabled={isDisabled}
        endIcon={<DownloadIcon />}
        loading={isLoading}
        onClick={handleOpen}
        title="Download Preview CSV"
        variant="contained"
      >
        Download Preview CSV
      </LoadingButton>
      <Dialog
        aria-describedby="Download preliminary emissions data via csv"
        aria-labelledby="Get ready for reporting season"
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        open={isOpen}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            color="neutral"
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              right: "16px",
              top: "16px",
            }}
            title="Close Icon"
          >
            <CloseIcon />
          </IconButton>
          <Box
            alt="An illustration of a person with light blue hair and an orange shirt holding papers and walking forward. There are floating check marks to signify completed tasks."
            component="img"
            height="205px"
            mb={3}
            mt={1}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            src={inReviewIllustration}
            width="271px"
          />
          <Box>
            <Typography component="h1" fontWeight="bold" mb={1}>
              Get ready for reporting season
            </Typography>
            <Typography variant={TYPOGRAPHY_VARIANT.body2}>
              Take a look at your preliminary emissions data and familiarize
              yourself with the report formatting.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} title="Close">
            Close
          </Button>
          <DownloadCsv
            onClick={handleClose}
            report={report}
            title="Download Preview CSV"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DownloadPreviewCsv
