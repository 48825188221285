import type { AxiosInstance } from "axios"

import { snakeCaseToCamelCase } from "./formatters"

const allowPassThrough = [
  "recommendations/building_simulations",
  "recommendations/interventions",
  "recommendations/plans",
  "recommendations/potential_sites",
  "recommendations/building_simulations/by_site_and_interventions",
]

export const initCaseResponseInterceptor = (httpClient: AxiosInstance) =>
  httpClient.interceptors.response.use(
    (response) => {
      if (
        allowPassThrough.some((passThroughUrl) =>
          response.config.url.includes(passThroughUrl)
        )
      ) {
        return response
      }
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return snakeCaseToCamelCase(response)
    },
    (error) => Promise.reject(snakeCaseToCamelCase(error))
  )
