import type { FC } from "react"
import React, { memo } from "react"

import type { ButtonProps } from "@mui/material"
import { Box } from "@mui/material"
import { useGridApiContext } from "@mui/x-data-grid-pro"

import type OrganizationalUnit from "../../../../../models/organizationalUnit"
import { OUDisplayCell } from "../ou-display-cell/ou-display-cell"
import { SiteDisplayCell } from "../site-display-cell/site-display-cell"
import type { HierarchyGroupingCellProps } from "./hierarchy-grouping-cell.types"

export const HierarchyGroupingCell: FC<HierarchyGroupingCellProps> = memo(
  ({ id, field, rowNode, row }) => {
    const apiRef = useGridApiContext()

    const handleClick: ButtonProps["onClick"] = (event) => {
      if (rowNode.type !== "group") {
        return
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
      apiRef.current.setCellFocus(id, field)
      event.stopPropagation()
    }

    return (
      <Box sx={{ ml: rowNode.depth * 4 }} width="100%">
        {row.type === "ou" ? (
          <OUDisplayCell
            model={row.model as OrganizationalUnit}
            name={row.name}
            onClick={handleClick}
            rowNode={rowNode}
          />
        ) : (
          <SiteDisplayCell
            name={row.name}
            orgId={row.orgId}
            parentId={row.parentId}
            siteId={row.id}
          />
        )}
      </Box>
    )
  }
)
