import { RootPath, TamAdminPath } from "../../../../models/route"

/**
 * Get the tab index for tam admin
 *
 * @param rowData - The pathname string
 * @returns - The index based on the pathname
 * @example
 * getActiveTabIndex("../tam-admin/onboarding")
 */
export const getActiveTabIndex = (pathname: string): number => {
  if (pathname === `${RootPath.TamAdmin}${TamAdminPath.Onboarding}`) {
    return 0
  }
  return 0
}

export default { getActiveTabIndex }
