import React, { useState } from "react"

import type { Moment } from "moment"
import moment from "moment"

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment"

import type { YearPickerProps } from "./year-picker.types"

export const YearPicker = ({
  availableDateRange,
  isDisabled,
  onChange,
  value,
}: YearPickerProps): JSX.Element => {
  const [error, setError] = useState<boolean>(false)

  const handleChange = (date: Moment | null) => {
    if (
      date &&
      date.isSameOrBefore(availableDateRange?.end) &&
      date.isSameOrAfter(availableDateRange?.start)
    ) {
      onChange(date)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        defaultValue={moment(value)}
        disabled={isDisabled ?? !value}
        maxDate={availableDateRange?.end ?? undefined}
        minDate={availableDateRange?.start ?? undefined}
        onChange={handleChange}
        onError={(newError) => {
          setError(!!newError)
        }}
        slotProps={{
          textField: {
            helperText: error && !isDisabled ? "Year out of range" : null,
            inputProps: { "aria-label": "Year" },
            size: "small",
          },
        }}
        sx={{ width: 148 }}
        value={moment(value)}
        views={["year"]}
      />
    </LocalizationProvider>
  )
}
