import React from "react"

import clearDayUrl from "../icons/weather/clear-day.svg"
import clearNightUrl from "../icons/weather/clear-night.svg"
import cloudyUrl from "../icons/weather/cloudy.svg"
import fogUrl from "../icons/weather/fog.svg"
import partlyCloudyDayUrl from "../icons/weather/partly-cloudy-day.svg"
import partlyCloudyNightUrl from "../icons/weather/partly-cloudy-night.svg"
import rainUrl from "../icons/weather/rain.svg"
import sleetUrl from "../icons/weather/sleet.svg"
import snowUrl from "../icons/weather/snow.svg"
import windUrl from "../icons/weather/wind.svg"

export const weatherUrls: Record<string, string> = {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  "clear-day": clearDayUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  "clear-night": clearNightUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  cloudy: cloudyUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  fog: fogUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  "partly-cloudy-day": partlyCloudyDayUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  "partly-cloudy-night": partlyCloudyNightUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  rain: rainUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  sleet: sleetUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  snow: snowUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  wind: windUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  Clear: clearDayUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  Overcast: cloudyUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  Sunny: clearDayUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  "Partly cloudy": partlyCloudyDayUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  "Patchy rain possible": rainUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  "Light rain shower": rainUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  "Light drizzle": rainUrl,
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  "Light rain": rainUrl,
}

export const WeatherIcon = ({ icon }) => (
  // migration to strict mode batch disable
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  <img alt={icon} className="weather__icon" src={weatherUrls[icon]} />
)
