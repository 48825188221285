import type { FC } from "react"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom"

import { AppLoader } from "@/components/route/app-loader"
import type { Organization } from "@/models/organization"
import { DataPath, RootPath } from "@/models/route"
import type User from "@/models/user"
import { AboutIndex } from "@/modules/about"
import { AssistantIndexRoute } from "@/modules/assistant/pages"
import { DataIndexRoute } from "@/modules/data/pages/data-index-route"
import { DataQARoute } from "@/modules/data/pages/data-qa-route"
import { DataUploadRoute } from "@/modules/data/pages/data-upload-route"
import RecommendationsRoute from "@/modules/recommendations"
import RepRoute from "@/modules/rep"
import { ReportingRoute } from "@/modules/report/routes"
import Settings from "@/modules/settings/module"
import { SitesRoute } from "@/modules/site/routes"
import TamAdmin from "@/modules/tamAdmin/module"
import {
  fetchSessionValidity,
  getAuthenticatedUserQueryKey,
  isSessionValidQueryKey,
} from "@/services/authentication"
import { useGtag } from "@/services/gtag"
import {
  fetchAuthorizedOrganizations,
  useAuthorizedOrganizationsQueryKey,
} from "@/services/organization"
import { getUser } from "@/utils"
import { queryClient } from "@/utils/queryClient"

import { ForgotPassword } from "../forgot-password/forgot-password"
import { NewLayout } from "../nav/appLayout/newLayout"
import Page404 from "../nav/page404/page404"
import { RedirectToOrgRoute } from "../nav/redirect-to-org-route"
import { OrganizationGuard } from "../organization-guard"
import { OrganizationDashboardIndexRoute } from "../organizationDashboard/organization-dashboard-index-route"
import { OrganizationSummary } from "../organizationSummary/organizationSummary"
import { ResetPassword } from "../reset-password/reset-password"
import AdminGuard from "../route/adminGuard/adminGuard"
import { AuthenticatedRoute } from "../route/authenticated-route/authenticated-route"
import { ConsultantGuard } from "../route/consultant-guard"
import { OrgGuard } from "../route/orgGuard"
import { UnauthenticatedRoute } from "../route/unauthenticated-route/unauthenticated-route"
import UserGuard from "../route/userGuard/userGuard"
import { SignUp } from "../sign-up/sign-up"
import { SignIn } from "../signIn/signIn"
import { ValidateAccount } from "../validate-account/validate-account"

import "@/styles/application.scss"

interface AuthenticatedRouteLoaderValue {
  authorizedOrganizations: Organization[]
  isSessionValid: boolean
  user: User | undefined
}

const authenticatedRouteLoader =
  async (): Promise<AuthenticatedRouteLoaderValue> => {
    try {
      const isSessionValid = await queryClient.fetchQuery(
        isSessionValidQueryKey,
        fetchSessionValidity
      )

      if (isSessionValid) {
        const [user, authorizedOrganizations] = await Promise.all([
          queryClient.fetchQuery(getAuthenticatedUserQueryKey, getUser),
          queryClient.fetchQuery(
            useAuthorizedOrganizationsQueryKey,
            fetchAuthorizedOrganizations
          ),
        ])

        return {
          authorizedOrganizations,
          isSessionValid,
          user,
        }
      }

      return {
        authorizedOrganizations: [],
        isSessionValid,
        user: undefined,
      }
    } catch {
      console.error("Error preloading authenticated route data")
    }
  }

const router = createBrowserRouter([
  {
    element: <AppLoader />,
    children: [
      // Unauthenticated routes
      {
        path: RootPath.About,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <AboutIndex />,
          },
        ],
      },
      {
        path: RootPath.Root,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <SignIn />,
          },
        ],
      },
      {
        path: RootPath.SignUp,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <SignUp />,
          },
        ],
      },
      {
        path: `${RootPath.ValidateAccount}/:token`,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ValidateAccount />,
          },
        ],
      },
      {
        path: RootPath.ForgotPassword,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ForgotPassword />,
          },
        ],
      },
      {
        path: `${RootPath.ResetPassword}/:token`,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ResetPassword />,
          },
        ],
      },
      // Authenticated routes
      {
        element: <AuthenticatedRoute />,
        loader: authenticatedRouteLoader,
        children: [
          // TAM Admin pages
          {
            path: RootPath.TamAdmin,
            element: (
              <AdminGuard>
                <TamAdmin />
              </AdminGuard>
            ),
          },
          {
            path: `${RootPath.TamAdmin}/*`,
            element: (
              <AdminGuard>
                <TamAdmin />
              </AdminGuard>
            ),
          },
          // Main authenticated pages
          {
            element: <NewLayout />,
            children: [
              {
                path: RootPath.Rep,
                element: <RepRoute />,
              },
              {
                path: `${RootPath.Rep}/*`,
                element: <RepRoute />,
              },
              {
                path: RootPath.Settings,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <Settings />,
                  },
                ],
              },
              // Old routes that do not start with :orgId
              // Redirect to new routes
              {
                path: RootPath.Dashboard,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <RedirectToOrgRoute path={`/${RootPath.Dashboard}`} />
                    ),
                  },
                  {
                    path: "*",
                    element: (
                      <RedirectToOrgRoute path={`/${RootPath.Dashboard}`} />
                    ),
                  },
                ],
              },
              {
                path: RootPath.Organization,
                element: <RedirectToOrgRoute path={`/${RootPath.Financial}`} />,
              },
              {
                path: `${RootPath.Organization}/:orgId`,
                element: (
                  <OrgGuard>
                    <RedirectToOrgRoute path={`/${RootPath.Financial}`} />
                  </OrgGuard>
                ),
              },
              {
                path: RootPath.Recommendations,
                element: <RedirectToOrgRoute />,
              },
              {
                path: `${RootPath.Recommendations}/*`,
                element: <RedirectToOrgRoute />,
              },
              {
                path: RootPath.Report,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <RedirectToOrgRoute />,
                  },
                  {
                    path: ":orgId",
                    element: (
                      <OrgGuard>
                        <Outlet />
                      </OrgGuard>
                    ),
                    children: [
                      {
                        index: true,
                        element: <RedirectToOrgRoute />,
                      },
                      {
                        path: "*",
                        element: <RedirectToOrgRoute />,
                      },
                    ],
                  },
                ],
              },
              {
                path: RootPath.Site,
                element: <RedirectToOrgRoute />,
              },
              {
                path: `${RootPath.Site}/*`,
                element: <RedirectToOrgRoute />,
              },
              // New routing approach starting with :orgId
              // Migrate almost all authenticated routes to this approach
              {
                path: ":orgId",
                element: <OrganizationGuard />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="/" replace={true} />,
                  },
                  {
                    path: RootPath.Assistant,
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: <AssistantIndexRoute />,
                      },
                      {
                        path: ":threadId",
                        element: <AssistantIndexRoute />,
                      },
                    ],
                  },
                  {
                    path: RootPath.Dashboard,
                    element: <OrganizationDashboardIndexRoute />,
                  },
                  {
                    path: RootPath.Data,
                    element: <DataIndexRoute />,
                    children: [
                      {
                        path: DataPath.Upload,
                        element: <DataUploadRoute />,
                      },
                      {
                        path: DataPath.QA,
                        element: <DataQARoute />,
                      },
                    ],
                  },
                  {
                    path: RootPath.Financial,
                    element: (
                      <UserGuard>
                        <ConsultantGuard>
                          <div className="authorized-organization">
                            <OrganizationSummary />
                          </div>
                        </ConsultantGuard>
                      </UserGuard>
                    ),
                  },
                  {
                    path: RootPath.Recommendations,
                    element: <RecommendationsRoute />,
                  },
                  {
                    path: `${RootPath.Recommendations}/*`,
                    element: <RecommendationsRoute />,
                  },
                  {
                    path: RootPath.Report,
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: <ReportingRoute />,
                      },
                      {
                        path: "*",
                        element: <ReportingRoute />,
                      },
                    ],
                  },
                  {
                    path: RootPath.Site,
                    element: <SitesRoute />,
                  },
                  {
                    path: `${RootPath.Site}/*`,
                    element: <SitesRoute />,
                  },
                ],
              },
              // Ensures authenticated route layout is rendered
              // when no matching route is found
              {
                path: "*",
                element: <Page404 />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
])

export const App: FC = () => {
  useGtag()
  const { i18n } = useTranslation()

  return i18n.isInitialized ? <RouterProvider router={router} /> : null
}
