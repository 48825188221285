import moment from "moment-timezone"

import ServiceHour from "../../../../models/serviceHour"
import type Site from "../../../../models/site"

export const fetchServiceHourMinMaxDates = (
  site: Site
): Promise<{
  fetchedMaxDate: string | null
  fetchedMinDate: string | null
}> =>
  ServiceHour.stats({
    min_max_dates: "calculate",
  })
    .where({
      site_id: site.id,
      metered_kwh: { gt: 0 },
    })
    .per(0)
    .all()
    .then((result) => {
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const stats = result.meta.stats.min_max_dates.calculate
      const fetchedMinDate =
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        stats.min_date === "" || stats.min_date === null
          ? null
          : // Mass lint disable
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            moment(stats.min_date).tz(site.timezone).format()
      const fetchedMaxDate =
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        stats.max_date === "" || stats.max_date === null
          ? null
          : // Mass lint disable
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            moment(stats.max_date).tz(site.timezone).format()
      return {
        fetchedMaxDate,
        fetchedMinDate,
      }
    })
