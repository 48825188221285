import { styled } from "@mui/material"

import { PageCard } from "./page-card"

export const StyledPageCard = styled(PageCard, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "offset",
})<{
  offset: number
  open?: boolean
}>(({ theme, open, offset }) => ({
  flex: 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: "100%",
  marginRight: `-${offset}px`,
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${offset}px)`,
    marginRight: 0,
  }),
  position: "relative",
}))
