import { scaleLinear, select } from "d3"

import type { Palette } from "@mui/material"

import { generateSemiAnnularPath } from "./generateSemiAnnularPath"

export interface NeedleDataInterface {
  domain: [number, number]
  palette: Palette
  range: [number, number]
  ratio: number
  svg: React.MutableRefObject<undefined>
  viewHeight: number
  viewWidth: number
}

export const drawGraphNeedle = ({
  ratio,
  domain,
  range,
  svg: svgRef,
  viewWidth,
  viewHeight,
  palette,
}: NeedleDataInterface) => {
  const circleOuterWidth = viewWidth / 50
  const circleInnerWidth = viewWidth / 90
  const dialLimitedRatio = (() => {
    if (ratio < 1.0) {
      return ratio
    }
    if (ratio > 1.0) {
      return 1.0
    }
    return 0.0
  })()
  const angleScale = scaleLinear().domain(domain).range(range)
  const dialReadingAngle =
    (angleScale(dialLimitedRatio * domain[1]) * 180) / Math.PI
  const svg = select(svgRef.current)
  const needleGroup = svg
    .append("g")
    .attr("class", "dial-graph__needle")
    .style("transform", "translate(50%, 50%)")

  needleGroup
    .append("path")
    .attr(
      "d",
      generateSemiAnnularPath(
        0,
        Math.PI * 2,
        circleOuterWidth,
        circleInnerWidth
      )
    )
    .style("fill", palette.common.black)

  needleGroup
    .append("line")
    .style("stroke", palette.common.black)
    .style("stroke-width", circleOuterWidth - circleInnerWidth)
    .attr("stroke-linecap", "round")
    .attr("x1", 0)
    .attr("y1", -circleOuterWidth)
    .attr("x2", 0)
    .attr("y2", -viewHeight / 4)
    .attr("transform", `rotate(${dialReadingAngle})`)
}
