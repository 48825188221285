import { useQuery } from "react-query"

import { ApiQueryName } from "../../models/api"
import BaCode from "../../models/baCode"
import type {
  ServiceOptions,
  ServiceSortOptions,
  ServiceWhereOptions,
} from "../types"
import type { ModelAttributes } from "../utils/spraypaint"
import { useSpraypaintModel } from "../utils/spraypaint"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useBaCodes = ({
  disabled: isDisabled,
  where,
  sort,
}: ServiceOptions<"getMany"> & {
  sort?: ServiceSortOptions<ModelAttributes<BaCode>>
  where?: ServiceWhereOptions<ModelAttributes<BaCode>>
} = {}) => {
  const { getMany } = useSpraypaintModel(BaCode)

  const queryKey = useQueryKey(ApiQueryName.BaCodes, "getMany", null, {
    sort,
    where,
  })

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => getMany({ sort, where }),
    enabled: !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
