import type { i18n } from "i18next"
import type { Moment } from "moment"
import moment from "moment"

import type { IDateFormat } from "../models/date/date"
import { Translation } from "../models/i18n"
import type { IRange } from "../models/range"
import { TIME_RANGES } from "./constants"

export const getDateFormatFromTimeRange = (
  timeRange: TIME_RANGES
): IDateFormat => ({
  year: timeRange === TIME_RANGES.YEAR ? "numeric" : undefined,
})

/**
 * Get an internationalized hour ending string (01-24) from a date
 *
 * @param date {Moment} - The date
 * @param t - The i18n translation function
 * @returns {string} - The hour ending
 * @example
 * getHourEnding(date, t)
 */
export const getHourEnding = (date: Moment, t): string => {
  // migration to strict mode batch disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const localizedHour: string = t(Translation.Common.Date, {
    value: date.toDate(),
    formatParams: {
      value: {
        hour: "numeric",
        hour12: false,
        timeZone: date.tz(), // Ensure we're always showing the date in the date's timezone, not the client's timezone
      },
    },
  })

  const numericLocalizedHour = Number(localizedHour)

  // Midnight is internationalized to either 00 or 24 depending on the locale
  // A midnight hour ending should always be 01
  const numericLocalizedHourEnding: number =
    numericLocalizedHour === 24 ? 1 : numericLocalizedHour + 1

  return numericLocalizedHourEnding.toString().padStart(2, "0")
}

/**
 * Clamps a date range within the bounds of another date range
 *
 * @param dateRange - The date range to clamp
 * @param bounds - The date range to clamp against
 * @returns - The new range of the clamped date range
 * @example
 * clampDateRange(dateRange, bounds)
 */
export const clampDateRange = (
  dateRange: IRange<Moment>,
  bounds: IRange<Moment>
): IRange<Moment> => {
  let start: Moment
  let end: Moment

  if (dateRange.start.isBefore(bounds.start)) {
    start = bounds.start.clone()
  } else if (dateRange.start.isAfter(bounds.end)) {
    start = bounds.end.clone()
  } else {
    start = dateRange.start.clone()
  }

  if (dateRange.end.isBefore(bounds.start)) {
    end = bounds.start.clone()
  } else if (dateRange.end.isAfter(bounds.end)) {
    end = bounds.end.clone()
  } else {
    end = dateRange.end.clone()
  }

  return {
    start,
    end,
  }
}

/**
 * Gets the most recent previous year date range within the bounds
 *
 * @param bounds - The date range to verify against
 * @returns - The most recent previous year date range within the bounds
 * @example
 * getMostRecentPreviousYearDateRange({
 *   start: moment("2013-03-01"),
 *   end: moment("2022-12-31"),
 * })
 */
export const getMostRecentPreviousYearDateRange = (
  bounds: IRange<Moment>
): IRange<Moment> => {
  const mostRecentPreviousYear: Moment = moment.min(
    moment().subtract(1, "year"),
    bounds.end
  )

  return clampDateRange(
    {
      start: mostRecentPreviousYear.clone().startOf("year"),
      end: mostRecentPreviousYear.clone().endOf("year").startOf("day"),
    },
    bounds
  )
}

/**
 * Determines if a date range is within the bounds of another date range
 *
 * @param dateRange - The date range to verify
 * @param bounds - The date range to verify against
 * @returns - Whether the date range is within the bounds
 * @example
 * isDateRangeWithinBounds(dateRange, bounds)
 */
export const isDateRangeWithinBounds = (
  dateRange: IRange<Moment>,
  bounds: IRange<Moment>
): boolean =>
  !!dateRange.start?.isBetween(bounds.start, bounds.end, "day", "[]") &&
  !!dateRange.end?.isBetween(bounds.start, bounds.end, "day", "[]")

/**
 * Determines if the start date is after the end date and both are valid dates
 *
 * @param dateRange - The date range to verify
 * @param bounds - The date range to verify against (is sent as a parameter to the function isDateRangeWithinBounds)
 * @returns - Whether the date range is within bounds and the start date is after the end date
 * @example
 * isValidCrossYearDateRange(dateRange, bounds)
 */
export const isValidCrossYearDateRange = (
  dateRange: IRange<Moment>,
  bounds: IRange<Moment>
): boolean => {
  if (
    dateRange.start?.isValid() &&
    dateRange.end?.isValid() &&
    dateRange.start?.isAfter(dateRange.end) &&
    dateRange.end?.isBefore(dateRange.start)
  ) {
    return false
  }
  return isDateRangeWithinBounds(dateRange, bounds)
}

/**
 * Determines if the start date is after the end date and they have the same year
 * @deprecated - Use isValidCrossYearDateRange instead
 * @param dateRange - The date range to verify
 * @param bounds - The date range to verify against (is sent as a parameter to the function isDateRangeWithinBounds)
 * @returns - Whether the date range is within the same year and the start date is after the end date
 * @example
 * isValidDateRange(dateRange, bounds)
 */
export const isValidDateRange = (
  dateRange: IRange<Moment>,
  bounds: IRange<Moment>
): boolean => {
  if (
    dateRange.end?.isValid() &&
    (dateRange.end.diff(dateRange.start, "month") < 0 ||
      !dateRange.end.isSame(dateRange.start, "year"))
  ) {
    return false
  }
  return isDateRangeWithinBounds(dateRange, bounds)
}
/**
 * Determines the range in months between the start and end date
 * @param startDate - The start date of the range
 * @param endDate - The end date of the range
 * @returns - The time range in months
 * @example
 * getMonthsInRange(startDate, endDate)
 */
export const getMonthsInRange = (startDate: Moment, endDate: Moment): number =>
  endDate.diff(startDate, "months")

/**
 * Determines the range in days between the start and end date
 * @param startDate - The start date of the range
 * @param endDate - The end date of the range
 * @returns - The time range in days
 * @example
 * getDaysInRange(startDate, endDate)
 */
export const getDaysInRange = (startDate: Moment, endDate: Moment) =>
  Math.abs(startDate?.diff(endDate.clone().add(1, "day"), "days"))

/**
 * Determines the time range based on the start and end date
 * @param startDate - The start date of the range
 * @param endDate - The end date of the range
 * @returns - The time range
 * @example
 * getRangeType(startDate, endDate)
 */
export const getRangeType = ({
  startDate,
  endDate,
}: {
  endDate: Moment
  startDate: Moment
}): TIME_RANGES.DAY | TIME_RANGES.MONTH | TIME_RANGES.RANGE => {
  const dayDiff = Math.abs(endDate?.diff(startDate, "days"))
  return dayDiff < 2 ? TIME_RANGES.DAY : TIME_RANGES.MONTH
}

export const getYearMonthDayISOString = (
  year: number | string,
  month: number | string,
  day: number | string = 1
): string =>
  `${year}-${Number(month).toString().padStart(2, "0")}-${Number(day)
    .toString()
    .padStart(2, "0")}`

export const removeTimeFromISODateString = (isoString: string): string | null =>
  isoString?.split("T")[0] ?? null

/**
 * Formats and internationalizes the provided date
 *
 * @param i18nService - The internationalization service
 * @param props.value - The date
 * @param props.format - The format configuration for the date
 * @returns - Internationalized date in requested format
 * @example
 * translateDate(i18n,
        date,
        {
          day: "numeric",
          month: "numeric",
      })) // day and month ordered with separator appropriately for internationalization
 * translateDate(i18n, date)) // day, month and year ordered with separator appropriately for internationalization
 */

export const translateDate = (
  i18nService: i18n,
  value: moment.Moment,
  format?: IDateFormat | null
) =>
  // Ensure we're always showing the date in the date's timezone, not the client's timezone
  Intl.DateTimeFormat(i18nService.language, {
    ...format,
    timeZone: value.tz(),
  }).format(value.toDate())

// DEPRECATED: DO NOT USE
/**
 * This function is only to be used as a temporary solution because the backend
 * is delivering some date strings in a UTC string and safari cannot interpret them.
 * @deprecated Remove use of this function once backend delivers date strings in
 * the standard ISO format which all browser Date constructors can interpret.
 * @param date
 */
export const convertDateStringDashesToSlashes = (date: string): string =>
  date.replace(/-/g, "/")
