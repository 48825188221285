import React from "react"
import type { FC } from "react"

import { useFormatDecimal } from "@/formatters/useFormatDecimal"
import { HStack } from "@/nzds/layout"

import { Box } from "@mui/material"

import type { ComplianceReductionProps } from "./compliance-reduction.types"

export const ComplianceReduction: FC<ComplianceReductionProps> = ({
  regulations,
}) => {
  const { format: formatDecimalToHundredths } = useFormatDecimal()

  return !regulations?.length ? (
    <>--</>
  ) : (
    <>
      {regulations.map((regulation, index) => {
        const reductionPercent: number =
          regulation.regulation_name === "No Regulation" ||
          regulation.is_supported_without_fines
            ? null
            : Math.max(
                regulation.cui_reduction_percentage,
                regulation.eui_reduction_percentage
              ) * 100

        return (
          <div
            key={`${regulation.regulation_name}-${regulation.effective_date}`}
          >
            <HStack>
              <div>
                {index > 0 && ", "}
                {formatDecimalToHundredths(reductionPercent)}{" "}
                {regulation.metric_type ? (
                  <Box
                    component="span"
                    sx={{
                      display: "inline-block",
                      textAlign: "right",
                      width: 28,
                    }}
                  >
                    {regulation.metric_type ?? ""}
                  </Box>
                ) : null}
              </div>
            </HStack>
          </div>
        )
      })}
    </>
  )
}
