// @ts-strict-ignore
import type { Moment } from "moment"
import type { PersistedSpraypaintRecord } from "spraypaint/lib-esm/model"

import type { IRange } from "../../../../models/range"
import { ResourceName } from "../../../../models/resource"
import type SiteEmissionDay from "../../../../models/siteEmissionDay"
import { LBS_PER_KG } from "../../../../utils/constants"

const getMonthlySiteNaturalGasEmissionsData = (
  data: PersistedSpraypaintRecord<SiteEmissionDay>[] | undefined
): PersistedSpraypaintRecord<SiteEmissionDay>[] | undefined =>
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  data?.filter(({ name }) => name === ResourceName.NaturalGas)

/**
 * Provides a finite number representing the total volume of natural gas (in therms)
 */
const getTotalVolumeNaturalGasThm = (
  data: PersistedSpraypaintRecord<SiteEmissionDay>[]
): number | undefined =>
  getMonthlySiteNaturalGasEmissionsData(data)?.reduce(
    (acc: number, currentData: SiteEmissionDay) =>
      acc + (Number.isFinite(currentData.amount) ? currentData.amount : 0),
    0
  )

/**
 * Provides a finite number representing the total volume of natural gas (in therms)
 * averaged over a given time range
 */
const getDailyAverageNaturalGasThm = (
  data: PersistedSpraypaintRecord<SiteEmissionDay>[],
  timeRange: IRange<Moment>
): number | undefined => {
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!timeRange?.start?.isValid() || !timeRange?.end?.isValid()) {
    return undefined
  }
  const days = timeRange.end.diff(timeRange.start, "days") + 1 // Adding 1 to be inclusive of both dates
  if (!days) {
    return undefined
  }

  return getTotalVolumeNaturalGasThm(data) / days
}

/**
 * Provides a finite number representing the total Co2eLbs of natural gas (in therms)
 * averaged over a given time range
 */
const getTotalEmissionsNaturalGasCo2eLbs = (
  data: PersistedSpraypaintRecord<SiteEmissionDay>[]
): number | undefined =>
  getMonthlySiteNaturalGasEmissionsData(data)?.reduce(
    (acc: number, currentData: SiteEmissionDay) =>
      acc +
      (Number.isFinite(Number(currentData.kgCo2e))
        ? Number(currentData.kgCo2e) * LBS_PER_KG
        : 0),
    0
  )

export {
  getDailyAverageNaturalGasThm,
  getTotalEmissionsNaturalGasCo2eLbs,
  getTotalVolumeNaturalGasThm,
}
