import type { Organization } from "@/models/organization"
import { client } from "@/services/client"
import type { Paths } from "@/services/types"

export type CreateGoalBody =
  Paths["/api/v1/recommendations/goals"]["post"]["requestBody"]["content"]["application/json"] & {
    organization_id: Organization["id"]
  }

export const createGoal = async (requestBody: CreateGoalBody) => {
  const { data, error } = await client.POST("/api/v1/recommendations/goals", {
    body: requestBody,
  })

  if (data) {
    return data
  }
  throw error as Error
}
