import React from "react"

import AddIcon from "@mui/icons-material/Add"
import CheckIcon from "@mui/icons-material/Check"
import { ToggleButton } from "@mui/material"
import { makeStyles } from "@mui/styles"

import { black60, black87, dustyGray, solidGray } from "../../utils/colors"
import { getDataE2EAttribute } from "../../utils/e2e"
import { pxToRem } from "../../utils/unit"
import { If } from "../if/if"

const buttonStyle = {
  height: pxToRem(32),
  padding: pxToRem(4),
  borderRadius: pxToRem(16),
  marginRight: pxToRem(8),
  marginBottom: pxToRem(8),
}

const buttonIconStyle = {
  marginRight: "8px",
}

const useStyles = makeStyles({
  activeButton: {
    ...buttonStyle,
    textTransform: "none",
    border: "1px solid transparent !important",
  },
  inactiveButton: {
    ...buttonStyle,
    textTransform: "none",
    color: solidGray.toString(),
    border: `1px solid ${dustyGray.toString()} !important`,
  },
  activeButtonIcon: {
    ...buttonIconStyle,
    color: black60.toString(),
  },
  inactiveButtonIcon: {
    ...buttonIconStyle,
    color: dustyGray.toString(),
  },
  buttonText: {
    paddingRight: "4px",
  },
  component: {
    "& .MuiToggleButton-root.Mui-selected": {
      color: black87.toString(),
    },
  },
})

export interface IToggleButtonItem {
  id: string
  name: string
}

interface ComponentProps {
  items: IToggleButtonItem[]
  labelledBy: string
  onChange: (value: string[]) => void
  value: string[] | null
}

export const ToggleButtonList = ({
  items,
  value,
  onChange,
  labelledBy,
}: ComponentProps) => {
  const classes = useStyles()
  const isSelected = (itemValue: string): boolean =>
    !!value?.includes(itemValue)

  const onButtonClick = (itemValue: string): void => {
    const newValue: string[] = Array.isArray(value) ? [...value] : []
    const isItemSelected: boolean = newValue.includes(itemValue)

    if (!isItemSelected) {
      newValue.push(itemValue)
    } else {
      newValue.splice(newValue.indexOf(itemValue), 1)
    }

    onChange(newValue)
  }

  return (
    <div aria-labelledby={labelledBy} className={classes.component}>
      {items.map((item) => {
        const buttonClass = isSelected(item.id)
          ? classes.activeButton
          : classes.inactiveButton
        return (
          <ToggleButton
            key={item.id}
            className={buttonClass}
            data-e2e={`toggle-button-${getDataE2EAttribute(item.name)}`}
            onChange={() => {
              onButtonClick(item.id)
            }}
            selected={isSelected(item.id)}
            value={item.id}
          >
            <If condition={isSelected(item.id)}>
              <CheckIcon
                className={classes.activeButtonIcon}
                fontSize="small"
              />
            </If>
            <If condition={!isSelected(item.id)}>
              <AddIcon
                className={classes.inactiveButtonIcon}
                fontSize="small"
              />
            </If>
            <span className={`paragraph--small__regular ${classes.buttonText}`}>
              {item.name}
            </span>
          </ToggleButton>
        )
      })}
    </div>
  )
}
