import { useEffect } from "react"

import { useOrganizationContext } from "@/contexts"
import { datadogRum } from "@datadog/browser-rum"

import { useAuthentication } from "../authentication"

export const useDatadogRumSetUser = () => {
  const { authenticatedUser, isConsultantUser, isFreeUser } =
    useAuthentication()
  const { organization } = useOrganizationContext()

  // Set Datadog RUM user
  useEffect(() => {
    if (authenticatedUser) {
      datadogRum.setUser({
        email: authenticatedUser.email,
        consultantUser: isConsultantUser,
        freeUser: isFreeUser,
        name: authenticatedUser.name,
        organization: organization?.name ?? "",
      })
    }
  }, [authenticatedUser, isConsultantUser, isFreeUser, organization?.name])
}
