import { z } from "zod"

import { s } from "../utils/schema"

// This schema currently supports only "create" and "edit" form validation
// It is not ready to be used to generate TypeScript types
export const organizationSchema = s.define("organization", {
  name: z.string().min(1),
  locale: z.string(),
  notes: z.string().nullable().optional(),
})
