import { baseColumnDef, baseNumberColumnDef } from "@/nzds/data-grid"

import type { GridColDef } from "@mui/x-data-grid-pro"

export const baseSiteColumnDef: Partial<GridColDef> = {
  ...baseColumnDef,
  filterable: false,
}

export const baseSiteNumberColumnDef: Partial<GridColDef> = {
  ...baseNumberColumnDef,
  ...baseSiteColumnDef,
}
