import React from "react"

import { line, scaleLinear } from "d3"

import { darkGray } from "../../utils/colors"
import type { GraphData } from "./graphData"

export const MeterLine = ({
  graphData,
  height,
  minWidth,
  tickWidth,
  maxDomainValue,
}: {
  graphData: GraphData[]
  height: number
  maxDomainValue: number
  minWidth: number
  tickWidth: number
}) => {
  const pointCount = graphData.reduce(
    (count, point) => (point.meteredKwh > 0 ? count + 1 : count),
    0
  )

  const padding = 2
  const offset = tickWidth / 2
  const graphWidth = tickWidth * pointCount

  const xLineScale = scaleLinear()
    .domain([0, pointCount])
    .range([
      minWidth + offset - padding,
      graphWidth + minWidth + offset - padding,
    ])

  const yLineScale = scaleLinear()
    .domain([0, maxDomainValue])
    .nice()
    .range([height, 0])

  const lin = line()
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .defined((d, i) => d[1] > 0)
    .x((d, i) => xLineScale(i))
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .y((d, i) => yLineScale(d[1]))

  return (
    <path
      className="line"
      d={lin(graphData.map((data) => [data.hour.unix(), data.meteredKwh]))}
      fill="none"
      stroke={darkGray.toString()}
      strokeDasharray="3,3"
      strokeWidth="2.5"
    />
  )
}
