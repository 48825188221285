import React from "react"

interface INzeroLogoProps {
  height?: number | string
  width?: number | string
}

export const NzeroLogo = ({
  height = "100%",
  width = "100%",
}: INzeroLogoProps) => (
  <svg
    fill="currentColor"
    height={height}
    viewBox="0 0 80 20"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1246_14996)">
      <g clipPath="url(#clip1_1246_14996)">
        <path
          clipRule="evenodd"
          d="M34.5946 7.98853C34.5946 1.2449 24.4065 0.109741 17.2973 0.109741V4.04913C10.1881 4.04913 0 5.18429 0 11.9279C0 18.6716 10.1881 19.8067 17.2973 19.8067V15.8673C24.4065 15.8673 34.5946 14.7322 34.5946 7.98853ZM17.2973 15.8673V11.9279C23.7718 11.9279 33.0502 11.3603 33.0502 7.98853C33.0502 4.61671 23.7718 4.04913 17.2973 4.04913V7.98853C10.8228 7.98853 1.5444 8.5561 1.5444 11.9279C1.5444 15.2997 10.8228 15.8673 17.2973 15.8673Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M75.8564 9.95827C75.8564 12.533 74.3963 14.1589 71.8885 14.1589C69.3807 14.1589 67.9206 12.536 67.9206 9.95827C67.9206 7.38054 69.4227 5.75757 71.8885 5.75757C74.3543 5.75757 75.8564 7.38354 75.8564 9.95827ZM74.429 9.95827C74.429 11.8708 73.5287 13.0786 71.8885 13.0786C70.2483 13.0786 69.348 11.8731 69.348 9.95827C69.348 8.04345 70.2483 6.8379 71.8885 6.8379C73.5287 6.8379 74.429 8.04569 74.429 9.95827Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          d="M63.8397 5.94739H65.2902V7.08108H65.3724C65.4848 6.85539 65.6454 6.6402 65.8539 6.43551C66.0627 6.23081 66.3435 6.07336 66.6965 5.96311C67.0551 5.84766 67.549 5.81878 68.1053 5.87654V7.0732C67.5382 7.01548 67.0616 7.0522 66.6391 7.18345C66.2162 7.30939 65.8873 7.55872 65.6519 7.93135C65.4218 8.30402 65.3069 8.82626 65.3069 9.49808V13.9699H63.8397V5.94739Z"
          fill="currentColor"
        />
        <path
          clipRule="evenodd"
          d="M55.4094 9.95482C55.4094 8.59018 55.7807 7.55094 56.4868 6.83712C57.1932 6.11806 58.1455 5.75854 59.3439 5.75854C60.5529 5.75854 61.4758 6.10494 62.1124 6.79776C62.749 7.49058 63.1039 8.42745 63.1039 9.60842V10.2719H56.8182C56.8343 11.1537 57.0459 11.8338 57.49 12.3482C57.9395 12.8573 58.5575 13.1119 59.3439 13.1119C59.8412 13.1119 60.2508 13.0279 60.5717 12.8599C61.1864 12.5115 61.5778 11.9751 61.6127 11.4585H62.9742C62.9396 11.9614 62.5514 12.795 61.9679 13.3008C61.6874 13.5443 61.3449 13.7601 60.9168 13.9228C60.4887 14.0802 59.9673 14.159 59.352 14.159C58.1535 14.159 57.1985 13.7995 56.4868 13.0804C55.7807 12.3613 55.4094 11.3195 55.4094 9.95482ZM56.8423 9.21039H61.693C61.6877 8.48085 61.5052 7.92885 61.1095 7.4827C60.7135 7.03133 60.1223 6.80564 59.3359 6.80564C58.5656 6.80564 57.9663 7.03133 57.5382 7.4827C57.1157 7.93409 56.8958 8.48609 56.8423 9.21039Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          d="M55.2575 5.94751H48.7836V7.05448H53.5444L48.623 12.8578V13.9701H55.314V12.8578H50.3392L55.2575 7.05448V5.94751Z"
          fill="currentColor"
        />
        <path
          d="M40.7722 5.94745H42.2236V7.08115H42.3049C42.396 6.90793 42.5406 6.72163 42.7382 6.52218C42.9418 6.31748 43.2198 6.14163 43.5728 5.99469C43.9314 5.84248 44.3914 5.76636 44.9532 5.76636C45.8521 5.76636 46.5742 6.00518 47.12 6.48281C47.6658 6.96042 47.9385 7.65851 47.9385 8.57702V13.97H46.4714V8.67148C46.4714 8.04166 46.2947 7.58239 45.9416 7.29372C45.5938 6.99978 45.1886 6.85284 44.6162 6.85284C44.1776 6.85284 43.8101 6.94469 43.4409 7.12839C43.0718 7.31209 42.7932 7.58763 42.5684 7.95505C42.3491 8.32245 42.2394 8.78433 42.2394 9.34069V13.97H40.7722V5.94745Z"
          fill="currentColor"
        />
        <path
          d="M76.7283 6.27682V7.84176H77.1534V6.27682H77.7492V5.9447H76.1325V6.27682H76.7283Z"
          fill="currentColor"
        />
        <path
          d="M77.9336 7.84176H78.329V6.97561C78.329 6.78961 78.3262 6.67004 78.3209 6.48407H78.3317C78.375 6.64613 78.4049 6.74976 78.467 6.93576L78.7704 7.84176H79.1632L79.4666 6.93576C79.5287 6.74976 79.5586 6.64613 79.6019 6.48407H79.6127C79.6074 6.67004 79.6046 6.78961 79.6046 6.97561V7.84176H80V5.9447H79.4558L79.1144 6.96497C79.0576 7.14301 79.0168 7.28913 78.9736 7.46979H78.9628C78.9168 7.28913 78.8788 7.14301 78.8192 6.96497L78.4778 5.9447H77.9336V7.84176Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1246_14996">
        <rect fill="currentColor" height="20" width="80" />
      </clipPath>
      <clipPath id="clip1_1246_14996">
        <rect fill="currentColor" height="20" width="80" />
      </clipPath>
    </defs>
  </svg>
)
