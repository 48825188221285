import type { FC } from "react"
import React from "react"
import { useNavigate } from "react-router-dom"

import { useConfirm } from "material-ui-confirm"

import AddRoundedIcon from "@mui/icons-material/AddRounded"
import { Button } from "@mui/material"

import { useDisclosure } from "../../../../nzds/utils/use-disclosure/use-disclosure"
import OrgViewDialog from "../tamAdminOnboarding/orgView/orgViewDialog/orgViewDialog"
import OrgViewDialogSiteCreateContent from "../tamAdminOnboarding/orgView/orgViewDialog/orgViewDialogSiteCreateContent/orgViewDialogSiteCreateContent"
import type { CreateSiteButtonProps } from "./create-site-button.types"

export const CreateSiteButton: FC<CreateSiteButtonProps> = ({
  parentObjectIds,
  redirectPath,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const confirm = useConfirm()

  const onSuccess = (newEntityId: string) => {
    navigate(`${redirectPath}/${newEntityId}`)
  }

  const showErrorDialog = async (errorMessage: string) => {
    await confirm({
      title: `Error creating site`,
      description:
        errorMessage ??
        "An error occurred while creating the site. Please try again.",
      confirmationText: "Close",
      hideCancelButton: true,
      confirmationButtonProps: {
        variant: "text",
        color: "primary",
      },
    })
  }

  const onError = (errorMessage: string) => {
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    showErrorDialog(errorMessage)
  }

  return (
    <>
      <Button
        aria-label="Create Site"
        onClick={onOpen}
        size="small"
        startIcon={<AddRoundedIcon fontSize="small" />}
        sx={{
          backgroundColor: "primary.light",
          color: (theme) => theme.palette.spectrum.blue[800],
          "&:hover": {
            backgroundColor: (theme) => theme.palette.spectrum.blue[200], // 20% darker color
          },
        }}
        variant="contained"
      >
        Create Site
      </Button>
      <OrgViewDialog
        content={
          <OrgViewDialogSiteCreateContent
            objectName="Site"
            onClose={onClose}
            onError={onError}
            onSuccess={onSuccess}
            parentObjectIds={parentObjectIds}
          />
        }
        onClose={onClose}
        open={isOpen}
        title="Create Site"
      />
    </>
  )
}
