import type { RecordProxy } from "spraypaint/lib-esm/proxies"

import Site from "../../../../models/site"

/**
 * Gets site by id, same as function portion of getSiteByID in Site provider
 */
export const getSite = async (siteId: string): Promise<Site | undefined> => {
  const response: RecordProxy<Site> = await Site.includes([
    "organization",
    "address",
    "location",
    "physical_details",
  ])
    .selectExtra([
      "sq_ft",
      "number_of_floors",
      "building_type",
      "department_name",
    ])
    .find(siteId)

  return response.data
}
