import { forEach, mapValues } from "lodash-es"

import type {
  RelationshipMeta,
  SchemaDefinition,
  SchemasArgument,
} from "./types"

type HydrateSchemasObject<SchemasT extends Record<string, SchemaDefinition>> = {
  [S in SchemasT[keyof SchemasT] as S["name"]]: Omit<
    S,
    "isHydrated" | "relationshipsByName"
  > & {
    isHydrated: true
    relationshipsByName: {
      [K in keyof S["relationshipsMeta"]]: SchemasT[S["relationshipsMeta"][K]["targetName"]]
    }
  }
}

export type HydratedSchemas = HydrateSchemas<Record<string, SchemaDefinition>>

export type HydratedSchema = HydratedSchemas[string]

// TODO: Also accept a single hydrated schema and infer SchemasObject
// TODO: Option to do this destructively?

export type HydrateSchemas<SchemasT extends SchemasArgument> =
  HydrateSchemasObject<{
    [S in SchemasT extends ArrayLike<infer V>
      ? V
      : SchemasT[keyof SchemasT] as S["name"]]: S
  }>

/**
 * Add references based on relationships between all provided schemas.
 * Returns an object of new schemas indexed by schema name.
 */
export const hydrateSchemas = <SchemasT extends SchemasArgument>(
  schemasArg: SchemasT
) => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const schemas = Array.isArray(schemasArg)
    ? Object.fromEntries(
        schemasArg.map((schema) => {
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (!schema.name) {
            throw new Error("Schema does not have a name set.")
          }
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          return [schema.name, schema]
        })
      )
    : schemasArg

  const hydratedSchemas = mapValues(schemas, (schema: SchemaDefinition) => {
    if (schema.isHydrated) {
      return schema
    }

    const relationshipsByName: Record<string, SchemaDefinition> = {}
    const relationshipsByTarget: Record<string, SchemaDefinition> = {}
    forEach(
      schema.relationshipsMeta,
      ({ targetName }: RelationshipMeta, relationshipName: string) => {
        // migration to strict mode batch disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const targetSchema = schemas[targetName]
        if (!targetSchema) {
          throw new Error(`Relationship "${targetName}" not found.`)
        }
        // TODO: Warn if the other side of a hasOne or a hasMany is also a hasOne.
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        relationshipsByName[relationshipName] = targetSchema
        // migration to strict mode batch disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        relationshipsByTarget[targetSchema.name] = targetSchema
      }
    )
    return {
      ...schema,
      relationshipsByName,
      isHydrated: true,
    }
  })

  return hydratedSchemas as unknown as HydrateSchemas<SchemasT> // TODO: Remove coercion
}
