import { Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class OrganizationEmissions extends ApplicationRecord {
  public static jsonapiType = "organization_emissions"
}

/**
 * Fetches the organization emissions count for an organization
 *
 * @param orgId - The organization id
 * @returns - The organization emissions count
 * @example
 * fetchOrganizationEmissionsCount("15")
 */
export const fetchOrganizationEmissionsCount = async (
  orgId: string
): Promise<number> => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: number = await OrganizationEmissions.where({
    organization_id: orgId,
  })
    .stats({
      total: "count",
    })
    .per(0)
    .all()
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    .then((res: any) => res.meta.stats.total.count)

  return response
}
