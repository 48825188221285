import { useQuery } from "react-query"

import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import { fetchOrganizationCounts } from "../../models/organization"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useOrganizationCounts = (
  organizationId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const queryKey = useQueryKey(
    ApiQueryName.OrganizationCounts,
    "getOne",
    organizationId
  )

  const queryResult = useQuery({
    queryKey,
    queryFn: () => fetchOrganizationCounts(organizationId),
    enabled: !isNil(organizationId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type OrganizationCounts = InferredQueryServiceValue<
  typeof useOrganizationCounts,
  ApiQueryName.OrganizationCounts
>
