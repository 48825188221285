import React, { useImperativeHandle, useRef } from "react"
import type { FieldPath, FieldValues } from "react-hook-form"
import { useController } from "react-hook-form"

import { Checkbox } from "../../inputs/checkbox/checkbox"
import type { FormCheckboxProps } from "./form-checkbox.types"

export const FormCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  id,
  inputRef,
  helperText,
  ...rest
}: FormCheckboxProps<TFieldValues, TName>) => {
  const { field, fieldState } = useController({
    name,
  })

  const { ref: fieldRef, ...fieldRest } = field

  const innerRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    inputRef,
    () => innerRef.current,
    []
  )

  return (
    // helperText is always a string to avoid jarring UI shifts
    <Checkbox
      {...rest}
      {...fieldRest}
      checked={Boolean(field.value)}
      error={Boolean(fieldState.error)}
      helperText={
        fieldState.error ? fieldState.error.message : (helperText ?? " ")
      }
      id={id ?? name}
      inputRef={(node) => {
        fieldRef(node)
        innerRef.current = node
      }}
    />
  )
}
