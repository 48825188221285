import { useEffect, useMemo, useRef, useState } from "react"

import type { Selection } from "d3"
import { select } from "d3"

export interface IPerimeter {
  height: number
  width: number
}

// DEPRECATED: DO NOT USE
/**
 * @deprecated
 */
export const useD3 = (
  renderChartFn,
  ref,
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  perimeter?: IPerimeter,
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: any[] = []
) => {
  const nodeRef = useRef<SVGSVGElement | null>(null)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [selection, setSelection] = useState<null | Selection<
    SVGGElement | null,
    unknown,
    null,
    undefined
  >>(null)
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [dimensions, setDimensions] = useState(null)
  // migration to strict mode batch disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
  const renderChart = useMemo(() => renderChartFn, [renderChartFn])
  const GRAPH_TOP_OFFSET = 4

  const setSVGDimensions = (node) => {
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    node
      .attr("width", perimeter.width)
      .attr("height", perimeter.height)
      .attr("viewBox", `0 0 ${perimeter.width} ${perimeter.height}`)

    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return node
  }

  useEffect(() => {
    if (!perimeter?.height && !perimeter?.width) {
      return
    }

    if (!selection) {
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const owner = ref.current

      let newSVG:
        | undefined
        | Selection<SVGGElement | null, unknown, null, undefined>

      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (owner?.nodeName !== "SVG" && owner?.children?.length === 0) {
        setDimensions(perimeter)

        newSVG = select(owner)
          .append("svg")
          .call(setSVGDimensions)
          .append("g")
          .attr("transform", `translate(0,${GRAPH_TOP_OFFSET})`)
      }

      // Get the raw SVG from from the Selection so we don't have a nested Selection up when set
      const svg = newSVG?.node() ?? nodeRef.current

      if (svg) {
        setSelection(select(svg))
      }
    } else if (data?.length > 0 && perimeter?.height && perimeter?.width) {
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const isResizeEvent = perimeter.width !== dimensions?.width

      if (isResizeEvent) {
        setDimensions(perimeter)
        selection.selectAll("*").remove()
        selection.call((node) =>
          // Mass eslint disable @typescript-eslint/no-unsafe-return
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          setSVGDimensions(select(node.node().parentElement))
        )
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        renderChart(selection, isInitialized, isResizeEvent)
      } else {
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        renderChart(selection, isInitialized, isResizeEvent)
      }

      if (!isInitialized) {
        setIsInitialized(true)
      }
    }

    return () => {
      /* noop */
    }

    /* The dependencies are passed so we are not sure what they will be here */
    // migration to strict mode batch disable
    // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-unsafe-assignment
  }, [renderChartFn, ref, data, perimeter, ...dependencies])

  return nodeRef
}
