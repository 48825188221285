import {
  baseStringSchema,
  emailSchema,
  passwordSchema,
  requiredMessage,
  validatePasswordMatch,
  validatePasswordMatchParams,
} from "@/schemas"
import z from "zod"

export const signUpSchema = z
  .object({
    confirmPassword: baseStringSchema,
    email: emailSchema,
    firstName: baseStringSchema.min(1, { message: requiredMessage }),
    lastName: baseStringSchema.min(1, { message: requiredMessage }),
    organizationName: baseStringSchema.min(1, { message: requiredMessage }),
    password: passwordSchema,
    recaptcha: z.string().min(1),
    termsAndConditions: z.coerce.boolean().refine((value) => value === true, {
      message: requiredMessage,
    }),
    title: baseStringSchema.optional(),
  })
  // Why partial? To allow the confirmPassword validation to display without having to complete the entire form.
  // See https://github.com/colinhacks/zod/issues/479#issuecomment-854822386
  .partial()
  .refine(validatePasswordMatch, validatePasswordMatchParams)

export type SignUpSchema = z.infer<typeof signUpSchema>
