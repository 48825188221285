import React, { useImperativeHandle, useRef } from "react"
import type { FieldPath, FieldValues } from "react-hook-form"
import { useController } from "react-hook-form"

import { SelectField } from "../../inputs/select-field"
import type { FormSelectFieldProps } from "./form-select-field.types"

export const FormSelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  id,
  helperText,
  required,
  inputRef,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...rest
}: FormSelectFieldProps<TFieldValues, TName>) => {
  const {
    field: { ref: fieldRef, ...restField },
    fieldState,
  } = useController({
    name,
  })

  const innerRef = useRef<HTMLSelectElement | null>(null)

  useImperativeHandle<HTMLSelectElement | null, HTMLSelectElement | null>(
    inputRef,
    () => innerRef.current,
    []
  )

  return (
    <SelectField
      {...rest}
      {...restField}
      error={Boolean(fieldState.error)}
      helperText={fieldState.error ? fieldState.error.message : helperText}
      id={id ?? name}
      inputRef={(node) => {
        fieldRef(node)
        innerRef.current = node as HTMLSelectElement
      }}
      required={required}
    />
  )
}
