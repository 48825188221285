import type { CustomErrorParams } from "zod"

import { getMinLengthMessage } from "./schema-utils"
import { baseStringSchema } from "./string.schema"

const minPasswordLength = 8
export const passwordRequirementsMessage = `Must be at least ${minPasswordLength} characters`

export const passwordSchema = baseStringSchema.min(minPasswordLength, {
  message: getMinLengthMessage(minPasswordLength),
})

export const validatePasswordMatch = ({ confirmPassword, password }) =>
  confirmPassword === password

export const validatePasswordMatchParams: CustomErrorParams = {
  message: "Passwords must match",
  path: ["confirmPassword"],
}
