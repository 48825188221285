import React from "react"
import type { ReactNode } from "react"

import moment from "moment-timezone"

interface OrganizationDashboardResolverProps {
  children: ReactNode
}

/**
 * A component that ensures the organization dashboard is set up before rendering
 *
 * @example
 * return (
 *   <OrganizationDashboardResolver>
 *     <OrganizationDashboard />
 *   </OrganizationDashboardResolver>
 * )
 */
export const OrganizationDashboardResolver = ({
  children,
}: OrganizationDashboardResolverProps) => {
  // Ensure timezone is the client's timezone
  moment.tz.setDefault()

  return <>{children}</>
}

export default OrganizationDashboardResolver
