import type { Moment } from "moment"

import ServiceHour from "../models/serviceHour"

export interface IFetchEmissionsAndEnergyVolume {
  ytdCarbonBasedKwh: number
  ytdCarbonEmissionsInMtCo2e: number
  ytdCarbonFreeKwh: number
}

export interface IFetchEnergyAllocationStats {
  totalCarbon: number
  totalCarbonBasedMWH: number
  totalCarbonFreeMWH: number
  totalCost: number
  totalMWH: number
}

// TODO: Move this function when we can remove organizationSummary
export const fetchShortImbalance = (
  siteIds: string[],
  startEndTimes: Moment[]
): Promise<{
  shortImbalanceCo2eLbs: number
  shortImbalanceKwh: number
}> =>
  ServiceHour.stats({
    short_imbalance_totals: "calculate",
  })
    .where({
      hour: {
        gte: startEndTimes[0].clone().format(),
        lt: startEndTimes[1].clone().endOf("day").format(),
      },
      site_id: siteIds,
    })
    .per(0)
    .all()
    .then((result) => {
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const stats = result.meta.stats.short_imbalance_totals.calculate
      return {
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        shortImbalanceKwh: Math.abs(parseFloat(stats.short_imbalance_kwh)),
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        shortImbalanceCo2eLbs: parseFloat(stats.short_imbalance_co2e_lbs),
      }
    })
