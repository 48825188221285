import { z } from "zod"

// Used to determine if any address fields have been filled out
const emptyAddressSchema = z.object({
  addressLine1: z.null().or(z.undefined()),
  addressLine2: z.null().or(z.undefined()),
  city: z.null().or(z.undefined()),
  state: z.null().or(z.undefined()),
  country: z.null().or(z.undefined()),
  postalCode: z.null().or(z.undefined()),
})

export const isAddressEmpty = (address: object) =>
  emptyAddressSchema.safeParse(address).success

export const schema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1, "Required"),
    currencyId: z.string().nullable().optional(),
    locationId: z.string().min(1, "Required"),
    notes: z.string().nullable().optional(),
    tariffMajor: z.coerce.number().nullable().optional(),
    yearBuilt: z.coerce.number().nullable().optional(),
    baCode: z.string().min(1, "Required"),
    electricityType: z.enum(["direct_access", "bundled"]).or(z.literal("")),
    siteAlias: z.string().nullable().optional(),
    sqFt: z.coerce.number().nullable().optional(),
    granularity: z.string().nullable().optional(),
    address: z.object({
      address_line_1: z.string().nullable().optional(),
      address_line_2: z.string().nullable().optional(),
      city: z.string().nullable().optional(),
      state: z.string().nullable().optional(),
      country: z.string().nullable().optional(),
      postal_code: z.string().nullable().optional(),
    }),
  })
  .superRefine(({ address }, context) => {
    // If any address field has a value, we need to make sure that all required fields are present
    // If there are any missing, we add an error to the field(s)
    if (!isAddressEmpty(address)) {
      if (!address.address_line_1) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: ["address", "address_line_1"],
        })
      }
      if (!address.city) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: ["address", "city"],
        })
      }
      if (!address.postal_code) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: ["address", "postal_code"],
        })
      }
      if (["US", "CA"].includes(address.country) && !address.state) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: ["address", "state"],
        })
      }
    }
  })

export type Site = z.infer<typeof schema>
