import React from "react"
import type { CSSProperties, FC } from "react"

interface NzeroSymbolLeftProps {
  height?: number | string
  style?: CSSProperties
  width?: number | string
}

export const NzeroSymbolLeft: FC<NzeroSymbolLeftProps> = ({
  height = "100%",
  style,
  width = "100%",
}) => (
  <svg
    height={height}
    fill="currentColor"
    style={style}
    viewBox="0 0 56 52"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 26C0 3.746 32.9839 0 56 0V13C35.0389 13 5 14.873 5 26C5 37.127 35.0389 39 56 39V52C32.9839 52 0 48.254 0 26Z" />
  </svg>
)
