import type { UseMutationOptions } from "react-query"
import { useMutation, useQueryClient } from "react-query"

import type { Operations } from "@/services/types"
import type { AxiosError } from "axios"

import { ApiQueryName } from "../../../../models/api"
import { deleteSitePlan } from "./delete-site-plan"
import type { DeleteSitePlanProps } from "./delete-site-plan"

interface UseDeleteSitePlanOptions {
  queryOptions?: Omit<
    UseMutationOptions<
      Operations["delete_site_plan_api_v1_plans__site_id__delete"]["responses"]["200"],
      AxiosError<
        Operations["delete_site_plan_api_v1_plans__site_id__delete"]["responses"]["422"]["content"]["application/json"]
      >,
      DeleteSitePlanProps
    >,
    "mutationFn"
  >
}

/**
 * returns a mutation to delete a plan for a site
 */
export const useDeleteSitePlan = (options?: UseDeleteSitePlanOptions) => {
  const queryClient = useQueryClient()
  const mutation = useMutation(deleteSitePlan, {
    ...(options?.queryOptions ?? {}),
    onSuccess: (data, variables, context) => {
      if (options?.queryOptions?.onSuccess) {
        void options.queryOptions.onSuccess(data, variables, context)
      }
      void queryClient.invalidateQueries([
        ApiQueryName.RecommendationsPlans,
        "getMany",
      ])
      void queryClient.invalidateQueries([
        ApiQueryName.RecommendationSite,
        "getOne",
      ])
      void queryClient.invalidateQueries([
        ApiQueryName.RecommendationsPotentialSites,
        "getMany",
      ])
    },
  })
  return mutation
}
