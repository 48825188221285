// @ts-strict-ignore
import type { PersistedSpraypaintRecord } from "spraypaint"

import SiteEmissionDay from "../../../../models/siteEmissionDay"
import { convertMmbtuToTherms } from "../../../../utils"

export const getSiteDailyNaturalGasEmissions = (siteId, timeRange) =>
  SiteEmissionDay.where({
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    site_id: siteId,
    day: {
      // migration to strict mode batch disable
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      gte: timeRange.start.format(),
      // migration to strict mode batch disable
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      lte: timeRange.end.format(),
    },
    ghg_emissions_factor: {
      eq: {
        category: "Stationary Combustion",
        sub_category: "Natural Gas",
        unit: "mmbtu",
      },
    },
    scope: 1,
  })
    .all()
    .then(({ data }) =>
      data.map(
        (siteEmissionDay) =>
          ({
            ...siteEmissionDay,
            amount: convertMmbtuToTherms(Number(siteEmissionDay.amount)),
          }) as PersistedSpraypaintRecord<SiteEmissionDay>
      )
    )
