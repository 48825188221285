import React from "react"

import type { PaperProps } from "@mui/material"
import { CircularProgress, Paper, Typography } from "@mui/material"
import withStyles from "@mui/styles/withStyles"

import { TYPOGRAPHY_VARIANT } from "../models/typography"

const SpinnerContainer = withStyles({
  root: {
    margin: "auto",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    backgroundColor: "unset",
    justifyContent: "center",
    height: "100%",
  },
})(Paper)

const LoadingText = withStyles({ root: { marginTop: "1rem" } })(Typography)

export const LoadingSpinner = ({
  text = true,
  size,
  color,
  classes,
  dataE2e,
  ...rest
}: PaperProps & {
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classes?: Record<string, any>
  color?: string
  dataE2e?: string
  size?: number
  text?: boolean
}) => (
  <SpinnerContainer
    className={rest.className ?? classes?.root ?? null}
    data-e2e={dataE2e}
    elevation={rest.elevation ? rest.elevation : 0}
    style={rest.style}
  >
    <CircularProgress size={size || 50} style={color ? { color } : {}} />
    {text && (
      <LoadingText variant={TYPOGRAPHY_VARIANT.h2}>
        {rest.children ? rest.children : "Loading data..."}
      </LoadingText>
    )}
  </SpinnerContainer>
)
