import React from "react"
import type { FC } from "react"

import type { ErrorDialogContentProps } from "./error-dialog-content.types"

export const ErrorDialogContent: FC<ErrorDialogContentProps> = ({ error }) => {
  return Array.isArray(error) ? (
    <ul>
      {error.map((record) => {
        const isHeaderRow = record.row === -1
        const rowNumber = record.row + 1
        const rowName = isHeaderRow ? "Header Row" : `Row ${rowNumber}`

        return (
          <li key={`${rowNumber}-${record.column}`}>
            {rowName}: {record.description}
          </li>
        )
      })}
    </ul>
  ) : null
}
