import React from "react"

import type { UnitName } from "../../models/unit"
import { useUnit } from "./use-unit"

interface IUnitProps {
  unit: UnitName
  value?: number | string | null
}

/**
 * A component that properly formats and internationalizes the provided unit
 *
 * @param props - The component properties
 * @param props.unit - The unit
 * @param props.value - The number to use for handling pluralization
 * @example
 * return (
 *   <Unit unit={SIUnit.KillowattHour} value={electricityUse} />
 * )
 */
export const Unit = ({ unit, value }: IUnitProps) => {
  const unitString = useUnit(unit, value)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return unitString ? <>{unitString}</> : null
}
