import React from "react"
import type { FC } from "react"
import { useParams } from "react-router-dom"

import Page404 from "@/components/nav/page404/page404"
import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { Page } from "@/components/page"
import { useSigmaWorkbook } from "@/services/sigma"

import { ReportingLink } from "../../components/reporting-link"
import { SigmaReport } from "../../components/sigma-report"

export const SigmaReportRoute: FC = () => {
  const { workbookId } = useParams<{ workbookId: string }>()
  const { sigmaWorkbookData } = useSigmaWorkbook(workbookId)

  return sigmaWorkbookData === null ? (
    <Page404 />
  ) : (
    <>
      <PageHeader title={sigmaWorkbookData?.name ?? ""} />
      <PageHeaderActionBar hasTabs={false}>
        <ReportingLink />
      </PageHeaderActionBar>
      <Page fullHeight>
        <SigmaReport workbookId={workbookId} />
      </Page>
    </>
  )
}
