import React from "react"

import { Grid, Typography } from "@mui/material"

import { EmissionDecimal } from "../../../../../../components/decimal/emissionDecimal/emissionDecimal"
import { UsageRateDecimal } from "../../../../../../components/decimal/usageRateDecimal/usageRateDecimal"
import Metric from "../../../../../../components/metric/metric"
import { Unit } from "../../../../../../components/unit/unit"
import { UnitName } from "../../../../../../models/unit"
import { KWH_PER_MWH } from "../../../../../../utils/constants"
import type { IFetchEmissionsAndEnergyVolume } from "../../../../../../utils/spraypaintApi"

interface CarbonMetricsProps {
  emissionsAndEnergyVolume: IFetchEmissionsAndEnergyVolume
  gridRate: number
  isBundledAmi: boolean
  ytdMeteredKwh: number
}

const CarbonMetrics = ({
  gridRate,
  emissionsAndEnergyVolume,
  ytdMeteredKwh,
  isBundledAmi,
}: CarbonMetricsProps): JSX.Element => {
  const ytdMeteredMwh: number = ytdMeteredKwh / KWH_PER_MWH
  const ytdCarbonEmissionsInMtCo2e: number =
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    emissionsAndEnergyVolume?.ytdCarbonEmissionsInMtCo2e
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const ytdCarbonFreeKwh: number = emissionsAndEnergyVolume?.ytdCarbonFreeKwh

  const carbonIntensity: number =
    ytdMeteredMwh > 0 ? ytdCarbonEmissionsInMtCo2e / ytdMeteredMwh : 0
  const gridComparisonPercentage: number =
    (1 - carbonIntensity / gridRate) * 100
  const carbonFreeIntensity: number = ytdCarbonFreeKwh / KWH_PER_MWH

  const isGridComparisonNegative: boolean = gridComparisonPercentage < 0

  const xs = isBundledAmi ? 12 : 6
  const lg = isBundledAmi ? 6 : 3

  return (
    <Grid container spacing={1}>
      <Grid item lg={lg} xs={xs}>
        <Metric title="Carbon Intensity YTD (CI)">
          <div>
            <UsageRateDecimal value={carbonIntensity} />
          </div>
          <Typography variant="caption">
            <Unit
              unit={UnitName.MetricTonsOfCarbonDioxideEquivalentPerMegawattHour}
            />
          </Typography>
        </Metric>
      </Grid>
      <Grid item lg={lg} xs={xs}>
        <Metric title="CI Grid Comparison YTD">
          <div>
            <UsageRateDecimal value={Math.abs(gridComparisonPercentage)} />
          </div>
          {/* TODO: Internationalize the percentage sign */}
          <Typography variant="caption">%</Typography>
          <Typography variant="caption">
            {isGridComparisonNegative ? "more" : "less"} than the grid average
          </Typography>
        </Metric>
      </Grid>
      <Grid item lg={lg} xs={xs}>
        <Metric title="Carbon Free Electricity YTD">
          <div>
            <UsageRateDecimal value={carbonFreeIntensity} />
          </div>
          <Typography variant="caption">
            <Unit unit={UnitName.MegawattHour} />
          </Typography>
        </Metric>
      </Grid>
      <Grid item lg={lg} xs={xs}>
        <Metric title="Total Emissions">
          <div>
            <EmissionDecimal value={ytdCarbonEmissionsInMtCo2e} />
          </div>
          <Typography variant="caption">
            <Unit unit={UnitName.MetricTonsOfCarbonDioxideEquivalent} />
          </Typography>
        </Metric>
      </Grid>
    </Grid>
  )
}

export default CarbonMetrics
