import type DateFilter from "../../../models/filter/dateFilter"
import {
  cerulean200,
  darkBlue,
  lightestBlue,
  newBlue,
  peach100,
} from "../../../utils"

const SCOPE_CARD_HEIGHT = 468

interface OrgDashboardDataE2eAttributes {
  energyConsumptionValue: string
  energyCostValue: string
  scopeOneEmissionsValue: string
  scopeThreeEmissionsValue: string
  totalEmissionsValue: string
  waterConsumptionValue: string
}

interface AllScopeEmissionsData {
  graphData: ScopeEmissionsGraphItem[]
  graphKeys: ScopeGraphKeyNames[]
  scopeTotals: ScopeEmissionsScopeTotal[]
  totalEmissions: number
}

interface ScopeOneEmissionData {
  name: string
  order: number
}

interface ScopeOneEmissionDataMap {
  fugitive: ScopeOneEmissionData
  "mobile combustion": ScopeOneEmissionData
  "stationary combustion": ScopeOneEmissionData
}

interface ScopeOneEmissionsGraphItem {
  /**
   * The fugitive for a scope one emission
   */
  Fugitive?: number
  /**
   * The mobile combustion for a scope one emission
   */
  "Mobile Combustion"?: number
  /**
   * The stationary combustion for a scope one emission
   */
  "Stationary Combustion"?: number
  /**
   * The date of the scope two emission
   */
  date: string
  /**
   * The index of the scope two emission
   */
  index?: number
}

interface ScopeTwoEmissionsGraphItem {
  /**
   * The costMajor for a scope two emission
   */
  costMajor: number
  /**
   * The date of the scope two emission
   */
  date: string
  /**
   * The index of the scope two emission
   */
  index?: number
  /**
   * The value of the scope two emission
   */
  value: number
}

interface ScopeThreeEmissionsGraphItem {
  categoryName: string
  mtCo2E: number
}

interface ScopeEmissionsGraphItem {
  "Scope 1"?: number
  "Scope 2"?: number
  "Scope 3"?: number
  date: string
  index?: number
}

interface ScopeEmissionsScopeTotal {
  /**
   * @param order The display sort order
   */
  order: number
  /**
   * @param scope Scope type (1,2,3)
   */
  scope: number
  /**
   * @param total Scope's total MT CO2e Emissions
   */
  value: number
}

interface OrganizationDashboardTheme {
  emissionsByScope: Record<string, string>
  scopeOne: Record<string, string>
  scopeThree: Record<string, string>
  scopeTwo: Record<string, string>
}

type ScopeGraphKeyNames = "Scope 1" | "Scope 2" | "Scope 3"

const scopeOneCategoryToGraphKeyMap: ScopeOneEmissionDataMap = {
  "mobile combustion": { name: "mobile combustion", order: 0 },
  "stationary combustion": { name: "stationary combustion", order: 1 },
  fugitive: { name: "fugitive", order: 2 },
}

const organizationDashboardGraphTheme: OrganizationDashboardTheme = {
  emissionsByScope: {
    "Scope 1": newBlue.toString(),
    "Scope 2": cerulean200.toString(),
    "Scope 3": peach100.toString(),
  },
  scopeOne: {
    "mobile combustion": newBlue.toString(),
    "stationary combustion": lightestBlue.toString(),
    fugitive: darkBlue.toString(),
  },
  scopeTwo: {
    "Scope 2": cerulean200.toString(),
  },
  scopeThree: {
    "Scope 3": peach100.toString(),
  },
}

const scopeEmissionToolTipDescriptions: Record<string, string[]> = {
  scope3: [
    "Emissions that are the result of activities from assets not owned or controlled by your organization, but that indirectly impact your value chain.",
    "There are 15 possible GHG protocol categories. Here we show your org's top categories (up to 5).",
  ],
  scope2: [
    "Indirect GHG emissions associated with the purchase of electricity, steam, heat, or cooling.",
  ],
  scope2CarbonIntensity: [
    "Measures how clean the electricity is that your organization consumes. It indicates how many metric tons of carbon dioxide equivalent (CO2e) are released to produce a megawatt hour (MWh) of electricity.",
  ],
  scope1: [
    "Direct GHG emissions that occur from sources that are controlled or owned by your organization. There are four categories of emissions:",
    "1. Mobile combustion: Transportation (gasoline, diesel fuel, etc.)",
    "2. Stationary combustion: Boilers, furnaces, turbines, incinerators, engines, gas ranges (natural gas, diesel fuel, etc.)",
    "3. Fugitive: Equipment leaks, refrigeration, industrial gases, wastewater treatment (methane, HFCs, HCFCs, etc.)",
    "4. Process: Physical or chemical processing (cement, aluminum, etc.)",
  ],
}

interface EmissionDayStats {
  amount: number
  kgCo2e: number
  name: string
  scope: number
}

interface OrganizationDashboardFilters {
  end?: DateFilter
  start?: DateFilter
}

interface AreFiltersValidProps {
  filters: OrganizationDashboardFilters
  organizationId: string
}

type EmissionRecord<
  T =
    | ScopeThreeEmissionsGraphItem
    | ScopeTwoEmissionsGraphItem
    | ScopeOneEmissionsGraphItem,
> = Record<number, T>

type GraphData = EmissionRecord<
  | ScopeOneEmissionsGraphItem
  | ScopeTwoEmissionsGraphItem
  | ScopeEmissionsGraphItem
>

export {
  SCOPE_CARD_HEIGHT,
  organizationDashboardGraphTheme,
  scopeOneCategoryToGraphKeyMap,
  scopeEmissionToolTipDescriptions,
  type GraphData,
  type EmissionRecord,
  type AllScopeEmissionsData,
  type AreFiltersValidProps,
  type OrganizationDashboardFilters,
  type ScopeGraphKeyNames,
  type ScopeOneEmissionData,
  type ScopeOneEmissionDataMap,
  type ScopeOneEmissionsGraphItem,
  type ScopeTwoEmissionsGraphItem,
  type ScopeThreeEmissionsGraphItem,
  type ScopeEmissionsGraphItem,
  type ScopeEmissionsScopeTotal,
  type OrganizationDashboardTheme,
  type OrgDashboardDataE2eAttributes,
  type EmissionDayStats,
}
