import Site from "../../../../models/site"

interface IFetchSiteExtraAttributes {
  hasAmiMeter: boolean
  hasAmrMeter: boolean
  hasSubMeter: boolean
  probeMeter: boolean
}

export const fetchSiteExtraAttributes = ({
  siteId,
}: {
  siteId: string
}): Promise<IFetchSiteExtraAttributes> =>
  Site.selectExtra([
    "has_ami_meter",
    "has_amr_meter",
    "has_sub_meter",
    "probe_meter",
  ])
    .find(siteId)
    .then((fetchedSite) => ({
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      hasAmiMeter: fetchedSite.data.attributes.hasAmiMeter,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      hasAmrMeter: fetchedSite.data.attributes.hasAmrMeter,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      hasSubMeter: fetchedSite.data.attributes.hasSubMeter,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      probeMeter: fetchedSite.data.attributes.probeMeter,
    }))
