// @ts-strict-ignore
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { Grid, Typography } from "@mui/material"

import { CurrencyWithPlaceholder } from "../../../../../../components/currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { DecimalWithPlaceholder } from "../../../../../../components/decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { UsageRateDecimal } from "../../../../../../components/decimal/usageRateDecimal/usageRateDecimal"
import Metric from "../../../../../../components/metric/metric"
import { Unit } from "../../../../../../components/unit/unit"
import { MeasurementSystem } from "../../../../../../models/measurementSystem"
import { UnitName } from "../../../../../../models/unit"
import {
  getMeasurementSystem,
  squareFootToSquareMeter,
} from "../../../../../../utils"
import {
  KWH_PER_MWH,
  LBS_PER_METRIC_TON,
} from "../../../../../../utils/constants"
import type { IFetchEnergyAllocationStats } from "../../../../../../utils/spraypaintApi"

interface BuildingMetricsProps {
  isMwhSelected: boolean
  siteEnergyAllocationStats: IFetchEnergyAllocationStats
  squareFoot: number | null
}

const BuildingMetrics = ({
  isMwhSelected,
  siteEnergyAllocationStats,
  squareFoot,
}: BuildingMetricsProps): JSX.Element => {
  const { i18n } = useTranslation()
  const isMetric = useMemo(
    () => getMeasurementSystem(i18n.language) !== MeasurementSystem.USCU,
    [i18n.language]
  )
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const totalMWH = siteEnergyAllocationStats?.totalMWH
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const totalCarbon = siteEnergyAllocationStats?.totalCarbon
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const totalCost = siteEnergyAllocationStats?.totalCost
  const carbonInMtCo2e = totalCarbon / LBS_PER_METRIC_TON

  // Building Metrics
  const calculatedArea = isMetric
    ? squareFootToSquareMeter(squareFoot)
    : squareFoot
  const usageIntensity = totalMWH / calculatedArea
  const costIntensity = totalCost / calculatedArea
  const emissionIntensity = carbonInMtCo2e / calculatedArea

  return (
    <Grid container spacing={1}>
      <Grid item lg={6} xs={12}>
        <Metric title={isMetric ? "Square Meter" : "Square Footage"}>
          <Typography>
            <DecimalWithPlaceholder
              format={{ precision: 0 }}
              value={calculatedArea}
            />
          </Typography>
          <Typography variant="caption">
            <Unit unit={UnitName.SquareFoot} />
          </Typography>
        </Metric>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Metric title="Usage Intensity">
          <Typography>
            <UsageRateDecimal
              value={
                isMwhSelected ? usageIntensity : usageIntensity * KWH_PER_MWH
              }
            />
          </Typography>
          <Typography variant="caption">
            <Unit
              unit={
                isMwhSelected
                  ? UnitName.MegawattHourPerSquareFoot
                  : UnitName.KilowattHourPerSquareFoot
              }
            />
          </Typography>
        </Metric>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Metric title="Cost Intensity">
          <Typography>
            <CurrencyWithPlaceholder
              format={{ precision: 3 }}
              value={costIntensity}
            />
          </Typography>
          <Typography variant="caption">
            <Unit unit={UnitName.PerSquareFoot} />
          </Typography>
        </Metric>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Metric title="Emissions Intensity">
          <Typography>
            <UsageRateDecimal value={emissionIntensity} />
          </Typography>
          <Typography variant="caption">
            <Unit
              unit={UnitName.MetricTonsOfCarbonDioxideEquivalentPerSquareFoot}
            />
          </Typography>
        </Metric>
      </Grid>
    </Grid>
  )
}

export default BuildingMetrics
