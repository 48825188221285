import { useQuery } from "react-query"

import { isNil } from "lodash-es"

import { ApiQueryName } from "../../models/api"
import { fetchBillingGroupCounts } from "../../models/billingGroup"
import type {
  InferredQueryServiceValue,
  ServiceEntityKey,
  ServiceOptions,
} from "../types"
import { useQueryKey } from "../utils/useQueryKey"
import { useRenamedQueryResult } from "../utils/useRenamedQueryResult/useRenamedQueryResult"

export const useBillingGroupCounts = (
  billingGroupId: ServiceEntityKey,
  { disabled: isDisabled }: ServiceOptions<"getOne"> = {}
) => {
  const queryKey = useQueryKey(
    ApiQueryName.BillingGroupCounts,
    "getOne",
    billingGroupId
  )

  const queryResult = useQuery({
    queryKey,
    queryFn: () => fetchBillingGroupCounts(billingGroupId),
    enabled: !isNil(billingGroupId) && !isDisabled,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}

export type BillingGroupCounts = InferredQueryServiceValue<
  typeof useBillingGroupCounts,
  ApiQueryName.BillingGroupCounts
>
