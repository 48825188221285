import { MonthlySitesReportKey } from "../modules/report/models/monthlySite"
import MonthSummary from "./monthSummary"

export interface ElectricityEmissionsFactorRecord {
  id: string
  mtCo2eKeyName: string
}

interface ElectricityEmissionsFactorMap {
  AdvancedGridStudy: ElectricityEmissionsFactorRecord
  LocationBased: ElectricityEmissionsFactorRecord
}

const ElectricityEmissionsFactor: ElectricityEmissionsFactorMap = {
  AdvancedGridStudy: {
    id: "1",
    mtCo2eKeyName: MonthlySitesReportKey.AdvancedGridStudyMtCo2e,
  },
  LocationBased: {
    id: "2",
    mtCo2eKeyName: MonthlySitesReportKey.LocationElectricityMtCo2e,
  },
}

const ElectricityEmissionsFactorIds = Object.values(
  ElectricityEmissionsFactor
  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
).map((emissionsFactorValue) => emissionsFactorValue.id)

const ElectricityEmissionsFactorById = {
  1: ElectricityEmissionsFactor.AdvancedGridStudy,
  2: ElectricityEmissionsFactor.LocationBased,
}

class ScopeTwoReportingData extends MonthSummary {}

export {
  ElectricityEmissionsFactor,
  ElectricityEmissionsFactorIds,
  ElectricityEmissionsFactorById,
}

// TODO: update ScopeTwoReportingData from class to functional component and remove this export
export default ScopeTwoReportingData
