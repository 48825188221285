import React from "react"
import { NavLink } from "react-router-dom"

import { PageHeader } from "@/components/nav/page-header"
import { PageHeaderActionBar } from "@/components/nav/page-header-action-bar"
import { NzDataGrid } from "@/components/nzDataGrid"
import { Page } from "@/components/page"
import { useOrganizationContext } from "@/contexts"
import { useSigmaWorkbooks } from "@/services/sigma"

import { Box, Link, Stack, Typography } from "@mui/material"
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"

import { reportCard } from "../../models/report"
import { ReportingLink } from "../reporting-link"

const columns: GridColDef[] = [
  {
    headerName: "Report Name",
    field: "name",
    width: 320,
    renderCell: (params: GridRenderCellParams) => (
      <Link component={NavLink} to={`${params.id}`}>
        {params.value}
      </Link>
    ),
  },
]

const CustomReports = () => {
  const { organization } = useOrganizationContext()
  const { sigmaWorkbooksData, sigmaWorkbooksIsFetching } = useSigmaWorkbooks({
    workspaceNames: [organization?.name],
  })

  return (
    <>
      <PageHeader title={reportCard.Custom.title} />
      <PageHeaderActionBar hasTabs={false}>
        <ReportingLink />
      </PageHeaderActionBar>
      <Page fullHeight>
        <Stack height="100%" spacing={2}>
          <Typography>{reportCard.Custom.description}</Typography>
          <div style={{ flex: 1, position: "relative" }}>
            <Box
              sx={{
                bottom: 0,
                left: 0,
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <NzDataGrid
                columns={columns}
                isLoading={sigmaWorkbooksIsFetching}
                rows={sigmaWorkbooksData?.entries || []}
              />
            </Box>
          </div>
        </Stack>
      </Page>
    </>
  )
}

export default CustomReports
