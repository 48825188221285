import React, { useEffect, useRef, useState } from "react"

import Tooltip from "@mui/material/Tooltip"

import styles from "./overflowTooltip.module.scss"

interface ComponentProps {
  children: React.ReactNode
}

/**
 * A component that displays a tooltip when its child's text has an ellipsis (is overflowing)
 *
 * @param props {ComponentProps} - The component properties
 * @param props.children {React.ReactNode} - The child nodes to render
 * @example
 * return (
 *   <OverflowTooltip>
 *     <span>A very long string of text that should show a tooltip when an ellipsis is displayed</span>
 *   </OverflowTooltip>
 * )
 */

export const OverflowTooltip = ({ children }: ComponentProps) => {
  const [isDisabled, setIsDisabled] = useState(true)
  const textElementRef = useRef<HTMLDivElement>()

  const updateIsDisabled = (): void => {
    const disabled: boolean =
      textElementRef.current?.scrollWidth ===
      textElementRef.current?.clientWidth
    setIsDisabled(disabled)
  }

  useEffect(() => {
    updateIsDisabled()
    window.addEventListener("resize", updateIsDisabled)

    return () => {
      window.removeEventListener("resize", updateIsDisabled)
    }
  }, [])

  return (
    <Tooltip disableHoverListener={isDisabled} title={children}>
      <div
        ref={textElementRef}
        className={styles.text}
        data-testid="overflowTooltipRoot"
      >
        {
          // OverflowTooltip and hit area only works properly with a single child element containing text
          React.Children.only(children)
        }
      </div>
    </Tooltip>
  )
}
