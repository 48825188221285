import type { ReactNode } from "react"
import React from "react"
import { useTranslation } from "react-i18next"

import { Translation } from "../../models/i18n"
import { UnitName } from "../../models/unit"
import { formatEnergyVolumeInUnit } from "../../utils/formatters"
import { DecimalWithPlaceholder } from "../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { Unit } from "../unit/unit"

export const ElectricityVolumesTable = ({
  carbonFreeKwh,
  meteredMwh,
  children,
}: {
  carbonFreeKwh: number
  children?: ReactNode
  meteredMwh: number
}) => {
  const { t } = useTranslation()

  const carbonFreeWh = Math.round(
    formatEnergyVolumeInUnit(
      carbonFreeKwh,
      UnitName.KilowattHour,
      UnitName.MegawattHour
    )
  )
  const meteredWh = formatEnergyVolumeInUnit(
    meteredMwh,
    UnitName.MegawattHour,
    UnitName.MegawattHour
  )
  return (
    <div className="electricity-volumes__table">
      {children}
      <div
        className="table__key key-label"
        data-e2e="carbon-free-label"
        data-electricity-volume="carbon-free"
      >
        {t(Translation.Common.OnSiteGenerated)} (
        <Unit unit={UnitName.MegawattHour} />)
      </div>
      <div
        className="label--metric table__key"
        data-e2e="carbon-free-value"
        data-electricity-volume="carbon-free"
      >
        {
          // Intentionally displaying 0 when value is null
        }
        <DecimalWithPlaceholder
          format={{ precision: 0 }}
          placeholder="0"
          value={carbonFreeWh}
        />
      </div>
      <div className="table__key key-label" data-electricity-volume="total">
        Total Volume (<Unit unit={UnitName.MegawattHour} />)
      </div>
      <div className="label--metric table__key" data-electricity-volume="total">
        <DecimalWithPlaceholder
          format={{ precision: 0 }}
          replaceZeroWithPlaceholder
          value={meteredWh}
        />
      </div>
    </div>
  )
}
