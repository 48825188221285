import React, { useCallback, useEffect } from "react"

import { Grid } from "@mui/material"

import { HStack } from "../../../../../../nzds"
import type { Department as DepartmentModel } from "../../../../../../services"
import { useDepartment, useUpdateDepartment } from "../../../../../../services"
import {
  TamForm,
  TamResetButton,
  TamSubmitButton,
  useTamForm,
} from "../../../tamForm"
import { TamFormTextField } from "../../../tamForm/formFields"
import type { Department } from "../../../tamForm/schemas/department"
import { schema } from "../../../tamForm/schemas/department"
import OrgViewDetailFooter from "../orgViewDetail/orgViewDetailFooter/orgViewDetailFooter"

interface OrgViewDepartmentDetailProps {
  departmentId: DepartmentModel["id"]
  linkSection: React.JSX.Element
}

const OrgViewDepartmentDetail = ({
  departmentId,
  linkSection,
}: OrgViewDepartmentDetailProps) => {
  const { department, isDepartmentLoading } = useDepartment(departmentId)
  const {
    updateOneDepartment,
    isUpdateOneDepartmentLoading,
    isUpdateOneDepartmentSuccess,
  } = useUpdateDepartment()

  const handleFormSubmit = useCallback(
    (newAttributes) => {
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      updateOneDepartment(departmentId, newAttributes)
    },
    [departmentId, updateOneDepartment]
  )

  const form = useTamForm({ schema, values: department })

  useEffect(() => {
    if (isUpdateOneDepartmentSuccess) {
      form.reset({}, { keepValues: true })
    }
  }, [form, isUpdateOneDepartmentSuccess])

  if (isDepartmentLoading) {
    return null
  }

  return (
    <TamForm form={form} name="department" onSubmit={handleFormSubmit}>
      <HStack
        alignItems="flex-start"
        flexGrow={1}
        gap={0.5}
        sx={{
          overflowY: "auto",
          py: 1,
          pr: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TamFormTextField<Department>
                dataE2E="department-name-input"
                label="Department Name"
                name="name"
              />
            </Grid>
            <Grid item xs={3}>
              <TamFormTextField<Department>
                dataE2E="department-id-input"
                disabled
                label="ID"
                name="id"
                readOnly
              />
            </Grid>
          </Grid>
        </Grid>
      </HStack>
      <OrgViewDetailFooter linkSection={linkSection}>
        <TamResetButton dataE2E="department-reset-button" />
        <TamSubmitButton
          dataE2E="department-submit-button"
          submitting={isUpdateOneDepartmentLoading}
        />
      </OrgViewDetailFooter>
    </TamForm>
  )
}

export default OrgViewDepartmentDetail
