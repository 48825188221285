import { sortBy } from "lodash-es"
import type { Moment } from "moment"

import type DateFilter from "../../../models/filter/dateFilter"
import type MultiSelectStringFilter from "../../../models/filter/multiSelectStringFilter"
import type SingleSelectStringFilter from "../../../models/filter/singleSelectStringFilter"
import { Translation } from "../../../models/i18n"
import { Metric } from "../../../models/metric"
import type { IRange } from "../../../models/range"
import { Resource } from "../../../models/resource"
import { ElectricityEmissionsFactor } from "../../../models/scopeTwo"
import type { ITableColumn, ITableRow } from "../../../models/table"
import { UnitName } from "../../../models/unit"
import type { Report } from "./report"

export const ORG_LEVEL_SITE_ID = "-2" // "-2" indicates the record is org level data not associated with a site
export const ORG_LEVEL_SITE_NAME = "General Org (Non-Site)"

export const FLEET_SITE_ID_RAW = "-1"
export const FLEET_SITE_ID = "-1" // -1 indicates the record is a special interpolated fleet site
export const FLEET_SITE_NAME = "Fleet" // "-1" indicates the record is a special interpolated fleet site

export const specialSiteIds: string[] = [ORG_LEVEL_SITE_ID, FLEET_SITE_ID]

export const extraRows = 2 // 2 represents including the header row and the total row

export interface ResourceSummaryMonthRecordDto {
  advancedGridStudyCo2ELbs: number
  departmentId: number | null
  departmentName: string | null
  fuelCo2ELbs: number
  kwh: number
  locationElectricityCo2ELbs: number
  month: number
  naturalGasCo2ELbs: number
  naturalGasThms: number
  organizationalUnitId: number | null
  organizationalUnitName: string | null
  refrigerantCo2ELbs: number
  siteId: number | null
  siteName: string | null
  waterGallons: number
  year: number
}

export interface ResourceSummaryMonthRecord
  extends Omit<ResourceSummaryMonthRecordDto, "departmentId" | "siteId"> {
  departmentId: string | null
  electricityGj?: number
  id?: number
  naturalGasGj?: number
  siteId: string | null
  totalGj?: number
}

export interface ResourceSummaryMonthRecordWithTotalGhg
  extends ResourceSummaryMonthRecord {
  totalGhgLbsCO2e: number
}

export type ResourceSummaryRecord = Omit<
  ResourceSummaryMonthRecordWithTotalGhg,
  "month" | "year"
>

export type ResourceSummaryReport = Report<ResourceSummaryReportTableRow>

export interface IGetReportDataCriteria {
  dateRange: IRange<Moment>
  departments: string[]
  groupIds: string[]
}

export interface IReportFilters {
  department: MultiSelectStringFilter
  electricityEmissionsFactor: SingleSelectStringFilter
  end: DateFilter
  group: MultiSelectStringFilter
  metric: MultiSelectStringFilter
  resource: MultiSelectStringFilter
  start: DateFilter
  weightUnit: SingleSelectStringFilter
}

export type DisplayFormatCallback = (value: string) => string

export enum ResourceSummaryReportName {
  Summary = "Resource Summary",
}

export default {
  ResourceSummaryReportName,
}

export type ResourceSummaryReportTableRow = ResourceSummaryRecord & ITableRow

export type ResourceSummaryReportTableColumn =
  ITableColumn<ResourceSummaryReportTableRow>

// Columns
export const ResourceSummaryReportColumn: Record<
  keyof Omit<
    ResourceSummaryReportTableRow,
    "departmentId" | "id" | "organizationalUnitId"
  >,
  ResourceSummaryReportTableColumn
> = {
  siteName: {
    id: 0,
    order: 0,
    key: "siteName",
    title: "Site Name",
  },
  siteId: {
    id: 1,
    order: 1,
    key: "siteId",
    title: "Site ID",
  },
  departmentName: {
    id: 2,
    order: 2,
    key: "departmentName",
    title: "Department",
  },
  organizationalUnitName: {
    id: 3,
    order: 2,
    key: "organizationalUnitName",
    title: "Group",
  },
  kwh: {
    id: 4,
    order: 3,
    key: "kwh",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.Volume,
      resource: Resource.Electricity,
      scope: Resource.Electricity.scope,
      unit: UnitName.KilowattHour,
    },
    title: "",
  },
  advancedGridStudyCo2ELbs: {
    id: 5,
    order: 4,
    key: "advancedGridStudyCo2ELbs",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.GHG,
      resource: Resource.Electricity,
      scope: Resource.Electricity.scope,
      unit: UnitName.PoundsOfCarbonDioxideEquivalent,
      electricityEmissionsFactor: ElectricityEmissionsFactor.AdvancedGridStudy,
    },
    title: "",
  },
  locationElectricityCo2ELbs: {
    id: 6,
    order: 5,
    key: "locationElectricityCo2ELbs",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.GHG,
      resource: Resource.Electricity,
      scope: Resource.Electricity.scope,
      unit: UnitName.PoundsOfCarbonDioxideEquivalent,
      electricityEmissionsFactor: ElectricityEmissionsFactor.LocationBased,
    },
    title: "",
  },
  electricityGj: {
    id: 7,
    order: 6,
    key: "electricityGj",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.GJ,
      // Mass lint disable
      // Mass eslint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      formatMetric: (value) => value.replace(" Consumption", ""),
      resource: Resource.Electricity,
      scope: Resource.Electricity.scope,
      unit: UnitName.GigaJoule,
    },
    title: "",
  },
  naturalGasThms: {
    id: 8,
    order: 7,
    key: "naturalGasThms",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.Volume,
      resource: Resource.NaturalGas,
      scope: Resource.NaturalGas.scope,
      unit: UnitName.Therm,
    },
    title: "",
  },
  naturalGasCo2ELbs: {
    id: 9,
    order: 8,
    key: "naturalGasCo2ELbs",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.GHG,
      resource: Resource.NaturalGas,
      scope: Resource.NaturalGas.scope,
      unit: UnitName.PoundsOfCarbonDioxideEquivalent,
    },
    title: "",
  },
  naturalGasGj: {
    id: 10,
    order: 9,
    key: "naturalGasGj",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.GJ,
      // Mass lint disable
      // Mass eslint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      formatMetric: (value) => value.replace(" Consumption", ""),
      resource: Resource.NaturalGas,
      scope: Resource.NaturalGas.scope,
      unit: UnitName.GigaJoule,
    },
    title: "",
  },
  fuelCo2ELbs: {
    id: 11,
    order: 10,
    key: "fuelCo2ELbs",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.GHG,
      resource: Resource.Fuel,
      scope: Resource.NaturalGas.scope,
      unit: UnitName.PoundsOfCarbonDioxideEquivalent,
    },
    title: "",
  },
  refrigerantCo2ELbs: {
    id: 12,
    order: 11,
    key: "refrigerantCo2ELbs",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.GHG,
      resource: Resource.Refrigerants,
      scope: Resource.NaturalGas.scope,
      unit: UnitName.PoundsOfCarbonDioxideEquivalent,
    },
    title: "",
  },
  waterGallons: {
    id: 13,
    order: 12,
    key: "waterGallons",
    metadata: {
      isTitleDynamic: true,
      resource: Resource.Water,
      resourceDisplayName: Translation.Organization.WaterUseTitle,
      unit: UnitName.CubicMeter,
    },
    title: "",
  },
  totalGj: {
    id: 14,
    order: 13,
    key: "totalGj",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.GJ,
      unit: UnitName.GigaJoule,
      isTotal: true,
    },
    title: "",
  },
  totalGhgLbsCO2e: {
    id: 15,
    order: 14,
    key: "totalGhgLbsCO2e",
    metadata: {
      isTitleDynamic: true,
      metric: Metric.GHG,
      unit: UnitName.PoundsOfCarbonDioxideEquivalent,
      isTotal: true,
    },
    title: "",
  },
}

export const resourceSummaryReportColumns: ResourceSummaryReportTableColumn[] =
  Object.values(ResourceSummaryReportColumn)

export const energyColumns: ResourceSummaryReportTableColumn[] = sortBy(
  resourceSummaryReportColumns.filter(
    (column) =>
      column.metadata?.metric === Metric.GJ &&
      column.key !== ResourceSummaryReportColumn.totalGj.key
  ),
  "order"
)

export const volumeColumns: ResourceSummaryReportTableColumn[] = sortBy(
  resourceSummaryReportColumns.filter(
    (column) =>
      (column.metadata?.resource === Resource.Electricity &&
        column.metadata?.metric === Metric.Volume) ||
      (column.metadata?.resource === Resource.NaturalGas &&
        column.metadata?.metric === Metric.Volume)
  ),
  "order"
)
