import moment from "moment"

import type { MonthlyAreaGraphDatum } from "../../components/graph/types"
import { formatToYYYYMMDD } from "../../utils/timeHelpers"

/**
 * a utility function that returns the pseudo end value with end of month and updated index
 * @param - the object to be duplicated and updated with end of month and updated index
 * @example
 * getPseudoEndValue({
 *   date: "2023-06-01",
 *   index: 0,
 *   ...
 * })
 * returns
 * {
 *   date: "2023-06-30",
 *   index: 1,
 *   ...
 * }
 */
export const getPseudoEndValue = (
  data: MonthlyAreaGraphDatum
): MonthlyAreaGraphDatum => {
  const endOfMonthDate = moment(data.date).endOf("month")
  return {
    ...data,
    index: data.index + 1,
    date: formatToYYYYMMDD(endOfMonthDate),
  }
}
