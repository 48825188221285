import type { FC } from "react"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { Box, Button, Stack, Typography } from "@mui/material"

import verifyEmailImage from "./verify-email.png"

interface VerifyEmailProps {
  email: string
}

export const VerifyEmail: FC<VerifyEmailProps> = ({ email }) => {
  return (
    <>
      <Typography mb={1.5} textAlign="center" variant="h1">
        Verify Email
      </Typography>
      <Box textAlign="center">
        <img
          alt="woman walking with checkmarks"
          src={verifyEmailImage as string}
          width="215"
        />
      </Box>
      <Typography mb={4.5} textAlign="center">
        We sent a verification link to {email}.<br />
        If you don&rsquo;t see it, check your spam folder.
        <br />
        After confirming your email, you can explore the platform.
      </Typography>
      <Stack alignItems="center" spacing={3}>
        <Button
          color="primary"
          component={RouterLink}
          to="../"
          variant="contained"
        >
          Log In to NZero
        </Button>
      </Stack>
    </>
  )
}
