// @ts-strict-ignore
import React, { useMemo } from "react"
import { Grid } from "react-virtualized"

import CloseIcon from "@mui/icons-material/Close"
import { DialogContent, DialogTitle, IconButton } from "@mui/material"
import Dialog from "@mui/material/Dialog"

import { DateRangeDisplay } from "../../../../../../components/date/dateRangeDisplay/dateRangeDisplay"
import useFilters from "../../../../../../hooks/useFilters/useFilters"
import DateFilter from "../../../../../../models/filter/dateFilter"
import type { SiteFilters } from "../../../site/site.types"
import { CellRenderer } from "./cellRenderer/cellRenderer"
import { HeaderTable } from "./headerTable/headerTable"
import type { MeterProps } from "./modalMetrics"
import { buildMetersData } from "./modalMetricsConsumptionUtils"

import styles from "./modalMetricsConsumption.module.scss"

export interface ModalMetricsConsumptionProps {
  meters: MeterProps[]
  onClose: () => void
  open: boolean
}

const MODAL_WIDTH = 774
const ROW_HEIGHT = 45
const MAX_HEIGHT_GRID = 360
const CENTER_COLUMN_WIDTH = 100
const RIGHT_COLUMN_WIDTH = 162
const LEFT_COLUMN_WIDTH = 250
const VISIBLE_ROWS = 8

const getColumnWidth = (index: number) => {
  switch (index) {
    case 0:
      return LEFT_COLUMN_WIDTH
    case 1:
    case 2:
      return CENTER_COLUMN_WIDTH
    case 3:
    case 4:
      return RIGHT_COLUMN_WIDTH
    default:
      return 0
  }
}

/**
 * A component that builds table data from promises and renders a table using Grid
 *
 * @param props {@link ModalMetricsConsumptionProps}
 * @returns element
 * @example
 * return (<ModalMetricsConsumption open={true} meters={meters} onClose={onClose} />)
 */
export const ModalMetricsConsumption = (
  props: ModalMetricsConsumptionProps
) => {
  const { filters } = useFilters<SiteFilters>({
    start: DateFilter,
    end: DateFilter,
  })
  const { onClose, open, meters } = props

  const gridData = buildMetersData(meters)

  const tableHeight = useMemo(() => {
    if (gridData.length * ROW_HEIGHT > MAX_HEIGHT_GRID) {
      return MAX_HEIGHT_GRID
    }
    return gridData.length * ROW_HEIGHT
  }, [gridData.length])

  return (
    <Dialog maxWidth="xl" onClose={onClose} open={open}>
      <DialogTitle>
        Meter Data
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: (theme) => theme.typography.pxToRem(8),
            top: (theme) => theme.typography.pxToRem(12),
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <h4 className={styles.modalSubtitle}>
          <DateRangeDisplay
            end={filters.end.value}
            format={{
              day: "numeric",
              month: "long",
              year: "numeric",
            }}
            start={filters.start.value}
          />
        </h4>
        {/* <p className={`paragraph--small ${styles.dateLastUpdate}`}>
          {`Data complete up to ${selectedDateRange.slice(
            selectedDateRange.lastIndexOf("- ") + 1
          )}`}
        </p> // This will be updated in the future and replaced a with functional component */}
        <div className={styles.containerGrid}>
          <HeaderTable />
          <Grid
            cellRenderer={CellRenderer({ gridData })}
            className={
              (gridData.length > VISIBLE_ROWS && styles.containerCells) || ""
            }
            columnCount={5}
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            columnWidth={({ index }) => getColumnWidth(index)}
            height={tableHeight}
            rowCount={gridData.length}
            rowHeight={ROW_HEIGHT}
            width={MODAL_WIDTH}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
