import { useMutation } from "react-query"

import { ApiQueryName } from "../../models/api"
import type OrganizationalUnit from "../../models/organizationalUnit"
import { createQueryKey } from "../utils/createQueryKey"
import { useInvalidateQueries } from "../utils/useInvalidateQueries"
import { useQueryKey } from "../utils/useQueryKey"

export const useUpdateOrganizationalUnit = () => {
  const mutationKey = useQueryKey(ApiQueryName.OrganizationalUnit, "updateOne")
  const { invalidateQueries } = useInvalidateQueries()

  const mutationResult = useMutation({
    mutationKey,
    mutationFn: async ({
      organizationalUnit,
    }: {
      organizationalUnit: OrganizationalUnit
    }) => {
      await organizationalUnit.save({ with: ["sites"] })
    },
    onSuccess: () => {
      invalidateQueries([
        createQueryKey(ApiQueryName.OrganizationalUnits, "getMany"),
        createQueryKey(ApiQueryName.OrganizationHierarchy, "getOne"),
      ])
    },
  })

  return mutationResult
}
