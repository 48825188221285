import React, { useCallback } from "react"

import { NzDataGrid, UNITLESS_ROW_HEIGHT } from "@/components/nzDataGrid"
import { useAvailableReportDates } from "@/services"
import { round } from "lodash-es"
import type { Moment } from "moment"

import { Stack, Typography } from "@mui/material"
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"
import type { DatePicker } from "@mui/x-date-pickers-pro"

import { MonthRangeSelector } from "../../../../components/date/monthRangeSelector/monthRangeSelector"
import { DecimalWithPlaceholder } from "../../../../components/decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { CheckboxList } from "../../../../components/form/checkboxList/checkboxList"
import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import useFilters from "../../../../hooks/useFilters/useFilters"
import DateFilter from "../../../../models/filter/dateFilter"
import MultiSelectStringFilter from "../../../../models/filter/multiSelectStringFilter"
import type { IRange } from "../../../../models/range"
import { MonthRangePicker } from "../../../../nzds/inputs/month-range-picker"
import {
  FeatureFlags,
  useActiveOrganizationId,
  useFeature,
} from "../../../../services"
import type { ActivityEmissionsFilters } from "../../models/activityEmissions"
import {
  ActivityEmissionsReportColumnName,
  ActivityEmissionsReportKey,
  ActivityEmissionsScopeCheckboxes,
} from "../../models/activityEmissions"
import { reportCard } from "../../models/report"
import { useActivityEmissionsReport } from "../../services/useActivityEmissionsReport/useActivityEmissionsReport"
import DownloadCsv from "../downloadCsv/downloadCsv"
import ReportTwoColumnLayout from "../reportTwoColumnLayout/reportTwoColumnLayout"

const columns: GridColDef[] = [
  {
    headerName: ActivityEmissionsReportColumnName.EmissionSource,
    field: ActivityEmissionsReportKey.EmissionSource,
    width: 264,
  },
  {
    headerName: ActivityEmissionsReportColumnName.Scope,
    field: ActivityEmissionsReportKey.Scope,
    width: 108,
    renderCell: (params: GridRenderCellParams) =>
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      params.value === -1 ? "" : params.value,
  },
  {
    headerName: ActivityEmissionsReportColumnName.Category,
    field: ActivityEmissionsReportKey.Category,
    width: 188,
  },
  {
    headerName: ActivityEmissionsReportColumnName.TotalEmissionsMtCo2e,
    field: ActivityEmissionsReportKey.MtCo2e,
    width: 236,
    renderCell: (params: GridRenderCellParams) => (
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      <DecimalWithPlaceholder format={{ precision: 2 }} value={params.value} />
    ),
    align: "right",
    headerAlign: "right",
    type: "number",
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    valueGetter: (params) => round(params.value, 2),
  },
  {
    headerName: ActivityEmissionsReportColumnName.ActivityData,
    field: ActivityEmissionsReportKey.AmountConsumed,
    width: 152,
    renderCell: (params: GridRenderCellParams) => (
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      <DecimalWithPlaceholder format={{ precision: 2 }} value={params.value} />
    ),
    align: "right",
    headerAlign: "right",
    type: "number",
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    valueGetter: (params) => round(params.value, 2),
  },
  {
    headerName: ActivityEmissionsReportColumnName.Unit,
    field: ActivityEmissionsReportKey.Unit,
    flex: 1,
    minWidth: 96,
  },
]

const ActivityEmissions = () => {
  const { filters, setFilters } = useFilters<ActivityEmissionsFilters>({
    start: DateFilter,
    end: DateFilter,
    filter: MultiSelectStringFilter,
  })
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()
  const { activeOrganizationId } = useActiveOrganizationId()
  const { availableReportDatesData } =
    useAvailableReportDates(activeOrganizationId)
  const { activityEmissionsReport, isActivityEmissionsReportFetching } =
    useActivityEmissionsReport(
      activeOrganizationId,
      {
        start: filters.start.value,
        end: filters.end.value,
      },
      { filter: filters.filter?.value ?? [] }
    )

  const onDateChange = useCallback(
    (
      value: IRange<Moment>,
      context: Parameters<Parameters<typeof DatePicker>[0]["onChange"]>[1]
    ): void => {
      if (context?.validationError) {
        return
      }
      setFilters({
        start: new DateFilter(value.start),
        end: new DateFilter(value.end),
      })
    },
    [setFilters]
  )

  const onFilterChange = useCallback(
    (filter: string[]): void => {
      setFilters({
        filter: new MultiSelectStringFilter(filter),
      })
    },
    [setFilters]
  )

  return (
    <ReportTwoColumnLayout
      actions={
        <DownloadCsv
          isDisabled={
            !activityEmissionsReport || isActivityEmissionsReportFetching
          }
          isLoading={isActivityEmissionsReportFetching}
          report={activityEmissionsReport}
        />
      }
      details={
        <NzDataGrid
          columnHeaderHeight={UNITLESS_ROW_HEIGHT}
          columns={columns}
          initialState={{
            pinnedColumns: {
              left: [ActivityEmissionsReportKey.EmissionSource],
            },
            sorting: {
              sortModel: [
                { field: ActivityEmissionsReportKey.MtCo2e, sort: "desc" },
              ],
            },
          }}
          isLoading={isActivityEmissionsReportFetching}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          rows={activityEmissionsReport?.data.rows || []}
        />
      }
      detailsTitle="Data Preview"
      filters={
        <>
          <Stack sx={{ mb: 2 }}>
            <Typography sx={{ mb: 0.5 }}>Date Range</Typography>
            {!isFeatureEnabled(
              FeatureFlags.REPORT_ACTIVITY_EMISSIONS_NZ_DATE_RANGE_SELECTOR,
              organization
            ) && (
              <MonthRangeSelector
                availableMaxMinMonths={availableReportDatesData}
                onChange={onDateChange}
                value={{
                  start: filters.start.value,
                  end: filters.end.value,
                }}
              />
            )}
            {isFeatureEnabled(
              FeatureFlags.REPORT_ACTIVITY_EMISSIONS_NZ_DATE_RANGE_SELECTOR,
              organization
            ) && (
              <MonthRangePicker
                availableMaxMinMonths={availableReportDatesData}
                fullWidth
                onChange={onDateChange}
                value={{
                  start: filters.start.value,
                  end: filters.end.value,
                }}
              />
            )}
          </Stack>
          <Stack>
            <Typography>Filter By</Typography>
            <CheckboxList
              onChange={onFilterChange}
              options={ActivityEmissionsScopeCheckboxes}
              value={filters.filter?.value ?? []}
            />
          </Stack>
        </>
      }
      report={reportCard.EmissionsSources}
    />
  )
}

export default ActivityEmissions
