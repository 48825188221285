import React, { useEffect, useRef, useState } from "react"
import type { FC } from "react"

import { Box, Tooltip } from "@mui/material"

import type { ComplianceEmissionFactorNameProps } from "./compliance-emission-factor-name.types"

function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  )
}

export const ComplianceEmissionFactorName: FC<
  ComplianceEmissionFactorNameProps
> = ({ emissionFactorType, regulations }) => {
  const emissionFactorRef = useRef<HTMLDivElement>(null)
  const [isTitleOverflown, setIsTitleOverflown] = useState(false)

  const emissionFactors = regulations
    .map((regulation) => {
      return (
        regulation.emission_factors?.[`${emissionFactorType}_source`] ?? "--"
      )
    })
    .join(", ")

  useEffect(() => {
    if (emissionFactorRef.current) {
      setIsTitleOverflown(isOverflown(emissionFactorRef.current))
    }
  }, [])

  return !regulations?.length ? (
    <>--</>
  ) : (
    <>
      {isTitleOverflown ? (
        <Tooltip disableInteractive title={emissionFactors}>
          <Box
            ref={emissionFactorRef}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {emissionFactors}
          </Box>
        </Tooltip>
      ) : (
        <Box
          ref={emissionFactorRef}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {emissionFactors}
        </Box>
      )}
    </>
  )
}
