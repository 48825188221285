import React from "react"

import { Box } from "@mui/material"

import { CurrencyWithPlaceholder } from "../currency/currencyWithPlaceholder/currencyWithPlaceholder"

export const BundledSiteFinancialSummaryCardTitle = ({
  titleName,
  titleTotal,
}: {
  titleName: string
  titleTotal: number
}) => (
  <Box
    className="financial-summary-panel__item submenu"
    sx={{
      "&:last-child": {
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
      },
    }}
  >
    <p className="financial-summary-panel__item-title paragraph--small paragraph--small__regular">
      {titleName}
    </p>
    <div className="financial-summary-panel__item-title-filler" />
    <p className="financial-summary-panel__item-title-total paragraph--small paragraph--small__regular">
      <CurrencyWithPlaceholder value={titleTotal} />
    </p>
    <div className="financial-summary-panel__item-command" />
  </Box>
)
