import type { GridComparatorFn } from "@mui/x-data-grid-pro"
import {
  gridNumberComparator,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro"

import type { RowData } from "./recommended-plans-table.types"

/**
 * Custom comparator for the `name` column. takes into account that a
 * plan can be recommended or not.
 *
 * @see https://mui.com/x/react-data-grid/sorting/#combine-built-in-comparators
 */
export const planNameIsRecommendedComparator: GridComparatorFn<RowData> = (
  v1,
  v2,
  param1,
  param2
) => {
  const result = gridNumberComparator(
    v1.isRecommended,
    v2.isRecommended,
    param1,
    param2
  )

  // The `isRecommended` values of the two cells are different
  // We can stop here and sort based on the `isAdmin` field.
  if (result !== 0) {
    return result
  }

  return gridStringOrNumberComparator(v1.name, v2.name, param1, param2)
}
