import React from "react"

import type { IDecimalWithPlaceholderProps } from "../../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { If } from "../../if/if"
import { CurrencyWithPreciseFraction } from "../currencyWithPreciseFraction/currencyWithPreciseFraction"

interface ICurrencyRange
  extends Omit<IDecimalWithPlaceholderProps, "placeholder" | "value"> {
  end: number | string | null | undefined
  start: number | string | null | undefined
}

/**
 * A component that properly formats and internationalizes the provided currency
 *
 * @param props - The component properties
 * @param props.start - The start currency
 * @param props.end - The end currency
 * @param props.replaceZeroWithPlaceholder - Whether to consider 0 as an empty value
 * @param props.useAccountingFormatWhenNegative - Whether to wrap negative currencies in parenthesis or not
 * @example
 * return (
 *   <CurrencyRange start={number | string} end={number | string} />
 * )
 */
export const CurrencyRange = ({
  start,
  end,
  replaceZeroWithPlaceholder,
  useAccountingFormatWhenNegative,
}: ICurrencyRange) => (
  <>
    {
      // Typographically ranges should use an en dash
      // Do not change the en dash to a hyphen or add a space before/after the en dash
    }
    <If condition={!Number.isFinite(end)}>
      <CurrencyWithPreciseFraction
        replaceZeroWithPlaceholder={replaceZeroWithPlaceholder}
        useAccountingFormatWhenNegative={useAccountingFormatWhenNegative}
        value={start}
      />
    </If>
    <If condition={Number.isFinite(end)}>
      <CurrencyWithPreciseFraction
        replaceZeroWithPlaceholder={replaceZeroWithPlaceholder}
        useAccountingFormatWhenNegative={useAccountingFormatWhenNegative}
        value={start}
      />
      –
      <CurrencyWithPreciseFraction
        replaceZeroWithPlaceholder={replaceZeroWithPlaceholder}
        useAccountingFormatWhenNegative={useAccountingFormatWhenNegative}
        value={end}
      />
    </If>
  </>
)
