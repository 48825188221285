import type {
  QueryData,
  QueryInputData,
  ServiceAction,
  ServiceName,
  ServiceValue,
} from "../../types"

const createRenamedQueryData = <
  NameT extends ServiceName,
  ActionT extends ServiceAction,
  ValueT extends ServiceValue,
>(
  serviceName: NameT,
  { data: inputData }: QueryInputData<ActionT, ValueT>
) =>
  ({
    [serviceName]: inputData,
  }) as QueryData<NameT, ActionT, ValueT>

export default createRenamedQueryData
