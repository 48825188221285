import { useEffect, useMemo, useRef, useState } from "react"

import { throttle } from "lodash-es"

interface Rect {
  height: number
  width: number
}

interface UseMeasureValue {
  height: number
  ref: React.RefObject<HTMLDivElement>
  width: number
}

/**
 * Hook that returns the width and height of the element
 * @param ref
 * @returns The updated width and height of the element
 * @example
 * const {ref, height, width} = useMeasure()
 */
const useMeasure = (): UseMeasureValue => {
  const [rect, setRect] = useState<Rect | null>(null)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    // Create the resize observer instance
    const observer = new ResizeObserver(
      throttle(([entry]) => {
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (entry?.contentRect) {
          setRect({
            // migration to strict mode batch disable
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            width: entry.contentRect.width,
            // migration to strict mode batch disable
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            height: entry.contentRect.height,
          })
        }
      }, 100)
    )

    // Start observing the target element
    observer.observe(ref.current)

    // Cleanup function that will be called on component unmount
    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect()
    }
  }, [])

  return useMemo(
    () => ({
      ref,
      width: rect?.width ?? 0,
      height: rect?.height ?? 0,
    }),
    [rect]
  )
}

export default useMeasure
