import React from "react"
import type { FC } from "react"

import { HStack } from "@/nzds/layout"

import type { DataGridLegendItemProps } from "./data-grid-legend-item.types"

export const DataGridLegendItem: FC<DataGridLegendItemProps> = ({
  label,
  value,
  ...rest
}) => {
  return (
    <HStack alignItems="center" gap={1} {...rest}>
      {label} <strong>{value}</strong>
    </HStack>
  )
}
