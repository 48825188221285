import React from "react"

import type { ScaleLinear } from "d3"
import { line, scaleLinear } from "d3"

import { darkGray } from "../../utils/colors"
import type { GraphData } from "./graphData"

export const AvgCarbonIntensityLine = ({
  graphData,
  minWidth,
  tickWidth,
  secondaryYLineScale,
}: {
  graphData: GraphData[]
  minWidth: number
  secondaryYLineScale: ScaleLinear<number, number>
  tickWidth: number
}) => {
  const pointCount = graphData.reduce(
    (count, point) => (point.lineValue > 0 ? count + 1 : count),
    0
  )

  const offset = tickWidth / 2
  const graphWidth = tickWidth * pointCount

  const xLineScale = scaleLinear()
    .domain([0, pointCount])
    .range([minWidth + offset, graphWidth + minWidth + offset])

  const lin = line()
    .defined((d) => d[1] > 0)
    .x((_, i) => xLineScale(i))
    .y((d) => secondaryYLineScale(d[1]))

  return (
    <path
      className="line avg-cost-line"
      d={lin(
        graphData.map((datum) => [datum.hour.unix(), datum.carbonIntensity])
      )}
      fill="none"
      stroke={darkGray.toString()}
      strokeWidth="1.5"
    />
  )
}
