import { useQuery } from "react-query"

import { ApiQueryName } from "@/models/api"
import type { ISitePreview } from "@/models/site"
import { useRenameQueryResult } from "@/services/utils"

import { useQueryKey } from "../utils/useQueryKey"
import { fetchSitePreviewsByOrgId } from "./useSitePreviewsUtils"

export const useSitePreviews = (orgId: string | undefined) => {
  const queryKey = useQueryKey(ApiQueryName.SitePreviews, "getMany", orgId)

  const queryResult = useQuery<ISitePreview[]>({
    queryKey,
    queryFn: () => fetchSitePreviewsByOrgId({ orgId }),
    enabled: !!orgId,
  })

  return useRenameQueryResult(queryResult, ApiQueryName.SitePreviews)
}
