import type { FC } from "react"
import React from "react"

import { InfoOutlined } from "@mui/icons-material"
import type { ChipProps } from "@mui/material"
import { Box, Chip, Tooltip } from "@mui/material"

import type { ComplianceStandardProps } from "./compliance-standard.types"

const computeColor = ({
  isComingSoon,
  isCompliant,
  isSupported,
}: Omit<ComplianceStandardProps, "name">): ChipProps["color"] => {
  if (isComingSoon || !isSupported) {
    return undefined
  }

  return isCompliant ? "success" : "error"
}

export const ComplianceStandard: FC<ComplianceStandardProps> = ({
  isComingSoon,
  isCompliant,
  isSupported,
  name,
}) => {
  return !name ? (
    <>--</>
  ) : (
    <Chip
      color={computeColor({ isComingSoon, isCompliant, isSupported })}
      deleteIcon={
        isComingSoon ? (
          <Tooltip title="Fine calculations are coming soon. Please feel free to contact us so we can prioritize this in one of our upcoming releases.">
            <Box component="span" sx={{ display: "inline-flex" }}>
              <InfoOutlined
                fontSize="small"
                sx={{ color: (theme) => theme.palette.grey[300] }}
              />
            </Box>
          </Tooltip>
        ) : null
      }
      label={name}
      onDelete={
        isComingSoon
          ? () => {
              // Noop. Function is required for the delete icon to show.
            }
          : null
      }
      size="small"
      sx={{
        bgcolor: (theme) => `${theme.palette.background.paper} !important`,
      }}
      variant="outlined"
    />
  )
}
