import { useMemo } from "react"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useLocation, useParams } from "react-router-dom"

import { FeatureFlags, useFeature, useOrganization } from "../../../../services"

/**
 * Determines whether the flexible hierarchy feature flag is enabled for an organization.
 * @returns An object with the following properties:
 * - isLoading: A boolean indicating whether the organization is currently being loaded.
 * - isFlexibleHierarchyEnabled: Indicates whether flex hierarchy is enabled for the organization.
 * @remarks This hook should be removed when the feature flag is no longer needed.
 */
export const useIsFlexibleHierarchyEnabled = () => {
  // We get the currently selected organization from the URL because
  // we must know if the organization is using Flexible Hierarchy before
  // we construct the routes.
  const location = useLocation()
  const orgId = location.pathname.split("/")[3] // /tam-admin/onboarding/:orgId

  const { organization, isOrganizationLoading } = useOrganization(orgId)
  const { isFeatureEnabled } = useFeature()

  const isFlexibleHierarchyEnabled = useMemo(
    () => isFeatureEnabled(FeatureFlags.OAT_FLEXIBLE_HIERARCHY, organization),
    [isFeatureEnabled, organization]
  )

  return { isLoading: isOrganizationLoading, isFlexibleHierarchyEnabled }
}
