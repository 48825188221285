import React, { useState } from "react"
import ReactDOMServer from "react-dom/server"

import { map, sum } from "lodash-es"
import moment from "moment"
import "moment-timezone"

import { Stack } from "@mui/material"

import { useOrganizationContext } from "../contexts/organizationProvider"
import EnergyAllocation from "../models/energyAllocation"
import type Site from "../models/site"
import { UnitName } from "../models/unit"
import { useSiteLoadAggregationPointAndPrices } from "../modules/site/services/useSiteLoadAggregationPointAndPrices/useSiteLoadAggregationPointAndPrices"
import { useYtdMeteredKwh } from "../modules/site/services/useYtdMeteredKwh/useYtdMeteredKwh"
import { pricesForToday } from "../utils"
import { KWH_PER_MWH } from "../utils/constants"
import { transformPricePerWhIntoUnit } from "../utils/formatters"
import { getKeyByValue } from "../utils/object"
import { CurrencyWithPlaceholder } from "./currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { DateDisplay } from "./date/dateDisplay/dateDisplay"
import { DateRangeDisplay } from "./date/dateRangeDisplay/dateRangeDisplay"
import { DayAheadPricing } from "./dayAheadPricing"
import { DecimalWithPlaceholder } from "./decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { Panel } from "./panel"
import { PriceDisplay } from "./priceDisplay"
import { PricingOverview } from "./pricingOverview"
import { Unit } from "./unit/unit"

export const SiteOverview = ({
  isParentLoading,
  site,
  energyAllocations,
  timeWindow,
}: {
  energyAllocations: EnergyAllocation[]
  isParentLoading: boolean
  site: Site
  timeWindow
}) => {
  const { organization } = useOrganizationContext()
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [whDisplayUnit, setWhDisplayUnit] = useState<
    UnitName.KilowattHour | UnitName.MegawattHour
  >(UnitName.MegawattHour)

  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { ytdMeteredKwh } = useYtdMeteredKwh(timeWindow, site?.id)

  const aggregatedMeteredAllocations = EnergyAllocation.aggregateByHour(
    energyAllocations
  ).filter((alloc) => alloc.meteredKwh)

  const contractCost = EnergyAllocation.getLossAdjustedConfirmCosts(
    aggregatedMeteredAllocations
  )
  const lineLossCost = EnergyAllocation.getLineLossCosts(
    aggregatedMeteredAllocations
  )
  const imbalanceCost = EnergyAllocation.getLossAdjustedImbalanceCosts(
    aggregatedMeteredAllocations
  )
  const schedulingCost = EnergyAllocation.getSchedulingCosts(
    aggregatedMeteredAllocations
  )

  const totalMwh =
    sum(map(aggregatedMeteredAllocations, (alloc) => alloc.meteredKwh)) /
    KWH_PER_MWH

  const transmissionAndDistributionCost =
    totalMwh > 0 && site.tariffDollars ? totalMwh * site.tariffDollars : 0

  const totalCost =
    contractCost +
    imbalanceCost +
    lineLossCost +
    transmissionAndDistributionCost +
    schedulingCost

  const { siteLoadAggregationPointPrices, siteLoadAggregationPoint } =
    useSiteLoadAggregationPointAndPrices(site)

  // The hook will provide these as undefined until the data is fetched
  if (
    siteLoadAggregationPointPrices === undefined ||
    siteLoadAggregationPoint === undefined
  ) {
    return null
  }

  if (isParentLoading) {
    return (
      <Stack className="site-overview site__panel">
        <div className="site-overview__left">
          <div className="left-loading-box loading-skeleton" />
          <div className="left-loading-box loading-skeleton" />
        </div>
        <div className="site-overview__right">
          <div className="right-loading-box loading-skeleton" />
          <div className="right-loading-box loading-skeleton" />
        </div>
      </Stack>
    )
  }

  const midTitle = ytdMeteredKwh
    ? ReactDOMServer.renderToString(
        DecimalWithPlaceholder({
          value: Math.ceil(ytdMeteredKwh.meteredKwh / KWH_PER_MWH),
          format: {
            precision: 2,
          },
        })
      )
    : ""

  const midSubtitle = ytdMeteredKwh ? "MWh / YTD" : ""

  return (
    <Panel midSubtitle={midSubtitle} midTitle={midTitle} title={null}>
      <div className="site-overview__left">
        <div className="title-with-note">
          <h3 className="title-with-note__title">Market At a Glance...</h3>
        </div>
        <div className="site-overview__left__panel">
          <DayAheadPricing
            prices={pricesForToday(siteLoadAggregationPointPrices)}
            whDisplayUnit={whDisplayUnit}
          />
        </div>
        {siteLoadAggregationPoint && (
          <div className="site-overview__left__panel">
            <div className="title-with-note">
              <h4 className="title-with-note__title">Current</h4>
              <p className="title-with-note__note">
                <DateDisplay
                  value={moment(siteLoadAggregationPoint.updatedAt)}
                />
              </p>
            </div>
            <PriceDisplay
              amount={transformPricePerWhIntoUnit(
                Number.parseFloat(siteLoadAggregationPoint.currentLmpMajor),
                UnitName.MegawattHour,
                whDisplayUnit
              )}
            >
              Real Time LMP (
              <Unit
                unit={
                  UnitName[
                    `CurrencyPer${getKeyByValue<string>(
                      UnitName,
                      whDisplayUnit
                    )}` as keyof typeof UnitName
                  ]
                }
              />
              )
            </PriceDisplay>
          </div>
        )}
      </div>
      <div className="site-overview__divider" />
      {aggregatedMeteredAllocations?.length > 0 ? (
        <div className="site-overview__right">
          <div className="title-with-note">
            <h3 className="title-with-note__note">
              <DateRangeDisplay
                // migration to strict mode batch disable
                // Mass lint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                end={timeWindow.endDate}
                // migration to strict mode batch disable
                // Mass lint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                start={timeWindow.startDate}
              />
            </h3>
            <h3 className="title-with-note__large-title">
              Financial Breakdown
            </h3>
          </div>
          <PricingOverview
            aggregatedAllocations={aggregatedMeteredAllocations}
            tariffDollars={site.tariffDollars}
          />
          <div className="financial-breakdown">
            <div>
              <div className="financial-breakdown__row financial-breakdown__highlight-row">
                <div>Total Cost of Power</div>
                <div data-name="total">
                  <CurrencyWithPlaceholder
                    replaceZeroWithPlaceholder={organization.displayNullState}
                    value={totalCost}
                  />
                </div>
              </div>
              <div className="financial-breakdown__row">
                <div>Transmission & Distribution</div>
                <div data-name="t&amp;d">
                  <CurrencyWithPlaceholder
                    replaceZeroWithPlaceholder={organization.displayNullState}
                    value={transmissionAndDistributionCost}
                  />
                </div>
              </div>
              <div className="financial-breakdown__row">
                <div>Imbalance Cost</div>
                <div data-name="imbalance-cost">
                  <CurrencyWithPlaceholder
                    replaceZeroWithPlaceholder={organization.displayNullState}
                    value={imbalanceCost}
                  />
                </div>
              </div>
              <div className="financial-breakdown__row">
                <div>Line Loss Factor</div>
                <div data-name="loss">
                  <CurrencyWithPlaceholder
                    replaceZeroWithPlaceholder={organization.displayNullState}
                    value={lineLossCost}
                  />
                </div>
              </div>
              <div className="financial-breakdown__row">
                <div>Scheduling Fees</div>
                <div data-name="fees">
                  <CurrencyWithPlaceholder
                    replaceZeroWithPlaceholder={organization.displayNullState}
                    value={schedulingCost}
                  />
                </div>
              </div>
              <div className="financial-breakdown__row">
                <div>Energy Cost</div>
                <div data-name="energy-cost">
                  <CurrencyWithPlaceholder
                    replaceZeroWithPlaceholder={organization.displayNullState}
                    value={contractCost}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      ) : (
        <div className="site-overview__right">
          <div className="title-with-note">
            <h2 className="title-with-note__large-title">
              The site overview is currently unavailable
            </h2>
          </div>
          <div className="site-overview__subtitle">
            The overview will display when last week&rsquo;s data is available
          </div>
        </div>
      )}
    </Panel>
  )
}
