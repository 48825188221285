// @ts-strict-ignore
import { useMemo } from "react"

import type { Schemas } from "@/services/types"

/**
 * creates a lookup object for sites with plans indexed by site id and has a plan response
 */
export const useSitesWithPlans = (
  plannedSites?: Schemas["PlanResponse"][]
): Record<number, Schemas["PlanResponse"]> => {
  const sitesWithPlans = useMemo(() => {
    if (!plannedSites) {
      return {}
    }
    return plannedSites.reduce((acc, site) => {
      acc[site.site_id] = site
      return acc
    }, {})
  }, [plannedSites])

  return sitesWithPlans
}
