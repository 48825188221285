import ReportingData from "../../../../models/reportingData"
import { GJ_PER_KWH, GJ_PER_THERM } from "../../../../utils"

export interface EnergyConsumptionBase {
  department_id: number | null
  department_name: string | null
  electricity_co2e_lbs: number
  fuel_co2e_lbs: number
  kwh: number
  month: number
  natural_gas_co2e_lbs: number
  natural_gas_thms: number
  refrigerant_co2e_lbs: number
  site_id: number | null
  site_name: string | null
  water_gallons: number
  year: number
}

interface GetTotalEnergyConsumptionProps {
  /**
   * The end date of the date range to get the total energy consumption for
   */
  endDate: string
  /**
   * The id of the organization to get the total energy consumption for
   */
  organizationId: string
  /**
   * The start date of the date range to get the total energy consumption for
   */
  startDate: string
}

export interface GetTotalEnergyConsumptionValue {
  /**
   * The total energy consumption value
   */
  total: number
}

/**
 * Reduces report data to a total energy consumption value
 * @param reportRawData The array of IReportDataRawDto objects to reduce
 * @returns The total energy consumption value
 */
export const reduceReportDataRawDtosToTotalEnergyConsumption = (
  reportRawData: EnergyConsumptionBase[]
): number =>
  /* Electricity falls under Scope 1 and natural gas falls under Scope 2. Convert the
     returned kWh and Therm values to GJ */
  reportRawData.reduce<number>((totalEnergyConsumption, rawReportDatum) => {
    totalEnergyConsumption += (rawReportDatum.kwh || 0) * GJ_PER_KWH
    totalEnergyConsumption +=
      (rawReportDatum.natural_gas_thms || 0) * GJ_PER_THERM

    return totalEnergyConsumption
  }, 0)

/**
 * This util fetches the total energy consumption for a given organization
 */
export const getEnergyConsumption = async ({
  organizationId,
  startDate,
  endDate,
}: GetTotalEnergyConsumptionProps): Promise<GetTotalEnergyConsumptionValue> =>
  ReportingData.where({
    organization_id: Number(organizationId),
    start_date: startDate,
    end_date: endDate,
  })
    .stats({
      data: "calculate",
    })
    .all()
    .then((result) => {
      const stats = result.meta.stats as Record<string, Record<string, string>>
      const parsedResult = JSON.parse(
        stats.data.calculate
      ) as EnergyConsumptionBase[]

      const res = reduceReportDataRawDtosToTotalEnergyConsumption(parsedResult)
      return {
        total: res,
      }
    })
