import type { FC } from "react"
import React, { useMemo } from "react"
import { useParams } from "react-router-dom"

import { useDialog } from "@/services/dialog"
import { isNil, sortBy } from "lodash"

import { Paper } from "@mui/material"

import {
  DeveloperAdminPath,
  RootPath,
  TamAdminPath,
} from "../../../../models/route"
import type { SiteCounts } from "../../../../services"
import { useBillingGroups, useSite, useSiteCounts } from "../../../../services"
import type { IOrgViewDisplayCount } from "../../models/onboarding/onboarding"
import { siteDisplayCounts } from "../../models/onboarding/onboarding"
import { convertToDisplayCounts } from "../../util/onboarding/onboarding"
import OrgViewDeleteDialog from "../tamAdminOnboarding/orgView/orgViewDeleteDialog/orgViewDeleteDialog"
import OrgViewDetail from "../tamAdminOnboarding/orgView/orgViewDetail/orgViewDetail"
import OrgViewDetailCreateButton from "../tamAdminOnboarding/orgView/orgViewDetail/orgViewDetailCreateButton/orgViewDetailCreateButton"
import OrgViewDetailEntityDeleteButton from "../tamAdminOnboarding/orgView/orgViewDetail/orgViewDetailEntityDeleteButton/orgViewDetailEntityDeleteButton"
import OrgViewDetailLinkSection from "../tamAdminOnboarding/orgView/orgViewDetailLinkSection/orgViewDetailLinkSection"
import OrgViewDialog from "../tamAdminOnboarding/orgView/orgViewDialog/orgViewDialog"
import OrgViewSiteDetail from "../tamAdminOnboarding/orgView/orgViewSiteDetail/orgViewSiteDetail"

export const SiteDetail: FC = () => {
  const params = useParams()
  const { siteId, orgId } = params
  const { site } = useSite(siteId)
  const { siteCounts } = useSiteCounts(siteId)
  const { billingGroups, isBillingGroupsLoading } = useBillingGroups({
    sort: { key: "groupName", order: "asc" },
    // TODO: Verify / fix typing for where clause.
    // migration to strict mode batch disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    where: { site_id: siteId } as any,
    disabled: isNil(siteId),
  })

  const currentSiteDisplayCounts: IOrgViewDisplayCount<SiteCounts>[] = useMemo(
    () =>
      sortBy(
        convertToDisplayCounts<SiteCounts>(siteCounts, siteDisplayCounts),
        "order"
      ),
    [siteCounts]
  )

  const {
    onDialogOpen: onCreateDialogOpen,
    onDialogClose: onCreateDialogClose,
    dialogTitle: createDialogTitle,
    dialogContent: createDialogContent,
    isDialogOpen: isCreateDialogOpen,
  } = useDialog()

  const {
    onDialogOpen: onDeleteDialogOpen,
    onDialogClose: onDeleteDialogClose,
    dialogActions: deleteDialogActions,
    dialogContent: deleteDialogContent,
    dialogTitle: deleteDialogTitle,
    isDialogOpen: isDeleteDialogOpen,
  } = useDialog()

  const canEntityBeDeleted = (numberOfChildObjects: number) =>
    !numberOfChildObjects

  return (
    <>
      <Paper
        sx={{
          flex: 1,
          maxWidth: { lg: "calc(50% - 8px)" },
          padding: (theme) => theme.typography.pxToRem(24),
        }}
        variant="outlined"
      >
        <OrgViewDetail
          createButton={
            <OrgViewDetailCreateButton
              createObjectName="Billing Group"
              onDialogClose={onCreateDialogClose}
              onDialogOpen={onCreateDialogOpen}
              parentObjectIds={{ siteId }}
              redirectPath={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites/${siteId}/billing-group`}
            />
          }
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          dateCreated={site?.dateCreated}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          dateUpdated={site?.dateUpdated}
          datUrl={`${RootPath.DeveloperAdmin}${DeveloperAdminPath.Site}/${site?.id}`}
          deleteButton={
            <OrgViewDetailEntityDeleteButton
              canEntityBeDeleted={canEntityBeDeleted(billingGroups?.length)}
              entityId={siteId}
              entityName="Site"
              isButtonDisabled={isBillingGroupsLoading}
              navLink={`${RootPath.TamAdmin}${TamAdminPath.Onboarding}/${orgId}/sites`}
              onDeleteDialogClose={onDeleteDialogClose}
              onDeleteDialogOpen={onDeleteDialogOpen}
            />
          }
          entityName="Site"
          webAppUrl={`/${orgId}/${RootPath.Site}/${site?.id}`}
        >
          <OrgViewSiteDetail
            linkSection={
              <OrgViewDetailLinkSection
                counts={currentSiteDisplayCounts}
                id={siteId}
              />
            }
            siteId={siteId}
          />
        </OrgViewDetail>
      </Paper>

      <OrgViewDialog
        content={createDialogContent}
        onClose={onCreateDialogClose}
        open={isCreateDialogOpen}
        title={createDialogTitle}
      />
      <OrgViewDeleteDialog
        actions={deleteDialogActions}
        content={deleteDialogContent}
        onClose={onDeleteDialogClose}
        open={isDeleteDialogOpen}
        title={deleteDialogTitle}
      />
    </>
  )
}
