// @ts-strict-ignore
import { groupBy } from "lodash-es"

import EnergyAllocation from "../../../../models/energyAllocation"
import type Site from "../../../../models/site"
import type { SitePremiseFinancialSummary } from "../../../../models/sitePremiseFinancialSummary"
import {
  calculateTotalCost,
  calculateTotalVolume,
  parseFloatMetaStatSum,
} from "../../../../utils"
import { KWH_PER_MWH } from "../../../../utils/constants"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"

interface IAllocationStatsFromData {
  data: EnergyAllocation[]
  endDate: moment.Moment
  site: Site
  startDate: moment.Moment
}

interface IFetchEnergyAllocationStats {
  totalCarbon: number
  totalCarbonBasedMWH: number
  totalCarbonFreeMWH: number
  totalCost: number
  totalMWH: number
}

const fetchEnergyAllocationStats = ({
  yearStart,
  dayStart,
  siteId,
  billingGroupId,
}): Promise<IFetchEnergyAllocationStats> =>
  EnergyAllocation.stats({
    co2e_lbs: "sum",
    financial_summaries_for_premise: "calculate",
    carbon_based_kwh: "sum",
    carbon_free_kwh: "sum",
  })
    .where({
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      start_time: yearStart,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      end_time: dayStart,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      site_id: siteId,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      billing_group_id: billingGroupId,
    })
    .per(0)
    .all()
    .then(({ meta }) => {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const financialSummary: SitePremiseFinancialSummary =
        snakeCaseToCamelCase(
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          meta.stats.financial_summaries_for_premise.calculate
        )
      const { totalCarbonBasedMWH, totalCarbonFreeMWH } = {
        totalCarbonBasedMWH:
          // Mass lint disable
          // Mass lint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-condition
          parseFloatMetaStatSum(meta?.stats?.carbon_based_kwh?.sum) /
          KWH_PER_MWH,
        totalCarbonFreeMWH:
          // Mass lint disable
          // Mass lint disable
          // migrate directories to strict mode
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-condition
          parseFloatMetaStatSum(meta?.stats?.carbon_free_kwh?.sum) /
          KWH_PER_MWH,
      }
      return {
        totalCost: calculateTotalCost(financialSummary),
        // Mass lint disable
        // Mass lint disable
        // migrate directories to strict mode
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-condition
        totalCarbon: parseFloatMetaStatSum(meta?.stats?.co2e_lbs?.sum),
        totalCarbonBasedMWH,
        totalCarbonFreeMWH,
        totalMWH: calculateTotalVolume(financialSummary) / KWH_PER_MWH,
      }
    })

export const allocationStatsFromData = ({
  data,
  site,
  startDate,
  endDate,
}: IAllocationStatsFromData) => {
  if (!site.isBundledEnergyCustomer()) {
    const defaultStats: IFetchEnergyAllocationStats = {
      totalCost: NaN,
      totalCarbon: NaN,
      totalMWH: NaN,
      totalCarbonBasedMWH: NaN,
      totalCarbonFreeMWH: NaN,
    }

    return {
      data,
      stats: [Promise.resolve(defaultStats)],
    }
  }

  const allocationsByBillingGroup = groupBy(data, "billingGroupId")
  const stats = Object.entries(allocationsByBillingGroup).map(
    (billingGroupIdWithValues) =>
      fetchEnergyAllocationStats({
        yearStart: startDate.format(),
        dayStart: endDate.clone().endOf("day").format(),
        siteId: site.id,
        billingGroupId: billingGroupIdWithValues[0],
      })
  )

  return {
    data,
    stats,
  }
}

interface IAllocationsAndStatsByBillingGroup {
  data: EnergyAllocation[]
  stats: Promise<IFetchEnergyAllocationStats>[]
}

export const allocationsWithCombinedStat = (
  promise: Promise<IAllocationsAndStatsByBillingGroup>
) =>
  promise.then(
    ({
      data,
      stats,
    }: {
      data: EnergyAllocation[]
      stats: Promise<IFetchEnergyAllocationStats>[]
    }) =>
      Promise.all(stats).then((allStats) => {
        const totalStat = allStats.reduce(
          (total, stat) => {
            total.totalCost += stat.totalCost
            total.totalCarbon += Number(stat.totalCarbon)
            total.totalMWH += Math.abs(stat.totalMWH)
            total.totalCarbonBasedMWH += Math.abs(stat.totalCarbonBasedMWH)
            total.totalCarbonFreeMWH += Math.abs(stat.totalCarbonFreeMWH)
            return total
          },
          {
            totalCarbon: 0,
            totalCost: 0,
            totalMWH: 0,
            totalCarbonBasedMWH: 0,
            totalCarbonFreeMWH: 0,
          }
        )
        return {
          stats: totalStat,
          energyAllocations: data,
        }
      })
  )
