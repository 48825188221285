import type { FC } from "react"
import React from "react"

import { HStack } from "../../../../../../../nzds/layout/h-stack/h-stack"

interface OrgViewDetailFooterProps {
  children: React.ReactNode
  linkSection?: React.ReactNode
}

const OrgViewDetailFooter: FC<OrgViewDetailFooterProps> = ({
  children,
  linkSection,
}) => (
  <HStack
    alignItems="center"
    gap={3}
    id="org-view-detail-footer"
    justifyContent={linkSection ? "space-between" : "flex-end"}
    marginTop={2}
  >
    {linkSection}
    <HStack gap={2} justifyContent="space-around">
      {children}
    </HStack>
  </HStack>
)

export default OrgViewDetailFooter
