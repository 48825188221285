import { useForm as useRHForm } from "react-hook-form"

import { zodResolver } from "@hookform/resolvers/zod"
import type { z } from "zod"

import type { UseFormOptions } from "./use-form-types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useForm = <Z extends z.Schema<any, any>>({
  schema,
  mode = "onBlur",
  ...rest
}: UseFormOptions<Z>) => {
  // Union type allows for setting server errors
  // See https://react-hook-form.com/docs/useform/seterror
  const useFormMethods = useRHForm<
    z.infer<Z> & { root?: Record<string, unknown> }
  >({
    ...rest,
    mode,
    resolver: zodResolver(schema),
  })

  return useFormMethods
}
