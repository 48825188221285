import type { Operations, Schemas } from "@/services/types"
import { httpClient } from "@/utils/api"
import { buildSearchParamsString } from "@/utils/urls"

export const getSiteRecommendations = async (
  query: Operations["get_bs_list_api_v1_building_simulations_get"]["parameters"]["query"] & {
    organization_id: string | undefined
  }
): Promise<Schemas["BuildSimResponse"][]> => {
  const searchParamsString = buildSearchParamsString(query)

  const response = await httpClient.get<Schemas["BuildSimResponse"][]>(
    `/api/v1/recommendations/building_simulations?${searchParamsString}`
  )

  return response.data
}
