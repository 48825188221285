import React from "react"

import { Stack } from "@mui/material"

import StackedAreaGraphTooltipRow from "./stacked-area-graph-tooltip-row"
import type { StackedAreaGraphTooltipRowsProps } from "./stacked-area-graph-tooltip.types"

const StackedAreaGraphTooltipRows = ({
  rows,
}: StackedAreaGraphTooltipRowsProps): JSX.Element => (
  <Stack p={1} spacing={1}>
    {rows.map((rowItem) => (
      <StackedAreaGraphTooltipRow key={rowItem.label} rowItem={rowItem} />
    ))}
  </Stack>
)

export default StackedAreaGraphTooltipRows
