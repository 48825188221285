import React from "react"

import { TableSortLabel } from "@mui/material"

import { Order } from "../../../../../../models/sort"
import type {
  ResourceSummaryReportTableColumn,
  ResourceSummaryReportTableRow,
} from "../../../../models/resourceSummary"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ResourceSummaryReportColumn } from "../../../../models/resourceSummary"
import ResourceMetricHeader from "../../resourceMetricHeader/resourceMetricHeader"

import styles from "./virtualizedReportingTableHeader.module.scss"

interface ComponentProps {
  columnIndex: number
  columns: ResourceSummaryReportTableColumn[]
  createSortHandler: (
    property: string
  ) => (event: React.MouseEvent<unknown>) => void
  order: Order
  orderBy: keyof ResourceSummaryReportTableRow
  style: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
}

export const VirtualizedReportingTableHeader = ({
  columns,
  columnIndex,
  style,
  order,
  orderBy,
  createSortHandler,
}: ComponentProps) => {
  const column = columns[columnIndex]

  switch (columnIndex) {
    case 0:
      return (
        <div
          key={column.key}
          className={`${styles.header} ${styles.stickyColumn}`}
          style={style}
        >
          <TableSortLabel
            active={orderBy === column.key}
            direction={orderBy === column.key ? order : Order.desc}
            onClick={createSortHandler(column.key)}
          >
            <span
              className="paragraph--small paragraph--small__heavy"
              data-e2e="site-header"
            >
              {column.title}
            </span>
          </TableSortLabel>
        </div>
      )
    case 1:
      return (
        <div key={column.key} className={`${styles.header}`} style={style}>
          <TableSortLabel
            active={orderBy === column.key}
            direction={orderBy === column.key ? order : Order.desc}
            onClick={createSortHandler(column.key)}
          >
            <span
              className="paragraph--small paragraph--small__heavy"
              data-e2e="site-id-header"
            >
              {column.title}
            </span>
          </TableSortLabel>
        </div>
      )
    case 2:
      return (
        <div key={column.key} className={`${styles.header}`} style={style}>
          <TableSortLabel
            active={orderBy === column.key}
            direction={orderBy === column.key ? order : Order.desc}
            onClick={createSortHandler(column.key)}
          >
            <span
              className="paragraph--small paragraph--small__heavy"
              data-e2e="department-header"
            >
              {column.title}
            </span>
          </TableSortLabel>
        </div>
      )

    default:
      return (
        <div
          key={column.key}
          className={`${styles.header} ${styles.headerAlignRight}`}
          style={style}
        >
          <TableSortLabel
            active={orderBy === column.key}
            className={styles.tableSortLabel}
            data-e2e="resources-header"
            direction={orderBy === column.key ? order : Order.desc}
            onClick={createSortHandler(column.key)}
          >
            <ResourceMetricHeader
              electricityEmissionsFactor={
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                column.metadata?.electricityEmissionsFactor
              }
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              formatMetric={column.metadata?.formatMetric}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              isTotal={column.metadata?.isTotal}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              metric={column.metadata?.metric}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              resource={column.metadata?.resource}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              resourceDisplayName={column.metadata?.resourceDisplayName}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              scope={column.metadata?.scope}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              unit={column.metadata?.unit}
            />
          </TableSortLabel>
        </div>
      )
  }
}
