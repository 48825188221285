import React from "react"
import type { CSSProperties, FC } from "react"

interface NzeroSymbolRightProps {
  height?: number | string
  style?: CSSProperties
  width?: number | string
}

export const NzeroSymbolRight: FC<NzeroSymbolRightProps> = ({
  height = "100%",
  style,
  width = "100%",
}) => (
  <svg
    height={height}
    fill="currentColor"
    style={style}
    viewBox="0 0 56 52"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M56 26C56 3.746 23.0161 0 0 0V13C20.9611 13 51 14.873 51 26C51 37.127 20.9611 39 0 39V52C23.0161 52 56 48.254 56 26Z" />
  </svg>
)
