import { useLoadScript } from "@react-google-maps/api"

interface UseGoogleMapsValue {
  googleMaps: typeof window.google.maps
  isLoaded: boolean
}
/**
 * The useGoogleMaps service/hook loads google maps api
 *
 * @returns isLoaded and google maps api handler
 * @example
 * const {
 *   isLoaded, googleMaps
 * } = useGoogleMaps()
 */
export const useGoogleMaps = (): UseGoogleMapsValue => {
  let googleMaps = null
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  })
  if (isLoaded) {
    googleMaps = window.google.maps
  }

  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return { isLoaded, googleMaps }
}
