import type { FC } from "react"
import React from "react"

import { Paper } from "@mui/material"

import type Site from "../../../../models/site"
import { SiteInfoContent } from "./siteInfoContent"

interface SiteInfoProps {
  onClose: () => void
  site: Site | null
}

const SiteInfo: FC<SiteInfoProps> = ({ onClose, site }) => (
  <Paper
    elevation={4}
    sx={{
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      height: "100%",
    }}
  >
    <SiteInfoContent onClose={onClose} site={site} />
  </Paper>
)

export default SiteInfo
