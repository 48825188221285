import type { MouseEvent } from "react"
import React, { useRef, useState } from "react"

import UpdateIcon from "@mui/icons-material/Update"
import { Box, Button, Popover } from "@mui/material"

import { getSiteDetailsMatrix } from "../../../../../../utils/formatters"
import ElectricityHeaderMeterDataCard from "./electricityHeaderMeterDataCard/electricityHeaderMeterDataCard"
import type { MeterDataContent } from "./electricityHeaderMeterDataUtils"
import {
  MeterDataTypeDescription,
  getPopoverButtonText,
} from "./electricityHeaderMeterDataUtils"

interface ElectricityHeaderMeterDataProps {
  siteHasSubMeter: boolean
  siteIsAmi: boolean
  siteIsAmr: boolean
  siteIsProbe: boolean
}

const ElectricityHeaderMeterData: React.FC<ElectricityHeaderMeterDataProps> = ({
  siteHasSubMeter,
  siteIsAmi,
  siteIsAmr,
  siteIsProbe,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  // Filter only truthy values
  const matrixOfPresentSiteDetails = getSiteDetailsMatrix({
    siteHasSubMeter,
    siteIsAmi,
    siteIsAmr,
    siteIsProbe,
  }) as MeterDataContent[]

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return siteHasSubMeter || siteIsAmi || siteIsAmr || siteIsProbe ? (
    <Box sx={{ ml: "16px", mr: "auto" }}>
      <Button
        ref={buttonRef}
        color="primary"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        size="small"
        startIcon={<UpdateIcon />}
      >
        {getPopoverButtonText(matrixOfPresentSiteDetails)}
      </Button>
      <Popover
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        PaperProps={{
          sx: {
            width: "360px",
            padding: "8px",
          },
        }}
        sx={{
          pointerEvents: "none",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {!!matrixOfPresentSiteDetails.length &&
          // Mass eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          matrixOfPresentSiteDetails.map(([key, _], index) => (
            <ElectricityHeaderMeterDataCard
              key={key}
              meterTypeContent={MeterDataTypeDescription[key]}
              showFooter={
                !siteHasSubMeter &&
                index === matrixOfPresentSiteDetails.length - 1
              }
            />
          ))}
      </Popover>
    </Box>
  ) : null
}

export default ElectricityHeaderMeterData
