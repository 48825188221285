import React, { useMemo } from "react"
import type { FC } from "react"

import type { Schemas } from "@/services/types"

import { ComplianceStatus } from "../compliance-status"
import type { ComplianceStatusReducedProps } from "./compliance-status-reduced.types"

const calculateCompliance = (
  regulations: Schemas["EmissionProfileResponse"]["regulations"]
): boolean | null => {
  if (!regulations?.length) {
    return null
  }

  return regulations.reduce((isInCompliance: boolean | null, regulation) => {
    if (regulation.is_supported_without_fines) {
      return null
    }

    if (regulation.in_compliance === false) {
      return false
    }

    if (
      (isInCompliance === null || isInCompliance) &&
      regulation.regulation_name !== "No Regulation" &&
      regulation.in_compliance
    ) {
      return true
    }

    return null
  }, null)
}

export const ComplianceStatusReduced: FC<ComplianceStatusReducedProps> = ({
  regulations,
}) => {
  const isCompliant: boolean | null = useMemo(
    () => calculateCompliance(regulations),
    [regulations]
  )

  return <ComplianceStatus isCompliant={isCompliant} />
}
