// @ts-strict-ignore
import { useMemo } from "react"

import useMonthlySiteData from "@/services/useMonthlySiteData/useMonthlySiteData"
import type { Moment } from "moment"

import type { MonthlyNaturalGasGraphDatum } from "../../../../components/graph/types"
import type { IRange } from "../../../../models/range"
import { getMonthlySiteConsumptionGraphData } from "./useMonthlyConsumptionGraphDataUtil"

/**
 * Provide monthly consumption data (water and gas) for a given organization and date range
 *
 * @param orgId  - The organization id to return data for
 * @param dateRange - The range to return data for
 * @param siteId - the ID to filter results by
 * @example
 * const {
    monthlyConsumptionGraphData,
    isMonthlyConsumptionGraphDataLoading,
  } = useMonthlyConsumptionGraphData("1", {
    start: Moment,
    end: Moment,
  }, {
    siteId: "1"
  })
 */

const useMonthlyConsumptionGraphData = (
  orgId: string,
  dateRange: IRange<Moment>,
  { siteId }
): {
  isMonthlyConsumptionGraphDataFetched: boolean
  isMonthlyConsumptionGraphDataFetching: boolean
  isMonthlyConsumptionGraphDataLoading: boolean
  monthlyConsumptionGraphData: MonthlyNaturalGasGraphDatum[]
} => {
  const {
    monthlySiteData,
    isMonthlySiteDataFetched,
    isMonthlySiteDataFetching,
    isMonthlySiteDataLoading,
  } = useMonthlySiteData(orgId, dateRange, {})

  return useMemo(
    () => ({
      monthlyConsumptionGraphData: getMonthlySiteConsumptionGraphData(
        monthlySiteData,
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        siteId
      ),
      isMonthlyConsumptionGraphDataLoading: isMonthlySiteDataLoading,
      isMonthlyConsumptionGraphDataFetching: isMonthlySiteDataFetching,
      isMonthlyConsumptionGraphDataFetched: isMonthlySiteDataFetched,
    }),
    [
      monthlySiteData,
      siteId,
      isMonthlySiteDataLoading,
      isMonthlySiteDataFetching,
      isMonthlySiteDataFetched,
    ]
  )
}

export { useMonthlyConsumptionGraphData }
