import { useQuery } from "react-query"

import { ApiQueryName } from "@/models/api"

import { useRenameQueryResult } from "../utils"
import { createQueryKey } from "../utils/createQueryKey"
import type { SigmaWorkbook } from "./sigma.types"
import { useSigmaApi } from "./useSigmaApi"

/**
 * Gets the Sigma workbook with the given workbook ID
 *
 * @returns The Sigma workbook
 *
 * @example
 * const {
 *   sigmaWorkbookData,
 * } = useSigmaWorkbook("asdf-1234")
 */
export const useSigmaWorkbook = (
  workbookId: SigmaWorkbook["workbookId"] | undefined
) => {
  const { fetchSigmaWorkbook, sigmaAccessTokenIsFetched } = useSigmaApi()

  const enabled = sigmaAccessTokenIsFetched && Boolean(workbookId)

  const queryKey = createQueryKey(
    ApiQueryName.SigmaWorkbooks,
    "getMany",
    workbookId
  )

  const queryResult = useQuery<SigmaWorkbook>({
    enabled,
    queryFn: async () => fetchSigmaWorkbook(workbookId),
    queryKey: [queryKey],
  })

  return useRenameQueryResult(queryResult, ApiQueryName.SigmaWorkbook)
}
