import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { Moment } from "moment"
import type { WhereClause } from "spraypaint"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import { fetchSiteTotalsByOrgUnit } from "./useSiteTotalsByOrgUnitUtils"

/**
 * The useSiteTotalsByOrgUnit service/hook returns the site totals of an org unit.
 *
 * @returns The useSiteTotalsByOrgUnit service/hook return object
 * @example
 * const {
 *   siteTotalsByOrgUnit,
 *   isSiteTotalsByOrgUnitLoading
 * } = useSiteTotalsByOrgUnit({organizational_unit_id: 3}, { start: moment(), end: moment() }, {isDisabled: false})
 */
export const useSiteTotalsByOrgUnit = (
  where: WhereClause,
  dateRange: IRange<Moment>,
  options?: { isDisabled?: boolean }
) => {
  const rangeStart = dateRange?.start?.format("YYYY-MM-DD")
  const rangeEnd = dateRange?.end?.clone().endOf("month").format("YYYY-MM-DD")
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const whereClause = {
    ...where,
    start_date: rangeStart,
    end_date: rangeEnd,
  }
  const queryKey = useQueryKey(
    ApiQueryName.SiteTotalsByOrgUnit,
    "getMany",
    null,
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    whereClause
  )

  const queryResult = useQuery<
    Awaited<ReturnType<typeof fetchSiteTotalsByOrgUnit>> | undefined
  >({
    queryKey,
    queryFn: () => fetchSiteTotalsByOrgUnit(whereClause),
    enabled:
      !options?.isDisabled &&
      !!dateRange?.start?.isValid() &&
      !!dateRange?.end?.isValid(),
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
