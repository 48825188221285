import { z } from "zod"

import { defaultMaxLength, requiredMessage } from "./schema-constants"
import { getMaxLengthMessage } from "./schema-utils"

export const emailSchema = z
  .string()
  .trim()
  .min(1, { message: requiredMessage })
  .max(defaultMaxLength, { message: getMaxLengthMessage(defaultMaxLength) })
  .email({ message: "Must be a properly formatted email address" })
