import React from "react"

import InfoRoundedIcon from "@mui/icons-material/InfoRounded"
import { IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../models/typography"
import { isStringValueANumber } from "../../../utils"
import { black25, darkGray, dustyGray } from "../../../utils/colors"
import { pxToRem } from "../../../utils/unit"

const styles = {
  tooltip: {
    color: darkGray.toString(),
    borderRadius: pxToRem(4),
    boxShadow: `0px 3px 16px ${black25.toString()}`,
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
  },
  tooltipButton: {
    color: dustyGray.toString(),
  },
  toolTipTitle: {
    mb: pxToRem(4),
  },
}

interface IOrganizationDashboardTooltipProps {
  content: string[]
  title: string
}

const setTooltipText = (title: string, content: string[]): JSX.Element => (
  <Stack>
    <Typography sx={styles.toolTipTitle} variant={TYPOGRAPHY_VARIANT.h5}>
      {title}
    </Typography>
    {content.map((text, index) => (
      <Typography
        key={index}
        component="p"
        sx={[
          isStringValueANumber(text[0]) && {
            ml: pxToRem(16),
            textIndent: pxToRem(-16),
          },
        ]}
        variant={TYPOGRAPHY_VARIANT.body2}
      >
        {text}
      </Typography>
    ))}
  </Stack>
)

export const OrganizationDashboardTooltip = ({
  title,
  content,
}: IOrganizationDashboardTooltipProps): JSX.Element => {
  const theme = useTheme()
  const tooltipText: JSX.Element = setTooltipText(title, content)
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            ...styles.tooltip,
            backgroundColor: theme.palette.common.white,
          },
        },
      }}
      data-e2e="scope-card-tooltip"
      placement="top-end"
      title={tooltipText}
    >
      <IconButton sx={styles.tooltipButton}>
        <InfoRoundedIcon />
      </IconButton>
    </Tooltip>
  )
}
