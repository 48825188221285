import moment from "moment"

import LoadAggregationPoint from "../../../../models/loadAggregationPoint"
import Price from "../../../../models/price"
import { HOURS_IN_DAY } from "../../../../utils/constants"

const fetchPricesForLAP = async (
  loadAggregationPointId: number,
  daysToFetch: number
) =>
  Price.where({
    hour: {
      gte: moment().startOf("day").subtract(daysToFetch, "day").format(),
      lte: moment().startOf("day").add(1, "day").format(),
    },
    load_aggregation_point_id: loadAggregationPointId,
    market: "DAM",
  })
    .per(HOURS_IN_DAY * daysToFetch)
    .order({ hour: "desc" })
    .all()
    .then(({ data }) => data)

const fetchLAP = async (loadAggregationPointId: number) =>
  (await LoadAggregationPoint.find(loadAggregationPointId)).data

export { fetchPricesForLAP, fetchLAP }
