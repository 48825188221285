/* eslint-disable no-nested-ternary */

/* eslint-disable no-underscore-dangle */
import { generateMock as generateZodMock } from "@anatine/zod-mock"
import { faker } from "@faker-js/faker"
import {
  forEach,
  isFunction,
  isInteger,
  isNil,
  isPlainObject,
  mapValues,
  times,
} from "lodash-es"
import type { PartialDeep } from "type-fest"
import { z } from "zod"

import { COMPATIBLE_ZOD_TYPES } from "./constants"
import { hydrateSchemas } from "./hydrateSchemas"
import type { HydrateSchemas } from "./hydrateSchemas"
import type { GetSchemaNames, SchemaDefinition, SchemasArgument } from "./types"

type Count = number | { max?: number; min?: number }

const getCount = (
  defaultCount: Exclude<Count, number>,
  countConfig: null | undefined | number | Count
) => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const countRange = isNil(countConfig)
    ? defaultCount
    : isInteger(countConfig)
      ? { min: countConfig, max: countConfig }
      : // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { ...defaultCount, ...(countConfig as any) }
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return faker.datatype.number(countRange)
}

type MockOverride<Z extends z.ZodTypeAny = z.ZodTypeAny> =
  | PartialDeep<z.infer<Z>>
  | (() => PartialDeep<z.infer<Z>>)
  | (Z extends z.AnyZodObject
      ? {
          [N in keyof z.infer<Z>]?: MockOverride<Z["shape"][N]>
        }
      : never)

const generateMockFromType = <Z extends z.ZodTypeAny>(
  zodType: Z,
  name: string,
  mockOverride?: MockOverride<Z>
): z.infer<Z> => {
  if (isFunction(mockOverride)) {
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return mockOverride()
  }

  if (mockOverride === null) {
    return null
  }

  // Mass lint disable
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  if (!COMPATIBLE_ZOD_TYPES.includes(zodType._def.typeName)) {
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    throw new Error(`Cannot parse schema type "${zodType._def.typeName}".`)
  }

  if (zodType instanceof z.ZodObject) {
    type ZType = z.infer<Z>
    return mapValues(
      zodType.shape,
      (nestedZodType: ZType, nestedName: keyof ZType) => {
        if (mockOverride !== undefined && !isPlainObject(mockOverride)) {
          throw new Error(`Invalid override for object ${name}`)
        }
        // Mass eslint disable @typescript-eslint/no-unsafe-return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return generateMockFromType(
          nestedZodType,
          String(nestedName),
          mockOverride?.[nestedName]
        )
      }
    )
  }

  if (mockOverride) {
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return mockOverride
  }

  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return generateZodMock(zodType)
}

export const generateMock = <SchemaT extends SchemaDefinition>(
  schema: SchemaT,
  mockOverride?: MockOverride<SchemaT["zodSchema"]>
): z.infer<SchemaT["zodSchema"]> => {
  if (typeof mockOverride === "function" && isFunction(mockOverride)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return mockOverride()
  }

  return generateMockFromType(
    schema.zodSchema,
    schema.name,
    Object.assign(
      Object.fromEntries(schema.relationshipNames.map((name) => [name, null])),
      mockOverride ?? {}
    )
  )
}

// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MocksConfig<SchemasT extends HydrateSchemas<any>> = {
  [S in SchemasT[keyof SchemasT] as S["name"]]?: {
    count?: Count
    overrides?: {
      [N in keyof z.infer<S["zodSchema"]>]?: () => z.infer<S["zodSchema"]>[N]
    }
    relationships?: {
      [R in keyof S["relationshipsMeta"]]?:
        | true
        | (S["relationshipsMeta"][R]["relationshipType"] extends "hasMany"
            ? Count
            : S["zodSchema"]["shape"][R extends keyof S["zodSchema"]["shape"]
                  ? R
                  : // Mass eslint disable @typescript-eslint/no-explicit-any
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    never] extends z.ZodNullable<any>
              ? null
              : never)
    }
  }
}

// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MockConfig = NonNullable<MocksConfig<any>[string]>

// type GenerateMocks<SchemasT extends SchemasArgument> = {
//   [N in GetSchemaNames<SchemasT>]: Array<
//     HydrateSchemas<SchemasT>[N]
//   >
// }

export const generateMocks = <SchemasT extends SchemasArgument>(
  schemasArg: SchemasT,
  mocksConfig: MocksConfig<HydrateSchemas<SchemasT>> = {}
) => {
  const hydratedSchemas = hydrateSchemas(schemasArg)

  const mocks = mapValues(
    hydratedSchemas,
    (schema: SchemaDefinition, schemaName: string) => {
      // migration to strict mode batch disable
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      const config: MockConfig = (mocksConfig as any)[schemaName]

      const configOverrides = config?.overrides ?? {}

      const defaultRelationshipOverrides = Object.fromEntries(
        schema.relationshipNames.map((name) => [name, () => undefined])
      )

      return times(getCount({ min: 1, max: 5 }, config?.count), () =>
        generateMock(schema, {
          ...defaultRelationshipOverrides,
          ...configOverrides,
        })
      )
    }
  ) as unknown as Record<GetSchemaNames<SchemasT>, Record<string, unknown>[]>

  forEach(mocks, (mocksArr, schemaName) => {
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const relationshipsConfig: MockConfig["relationships"] =
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      (mocksConfig as any)[schemaName]?.relationships ?? {}
    // migration to strict mode batch disable
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const schema = (hydratedSchemas as any)[schemaName]

    mocksArr.forEach((mock) => {
      Object.assign(
        mock,
        { id: faker.datatype.uuid() }, // TODO: Make configurable?
        mapValues(
          relationshipsConfig,
          (relationshipConfig, relationshipName) => {
            if (relationshipConfig === null) {
              return null
            }

            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const { targetName, relationshipType } =
              // Mass lint disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              schema.relationshipsMeta[relationshipName]

            if (relationshipType === "hasMany") {
              return faker.helpers.arrayElements(
                // Mass lint disable
                // Mass lint disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                mocks[targetName],
                Math.min(
                  // Mass lint disable
                  // Mass lint disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                  mocks[targetName],
                  // Mass lint disable
                  // Mass eslint disable @typescript-eslint/no-explicit-any
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
                  getCount({ min: 1, max: 5 }, relationshipConfig as any)
                )
              )
            }

            // Mass lint disable
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            return faker.helpers.arrayElement(mocks[targetName])
          }
        )
      )
    })
  })

  // Mass eslint disable @typescript-eslint/no-explicit-any
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
  return mocks as any
}
