/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import type { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form"
import { FormProvider, useForm as useHookForm } from "react-hook-form"

import { zodResolver } from "@hookform/resolvers/zod"
import type { ZodTypeAny } from "zod"

interface UseTamFormProps {
  schema: ZodTypeAny
  values?: Record<string, unknown>
}

export const useTamForm = ({ schema, values }: UseTamFormProps) =>
  useHookForm({
    values,
    mode: "all",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  })

interface BetaFormProps<T> {
  children: React.ReactNode
  form: UseFormReturn<T>
  name: string
  onSubmit: SubmitHandler<T>
}

export const TamForm = <T extends FieldValues>({
  form,
  onSubmit,
  children,
  name: formName,
}: BetaFormProps<T>) => (
  <FormProvider {...form}>
    <form
      data-e2e={`${formName}-form`}
      noValidate
      onSubmit={form.handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
      }}
    >
      {children}
    </form>
  </FormProvider>
)
