import { client } from "@/services/client"
import type { Paths } from "@/services/types"

export const getPotentialSites = async (
  params: Paths["/api/v1/recommendations/potential_sites"]["get"]["parameters"]
) => {
  const { data, error } = await client.GET(
    "/api/v1/recommendations/potential_sites",
    {
      params,
    }
  )

  if (data) {
    return data
  }
  throw error as Error
}
