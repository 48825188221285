import { squareFootToSquareMeter } from "."
import { THERMS_PER_MMBTU } from "./constants"
import { customVariableTypeNameIsSquareFoot } from "./filters"

/**
 * Creates a new array of custom variables, converting all `square-foot` unit values into
 * `square-meter` units. Does not modify other variable properties or values.
 *
 * @param customVariables An array of a site's custom variables
 */
export const convertCustomVariablesSquareFootToSquareMeter = (
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customVariables: Record<string, any>[]
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any>[] =>
  customVariables.map((sqFtCustomVariable) =>
    customVariableTypeNameIsSquareFoot(sqFtCustomVariable)
      ? {
          ...sqFtCustomVariable,
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          value: squareFootToSquareMeter(sqFtCustomVariable.value),
        }
      : sqFtCustomVariable
  )

/**
 * Converts a mmbtu number to therms
 *
 * @param mmbtu A number in mmbtu
 * @returns A number in therms
 */
export const convertMmbtuToTherms = (mmbtu: number): number =>
  mmbtu * THERMS_PER_MMBTU
