import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"
import type { Moment } from "moment"

import { ApiQueryName } from "../../../../models/api"
import type { IRange } from "../../../../models/range"
import { fetchScopeTwoEmissions } from "./useScopeTwoEmissionsUtils"

/**
 * This hook fetches and provides the scope two emissions for a given organization
 *
 * @param orgId - The id of the organization whose dates to get
 * @param dateRange - The date range to get the report dates for
 * @returns The scope two emissions of the top-most user accessible group for the given organization
 *
 * @example
 * ```tsx
 * import { useScopeTwoEmissions } from '../services'
 *
 * const {
 *   scopeTwoData,
 *   isScopeTwoDataFetched,
 *   isScopeTwoDataLoading
 * } = useScopeTwoEmissions(orgId, { start: moment(), end: moment() })
 * ```
 */
export const useScopeTwoEmissions = (
  orgId: string,
  dateRange: IRange<Moment>
) => {
  const startDate = dateRange?.start?.format("YYYY-MM-DD")
  const endDate = dateRange?.end?.format("YYYY-MM-DD")
  const queryKey = useQueryKey(
    ApiQueryName.ScopeTwoEmissions,
    "getMany",
    orgId,
    {
      startDate,
      endDate,
    }
  )

  const queryFn = () =>
    fetchScopeTwoEmissions({
      organizationId: orgId,
      startDate,
      endDate,
    })

  const queryResult = useQuery({
    queryKey,
    queryFn,
    enabled:
      !!orgId && !!dateRange?.start?.isValid() && !!dateRange?.end?.isValid(),
    keepPreviousData: true, // Prevent flash of previous data on date range change
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
