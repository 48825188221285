import { useCallback, useMemo, useState } from "react"

import { GridRowEditStopReasons, GridRowModes } from "@mui/x-data-grid-pro"
import type {
  GridEventListener,
  GridRowEditStopParams,
  GridRowId,
  GridRowModesModel,
  MuiEvent,
} from "@mui/x-data-grid-pro"

export const useEditRow = () => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  const handleRowEditStop: GridEventListener<"rowEditStop"> = useCallback(
    (params: GridRowEditStopParams, event: MuiEvent): void => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true
      }
    },
    []
  )

  const handleRowEditClick = useCallback(
    (id: GridRowId): void => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    },
    [rowModesModel]
  )

  const handleRowEditSaveClick = useCallback(
    (id: GridRowId): void => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    },
    [rowModesModel]
  )

  const handleRowEditCancelClick = useCallback(
    (id: GridRowId): void => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      })
    },
    [rowModesModel]
  )

  return useMemo(
    () => ({
      handleRowEditCancelClick,
      handleRowEditClick,
      handleRowEditSaveClick,
      handleRowEditStop,
      rowModesModel,
    }),
    [
      handleRowEditCancelClick,
      handleRowEditClick,
      handleRowEditStop,
      handleRowEditSaveClick,
      rowModesModel,
    ]
  )
}
