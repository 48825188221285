// @ts-strict-ignore
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import { useFormatDecimal } from "@/formatters/useFormatDecimal"
import { useFormatMoney } from "@/formatters/useFormatMoney"
import { useFormatPercentage } from "@/formatters/useFormatPercentage"
import { useCurrencySymbol } from "@/hooks/useCurrencySymbol/useCurrencySymbol"
import { UnitName } from "@/models/unit"
import { DataGridColumnHeader } from "@/nzds/data-grid"
import { useAuthentication } from "@/services/authentication"
import { FeatureFlags, useFeature } from "@/services/feature"
import { translateUnit } from "@/utils"

import type {
  GridColDef,
  GridColumnVisibilityModel,
  GridPinnedColumns,
  GridRowId,
  GridRowModesModel,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro"

import { baseSiteNumberColumnDef } from "../../../../models"
import type { SiteRowData } from "../../../../services/use-get-site-explorer"
import { useCommonGridColumns } from "../../../utils/use-common-grid-columns/use-common-grid-columns"

export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
  electricity_kbtu: false,
  electricity_kbtu_per_sq_ft: false,
  electricity_mt_co2e: false,
  nat_gas_kbtu: false,
  nat_gas_kbtu_per_sq_ft: false,
  nat_gas_mt_co2e: false,
  water_usage_gallons: false,
}

export const defaultPinnedColumns: GridPinnedColumns = {
  left: ["rowNumber", "site_name"],
  right: ["actions", "isPlanned"],
}

export const defaultSortModel: GridSortModel = [
  {
    field: "site_name",
    sort: "desc",
  },
]

interface UseOverviewGridColumnsProps {
  buildingTypes: string[] | undefined
  handleRowEditCancelClick: (id: GridRowId) => void
  handleRowEditClick: (id: GridRowId) => void
  handleRowEditSaveClick: (id: GridRowId) => void
  rowModesModel: GridRowModesModel
}

export const useOverviewGridColumns = (
  props: UseOverviewGridColumnsProps
): GridColDef<SiteRowData>[] => {
  const { i18n } = useTranslation()
  const { canEditSite } = useAuthentication()

  const { format: formatMoneyInteger } = useFormatMoney({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  const { format: formatPercentage } = useFormatPercentage()

  const { format: formatInteger } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  const currencySymbol = useCurrencySymbol()

  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const commonGridColumnDefs = useCommonGridColumns(props)

  const columns: GridColDef<SiteRowData>[] = useMemo(() => {
    return [
      {
        ...commonGridColumnDefs.rowNumber,
      },
      {
        ...commonGridColumnDefs.siteName,
      },
      {
        ...commonGridColumnDefs.group,
      },
      {
        ...commonGridColumnDefs.buildingType,
      },
      {
        ...commonGridColumnDefs.buildingArea,
      },
      {
        ...commonGridColumnDefs.combinedEnergyUsage,
      },
      {
        ...commonGridColumnDefs.combinedEmissions,
      },
      {
        ...commonGridColumnDefs.combinedEui,
      },
      {
        ...baseSiteNumberColumnDef,
        field: "percent_of_portfolio_usage",
        headerName: "Portfolio Contribution",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Portfolio Contribution"
            tooltipContent="Individual site&rsquo;s contribution to the overall portfolio emissions."
            unit="%"
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatPercentage(params.value),
        width: 224,
      },
      {
        ...commonGridColumnDefs.electricityUsage,
      },
      {
        ...commonGridColumnDefs.electricityEmissions,
      },
      {
        ...commonGridColumnDefs.electricityEui,
      },
      {
        ...commonGridColumnDefs.naturalGasUsage,
      },
      {
        ...commonGridColumnDefs.naturalGasEmissions,
      },
      {
        ...commonGridColumnDefs.naturalGasEui,
      },
      {
        ...baseSiteNumberColumnDef,
        field: "water_usage_gallons",
        headerName: "Water Usage",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Water Usage"
            unit={translateUnit(i18n.language, UnitName.Gallon)}
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatInteger(params.value),
        width: 124,
      },
      {
        ...baseSiteNumberColumnDef,
        field: "operational_cost",
        headerName: "Operational Costs",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Operational Costs"
            unit={currencySymbol}
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          formatMoneyInteger(params.value),
        width: 160,
      },
      ...(canEditSite
        ? [
            {
              ...commonGridColumnDefs.actions,
            },
          ]
        : []),
      ...(isFeatureEnabled(FeatureFlags.PLANNING, organization)
        ? [{ ...commonGridColumnDefs.planning }]
        : []),
    ]
  }, [
    commonGridColumnDefs.rowNumber,
    commonGridColumnDefs.siteName,
    commonGridColumnDefs.group,
    commonGridColumnDefs.buildingType,
    commonGridColumnDefs.buildingArea,
    commonGridColumnDefs.combinedEnergyUsage,
    commonGridColumnDefs.combinedEmissions,
    commonGridColumnDefs.combinedEui,
    commonGridColumnDefs.electricityUsage,
    commonGridColumnDefs.electricityEmissions,
    commonGridColumnDefs.electricityEui,
    commonGridColumnDefs.naturalGasUsage,
    commonGridColumnDefs.naturalGasEmissions,
    commonGridColumnDefs.naturalGasEui,
    commonGridColumnDefs.actions,
    commonGridColumnDefs.planning,
    canEditSite,
    isFeatureEnabled,
    organization,
    formatPercentage,
    i18n.language,
    formatInteger,
    currencySymbol,
    formatMoneyInteger,
  ])

  return columns
}
