import React from "react"
import type { FC } from "react"
import {
  Navigate,
  Outlet,
  Link as RouterLink,
  useLocation,
} from "react-router-dom"

import { RootPath } from "@/models/route"

import { Tab, Tabs } from "@mui/material"

import { RepPath } from "../../enums"
import { Header } from "../components/header/header"

export const RepResultsRoute: FC = () => {
  const { pathname } = useLocation()

  if (
    pathname === `${RootPath.Rep}/${RepPath.Results}` ||
    pathname === `${RootPath.Rep}/${RepPath.Results}/`
  ) {
    return <Navigate to={RepPath.Compliance} replace={true} />
  }

  return (
    <>
      <Header
        navigation={
          <Tabs
            aria-label="Rapid Emissions Profile navigation"
            role="navigation"
            sx={{ px: 3 }}
            value={pathname}
          >
            <Tab
              component={RouterLink}
              label="Compliance"
              to={RepPath.Compliance}
              value={`${RootPath.Rep}/${RepPath.Results}/${RepPath.Compliance}`}
            />
            <Tab
              component={RouterLink}
              label="Performance"
              to={RepPath.Performance}
              value={`${RootPath.Rep}/${RepPath.Results}/${RepPath.Performance}`}
            />
          </Tabs>
        }
      />
      <Outlet />
    </>
  )
}
