import type { FC } from "react"
import React, { cloneElement, useState } from "react"

import { Box, Popover } from "@mui/material"

import type { DataGridCellPopoverProps } from "./data-grid-cell-popover.types"

export const DataGridCellPopover: FC<DataGridCellPopoverProps> = ({
  children,
  content,
  disableTypography,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const clonedElement = cloneElement(children, {
    "aria-haspopup": "true",
    "aria-owns": open ? "mouse-over-popover" : undefined,
    onMouseEnter: handlePopoverOpen,
    onMouseLeave: handlePopoverClose,
  })

  return (
    <>
      {clonedElement}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        disableRestoreFocus
        id="mouse-over-popover"
        onClose={handlePopoverClose}
        open={open}
        PaperProps={{
          sx: {
            maxWidth: 300,
          },
        }}
        sx={{
          pointerEvents: "none",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box fontSize={disableTypography ? undefined : "body2.fontSize"} p={1}>
          {content}
        </Box>
      </Popover>
    </>
  )
}
