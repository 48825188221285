import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import type { ISitePreview, ISitePreviewDto } from "../../models/site"
import Site from "../../models/site"
import { snakeCaseToCamelCase } from "../../utils/formatters"

export const sitePreviewResponseToFormattedData = (
  response: CollectionProxy<Site>
): ISitePreview[] =>
  // Mass lint disable
  // Mass eslint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  snakeCaseToCamelCase(response.meta.stats.site_preview.map)
    .sort((siteNavItemA, siteNavItemB) =>
      // Mass lint disable
      // Mass eslint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      siteNavItemA.siteName.localeCompare(siteNavItemB.siteName)
    )
    .map(
      (siteNavItem: ISitePreviewDto): ISitePreview => ({
        departmentName: siteNavItem.departmentName,
        id: siteNavItem.siteId.toString(),
        name: siteNavItem.siteName,
        electricityType: siteNavItem.electricityType,
        timezone: siteNavItem.timezone,
        topOrgUnitName: siteNavItem.topOrgUnitName,
      })
    )

export const fetchSitePreviewsByOrgId = ({
  orgId,
}: {
  orgId: string
}): Promise<ISitePreview[]> =>
  Site.where({
    organization_id: orgId,
  })
    .stats({ site_preview: "map" })
    .per(0)
    .all()
    .then(sitePreviewResponseToFormattedData)
