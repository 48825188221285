import React from "react"
import type { FC } from "react"

import { useFormatDecimal, useFormatMoney } from "@/formatters"
import { DataGridLegend, DataGridLegendItem } from "@/nzds/data-grid"

import { ComplianceStatus } from "../compliance-status"
import type { ComplianceGridLegendProps } from "./compliance-grid-legend.types"

export const ComplianceGridLegend: FC<ComplianceGridLegendProps> = ({
  compliantCount,
  nonCompliantCount,
  siteCount,
  totalPenalties,
}) => {
  const { format: formatInteger } = useFormatDecimal({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  const { format: formatMoney } = useFormatMoney({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  return (
    <DataGridLegend itemCount={siteCount} itemLabel="Sites">
      <DataGridLegendItem
        data-testid="non-compliant-count"
        label={<ComplianceStatus isCompliant={false} />}
        value={formatInteger(nonCompliantCount)}
      />

      <DataGridLegendItem
        data-testid="compliant-count"
        label={<ComplianceStatus isCompliant={true} />}
        value={formatInteger(compliantCount)}
      />

      <DataGridLegendItem
        data-testid="total-fines"
        label="Potential Fines"
        value={formatMoney(totalPenalties)}
      />
    </DataGridLegend>
  )
}
