import type { Moment } from "moment"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import type { IRange } from "../../../../models/range"
import ReportingData from "../../../../models/reportingData"
import type { Scope } from "../../../../models/scope"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"
import type { ActivityEmissionsReportResult } from "../../models/activityEmissions"
import { ActivityEmissionsScopeCheckboxes } from "../../models/activityEmissions"
import { getReportMultiSelectStringFilterByScope } from "../../util/util"

interface ActivityEmissionsReportResponse
  extends CollectionProxy<ReportingData> {
  meta: {
    stats: {
      activity_emissions: {
        // app/services/activity_emissions_service.rb ATTR_NAMES
        calculate: {
          amount_consumed: string
          category: string
          emission_source: string
          mt_co2e: string
          scope: Scope
          units_for_amount_consumed: string
        }[]
      }
    }
  }
}

interface ActivityEmissionsReportFilters {
  filter: string[]
}

const hasNoFilters = (filters: ActivityEmissionsReportFilters): boolean =>
  filters.filter.length === 0

const hasResultAndFilterMatchingScopeNumber = (
  result: ActivityEmissionsReportResult,
  filters: ActivityEmissionsReportFilters
) =>
  filters.filter.find(
    // Identifies a result that matches a filter and matches a checkbox value
    (filter) =>
      ActivityEmissionsScopeCheckboxes.find(
        ({ value }) =>
          value === getReportMultiSelectStringFilterByScope(result.scope) &&
          filter === value
      ) !== undefined
  ) !== undefined

const hasResultAndFilterMatchingWater = (
  result: ActivityEmissionsReportResult,
  filters: ActivityEmissionsReportFilters
) =>
  filters.filter.find((filter) => filter === "water") !== undefined &&
  result.scope === -1

export const hasResultMatchingFilters = (
  result: ActivityEmissionsReportResult,
  filters: ActivityEmissionsReportFilters
) => {
  if (hasNoFilters(filters)) {
    return true
  }
  if (hasResultAndFilterMatchingScopeNumber(result, filters)) {
    return true
  }
  if (hasResultAndFilterMatchingWater(result, filters)) {
    return true
  }

  return false
}

const processActivityEmissionsResponse = (
  calculate: ActivityEmissionsReportResponse["meta"]["stats"]["activity_emissions"]["calculate"]
): ActivityEmissionsReportResult[] =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  calculate.map((result) =>
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    snakeCaseToCamelCase(
      Object.fromEntries(
        Object.entries(result).map(([key, value]) => {
          if (["mt_co2e", "amount_consumed", "scope"].includes(key)) {
            return [key, Number(value) ?? 0]
          }
          return [key, value]
        })
      )
    )
  )

/**
 * Passes through props to Rails ActivityEmissionsService via the API
 * which runs ActivityEmissionsService and returns the response
 *
 * @returns - A promise of the report data
 * @example
 * fetchActivityEmissionsReport(
 *   "1",
 *   {
 *    start: moment("2021-01-01"),
 *    end: moment("2021-12-31"),
 *   },
 * )
 */
export const fetchActivityEmissionsReport = async (
  orgId: string,
  reportDates: IRange<Moment>
): Promise<ActivityEmissionsReportResult[]> => {
  try {
    return await ReportingData.where({
      organization_id: orgId,
      start_date: reportDates.start.format("YYYY-MM-DD"),
      end_date: reportDates.end.format("YYYY-MM-DD"),
    })
      .stats({
        activity_emissions: "calculate",
      })
      .all()
      .then(
        ({
          meta: {
            stats: {
              activity_emissions: { calculate },
            },
          },
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        }) => processActivityEmissionsResponse(calculate)
      )
  } catch {
    return null
  }
}

export default {
  fetchActivityEmissionsReport,
  hasResultMatchingFilters,
}
