import * as React from "react"

import { ArrowForwardIosRounded } from "@mui/icons-material"
import type { AccordionProps } from "@mui/material/Accordion"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import type { AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import { styled } from "@mui/material/styles"

import { HStack } from "../../../../nzds"

export const AccordionCard = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} variant="outlined" {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.typography.pxToRem(8),
  "&:before": {
    display: "none",
  },
}))

export const AccordionCardTitle = styled(
  ({
    icon,
    children,
    ...rest
  }: AccordionSummaryProps & { icon?: React.ReactElement }) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosRounded sx={{ fontSize: "0.9rem" }} />}
      {...rest}
    >
      <HStack alignItems="center" gap={2}>
        {icon}
        {children}
      </HStack>
    </MuiAccordionSummary>
  )
)(({ theme }) => ({
  ...theme.typography.h3,
  transition: theme.transitions.create(["border-radius"], {
    duration: theme.transitions.duration.complex,
    easing: theme.transitions.easing.easeInOut,
  }),
  borderRadius: theme.typography.pxToRem(8),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[25],
  justifyContent: "flex-start",
  minHeight: "unset",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    flex: "0 1 auto",
    margin: "0",
    marginRight: theme.spacing(1),
  },
  "&.Mui-expanded": {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}))

export const AccordionCardContent = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: "transparent",
  })
)
