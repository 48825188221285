/* eslint-disable @typescript-eslint/no-invalid-this */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { axisRight, scaleLinear, select } from "d3"
import type { i18n } from "i18next"

import { UnitName } from "../../models/unit"
import type { IUserSettingsData } from "../../modules/settings"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { darkGray, gray500 } from "../../utils/colors"
import {
  GRAPH_COST_OR_CARBON_INTENSITY,
  KWH_PER_MWH,
} from "../../utils/constants"
import { translateCurrency } from "../../utils/currency"
import { translateDecimal } from "../../utils/decimal"
import { translateUnit } from "../../utils/unit"
import { customTickValues } from "./yAxis"

const constructWattageUnitFormatter = ({
  height,
  maxDomainValue,
  wattUnit,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axisType,
}) => {
  const yScale = scaleLinear()
    .range([height, 0])
    .domain([0, maxDomainValue])
    .nice()

  const getCurrencyText = (
    d,
    i18nService: i18n,
    userSettings: IUserSettingsData
  ): string => {
    if (wattUnit === UnitName.MegawattHour) {
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return translateCurrency(i18nService, userSettings.currency, d, {
        precision: 0,
      })
    }
    return translateCurrency(
      i18nService,
      userSettings.currency,
      d / KWH_PER_MWH,
      {
        precision: 2,
      }
    )
  }

  const getCarbonIntensityText = (d, i18nService: i18n) => {
    if (wattUnit === UnitName.MegawattHour) {
      return translateDecimal(i18nService, d * KWH_PER_MWH, { precision: 0 })
    }
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return translateDecimal(i18nService, d, { precision: 3 })
  }

  return {
    getCarbonIntensityText,
    getCurrencyText,
    yScale,
  }
}

const yScaleAndAxis = ({
  height,
  maxDomainValue,
  wattUnit,
  axisType,
  i18nService,
  userSettings,
}) => {
  const { getCurrencyText, getCarbonIntensityText, yScale } =
    constructWattageUnitFormatter({
      height,
      maxDomainValue,
      wattUnit,
      axisType,
    })

  const yAxis = axisRight(yScale)
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    .tickValues(customTickValues(maxDomainValue))
    .tickSize(0)
    .tickFormat((d) =>
      axisType === GRAPH_COST_OR_CARBON_INTENSITY.CARBON_INTENSITY
        ? // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          getCarbonIntensityText(d, i18nService)
        : // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          getCurrencyText(d, i18nService, userSettings)
    )

  return {
    yScale,
    yAxis,
  }
}

export const scaleY = ({
  height,
  maxDomainValue,
  wattUnit,
  axisType,
  i18nService,
  userSettings,
}) =>
  yScaleAndAxis({
    height,
    maxDomainValue,
    wattUnit,
    axisType,
    i18nService,
    userSettings,
  }).yScale

export const clearYAxis = (className: string) => {
  select(className).selectChildren().remove()
}

export const renderSecondaryYAxis = ({
  height,
  maxDomainValue,
  wattUnit,
  axisType,
  width,
  i18nService,
  userSettings,
}) => {
  const { yAxis } = yScaleAndAxis({
    height,
    maxDomainValue,
    wattUnit,
    axisType,
    i18nService,
    userSettings,
  })

  const axisLabel = `${
    axisType === GRAPH_COST_OR_CARBON_INTENSITY.COST
      ? "Cost"
      : translateUnit(
          // Mass lint disable
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          i18nService.language,
          UnitName.PoundsOfCarbonDioxideEquivalent,
          // Mass lint disable
          // Mass lint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          userSettings.currency
        )
    // Mass lint disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  } / ${translateUnit(i18nService.language, wattUnit, userSettings.currency)}`

  const svg = select("g.secondary-y-axis-container")

  svg.selectAll("g.yaxis").remove()

  if (maxDomainValue) {
    svg
      .append("g")
      .attr("class", "yaxis")
      .attr("transform", `translate(${width}, 0)`)
      .call(yAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) => g.select(".tick:first-of-type text").remove())
      .call((g) =>
        g.selectAll(".tick:last-of-type text").text(axisLabel).attr("y", 10)
      )
      .call((g) =>
        g
          .selectAll(".tick text")
          .style("font-size", "12px")
          .style("color", gray500.toString())
          .style("text-anchor", "end")
          .attr("x", -20)
      )
  }
}
