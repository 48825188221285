import { z } from "zod"

import { s } from "../utils/schema"

// This schema currently supports only "create" and "edit" form validation
// It is not ready to be used to generate TypeScript types
export const siteSchema = s.define("site", {
  name: z.string().min(1),
  // We would like to use optional() on currency, but it returns undefined.
  currency: s.belongsTo("currency"),
  location: s.belongsTo("location"),
  notes: z.string().nullable().optional(),
  tariffMajor: z.coerce.number().nullable().optional(),
  yearBuilt: z.coerce.number().nullable().optional(),
  baCode: z.string(),
  electricityType: z.enum(["direct_access", "bundled"]),
  siteAlias: z.string().nullable().optional(),
  sqFt: z.coerce.number().nullable().optional(),
})
