import type { WhereClause } from "spraypaint"

import type { SiteSummary } from "../../../../models/monthSummary"
import MonthSummary from "../../../../models/monthSummary"
import { snakeCaseToCamelCase } from "../../../../utils/formatters"
import type { OrganizationChildrenSummaryDto } from "../../../dashboard/services/useOrgSummaryStats/useOrgSummaryStatsUtils"

interface SiteSummaryDto extends OrganizationChildrenSummaryDto {
  site_id: number
  site_name: string
}

/**
 * This returns the site totals of a org unit based on the where clause.
 *
 * @param where - The where clause to filter the totals by
 * @returns The filtered totals
 *
 * @example
 * ```tsx
 * import { fetchTotalsByOrgUnit } from '../services/useSiteTotalsByOrgUnit/useSiteTotalsByOrgUnitUtils'
 *
 * const totalsByOrgUnit = await fetchSiteTotalsByOrgUnit({ organizational_unit_id: 3, start_date: '2021-01-01', end_date: '2021-01-31' })
 * ```
 */
const fetchSiteTotalsByOrgUnit = async (where: WhereClause) =>
  MonthSummary.where(where)
    .stats({
      totals_by_organizational_unit: "calculate",
    })
    .per(0)
    .all()
    .then((result) => {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data: SiteSummary[] = snakeCaseToCamelCase(
        // Mass lint disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        result.meta.stats.totals_by_organizational_unit.calculate.map(
          (siteTotal: SiteSummaryDto) =>
            // Transform numbers as strings into numbers
            ({
              ...siteTotal,
              confirm_cost_minor: Number.parseFloat(
                siteTotal.confirm_cost_minor
              ),
              cost_major: Number.parseFloat(siteTotal.cost_major),
              energy_cost_minor: Number.parseFloat(siteTotal.energy_cost_minor),
              imbalance_cost_minor: Number.parseFloat(
                siteTotal.imbalance_cost_minor
              ),
              metered_kwh: Number.parseFloat(siteTotal.metered_kwh),
              scheduling_cost_minor: Number.parseFloat(
                siteTotal.scheduling_cost_minor
              ),
              sq_ft: Number.parseFloat(siteTotal.sq_ft),
              transmission_cost_major: Number.parseFloat(
                siteTotal.transmission_cost_major
              ),
            })
        )
      )
      return data
    })

export { fetchSiteTotalsByOrgUnit }
