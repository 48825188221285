import React from "react"

import { useTheme } from "@mui/material"

const X_OFFSET = 10
const Y_OFFSET = 30

export interface Config {
  content: string
  left: number
  top: number
}

export const emptyConfig = {
  content: "",
  left: 0,
  top: 0,
}

export const Tooltip = ({ content, left, top }: Config) => {
  const theme = useTheme()
  return (
    <div
      className="tooltip"
      style={{
        left: left + X_OFFSET,
        top: top + Y_OFFSET,
        border: `1px solid ${theme.palette.common.black}`,
      }}
    >
      {content}
    </div>
  )
}
