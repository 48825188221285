import { Attr, Model } from "spraypaint"

import ApplicationRecord from "./applicationRecord/applicationRecord"

@Model()
export default class ForecastDay extends ApplicationRecord {
  public static jsonapiType = "forecast_days"

  @Attr() public day: Date

  @Attr() public icon: string

  @Attr() public description: string

  @Attr() public high: number

  @Attr() public highC: number

  @Attr() public low: number

  @Attr() public lowC: number

  @Attr() public humidity: number
}
