import moment from "moment"
import { z } from "zod"

export const schema = z
  .object({
    id: z.string().optional(),
    name: z.string().nullable().optional(),
    meterStatus: z.string().optional(),
    meterCode: z.string().trim().min(1, "Required"),
    meterType: z.string().trim().min(1, "Required"),
    meterProvider: z.string().trim().min(1, "Required"),
    energyAllocatorId: z.string().nullable().optional(),
    fullMeter: z.boolean({ required_error: "Required" }),
    probeMeter: z.boolean({ required_error: "Required" }),
    isOnSiteGeneration: z.boolean({
      required_error: "Required",
    }),
    isNetMeter: z.boolean({ required_error: "Required" }),
    isChildMeter: z.boolean().optional(),
    meterSourceTypeId: z.string().trim().min(1, "Required"),
    startDate: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .datetime({ offset: true, message: "Invalid date" }),
    endDate: z
      .string()
      .datetime({ offset: true, message: "Invalid date" })
      .nullable()
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.meterSourceTypeId === "1" && !data.energyAllocatorId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required for this meter source type",
        path: ["energyAllocatorId"],
      })
    }
    if (data.startDate && data.endDate) {
      if (moment(data.startDate).isAfter(moment(data.endDate))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Start date must be before end date",
          path: ["startDate"],
        })
      }
      if (moment(data.endDate).isBefore(moment(data.startDate))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "End date must be after start date",
          path: ["endDate"],
        })
      }
    }
  })

export type Meter = z.infer<typeof schema>
