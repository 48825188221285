import type { WhereClause } from "spraypaint"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import type { IFetchApplicationRecordOptions } from "../../models/applicationRecord/applicationRecord"
import OrganizationalUnit from "../../models/organizationalUnit"

/**
 * Fetches organizational units. Results are filtered by provided orgId and any additional params
 * included in the clause.
 * @param organizationId - The organizationId to retrieve OUs for
 * @param options.clause - Used to construct graphiti filter query params https://www.graphiti.dev/js/reads/filtering
 * @param options.fetchOptions - Used to configure sorting and pagination for the response.
 * @example
 * const options = {clause: {level: 1}, fetchOptions:{order: "desc"}}
 * const { data } = await fetchOrganizationalUnits(oganizationId: 5, options)
 */
export const fetchOrganizationalUnits = async (
  organizationId: string,
  options?: {
    clause?: WhereClause
    fetchOptions?: Partial<IFetchApplicationRecordOptions>
  }
): Promise<OrganizationalUnit[]> => {
  const mergedOptions: IFetchApplicationRecordOptions = {
    ...{
      order: "asc",
      orderBy: "name",
      pageSize: 1000,
      pageNumber: 1,
    },
    ...options?.fetchOptions,
  }
  const response: CollectionProxy<OrganizationalUnit> =
    await OrganizationalUnit.order({
      [mergedOptions.orderBy]: mergedOptions.order,
    })
      .where({ organizationId, ...options?.clause })
      .per(mergedOptions.pageSize)
      .page(mergedOptions.pageNumber)
      .all()

  return response.data
}
