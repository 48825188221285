import { useMutation } from "react-query"
import type { UseMutationOptions } from "react-query"

import type { AxiosError, AxiosResponse } from "axios"

import type { User } from "../../models/user"
import { httpClient } from "../../utils/api"
import type { SuccessData } from "../../utils/api"

export interface ResendEmailError extends SuccessData {
  errors: string[]
}

export const resendEmailApi = async (
  email: User["email"]
): Promise<AxiosResponse<SuccessData>> => {
  return await httpClient.post("/users/confirmation", {
    user: { email: email },
  })
}

export const useResendEmail = (
  options: UseMutationOptions<
    AxiosResponse<SuccessData>,
    AxiosError<ResendEmailError>,
    User["email"]
  >
) => {
  const useMutationOptions = {
    mutationFn: async (email: User["email"]) => resendEmailApi(email),
    ...options,
  }

  const { data, isError, isLoading, isSuccess, mutateAsync } =
    useMutation(useMutationOptions)

  return {
    resendEmail: mutateAsync,
    resendEmailData: data,
    resendEmailIsError: isError,
    resendEmailIsLoading: isLoading,
    resendEmailIsSuccess: isSuccess,
  }
}
