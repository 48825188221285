import type { NumberValue } from "d3"
import { format as d3Format } from "d3"
import type { i18n } from "i18next"

import { LanguageRegion, Translation } from "../models/i18n"

export interface IDecimalFormat extends Intl.NumberFormatOptions {
  precision?: number
}

export interface IDecimalOptions {
  useAccountingFormatWhenNegative?: boolean
}

export interface IDecimalWithPlaceholderOptions extends IDecimalOptions {
  placeholder?: string
  replaceZeroWithPlaceholder?: boolean
}

const defaultFormatConfig: IDecimalFormat = {
  maximumFractionDigits: 20,
}

const defaultOptionsConfig: IDecimalOptions = {
  useAccountingFormatWhenNegative: true,
}

export interface IEmptyDecimalOptions {
  includeZero?: boolean
}

const defaultEmptyDecimalOptions: IEmptyDecimalOptions = {
  includeZero: false,
}

export const DEFAULT_DECIMAL_PLACEHOLDER = "--"
export const DECIMAL_LESS_THAN_ONE_PLACEHOLDER = "< 1"

/**
 * Determines whether a decimal is less than 1 or not
 *
 * @param value {number | string | null | undefined} - The value
 * @returns {boolean} - Whether the value is less than 1 or not
 * @example
 * isDecimalLessThanOne(waterUsage)
 */
export const isDecimalLessThanOne = (
  value: number | string | null | undefined
): boolean => {
  let numericValue: number

  if (typeof value === "number" || typeof value === "string") {
    numericValue = Number(value)

    return numericValue < 1
  }

  return false
}

/**
 * Determines whether a value is "empty" (e.g. null, undefined, NaN) or not
 *
 * @param value {number | string | null | undefined} - The value
 * @param options {IEmptyDecimalOptions} - The options
 * @param options.includeZero {boolean} - Whether to consider 0 as an "empty" value
 * @returns {boolean} - Whether the value is "empty" or not
 * @example
 * isDecimalEmpty(waterUsage, { includeZero: true })
 */
export const isDecimalEmpty = (
  value: number | string | null | undefined,
  options?: IEmptyDecimalOptions
): boolean => {
  const config: IEmptyDecimalOptions = {
    ...defaultEmptyDecimalOptions,
    ...options,
  }

  let numericValue: number

  if (typeof value === "number" || typeof value === "string") {
    numericValue = Number(value)
  }

  return (
    value === null ||
    value === undefined ||
    !Number.isFinite(numericValue) ||
    (config.includeZero && numericValue === 0)
  )
}

export const translateDecimal = (
  i18nService: i18n,
  value: number | string | null | undefined,
  format?: IDecimalFormat | null,
  options?: IDecimalOptions
): string => {
  let numericValue: number | null = null
  const formatConfig: IDecimalFormat = {
    ...defaultFormatConfig,
    ...format,
  }
  const optionsConfig: IDecimalOptions = {
    ...defaultOptionsConfig,
    ...options,
  }

  if (optionsConfig.useAccountingFormatWhenNegative) {
    formatConfig.signDisplay = "never"
  }

  // Handling for both numbers and numbers as strings
  if (
    Number.isFinite(value) ||
    (typeof value === "string" && Number.isFinite(Number.parseFloat(value)))
  ) {
    numericValue = Number(value)
  }

  // We need to handle significantDigits before fractionDigits in order to have fractionDigits win out
  // https://stackoverflow.com/questions/55834596/use-intl-numberformat-with-maximumfractiondigits-and-maximumsignificantdigi
  if (
    formatConfig.minimumSignificantDigits ||
    formatConfig.maximumSignificantDigits
  ) {
    // Run through Intl.NumberFormat with hardcoded en-US to get significant digits set before applying other internationalization
    const significantified: string = Intl.NumberFormat(
      LanguageRegion.EnglishUnitedStates,
      {
        minimumSignificantDigits: formatConfig.minimumSignificantDigits,
        maximumSignificantDigits: formatConfig.maximumSignificantDigits,
      }
    )
      .format(numericValue)
      .replace(/,/g, "")

    numericValue = Number(significantified)

    delete formatConfig.minimumSignificantDigits
    delete formatConfig.maximumSignificantDigits
  }

  // Handling of custom "precision" format
  if (Number.isInteger(formatConfig?.precision)) {
    formatConfig.minimumFractionDigits = formatConfig.precision
    formatConfig.maximumFractionDigits = formatConfig.precision
  }

  const showAccountingParenthesis: boolean =
    numericValue < 0 && optionsConfig?.useAccountingFormatWhenNegative

  if (Number.isFinite(numericValue)) {
    return `${showAccountingParenthesis ? "(" : ""}${i18nService.t(
      Translation.Common.Decimal,
      {
        value: numericValue,
        formatParams: {
          value: formatConfig,
        },
      }
    )}${showAccountingParenthesis ? ")" : ""}`
  }
  return ""
}

export const translateDecimalWithPlaceholder = (
  i18nService: i18n,
  value: number | string | null | undefined,
  format?: IDecimalFormat | null,
  options?: IDecimalWithPlaceholderOptions
): string => {
  if (
    isDecimalEmpty(value, { includeZero: options?.replaceZeroWithPlaceholder })
  ) {
    return options?.placeholder || DEFAULT_DECIMAL_PLACEHOLDER
  }

  return translateDecimal(i18nService, value, format, options)
}

/**
 * Translates a decimal to an emission format for the corresponding locale
 *
 * @param i18nService - The internationalization service
 * @param value - The value
 * @param options - The emission decimal options
 * @returns - The formatted decimal as a string
 * @example
 * translateEmissionDecimal(i18n, 0.000012345) // 0.000012
 * translateEmissionDecimal(i18n, 1234.567) // 1,234.57
 */
export const translateEmissionDecimal = (
  i18nService: i18n,
  value: number | string | null | undefined,
  options?: IDecimalWithPlaceholderOptions
): string => {
  let format: IDecimalFormat

  if (Number(value) < 1) {
    format = { maximumFractionDigits: 6, maximumSignificantDigits: 3 }
  } else {
    format = { precision: 2 }
  }

  return translateDecimalWithPlaceholder(i18nService, value, format, options)
}

/**
 * Sets the value to a string or SI suffix format
 *
 * @param numberValue - A number value
 * @returns Stringified or SI suffix formatted number
 */
export const formatNumberWithAbbreviation = (
  numberValue: NumberValue
): string => {
  const value =
    typeof numberValue === "number" ? numberValue : numberValue.valueOf()
  if (value > 1) {
    return d3Format("~s")(numberValue)
  }
  return value.toString()
}
