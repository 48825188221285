import React from "react"
import type { FC } from "react"
import { Navigate, useSearchParams } from "react-router-dom"

import { OrganizationResolver } from "@/components/organizationResolver"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { useAuthentication } from "@/services/authentication"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useUserAccess } from "@/services/user"
import { useUrl } from "@/utils/url"

import { If } from "../if/if"
import { Login } from "../login"

export const SignIn: FC = () => {
  const { isAuthenticated, isConsultantUser } = useAuthentication()
  const { isLimitedAccessUser } = useUserAccess()
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()
  const activeOrganizationId = organization?.id
  const { dashboardPath, reportingPath, sitesPath } = useUrl()

  const [searchParams] = useSearchParams()

  const isLimitedAccessUserBasicsEnabled = isFeatureEnabled(
    FeatureFlags.LIMITED_ACCESS_USER_BASICS,
    organization
  )

  const isUserRedirectedToSite =
    isLimitedAccessUser && !isLimitedAccessUserBasicsEnabled

  let landingPage = dashboardPath

  if (isUserRedirectedToSite) {
    landingPage = sitesPath
  }

  if (isConsultantUser) {
    landingPage = reportingPath
  }

  const redirectPath: string | undefined = searchParams.get("from") ?? undefined

  const landingPath: string = redirectPath ?? landingPage

  return (
    <>
      <If condition={!isAuthenticated}>
        <Login />
      </If>
      <If condition={isAuthenticated}>
        <If condition={!activeOrganizationId}>
          <OrganizationResolver />
        </If>
        <If condition={activeOrganizationId}>
          <Navigate to={landingPath} />
        </If>
      </If>
    </>
  )
}
