// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { AutoSizer, MultiGrid } from "react-virtualized"

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box } from "@mui/material"

import { ProvideChildrenUnlessLoading } from "../../../../../components/if/loading/provideChildrenUnlessLoading"
import { LoadingSpinner } from "../../../../../components/loadingSpinner"
import { Order } from "../../../../../models/sort"
import { lightGray } from "../../../../../utils/colors"
import type {
  ResourceSummaryReportTableColumn,
  ResourceSummaryReportTableRow,
} from "../../../models/resourceSummary"
import {
  ResourceSummaryReportColumn,
  extraRows,
} from "../../../models/resourceSummary"
import { VirtualizedReportingTableContent } from "./virtualizedReportingTableContent/virtualizedReportingTableContent"
import { VirtualizedReportingTableHeader } from "./virtualizedReportingTableHeader/virtualizedReportingTableHeader"
import { VirtualizedReportingTableTotalRow } from "./virtualizedReportingTableTotalRow/virtualizedReportingTableTotalRow"

import styles from "./virtualizedReportingTable.module.scss"

const siteWidth = 250
const siteIdWidth = 125
const departmentWidth = 150
const rightAlignedColumnWidth = 175
const electricityGhgScope2 = 215
const headerContentHeight = 84
const electricityEmissionsFactorColumnIndices: number[] = [3, 4]
const contentHeight = 48
const loadingSpinnerHeight = 200

interface ComponentProps {
  columns: ResourceSummaryReportTableColumn[]
  // TODO: Remove isUseFlexibleHierarchyEnabled prop when flexible hierarchy is fully enabled
  isUseFlexibleHierarchyEnabled: boolean
  onSort: (order: Order, orderBy: keyof ResourceSummaryReportTableRow) => void
  order: Order
  orderBy: keyof ResourceSummaryReportTableRow
  rows: ResourceSummaryReportTableRow[]
}

export const VirtualizedReportingTable = ({
  rows,
  columns,
  order,
  orderBy,
  onSort,
  isUseFlexibleHierarchyEnabled,
}: ComponentProps) => {
  const location = useLocation()
  const multiGrid = useRef<MultiGrid>(null)
  const loading: boolean = !rows || rows.length === 0
  const columnCount: number = columns.length

  const getRowHeight = ({ index }): number => {
    if (index === 0) {
      return headerContentHeight
    }

    if (loading) {
      return loadingSpinnerHeight
    }

    return contentHeight
  }

  const getColumnWidth = ({ index }): number => {
    const outputs: number[] = [siteWidth, siteIdWidth, departmentWidth]
    if (index < outputs.length) {
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return outputs[index]
    }
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (electricityEmissionsFactorColumnIndices.includes(index)) {
      return electricityGhgScope2
    }
    return rightAlignedColumnWidth
  }

  const handleRequestSort = (
    event: React.MouseEvent<keyof ResourceSummaryReportTableRow>,
    property: keyof ResourceSummaryReportTableRow
  ) => {
    const isDesc = orderBy === property && order === Order.desc
    onSort(isDesc ? Order.asc : Order.desc, property)
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    multiGrid.current.forceUpdateGrids()
  }

  const createSortHandler =
    (property: keyof ResourceSummaryReportTableRow) =>
    (event: React.MouseEvent<keyof ResourceSummaryReportTableRow>) => {
      handleRequestSort(event, property)
    }

  // Set sort to site name if current sorted column doesn't exist
  useEffect(() => {
    const columnKeys: string[] = columns.map((column) => column.key)

    if (!loading && !columnKeys.includes(orderBy)) {
      onSort(Order.asc, ResourceSummaryReportColumn.siteName.key)
    }
  }, [location])

  interface ILoadingStateProps {
    columnIndex: number
    rowIndex: number
    style: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  }

  const LoadingGridState = ({
    rowIndex,
    columnIndex,
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    style,
  }: ILoadingStateProps) => {
    if (rowIndex === 1 && columnIndex === 0) {
      return (
        <div key="loadingSpinner">
          <div className={styles.loadingSpinner}>
            <LoadingSpinner />
          </div>
        </div>
      )
    }
    return <></>
  }

  const MultiGridCellRenderer = ({ columnIndex, rowIndex, style, key }) => {
    if (rowIndex === 0) {
      return (
        <VirtualizedReportingTableHeader
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          key={key}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          columnIndex={columnIndex}
          columns={columns}
          createSortHandler={createSortHandler}
          order={order}
          orderBy={orderBy}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          style={style}
        />
      )
    }

    if (loading) {
      return (
        <LoadingGridState
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          key={key}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          columnIndex={columnIndex}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          rowIndex={rowIndex}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          style={style}
        />
      )
    }

    if (!loading && rowIndex === 1) {
      return (
        <VirtualizedReportingTableTotalRow
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          key={key}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          columnIndex={columnIndex}
          columns={columns}
          rows={rows}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          style={style}
        />
      )
    }

    return (
      <VirtualizedReportingTableContent
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        key={key}
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        columnIndex={columnIndex}
        columns={columns}
        // TODO: Remove isUseFlexibleHierarchyEnabled prop when flexible hierarchy is fully enabled
        isUseFlexibleHierarchyEnabled={isUseFlexibleHierarchyEnabled}
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        rowIndex={rowIndex}
        rows={rows}
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        style={style}
      />
    )
  }
  const propDefaults = {
    columnCount,
    fixedColumnCount: 1,
    fixedRowCount: 1,
    rowCount: rows.length + extraRows,
    ref: multiGrid,
    style: {
      border: `1px solid ${lightGray.toString()}`,
      boxSizing: "content-box" as const,
    },
    enableFixedColumnScroll: true,
    hideBottomLeftGridScrollbar: true,
  }

  return (
    <AutoSizer>
      {({ width, height }) => (
        // Replace all of this with MUI Grid
        // ProvideChildrenUnlessLoading is a hack to render grid correctly
        <ProvideChildrenUnlessLoading
          loading={loading}
          loadingComponent={
            <MultiGrid
              {...propDefaults}
              cellRenderer={MultiGridCellRenderer}
              columnWidth={getColumnWidth}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              height={height}
              id="multiGrid"
              rowHeight={getRowHeight}
              // migration to strict mode batch disable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              width={width}
            />
          }
        >
          <MultiGrid
            {...propDefaults}
            cellRenderer={MultiGridCellRenderer}
            columnWidth={getColumnWidth}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            height={height}
            id="multiGrid"
            rowHeight={getRowHeight}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            width={width}
          />
        </ProvideChildrenUnlessLoading>
      )}
    </AutoSizer>
  )
}
