import React, { useImperativeHandle, useRef } from "react"
import type { FieldPath, FieldValues } from "react-hook-form"
import { useController } from "react-hook-form"

import { TextField } from "../../inputs/text-field/text-field"
import type { FormTextFieldProps } from "./form-text-field.types"

export const FormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  id,
  error,
  helperText,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  inputRef,
  InputProps,
  ...rest
}: FormTextFieldProps<TFieldValues, TName>) => {
  const {
    field: { ref: fieldRef, ...restField },
    fieldState,
  } = useController({
    name,
  })

  const innerRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle(inputRef, () => innerRef.current)

  // helperText is always a string to avoid jarring UI shifts
  // required is false below to disable native browswer ui
  return (
    <TextField
      {...rest}
      {...restField}
      error={Boolean(fieldState.error) || error}
      helperText={
        fieldState.error ? fieldState.error.message : (helperText ?? " ")
      }
      id={id ?? name}
      InputProps={{ ...(InputProps ?? {}), required: false }}
      inputRef={(node) => {
        fieldRef(node)
        innerRef.current = node as HTMLInputElement
      }}
    />
  )
}
