import { Scope } from "./scope"

export enum ResourceName {
  Electricity = "Electricity",
  Fuel = "Fuel",
  NaturalGas = "Natural Gas",
  Refrigerants = "Refrigerants",
  Water = "Water",
}

export interface IResource {
  id: string
  name: ResourceName
  order: number
  scope?: Scope
}

interface IResourceMap {
  Electricity: IResource
  Fuel: IResource
  NaturalGas: IResource
  Refrigerants: IResource
  Water: IResource
}

export const Resource: IResourceMap = {
  Electricity: {
    id: "1",
    name: ResourceName.Electricity,
    order: 1,
    scope: Scope.Two,
  },
  NaturalGas: {
    id: "2",
    name: ResourceName.NaturalGas,
    order: 2,
    scope: Scope.One,
  },
  Fuel: {
    id: "3",
    name: ResourceName.Fuel,
    order: 3,
    scope: Scope.One,
  },
  Water: {
    id: "4",
    name: ResourceName.Water,
    order: 4,
    scope: Scope.None,
  },
  Refrigerants: {
    id: "5",
    name: ResourceName.Refrigerants,
    order: 5,
    scope: Scope.One,
  },
}

// migration to strict mode batch disable
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const resources: IResource[] = Object.values(Resource)
