import React, { useCallback, useMemo, useState } from "react"

import { useAvailableReportDates } from "@/services"
import type { Moment } from "moment"
import moment from "moment"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { Box, Button, Collapse, Typography } from "@mui/material"

import { MonthRangeSelector } from "../../../../components/date/monthRangeSelector/monthRangeSelector"
import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import useFilters from "../../../../hooks/useFilters/useFilters"
import BooleanFilter from "../../../../models/filter/booleanFilter"
import DateFilter from "../../../../models/filter/dateFilter"
import MultiSelectNumberFilter from "../../../../models/filter/multiSelectNumberFilter"
import SingleSelectNumberFilter from "../../../../models/filter/singleSelectNumberFilter"
import type { IRange } from "../../../../models/range"
import type { IScopeCategory } from "../../../../models/scope"
import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import { useScopeThreeEmissions } from "../../../../services"
import type { Report } from "../../models/report"
import { reportCard } from "../../models/report"
import type {
  IScopeThreeCategoryReportTableRow,
  IScopeThreeFilters,
  IScopeThreeSummaryReportTableRow,
} from "../../models/scopeThree"
import { getReportFileName } from "../../util/util"
import DownloadCsv from "../downloadCsv/downloadCsv"
import ReportLayout from "../reportLayout/reportLayout"
import { ScopeThreeDataLevel } from "./scopeThreeDataLevel/scopeThreeDataLevel"
import ScopeThreeDataPreview from "./scopeThreeDataPreview/scopeThreeDataPreview"
import {
  areScopeThreeReportFiltersValid,
  getScopeThreeCategories,
  getScopeThreeCategoryReport,
  getScopeThreeReportTitle,
  getScopeThreeSubcategories,
  getScopeThreeSummaryReport,
  isScopeThreeCategoryFilterValid,
  isScopeThreeSubcategoryFilterValid,
} from "./scopeThreeUtils"

import styles from "./scopeThree.module.scss"

export const ScopeThree = () => {
  const { filters, setFilters } = useFilters<IScopeThreeFilters>({
    start: DateFilter,
    end: DateFilter,
    summary: BooleanFilter,
    category: SingleSelectNumberFilter,
    subcategory: MultiSelectNumberFilter,
  })

  const { organization } = useOrganizationContext()
  const { availableReportDatesData } = useAvailableReportDates(organization?.id)
  const [isControlsOpen, setIsControlsOpen] = useState<boolean>(
    filters.summary.value === null ? false : !filters.summary.value
  )
  const {
    scopeThreeEmissions,
    isScopeThreeEmissionsFetched,
    isScopeThreeEmissionsFetching,
  } = useScopeThreeEmissions(organization?.id, {
    start: filters.start.value,
    end: filters.end.value,
  })

  const scopeCategories = useMemo(
    () => getScopeThreeCategories(scopeThreeEmissions),
    [scopeThreeEmissions]
  )
  const scopeSubcategories = useMemo(
    () => getScopeThreeSubcategories(scopeThreeEmissions),
    [scopeThreeEmissions]
  )

  const scopeThreeReport:
    | Report<IScopeThreeSummaryReportTableRow>
    | Report<IScopeThreeCategoryReportTableRow> = useMemo(() => {
    if (
      !areScopeThreeReportFiltersValid(
        filters,
        scopeCategories,
        scopeSubcategories
      )
    ) {
      return {
        data: {
          columns: [],
          rows: [],
        },
        name: "",
      }
    }

    const selectedCategory: IScopeCategory | undefined = scopeCategories.find(
      (category) => category.id === filters.category.value
    )

    const reportTitle: string = getScopeThreeReportTitle(
      filters.summary.value,
      selectedCategory
    )
    const reportFileName: string = getReportFileName(
      reportTitle,
      {
        start: filters.start.value,
        end: filters.end.value,
      },
      true
    )

    if (filters.summary.value) {
      return {
        data: getScopeThreeSummaryReport(scopeThreeEmissions),
        name: reportFileName,
      }
    }

    return {
      data: getScopeThreeCategoryReport(
        scopeThreeEmissions,
        filters.subcategory.value ?? []
      ),
      name: reportFileName,
    }
  }, [filters, scopeCategories, scopeSubcategories, scopeThreeEmissions])

  const isDownloadDisabled: boolean = useMemo(
    () =>
      !areScopeThreeReportFiltersValid(
        filters,
        scopeCategories,
        scopeSubcategories
      ) || !scopeThreeReport.data.rows?.length,
    [filters, scopeCategories, scopeSubcategories, scopeThreeReport]
  )

  const downloadButtonHelperText: string = useMemo(() => {
    if (
      !filters.summary.value &&
      scopeCategories?.length &&
      !isScopeThreeCategoryFilterValid(filters.category, scopeCategories)
    ) {
      return "Select a category to download"
    }
    if (
      !filters.summary.value &&
      isScopeThreeCategoryFilterValid(filters.category, scopeCategories) &&
      !isScopeThreeSubcategoryFilterValid(
        filters.subcategory,
        scopeSubcategories,
        filters.category,
        scopeCategories
      )
    ) {
      return "Select at least one subcategory to download"
    }
    if (isScopeThreeEmissionsFetched && !scopeThreeReport.data.rows?.length) {
      return "Data unavailable"
    }
    return ""
  }, [
    filters,
    isScopeThreeEmissionsFetched,
    scopeCategories,
    scopeSubcategories,
    scopeThreeReport,
  ])

  const onDateChange = useCallback(
    (value: IRange<Moment>): void => {
      setFilters({
        start: new DateFilter(moment(value.start)),
        end: new DateFilter(moment(value.end)),
      })
    },
    [setFilters]
  )

  const onSummaryChange = useCallback((): void => {
    setFilters({
      summary: new BooleanFilter(!filters.summary.value),
      category: new SingleSelectNumberFilter(),
      subcategory: new MultiSelectNumberFilter(),
    })
  }, [filters.summary.value, setFilters])

  const onCategoryChange = useCallback(
    (categoryId: number, subcategoryIds: number[]): void => {
      setFilters({
        category: new SingleSelectNumberFilter(categoryId),
        subcategory: new MultiSelectNumberFilter(subcategoryIds),
      })
    },
    [setFilters]
  )

  const onSubcategoryChange = useCallback(
    (value: number[]): void => {
      setFilters({
        subcategory: new MultiSelectNumberFilter(value),
      })
    },
    [setFilters]
  )

  return (
    <ReportLayout
      actions={
        <div>
          <DownloadCsv
            isDisabled={isDownloadDisabled}
            isLoading={isScopeThreeEmissionsFetching}
            report={scopeThreeReport}
          />
          <Collapse in={isScopeThreeEmissionsFetched && isDownloadDisabled}>
            <Typography
              className={styles.disabledButtonText}
              component="p"
              variant={TYPOGRAPHY_VARIANT.caption}
            >
              {downloadButtonHelperText}
            </Typography>
          </Collapse>
        </div>
      }
      details={
        <Collapse in={isScopeThreeEmissionsFetched}>
          <ScopeThreeDataPreview
            hasScopeThreeEmissions={!!scopeThreeEmissions?.length}
            isSummaryReport={filters.summary.value}
            itemCount={scopeThreeReport?.data?.rows?.length}
          />
        </Collapse>
      }
      filters={
        <>
          <div className={styles.dateRangeContainer}>
            <Box mr={2}>
              <span className={styles.criteriaLabel}>Date Range</span>
              <MonthRangeSelector
                availableMaxMinMonths={availableReportDatesData}
                onChange={onDateChange}
                value={{
                  start: filters.start.value,
                  end: filters.end.value,
                }}
              />
            </Box>
            <Button
              endIcon={
                isControlsOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )
              }
              onClick={() => {
                setIsControlsOpen(!isControlsOpen)
              }}
              size="small"
            >
              {isControlsOpen
                ? "Collapse Granularity Controls"
                : "Open Granularity Controls"}
            </Button>
          </div>
          <Collapse in={isControlsOpen}>
            {
              // isSummaryPresent must always have a value
              // so that the checkbox is always a controlled input
            }
            <ScopeThreeDataLevel
              categoryData={scopeCategories}
              hasScopeThreeEmissions={!!scopeThreeEmissions?.length}
              initialCategoryValue={filters.category.value}
              initialSubcategoryValue={filters.subcategory.value}
              isSummaryPresent={filters.summary.value ?? false}
              onCategoryChange={onCategoryChange}
              onSubcategoryChange={onSubcategoryChange}
              onSummaryChange={onSummaryChange}
              subcategoryData={scopeSubcategories}
            />
          </Collapse>
        </>
      }
      report={reportCard.Scope3}
    />
  )
}

export default ScopeThree
