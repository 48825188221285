export enum FeeType {
  Distribution = "distribution",
  Gas = "gas",
  Kilowatt = "kw",
  KilowattHour = "kwh",
  KilowattHourPerKilowatt = "kwh_per_kw",
  Month = "month",
  Tax = "tax",
}

export class Fee {
  constructor({ feeName, feeUsage, feeRate, feeCost, feeType }) {
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.feeName = feeName
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.feeUsage = feeUsage
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.feeRate = feeRate
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.feeCost = feeCost
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.feeType = feeType
  }

  public feeName: string

  public feeUsage: number

  public feeRate: number

  public feeCost: number

  public feeType: string
}
