import { client } from "../client"
import type { Operations, Schemas } from "../types"

export const getPlans = async ({
  query,
}: {
  query: Operations["get_bs_list_api_v1_plans_get"]["parameters"]["query"]
}): Promise<Schemas["PlanResponse"][]> => {
  const { data } = await client.GET("/api/v1/recommendations/plans", {
    params: {
      query,
    },
  })

  if (data) {
    return data
  }

  // Don't throw until we're handling in site module
  // throw error as Error
}
