/* eslint-disable @typescript-eslint/no-invalid-this */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { axisLeft, scaleLinear, select } from "d3"
import type { i18n } from "i18next"

import { UnitName } from "../../models/unit"
import type { IUserSettingsData } from "../../modules/settings"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { darkGray, gray500 } from "../../utils/colors"
import type { TIME_RANGES } from "../../utils/constants"
import { EnergyUnit, KWH_PER_MWH, yAxisText } from "../../utils/constants"
import { translateUnit } from "../../utils/unit"

const constructWattageUnitFormatter = ({
  height,
  maxDomainValue,
  wattUnit,
  i18nService,
  userSettings,
}) => {
  const y = scaleLinear().range([height, 0]).domain([0, maxDomainValue]).nice() // nice ;)

  const formatter = (axisValue: number): string => {
    const mwh =
      wattUnit === UnitName.MegawattHour ? axisValue / KWH_PER_MWH : axisValue
    const sigDigits = mwh < 5 ? 3 : 0
    const formattedData = mwh.toLocaleString(undefined, {
      minimumFractionDigits: sigDigits,
      maximumFractionDigits: sigDigits,
    })
    return `${formattedData} ${translateUnit(
      // Mass lint disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      i18nService.language,
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      wattUnit,
      // Mass lint disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      userSettings.currency
    )}`
  }

  return {
    formatter,
    y,
  }
}

export const customTickValues = (maxDomainValue: number) => {
  const maxTicks = 6

  const tickValues = [0]
  for (let i = 1; i < maxTicks; i += 1) {
    tickValues.push(maxDomainValue * (i / maxTicks))
  }
  tickValues.push(maxDomainValue)

  return tickValues
}

const constructYAxis = ({
  axisUnit,
  height,
  maxDomainValue,
  wattUnit,
  width,
  i18nService,
  userSettings,
}) => {
  // Defaults to displaying Watts
  let { formatter, y } = constructWattageUnitFormatter({
    height,
    maxDomainValue,
    wattUnit,
    i18nService,
    userSettings,
  })

  if (axisUnit === EnergyUnit.TOKEN) {
    formatter = (d) => `${d / 1000} Tokens`
    y = scaleLinear().range([height, 0]).domain([0, maxDomainValue]).nice()
  }

  const yAxis = axisLeft(y)
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    .tickValues(customTickValues(maxDomainValue))
    .tickSize(-width)
    .tickFormat(formatter)

  return {
    y,
    yAxis,
  }
}

export const renderYAxis = ({
  axisUnit,
  height,
  maxDomainValue,
  timeRange,
  wattUnit,
  width,
  outerPadding,
  i18nService,
  userSettings,
}: {
  axisUnit
  height
  i18nService: i18n
  maxDomainValue
  outerPadding
  timeRange: TIME_RANGES
  userSettings: IUserSettingsData
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
  width
}) => {
  const { y, yAxis } = constructYAxis({
    axisUnit,
    height,
    maxDomainValue,
    wattUnit,
    width,
    i18nService,
    userSettings,
  })

  const svg = select("g.y-axis-container")

  svg.selectAll("g.yaxis").remove()

  svg
    .append("g")
    .attr("class", "yaxis")
    .attr("stroke", gray500.toString())
    .attr("stroke-width", 0.5)
    .attr("stroke-opacity", 0.5)
    .call(yAxis)
    .call((g) => g.select(".domain").remove())
    .call((g) =>
      g
        .selectAll(".tick:first-of-type line")
        .attr("stroke-width", 2)
        .attr("stroke-opacity", 1)
    )
    .call((g) => g.selectAll(".tick:last-of-type text").remove())
    .call((g) => g.selectAll(".tick:last-of-type line").remove())
    .call((g) =>
      g
        .selectAll(".tick:nth-of-type(n+1):not(:first-child) line")
        .attr("x2", width - outerPadding)
    )
    .call((g) =>
      g
        .selectAll(".tick text")
        .style("font-size", "12px")
        .style("color", gray500.toString())
        .style("text-anchor", "start")
        .attr("x", 20)
        .attr("dy", 15)
    )
    .call((g) =>
      g
        .select(".tick:first-of-type text")
        .attr("x", 0)
        .text(yAxisText(timeRange))
    )

  return y
}
