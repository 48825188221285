import React from "react"

import type { SxProps } from "@mui/material"
import { Chip } from "@mui/material"

import { darkestTeal, lightestTeal } from "../../utils/colors"
import { pxToRem } from "../../utils/unit"
import { If } from "../if/if"

export const enum FeatureReleaseCalloutTypes {
  BETA = "BETA",
  NEW = "NEW",
}

const styles: SxProps = {
  color: darkestTeal.rgb().toString(),
  backgroundColor: lightestTeal.rgb().toString(),
  cursor: "inherit",
  borderRadius: "16px",
  borderWidth: "1px",
  borderStyle: "solid",
  height: "unset",
  "& .MuiChip-label": {
    fontSize: pxToRem(10),
    lineHeight: pxToRem(10),
    paddingTop: "4px",
    paddingBottom: "4px",
  },
}

interface IFeatureReleaseCalloutProps {
  className?: string
  sx?: SxProps
  type?: FeatureReleaseCalloutTypes
}

/**
 * Renders a feature release callout chip.
 *
 * @param props - The component's props
 * @param props.type - The type of callout to display (the content)
 * @param props.className - Optional class names to apply to the root Chip element
 * @param props.sx - Optional MUI sx styles to apply to the root Chip element
 * @returns A feature release callout chip component
 * @example
 * // Renders a chip with "BETA" as the content
 * <FeatureReleaseCallout type={FeatureReleaseCalloutTypes.BETA} />
 */
const FeatureReleaseCallout = ({
  type,
  className,
  sx,
}: IFeatureReleaseCalloutProps) => (
  <If condition={!!type}>
    <Chip
      className={className}
      data-testid="feature-release-callout-chip"
      label={type}
      size="small"
      sx={{ ...styles, ...sx }}
    />
  </If>
)

export default FeatureReleaseCallout
