import type { MutableRefObject } from "react"
import { useEffect, useState } from "react"

export const useResizeObserver = (
  ref: MutableRefObject<undefined>
): Record<string, DOMRectReadOnly> | null => {
  const [dimensions, setDimensions] = useState<DOMRectReadOnly | null>(null)
  if (!ref) {
    return null
  }

  useEffect(() => {
    const target = ref.current
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect)
      })
    })

    resizeObserver.observe(target)

    return () => {
      resizeObserver.unobserve(target)
    }
  }, [ref])

  return { dimensions }
}
