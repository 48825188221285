import type { Moment } from "moment"

export interface IDateFormat extends Intl.DateTimeFormatOptions {
  dateStyle?: "full" | "long" | "medium" | "short"
  timeStyle?: "full" | "long" | "medium" | "short"
}

export enum DatePeriod {
  MTD = "MTD",
  YTD = "YTD",
}

export enum DatePartName {
  Day = "day",
  Literal = "literal",
  Month = "month",
  Year = "year",
}

export interface IWithDate {
  date: string | Moment
}
