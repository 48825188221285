import { getMeasurementSystem } from "."
import type { CurrencyCode } from "../models/currencyCode"
import { defaultCurrencyCode } from "../models/currencyCode"
import type { LanguageRegion } from "../models/i18n"
import type { IMeasurementSystem } from "../models/measurementSystem"
import { MeasurementSystemName } from "../models/measurementSystem"
import type { IUnit, IUnitSeparator } from "../models/unit"
import {
  CustomVariableNameToUnitKeyMap,
  FeeTypeToUnitKeyMap,
  USCUnitNameToUnitNameMap,
  UnitName,
  UnitNameToUSCUnitNameMap,
  UnitSeparators,
  Units,
} from "../models/unit"
import { getCurrencySymbol } from "./currency"

const getUnit = (
  measurementSystem: IMeasurementSystem,
  unitName: UnitName
): IUnit | undefined => {
  const unit: IUnit | undefined = Units.get(unitName)

  if (!unit || !Object.values(UnitName).includes(unitName)) {
    return
  }

  if (unitName.includes("currency")) {
    return Units.get(UnitName.Currency)
  }
  if (
    unit.measurementSystem &&
    unit.measurementSystem !== MeasurementSystemName.BIS &&
    measurementSystem.name !== unit.measurementSystem
  ) {
    let unitTranslationMap: Map<UnitName, UnitName>

    if (unit.measurementSystem === MeasurementSystemName.SI) {
      unitTranslationMap = UnitNameToUSCUnitNameMap
    } else if (unit.measurementSystem === MeasurementSystemName.USC) {
      unitTranslationMap = USCUnitNameToUnitNameMap
    }

    return unitTranslationMap.has(unitName)
      ? Units.get(unitTranslationMap.get(unitName))
      : unit
  }

  return unit
}

const isTokenASeparator = (token: string): boolean =>
  Array.from(UnitSeparators.values())
    .map((separator) => separator.tokenValue)
    .includes(token)

const tokenizeUnit = (unit: UnitName): string[] => {
  const tokenizedStr: string = Array.from(UnitSeparators.values()).reduce(
    (value, separator): string =>
      value.split(separator.tokenValue).join(` ${separator.tokenValue} `),
    unit
  )

  // trim() to handle Unit.PerKilowattHour where -per- appears as the first token
  return tokenizedStr.trim().split(" ")
}

const translateIndividualUnit = (
  locale: string,
  unit: IUnit,
  currency: CurrencyCode,
  value: number | string
): string => {
  // Handle currency
  if (unit.id === UnitName.Currency) {
    return getCurrencySymbol(locale, currency)
  }
  // Handle custom units
  if (!unit.isECMAScriptSupported) {
    return unit.shortDisplayValue
  }
  // Handle Intl.NumberFormat supported units

  const translatedNumberParts = Intl.NumberFormat(locale, {
    style: "unit",
    unit: unit.tokenValue,
  }).formatToParts(Number(value) || 0)

  return translatedNumberParts.find((part) => part.type === "unit")?.value || ""
}

/**
 * A function that returns the unit key when given a custom variable name
 *
 * @param name - The name of the custom variable
 * @returns - The key of the corresponding unit
 * @example
 * getUnitKeyFromCustomVariableName("Sq ft")
 */
export const getUnitKeyFromCustomVariableName = (name: string): string =>
  CustomVariableNameToUnitKeyMap.get(name) ?? ""

/**
 * A function that returns the unit key when given a fee type
 *
 * @param feeType - The fee type
 * @returns - The corresponding unit key
 * @example
 * getUnitKeyFromFeeType("kwh")
 */
export const getUnitKeyFromFeeType = (feeType: string): string =>
  FeeTypeToUnitKeyMap.get(feeType) ?? ""

/**
 * A function that formats and internationalizes the provided unit
 *
 * @param locale - The locale
 * @param unit - The unit
 * @param currency - The currency code
 * @param value - The number to use for handling pluralization
 * @example
 * translateUnit(i18n, SIUnit.KilowattHour, userSettings.currency, 123)
 */
export const translateUnit = (
  locale: string,
  unit: UnitName,
  currency: CurrencyCode = defaultCurrencyCode,
  value?: number | string | null | undefined
): string => {
  if (!unit) {
    return ""
  }

  // Log invalid units so we'll see them in our observability tools
  if (!Object.values(UnitName).includes(unit)) {
    console.error(`${unit} is not a supported unit`)
    return ""
  }

  const measurementSystem: IMeasurementSystem = getMeasurementSystem(
    locale as LanguageRegion
  )

  const tokens: string[] = tokenizeUnit(unit)

  const unitDisplays: string[] = tokens.map((token) => {
    if (isTokenASeparator(token)) {
      const separator: IUnitSeparator | undefined = Array.from(
        UnitSeparators.values()
      ).find((unitSeparator) => unitSeparator.tokenValue === token)

      return separator?.displayValue || ""
    }

    return translateIndividualUnit(
      locale,
      getUnit(measurementSystem, token as UnitName),
      currency,
      value
    )
  })

  return unitDisplays.join("")
}

/**
 * @deprecated - Use theme.typography.pxToRem()
 */
export const pxToRem = (value: number) => `${value / 16}rem`
