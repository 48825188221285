import React from "react"

import { Typography } from "@mui/material"

interface FieldWrapperProps {
  children: React.ReactNode
  errorMessage?: string
  label: string
  name: string
  required?: boolean
}

export const FieldWrapper = ({
  name,
  label,
  required,
  errorMessage,
  children,
}: FieldWrapperProps) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    }}
  >
    {label && (
      <label htmlFor={name}>
        <Typography gutterBottom>
          {label}
          {required ? "*" : ""}
        </Typography>
      </label>
    )}
    {children}
    {errorMessage && (
      <Typography sx={{ color: "error.main" }} variant="body2">
        {errorMessage}
      </Typography>
    )}
  </div>
)
