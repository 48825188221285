import stableStringify from "json-stable-stringify"

import type {
  ServiceAction,
  ServiceEntityKey,
  ServiceName,
  ServiceQueryKey,
} from "../types"

/**
 * Create a standardized key array to uniquely identify a single query.
 * @param serviceName A unique identifier for a CRUD grouping of service hooks.
 * @param serviceAction The action types used to categorize the functionality of services.
 * @param options An arbitrary object, interpolated as a string, to make keys unique.
 * @returns A tuple of primitive values describing the query.
 */
export const createQueryKey = <
  NameT extends ServiceName,
  ActionT extends ServiceAction,
  IdT extends ServiceEntityKey,
>(
  serviceName: NameT,
  serviceAction: ActionT,
  serviceEntityKey: null | IdT = null,
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceOptions: Record<string, any> = {}
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
): ServiceQueryKey<NameT, ActionT, IdT> => [
  serviceName,
  serviceAction,
  serviceEntityKey ?? null,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  stableStringify(serviceOptions ?? {}),
]
