import React from "react"
import type { FC } from "react"

import type { IDateFormat } from "@/models/date/date"
import type { Moment } from "moment"

import { Box } from "@mui/material"

import { If } from "../../if/if"
import { DateDisplay } from "../dateDisplay/dateDisplay"

interface IDateRangeDisplayProps {
  /**
   * The end date
   */
  end: Moment | null

  /**
   * The format configuration for the date range
   */
  format?: IDateFormat

  /**
   * The start date
   */
  start: Moment | null
}

/**
 * A component that properly formats and internationalizes the provided date range
 *
 * @example
 * <DateRangeDisplay start={moment("2021-01-01")} end={moment("2021-02-28")} format={{
 *   year: "numeric"
 * }} />
 */
export const DateRangeDisplay: FC<IDateRangeDisplayProps> = ({
  start,
  end,
  format,
}) => (
  <If condition={start?.isValid()}>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <DateDisplay format={format} value={start} />
      <If condition={end?.isValid()}>
        –
        <DateDisplay format={format} value={end} />
      </If>
    </Box>
  </If>
)
