import React from "react"

import { ToggleButton, ToggleButtonGroup } from "@mui/material"

import { GhgWeightUnit, UnitName } from "../../models/unit"
import { Unit } from "../unit/unit"

import styles from "./weightUnitToggle.module.scss"

interface ComponentProps {
  showToggle: boolean
  weightUnit: GhgWeightUnit
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  weightUnitHandler: (event: any) => void
}

/**
 * The component that handles the toggle buttons for the
 *
 * @param weightUnit - A single string value
 * @param weightUnitHandler - A single string value
 * @param showToggle - A single string value
 * @returns - The JSX element to be rendered
 * @example
 * new WeightUnitToggle(ghgWeightUnit.Lbs, handleWeightUnitChange, true)
 */
export const WeightUnitToggle = ({
  weightUnit,
  weightUnitHandler,
  showToggle,
}: ComponentProps): JSX.Element => {
  const isLbsActive: boolean = weightUnit === GhgWeightUnit.Lbs

  const toggleMenuInactive = showToggle ? "" : styles.toggleMenuInactive

  const lbsClass = isLbsActive ? styles.unitActive : styles.unitInactive
  const mtClass = !isLbsActive ? styles.unitActive : styles.unitInactive

  const lbsBorderRightClass = isLbsActive ? "" : styles.lbsUnit__inactive
  const mtBorderLeftClass = !isLbsActive ? "" : styles.mtUnit__inactive

  return (
    <ToggleButtonGroup exclusive>
      <div
        className={`${styles.toggleMenu} ${toggleMenuInactive}`}
        data-active={showToggle}
        data-testid="toggle-button-container"
      >
        <p className="financial-summary__toggle-menu-title data-label--medium data-label--medium__regular">
          <Unit unit={UnitName.CarbonDioxideEquivalent} /> units
        </p>
        <div className={styles.buttonContainer}>
          {
            // TODO: These values should be enums, not strings
          }
          <ToggleButton
            className={`${mtClass} ${styles.mtUnit} ${styles.unit} ${mtBorderLeftClass}`}
            data-testid="toggle-mt"
            onClick={() => {
              weightUnitHandler(GhgWeightUnit.Mt)
            }}
            value="mt"
          >
            <Unit unit={UnitName.MetricTon} />
          </ToggleButton>
          <ToggleButton
            className={`${lbsClass} ${styles.lbsUnit} ${styles.unit} ${lbsBorderRightClass}`}
            onClick={() => {
              weightUnitHandler(GhgWeightUnit.Lbs)
            }}
            value="lbs"
          >
            <Unit unit={UnitName.Pound} />
          </ToggleButton>
        </div>
      </div>
    </ToggleButtonGroup>
  )
}
