import { getMaxLengthMessage, requiredMessage } from "@/schemas"
import z from "zod"

import { contactDialogTypes } from "./contact-dialog.types"

const messageMaxLength = 500

export const contactSchema = z
  .object({
    message: z
      .string()
      .trim()
      .max(messageMaxLength, { message: getMaxLengthMessage(messageMaxLength) })
      .min(1, { message: requiredMessage }),
    type: z.enum(contactDialogTypes),
  })
  // Why partial? To allow the confirmPassword validation to display without having to complete the entire form.
  // See https://github.com/colinhacks/zod/issues/479#issuecomment-854822386
  .partial()

export type ContactSchema = z.infer<typeof contactSchema>
