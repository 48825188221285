import Color from "color"

/**
 *
 *
 * PLEASE READ:
 * All approved colors for design are now also in the theme.ts file.
 * This means you should use the tokens from the theme file
 * instead of directly importing them from here. You also do not
 * need to call `toString()` or anything like that on the color from
 * the theme. If a figma file has a color that does not map to the theme,
 * please have a conversation with them and the design team as a whole.
 * There is also a slack channel, #design-system-mui, for asking these sorts of questions.
 *
 *
 */

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const almond = new Color("#EFDDC9")

/**
 * @deprecated Use palette.grey.25 from the theme
 */
export const bgColor = new Color("#F9F9F9")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const biomassGreen = new Color("#6C8446")

export const black = new Color("#000000")

/**
 * @deprecated Use palette.action.disabledBackground from the theme
 */
export const black12 = new Color("rgba(0,0,0,0.12)")

/**
 * @deprecated Use palette.action.disabled from the theme
 */
export const black25 = new Color("rgba(0,0,0,0.25)")

/**
 * @deprecated Use palette.text.secondary from the theme
 */
export const black60 = new Color("rgba(0,0,0,0.6)")

/**
 * @deprecated Use palette.text.primary from the theme
 */
export const black87 = new Color("rgba(0,0,0,0.87)")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const coalBrown = new Color("#635A49")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const darkBlue = new Color("#2B3671")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const darkCarbon = new Color("#A6A9B2")

/**
 * @deprecated Use palette.grey.800 from the theme
 */
export const darkGray = new Color("#1D1D1D")

/**
 * @deprecated Use palette.grey.300 from the theme
 */
export const dustyGray = new Color("#9B9B9B")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const gasBrown = new Color("#968257")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const geothermalBlue = new Color("#00D4CD")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const hydroBlue = new Color("#9FC5FF")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const lightCarbon = new Color("#D6D9E5")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const lightGray = new Color("#CECECD")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const lighterGray = new Color("#E9E9E9")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const lightGreen = new Color("#98D4AD")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const lightSolarOrange = new Color("#FEAB0F")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const nuclearGreen = new Color("#A3EE18")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const offWhite = new Color("#F9F9F9")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const pickledBluewood = new Color("#233143")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const solarOrange = new Color("#D68D40")

/**
 * @deprecated The alternate color is grey600 from the theme.ts
 */
export const solidGray = new Color("#545454")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const solarYellow = new Color("#FEAB0F")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const warningYellowAccent = new Color("#FEE0A7")

export const transparent = new Color("rgba(0,0,0,0)")

export const white = new Color("#FFFFFF")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const windGreen = new Color("#C7DC67")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const coral = new Color("#FE6504")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const darkTeal = new Color("#018281")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const darkestTeal = new Color("#006060")

/**
 * @deprecated Use the theme's palette.action.hover
 */
export const hoverOpacity = new Color("rgba(0, 0, 0, 0.04)")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const lightBlue = new Color("#BACCE9")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const lightestBlue = new Color("#DBE6F7")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const lightestTeal = new Color("#DAEBE9")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const limeGreen = new Color("#479A5E")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const newBlue = new Color("#3362AB")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const placeholder = new Color("#C4C4C4")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const fossilFuelGray = new Color("#92949C")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const teal = new Color("#84BDBA")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const errorColor = new Color("#CF2517")

/**
 * @deprecated Use grey from theme palette
 */
export const gray = new Color("#7C7C7C")

/**
 * @deprecated Use grey from theme palette
 */
export const deprecatedGray400 = new Color("#ABABAB")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const scope2Blue = new Color("#A4C8D1")

/**
 * @deprecated Use grey from theme palette
 */
export const grayScale = new Color("#E9E9E9")

// carbon intensity colors -- feel free to change any of the names
// some taken from https://www.color-blindness.com/color-name-hue/

// darkest color is above: darkGray = #1D1D1D
/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const darkSlateBlue = new Color("#404866")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const cometBlue = new Color("#676C84")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const lightSlateBlue = new Color("#A4A7B5")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const linkWater = new Color("#D6D9E5")

/**
 * @deprecated Please ask the design team for an alternate color that is in the theme.ts
 */
export const ghostWhite = new Color("#EEF1FE")

/**
 * @deprecated It doesn't belong in this file
 */
export const fuelColorMap = new Map([
  [
    "BATTERY",
    {
      name: "Battery",
      color: placeholder,
    },
  ],
  [
    "BIOMASS",
    {
      name: "Biomass",
      color: biomassGreen,
    },
  ],
  [
    "COAL",
    {
      name: "Coal",
      color: coalBrown,
    },
  ],
  [
    "GAS",
    {
      name: "Gas",
      color: gasBrown,
    },
  ],
  [
    "GEOTHERMAL",
    {
      name: "Geothermal",
      color: geothermalBlue,
    },
  ],
  [
    "HYDRO",
    {
      name: "Hydro",
      color: hydroBlue,
    },
  ],
  [
    "IMBALANCE",
    {
      name: "Imbalance",
      color: lightGray,
    },
  ],
  [
    "NUCLEAR",
    {
      name: "Nuclear",
      color: nuclearGreen,
    },
  ],
  [
    "OFSL",
    {
      name: "Other Fossil Fuel",
      color: placeholder,
    },
  ],
  [
    "OIL",
    {
      name: "Oil",
      color: black,
    },
  ],
  [
    "OTHF",
    {
      name: "Other Fuel",
      color: placeholder,
    },
  ],
  [
    "SOLAR",
    {
      name: "Solar",
      color: solarYellow,
    },
  ],
  [
    "Unspecified",
    {
      name: "Market Hub (Unspecified)",
      color: white,
    },
  ],
  [
    "WIND",
    {
      name: "Wind",
      color: windGreen,
    },
  ],
  [
    "CARBON-FREE",
    {
      name: "Carbon-Free Electricity",
      color: lightGreen,
    },
  ],
  [
    "FOSSIL-FUEL",
    {
      name: "Fossil Fuels",
      color: fossilFuelGray,
    },
  ],
  [
    "City of Reno Rooftop Solar",
    {
      name: "City of Reno Rooftop Solar",
      color: solarYellow,
    },
  ],
  [
    "NV Energy North",
    {
      name: "NV Energy North",
      color: placeholder,
    },
  ],
])

export const colorDarken = (color: Readonly<Color>): Color =>
  color.desaturate(0.3)

// UI refresh color palette
export const primary25 = new Color("#F5F9FF")
export const primary50 = new Color("#E7F2FF")
export const primary100 = new Color("#BEDCFF")
export const primary200 = new Color("#9FCBFF")
export const primary300 = new Color("#74B0F8")
export const primary400 = new Color("#1073EB")
export const primary500 = new Color("#135CB1")
export const primary600 = new Color("#144F95")
export const primary700 = new Color("#0A3F7D")
export const primary800 = new Color("#0D2E54")

export const cerulean25 = new Color("#E6F3F6").lighten(0.1)
export const cerulean50 = new Color("#E6F3F6")
export const cerulean100 = new Color("#B8DAE4")
export const cerulean200 = new Color("#7FBED2")
export const cerulean300 = new Color("#559EB5")
export const cerulean400 = new Color("#297D97")
export const cerulean500 = new Color("#046381")
export const cerulean600 = new Color("#045771")
export const cerulean700 = new Color("#03465B")
export const cerulean800 = new Color("#023241")

export const gray25 = new Color("#F9F9F9")
export const gray50 = new Color("#F0F0F0")
export const gray100 = new Color("#E3E3E3")
export const gray200 = new Color("#BDBDBD")
export const gray300 = new Color("#8E8E8E")
export const gray400 = new Color("#7A7A7A")
export const gray500 = new Color("#636363")
export const gray600 = new Color("#4E4E4E")
export const gray700 = new Color("#3A3A3A")
export const gray800 = new Color("#1D1D1D")

export const green25 = new Color("#EBF3EA").lighten(0.1)
export const green50 = new Color("#EBF3EA")
export const green100 = new Color("#A9E49E")
export const green200 = new Color("#84CD7A")
export const green300 = new Color("#479A5E")
export const green400 = new Color("#408854")
export const green500 = new Color("#346942")
export const green600 = new Color("#2E5939")
export const green700 = new Color("#26472E")
export const green800 = new Color("#1D3222")

export const red25 = new Color("#FFEDE7").lighten(0.1)
export const red50 = new Color("#FFEDE7")
export const red100 = new Color("#FFCCBC")
export const red200 = new Color("#FDA58D")
export const red300 = new Color("#F57F63")
export const red400 = new Color("#CF2517")
export const red500 = new Color("#BE0B0B")
export const red600 = new Color("#A50000")
export const red700 = new Color("#860000")
export const red800 = new Color("#630000")

export const mustard25 = new Color("#FEFBF6")
export const mustard50 = new Color("#FFF1CF")
export const mustard100 = new Color("#FFE4A6")
export const mustard200 = new Color("#F2BA39")
export const mustard300 = new Color("#E29E03")
export const mustard400 = new Color("#AC6B0E")
export const mustard500 = new Color("#7E530E")
export const mustard600 = new Color("#6F480D")
export const mustard700 = new Color("#5A390A")
export const mustard800 = new Color("#402807")

export const peach25 = new Color("#FEFBF6")
export const peach50 = new Color("#FBE4D6")
export const peach100 = new Color("#FFD6BD")
export const peach200 = new Color("#FCB486")
export const peach300 = new Color("#E18245")
export const peach400 = new Color("#BC5818")
export const peach500 = new Color("#944513")
export const peach600 = new Color("#813C10")
export const peach700 = new Color("#67300D")
export const peach800 = new Color("#4A2209")

export const purple25 = new Color("#F3EFF6").lighten(0.1)
export const purple50 = new Color("#F3EFF6")
export const purple100 = new Color("#F5E8FF")
export const purple200 = new Color("#E0C7F4")
export const purple300 = new Color("#B08BCD")
export const purple400 = new Color("#8767A7")
export const purple500 = new Color("#684B8D")
export const purple600 = new Color("#503375")
export const purple700 = new Color("#42226A")
export const purple800 = new Color("#2F1452")

export const carbonIntensity1 = new Color("#CAFE96")
export const carbonIntensity2 = new Color("#D7F139")
export const carbonIntensity3 = new Color("#F2E00B")
export const carbonIntensity4 = new Color("#ECC125")
export const carbonIntensity5 = new Color("#DF8838")
export const carbonIntensity6 = new Color("#AE3C0F")

export const getProductColor = (sourceType: string): Color => {
  switch (sourceType) {
    case "fixed":
    case "market":
    case "hub_market":
    case "custom_market":
    case "bundled_delivered":
      return cerulean300
    case "resource":
      return lightSolarOrange
    case "curtailment":
      return white
    case "short_imbalance":
      return coral
    case "long_imbalance":
    case "bundled_received":
      return transparent
    default:
      return cerulean300
  }
}
