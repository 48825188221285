import type { ReactElement } from "react"
import React from "react"

import reportComplete from "../../assets/images/report-complete.svg"
import { DataAlertCompleteText } from "../../models/report"
import DataAlertBase from "../dataAlertBase/dataAlertBase"

const DataAlertComplete = (): ReactElement => (
  <DataAlertBase
    altText={DataAlertCompleteText.AltText}
    body={DataAlertCompleteText.Body}
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    img={reportComplete}
    title={DataAlertCompleteText.Title}
  />
)

export default DataAlertComplete
