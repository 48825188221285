import { useQuery } from "react-query"
import type { UseQueryOptions } from "react-query"

import { ApiQueryName } from "@/models/api"
import type { Schemas } from "@/services/types"
import { useRenameQueryResult } from "@/services/utils"
import { createQueryKey } from "@/services/utils/createQueryKey"
import { httpClient } from "@/utils/api"

import type { Thread } from "../../assistant.types"

// "specialty" should be optional
export type GetThreadsParams = Omit<
  Schemas["ThreadRequest"],
  "assistant_user_id"
>

const placeholderThread: Thread = {
  assistantId: "1",
  messages: [
    {
      content: "",
      role: "user",
    },
    {
      content: "",
      role: "assistant",
    },
  ],
  organizationId: "1",
  specialty: "default",
  status: "active",
  threadId: "1",
  userId: "1",
}

const placeholderThreads: Thread[] = [
  {
    ...placeholderThread,
    threadId: "1",
  },
  {
    ...placeholderThread,
    threadId: "2",
  },
  {
    ...placeholderThread,
    threadId: "3",
  },
  {
    ...placeholderThread,
    threadId: "4",
  },
  {
    ...placeholderThread,
    threadId: "5",
  },
]

/**
 * Gets a list of threads
 */
export const getThreads = async (
  params: GetThreadsParams
): Promise<Thread[]> => {
  try {
    const response = await httpClient.get<Thread[]>(
      "/api/v1/recommendations/assistant/threads",
      {
        params,
      }
    )

    return response.data
  } catch {
    console.error("Failed to fetch threads")
    return []
  }
}

interface UseThreadsOptions {
  params: GetThreadsParams
  useQueryOptions?: Omit<UseQueryOptions<Thread[]>, "queryFn" | "queryKey">
}

/**
 * Gets threads
 */
export const useThreads = (options: UseThreadsOptions) => {
  const queryKey = createQueryKey(
    ApiQueryName.AssistantThreads,
    "getMany",
    null,
    options.params
  )

  const queryResult = useQuery<Thread[]>({
    ...options.useQueryOptions,
    keepPreviousData: true,
    placeholderData: placeholderThreads,
    queryKey,
    queryFn: async () => getThreads(options.params),
  })

  return useRenameQueryResult(queryResult, ApiQueryName.AssistantThreads)
}
