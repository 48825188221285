import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import PhysicalDetailType from "../../../../models/physical-detail-types"

/**
 * gets list of physcial detail types
 */
export const getPhysicalDetailTypes = async (): Promise<
  PhysicalDetailType[] | undefined
> => {
  const response: CollectionProxy<PhysicalDetailType> =
    await PhysicalDetailType.all()

  return response.data
}
