// @ts-strict-ignore
import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"

import { ApiQueryName } from "../../../../models/api"
import { fetchSiteExtraAttributes } from "./useSiteExtraAttributesUtils"

/**
 * Hook to provide site extra attributes and provide loading states.
 *
 * @example
 * const {
 *  siteExtraAttributes,
 *  isSiteExtraAttributesFetched,
 *  isSiteExtraAttributesLoading
 * } = useSiteExtraAttributes("example-site-id");
 */
export const useSiteExtraAttributes = (siteId: string | undefined) => {
  const queryKey = useQueryKey(
    ApiQueryName.SiteExtraAttributes,
    "getOne",
    siteId
  )
  const queryResult = useQuery({
    queryKey,
    queryFn: () => fetchSiteExtraAttributes({ siteId }),
    enabled: !!siteId,
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
