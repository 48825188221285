import React from "react"

import { Box, Paper, Typography, useTheme } from "@mui/material"

import {
  MetricType,
  getMetricFormattingComponent,
} from "../../../../models/decimal/decimal"
import { pxToRem } from "../../../../utils/unit"
import StackedAreaGraphTooltipRow from "./stacked-area-graph-tooltip-row"
import StackedAreaGraphTooltipRows from "./stacked-area-graph-tooltip-rows"
import type { StackedAreaGraphTooltipProps } from "./stacked-area-graph-tooltip.types"

export const StackedAreaGraphTooltip: React.FC<
  StackedAreaGraphTooltipProps
> = ({ active, isTotalHidden, payload }) => {
  const theme = useTheme()
  const styles = {
    container: {
      border: `1px solid ${theme.palette.grey[500]}`,
      transition: "opacity 0.45s",
      width: "148px",
    },
    header: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: `${pxToRem(8)} ${pxToRem(8)} 0 0`, // needed for the background color to go over the border
      display: "flex",
      justifyContent: "space-between",
      whiteSpace: "nowrap",
      p: 1,
    },
    footer: {
      borderTop: `1px solid ${theme.palette.grey[500]}`,
      p: 1,
    },
  }

  if (active && payload?.length) {
    let total = 0
    const column1Header = payload[0].payload.tooltipColumn1Header
    const column2Header = payload[0].payload.tooltipColumn2Header
    const { tooltipLabelsMap } = payload[0].payload
    const rows = payload.map((item) => {
      total += item.value
      const tooltip = tooltipLabelsMap[item.dataKey]
      return {
        label: tooltip.label,
        value: item.value,
        color: item.color,
        decimalComponent: getMetricFormattingComponent(
          tooltip.metricFormattingComponentId
        ),
      }
    })
    // Ensure that the rows are in the correct display order
    const reversedRows = [...rows].reverse()
    return (
      <Paper sx={styles.container}>
        <Box sx={styles.header}>
          <Typography fontWeight="bold" variant="dataLabel">
            {column1Header}
          </Typography>
          <Typography fontWeight="bold" variant="dataLabel">
            {column2Header}
          </Typography>
        </Box>
        <StackedAreaGraphTooltipRows rows={reversedRows} />
        {!isTotalHidden && (
          <Box sx={styles.footer}>
            <StackedAreaGraphTooltipRow
              rowItem={{
                label: "Total",
                value: total,
                decimalComponent: getMetricFormattingComponent(
                  MetricType.Emission
                ),
              }}
            />
          </Box>
        )}
      </Paper>
    )
  }

  return null
}

export default StackedAreaGraphTooltip
