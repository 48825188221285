import type Address from "../../../../models/address"

export const getSiteAddress = (address: Address) => {
  // migrate directories to strict mode
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (address) {
    // migrate directories to strict mode
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return `${address.address_line_1 ?? ""} ${address.address_line_2 ?? ""} ${
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      address.city ?? ""
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } ${address.state ?? ""} ${address.postal_code ?? ""} ${
      // migrate directories to strict mode
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      address.country ?? ""
    }`
  }
  return null
}
