import React from "react"
import type { FC } from "react"
import { Navigate, useLocation } from "react-router-dom"

import { useActiveOrganizationId } from "@/services/organization"

import type { RedirectToOrgRouteProps } from "./redirect-to-org-route.types"

/**
 * A temporary component that redirects legacy routes to routes that start with the active organization id
 */
export const RedirectToOrgRoute: FC<RedirectToOrgRouteProps> = ({ path }) => {
  const { activeOrganizationId } = useActiveOrganizationId()
  const location = useLocation()
  const orgStrippedPathname = location.pathname.replace(
    `/${activeOrganizationId}`,
    ""
  )
  const toUrl = `/${activeOrganizationId}${path ?? orgStrippedPathname}${location.search}`

  return activeOrganizationId ? <Navigate to={toUrl} replace={true} /> : null
}
