import type { GridColDef } from "@mui/x-data-grid-pro"

export const baseColumnDef: Partial<GridColDef> = {
  width: 200,
}

export const baseNumberColumnDef: Partial<GridColDef> = {
  ...baseColumnDef,
  align: "right",
  headerAlign: "right",
  type: "number",
}
