import { all, getContext, put, takeEvery, takeLatest } from "redux-saga/effects"

import type Currency from "../../../models/currency"
import { fetchCurrencies } from "../../../models/currency"
import type { CurrencyCode } from "../../../models/currencyCode"
import { SagaContextKeys } from "../../../state/store"
import { handleError } from "../../../utils/error"
import { saveCurrency, saveLanguage } from "../../../utils/localStorage"
import {
  currenciesToCurrencyCodes,
  isSupportedCurrency,
} from "../util/settings/settings"
import type {
  ChangeCurrencyAction,
  ChangeLanguageAction,
  SetInitialUserLanguageAction,
} from "./userSettings.store"
import {
  changeCurrency,
  changeLocale,
  loadSupportedCurrencies,
  loadSupportedCurrenciesFail,
  loadSupportedCurrenciesSuccess,
  setInitialUserLocale,
} from "./userSettings.store"

/**
 * Fetches the user-selectable currencies
 *
 * @yields
 */
export function* fetchSupportedCurrencies() {
  try {
    // Fetch the currencies
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = yield fetchCurrencies()

    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const serverSupportedCurrencies: Currency[] = response

    const uiSupportedCurrencyCodes: CurrencyCode[] = currenciesToCurrencyCodes(
      serverSupportedCurrencies.filter((serverCurrencyCode) =>
        isSupportedCurrency(serverCurrencyCode.alphaCode as CurrencyCode)
      )
    )

    yield put(loadSupportedCurrenciesSuccess(uiSupportedCurrencyCodes))
  } catch (error) {
    yield put(loadSupportedCurrenciesFail())
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    handleError(error)
  }
}

/**
 * Saves the selected currency code to local storage
 *
 * @param action
 * @param action.payload
 * @generator
 * @yields
 */
export function* saveCurrencyChange({ payload }: ChangeCurrencyAction) {
  // Don't bother fixing this eslint violation as we will replace this with React Query
  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  yield saveCurrency(payload)
}

/**
 * Saves the selected language region to local storage and sets the change in the i18n service
 *
 * @param action
 * @param action.payload
 * @generator
 * @yields
 */
export function* saveLanguageChange({ payload }: ChangeLanguageAction) {
  // Don't bother fixing this eslint violation as we will replace this with React Query
  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  yield saveLanguage(payload)
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const i18nService = yield getContext(SagaContextKeys.i18n)
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  yield i18nService.changeLanguage(payload)
}

/**
 * Saves the default language region to the i18n service.
 *
 * @param action
 * @param action.payload
 * @generator
 * @yields
 */
export function* saveDefaultLanguage({
  payload,
}: SetInitialUserLanguageAction) {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const i18nService = yield getContext(SagaContextKeys.i18n)
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  yield i18nService.changeLanguage(payload)
}

/**
 * Saga watchers for UserSettings actions
 *
 * @yields
 */
export default function* watchUserSettings() {
  yield all([
    takeEvery(changeCurrency, saveCurrencyChange),
    takeLatest(loadSupportedCurrencies, fetchSupportedCurrencies),
    takeEvery(changeLocale, saveLanguageChange),
    takeEvery(setInitialUserLocale, saveDefaultLanguage),
  ])
}
