import { Attr, Model } from "spraypaint"
import type { CollectionProxy } from "spraypaint/lib-esm/proxies"

import ApplicationRecord from "./applicationRecord/applicationRecord"

export const DEFAULT_ENERGY_ALLOCATOR_PLACEHOLDER = "_.__"

@Model()
export default class EnergyAllocator extends ApplicationRecord {
  public static jsonapiType = "energy_allocators"

  @Attr() public readonly name: string

  @Attr() public readonly baCode: string

  @Attr() public readonly providerType: string
}

export const fetchCurrencies = async (): Promise<EnergyAllocator[]> => {
  const response: CollectionProxy<EnergyAllocator> = await EnergyAllocator.all()
  return response.data
}
