import React from "react"
import { useLocation } from "react-router-dom"

import { NzeroLogo } from "@/components/logo/nzeroLogo"
import type Organization from "@/models/organization"
import { RootPath } from "@/models/route"
import { useAuthentication } from "@/services/authentication"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useUserAccess } from "@/services/user"
import {
  LEFT_NAV_COLLAPSED_WIDTH,
  LEFT_NAV_EXPANDED_WIDTH,
  primary800,
} from "@/utils"
import { useUrl } from "@/utils/url"

import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import AssistantIcon from "@mui/icons-material/Assistant"
import BoltIcon from "@mui/icons-material/Bolt"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined"
import HomeWorkIcon from "@mui/icons-material/HomeWork"
import InsightsIcon from "@mui/icons-material/Insights"
import LeaderboardIcon from "@mui/icons-material/Leaderboard"
import ListAltIcon from "@mui/icons-material/ListAlt"
import MenuRoundedIcon from "@mui/icons-material/MenuRounded"
import RadarRoundedIcon from "@mui/icons-material/RadarRounded"
import {
  Box,
  IconButton,
  List,
  Drawer as MuiDrawer,
  styled,
} from "@mui/material"

import { NavItemButton } from "./components/nav-item-button/nav-item-button"

interface NavigationDrawerProps {
  isOpen: boolean
  organization: Organization | null
  toggleDrawer: () => void
}

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: open ? LEFT_NAV_EXPANDED_WIDTH : LEFT_NAV_COLLAPSED_WIDTH,
  boxSizing: "border-box",
  flexShrink: 0,
  whiteSpace: "nowrap",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  "& .MuiDrawer-paper": {
    width: open ? LEFT_NAV_EXPANDED_WIDTH : LEFT_NAV_COLLAPSED_WIDTH,
    backgroundColor: primary800.toString(),
    borderRadius: 0,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))

export const NavigationDrawer = ({
  isOpen,
  toggleDrawer,
  organization,
}: NavigationDrawerProps) => {
  const { isFeatureEnabled } = useFeature()
  const location = useLocation()
  const {
    assistantPath,
    dashboardPath,
    dataPath,
    financialPath,
    recommendationsPath,
    reportingPath,
    sitesPath,
  } = useUrl()
  const { isConsultantUser } = useAuthentication()
  const { isLimitedAccessUser } = useUserAccess()

  const isLimitedAccessUserBasicsEnabled = isFeatureEnabled(
    FeatureFlags.LIMITED_ACCESS_USER_BASICS,
    organization
  )

  const isNavButtonVisible =
    !isLimitedAccessUser ||
    (isLimitedAccessUser && isLimitedAccessUserBasicsEnabled)

  return (
    <Drawer open={isOpen} variant="permanent">
      <Box
        sx={{
          height: 48,
          color: (theme) => theme.palette.common.white,
          display: "flex",
          alignItems: "center",
          justifyContent: isOpen ? "space-between" : "center",
          ...(isOpen
            ? { padding: (theme) => theme.spacing(1, 1, 0, 2) }
            : null),
        }}
      >
        {isOpen ? <NzeroLogo width={80} /> : null}
        <IconButton
          aria-label="toggle drawer"
          color="inherit"
          onClick={toggleDrawer}
        >
          {isOpen ? <ChevronLeftIcon /> : <MenuRoundedIcon />}
        </IconButton>
      </Box>
      <List>
        {isConsultantUser ? (
          <NavItemButton
            dataE2e="analyze-link"
            from={location.pathname}
            icon={<InsightsIcon />}
            id="analyze-button"
            isOpen={isOpen}
            title="Analyze"
            to={reportingPath}
          />
        ) : null}
        {isNavButtonVisible && !isConsultantUser ? (
          <NavItemButton
            dataE2e="dashboard-link"
            from={location.pathname}
            icon={<LeaderboardIcon />}
            id="dashboard-button"
            isOpen={isOpen}
            title="Dashboard"
            to={dashboardPath}
          />
        ) : null}
        {isNavButtonVisible && !isConsultantUser ? (
          <NavItemButton
            dataE2e="organization-link"
            from={location.pathname}
            icon={<AccountBalanceIcon />}
            id="organization-button"
            isOpen={isOpen}
            title="Financial"
            to={financialPath}
          />
        ) : null}
        <NavItemButton
          dataE2e="sites-link"
          from={location.pathname}
          icon={<HomeWorkIcon />}
          id="sites-link"
          isOpen={isOpen}
          title="Site Explorer"
          to={sitesPath}
        />
        {isNavButtonVisible && !isConsultantUser ? (
          <NavItemButton
            dataE2e="reporting-link"
            from={location.pathname}
            icon={<ListAltIcon />}
            id="reporting-button"
            isOpen={isOpen}
            title="Reporting"
            to={reportingPath}
          />
        ) : null}
        {!isLimitedAccessUser &&
        isFeatureEnabled(FeatureFlags.ASSISTANT, organization) ? (
          <NavItemButton
            dataE2e="assistant-link"
            from={location.pathname}
            icon={<AssistantIcon />}
            id="assistant-button"
            isBeta={true}
            isOpen={isOpen}
            title="Assistant"
            to={assistantPath}
          />
        ) : null}
        {isConsultantUser ? (
          <NavItemButton
            dataE2e="data-manager-link"
            from={location.pathname}
            icon={<DatasetOutlinedIcon />}
            id="data-manager-button"
            isOpen={isOpen}
            title="Data Manager"
            to={dataPath}
          />
        ) : null}
        {isConsultantUser ||
        isFeatureEnabled(FeatureFlags.PLANNING, organization) ? (
          <NavItemButton
            dataE2e="recommendations-link"
            from={location.pathname}
            icon={<RadarRoundedIcon />}
            id="recommendations-button"
            isOpen={isOpen}
            title="Planning"
            to={recommendationsPath}
          />
        ) : null}
        {!isLimitedAccessUser &&
        !isConsultantUser &&
        isFeatureEnabled(FeatureFlags.RECOMMENDATIONS_REP, organization) ? (
          <NavItemButton
            dataE2e="rep-link"
            from={location.pathname}
            icon={<BoltIcon />}
            id="rep-button"
            isOpen={isOpen}
            title="Rapid Emissions Profile"
            to={`${RootPath.Rep}`}
          />
        ) : null}
      </List>
    </Drawer>
  )
}
